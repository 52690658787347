import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import classNames from 'classnames';

import ArticleHeader from './Article/ArticleHeader';
import SearchBar from './SearchBar';
import { store, history } from '../store';
import agent from '../agent';
import { ArticleLayoutView } from '../models/ArticleLayoutEnum';
import {
  LOGOUT,
  TOGGLE_LEFT_SIDEBAR,
  TOGGLE_RIGHT_SIDEBAR,
  SET_VIEW_MODE,
  SET_NEXT_VIEW_MODE,
  SEARCH_EXECUTED,
  SET_SWIPE_SLIDE_POSITION,
  TOGGLE_SWIPE_OVERLAYS,
  ARTICLE_SUBMITTED,
  MODAL_OPEN,
  DELETE_ARTICLE,
  ARTICLE_TITLE_IMAGE_UPDATED,
  EXIT_CHILD_ARTICLE_EDITOR,
  TOGGLE_ARTICLE_EDIT_MODE,
  TOGGLE_COLUMNS,
  TOGGLE_ARTICLE_LAYOUT_VIEW,
  TOGGLE_WIDESCREEN_EDIT_MODE,
  EXIT_SLIDE_SELECTION_MODE,
  UPDATE_SLIDE_SELECTION_METHOD,
  UPDATE_SLIDE_SELECTION_OPERATION_MODE,
  // ARTICLE_IMAGE_URL_UPDATED,
} from '../constants/actionTypes';
import { getCustomDomain, urlForArticle } from '../utils/helperFunctions';
import SlideEditGideHeader from './Slides/SlideEditGideHeader/SlideEditGideHeader';
import GideImage from './Shared/Image/GideImage';
import isNil from 'ramda/es/isNil';

const mapDispatchToProps = dispatch => ({
  onDeleteArticle: payload => dispatch({ type: DELETE_ARTICLE, payload }),
  onSearch: payload => dispatch({ type: SEARCH_EXECUTED, payload }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onToggleLeftSidebar: () => dispatch({ type: TOGGLE_LEFT_SIDEBAR }),
  onToggleRightSidebar: (isGideMenu) => dispatch({ type: TOGGLE_RIGHT_SIDEBAR, payload: { isGideMenu: isGideMenu } }),
  setNextViewMode: mode => dispatch({ type: SET_NEXT_VIEW_MODE, mode }),
  setSwipeSlidePosition: payload => dispatch({ type: SET_SWIPE_SLIDE_POSITION, payload }),
  onToggleSwipeOverlays: () => dispatch({ type: TOGGLE_SWIPE_OVERLAYS }),
  updateSlideSelectionMethod: payload => dispatch({type: UPDATE_SLIDE_SELECTION_METHOD, payload}),
  updateSlideSelectionOperationMode: payload => dispatch({type: UPDATE_SLIDE_SELECTION_OPERATION_MODE, payload}),
  onSubmitArticle: payload => dispatch({ type: ARTICLE_SUBMITTED, payload }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  onUpdateArticleTitleAndImage: payload => dispatch({ type: ARTICLE_TITLE_IMAGE_UPDATED, payload }),
  exitInlineSlideArticleEditor: () => dispatch({ type: EXIT_CHILD_ARTICLE_EDITOR }),
  exitSlideSelectionMode: () => dispatch({ type: EXIT_SLIDE_SELECTION_MODE }),
  toggleArticleEditMode: payload => dispatch({ type: TOGGLE_ARTICLE_EDIT_MODE, payload }),
  onToggleColumns: () => dispatch({ type: TOGGLE_COLUMNS }),
  toggleArticleLayoutView: payload => dispatch({ type: TOGGLE_ARTICLE_LAYOUT_VIEW, payload }),
  toggleWidewcreenEditMode: () => dispatch({ type: TOGGLE_WIDESCREEN_EDIT_MODE }),

  // onUpdateArticleImageUrl: payload => dispatch ({type: ARTICLE_IMAGE_URL_UPDATED, payload}),
});

const mapStateToProps = (state, ownProps) => {
  let view;
  // let slide;
  if (ownProps.location) {
    const query = new URLSearchParams(ownProps.location.search);
    view = query.get('view');
    // slide = query.get('slide');
  }
  return {
    ...state.article,
    percentArticleCompleted: ownProps.percentArticleCompleted,
    currentUser: state.common.currentUser,
    attendances: state.article.attendances,
    retainArticle: state.common.retainArticle,
    viewMode: view === 'website' ? 'SCROLL' : ownProps.viewMode ? ownProps.viewMode : state.common.viewMode,
    additionUsername: ownProps.match ? ownProps.match.params.username : null,
    slideNumber: state.common.swipeSlidePosition,
    view,
    showChrome: ownProps.showChrome !== undefined ? ownProps.showChrome : state.common.showChrome,
    loading: state.common.loading,
    displayGidesMenu: state.common.displayGidesMenu,
    showRightSidebar: state.common.showRightSidebar,
    articleEditMode: state.common.articleEditMode,
    renderColumns: state.article.renderColumns,
    articleLayoutView: state.common.articleLayoutView,
    showLeftSidebar: state.common.showLeftSidebar,
    slideSelectionModeDetail: state.article.slideSelectionModeDetail,
  };
};

export const LoggedOutView = props => {
  const onLogin = () => {
    const url = `${window.location.origin}/login`;
    window.location = url;
  };

  const onRegister = () => {
    const url = `${window.location.origin}/register`;
    window.location = url;
  };

  if (!props.currentUser) {
    if (props.loading !== true) {
      return (
        <ul className="nav navbar-nav pull-xs-right">
          <li className="nav-item">
            <span onClick={onLogin} className="login-link">
              Sign in
            </span>
          </li>

          <li className="nav-item">
            <span onClick={onRegister} className="login-link">
              Sign up
            </span>
          </li>
        </ul>
      );
    } else {
      return <div />;
    }
  }
  return null;
};

const LoggedInView = props => {
  const classes = classNames(
    props.article ? 'flexRowFull' : 'flexRowFlexStart',
    // props.showLeftSidebar ? 'leftSideBarVisibile' : '',
  );

  const handleDistributeClicked = () => {
    if (!props.article.slug) {
      const article = {
        title: 'Untitled',
      };
      props.onSubmitArticle(agent.Articles.create(article));
    } else {
      props.onToggleRightSidebar(false);
    }
  };

  if (props.currentUser) {
    return (
      <div className={classes}>
        {props.article &&
          !props.webView &&
          props.type !== 'SETTINGS' &&
          props.type !== 'HI' &&
          props.type !== 'MY' &&
          props.type !== 'CALENDAR' && (
            <div className={classes}>
              <ArticleHeader
                {...props}
                handleRootTitleClick={props.handleRootTitleClick}
                onSwipeModeClick={props.onSwipeModeClick}
                onScrollModeClick={props.onScrollModeClick}
                onSlideModeClick={props.onSlideModeClick}
                onWideViewModeClick={props.onWideViewModeClick}
                articleLayoutView={props.articleLayoutView}
                onSearchStateChanged={props.onSearchStateChanged}
                showOutline={props.showOutline}
                showChrome={props.showChrome}
                deleteArticle={props.onDeleteArticle}
                percentArticleCompleted={props.percentArticleCompleted}
                onUpdateArticleTitleAndImage={props.onUpdateArticleTitleAndImage}
                openModal={props.openModal}
                toggleArticleEditMode={props.toggleArticleEditMode}
                articleEditMode={props.articleEditMode}
                toggleWidewcreenEditMode={props.toggleWidewcreenEditMode}
                toggleChrome={props.toggleChrome}
              />
              {props.showLeftSidebar && (
                <div className="mobileLeftSidebarHeader">
                  <GideImage 
                    onClick={() => {
                      props.closeSideBarAndNavigateHome();
                    }}
                    style={{ width: '24px', height: '24px' }}
                    className="hoverIcon color-primary-500-svg"
                    src="/icons/nav/home.svg"
                    alt={`navigate home`}
                  />
                </div>
              )}
            </div>
          )}
        {props.currentUser &&
          props.currentUser.image &&
          props.article && (
            <span className="flexRowFlexNormal" style={{ marginRight: '0px' }}>
              {props.viewMode !== 'SWIPE' &&
                props.article.author &&
                props.currentUser.username === props.article.author.username && (
                  <div className={`gideHeaderDistributeRectangle${props.showLeftSidebar ? ' leftSidebarVisible' : ''}`}>
                    <GideImage 
                      className="pointer largeInputBarIcon distribute whites-normal-1000-svg"
                      onClick={handleDistributeClicked}
                      src="/icons/nav/publish.svg"
                      alt={props.article.slug ? `Save, Send, Share` : `New Gide`}
                    />
                  </div>
                )}
              {props.viewMode !== 'SWIPE' &&
                props.currentUser.username !== props.article.author.username && <div className="verticalLine current-user-separator" />}

              <span className={`avatarContainer${props.showLeftSidebar ? ' leftSidebarVisible' : ''}`}>
                <GideImage 
                  src={props.currentUser.image}
                  className="current-user-pic-gide link"
                  alt={props.currentUser.username}
                  onClick={props.toggleGidesMenu}
                />
              </span>
            </span>
          )}
        {props.currentUser.image &&
          !props.article && (
            <span className="flexRowFlexNormal" style={{ marginRight: '7px' }}>
              <div className="verticalLine mobile-current-user-separator" />
              <span className="avatarContainer">
                <GideImage 
                  src={props.currentUser.image}
                  className="current-user-pic link"
                  alt={props.currentUser.username}
                  onClick={props.toggleGidesMenu}
                />
              </span>
            </span>
          )}
      </div>
    );
  }
  return null;
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.onToggleLeft = () => {};
    this.onSwipeModeClick = this._onSwipeModeClick.bind(this);
    this.handleRootTitleClick = this._handleRootTitleClick.bind(this);
    this.onScrollModeClick = this._onScrollModeClick.bind(this);
    this.onSlideModeClick = this._onSlideModeClick.bind(this);
    this.onWideViewModeClick = this._onWideViewModeClick.bind(this);
    this.searchArticles = this._onSearchArticlesClick.bind(this);
    this.onSearchStateChanged = this._onSearchStateChanged.bind(this);
    this.exitSwipeMode = () => {
      if (this.props.onExitSwipeMode) {
        this.props.onExitSwipeMode();
      } else {
        const nextViewMode = this.props.nextViewMode || 'SLIDE';
        this.props.onSetViewMode(nextViewMode);
        history.push(`${urlForArticle(this.props.article)}?slide=${this.props.slideNumber}`);
      }
    };
    this.state = { searching: false };
  }

  isInViewport(el, offset = 0) {
    const top = el.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  _onSearchStateChanged(ev) {
    this.setState({ searching: ev });
  }

  _onSwipeModeClick() {
    this.setState({ loading: true });
    this.props.setNextViewMode(this.props.viewMode);
    const els = document.querySelectorAll('.slideWrap');
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      if (this.isInViewport(el)) {
        this.props.onSetViewMode('SWIPE');
        let slideNumToNavigateTo = i + 1;
        // Offset requires choosing the next slide if available
        if (els[i + 1]) {
          slideNumToNavigateTo = i + 2;
        }
        // TODO: Temporary hack to fix issue with gide that has embedded gide causing it to think there are more slides than there actually are.
        slideNumToNavigateTo = slideNumToNavigateTo < this.props.slides.length ? slideNumToNavigateTo : this.props.slides.length - 1;
        this.props.setSwipeSlidePosition({
          slidePosition: slideNumToNavigateTo,
        });

        window.setTimeout(() => {
          history.push(`${urlForArticle(this.props.article)}/slide/${slideNumToNavigateTo}`);
        }, 300);
        break;
      }
    }
  }

  _handleRootTitleClick() {
    store.dispatch(push(urlForArticle(this.props.article)));
  }

  _onSlideModeClick() {
    // const currentViewMode = this.props.viewMode;
    this.props.onSetViewMode('SLIDE');
    // if (currentViewMode === 'SWIPE' || currentViewMode === 'WEBSITE') {
    store.dispatch(push(`${urlForArticle(this.props.article)}/?slide=${!isNil(this.props.slideNumber) ? this.props.slideNumber : 1}`));
    // }
  }
  _onWideViewModeClick() {
    const articleLayoutView =
      this.props.articleLayoutView === ArticleLayoutView.Responsive ? ArticleLayoutView.WideScreen : ArticleLayoutView.Responsive;
    this.props.toggleArticleLayoutView({ articleLayoutView });
    history.push(`${urlForArticle(this.props.article, articleLayoutView)}`);
  }

  _onScrollModeClick() {
    // const currentViewMode = this.props.viewMode;
    this.props.onSetViewMode('SCROLL');
    // if(currentViewMode === 'SWIPE') {
    // Commented out because we need to switch the URL always, in case the URL is on ?view=website
    // TODO possibly make view=website functionaity be based on a redux prop so we don't have to set the URL, may be cleaner
    if (this.props.slideNumber) {
      store.dispatch(push(`${urlForArticle(this.props.article)}/?slide=${this.props.slideNumber}`));
    } else {
      store.dispatch(push(urlForArticle(this.props.article)));
    }
    // }
  }

  _onSearchArticlesClick(searchCriteria) {
    let q = '';
    if (searchCriteria.searchText) {
      q += `searchText=${searchCriteria.searchText}&`;
    }
    if (searchCriteria.startDate) {
      q += `startDate=${searchCriteria.startDate}&`;
    }
    if (searchCriteria.endDate) {
      q += `endDate=${searchCriteria.endDate}&`;
    }
    if (searchCriteria.address) {
      q += `address=${searchCriteria.address}&`;
    }
    if (searchCriteria.latLng) {
      q += `lat=${searchCriteria.latLng.lat}&lng=${searchCriteria.latLng.lng}`;
    }
    if (!q.length) {
      q = `searchText=`;
    }
    this.props.onSearch(agent.Articles.bySearch(q));
  }
  
  render() {
    if (getCustomDomain()) return null;
    if (this.props.slideSelectionModeDetail) {
      return (
        <SlideEditGideHeader
         slideSelectionModeDetail={this.props.slideSelectionModeDetail}
         updateSlideSelectionMethod={this.props.updateSlideSelectionMethod}
         updateSlideSelectionOperationMode={this.props.updateSlideSelectionOperationMode}
         onClose={this.props.exitSlideSelectionMode}
        />
      );
    }
    return (
      <div className="header">
        {/* <nav className="navbar navbar-light"> */}

        <div className="container gidesHeader">
          {!this.props.showRightSidebar &&
            !getCustomDomain() &&
            !this.props.article && (
              <span className="hamburgerBtn">
                <GideImage 
                  className="hamburger-button-image color-secondary-600-svg"
                  src={this.props.showLeftSidebar ? '/icons/nav/hamburger/closed.svg' : '/icons/nav/hamburger/open.svg'}
                  onClick={this.props.onToggleLeftSidebar}
                  alt={`□`}
                />
              </span>
            )}
          {!this.props.showRightSidebar &&
            !getCustomDomain() &&
            this.props.viewMode !== 'SWIPE' &&
            this.props.article && (
              <span className="hamburgerBtn-gideView">
                <GideImage 
                  className="hamburger-button-image color-secondary-600-svg"
                  src={this.props.showLeftSidebar ? '/icons/nav/hamburger/closed.svg' : '/icons/nav/hamburger/open.svg'}
                  onClick={this.props.onToggleLeftSidebar}
                  alt={`□`}
                />
                <GideImage 
                  className="hamburger-button-image-responsive"
                  src={this.props.showLeftSidebar ? '/icons/nav/hamburger/closed.svg' : '/icons/nav/hamburger/open.svg'}
                  onClick={this.props.onToggleLeftSidebar}
                  alt={`□`}
                />
              </span>
            )}
          {!getCustomDomain() &&
            this.props.viewMode === 'SWIPE' &&
            this.props.article && (
              <span className="hamburgerBtn-gideView">
                <GideImage 
                  className="exit-swipe-mode-image whites-normal-1000-svg"
                  src="/icons/nav/arrow/arrow-left.svg"
                  onClick={this.exitSwipeMode.bind(this)}
                  alt={`□`}
                />
              </span>
            )}
          {/* TODO: Might need to check here for this.props.article. It is currently working so I
                may have not put it in for a reason. css may be handling it. Will check back
            */}
          {!getCustomDomain() &&
            this.props.viewMode !== 'SWIPE' && (
              <Link to="/" className="gides-brand">
                <GideImage src="/icons/nav/logo/logo-icon-sm.svg" className="logo color-primary-500-svg" alt={`□`} />
              </Link>
            )}
          {!getCustomDomain() &&
            this.props.viewMode !== 'SWIPE' &&
            !this.props.article &&
            this.props.loading !== true && (
              <Link to="/" className="gides-brand-mobile">
                <GideImage src="/icons/nav/logo/logo-icon-sm.svg" className="color-primary-500-svg" alt={`□`} />
              </Link>
            )}
          {!getCustomDomain() &&
            !this.props.article && (
              <div className="flexRowFull">
                <div className="verticalLine fullscreen" />
                {/* The following 2 inner spans are identical with the exception of the
                    placeholder text. So I hide/show the appropriate one using css
                    based on the width of the screen. There may be a better way to
                    handle this using ::after. TODO: Look into using ::after
                */}
                <span className="flexRowFullCenter no-gide">
                  <span className="searchBarContainer no-mobile" style={{ marginRight: '21px' }}>
                    <SearchBar onSearch={this.searchArticles} viewMode={this.props.viewMode} placeholder={'What are you looking for?'} />
                  </span>
                  <span className="searchBarContainer mobile">
                    <SearchBar onSearch={this.searchArticles} viewMode={this.props.viewMode} placeholder={'search'} />
                  </span>
                </span>
              </div>
            )}

          <LoggedOutView currentUser={this.props.currentUser} loading={this.props.loading} />

          <LoggedInView
            {...this.props}
            handleRootTitleClick={this.handleRootTitleClick}
            onSwipeModeClick={this.onSwipeModeClick}
            onScrollModeClick={this.onScrollModeClick}
            onSlideModeClick={this.onSlideModeClick}
            onWideViewModeClick={this.onWideViewModeClick}
            onSearchStateChanged={this.onSearchStateChanged}
            toggleGidesMenu={this.props.toggleGidesMenu}
            showOutline={this.props.showOutline}
            percentArticleCompleted={this.props.percentArticleCompleted}
            articleLayoutView={this.props.articleLayoutView}
            closeSideBarAndNavigateHome={this.props.closeSideBarAndNavigateHome}
            toggleChrome={this.props.toggleChrome}
            // currentUser={this.props.currentUser}
            // onNewClick={this.props.onNewClick}
            // onClickLogout={this.props.onClickLogout}
          />
        </div>

        {/* </nav> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
