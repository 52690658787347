import {
  UPDATE_FOOTER_INPUT,
  TOGGLE_TOOLBAR,
  FOCUS_INPUT,
  INPUT_FOCUSED,
  INPUT_BLURRED,
} from '../constants/actionTypes';
import { FooterAction } from '../models/FooterAction';

export default (state = { hideToolbar: true, focus: false }, action: FooterAction) => {
  switch (action.type) {
    case UPDATE_FOOTER_INPUT:
      return { ...state, [action.payload.key]: action.payload.value };
    case TOGGLE_TOOLBAR:
      return { ...state, hideToolbar: !state.hideToolbar };
    case FOCUS_INPUT:
      return { ...state, focus: true };
    case INPUT_FOCUSED:
      return { ...state, focus: false, focused: true };
    case INPUT_BLURRED:
      return { ...state, focused: false };
    default:
      return state;
  }
};
