import React, { CSSProperties } from 'react';
import styles from './_tertiary-button.module.scss';
import classNames from 'classnames';
import { IconProps } from '../../../../assets/icons';

export interface  TertiaryButtonProps {
  isSelected: boolean;
  label: string;
  style?: CSSProperties;
  icon: (props: IconProps) => JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>)  => void;
}

export function TertiaryButton (props:  TertiaryButtonProps) {

  return (
    <div className={classNames(styles.tertiaryButtonContainer, props.isSelected ? styles.selected : undefined)}
      style={props.style}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <props.icon 
        color={props.isSelected 
          ? 'var(--WHITES-NORMAL-1000)'
          : 'var(--COLOR-SECONDARY-600)'
        }
      />
      <span className={classNames(styles.buttonLabel, props.isSelected ? styles.selected : undefined)}>{props.label}</span>
    </div>
  );
}
