export const contentMetaData = {
  AUDIO: {
    icon: '/images/slide-icons/icon/PNGS/audio.png',
    colors: ['rgb(16, 85, 115)', 'rgb(59, 128, 169)', 'rgb(90, 177, 196)'],
    primaryColor: 'rgb(59, 130, 170)',
  },
  AUTHORIZE: {
    icon: '/images/slide-icons/icon/PNGS/authorize.png',
    colors: ['rgb(17, 94, 96)', 'rgb(24, 121, 127)', 'rgb(72, 180, 173)'],
    primaryColor: 'rgb(24, 121, 127)',
  },
  AUTHORIZEBLOCK: {
    icon: '/images/slide-icons/icon/PNGS/authorize.png',
    colors: ['rgb(17, 94, 96)', 'rgb(24, 121, 127)', 'rgb(72, 180, 173)'],
    primaryColor: 'rgb(24, 121, 127)',
  },
  CHOOSE: {
    icon: '/images/slide-icons/icon/PNGS/choose.png',
    colors: ['rgb(211, 100, 72)', 'rgb(217, 126, 56)', 'rgb(232, 164, 64)'],
    primaryColor: 'rgb(218, 128, 57)',
  },
  CONTACTS: {
    icon: '/images/slide-icons/icon/PNGS/contact.png',
    colors: ['rgb(158, 55, 41)', 'rgb(194, 71, 81)', 'rgb(229, 128, 130)'],
    primaryColor: 'rgb(250, 178, 49)',
  },
  DIAGRAM: {
    icon: '/images/slide-icons/icon/PNGS/diagram.png',
    colors: ['rgb(127, 50, 72)', 'rgb(152, 63, 85)', 'rgb(191, 77, 131)'],
    primaryColor: 'rgb(128, 51, 72)',
  },
  DOODLE: {
    icon: '/images/slide-icons/icon/PNGS/doodle.png',
    colors: ['rgb(94, 74, 127)', 'rgb(122, 96, 155)', 'rgb(181, 173, 185)'],
    primaryColor: 'rgb(97, 75, 128)',
  },
  SIGNATURE: {
    icon: '/images/slide-icons/icon/PNGS/doodle.png',
    colors: ['rgb(50, 50, 50)', 'rgb(100, 100, 100)', 'rgb(150, 150, 150)'],
    primaryColor: 'rgb(100, 100, 100)',
  },
  FILE: {
    icon: '/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg',
    colors: ['rgb(208, 78, 78)', 'rgb(227, 98, 99)', 'rgb(229, 128, 132)'],
    primaryColor: 'rgb(250, 178, 49)',
    pdf: 'rgb(193, 40, 52)',
  },
  GIDE: {
    icon: '/images/slide-icons/icon/PNGS/gides.png',
    colors: ['rgb(211, 130, 52)', 'rgb(237, 168, 52)', 'rgb(241, 205, 133)'],
    primaryColor: 'rgb(237, 168, 52)',
    embeddedColor: 'rgb(226, 155, 50)',
    eventColor: 'rgb(67, 179, 173)',
  },
  SLIDE: {
    icon: '/images/slide-icons/icon/PNGS/gides.png',
    colors: ['rgb(211, 130, 52)', 'rgb(237, 168, 52)', 'rgb(241, 205, 133)'],
    primaryColor: 'rgb(237, 168, 52)',
    embeddedColor: 'rgb(226, 155, 50)',
    eventColor: 'rgb(67, 179, 173)',
  },
  HEADER: {
    icon: '/images/slide-icons/icon/PNGS/headersWhite.png',
    colors: ['rgb(17, 94, 86)', 'rgb(24, 121, 127)', 'rgb(72, 180, 173)'],
    primaryColor: 'rgb(23, 121, 127)',
  },
  IMAGE: {
    icon: '/images/slide-icons/icon/PNGS/image.png',
    colors: ['rgb(208, 78, 78)', 'rgb(227, 98, 99)', 'rgb(229, 128, 132)'],
    primaryColor: 'rgb(233, 233,235)',
  },
  INPUT: {
    icon: '/images/slide-icons/icon/PNGS/input.png',
    colors: ['rgb(228, 129, 131)', 'rgb(195, 73, 82)', 'rgb(155, 55, 41)'],
    primaryColor: 'rgb(124, 97, 157)',
  },
  ITEM: {
    icon: '/images/slide-icons/icon/PNGS/item.png',
    colors: ['rgb(16, 85, 115)', 'rgb(60, 127, 166)', 'rgb(90, 177, 196)'],
    primaryColor: 'rgb(25,86,117)',
  },
  LEAD: {
    icon: '/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg',
    colors: ['rgb(208, 78, 78)', 'rgb(227, 98, 99)', 'rgb(229, 128, 132)'],
    primaryColor: 'rgb(194, 71, 81)',
  },
  LINKS: {
    icon: '/images/slide-icons/icon/PNGS/diagram.png',
    colors: ['rgb(17, 94, 96)', 'rgb(24, 121, 127)', 'rgb(72, 180, 173)'],
    primaryColor: 'rgb(59, 130, 170)',
  },
  LIST: {
    icon: '/images/slide-icons/icon/PNGS/list.png',
    colors: ['rgb(94, 74, 127)', 'rgb(122, 96, 155)', 'rgb(181, 173, 185)'],
    primaryColor: 'rgb(97, 75, 128)',
  },
  LOCATION: {
    icon: '/images/slide-icons/icon/PNGS/maps.png',
    colors: ['rgb(27, 122, 90)', 'rgb(31, 142, 117)', 'rgb(137, 191, 115)'],
    primaryColor: 'rgb(25, 122,92)',
  },
  MAP: {
    icon: '/images/slide-icons/icon/PNGS/maps.png',
    colors: ['rgb(27, 122, 90)', 'rgb(31, 142, 117)', 'rgb(137, 191, 115)'],
    primaryColor: 'rgb(25, 122, 92)',
  },
  TEXT: {
    icon: '/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg',
    colors: ['rgb(228, 129, 131)', 'rgb(195, 73, 82)', 'rgb(155, 55, 41)'],
    primaryColor: 'rgb(0, 0, 0)',
  },
  VIDEO: {
    icon: '/images/slide-icons/icon/PNGS/video.png',
    colors: ['rgb(94, 74, 127)', 'rgb(122, 96, 155)', 'rgb(181, 173, 185)'],
    primaryColor: 'rgb(228, 129, 131)',
  },
  CHANNEL: {
    icon: '/images/slide-icons/icon/PNGS/channel.png',
    colors: ['rgb(228, 129, 131)', 'rgb(195, 73, 82)', 'rgb(155, 55, 41)'],
    primaryColor: 'rgb(196, 72, 82)',
  },
  EMBED: {
    icon: '/images/slide-icons/icon/PNGS/video.png',
    colors: ['rgb(228, 129, 131)', 'rgb(195, 73, 82)', 'rgb(155, 55, 41)'],
    primaryColor: 'rgb(196, 72, 82)',
  },
  COLLAPSE: {
    icon: '/images/slide-icons/icon/PNGS/headersWhite.png',
    colors: ['rgb(17, 94, 86)', 'rgb(24, 121, 127)', 'rgb(72, 180, 173)'],
    primaryColor: 'rgb(23, 121, 127)',
  },

};

export const attachmentMetaData = {
  AUDIO: {
    icon: '/images/slide-icons/icon/audio.svg'
  },
  AUTHORIZE: {
    icon: '/images/slide-icons/icon/authorize.svg'
  },
  AUTHORIZEBLOCK: {
    icon: '/images/slide-icons/icon/authorize.svg'
  },
  CHOOSE: {
    icon: '/images/slide-icons/icon/choose.svg'
  },
  CONTACTS: {
    icon: '/images/slide-icons/icon/contact.svg'
  },
  DIAGRAM: {
    // icon: '/images/slide-icons/icon/diagram.svg'
    icon: '/icons/slidetype/diagram/SM.svg'
  },
  DOODLE: {
    icon: '/images/slide-icons/icon/doodle.svg'
  },
  SIGNATURE: {
    icon: '/images/slide-icons/icon/doodle.svg'
  },
  FILE: {
    icon: '/images/slide-icons/icon/attachment.svg'
  },
  GIDE: {
    icon: '/images/slide-icons/icon/attachment.svg' // TODO
  },
  SLIDE: {
    icon: '/images/slide-icons/icon/slides.svg'
  },
  HEADER: {
    icon: '/images/slide-icons/icon/header.svg'
  },
  IMAGE: {
    icon: '/images/slide-icons/icon/image.svg'
  },
  INPUT: {
    icon: '/images/slide-icons/icon/input.svg'
  },
  ITEM: {
    icon: '/images/slide-icons/icon/item.svg'
  },
  LEAD: {
    icon: '/images/slide-icons/icon/item.svg' // TODO
  },
  LINKS: {
    icon: '/images/slide-icons/icon/lead.svg'
  },
  LIST: {
    icon: '/images/slide-icons/icon/list.svg'
  },
  LOCATION: {
    icon: '/images/slide-icons/icon/map.svg'
  },
  MAP: {
    icon: '/images/slide-icons/icon/map.svg'
  },
  TEXT: {
    icon: '/images/slide-icons/icon/text.svg'
  },
  VIDEO: {
    icon: '/images/slide-icons/icon/video.svg'
  },
  CHANNEL: {
    icon: '/images/slide-icons/icon/channel.svg'
  },
  EMBED: {
    icon: '/images/slide-icons/icon/video.svg' // TODO
  },
}

export const addSlideInputBarMenuChoices = [
  {
    icon: '/icons/nav/plusicon.svg',
    sortIndex: 0,
    label: 'More...',
    modal: 'Choices',
    className: 'innerInputMenuLargeIcon color-primary-500-svg',
    containerClassName: 'innerInputSlideMenuLargeItem pointer',
    detailItemClassName: 'largeDetailItem',
  },
  {
    icon: '/icons/slidetype/input/main.svg',
    sortIndex: 1,
    label: 'Input',
    modal: 'Input',
    className: 'innerInputMenuIcon color-purple-200-svg',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/authorize/main.svg',
    sortIndex: 2,
    label: 'Authorize',
    modal: 'Authorize',
    className: 'innerInputMenuIcon',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/file/main.svg',
    sortIndex: 3,
    label: 'File',
    modal: 'File',
    className: 'innerInputMenuIcon',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/location/location.svg',
    sortIndex: 4,
    label: 'Geotag',
    modal: 'Location',
    className: 'innerInputMenuIcon color-green-300-svg',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/map/main.svg',
    sortIndex: 5,
    label: 'Map',
    modal: 'Map',
    className: 'innerInputMenuIcon color-green-200-svg',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/embed/main.svg',
    sortIndex: 6,
    label: 'Embed',
    modal: 'Embed',
    className: 'innerInputMenuIcon',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/audio/mic.svg',
    sortIndex: 7,
    label: 'Audio',
    modal: 'Audio',
    className: 'innerInputMenuIcon color-blue-100-svg',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/video/main.svg',
    sortIndex: 8,
    label: 'Video',
    modal: 'Video',
    className: 'innerInputMenuIcon',
    containerClassName: 'innerInputSlideMenuItem pointer',
    detailItemClassName: 'detailItem',
  },
  {
    icon: '/icons/slidetype/image/main.svg',
    sortIndex: 9,
    label: 'Image',
    modal: 'IMAGE',
    className: 'innerInputMenuIcon color-tomato-svg',
    containerClassName: 'innerInputSlideMenuItem separator pointer',
    detailItemClassName: 'detailItem',
  },
]