import React, { Component } from 'react';
import { connect } from 'react-redux';
import agent from '../../agent';

import ArticleList from '../ArticleList';
import ChannelList from '../ChannelList';
import CollectionList from '../CollectionList';
import UserList from '../UserList';

import {
  CHANGE_TAB,
  DELETE_CHANNEL_NO_REDIRECT,
  DELETE_ARTICLE_NO_REDIRECT,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';

const YourFeedTab = props => {
  if (props.token) {
    const clickHandler = ev => {
      ev.preventDefault();
      props.onTabClick('feed', agent.Articles.feed, agent.Articles.feed());
    };

    return (
      // <li className="nav-item">
      <span
        className={
          props.tab === 'feed' ? 'homePageMenuItem active' : 'homePageMenuItem'
        }
      >
        <a href="#/" className="homePageMenuItem" onClick={clickHandler}>
          recommended
        </a>
      </span>
      // </li>
    );
  }
  return null;
};

const GlobalFeedTab = props => {
  const clickHandler = ev => {
    ev.preventDefault();
    props.onTabClick('all', agent.Articles.all, agent.Articles.all());
  };
  return (
    <span
      className={
        props.tab === 'all' ? 'homePageMenuItem active' : 'homePageMenuItem'
      }
    >
      <a href="#/" className="homePageMenuItem" onClick={clickHandler}>
        categories
      </a>
    </span>
    // </li>
  );
};

const PlacesFeedTab = props => {
  const clickHandler = ev => {
    ev.preventDefault();
    props.onTabClick('places', agent.Articles.all, agent.Articles.all());
  };
  return (
    <span
      className={
        props.tab === 'places' ? 'homePageMenuItem active' : 'homePageMenuItem'
      }
    >
      <a href="#/" className="homePageMenuItem" onClick={clickHandler}>
        places
      </a>
    </span>
    // </li>
  );
};

const PageAnchorTab = props => {
  return (
    <span
      className={
        props.anchor === props.selectedAnchor
          ? 'homePageSubMenuItem active'
          : 'homePageSubMenuItem'
      }
    >
      <a
        href="#/"
        className="homePageSubMenuItem"
        onClick={() => props.onAnchorClick(props.anchor)}
      >
        {props.anchor}
      </a>
      <span className="homePageSubMenuActive" />
    </span>
  );
};

// const TagFilterTab = props => {
//   if (!props.tag) {
//     return null;
//   }

//   return (
//     <li className="nav-item">
//       <a href="#/" className="nav-link active">
//         <i className="ion-pound" /> {props.tag}
//       </a>
//     </li>
//   );
// };

const mapStateToProps = state => ({
  ...state.articleList,
  tags: state.home.tags,
  channels: state.home.channels,
  collections: state.home.collections,
  token: state.common.token,
  currentUser: state.common.currentUser,
  users: state.home.users,
});

const mapDispatchToProps = dispatch => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  onDeleteChannel: payload =>
    dispatch({ type: DELETE_CHANNEL_NO_REDIRECT, payload }),
  onDeleteArticle: payload =>
    dispatch({ type: DELETE_ARTICLE_NO_REDIRECT, payload }),
});

class MainView extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedAnchor: 'gides' };
    this.onAnchorClick = selectedAnchor => {
      this.setState({ selectedAnchor });
      let selectedRef;
      switch (selectedAnchor) {
        case 'gides':
          selectedRef = this.$gidesRef;
          break;
        case 'channels':
          selectedRef = this.$channelsRef;
          break;
        case 'business':
          selectedRef = this.$businessRef;
          break;
        case 'people':
          selectedRef = this.$peopleRef;
          break;
        case 'events':
          selectedRef = this.$eventsRef;
          break;
        case 'experience':
          selectedRef = this.$experienceRef;
          break;
        default:
          selectedRef = null;
      }
      if (selectedRef !== null) {
        try {
          selectedRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
          });
        } catch(err) {

        }
      }
    };
  }

  render() {
    const { collections } = this.props;
    const users = this.props.users || [];
    return (
      <div style={{ flex: 1 }}>
        {/* <div className="feed-toggle">
          <ul className="nav nav-pills outline-active">
            <YourFeedTab
              token={props.token}
              tab={props.tab}
              onTabClick={props.onTabClick}
            />
            <GlobalFeedTab tab={props.tab} onTabClick={props.onTabClick} />
            <TagFilterTab tag={props.tag} />
          </ul>
        </div> */}

        <div className="homePageMenu">
          <div className="flexRowFullCenter" style={{ flexWrap: 'wrap' }}>
            <YourFeedTab
              token={this.props.token}
              tab={this.props.tab}
              onTabClick={this.props.onTabClick}
            />
            <GlobalFeedTab
              tab={this.props.tab}
              onTabClick={this.props.onTabClick}
            />
            <PlacesFeedTab
              tab={this.props.tab}
              onTabClick={this.props.onTabClick}
            />
          </div>
        </div>
        {/* This is where the (sponsored) image will appear if there is one */}

        <div className="homePageSubMenu">
          <div
            className="flexRowFullCenter"
            style={{ flex: 5, flexWrap: 'wrap' }}
          >
            <PageAnchorTab
              anchor={'gides'}
              selectedAnchor={this.state.selectedAnchor}
              onAnchorClick={this.onAnchorClick}
            />
            <PageAnchorTab
              anchor={'channels'}
              selectedAnchor={this.state.selectedAnchor}
              onAnchorClick={this.onAnchorClick}
            />
            <PageAnchorTab
              anchor={'business'}
              selectedAnchor={this.state.selectedAnchor}
              onAnchorClick={this.onAnchorClick}
            />
            <PageAnchorTab
              anchor={'people'}
              selectedAnchor={this.state.selectedAnchor}
              onAnchorClick={this.onAnchorClick}
            />
            <PageAnchorTab
              anchor={'events'}
              selectedAnchor={this.state.selectedAnchor}
              onAnchorClick={this.onAnchorClick}
            />
            <PageAnchorTab
              anchor={'experience'}
              selectedAnchor={this.state.selectedAnchor}
              onAnchorClick={this.onAnchorClick}
            />
          </div>
        </div>

        <CollectionList
          collections={collections}
          iterate={true}
          currentUser={this.props.currentUser}
          justifyCenter={true}
        />

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >          
          <div className="gideSectionTitle">
            <span
              className="gideSectionTitleHeader"
              ref={ref => {
                this.$gidesRef = ref;
              }}
            >
              Gides for you
            </span>
            {/* CSS Centering Trick */}
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
          </div>
        </div>


        
        <ArticleList
          articles={this.props.articles}
          onDeleteArticle={this.props.onDeleteArticle}
          currentUser={this.props.currentUser}
          center={true}
          layout="SQUARE"
        />
        {/* Private Channels */}
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <div className="gideSectionTitleHeader">
              <span
                ref={ref => {
                  this.$channelsRef = ref;
                }}
              >
                your private channels
              </span>
              <GideImage 
                style={{ marginLeft: '10px' }}
                src="/icons/nav/filter.svg"
                onClick={() => {
                  alert('coming soon');
                }}
                alt="filter"
              />
            </div>
            {/* CSS Centering Trick */}
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
          </div>
        </div>
        <ChannelList
          channels={
            this.props.channels
              ? this.props.channels.filter(c => { return (c.type === 'SPECIAL' || c.public === false) } )
              : undefined
          }
          loading={this.props.loading}
          onDeleteChannel={this.props.onDeleteChannel}
          currentUser={this.props.currentUser}
          onSelectChannel={this.props.onSelectChannel}
        />
        {/* Public Channels */}
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <div className="gideSectionTitleHeader">
              <span
                ref={ref => {
                  this.$channelsRef = ref;
                }}
              >
                newest to oldest
              </span>
              <GideImage 
                style={{ marginLeft: '10px' }}
                src="/icons/nav/sort.svg"
                onClick={() => {
                  alert('coming soon');
                }}
                alt="sort"
              />
            </div>
            {/* CSS Centering Trick */}
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
            <span className="emptyChannelTitleSpan" />
          </div>
        </div>
        <ChannelList
          channels={
            this.props.channels
              ? this.props.channels.filter(c => c.type !== 'SPECIAL' && c.public === true)
              : undefined
          }
          loading={this.props.loading}
          onDeleteChannel={this.props.onDeleteChannel}
          currentUser={this.props.currentUser}
          onSelectChannel={this.props.onSelectChannel}
        />

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <span
              className="gideSectionTitleHeader"
              ref={ref => {
                this.$businessRef = ref;
              }}
            >
              Businesses
            </span>
            {/* CSS Centering Trick */}
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
          </div>
        </div>
        <UserList users={users.filter(u => u.business && u.public)} />
        {/* <h2>Collections</h2> ?? Are these going away. Not in ZEPLIN design.*/}
        {/* <CollectionList collections={this.props.collections} center={false} />  */}

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <span
              className="gideSectionTitleHeader"
              ref={ref => {
                this.$peopleRef = ref;
              }}
            >
              People
            </span>
            {/* CSS Centering Trick */}
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
          </div>
        </div>
        <UserList users={users.filter(u => !u.business && u.public)} />
        {/* <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <span
              className="gideSectionTitleHeader"
              ref={ref => {
                this.$eventsRef = ref;
              }}
            >
              Events
            </span>
            {/ * CSS Centering Trick * /}
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
          </div>
        </div> */}

        {/* <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <span
              className="gideSectionTitleHeader"
              ref={ref => {
                this.$experienceRef = ref;
              }}
            >
              Experience
            </span>
            {/ * CSS Centering Trick * /}
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
          </div>
        </div> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
