import React from 'react';
import { Flag } from 'semantic-ui-react';

import Link from './Link';
import { urlForArticle } from '../utils/helperFunctions';
import GideImage from './Shared/Image/GideImage';

const ArticlePreview = ({ article }) => {
  return (
    <div className="articlePreview">
      <Link to={urlForArticle(article)}>
        <div className="articleLeft">
          <GideImage className="articleImage" src={article.image} alt={article.title} />
          <div className="locals">
            <Flag name="gb" />
          </div>
        </div>
        <div className="articleRight">
          <div className="articleInfo">
            <div className="articleHeader">
              {article.author &&
              <GideImage 
                src={article.author.image}
                alt={article.author.username}
                className="articleAuthorImage"
              />
              }
              {article.author &&
              <div className="articleAuthor">{article.author.username}</div>
              }
              <div className="articleDate">
                {new Date(article.createdAt).toDateString()}
              </div>
            </div>
            <div className="articleContent">
              <div className="articleTitle">{article.title}</div>
              <div>{article.description}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ArticlePreview;
