import React, { FunctionComponent } from 'react';
import GideImage from '../Image/GideImage';
import styles from './_article-slide-reference-header.module.scss';

export interface ArticleSlideReferenceHeaderProps {
  articleAuthorImageUrl: string;
  articleAuthor: string;
  articleImage?: string;
  articleTitle: string;
  numberOfSlides?: number;
  createdAt?: Date;
}
export const ArticleSlideReferenceHeader: FunctionComponent<ArticleSlideReferenceHeaderProps> = (
  props: ArticleSlideReferenceHeaderProps,
) => {
  const newCreateDate = props.createdAt && new Date(props.createdAt);
  const createDate = newCreateDate && `${(newCreateDate as Date).toLocaleDateString('en-US', { month: 'short' })} '${(newCreateDate as Date).toLocaleDateString('en-US', {
    year: '2-digit',
  })}`;
  return (
    <div className={styles.articleSlideReferenceHeaderContainer}>
      <GideImage
        className={styles.articleHeaderSlideReferenceGideImage}
        src={
          props.articleImage ? props.articleImage : 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/7402810-visual-story-telling.png'
        }
        alt={
          props.articleImage ? props.articleImage : 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/7402810-visual-story-telling.png'
        }
      />
      <div className={styles.articleSlideReferenceHeaderGideDetails}>
        <div className={styles.articleSlideReferenceHeaderGideTitleDetails}>
          {props.articleTitle}
          <div className={styles.articleSlideReferenceHeaderGideDetail}>
            <div>{`${props.numberOfSlides} slides`}</div>
            <div>{createDate}</div>
          </div>
        </div>
        <GideImage
          className={styles.articleHeaderSlideReferenceAuthorImage}
          src={props.articleAuthorImageUrl}
          alt={props.articleAuthorImageUrl}
        />
      </div>
    </div>
  );
};
