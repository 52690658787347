import React, { CSSProperties } from 'react';
import { TextArea, TextAreaProps } from 'semantic-ui-react';
import { CircleIconButton } from '../CircleIconButton/CircleIconButton';
import { isNullOrUndefined } from 'util';
import GideImage from '../Image/GideImage';

export interface SearchBarProps {
  placeholder?: string;
  numberOfSearchElements?: number;
  style?: CSSProperties;
  onSearchTextChange: (searchText?: string) => void;
  onExitSearch: () => void;
  onMoveToNextSearchText: () => void;
  onMoveToPrevSearchText: () => void;
}

export default function SearchBar(props: SearchBarProps) {
  const onSearchTextInputChanged = (event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
    props.onSearchTextChange(data.value ? data.value.toString() : '');
  };

  return (
    <div className="viewBarSearchContainer" style={props.style}>
      <TextArea
        className="viewBarSearchInput"
        onChange={onSearchTextInputChanged}
        placeholder={props.placeholder ? props.placeholder : 'Search the gide'}
        autoFocus
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            e.target.focus();
          }
        }}
      />
      <div className={'viewBarRight'}>
        <div className={`viewBarSearchNav ${!isNullOrUndefined(props.numberOfSearchElements) && props.numberOfSearchElements > 0 ? '' : 'hide'}`}>
          {/* <div>{`found: ${props.numberOfSearchElements}`}</div> */}
          <GideImage src="/icons/nav/arrow/arrow-left.svg" className="arrowRight" onClick={props.onMoveToPrevSearchText} alt="previous" style={{ marginRight: "12px" }} />
          <GideImage src="/icons/nav/arrow/arrow-right.svg" className="arrowRight" onClick={props.onMoveToNextSearchText} alt="next" />
        </div>
        <CircleIconButton
          className="viewBarSearchExitIcon"
          alt="exit"
          onClick={props.onExitSearch}
          iconCssClass="whites-normal-1000-svg"
          backgroundColor="var(--COLOR-SECONDARY-600)"
          image="/icons/nav/exit/main.svg"
          width={17}
          height={18}
          iconWidth={17}
          iconHeight={18}
        />
      </div>
    </div>
  );
}
