import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import agent from '../../agent';
import ArticleList from '../ArticleList';

class BlogSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      channel: null,
    };

    this.load = this._load.bind(this);
  }

  async _load() {
    try {
      let resp = await agent.Channels.getContent(this.props.slide.data.channel.slug);
      this.setState({ channel: resp, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      alert('Sorry, please try again later.');
    }
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { loading, channel } = this.state;

    return (
      <div className="channelSlide">
        {loading && <Loader active inline="centered" />}
        {channel && <ArticleList articles={channel.content.articles} layout='BLOG' center={true} addHorizontalRule={true} />}
      </div>
    );
  }
}
export default BlogSlide;
