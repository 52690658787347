import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Image, Button, Icon } from 'semantic-ui-react';
import slideTools from '../../slideTools';

import {
  MODAL_OPEN,
  MODAL_CLOSE,
  FOCUS_INPUT,
  SET_SLIDE_TYPE,
  UPDATE_POINTS,
  INPUT_FOCUSED,
} from '../../constants/actionTypes';
import isNil from 'ramda/es/isNil';

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  slideNumber: state.common.slideNumber,
});

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload: payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  focusInput: () => dispatch({ type: FOCUS_INPUT }),
  setInputFocused: () => dispatch({ type: INPUT_FOCUSED }), // TODO: Discuss with Grant. Currently there is focusInput (no longer used and possibly not needed).
  setSlideType: payload => dispatch({ type: SET_SLIDE_TYPE, payload }),
  resetPoints: () => dispatch({ type: UPDATE_POINTS }),
});

const style = {
  background: 'rgb(227, 99, 100)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export class ChoicesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: true,
    };

    this.getText = () => {
      let text = '';
      switch (this.props.createType) {
        case 'MAGNASLIDE':
          text = 'Magnaslide';
          break;
        case 'NOTE':
          text = 'Note';
          break;
        default:
          text = 'Slide';
      }
      return text;
    };

    this.onTextTypeSelect = () => {
      this.props.focusInput();
      this.props.closeModal();
    };

    this.onHeaderTypeSelect = () => {
      this.props.focusInput();
      this.props.closeModal();
      this.props.setSlideType({ slideType: 'HEADER' });
    };

    this.getPrecedingColumnSlide = () => {
      const { slides } = this.props;
      if (!slides) return null;
      for (let i = slides.length - 1; i > 0; i--) {
        let s = slides[i];
        if (s.slideType === 'COLUMN') {
          return s;
        }
      }
    };

    this.openModal = this._openModal.bind(this);
    this.focusFooterForTextSlide = () => {
      this.props.setInputFocused();
      this.props.closeModal();
    };
  }

  _openModal(modalName, props) {
    const position = !isNil(this.props.currentSlidePosition)
      ? this.props.currentSlidePosition
      : !isNil(this.props.slideNumber)
        ? this.props.slideNumber // - 1
        : null;
    this.props.resetPoints();
    this.props.closeModal();
    if (props) {
      this.props.openModal({
        modalType: modalName,
        modalProps: {
          ...props,
          position,
        },
      });
    } else {
      this.props.openModal({
        modalType: modalName,
        modalProps: {
          position,
        },
      });
    }
  }

  render() {
    // const precedingColumnSlide = this.getPrecedingColumnSlide();
    const { showMore } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        size="tiny"
        dimmer="inverted"
        closeOnDocumentClick={true}
        open={true}
        className="choicesModal"
        closeOnDimmerClick={false}
      >
        <div className="modalHeader" style={style}>
          <span>Add {this.getText()}</span>
          <Button id="modalClose" icon onClick={this.props.closeModal}>
            <Icon name="close" />
          </Button>
        </div>
        <Modal.Content scrolling className="inner">
          <Modal.Description>
            <Grid className="choices">
              {/* Row 1 */}
              <Grid.Row columns={3}>
                <Grid.Column
                  onClick={() =>
                    this.openModal('TextModal', {
                      currentSlidePosition: this.props.currentSlidePosition,
                      onSubmitSlide: this.props.onSubmitSlide,
                      article: this.props.article,
                      position: this.props.position,
                    })
                  }
                  title="Rich Text"
                >
                  <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                  <p style={{ textAlign: 'center' }}>Rich Text</p>
                </Grid.Column>
                {/* Place Holder. This is going to be Regular Text */}
                <Grid.Column
                  onClick={this.focusFooterForTextSlide.bind(this)}
                  title="Normal Text"
                >
                  <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                  <p style={{ textAlign: 'center' }}>Text</p>
                </Grid.Column>
                <Grid.Column
                  onClick={() =>
                    this.openModal('InputModal', {
                      currentSlidePosition: this.props.currentSlidePosition,
                      onSubmitSlide: this.props.onSubmitSlide,
                      article: this.props.article,
                      position: this.props.position,
                    })
                  }
                  title="Require something from the viewer"
                >
                  <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-input.svg" />
                  <p style={{ textAlign: 'center' }}>Input</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              {/* Row 2 */}
              <Grid.Row columns={3}>
                <Grid.Column
                  onClick={() =>
                    this.openModal('DiagramModal', {
                      currentSlidePosition: this.props.currentSlidePosition,
                      onSubmitSlide: this.props.onSubmitSlide,
                      article: this.props.article,
                      position: this.props.position,
                    })
                  }
                  title="Create a diagram"
                >
                  <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-diagram.svg" />
                  <p style={{ textAlign: 'center' }}>Diagram</p>
                </Grid.Column>
                <Grid.Column
                  onClick={this.onHeaderTypeSelect}
                  title="Insert headers to separate or organize your content"
                >
                  <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                  <p style={{ textAlign: 'center' }}>Header</p>
                </Grid.Column>
                <Grid.Column
                  // onClick={() => this.openModal('ImageModal')}
                  onClick={() =>
                    this.openModal('ImageSlideEditor', {
                      currentUser: this.props.currentUser,
                      article: this.props.article,
                      position: this.props.currentSlidePosition,
                      onSubmitSlide: this.props.onSubmitSlide,
                    })
                  }
                  title="Upload or capture image(s)"
                >
                  <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                  <p style={{ textAlign: 'center' }}>Image</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {showMore && (
              <div>
                <Grid>
                  {/* Row 3 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        // this.openModal('AudioModal', {
                        //   currentSlidePosition: this.props.currentSlidePosition,
                        //   onSubmitSlide: this.props.onSubmitSlide,
                        // })
                        this.openModal('AudioSlideEditor', {
                          currentUser: this.props.currentUser,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                        })
                      }
                      title="Upload or record audio"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-audio.svg" />
                      <p style={{ textAlign: 'center' }}>Audio</p>
                    </Grid.Column>
                    <Grid.Column
                      //  onClick={() => this.openModal('VideoModal')}
                      onClick={() =>
                        this.openModal('VideoSlideEditor', {
                          currentUser: this.props.currentUser,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                        })
                      }
                      title="Upload or record video"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-video.svg" />
                      <p style={{ textAlign: 'center' }}>Video</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() => {
                        this.openModal('LocationModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        });
                      }}
                      title="Geotag a location"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-location.svg" />
                      <p style={{ textAlign: 'center' }}>Geotag</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 4 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('MapModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.position,
                        })
                      }
                      title="Create a map"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-maps.svg" />
                      <p style={{ textAlign: 'center' }}>Map</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('FileModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Add any file"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                      <p style={{ textAlign: 'center' }}>File</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('ChooseModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Give viewers a choice"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-choose.svg" />
                      <p style={{ textAlign: 'center' }}>Choose</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 5 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('AuthorizeModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Require authorization"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-authorize.svg" />
                      <p style={{ textAlign: 'center' }}>Authorize</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('ListModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Make a list"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-list.svg" />
                      <p style={{ textAlign: 'center' }}>List</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('ContactsModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Add contact(s)"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-contact.svg" />
                      <p style={{ textAlign: 'center' }}>Contacts</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 6 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('GideModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Reference a Gide"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-gide.svg" />
                      <p style={{ textAlign: 'center' }}>Gide</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('SlideModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Reference a slide from a Gide"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-slides.svg" />
                      <p style={{ textAlign: 'center' }}>Slide</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        // this.openModal('LinksModal', {
                        //   currentSlidePosition: this.props.currentSlidePosition,
                        //   onSubmitSlide: this.props.onSubmitSlide,
                        //   article: this.props.article,
                        //   position: this.props.currentSlidePosition,
                        // })
                        this.openModal('LinkSlideEditor', {
                          currentUser: this.props.currentUser,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                        })
                      }
                      title="Insert internet link(s)"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-hyperlink.svg" />
                      <p style={{ textAlign: 'center' }}>Link</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 7 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('ItemModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Display an item, such as an ingredient or part"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-item.svg" />
                      <p style={{ textAlign: 'center' }}>Item</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('LeadModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Request and Capture a contact from viewer(s)"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                      <p style={{ textAlign: 'center' }}>Lead</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('DoodleModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Make a doodle with shapes, images, and colors"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-doodle.svg" />
                      <p style={{ textAlign: 'center' }}>Doodle</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 8 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('SlideshowModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Display and change slides automatically"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
                      <p style={{ textAlign: 'center' }}>Slide Show</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() => alert('Coming Soon')}
                      title="Associate Slides for easy reference and styling"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-slides.svg" />
                      <p style={{ textAlign: 'center' }}>Group</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('SignatureModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Sign"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-doodle.svg" />
                      <p style={{ textAlign: 'center' }}>Signature</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 9 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('EmbedModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Embed Twitter, Facebook, Instagram, or YouTube"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                      <p style={{ textAlign: 'center' }}>Embed</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('ColumnModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Begin or End Column Layout"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                      <p style={{ textAlign: 'center' }}>Columns</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('BlogModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Display Gides in a blog type format"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                      <p style={{ textAlign: 'center' }}>Blog</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid>
                  {/* Row 10 */}
                  <Grid.Row columns={3}>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('ButtonModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Create a button"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                      <p style={{ textAlign: 'center' }}>Button</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal('CollapseModal', {
                          currentSlidePosition: this.props.currentSlidePosition,
                          onSubmitSlide: this.props.onSubmitSlide,
                          article: this.props.article,
                          position: this.props.currentSlidePosition,
                        })
                      }
                      title="Collapse Section"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                      <p style={{ textAlign: 'center' }}>Collapse</p>
                    </Grid.Column>
                    <Grid.Column
                      onClick={() =>
                        this.openModal(
                          'ImageGallerySlideEditor',
                          {
                            currentUser: this.props.currentUser,
                            article: this.props.article,
                            slidePosition: this.props.currentSlidePosition,
                          },
                        )
                      }
                      title="Image Gallery"
                    >
                      <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
                      <p style={{ textAlign: 'center' }}>Image Gallery</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
            <p className="showMoreLessOptions">
              {showMore ? (
                <span
                  className="link"
                  onClick={() => this.setState({ showMore: false })}
                >
                  Less
                </span>
              ) : (
                  <span
                    className="link"
                    onClick={() => this.setState({ showMore: true })}
                  >
                    More
                </span>
                )}
            </p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoicesModal);
