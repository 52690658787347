import React, { useState, ReactNode } from 'react';
import { Slide } from '../../../models/Slide';
import styles  from './_slide-transfer-gide-selector.module.scss';
import { SlideView } from '../../Gide/SlideView/SlideView';
import { User } from '../../../models/User';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import classNames from 'classnames';
import { NotificationType } from '../../../constants/strings';
import { GideSearchElement } from '../../GideElementSearch';
import { Input, InputOnChangeData } from 'semantic-ui-react';
import { MultiSlideSelectionDetail } from '../../../reducers/article';
import SlideChooser, { SlideSelection } from '../../Shared/SlideChooser/SlideChooser';
import { hasValue } from '../../../utils/helperFunctions';
import GideImage from '../../Shared/Image/GideImage';
import GideChooser from '../../Shared/GideChooser/GideChooser';
import agent from '../../../agent';
import { ItemSelectionMode } from '../../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';

export enum SlideTransferMode {
  TransferToAnotherGide = 1,
  CopyWithinTheSameGide = 2,
  AddAsAttachmentToSlide = 3,
}
export interface SlideTransferSelectionInfo {
  createNewGide: boolean | undefined;
  selectedGide: GideSearchElement | undefined;
}
export interface SlideTransferGideSelectorProps {
  slides: Slide[];
  selectionSlides?: Slide[];
  currentUser: User;
  showAsModal?: boolean;
  mode: SlideTransferMode;
  multiSlideSelectionDetail?: MultiSlideSelectionDetail;
  slideSelectionIcon?: ReactNode;
  slideSelectionLabel: string;

  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  articleSelected?: (articleId: string) => void;
  onSelectionMade?: (gideInfo: SlideTransferSelectionInfo) => void;
  onSlidePositionAccepted?: (slidePosition: number) => void;
  onSlideSelected?: (slide: Slide) => void;
  onCancel?: () => void;
}

export default function SlideTransferGideSelector(props: SlideTransferGideSelectorProps) {
  const [selectedGide, setSelectedGide] = useState<GideSearchElement | undefined>(undefined);
  const [createNewGide, setCreateNewGide] = useState(false);
  const [slidePosition, setSlidePosition] = useState('');
  return (
    <div className={classNames(styles.transferSlidesModalContainer, props.showAsModal ? styles.showAsModal : undefined)}>
      <div className={styles.transferSlidesModal}>
        <div
          className={
            props.mode === SlideTransferMode.TransferToAnotherGide
              ? styles.selectedSlidesForTransfer
              : props.mode === SlideTransferMode.AddAsAttachmentToSlide
              ? styles.selectedSlidesForMoveToAttachments
              : styles.selectedSlidesForCopy
          }
        >
          {props.slides.map((slide, index) => (
            <SlideView
              key={index}
              slide={slide}
              currentUser={props.currentUser}
              disableInlineTextEditing={true}
              slidePosition={index}
              showAuthorHeader={false}
              view={'SCROLL'}
              viewMode="SCROLL"
              collapsed={false}
            />
          ))}
        </div>

        {props.mode === SlideTransferMode.TransferToAnotherGide && (
          <>
            <div className={styles.centerContent}>
              <RoundedCornerButton
                label="Done"
                style={{
                  height: '32px',
                  marginTop: '7px',
                  marginBottom: '7px',
                  width: '93px',
                  backgroundImage: 'linear-gradient(293deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                }}
                onClick={() => {
                  if (selectedGide || createNewGide) {
                    props.onSelectionMade && props.onSelectionMade({ createNewGide, selectedGide });
                  } else {
                    props.showNotification({
                      message: 'You must select a gide',
                      type: NotificationType.INFO,
                    });
                  }
                }}
                disabled={!hasValue(selectedGide) && createNewGide === false}
                iconCssClass="whites-normal-1000-svg"
                image="/icons/content-alteration/check/main.svg"
                imagePosition="right"
                fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
              />
            </div>
            <GideChooser
              agent={agent}
              onSelectGide={(gideSummary: GideSearchElement) => {
                setCreateNewGide(false);
                setSelectedGide(gideSummary);
              }}
              selectionMode={ItemSelectionMode.SingleDisplayNone}
            />
          </>
        )}
        {props.mode === SlideTransferMode.CopyWithinTheSameGide && (
          <div className={styles.messageDialogContainer}>
            <div className={styles.centerModalPointerTriangle} />
            <div className={styles.messageDialog}>
              <span className={styles.messageDialogTitle}>
                Enter slide number to place copy<span className="TEXTSUBTITLEorangecenter">*</span>
              </span>
              <div className={styles.slidePositionContainer}>
                <Input
                  type="text"
                  value={slidePosition}
                  placeholder="copy to position"
                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                    setSlidePosition(data.value);
                  }}
                />
              </div>
              <div className={styles.messageDialogActions}>
                <div
                  className={classNames(styles.messageDialogAction, styles.cancelButton)}
                  onClick={() => props.onCancel && props.onCancel()}
                >
                  <span>Cancel</span>
                </div>

                <div
                  className={classNames(styles.messageDialogAction, styles.doneButton)}
                  onClick={() => {
                    const position = parseInt(slidePosition, 10);
                    if (isNaN(position)) {
                      props.showNotification({
                        message: 'You must enter a number',
                        type: NotificationType.INFO,
                      });
                    } else {
                      props.onSlidePositionAccepted && props.onSlidePositionAccepted(position);
                    }
                  }}
                >
                  <span>Done</span>
                  <GideImage className="color-secondary-300-svg" src="/icons/content-alteration/copy.svg" alt="Done" />
                </div>
              </div>
            </div>
          </div>
        )}
        {props.mode === SlideTransferMode.AddAsAttachmentToSlide && props.selectionSlides && (
          <div className={styles.slideSelection}>
            <SlideChooser
              currentUser={props.currentUser}
              slides={props.selectionSlides}
              showNotification={props.showNotification}
              onSelectSlide={(slideSelectionInfo: SlideSelection) =>
                props.onSlideSelected && props.onSlideSelected(slideSelectionInfo.slide)
              }
              slideSelectionIcon={props.slideSelectionIcon}
              slideSelectionLabel={props.slideSelectionLabel}
              agent={agent}
            />
          </div>
        )}
      </div>
    </div>
  );
}
