import React, { Component } from 'react';
import { Modal, Button, Input } from 'semantic-ui-react';

export class SlideNumberModal extends Component {
  constructor(props) {
    super(props);
    this.state = { slideNumber: props.slideNumber };
  }
  render() {
    const {
      closeModal,
      updateSlideNumber,
      scrollToSlidePosition,
      lastPosition,
    } = this.props;
    return (
      <Modal
        style={{ width: '300px' }}
        closeOnEscape={true}
        onClose={closeModal}
        className="slideModal"
        size="tiny"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content className="preventSemanticActionStyle">
          <Modal.Description
            className="headerLevel3 preventSemanticActionStyle"
            style={{ padding: '10px' }}
          >
            <span>
              <strong>Select Slide Number</strong>
            </span>
          </Modal.Description>
          <fieldset className="form-group">
            <Input
              min={1}
              max={lastPosition}
              className="form-control"
              type="number"
              placeholder="Slide Number"
              value={this.state.slideNumber}
              onChange={ev => {
                this.setState({ slideNumber: ev.target.value });
              }}
            />
          </fieldset>
        </Modal.Content>
        <Modal.Actions className="preventSemanticActionStyle">
          <div className="flexRowSpaceBetween">
            <Button
              color="blue"
              content="Set to End"
              onClick={() => {
                updateSlideNumber(lastPosition - 1);
                scrollToSlidePosition(lastPosition - 1);
                closeModal();
              }}
            />
            <Button
              primary
              content="Save"
              onClick={() => {
                updateSlideNumber(this.state.slideNumber - 1);
                scrollToSlidePosition(this.state.slideNumber - 1);
                closeModal();
              }}
            />
            <Button onClick={closeModal}>Cancel</Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default SlideNumberModal;
