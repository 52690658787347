// **** Image **** 

export enum ImageEditType {
  Crop = 1,
  Draw = 2,
  GeoLocation = 3,
  Text = 4,
  Sticker = 5,
  Credit = 6,
  Delete = 7,
  Touch = 8,
  HashTag = 9,
  Selection = 10,
}

export enum ImageViewerMode {
  Carousel = 1,  
  Scroll = 2, // Stacked
  Gallery = 3, // Swipe
}

export enum ImageObjectFit {
  AutoCrop = 1,
  FullImage = 2,
}

// **** Video **** 

export enum VideoViewerMode {
  Stacked = 1,
  Gallery = 2,
  Carousel = 3,
}
export enum VideoEditType {
  Trim = 1,
  Draw = 2,
  GeoLocation = 3,
  Text = 4,
  Credit = 5,
  Delete = 6,
  Selection = 7,
}
export enum VideoAddType {
  VideoCamera = 1,
  Upload = 2,
  Link = 3,
  Slide = 4,
}

// **** Link **** 

export enum LinkViewerMode {
  Stacked = 1,
  Carousel = 2,
}
export enum LinkEditType {
  Trim = 1,
  Selection = 2,
}
export enum LinkAddType {
  Url = 1,
  Gide = 2,
  Channel = 3,
  Slide = 4,
}
export enum ArticlePreviewLayout {
  BlogImageTop = 1,
  BlogImageLeft = 2,
  Tile = 3,
}
export enum HyperLinkLayout {
  BlogImageTop = 1,
  BlogImageLeft = 2,
  HyperLink = 3,
}
export type LinkLayout = ArticlePreviewLayout | HyperLinkLayout;

