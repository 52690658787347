import { CommonContext, CommonModes } from "./Common";
import { getCreateActionsForImage, getImagePreviewItems } from "./SharedLogicForImageSlideEditor";
import { getCreateActionsForVideo, getVideoPreviewItems } from "./SharedLogicForVideoSlideEditor";
import { getCreateActionsForLink, getLinkPreviewItems } from "./SharedLogicForLinkSlideEditor";

export const getCreateActions = (context: CommonContext) => {  
  const { isImageSlide, isVideoSlide, isLinkSlide } = context;
  const createActions = 
    isImageSlide ? getCreateActionsForImage(context) :
    isVideoSlide ? getCreateActionsForVideo(context) :
    isLinkSlide ? getCreateActionsForLink(context) :
      "ERROR";
  return createActions;
}

export const getPreviewItems = (selectedItemIndex: number, context: CommonContext) => {
  const { isImageSlide, isVideoSlide, isLinkSlide } = context;
  const previewItems = 
    isImageSlide ? getImagePreviewItems(context, selectedItemIndex, setFileSelection) :
    isVideoSlide ? getVideoPreviewItems(context, selectedItemIndex, setFileSelection) :
    isLinkSlide ? getLinkPreviewItems(context, selectedItemIndex, setFileSelection) :
      "ERROR";
  return previewItems;
}

const setFileSelection = (selectedItemIndex: number, context: CommonContext) => {
  context.setState({
    ...context.state,
    mode: selectedItemIndex >= 0 ? CommonModes.Edit : CommonModes.Preview,
    selectedItemIndex,
  });
};
