import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, List, Button, Image, Grid, Icon } from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import { contentMetaData } from '../../constants/contentMetaData';
import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  SLIDE_SELECTED,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import GideImage from '../Shared/Image/GideImage';

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  ...state.articleList,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  onSlideSelected: payload => dispatch({ type: SLIDE_SELECTED, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class SlideModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: 'GRID',
      slide: {
        slideType: 'SLIDE',
        data: {
          audioCaption: null,
          caption: '',
          slide: null,
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.setBody = ev => {
      this.setState({ body: ev.target.value });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };

      if (!slide.data.slide) {
        return alert('No Slide selected.');
      }

      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.selectSlide = s => {
      let slide = Object.assign({}, this.state.slide);
      slide.data.slide = s;
      this.setState({ slide });
    };

    this.changeLayout = layout => {
      this.setState({ layout });
    };
  }

  render() {
    const { layout, slide } = this.state;
    const { slides } = this.props;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="slideModal"
        size="small"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(237, 168, 52)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Embed Slide</span>
            <Button.Group className="layoutBtns">
              <Button
                icon="block layout"
                onClick={() => this.changeLayout('GRID')}
              />
              <Button
                icon="list layout"
                onClick={() => this.changeLayout('LIST')}
              />
            </Button.Group>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-slides.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <section>
                {layout === 'LIST' && (
                  <List selection divided relaxed>
                    {slides.map((s, i) => (
                      <List.Item
                        key={i}
                        onClick={() => this.selectSlide(s)}
                        className={
                          slide.data.slide
                            ? slide.data.slide.id === s.id ? 'selected' : ''
                            : ''
                        }
                      >
                        <GideImage 
                          src={
                            contentMetaData[s.slideType]
                              ? contentMetaData[s.slideType].icon
                              : ''
                          }
                          className="icon sm"
                          alt={`□`}
                        />
                        <List.Content>
                          slide {i + 1}, type: {s.slideType}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                )}
                {layout === 'GRID' && (
                  <Grid columns={3} padded>
                    {slides.map((s, i) => (
                      <Grid.Column
                        style={{
                          background:
                            contentMetaData[slide.slideType].primaryColor,
                        }}
                        key={i}
                        onClick={() => this.selectSlide(s)}
                        className={
                          slide.data.slide
                            ? slide.data.slide.id === s.id ? 'selected' : ''
                            : ''
                        }
                      >
                        <span className="slideNumber">{i + 1}</span>
                        <GideImage 
                          src={contentMetaData[slide.slideType].icon}
                          className="icon sm"
                          alt="logo"
                        />
                      </Grid.Column>
                    ))}
                  </Grid>
                )}
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions
          style={{ background: contentMetaData['SLIDE'].primaryColor }}
        >
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideModal);
