import React, { ComponentType } from "react";
import { ThemeProvider } from 'styled-components';
// import theme from "styled-theming";


export const withNewTheme = (StyledComponent: ComponentType | any, theme: any) => (props: any) => (
  <ThemeProvider theme={theme}>
    <StyledComponent {...props} />
  </ThemeProvider>
);
export const darkTheme = {
  mode: 'dark'
};

export const lightTheme = {
  mode: 'light'
};

// export const backgroundColor = theme('mode', {
//   light: 'white',
//   dark: '#222'
// });

// const textColor = theme('mode', {
//   light: '#000',
//   dark: '#fff'
// });