/* eslint-disable no-undef */
/* Because the HOC brings in google obj but eslint doesn't know about it */
import React from 'react';
import { compose, withProps, withHandlers, lifecycle } from 'recompose';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  OverlayView,
} from "react-google-maps";

import { GOOGLE_MAPS_URL } from '../../constants/paths';

import './_Map.scss';

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
})

const MapWithAMarker = compose(  
  withProps(props => {
    return {
      googleMapURL: GOOGLE_MAPS_URL,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div className={`mapContainer${props.showTitle ? ' title' : ' noTitle'}`} />,
      mapElement: <div style={{ height: `100%` }} />,
    }
  }),
  withScriptjs,
  withGoogleMap,
  withHandlers(() => {

    return {
      onMapMounted: () => ref => {
        refs.map = ref
      },
    }
  }),
  lifecycle({
    componentWillMount() {
      this.showDirections = (props) => {
        const { showDirections, points } = props;
        if (showDirections && points.length >= 2) {
          const DirectionsService = new google.maps.DirectionsService();
          const waypoints = points.map(p => {
            return {
              location: new google.maps.LatLng({lat: p.lat, lng: p.lng}),
              stopover: true,
            }
          });
          const startPoint = waypoints.shift();
          const endPoint = waypoints.pop();
          DirectionsService.route({
            origin: startPoint.location,
            destination: endPoint.location,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              this.setState({
                directions: result,
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          });
        }
      }
      this.showDirections(this.props);
    },
    componentWillReceiveProps(props) {
      if (props.showDirections && props.points.length >= 2) {
        this.showDirections(props);
      } else {

      }
    }
  })
)(({ onPointClick, zoom=8, lat=1, lng=1, onDrag, onClick, showMarker=false, refMarker, refMap, points=[], showDirections=false, directions }) => (

      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={{ lat, lng }}
        onDrag={onDrag}
        onClick={onClick}
        ref={refMap}
      >
        {showMarker && <Marker position={{ lat, lng }} ref={refMarker} />}
        {!showDirections && points.map((p, i) => {
          const { lat, lng } = p;
          return (
            <OverlayView
              key={i}
              position={{ lat, lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <div className='overlay'>
                <div onClick={() => onPointClick(p, i)} className={'point ' + (p.selected ? 'selected' : '')}>
                  <div className='number'>
                    {i + 1}
                  </div>
                </div>
                {p.selected &&
                <div className='label'>
                  {p.label}
                </div>
                }
              </div>

            </OverlayView>
          );
        })}
        {showDirections && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    )
);

export default MapWithAMarker;
