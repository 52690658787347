import React from 'react';
import { RoundedCornerButton } from '../Shared/RoundedCornerButton/RoundedCornerButton';
import { history } from '../../store';
import styles from './_NotFound.module.scss';
import icons from '../../assets/icons';

export const NotFound: React.FC = (props) => {
  const navigateHome = () => history.push('/');
  return (
    <div className={styles.page}>
      
      <div className={styles.card}>
        <icons.Nav_Logo_LogoColorEdit 
          className={styles.gidesLogo}
          width="81"
          height="35"
          color="white"
          onClick={navigateHome}
        />
        
        <div className={styles.monkeyContainer}>          
          <span className={styles.bigDot}></span>
          <span className={styles.littleDot}></span>
        </div>
        <div className={styles.monkeyBackground}></div>
        <div className={styles.monkey}></div>

        <div className={styles.title}>
          Bah! Space monkeys!
        </div>

        <div className={styles.description}>
          This Gide has been stolen by those pesky space monkeys again, we can neither confirm nor deny that there is a plan in place to build a rocket ship to Quantar to retrieve this stolen content, if we return with this glorious content we will let you know…. maybe.
        </div>

        <div className={styles.buttonContainer}>
          <RoundedCornerButton
            label="Take me back"
            className={styles.button}
            onClick={navigateHome}
          />
        </div>
      </div>
    </div>
  );
}
