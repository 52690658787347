import React, { CSSProperties } from 'react';
import styles from './_gide-blog-image-left-preview.module.scss';
import GideImage from '../../../Image/GideImage';
import icons from '../../../../../assets/icons';
import Avatar from '../../../Avatar/Avatar';
import classNames from 'classnames';
import flagDictionary from '../../../../../assets/icons/flag-lookup';
export interface GideBlogImageLeftPreviewProps {
  image: string;
  url: string;
  author: string;
  authorImage: string;
  views?: number;
  title?: string;
  description?: string;
  userLanguage: string;
  createdAt: string;
  style?: CSSProperties;
}

export function GideBlogImageLeftPreview(props: GideBlogImageLeftPreviewProps) {
  return (
    <div
          className={classNames(styles.gideBlogImageLeftPreviewContainer)}
          style={props.style}
        >
          <div className={styles.image}>
            <GideImage src={props.image} alt={props.title} />
          </div>
          <div className={styles.gidesLogoContainer}>
              <icons.Nav_Logo_LogoIconSm 
                color='var(--WHITES-NORMAL-1000)'
              />
          </div>
          {flagDictionary[props.userLanguage] && (            
            <GideImage className={styles.userLanguageFlag} src={flagDictionary[props.userLanguage]}
              alt={`${props.userLanguage} flag`}
            />
          )}
          <div className={styles.details}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.description}>{props.description}</div>
            <div className={styles.footerInfo}>
              <Avatar
                avatarImage={props.authorImage}
                imgStyle={{ width: '24px', height: '24px' }}
                alt={props.author}
                displayOuterBorder={false}
                outerBorderColor="var(--COLOR-PRIMARY-700)"
              />
              <span
                className="TEXTSUBTITLE-2blackhigh-emphasisleft"
                style={{ marginLeft: '5px' }}
              >
                {props.author}
              </span>
              <span
                className="TEXTSUBTITLE-2blackmedium-emphasisleft"
                style={{ marginLeft: '5px' }}
              >
                {`3.2 stars • ${props.views ? props.views : 0} views • Created ${props.createdAt} ago`}
              </span>
            </div>
          </div>
        </div>
  );
}
