import React from 'react';
import { connect } from 'react-redux';
import { Loader, Button } from 'semantic-ui-react';

import Article from './Article';
import '../lib/Datetime/_Datetime.scss';
import agent from '../agent';
import {
  ADD_TAG,
  ADD_USER,
  ADD_USERS,
  ADD_VIEWERS,
  EDITOR_PAGE_LOADED,
  REMOVE_TAG,
  REMOVE_USER,
  REMOVE_VIEWER,
  ARTICLE_SUBMITTED,
  EDITOR_PAGE_UNLOADED,
  UPDATE_FIELD_EDITOR,
  USERS_LOADED,
  MODAL_OPEN,
  BEGIN_ADD_SETTING_ON_SLIDE,
} from '../constants/actionTypes';
import { history } from '../store';

const mapStateToProps = state => ({
  ...state.editor,
  users: state.common.users,
  channels: state.common.meta.channels,
});

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  beginAddSettingOnSlide: payload =>
    dispatch({ type: BEGIN_ADD_SETTING_ON_SLIDE, payload }),
  onAddTag: () => dispatch({ type: ADD_TAG }),
  onAddUser: () => dispatch({ type: ADD_USER }),
  onAddUsers: users => dispatch({ type: ADD_USERS, users }),
  onAddViewers: users => dispatch({ type: ADD_VIEWERS, users }),
  onLoad: payload => dispatch({ type: EDITOR_PAGE_LOADED, payload }),
  onRemoveTag: tag => dispatch({ type: REMOVE_TAG, payload: {tag} }),
  onRemoveUser: user => dispatch({ type: REMOVE_USER, user }),
  onRemoveViewer: user => dispatch({ type: REMOVE_VIEWER, user }),
  onSubmit: payload => {
    dispatch({ type: ARTICLE_SUBMITTED, payload });
  },
  onUnload: payload => dispatch({ type: EDITOR_PAGE_UNLOADED }),
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_EDITOR, payload: {key, value} }),
  onUsersLoaded: payload => dispatch({ type: USERS_LOADED, payload }),
});

class EditSlide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.changeDefaultView = (ev, data) => {
      this.props.onUpdateField('defaultView', data.value);
    };

    this.changeDate = moment => {
      let date = '';
      if (moment) {
        date = moment.toDate();
      }
      this.props.onUpdateField('date', date);
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, {
        [field]: ev.target.value,
      });
      this.setState(newState);
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: data.checked });
      this.setState(newState);
    };

    this.submitForm = ev => {
      ev.preventDefault();
      const slide = {
        id: this.state._id,
        link: this.state.link,
        linkUrl: this.state.linkUrl,
        backgroundColor: this.state.backgroundColor,
        fontColor: this.state.fontColor,
        font: this.state.font,
        allowLink: this.state.allowLink,
        allowEmbed: this.state.allowEmbed,
        requireAttribution: this.state.requireAttribution,
      };
      agent.Slides.update(slide)
        .then(resp => {
          if (resp.slide) {
            alert('Updated.');
          } else {
            alert('Please try again.');
          }
        })
        .catch(err => {
          alert('Please try again.');
        });
    };
    this.addOther = this._addOther.bind(this);
    this.load = this._load.bind(this);
  }

  _addOther(ev) {
    ev.preventDefault();

    this.props.beginAddSettingOnSlide({
      mode: 'SETTING',
      slide: this.state._id,
    });
    let modalType = '';
    let modalProps = {};
    modalType = 'ChoicesModal';
    modalProps = {
      createType: 'SETTING',
    };
    this.props.openModal({
      modalType,
      modalProps,
    });
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.match.params.slug !== nextProps.match.params.slug) {
    //   if (nextProps.match.params.slug) {
    //     this.props.onUnload();
    //     return this.props.onLoad(agent.Articles.get(this.props.match.params.slug));
    //   }
    //   this.props.onLoad(null);
    // }
  }

  async _load() {
    try {
      let resp = await agent.Slides.get(this.props.match.params.id);
      this.setState({ ...resp.slide, slide: resp.slide, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  componentWillMount() {
    this.load();
    // this.props.onUsersLoaded(agent.Users.getAll());
    // if (this.props.match.params.slug) {
    //   return this.props.onLoad(
    //     agent.Articles.get(this.props.match.params.slug),
    //   );
    // }
    // this.props.onLoad(null);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { loading, slide } = this.state;
    if (loading) {
      return <Loader active inline="centered" />;
    }
    return (
      <div className="editor-page slideSettings">
        <div className="container page">
          <div className="row slideSettingsRow">
            <div className="col-xs-12 slideSettingsContent">
              <h1 className="center" style={{ margin: '10px 0' }}>
                Slide Attachments
              </h1>
              <Button
                basic
                circular
                style={{ width: '70px', margin: '20px auto' }}
                onClick={history.goBack}
              >
                Back
              </Button>
              <p style={{ textAlign: 'center' }}>
                Attach using the input bar as usual.
              </p>
              {slide && <Article type="SETTINGS" slide={slide.id} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSlide);
