import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { API_ROOT } from './constants/paths';
import { GIDES_URL_PREFIX } from './utils/helperFunctions';
import { decodeAuthToken } from './utils/authorization';
import { history } from './store';

const superagent = superagentPromise(_superagent, global.Promise);
const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
};

const navigate = (path) => {
  history.push(path);
}

const handleAuthorizationError = (error) => {
  if (error.status === 401) {
    if (token) {
      const decodedToken = decodeAuthToken(token);
      const now = new Date();
      if (now >= decodedToken.expirationTime) {
        // Token is expired, so clear token and go to login page.
        window.localStorage.setItem('jwt', '');
        token = null;
        navigate('/login');
      } else {
        console.error(error);
        navigate('/');
      }
    } else {
      console.error(error);
      navigate('/');
    }
  }
  throw error;
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(handleAuthorizationError),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(handleAuthorizationError),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(handleAuthorizationError),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(handleAuthorizationError),
};

const Auth = {
  current: () => requests.get('/user'),
  currentSinceLastViewDate: (lastViewDate) => requests.get(lastViewDate ? '/user?lastViewDate='+lastViewDate : '/user'),
  login: (email, password) =>
    requests.post('/users/login', { user: { email, password } }),
  register: (username, email, password, accessCode) =>
    requests.post('/users', {
      user: { username, email, password },
      accessCode,
    }),
  save: user => requests.put('/user', { user }),
  config: () => requests.get('/user/config'),
};

const Tags = {
  getAll: () => requests.get('/tags'),
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;

const omitSlugChannel = channel =>
  Object.assign({}, channel, { slug: undefined });
const Articles = {
  copy: (articleId) => {
    return requests.post(`/articles/${articleId}/copy`);
  },
  all: page => requests.get(`/articles?${limit(10, page)}`),
  byAuthor: (author, page, lim) => {
    return requests.get(
      `/articles?author=${encode(author)}&${limit(lim || 5, page)}`,
    );
  },
  bySearch: q => requests.get(`/articles?${q}`),
  byTag: (tag, page) =>
    requests.get(`/articles?tag=${encode(tag)}&${limit(10, page)}`),
  forArticleByType: (articleId, type) => {
    let url = `/articles/${articleId}/articles?type=${type}`;
    return requests.get(url);
  },
  forSlideByType: (slideId, type) => {
    let url = `/articles/slides/${slideId}/articles?type=${type}`;
    return requests.get(url);
  },
  del: (article, options) => {
    return requests.del(
      `/articles/${article.id || article._id}?${
        options && options.full ? `full=true` : ``
      }`,
    )
  },
  expire: id => requests.put(`/articles/${id}/expire`),
  recover: article =>
    requests.put(`/articles/${article.id || article._id}/recover`),
  deleteSlides: (article, slideIds) =>
    requests.put(`/articles/${article.id}/deletemultipleslides`, { slideIds }),
  deleteOwnerSlideArticle: (articleId, ownerSlideId, options) =>
    requests.del(
      `/articles/${articleId}/${ownerSlideId}?${
        options && options.full ? `full=true` : ``
      }`,
    ),
  deleteInquiryResponseSlide: (article, slideId) =>
    requests.del(
      `/articles/${article.id ||
        article._id}/deleteInquiryResponseSlide?slideId=${slideId}`,
    ),
  favorite: article => requests.post(`/articles/${article.id}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
  feed: () =>
    token
      ? requests.get(`/articles?onlyAuthorizedGides=true&${limit(20)}`) // requests.get('/articles/feed?limit=20&offset=0')
      : requests.get(`/articles?onlyAuthorizedGides=true&${limit(20)}`),
  get: id => requests.get(`/articles/${id}`),
  getByUsernameAndSlug: (username, slug) =>
    requests.get(`/articles/username/${username}/slug/${slug}`),
  getType: (type, username) => {
    let url = `/articles/type/${type}?username=${username}`;
    return requests.get(url);
  },
  unfavorite: article => requests.del(`/articles/${article.id}/favorite`),
  unsubscribe: article => requests.del(`/articles/${article.id}/subscribe`),
  update: article => {
    return requests.put(`/articles/${article.id}`, { article });
  },
  create: article => requests.post('/articles', { article }),
  // Copies in a single article
  copySlides: (articleId, copyToPosition, slideIds) =>
    requests.post(`/articles/${articleId}/copyslides`, {
      copyToPosition,
      slideIds,
    }),
  // Moves in a single article
  moveSlides: (article, moveToPosition, slideIds) =>
    requests.put(`/articles/${article.id}/moveslides`, {
      moveToPosition,
      slideIds,
    }),
  updateTemplateSlides: (article, isTemplate) =>
    requests.put(`/articles/${article.id}/updatetemplateslides`, {
      isTemplate,
    }),
  updateCommentSlides: (article, allowComments) =>
    requests.put(`/articles/${article.id}/updatecommentslides`, {
      allowComments,
    }),
  updateQuestionSlides: (article, allowQuestions) =>
    requests.put(`/articles/${article.id}/updatequestionslides`, {
      allowQuestions,
    }),
  // Create copy of selected slides and then add them to the slide attachment (article) of the slide
  copySlidesToSlideAttachments: (slideId, slideIds) =>
    requests.post(`/articles/slides/${slideId}/copytoattachments`, {
      slideIds,
    }),
  // Copy attachment slide(s) to the article which owns the slide for an child slide
  copyToParentGide: (slideId, slideIds) =>
    requests.post(`/articles/slides/${slideId}/copytoparentarticle`, {
      slideIds,
    }),
  // The slides are being exported but then also imported into the selected article.

  moveSlidesToArticle: (moveFromArticleId, moveToArticleId, slideIds) =>
    requests.post(`/articles/slides/moveslidestoarticle`, {
      moveFromArticleId,
      moveToArticleId,
      slideIds,
    }),
  convertTextSlidesToSingleRichTextSlide: (articleId, slideIds) => {
    return requests.post(
      `/articles/${articleId}/convertTextSlidesToSingleRichTextSlide`,
      { slideIds },
    );
  },
  exportSlides: (articleId, slideIds) =>
    requests.post(`/articles/${articleId}/importslides`, { slideIds }),
  attend: (article, attendance) =>
    requests.post(`/articles/${article.id}/attend`, { attendance }),
  attendances: article => requests.get(`/articles/${article.id}/attendances`),
  removeView: view => requests.del(`/articles/views/${view._id}`),
  copyTemplate: article =>
    requests.post(`/articles/${article.id}/template/copy`),
  purchase: article => requests.post(`/articles/${article.id}/purchase`),
  refund: purchase =>
    requests.post(`/articles/purchases/${purchase._id}/refund`),
  emptyTrash: page => requests.del(`/articles/trash/empty`),
  updateArticleStatusOnArticle: (articleId, ownerArticleId, status) =>
    requests.put(
      `/articles/${articleId}/updateapprovalstatus/${ownerArticleId}?status=${status}`,
    ),
  updateArticleStatusOnSlide: (articleId, ownerSlideId, status) =>
    requests.put(
      `/articles/${articleId}/updateapprovalstatus/slides/${ownerSlideId}?status=${status}`,
    ),
};

const Channels = {
  all: () => requests.get(`/channels`),
  create: channel => requests.post('/channels', { channel }),
  createFolder: (channel, folder) =>
    requests.post(`/channels/${channel.slug}/folders`, { folder }),
  get: slug => requests.get(`/channels/${slug}`),
  getFolders: channel => requests.get(`/channels/${channel.slug}/folders`),
  getContent: slug => requests.get(`/channels/${slug}/content`),
  getWebsite: slug => requests.get(`/channels/${slug}/website`),
  del: channel => requests.del(`/channels/${channel.slug}`),
  byAuthor: (author, userIsAuthor) => {
    let url = `/channels?author=${encode(author)}`;
    if (userIsAuthor) url = `${url}&userIsAuthor=true`;
    return requests.get(url);
  },
  update: channel =>
    requests.put(`/channels/${channel.slug}`, {
      channel: omitSlugChannel(channel),
    }),
  follow: channel => requests.post(`/channels/${channel.slug}/follow`),
  unfollow: channel => requests.del(`/channels/${channel.slug}/follow`),
  join: channel => requests.post(`/channels/${channel.slug}/join`),
};

const Collections = {
  create: collection => requests.post('/collections', { collection }),
  remove: collection => requests.del(`/collections/${collection._id}`),
  landing: collection => requests.get(`/collections/landing`),
  addItem: (collection, article) =>
    requests.post(
      `/collections/${collection._id}/add/${article.id || article._id}`,
    ),
};

const Slides = {
  update: (id, slide) => requests.put(`/articles/slides/${id}`, { slide }),
  setSlideApproval: (slideId, ownerSlideId, articleId, approved) =>
    requests.put(
      `/articles/setslideapproval/${slideId}/${ownerSlideId}/${articleId}?approved=${approved}`,
    ),
  updateChecks: (id, checkedItems) =>
    requests.put(`/articles/slides/${id}/checks`, { checkedItems }),
  createNote: (article, slideId, note) =>
    requests.post(`/articles/${article.id}/slides/${slideId}/notes`, { note }),
  authorize: (id, authInfo) =>
    requests.post(`/articles/slides/${id}/authorize`, { authInfo }),
  expire: id => requests.post(`/articles/slides/${id}/expire`),
  unauthenticatedAuthorize: (id, authInfo) =>
    requests.post(`/articles/slides/${id}/unauthenticated/authorize`, {
      authInfo,
    }),
  createMagnaSlide: (article, slideId, slide) =>
    requests.post(`/articles/${article.id}/slides/${slideId}/slides`, {
      slide,
    }),
  create: (article, slide) => {
    if (slide.slide) {
      let url = `/articles/slide/${slide.slide}/slides`;
      return requests.post(url, { slide });
    }
    return requests.post(`/articles/${article.id}/slides`, { slide });
  },
  createSlideZero: (articleId, slideZeroInfo) => requests.post(`/articles/${articleId}/slidezero`, {slideZeroInfo: slideZeroInfo}),
  updateSlideZero: (articleId, slideZeroInfo) => requests.put(`/articles/${articleId}/slidezero/${slideZeroInfo.slide.id}`, {slideZeroInfo: slideZeroInfo}),
  createMultipleSlides: (article, slide) => {
    return requests.post(`/articles/${article.id}/multipleslides`, { slide });
  },
  replace: (article, replaceSlideId, slide) => {
    return requests.post(
      `/articles/${article.id}/slides/${replaceSlideId}/replace`,
      { slide },
    );
  },
  delete: (article, slideId) => {
    if (article.id) {
      return requests.del(`/articles/${article.id}/slides/${slideId}`);
    } else {
      return requests.del(`/articles/slides/${slideId}`);
    }
  },
  forArticle: article =>
    requests.get(`/articles/${article.id || article._id}/slides`),
  get: id => requests.get(`/articles/slides/${id}`),
  getSettings: (id, articleTypeName) => {
    return requests.get(
      `/articles/slides/${id}/settings?articleType=${articleTypeName}`,
    );
  },
  isUserAuthorized: (id, authInfo) => {
    return requests.post(`/articles/slides/${id}/isUserAuthorized`, {
      authInfo,
    });
  },
  getChecks: id => requests.get(`/articles/slides/${id}/checks`),
  getAttachmentDetails: id =>
    requests.get(`/articles/slides/${id}/attachmentdetails`),
  moveSlidesToChildArticle: (
    moveFromArticleId,
    slideId,
    childArticleType,
    slideIds,
  ) =>
    requests.post(`/articles/slides/${slideId}/moveslidestochildarticle`, {
      moveFromArticleId,
      childArticleType,
      slideIds,
    }),
  moveSlidesToParentArticle: (
    moveToArticleId,
    slideId,
    childArticleType,
    slideIds,
  ) =>
    requests.post(`/articles/slides/${slideId}/moveslidestoparentarticle`, {
      moveToArticleId,
      childArticleType,
      slideIds,
    }),

  createInquiryResponseSlide: (articleId, slide) =>
    requests.post(`/articles/${articleId}/slides/createinquiryresponseslide`, {
      slide,
    }),
};

const Comments = {
  create: (article, comment) =>
    requests.post(`/articles/${article.id}/comments`, { comment }),
  delete: (article, commentId) =>
    requests.del(`/articles/${article.id}/comments/${commentId}`),
  forArticle: article => requests.get(`/articles/${article.id}/comments`),
};

const Profile = {
  follow: username => requests.post(`/profiles/${username}/follow`),
  get: username => requests.get(`/profiles/${username}`),
  unfollow: username => requests.del(`/profiles/${username}/follow`),
};

const Users = {
  getAll: () => requests.get('/users'),
  getShortcuts: () => requests.get(`/user/shortcut`),
  getFavorites: () => requests.get(`/user/shortcut/favorites`),
  search: (elementType, searchCriteria) =>
    requests.post(`/user/search/${elementType.toLowerCase()}`, {
      searchCriteria
    }),
  gideSearch: (searchCriteria) =>
  requests.post(`/user/search/article`, {
    searchCriteria
  }),
  channelSearch: (searchCriteria) =>
  requests.post(`/user/search/channel`, {
    searchCriteria
  }),
  saveShortcuts: shortcuts => requests.post(`/user/shortcuts`, { shortcuts }),
  updateShortcut: shortcut => requests.put(`/user/shortcut`, shortcut),
  deleteShortcut: shortcutId => requests.del(`/user/shortcut/${shortcutId}`),
  getCollections: () => requests.get(`/user/collections`),
  contact: (message, toUsername, fromUsername) =>
    requests.post('/user/contact', {message, toUsername, fromUsername, clientUrlPrefix: GIDES_URL_PREFIX}),
};

const Account = {
  get: () => requests.get('/user/account'),
  recharge: info => requests.post('/user/account/recharge', { info }),
};

const Util = {
  getLinkPreview: url => requests.post('/util/linkpreview', { url: url }),
};
const Folders = {
  addArticle: (folder, article) =>
    requests.post(`/channels/folders/${folder._id}/article/${article._id}`),
};

export default {
  Articles,
  Auth,
  Channels,
  Comments,
  Slides,
  Profile,
  Tags,
  Users,
  Collections,
  Account,
  Util,
  Folders,
  setToken: _token => {
    token = _token;
  },
};
