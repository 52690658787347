import { AuthConfig } from './../../models/AuthConfig';
import { ToasterMessageInfo } from './../../models/ToasterMessageInfo';
import { Shortcut } from './../../components/AvatarMenu/AvatarMenu';
import { ROUTER_LOCATION_CHANGE, TOGGLE_ZOOM, EXIT_DISTRIBUTE_GIDE, DISTRIBUTE_GIDE, RENAVIGATE_TO_SAME_ARTICLE, CONFIG_LOADED } from './common.actions';
import { slideEditorModals } from '../../utils/helperFunctions';
import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  ARTICLE_SUBMITTED,
  ARTICLE_CREATED_NAVIGATE_TO_SETTINGS,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_ARTICLE,
  DELETE_CHANNEL,
  ARTICLE_PAGE_UNLOADED,
  ARTICLE_PAGE_ARTICLE_LOADED,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  HOME_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  LOGIN_PAGE_LOADED,
  REGISTER_PAGE_UNLOADED,
  SLIDE_SELECTED,
  SLIDE_CAPTURED,
  UPDATE_POINTS,
  UPDATE_SLIDE_INLINE_SELECTION,
  MAKE_INLINE_SELECTION_ON_SLIDE,
  CANCEL_MODE,
  SET_CREATE_MODE,
  SET_SLIDE_TYPE,
  USERS_LOADED,
  SET_VIEW_MODE,
  SET_CHROME_VISIBILITY,
  SET_OVERLAY_ITEM_VISIBILITY,
  BEGIN_ADD_SETTING_ON_SLIDE,
  SLIDE_PAGE_UNLOADED,
  DELETE_VIEW,
  ARTICLE_UPDATED,
  SEARCH_EXECUTED,
  VIEW_SLIDE,
  TOGGLE_LEFT_SIDEBAR,
  TOGGLE_RIGHT_SIDEBAR,
  EDIT_TEXT,
  ADD_SLIDE,
  DELETE_SLIDE,
  MANUAL_RETURN_MADE,
  SET_NEXT_VIEW_MODE,
  UPDATE_SLIDE_NUMBER,
  SET_SWIPE_SLIDE_POSITION,
  SET_LOADING,
  ARTICLE_PAGE_LOADED,
  SET_ZOOM,
  CHANNEL_SUBMITTED,
  COPY_ARTICLE,
  EXPAND_ALL_SLIDES,
  COLLAPSE_SLIDE_LIST,
  COLLAPSE_SLIDES,
  ADD_MULTIPLE_SLIDES,
  TOGGLE_SWIPE_OVERLAYS,
  MODAL_CLOSE,
  SET_INLINE_SLIDE_TEXT_EDIT_INFO,
  UPDATE_SLIDE_WITH_INLINE_EDITS,
  SET_OPEN_FOOTER_MENU,
  TOGGLE_GIDES_MENU,
  CLOSE_AVATAR_MENU,
  MODAL_OPEN,
  SET_TOASTER_MESSAGE,
  LOAD_USER_SHORTCUTS,
  LOAD_USER_FAVORITES,
  LOAD_GIDE_ELEMENT_SEARCH_RESULTS,
  CLEAR_GIDE_ELEMENT_SEARCH_RESULTS,
  ADD_USER_SHORTCUTS,
  DELETE_USER_SHORTCUT,
  UPDATE_USER_SHORTCUT,
  EXIT_CHILD_ARTICLE_EDITOR,
  ENTER_CHILD_ARTICLE_EDITOR,
  TOGGLE_ARTICLE_EDIT_MODE,
  TOGGLE_ARTICLE_LAYOUT_VIEW,
  ACCOUNT_RECHARGED,
  UPDATE_ACCOUNT_EDITOR,
  ACCOUNT_PAGE_LOADED,
  ACCOUNT_PAGE_UNLOADED,
  ARTICLES_LOADED,
  CHANNELS_LOADED,
  TOGGLE_WIDESCREEN_EDIT_MODE,
  RECOVER_ARTICLE,
  TOGGLE_COLLAPSED_HEADER_DISPLAY,
  TOGGLE_END_SLIDE_DISPLAY,
  ENTER_SLIDE_SELECTION_MODE,
  EXIT_SLIDE_SELECTION_MODE,
  ARTICLE_PAGE_SELECTED_FROM_SIDEBAR,
  LOCK_GIDE_INLINE_TEXT_EDIT,
  UNLOCK_GIDE_INLINE_TEXT_EDIT,
  HOME_PAGE_GIDES_LOADED,
  PROFILE_PAGE_LOADED,
  CHANNEL_PAGE_CONTENT_LOADED,
  REGISTER_PAGE_LOADED,
  SLIDE_ZERO_SUBMITTED,
  SIDEBAR_LOAD,
} from '../../constants/actionTypes';
import { merge } from 'lodash';
import { urlForArticle, getOverlayState } from '../../utils/helperFunctions';
import { InputBarOpenMenu } from '../../constants/strings';
import { reject, contains } from 'ramda';
import { ArticleLayoutView } from '../../models/ArticleLayoutEnum';
import { isNullOrUndefined } from 'util';
import { SlideSelectionOperation } from '../../models/SlideSelectionInfo';
import { GideAction } from '../../models/GideAction';
import { User } from '../../models/User';
import { Slide } from '../../models/Slide';
import { mergeAriaAttributeValues } from 'office-ui-fabric-react/lib/Utilities';
import produce from 'immer';
import * as R from 'ramda';
import { Meta } from '../../models/Meta';
import { COMPLETE_TITLE_SLIDE_PROCESSING } from '../FileProcessing/fileProcessing.actions';
// const EXCLUDE_VIEW_MODE_TYPES = ['MY', 'HI', 'CALENDER'];

export interface CommonState {
  appName: string;
  appLoaded: boolean;
  token: string | null | any;
  viewChangeCounter: number;
  points: any[];
  inlineToolbarPosition: any;
  showInlineToolbar: boolean;
  retainArticle: boolean;
  nextViewMode: string | null | any;
  showLeftSidebar: boolean;
  showRightSidebar: boolean;
  meta: Meta;
  slideType: string | null | any;
  manualReturnMade: boolean;
  slideNumber: number | null | any;
  loading: boolean;
  zoomed: boolean;
  showChrome: boolean;
  showCaptionPanel: boolean;
  showInfoPanel: boolean;
  toggleOverlayCount: number;
  collapsedWebsiteSlides: Slide[];
  slideRangeList: any[];
  displayGidesMenu: boolean;
  userFavoritesLoaded: boolean;
  userShortcuts: Shortcut[];
  userShortcutFavorites: any[];
  userChannelFavorites: any[];
  gideElementSearchResults: any[];
  articleEditMode: 'cleanEdit' | 'advancedEdit'; // cleanEdit or advancedEdit
  articleLayoutView: ArticleLayoutView;
  rechargeAmount: number;
  account: {
    transactions: any[];
    purchases: any[];
  };
  wideScreenEditModeEnabled: boolean;
  displayCollapsedHeaders: boolean;
  hideEndSlides: boolean;
  textSlideEditing: null;
  openMenu: InputBarOpenMenu;
  lockInlineTextEdit: boolean;
  isInInlineEditMode: boolean;
  currentUser: User | null;
  loadingUserHistory: boolean;
  slide: Slide | null;
  viewMode: null;
  isInCreationProcessModal: boolean;
  redirectTo: string | null | any;
  toasterMessageInfo: ToasterMessageInfo | null | any;
  users: any[];
  channels: any[];
  distributingGide: boolean;
  config?: AuthConfig;
  slideSelectionOperation?: SlideSelectionOperation;
}
const defaultState: CommonState = {
  appName: 'Gides',
  appLoaded: false,
  token: null,
  viewChangeCounter: 0,
  points: [] as any[],
  inlineToolbarPosition: {},
  showInlineToolbar: false,
  retainArticle: false,
  nextViewMode: null,
  showLeftSidebar: false,
  showRightSidebar: false,
  meta: {} as any,
  slideType: null,
  manualReturnMade: false,
  slideNumber: null,
  loading: true,
  zoomed: false,
  showChrome: true,
  showCaptionPanel: true,
  showInfoPanel: true,
  toggleOverlayCount: 0,
  collapsedWebsiteSlides: [] as any[],
  slideRangeList: [] as any[],
  displayGidesMenu: false,
  userFavoritesLoaded: false,
  userShortcuts: [] as any[],
  userShortcutFavorites: [] as any[],
  userChannelFavorites: [] as any[],
  gideElementSearchResults: [] as any[],
  articleEditMode: 'cleanEdit', // cleanEdit or advancedEdit
  articleLayoutView: ArticleLayoutView.Responsive,
  rechargeAmount: 0,
  account: {
    transactions: [] as any[],
    purchases: [] as any[],
  },
  wideScreenEditModeEnabled: false,
  displayCollapsedHeaders: true,
  hideEndSlides: false,
  textSlideEditing: null,
  openMenu: InputBarOpenMenu.NONE,
  lockInlineTextEdit: false,
  isInInlineEditMode: false,
  currentUser: null as User | null,
  loadingUserHistory: true,
  slide: null as Slide | null,
  viewMode: null,
  isInCreationProcessModal: false,
  redirectTo: null,
  toasterMessageInfo: null,
  users: [] as any[],
  channels: [] as any[],
  distributingGide: false,
};

export default (state = defaultState, action: GideAction) => {
  switch (action.type) {
    case UPDATE_ACCOUNT_EDITOR:
      return { ...state, [action.key]: action.value };
    case ACCOUNT_RECHARGED:
      return {
        ...state,
        currentUser: action.error ? null : action.payload.user,
        loading: false,
        rechargeAmount: 0,
      };
    case ACCOUNT_PAGE_LOADED:
      return {
        ...state,
        account: action.payload,
      };
    case ACCOUNT_PAGE_UNLOADED:
      return {
        ...state,
      };
    case TOGGLE_ZOOM:
      return {
        ...state,
        zoomed: !state.zoomed,
      };
    case SET_ZOOM:
      return {
        ...state,
        zoomed: action.zoom,
      };
    case ARTICLE_PAGE_LOADED:
      return {
        ...state,
        loading: false,
        zoomed: false,
      };
    // case HOME_PAGE_LOADED:
    case LOGIN_PAGE_LOADED:
    case REGISTER_PAGE_LOADED:
    case HOME_PAGE_GIDES_LOADED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading ? action.loading : true,
      };
    case SET_NEXT_VIEW_MODE:
      return {
        ...state,
        nextViewMode: action.mode,
      };
    case MANUAL_RETURN_MADE:
      return {
        ...state,
        manualReturnMade: true,
      };
    case UPDATE_SLIDE_NUMBER: {
      return {
        ...state,
        slideNumber: action.payload.slideNumber > -1 ? action.payload.slideNumber : null, // can reset to end by setting number to negative number
      };
    }
    case SET_SWIPE_SLIDE_POSITION: {
      return {
        ...state,
        swipeSlidePosition: action.payload.slidePosition,
      };
    }
    case ENTER_CHILD_ARTICLE_EDITOR: {
      return {
        ...state,
        isInInlineEditMode: true,
      };
    }
    case EXIT_CHILD_ARTICLE_EDITOR: {
      return {
        ...state,
        isInInlineEditMode: false,
      };
    }
    case ADD_SLIDE: {
      // Prevent state from being updated adding a slide when editing the gide that is owned by a slide.
      if (state.isInInlineEditMode) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        textSlideEditing: null,
        inlineSlideTextEditInfo: null,
        inlineEditedSlide: null,
        slideType: null,
        slideNumber: !state.textSlideEditing && !action.payload.isTextEdit && !isNullOrUndefined(action.payload.slide.position)
            ? action.payload.slide.position + 1
            : null,
        manualReturnMade: false,
        mode: null,
        slide: null,
        selection: null,
      };
    }
    case ADD_MULTIPLE_SLIDES: {
      if (state.isInInlineEditMode) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        textSlideEditing: null,
        inlineSlideTextEditInfo: null,
        inlineEditedSlide: null,
        slideType: null,
        slideNumber: action.payload.insertedPosition + action.payload.slidesToInsert.length + 1,
        manualReturnMade: false,
        mode: null,
        slide: null,
        selection: null,
      };
    }
    case DELETE_SLIDE: {
      return {
        ...state,
        slideNumber: state.isInInlineEditMode ? state.slideNumber : null,
      };
    }
    case ROUTER_LOCATION_CHANGE: {
      return {
        ...state,
        isInCreationProcessModal: action.payload.action === 'POP' ? false : state.isInCreationProcessModal,
      };
    }

    case MODAL_CLOSE: {
      return {
        ...state,
        textSlideEditing: null,
        inlineSlideTextEditInfo: null,
        inlineEditedSlide: null,
        isInCreationProcessModal: false,
      };
    }
    case MODAL_OPEN: {
      return {
        ...state,
        isInCreationProcessModal: contains(action.payload.modalType, slideEditorModals),
      };
    }
    case SET_INLINE_SLIDE_TEXT_EDIT_INFO: {
      return {
        ...state,
        inlineSlideTextEditInfo: action.payload ? action.payload.inlineSlideTextEditInfo : undefined,
        inlineEditedSlide: null,
      };
    }
    // The inlineEditedSlide is
    case UPDATE_SLIDE_WITH_INLINE_EDITS: {
      return {
        ...state,
        inlineEditedSlide: action.payload.inlineEditedSlide,
      };
    }
    case EDIT_TEXT: {
      return {
        ...state,
        textSlideEditing: action.slide,
      };
    }
    case VIEW_SLIDE: {
      return {
        ...state,
        slideNumberViewing: action.number,
      };
    }
    case TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        showLeftSidebar: !state.showLeftSidebar,
        showRightSidebar: false,
        displayGidesMenu: false,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        displayGidesMenu: action.payload.isGideMenu,
        showRightSidebar: !state.showRightSidebar,
        showLeftSidebar: false,
      };
    case ARTICLE_PAGE_ARTICLE_LOADED: {
      const { article } = action.payload;
      let viewMode: string | null = 'SLIDE';
      // Uncomment to Default to Slide view for author
      if (!state.currentUser || (state.currentUser && state.currentUser.username !== article.author.username)) {
        viewMode = 'SCROLL';
      }
      if (state.nextViewMode) {
        viewMode = state.nextViewMode;
      }
      // if (!EXCLUDE_VIEW_MODE_TYPES.includes(article.type)) {
      //   viewMode = article.defaultView;
      // }
      return {
        ...state,
        viewMode,
        nextViewMode: null,
        slideNumber: null,
        loading: false,
      };
    }
    case CHANNEL_PAGE_CONTENT_LOADED:
    case PROFILE_PAGE_LOADED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ARTICLE_PAGE_SELECTED_FROM_SIDEBAR: {
      var responsive = window.innerWidth < 1074;
      if (responsive) {
        return {
          ...state,
          openMenu: InputBarOpenMenu.NONE,
          showLeftSidebar: false,
          showRightSidebar: false,
        };
      } else {
        return {
          ...state,
          openMenu: InputBarOpenMenu.NONE,
        };
      }
    }
    case SLIDE_PAGE_UNLOADED:
      return {
        ...state,
      };
    case SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.mode,
        retainArticle: action.mode === 'SWIPE' ? true : false,
        showChrome: true,
        showCaptionPanel: true,
        showInfoPanel: true,
      };
    case SET_CHROME_VISIBILITY: {
      const overlayState = getOverlayState(
        action.payload.showChrome,
        action.payload.showCaptionPanel,
        action.payload.isEmbeddedGide,
        action.payload.slide,
        action.payload.slides,
      );

      return {
        ...state,
        //showChrome: action.payload.showChrome,
        ...overlayState,
      };
    }
    case SET_OVERLAY_ITEM_VISIBILITY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TOGGLE_SWIPE_OVERLAYS:
      return {
        ...state,
        toggleOverlayCount: state.toggleOverlayCount + 1,
      };
    case ARTICLES_LOADED:
      return {
        ...state,
        articles: action.payload.articles,
      };
    case USERS_LOADED:
      return {
        ...state,
        users: action.payload.users,
      };
    case CHANNELS_LOADED:
      return {
        ...state,
        channels: action.payload.channels,
      };
    case SET_CREATE_MODE:
      return {
        ...state,
        slide: action.payload.slide,
        mode: action.payload.mode,
      };
    case SET_SLIDE_TYPE:
      return {
        ...state,
        slideType: action.payload.slideType,
      };
    case CANCEL_MODE:
      return {
        ...state,
        mode: null,
      };
    case BEGIN_ADD_SETTING_ON_SLIDE:
      return {
        ...state,
        mode: action.payload.mode,
        slide: action.payload.slide,
      };
    case MAKE_INLINE_SELECTION_ON_SLIDE:
      return {
        ...state,
        mode: action.payload.mode,
        showInlineToolbar: false,
      };
    case UPDATE_SLIDE_INLINE_SELECTION:
      return {
        ...state,
        mode: null,
        inlineToolbarPosition: action.payload.position,
        showInlineToolbar: action.payload.show,
        selection: action.payload.selection,
        slide: action.payload.slide ? action.payload.slide : state.slide,
      };
    case UPDATE_POINTS:
      return {
        ...state,
        points: action.payload ? action.payload.points : [],
      };
    case SLIDE_SELECTED: {
      let points = state.points.slice();
      points.forEach(p => {
        if (p.selected) {
          p.slide = action.payload.slide;
        }
      });
      return {
        ...state,
        points,
        slide: action.payload ? action.payload.slide : null,
      };
    }
    case SLIDE_CAPTURED:
      return {
        ...state,
        slide: null,
      };
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null,
        meta: action.payload ? action.payload.meta : null,
        status: {},
        loadingUserHistory: action.payload ? false : state.loadingUserHistory,
      };
    case COMPLETE_TITLE_SLIDE_PROCESSING:
      return produce(state, state => {
        const updatedView = state.meta.views && state.meta.views.find(v => action.payload.titleSlideProcessorInfo.gideId === v.article.id);
        if (updatedView) {
          updatedView.article.image = action.payload.fileUrl;
        }
      });
    case SIDEBAR_LOAD:
      return produce(state, state => {
        const updatedViews = action.payload?.meta?.views ?? [];
        const oldViewsWithoutUpdatedRecords = state.meta ? R.reject(x => R.any(y => y.id === x.id, updatedViews), state.meta.views) : [];
        const newViews = [...updatedViews, ...oldViewsWithoutUpdatedRecords];
        if (state.meta) {
          state.meta.views = newViews;
        } else {
          state.meta = {
            views: newViews,
            channels: [],
            collections: [],
            interactions: [],
            notifications: [],
          };
        }
      });
    case LOAD_USER_SHORTCUTS:
      return {
        ...state,
        userShortcuts: action.payload.userShortcuts,
      };
    case LOAD_USER_FAVORITES:
      return {
        ...state,
        userFavoritesLoaded: true,
        userShortcutFavorites: action.payload.userShortcuts,
        userChannelFavorites: action.payload.userChannels,
      };
    case ADD_USER_SHORTCUTS: {
      return {
        ...state,
        userFavoritesLoaded: false,
        userShortcuts: [...state.userShortcuts, ...action.payload.shortcuts],
      };
    }
    case DELETE_USER_SHORTCUT: {
      return {
        ...state,
        userFavoritesLoaded: false,
        userShortcuts: reject(s => s.id === action.payload.shortcutId, state.userShortcuts),
      };
    }
    case UPDATE_USER_SHORTCUT: {
      return {
        ...state,
        userFavoritesLoaded: false,
        userShortcuts: state.userShortcuts.map(s => {
          const shortcut = action.payload.find((us: { shortcutId: string }) => s.id === us.shortcutId);
          if (shortcut) {
            return {
              ...s,
              sortIndex: shortcut.sortIndex,
            };
          } else {
            return s;
          }
        }),
      };
    }
    case LOAD_GIDE_ELEMENT_SEARCH_RESULTS:
      return {
        ...state,
        gideElementSearchResults: action.payload.searchResults,
      };
    case CLEAR_GIDE_ELEMENT_SEARCH_RESULTS:
      return {
        ...state,
        gideElementSearchResults: [],
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case ARTICLE_SUBMITTED: {
      const redirectUrl = urlForArticle(action.payload.article);
      return { ...state, redirectTo: redirectUrl };
    }
    case SLIDE_ZERO_SUBMITTED: {
      // return { ...state, redirectTo: action.payload.articleSlug };
      return produce(state, state => {
        const updatedView = state.meta.views && state.meta.views.find(v => action.payload.articleId === v.article.id);
        if (updatedView) {
          updatedView.article.slug = action.payload.articleSlug;
          updatedView.article.title = action.payload.slideZero.data.title;
        }
      });
    }
    case RENAVIGATE_TO_SAME_ARTICLE: {
      return {...state, ignoreArticleNavigation: action.payload.ignore}
    }
    case ARTICLE_CREATED_NAVIGATE_TO_SETTINGS: {
      const redirectUrl = `/editor/${action.payload.article.id}`;
      return { ...state, redirectTo: redirectUrl };
    }
    case ARTICLE_UPDATED: {
      let article = merge({}, action.payload.article);
      return {
        ...state,
        article,
      };
    }
    case SETTINGS_SAVED:
      return {
        ...state,
        currentUser: action.error ? null : action.payload.user,
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user,
      };
    case DELETE_VIEW: {
      let meta = merge({}, state.meta);
      meta.views = meta.views.filter((view: any) => view._id !== action.id);
      return {
        ...state,
        meta,
      };
    }
    case DELETE_ARTICLE:
      return {
        ...state,
        meta: {
          ...state.meta,
          views: reject((v: any) => v.article.id === action.payload.id, state.meta.views),
        },
        redirectTo: '/',
      };
    case DELETE_CHANNEL:
      return {
        ...state,
        redirectTo: `/@${state.currentUser && state.currentUser.username}/channels`,
      };
    case ARTICLE_PAGE_UNLOADED:
      return {
        ...state,
        viewMode: 'SLIDE',
        showRightSidebar: false,
        slideNumber: null,
        openMenu: InputBarOpenMenu.NONE,
      };
    case EDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    case SEARCH_EXECUTED:
      return {
        ...state,
        redirectTo: '/',
      };
    case COPY_ARTICLE: {
      const redirectUrl = `/editor/${action.payload.article.id}`;
      return { ...state, redirectTo: redirectUrl };
    }
    case EXPAND_ALL_SLIDES: {
      return {
        ...state,
        collapsedWebsiteSlides: [],
      };
    }
    case COLLAPSE_SLIDE_LIST: {
      const collapsedHeaderSlides = action.payload.slides.filter(
        (s: Slide) => s.slideType === 'HEADER' && s.data.type !== 'END' && s.data.level > 0,
      );
      return {
        ...state,
        collapsedWebsiteSlides: merge([], collapsedHeaderSlides || []),
      };
    }
    case TOGGLE_COLLAPSED_HEADER_DISPLAY: {
      return {
        ...state,
        displayCollapsedHeaders: !state.displayCollapsedHeaders,
      };
    }
    case TOGGLE_END_SLIDE_DISPLAY: {
      return {
        ...state,
        hideEndSlides: !state.hideEndSlides,
      };
    }
    case COLLAPSE_SLIDES: {
      const collapsedWebsiteSlides =
        !state.collapsedWebsiteSlides || state.collapsedWebsiteSlides.findIndex(s => s.id === action.slide.id) === -1
          ? (state.collapsedWebsiteSlides || []).concat(action.slide)
          : state.collapsedWebsiteSlides.filter(s => s.id !== action.slide.id);
      return {
        ...state,
        collapsedWebsiteSlides: collapsedWebsiteSlides,
      };
    }
    case SET_OPEN_FOOTER_MENU: {
      return {
        ...state,
        openMenu: action.payload.openMenu,
      };
    }
    case SET_TOASTER_MESSAGE: {
      return {
        ...state,
        toasterMessageInfo: action.payload.toasterMessageInfo || null,
      };
    }
    case TOGGLE_GIDES_MENU: {
      return {
        ...state,
        displayGidesMenu: true,
        showLeftSidebar: false,
        showRightSidebar: !state.showRightSidebar,
      };
    }
    case CLOSE_AVATAR_MENU: {
      return {
        ...state,
        displayGidesMenu: true,
        showLeftSidebar: false,
        showRightSidebar: false,
      };
    }
    case TOGGLE_ARTICLE_EDIT_MODE: {
      return {
        ...state,
        articleEditMode: action.payload.articleEditMode,
      };
    }
    case TOGGLE_ARTICLE_LAYOUT_VIEW: {
      return {
        ...state,
        articleLayoutView: action.payload.articleLayoutView,
        wideScreenEditModeEnabled: false,
      };
    }
    case TOGGLE_WIDESCREEN_EDIT_MODE: {
      return {
        ...state,
        wideScreenEditModeEnabled: !state.wideScreenEditModeEnabled,
      };
    }
    case RECOVER_ARTICLE: {
      return { ...state, redirectTo: `/` };
    }
    case ENTER_SLIDE_SELECTION_MODE: {
      return {
        ...state,
        slideSelectionOperation: action.payload.operation
      };
    }
    case EXIT_SLIDE_SELECTION_MODE: {
      return {
        ...state,
        slideSelectionOperation: undefined,
        slideNumber: null,
      };
    }
    case LOCK_GIDE_INLINE_TEXT_EDIT: {
      return {
        ...state,
        lockInlineTextEdit: true,
      };
    }
    case UNLOCK_GIDE_INLINE_TEXT_EDIT: {
      return {
        ...state,
        lockInlineTextEdit: false,
      };
    }
    case EXIT_DISTRIBUTE_GIDE: {
      return {
        ...state,
        distributingGide: false,
      };
    }
    case DISTRIBUTE_GIDE: {
      return {
        ...state,
        distributingGide: true,
      };
    }
    case CONFIG_LOADED: {
      return {
        ...state,
        config: action.payload.config
      }
    }
    default:
      return state;
  }
};
