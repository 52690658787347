import React, { useState } from 'react';
import { ChannelSearchElement, GideSearchElement } from '../../GideElementSearch';
import ChannelListItemPreview from '../Previews/ChannelPreview/ChannelListItemPreview/ChannelListItemPreview';
import styles from './_channel-chooser.module.scss';
import { SearchCriteria } from '../../Header/GidesAppHeader/GidesAppHeader';
import SearchBarWithResults from '../SearchBarWithResults/SearchBarWithResults';
import { ItemSelectionMode } from '../Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import contains from 'ramda/es/contains';
import { RoundedCornerButton } from '../RoundedCornerButton/RoundedCornerButton';
import icons from '../../../assets/icons';
import { Agent } from '../../../models/Agent';

export interface ChannelChooserProps {
  initialChannels?: ChannelSearchElement[];
  title?: string;
  subtitle?: string;
  searchOnKeyStroke?: boolean;
  selectionMode: ItemSelectionMode;
  channelSearch: (searchCriteria: SearchCriteria) => Promise<ChannelSearchElement[]>;
  onSelectChannel?: (channelSummary: ChannelSearchElement) => void;
  onSelectMultipleChannels?: (channelSummaries: ChannelSearchElement[]) => void;
}

export default function ChannelChooser(props: ChannelChooserProps) {
  const [channelSearchResults, updateChannelSearchResults] = useState<ChannelSearchElement[]>(props.initialChannels ? props.initialChannels : []);
  const [selectedChannelIds, updateSelectedChannelIds] = useState<string[]>([]);

  const onSearch = async (searchCriteria: SearchCriteria) => {
    const channelSearchResults = await props.channelSearch({ searchText: searchCriteria.searchText });
    updateChannelSearchResults(channelSearchResults ? channelSearchResults : []);
  };

  const onSelectChannel = (channelSearchResult: ChannelSearchElement) => {
    if (contains(channelSearchResult.id, selectedChannelIds)) {
      if(props.selectionMode === ItemSelectionMode.MultipleDisplayCheckBox) {
        updateSelectedChannelIds(selectedChannelIds.filter(c => c !== channelSearchResult.id));
      } else {
        updateSelectedChannelIds([]);
      }
    } else {
      if(props.selectionMode === ItemSelectionMode.MultipleDisplayCheckBox) {
        updateSelectedChannelIds([...selectedChannelIds, channelSearchResult.id]);
      } else {
        updateSelectedChannelIds([channelSearchResult.id]);
      }
    }
    props.onSelectChannel && props.onSelectChannel(channelSearchResult);
  };
  const onClearSearch = () => {
    updateChannelSearchResults(props.initialChannels ? props.initialChannels : []);
  };

  return (
    <div className={styles.channelChooserContainer}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
      <SearchBarWithResults searchOnKeyStroke={props.searchOnKeyStroke} searchPlaceHolder={'Search for Channels'} onSearch={onSearch} onClearSearch={onClearSearch}>
        {channelSearchResults.map((channel: ChannelSearchElement, index: number) => {
          return (
            <div className={styles.channelChooserItem} key={index}>
              <ChannelListItemPreview
                image={channel.imageUrl}
                title={channel.displayName}
                authorImage={channel.authorImageUrl}
                author={channel.authorUsername}
                type={channel.type}
                isSubChannel={channel.channel ? true : false}
                onClick={() => {
                  onSelectChannel(channel);
                }}
                selected={contains(channel.id, selectedChannelIds)}
                selectionMode={props.selectionMode}
              />
            </div>
          );
        })}
        {channelSearchResults.length == 0 && <div>No Results</div>}
      </SearchBarWithResults>
      {props.onSelectMultipleChannels && selectedChannelIds.length > 0 && (
        <div className={styles.channelChooserSelectionActions}>
          <RoundedCornerButton
            label="Uncheck all"
            labelColor="var(--COLOR-SECONDARY-600)"
            style={{ width: '124px', backgroundColor: '#fafafa' }}
            icon={<icons.ContentAlteration_Checkbox_Uncheck color="var(--COLOR-SECONDARY-600)" />}
            iconCssClass="color-secondary-600-svg"
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
            imagePosition="right"
            onClick={() => {
              updateSelectedChannelIds([]);
            }}
            disabled={selectedChannelIds.length === 0}
          />
          <RoundedCornerButton
            label="Done"
            labelColor="var(--WHITES-NORMAL-1000)"
            style={{ width: '84px', marginLeft: '12px', backgroundColor: 'var(--COLOR-PRIMARY-600)' }}
            icon={<icons.ContentAlteration_Check_Main color="var(--WHITES-NORMAL-1000)" />}
            iconCssClass="whites-normal-1000-svg"
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
            imagePosition="right"
            onClick={() => {
              props.onSelectMultipleChannels && props.onSelectMultipleChannels(channelSearchResults.filter(cr => contains(cr.id, selectedChannelIds)));
            }}
            disabled={selectedChannelIds.length === 0}
          />
        </div>
      )}
    </div>
  );
}
