import React, { useState, useEffect } from 'react';
import { Slide } from '../../../models/Slide';
import { generateTableOfContents } from '../../../utils/helperFunctions';
import { SlideRange } from '../../../models/SlideRange';
import Slider from '../Slider/Slider';
import { RoundedCornerButton } from '../RoundedCornerButton/RoundedCornerButton';
import classNames from 'classnames';

export interface NavigideProps {
  currentSlidePosition: number;
  showSlideNumbers: boolean;
  numberOfSlides: number;
  headerSlides: Slide[];
  slideRangeList?: SlideRange[];
  isAuthor: boolean;
  onNavigateToSlidePosition: (slidePosition: number) => void;
  onCollapseAllSlides: (collapseType: string) => void;
  onExpandAllSlides: (expandType: string) => void;
  style?: React.CSSProperties;
}

export default function Navigide(props: NavigideProps) {
  useEffect(
    () => {
      if (manuallyMovingSliderToValue && props.currentSlidePosition === manuallyMovingSliderToValue) {
        setManuallyMovingSliderToValue(undefined);
      }
      if (selectedSlideNumber !== props.currentSlidePosition && !(isManuallyScrollingSlider || manuallyMovingSliderToValue)) {
        setSelectedSliderPosition(props.currentSlidePosition > 0 ? props.currentSlidePosition / props.numberOfSlides : 0.01);
      }
      setSelectedSlideNumber(props.currentSlidePosition);
    },
    [props.currentSlidePosition],
  );

  const [selectedSlideNumber, setSelectedSlideNumber] = useState<number>(props.currentSlidePosition);
  const [selectedSliderPosition, setSelectedSliderPosition] = useState<number>(props.currentSlidePosition / props.numberOfSlides);
  const [isManuallyScrollingSlider, setManuallyScrollingSlider] = useState<boolean>(false);
  const [manuallyMovingSliderToValue, setManuallyMovingSliderToValue] = useState<number>();

  const onNavigateToSlide = (slidePosition: number) => {
    props.onNavigateToSlidePosition(slidePosition > 0 ? slidePosition : 1);
  };

  const onManualMoveSliderToValue = (args: any) => {
    const sliderValue = args.target.value ? args.target.value : 0.01;
    const slideNumber = Math.round(props.numberOfSlides * sliderValue);
    if (props.currentSlidePosition !== slideNumber) {
      setManuallyMovingSliderToValue(slideNumber);
      props.onNavigateToSlidePosition(slideNumber > 0 ? slideNumber : 0);
    }
  };

  const onNavigideSliderChange = (args: any) => {
    const sliderValue = args.target.value ? args.target.value : 0.01;
    const slideNumber = Math.round(props.numberOfSlides * sliderValue);
    // can not be manually scrolling slider and have a value in manuallyMovingSliderToValue
    if (isManuallyScrollingSlider && manuallyMovingSliderToValue) setManuallyMovingSliderToValue(undefined);
    if (isManuallyScrollingSlider) setSelectedSliderPosition(args.target.value);
    props.onNavigateToSlidePosition(slideNumber > 0 ? slideNumber : 1);
  };

  const tableOfContents = generateTableOfContents(props.headerSlides);
  return (
    <div className={classNames("navigideContainer", props.isAuthor ? 'author' : undefined)} style={props.style}>
      <div className="navigideTOC"
        onScroll={(e) => e.stopPropagation()}
      >
        {tableOfContents.map(headerSlide => {
          const headerSlideRange = props.slideRangeList && props.slideRangeList.find(srl => srl.rangeSlideId === headerSlide.id);
          const startingSlidePosition = headerSlideRange ? headerSlideRange.startingPosition : 1;
          const endingSlidePosition =
            headerSlideRange && headerSlideRange.endingPosition ? headerSlideRange.endingPosition : props.numberOfSlides;
          const headerSlideRangeTitle = `${startingSlidePosition < 10 ? '0' + startingSlidePosition : startingSlidePosition}-${
            endingSlidePosition < 10 ? '0' + endingSlidePosition : endingSlidePosition
          }`;

          return (
            <div
              key={headerSlide.id}
              data-header-id={headerSlide.id}
              className="flexRowFlexNormal"
              style={{ marginBottom: '5px' }}
              onClick={() => {
                onNavigateToSlide(headerSlide.position ? headerSlide.position : headerSlide.slidePosition);
              }}
            >
              <div className={`navigideHeader tocLevel${headerSlide.data.level}`}>
                <div className={`navigideHeaderText tocLevel${headerSlide.data.level}`}>
                  <div className={`navigideHeaderTitle ${props.showSlideNumbers ? '' : 'hideSlideNumbers'}`}>
                    {headerSlide.data.level > 1 ? `${headerSlide.data.idx} ${headerSlide.data.title}` : headerSlide.data.title}
                  </div>
                  <div className={`slideRange ${props.showSlideNumbers ? '' : 'hideSlideNumbers'}`}>{headerSlideRangeTitle}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        padding: "16px",
        borderTop: "solid var(--COLOR-SECONDARY-200) 2px",
        borderBottom: "solid var(--COLOR-SECONDARY-200) 2px",
      }}>
        {tableOfContents.length > 0 && <>
            <RoundedCornerButton
              label="Collapse all"
              style={{
                backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                width: '112px',
                height: '32px',
                marginLeft: "12px",
              }}
              onClick={e => {
                props.onCollapseAllSlides('Headers');
              }}
            />
            <RoundedCornerButton
              label="Expand all"
              style={{
                backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                width: '112px',
                height: '32px',
                marginLeft: "12px",
              }}
              onClick={e => {
                props.onExpandAllSlides('Headers');
              }}
            />
          </>
        }
        {!tableOfContents.length &&
          <div className="TEXTSUBTITLEblackmedium-emphasisleft">
            This gide has no headers
          </div>
        }
      </div>
      <div className="navigideMoveTo">
        <div className="moveToDetails">
          <div>move to %</div>
          <div className={`slideNumberDetails ${props.showSlideNumbers ? '' : 'hideSlideNumbers'}`} id="slide-number-details">
            <div className="numberOfSlide">slide number</div>
            <div className="number">{selectedSlideNumber}</div>
          </div>
        </div>
        <Slider
          sliderValue={selectedSliderPosition}
          sliderTickLabels={['0%', '50%', '100%']}
          onChange={onNavigideSliderChange}
          onMouseDown={() => setManuallyScrollingSlider(true)}
          onMouseUp={() => setManuallyScrollingSlider(false)}
          onClick={onManualMoveSliderToValue}
        />
      </div>
    </div>
  );
}
