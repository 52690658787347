import { SlideFileProcessorInfo, TitleSlideProcessorInfo, GallerySlideFileProcessorInfo } from "./fileProcessing";

export const ADD_SLIDE_FILE_FOR_PROCESSING = 'ADD_SLIDE_FILE_FOR_PROCESSING';
export const CANCEL_SLIDE_FILE_PROCESSING = 'CANCEL_SLIDE_FILE_PROCESSING';
export const COMPLETE_SLIDE_FILE_PROCESSING = 'COMPLETE_SLIDE_FILE_PROCESSING';
export const START_SLIDE_FILE_PROCESSING = 'START_SLIDE_FILE_PROCESSING';


export const ADD_TITLE_SLIDE_FOR_PROCESSING = 'ADD_TITLE_SLIDE_FOR_PROCESSING';
export const CANCEL_TITLE_SLIDE_PROCESSING = 'CANCEL_TITLE_SLIDE_PROCESSING';
export const COMPLETE_TITLE_SLIDE_PROCESSING = 'COMPLETE_TITLE_SLIDE_PROCESSING';
export const START_TITLE_SLIDE_PROCESSING = 'START_TITLE_SLIDE_PROCESSING';


export const ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING = 'ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING';
export const CANCEL_GALLERY_SLIDE_FILE_PROCESSING = 'CANCEL_GALLERY_SLIDE_FILE_PROCESSING';
export const COMPLETE_GALLERY_SLIDE_FILE_PROCESSING = 'COMPLETE_GALLERY_SLIDE_FILE_PROCESSING';
export const START_GALLERY_SLIDE_FILE_PROCESSING = 'START_SLIDE_GALLERY_FILE_PROCESSING';

export interface GideAction_ADD_SLIDE_FILE_FOR_PROCESSING {
  type: typeof ADD_SLIDE_FILE_FOR_PROCESSING,
  payload: {slidefileProcessingInfoList: SlideFileProcessorInfo[]}
}
export interface GideAction_CANCEL_SLIDE_FILE_PROCESSING {
  type: typeof CANCEL_SLIDE_FILE_PROCESSING,
  payload: {processingId: string}
}
export interface GideAction_COMPLETE_SLIDE_FILE_PROCESSING {
  type: typeof COMPLETE_SLIDE_FILE_PROCESSING,
  payload: {slideFileProcessorInfo: SlideFileProcessorInfo, fileUrl: string}
}

export interface GideAction_START_SLIDE_FILE_PROCESSING {
  type: typeof START_SLIDE_FILE_PROCESSING,
  payload: {processingIds: string[]}
}



export interface GideAction_ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING {
  type: typeof ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING,
  payload: {gallerySlideFileProcessingInfoList: GallerySlideFileProcessorInfo[]}
}
export interface GideAction_CANCEL_GALLERY_SLIDE_FILE_PROCESSING {
  type: typeof CANCEL_GALLERY_SLIDE_FILE_PROCESSING,
  payload: {processingId: string}
}
export interface GideAction_COMPLETE_GALLERY_SLIDE_FILE_PROCESSING {
  type: typeof COMPLETE_GALLERY_SLIDE_FILE_PROCESSING,
  payload: {gallerySlideFileProcessingInfo: GallerySlideFileProcessorInfo, fileUrl: string}
}

export interface GideAction_START_GALLERY_SLIDE_FILE_PROCESSING {
  type: typeof START_GALLERY_SLIDE_FILE_PROCESSING,
  payload: {processingIds: string[]}
}



export interface GideAction_ADD_TITLE_SLIDE_FOR_PROCESSING {
  type: typeof ADD_TITLE_SLIDE_FOR_PROCESSING,
  payload: {titleSlideProcessorInfo: TitleSlideProcessorInfo[]}
}
export interface GideAction_CANCEL_TITLE_SLIDE_PROCESSING {
  type: typeof CANCEL_TITLE_SLIDE_PROCESSING,
  payload: {processingId: string}
}
export interface GideAction_COMPLETE_TITLE_SLIDE_PROCESSING {
  type: typeof COMPLETE_TITLE_SLIDE_PROCESSING,
  payload: {titleSlideProcessorInfo: TitleSlideProcessorInfo, fileUrl: string}
}

export interface GideAction_START_TITLE_SLIDE_PROCESSING {
  type: typeof START_TITLE_SLIDE_PROCESSING,
  payload: {processingIds: string[]}
}


export type FileProcessingActions = 
GideAction_ADD_TITLE_SLIDE_FOR_PROCESSING
| GideAction_CANCEL_TITLE_SLIDE_PROCESSING
| GideAction_COMPLETE_TITLE_SLIDE_PROCESSING
| GideAction_START_TITLE_SLIDE_PROCESSING
| GideAction_ADD_SLIDE_FILE_FOR_PROCESSING
| GideAction_CANCEL_SLIDE_FILE_PROCESSING
| GideAction_COMPLETE_SLIDE_FILE_PROCESSING
| GideAction_START_SLIDE_FILE_PROCESSING
| GideAction_ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING
| GideAction_CANCEL_GALLERY_SLIDE_FILE_PROCESSING
| GideAction_COMPLETE_GALLERY_SLIDE_FILE_PROCESSING
| GideAction_START_GALLERY_SLIDE_FILE_PROCESSING;
