import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  List,
  Checkbox,
  Input,
  Image,
  Menu,
  Button,
  Icon,
  Radio,
  Form,
} from 'semantic-ui-react';

import {
  CHECKABLE_LISTITEM_USER,
  CHECKABLE_LISTITEM_EXHIBIT,
  CHECKABLE_LISTITEM_TODO,
  CHECKABLE_LISTITEM_NUMBERED,
} from '../../constants/strings';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import { contentMetaData } from '../../constants/contentMetaData';

import slideTools from '../../slideTools';
const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class ListModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      comment: '',
      slide: {
        slideType: 'LIST',
        data: {
          audioCaption: null,
          caption: '',
          listType: '',
          title: '',
          items: [],
        },
      },
      showSettings: false,
      defaultCheckItemType: CHECKABLE_LISTITEM_USER, // Used to keep track of previous selection when user clicks back.
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
      this.state.defaultCheckItemType = this.state.slide.data.listType;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.setListType = field => {
      // let slide = Object.assign({}, this.state.slide);
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.listType = field;
      this.setState({ slide });
    };
    this.handleSlideTypeChange = (ev, data) => {
      // const slide = Object.assign({}, this.state.slide);
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.listType = data.value;
      this.setState({ slide, defaultCheckItemType: slide.data.listType });
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateDataState = field => ev => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      // const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.onRemove = idx => {
      if (!window.confirm('Are you sure?')) return;
      // let slide = Object.assign({}, this.state.slide);
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      let items = slide.data.items.slice();
      items.splice(idx, 1);
      slide.data.items = items;
      this.setState({ slide });
    };

    this.addItem = ev => {
      if (!this.state.name) return;
      // let slide = Object.assign({}, this.state.slide);
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      let item = {
        name: this.state.name,
        comment: this.state.comment,
      };
      slide.data.items = [...slide.data.items, item];
      this.setState({
        name: '',
        content: '',
        slide,
      });
    };

    this.createSlide = async () => {
      if (!this.state.slide.data.items.length) {
        return alert('Add at least 1.');
      }
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.linkSlide = () => {
      this.props.openModal({
        modalType: 'SlideSelectModal',
      });
    };
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="attachModal listModal"
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(122, 96, 155)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{
            background: 'rgb(122, 96, 155)',
            padding: '30px !important',
          }}
        >
          <div
            className="modalHeader"
            style={{ background: 'rgb(122, 96, 155)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>List Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-list.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              {slide.data.listType === '' && (
                <Menu fluid vertical className="subTypeChoice">
                  <Menu.Item
                    onClick={() =>
                      this.setListType(this.state.defaultCheckItemType)
                    } // TODO is default
                    style={{ background: 'rgb(65, 45, 100)' }}
                  >
                    CHECKLIST
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      this.setListType(CHECKABLE_LISTITEM_NUMBERED)
                    }
                    style={{ background: 'rgb(82, 65, 126)' }}
                  >
                    LIST OF THINGS
                  </Menu.Item>
                </Menu>
              )}
              {(slide.data.listType === CHECKABLE_LISTITEM_TODO ||
                slide.data.listType === CHECKABLE_LISTITEM_USER ||
                slide.data.listType === CHECKABLE_LISTITEM_EXHIBIT) && (
                <div className="dataEntry">
                  <div>
                    <Input
                      fluid
                      placeholder="List Title"
                      value={slide.data.title}
                      onChange={this.updateDataState('title')}
                    />
                  </div>

                  <div
                    className="flexRowFlexStart"
                    style={{ marginTop: '10px' }}
                  >
                    <h4>Check Item Type</h4>
                    {/* <Form.Field>
                    <Radio
                      style={{marginLeft: '15px'}}
                      label="None Persist"
                      name="listItemTypeGroup"
                      value={CHECKABLE_LISTITEM_TODO}
                      checked={slide.data.listType === CHECKABLE_LISTITEM_TODO}
                      onChange={this.handleSlideTypeChange}
                    />
                  </Form.Field> */}
                    <Form.Field>
                      <Radio
                        style={{ marginLeft: '15px' }}
                        label="User Persist"
                        name="listItemTypeGroup"
                        value={CHECKABLE_LISTITEM_USER}
                        checked={
                          slide.data.listType === CHECKABLE_LISTITEM_USER
                        }
                        onChange={this.handleSlideTypeChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        style={{ marginLeft: '15px' }}
                        label="Author Persist"
                        name="listItemTypeGroup"
                        value={CHECKABLE_LISTITEM_EXHIBIT}
                        checked={
                          slide.data.listType === CHECKABLE_LISTITEM_EXHIBIT
                        }
                        onChange={this.handleSlideTypeChange}
                      />
                    </Form.Field>
                  </div>

                  <List bulleted>
                    {slide.data.items.map((item, i) => {
                      return (
                        <List.Item key={i}>
                          <List.Content floated="right">
                            <Button icon onClick={() => this.onRemove(i)}>
                              <Icon name="trash" />
                            </Button>
                          </List.Content>
                          <List.Content>
                            <Checkbox label={item.name} />
                            <p>{item.comment}</p>
                            <p>
                              {' '}
                              <span
                                style={{ margin: '0 5px 0 5px' }}
                                className="link underlined"
                                onClick={this.linkSlide}
                              >
                                Link Slide
                              </span>
                            </p>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                  <div>
                    <Input
                      fluid
                      placeholder="New Item Name"
                      value={this.state.name}
                      onChange={this.updateState('name')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="comment (optional)"
                      value={this.state.comment}
                      onChange={this.updateState('comment')}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={this.addItem}
                      icon="add"
                      content="Add"
                      disabled={!this.state.name.length}
                    />
                  </div>
                </div>
              )}
              {slide.data.listType === 'NUMBERED' && (
                <div className="dataEntry">
                  <div>
                    <Input
                      fluid
                      placeholder="List Title"
                      value={this.state.title}
                      onChange={this.updateState('title')}
                    />
                  </div>

                  <List ordered>
                    {slide.data.items.map((item, i) => {
                      return (
                        <List.Item key={i}>
                          <List.Content floated="right">
                            <Button icon onClick={() => this.onRemove(i)}>
                              <Icon name="trash" />
                            </Button>
                          </List.Content>
                          <List.Content>
                            <p>{item.name}</p>
                            <p>{item.comment}</p>
                            <p>
                              {' '}
                              <span
                                style={{ margin: '0 5px 0 5px' }}
                                className="link underlined"
                                onClick={this.linkSlide}
                              >
                                Link Slide
                              </span>
                            </p>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                  <div>
                    <Input
                      fluid
                      placeholder="New Item Name"
                      value={this.state.name}
                      onChange={this.updateState('name')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="comment (optional)"
                      value={this.state.comment}
                      onChange={this.updateState('comment')}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={this.addItem}
                      icon="add"
                      content="Add"
                      disabled={!this.state.name.length}
                    />
                  </div>
                </div>
              )}
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {slide.data.listType !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['LIST'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={() => this.setListType('')}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListModal);
