import React from 'react';
import styles from './_home.module.scss';
import ImageCarousel, { CarouselNavigationMode } from '../Viewers/ImageViewers/ImageCarousel/ImageCarousel';
import { ImageFile } from '../../models/SlideFile';
import GidePreviewList from './GidePreviewList/GidePreviewList';
import { GideGroup } from '../../models/GideEnum';
import { User } from '../../models/User';
import { RoundedCornerButton } from '../Shared/RoundedCornerButton/RoundedCornerButton';
import icons from '../../assets/icons';
import any from 'ramda/es/any';
import ComponentCarousel from '../Viewers/ImageViewers/ComponentCarousel/component';
import { homeCarouselImageSet } from '../Viewers/ImageViewers/ComponentCarousel/CarouselHelper';
import MultiImage from '../Shared/Image/MultiImage/MultiImage';

export interface HomeProps {
  currentUser: User | null;
  gideGroups: GideGroup[];
  searchText?: string;
  onShowSignup?: () => void;
  onNavigateToAboutGides: () => void;
  onNavigateToHowToUseGides: () => void;
  exitSearch: () => void;
}

export default function Home(props: HomeProps) {
  const containsSearchResults = props.gideGroups && props.gideGroups.length > 0 && any(g => g.gideList.length > 0, props.gideGroups);
  return (
    <div className={styles.homeContainer}>
      {props.gideGroups && props.gideGroups.length > 0 && !props.searchText && (
        <div className={styles.carousel}>
          <ComponentCarousel          
            autoAdvance={true}
            autoAdvanceSeconds={5}
            height={264}
            mobileHeight={294}
            navigationStyle='bullets'
            carouselComponents={homeCarouselImageSet(
                () => {props.onShowSignup && props.onShowSignup()},
                () => {props.onNavigateToAboutGides()},
                () => {props.onNavigateToHowToUseGides()}
              ).map((hi, index) => {
              return <MultiImage
                  imageInfo={hi}                  
                />              
            })}
          />
        </div>
      )}
    {props.searchText && (
      <div className={styles.gideSearchResultHeader}>
        <span style={{ marginLeft: '44px' }}>{containsSearchResults ? `Search results for: ${props.searchText}` : 'no search results'}</span>
        <RoundedCornerButton
          label='Cancel search'
          className={styles.cancelSearchButton}
          style={{
            backgroundColor: 'var(--COLOR-PRIMARY-700)',
            width: '143px',
            height: '32px',
            marginLeft: '11px',
          }}
          fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
          onClick={props.exitSearch}
          imagePosition='right'
          icon={<icons.Nav_Exit_Alternative color="var(--WHITES-NORMAL-900)"/>}
        />
      </div>
      )}       
      {props.gideGroups &&
        props.gideGroups.map((gideGroup, index) => {
          return (           
            <GidePreviewList
              style={{paddingTop: '29px'}}
              key={index}
              gidePreviewTitle={gideGroup.groupTitle}
              gideList={gideGroup.gideList}
              currentUser={props.currentUser}
              searchText={props.searchText}
            />
          );
        })}
    </div>
  );
}
