import React, { Component } from 'react';
import { Input, Dropdown, Checkbox } from 'semantic-ui-react';
import 'react-dates/initialize';
import { DateRangePicker,  } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import LocationField from './maps/LocationField';
import icons from '../assets/icons';
import { isEmpty } from 'ramda';

const InitialState = {
  active: 'TEXT',
  searchText: '',
  startDate: null,
  endDate: null,
  focusedInput: null,
  address: '',
  latLng: null,
};
class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = InitialState;

    this.watchForEnter = ev => {
      if (ev.keyCode === 13) {
        ev.target.blur();
        this.onSearchClick();
      }
    };

    this.onSetActiveClick = this._onSetActiveClick.bind(this);
    this.onSearchClick = this._onSearchClick.bind(this);
    this.onSettingsClick = this._onSettingsClick.bind(this);
    this.onSearchTextChange = this._onSearchTextChange.bind(this);
    this.handleAddressChanged = this._handleAddressChanged.bind(this);
    this.handleLatLngChanged = this._handleLatLngChanged.bind(this);
    this.onDateTypeChange = this._onDateTypeChange.bind(this);
  }

  componentDidMount() {
    if (this.searchInput && this.props.focusOnLoad === true) {
      this.searchInput.focus();
    } 
  }
  componentDidUpdate(prevProps) {
    if(this.props.searchText !== prevProps.searchText && this.props.searchText === undefined && this.state.searchText) {
      this.setState({searchText: ''});
    }
  }
  _onDateTypeChange(ev, data) {
    // console.log(ev, data);
  }

  _onSetActiveClick(active) {
    this.setState({ active });
  }

  _onSettingsClick() {
    alert('Advanced Search Settings coming soon...');
  }

  _onSearchClick() {
    const searchCriteria =  {
      startDate: this.state.startDate,
      endDate: this.stateendDate,
      searchText: this.state.searchText,
      address: this.stateaddress,
      latlng: this.statelatLng
    };
    this.props.onSearch(searchCriteria);
    if(this.props.clearOnSearch) {
      this.setState({searchText: ''});
    }
  }


  _onSearchTextChange(e) {
    if(this.props.searchOnKeyStroke === true) {
      this.setState({
        searchText: e.target.value,
      }, () => this._onSearchClick());
    } else {
      this.setState({
        searchText: e.target.value,
      });
    }
  }

  _handleAddressChanged(address, latLng) {
    this.setState({ address });
  }

  _handleLatLngChanged(address, latLng) {
    this.setState({ latLng });
  }

  render() {
    const { active, searchText, address } = this.state;
    return (
      <div className="searchBar" style={this.props.style}>
        {/* <Icon DO NOT DELETE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          className={active === 'TEXT' ? 'active' : ''}
          onClick={() => this.onSetActiveClick('TEXT')}
          name="text height"
          size="large"
        />
        <GideImage 
          className={`svgIcon pointer${active === 'DATE' ? ' active' : ''}`}
          src="/icons/creationprocess/events.svg"
          onClick={() => this.onSetActiveClick('DATE')}
          alt="Date"
        />
        <GideImage 
          className={`svgIcon pointer${active === 'LOCATION' ? ' active' : ''}`}
          src="/icons/slidetype/location/location.svg"
          onClick={() => this.onSetActiveClick('LOCATION')}
          alt="Location"
        /> */}
        {active === 'TEXT' && (
          <Input
            className="searchInput"
            value={searchText}
            onChange={this.onSearchTextChange}
            placeholder={this.props.placeholder ? this.props.placeholder : "Search gides.."}
            onKeyDown={this.watchForEnter}
            ref={ref => (this.searchInput = ref)}
          />
        )}
        {active === 'DATE' && (
          <span>
            <DateRangePicker
              //isOutsideRange={()=>false} // Disable past dates
              //isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())} // Disable future dates
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) =>
                this.setState({ startDate, endDate })
              } // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
            <Dropdown
              onChange={this.onDateTypeChange}
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Checkbox label="Created On" checked={false} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <Checkbox label="Updated On" checked={false} />
                </Dropdown.Item>
                <Dropdown.Item>
                  <Checkbox label="Event Date" checked={true} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        )}
        {active === 'LOCATION' && (
          <LocationField
            address={address}
            onAddressChanged={this.handleAddressChanged}
            onLatLngChanged={this.handleLatLngChanged}
            onKeyDown={this.watchForEnter}
          />
        )
        //<PlacesWithStandaloneSearchBox placesChanged={this.handlePlacesChanged}/>
        }
        {/*<span>
          <Image
            src="/icons/nav/settings.svg"
            className="svgIcon pointer inline"
            onClick={this.onSettingsClick}
          />
        </span>
        */}
        {/* <Icon name="search" size="large" onClick={this.onSearchClick} className="searchBtn" /> */}
        {!this.props.hideClearIcon && (
          <icons.Nav_Exit_Alternative
            onClick={() => {
              this.setState(InitialState);
              this.props.onToggleSearch();
            }}
            color={!isEmpty(this.state.searchText) ? 'var(--COLOR-SECONDARY-600)' :'var(--COLOR-SECONDARY-300)'}
            style={{marginRight: '10px'}}
          />
        )}
        <span className="flexRowFlexNormal activeSearchIconContainer">
          {/* <GideImage 
            className={`activeSearchIcon${this.props.viewMode !== 'SWIPE'
              ? ' color-primary-500-svg'
              : ' color-secondary-800-svg'}`
            }
            src="/icons/nav/search.svg"
            style={{maxWidth: '32px', maxHeight: '32px'}}
            onClick={this.onSearchClick}
            alt={`□`}
          /> */}
          {!this.props.hideSearchIcon && (
            <icons.Nav_Search
              className={`activeSearchIcon`}
                color={!isEmpty(this.state.searchText) ? 'var(--COLOR-SECONDARY-600)' :'var(--COLOR-SECONDARY-500)'}
                width={32} height={32}
                onClick={this.onSearchClick}
                title={'Search'}
            />
          )}
        </span>
      </div>
    );
  }
}

export default SearchBar; // connect(null, mapDispatchToProps)(SearchBar);
