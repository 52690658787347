import {
  ADD_SLIDE_FILE_FOR_PROCESSING, 
  CANCEL_SLIDE_FILE_PROCESSING,
  COMPLETE_SLIDE_FILE_PROCESSING,
  START_SLIDE_FILE_PROCESSING,
  ADD_TITLE_SLIDE_FOR_PROCESSING,
  CANCEL_TITLE_SLIDE_PROCESSING,
  COMPLETE_TITLE_SLIDE_PROCESSING,
  START_TITLE_SLIDE_PROCESSING,
  ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING,
  COMPLETE_GALLERY_SLIDE_FILE_PROCESSING,
  CANCEL_GALLERY_SLIDE_FILE_PROCESSING,
  START_GALLERY_SLIDE_FILE_PROCESSING

} from './fileProcessing.actions';
import { GideAction } from './../../models/GideAction';
import { combineReducers, Reducer } from 'redux';
import { reject, contains } from 'ramda';


interface FileProcessorInfo {
  dataUrl: any;
  isProcessing?: boolean;
  processingId: string;
}
export interface SlideFileProcessorInfo extends FileProcessorInfo {
  slideId: string; // To reduce the searching required to find processing slide files.
  slideFileId: string;
}
export interface GallerySlideFileProcessorInfo extends SlideFileProcessorInfo {
  galleryFileId: string;
}
export interface TitleSlideProcessorInfo extends FileProcessorInfo {    
  gideId: string;
  dataUrl: any;
  isProcessing?: boolean;
}

export interface FileProcessorState {
  slideFileProcessorList: SlideFileProcessorInfo[],
  titleSlideProcessorList: TitleSlideProcessorInfo[],
  gallerySlideFileProcessorList: GallerySlideFileProcessorInfo[],
} 

export const INITIAL_STATE: FileProcessorState = {
  slideFileProcessorList: [],
  titleSlideProcessorList: [],
  gallerySlideFileProcessorList: []
}

const slideFileProcessorListReducer = (state: SlideFileProcessorInfo[] = INITIAL_STATE.slideFileProcessorList, action: GideAction): SlideFileProcessorInfo[] => {
  switch(action.type) {
    case ADD_SLIDE_FILE_FOR_PROCESSING:
      return [...state, ...action.payload.slidefileProcessingInfoList];   
    case START_SLIDE_FILE_PROCESSING:
      return state.map(sf => {
        if(contains(sf.processingId, action.payload.processingIds)) {
          return {
            ...sf,
            isProcessing: true
          }
        }
        return sf;
      });
    case CANCEL_SLIDE_FILE_PROCESSING:
      return reject(item => item.processingId === action.payload.processingId, state);    
    case COMPLETE_SLIDE_FILE_PROCESSING:
      return reject(item => item.slideFileId === action.payload.slideFileProcessorInfo.slideFileId, state);    
    default:
      return state;
  }
};

const titleSlideProcessorListReducer = (state: TitleSlideProcessorInfo[] = INITIAL_STATE.titleSlideProcessorList, action: GideAction): TitleSlideProcessorInfo[] => {
  switch(action.type) {
    case ADD_TITLE_SLIDE_FOR_PROCESSING:
      return [...state, ...action.payload.titleSlideProcessorInfo];
    case START_TITLE_SLIDE_PROCESSING:
      return state.map(sf => {
        if(contains(sf.processingId, action.payload.processingIds)) {
          return {
            ...sf,
            isProcessing: true
          }
        }
        return sf;
      });
    case CANCEL_TITLE_SLIDE_PROCESSING:
      return reject(item => item.processingId === action.payload.processingId, state);    
    case COMPLETE_TITLE_SLIDE_PROCESSING:
      return reject(item => item.processingId === action.payload.titleSlideProcessorInfo.processingId, state);    
    default:
      return state;
  }
}

const gallerySlideFileProcessorListReducer = (state: GallerySlideFileProcessorInfo[] = INITIAL_STATE.gallerySlideFileProcessorList, action: GideAction)
  : GallerySlideFileProcessorInfo[] => {
  switch(action.type) {
    case ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING:
      return [...state, ...action.payload.gallerySlideFileProcessingInfoList];
    case START_GALLERY_SLIDE_FILE_PROCESSING:
      return state.map(sf => {
        if(contains(sf.processingId, action.payload.processingIds)) {
          return {
            ...sf,
            isProcessing: true
          }
        }
        return sf;
      });
    case CANCEL_GALLERY_SLIDE_FILE_PROCESSING:
      return reject(item => item.processingId === action.payload.processingId, state);    
    case COMPLETE_GALLERY_SLIDE_FILE_PROCESSING:
      return reject(item => item.processingId === action.payload.gallerySlideFileProcessingInfo.processingId, state);    
    default:
      return state;
  }
}

export const fileProcessor: Reducer<FileProcessorState> = combineReducers<FileProcessorState>({
  slideFileProcessorList: slideFileProcessorListReducer,
  titleSlideProcessorList: titleSlideProcessorListReducer,
  gallerySlideFileProcessorList: gallerySlideFileProcessorListReducer
});
