import React from 'react';
import { SlideFile } from '../../../models/SlideFile';
import { hasValue, formatDisplayTextWithSearchMarkup } from '../../../utils/helperFunctions';
import AudioPlayer, { AudioPlayerType } from '../../media/AudioPlayer/AudioPlayer';
import SearchContext from '../../Shared/SearchBar/SearchContext';
const createDOMPurify = require('dompurify');
export interface SlideFileCaptionProps {
  slideFile: SlideFile;
  linkedFileAttribution?: string;
  slideType: string;
}

export default function SlideFileCaption(props: SlideFileCaptionProps) {
  const searchContext = React.useContext(SearchContext);
  const displayText =
    searchContext && searchContext.searchText
      ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(props.slideFile.caption), searchContext.searchText)
      : createDOMPurify.sanitize(props.slideFile.caption);
  const containerClassName = 
    props.slideType === "IMAGE" ? "imageViewCaptionContainer" :
    props.slideType === "VIDEO" ? "videoViewCaptionContainer" :
      "imageViewCaptionContainer videoViewCaptionContainer";
  return (
    <>
      {(hasValue(displayText) || hasValue(props.slideFile.attribution || hasValue(props.slideFile.audioCaption))) && (
        <div className={containerClassName}>
          {props.linkedFileAttribution && (
            <span className="TEXTSUBTITLEblackhigh-emphasisleft">
              From:
              <span style={{ marginLeft: '5px' }} className="TEXTSUBTITLEblueleft">
                {props.linkedFileAttribution}
              </span>
            </span>
          )}
          {hasValue(props.slideFile.attribution) && (
            <span className="TEXTSUBTITLEblackhigh-emphasisleft">
              {props.slideType} by:
              <span style={{ marginLeft: '5px' }} className="TEXTSUBTITLEblackboldlow-emphasisleft">
                {props.slideFile.attribution}
              </span>
            </span>
          )}
          {hasValue(displayText) && (
            <span
              className="TEXTBODYblackregularmedium-emphasisleft"
              style={{ marginRight: '5px' }}
              dangerouslySetInnerHTML={{
                __html: displayText,
              }}
            />
          )}
          {props.slideFile.audioCaption && (
            <AudioPlayer autoPlay={false} src={props.slideFile.audioCaption.url} playerType={AudioPlayerType.AudioCaption} color={'blue'} />
          )}
        </div>
      )}
    </>
  );
}
