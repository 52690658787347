import  React from 'react';
import icons from '../../../assets/icons';
import styles from './_new-channel-button.module.scss';
export interface  NewChannelButtonProps {
  onCreateNewChannel: () => void, 
}

export function NewChannelButton (props:  NewChannelButtonProps) {
  return (
    <div className={styles.createChannelComponent} style={{
      backgroundColor: 'var(--WHITE-BACKGROUND-02)',
      paddingTop: 3,
      marginTop: 2,
    }}>
      <div style={{
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          backgroundColor: 'var(--WHITES-NORMAL-1000)',
          padding: 3,
        }}
        onClick={e => {
          props.onCreateNewChannel();
        }}
      >
        <div className={styles.createChannelImage}>
          <icons.Nav_Channels />
        </div>
        <div className={styles.createChannelTitle}>
          Create new channel
        </div>
      </div>
    </div>
  );
}
