import React from 'react';
import styles from './_gide-actions.module.scss';
import icons from '../../../../assets/icons';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';

export enum GideAction {
  GidePreview = 1,
  CleanEdit = 2,
  AdvancedEdit = 3,
}
export interface  GideActionsProps {
  selectedGideAction: GideAction,
  lockInlineTextEdit?: boolean;
  authorViewing: boolean;
  onLockInlineTextEdit: () => void;
  onUnlockInlineTextEdit: () => void;
  onSetGideViewMode: (action: GideAction) => void;
  onDistribute: () => void;
  onShare: () => void;
}

export default function GideActions (props:  GideActionsProps) {
  return (
      <div className={styles.gideActionsContainer}>
        {props.authorViewing && (
          <>

        {props.lockInlineTextEdit && (
          <icons.Nav_TextLock_Locked
            onClick={() => {
              props.onUnlockInlineTextEdit();
            }}
            style={{marginTop: '8px'}}
            doNotFillFromColor={true}
          />
        )}
        {!props.lockInlineTextEdit && (
          <icons.Nav_TextLock_Unlocked
            onClick={() => {
              props.onLockInlineTextEdit();
            }}
            style={{marginTop: '3px'}}
            doNotFillFromColor={true}
          />
        )}

        <icons.Nav_Eye_EyeOpen
          className={styles.headerBarIcon}
          color={props.selectedGideAction === GideAction.GidePreview ? 'var(--COLOR-SECONDARY-800)' : 'var(--COLOR-SECONDARY-300)'}
          width={38} height={38}
          onClick={() => {
            props.onSetGideViewMode(GideAction.GidePreview);
          }}
        />
        <icons.Nav_Edit_EditMain
          className={styles.headerBarIcon}
          color={props.selectedGideAction === GideAction.CleanEdit ? 'var(--COLOR-SECONDARY-800)' : 'var(--COLOR-SECONDARY-300)'}
          onClick={() => {
            props.onSetGideViewMode(GideAction.CleanEdit);
          }}
        />
        <icons.Nav_Edit_EditAdvanced
          className={styles.headerBarIcon}
          color={props.selectedGideAction === GideAction.AdvancedEdit ? 'var(--COLOR-SECONDARY-800)' : 'var(--COLOR-SECONDARY-300)'}
          onClick={() => {
            props.onSetGideViewMode(GideAction.AdvancedEdit);
          }}
        />
        <CircleIconButton
          backgroundColor='var(--COLOR-PRIMARY-700)'        
          style={{marginLeft: '24px', marginRight: '5px'}}
          onClick={props.onDistribute}
        >
          <icons.ContentAlteration_Share          
            color="var(--WHITES-NORMAL-1000)"
          />
          </CircleIconButton>
        
          </>
        )}
        {!props.authorViewing && (
          <icons.ContentAlteration_Share  
            onClick={props.onShare}        
            color="var(--COLOR-SECONDARY-600)"
            style={{marginRight: '14px'}}
          />
        )}
      </div>
  );
}
