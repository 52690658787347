import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  List,
  Input,
  Image,
  Button,
  Icon,
  Checkbox,
} from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import { contentMetaData } from '../../constants/contentMetaData';

import slideTools from '../../slideTools';
const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class LinksModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      url: '',
      slide: {
        slideType: 'LINKS',
        data: {
          audioCaption: null,
          caption: '',
          title: '',
          items: [],
        },
      },
      showLinkPreview: true,
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.setListType = field => {
      let slide = Object.assign({}, this.state.slide);
      slide.data.listType = field;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.onRemove = idx => {
      if (!window.confirm('Are you sure?')) return;
      let slide = Object.assign({}, this.state.slide);
      let items = slide.data.items.slice();
      items.splice(idx, 1);
      slide.data.items = items;
      this.setState({ slide });
    };

    this.addItem = ev => {
      let { url, text, showLinkPreview } = this.state;
      if (!url) return;
      if (!/http/.test(url)) {
        url = `http://${url}`;
      }
      let slide = Object.assign({}, this.state.slide);
      let item = {
        text,
        url,
        showLinkPreview,
      };
      slide.data.items = [...slide.data.items, item];
      this.setState({
        text: '',
        url: '',
        showLinkPreview: true,
        slide,
      });
    };

    this.createSlide = async () => {
      if (!this.state.slide.data.items.length) {
        return alert('Add at least 1.');
      }
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="attachModal linkModal"
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(122, 96, 155)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{
            background: 'rgb(59, 128, 169)',
            padding: '30px !important',
          }}
        >
          <div className="modalHeader">
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Link Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-hyperlink.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <div className="dataEntry">
                <div>
                  <Input
                    fluid
                    placeholder="Title (optional)"
                    value={slide.data.title}
                    onChange={this.updateDataState('title')}
                  />
                </div>
                <List ordered>
                  {slide.data.items.map((item, i) => {
                    return (
                      <List.Item key={i}>
                        <List.Content floated="right">
                          <Button icon onClick={() => this.onRemove(i)}>
                            <Icon name="trash" />
                          </Button>
                        </List.Content>
                        <List.Content>
                          <p>{item.text}</p>
                          <p>{item.url}</p>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
                <div>
                  <Input
                    fluid
                    placeholder="URL (required)"
                    value={this.state.url}
                    onChange={this.updateState('url')}
                  />
                </div>
                <div>
                  <Input
                    fluid
                    placeholder="Link Text (optional)"
                    value={this.state.text}
                    disabled={this.state.showLinkPreview}
                    onChange={this.updateState('text')}
                  />
                </div>
                <div style={{ padding: '2px' }}>
                  <Checkbox
                    label="Display URL preview"
                    onChange={(ev, data) => {
                      this.setState({ showLinkPreview: data.checked });
                      this.setState({ text: '' });
                    }}
                    checked={this.state.showLinkPreview}
                  />
                </div>
                <div>
                  <Button
                    onClick={this.addItem}
                    icon="add"
                    content="Add"
                    disabled={!this.state.url.length}
                  />
                </div>
              </div>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {this.state.listType !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['LINKS'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={false}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinksModal);
