import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import ArticleList from './ArticleList';
import { storyTellingImage } from '../utils/helperFunctions';
import GideImage from '../../src/components/Shared/Image/GideImage';

const CollectionPreview = ({ collection, showRemove, onRemove, currentUser }) => {
  const handleClick = ev => {
    // const url = `/channel/${channel.slug}`;
    // history.push(url);
  };

  const handleUserClick = ev => {
    // const url = `/channel/${channel.slug}`;
    // history.push(url);
  };

  return (
    <div className="collectionPreview">
      <div className="articleInfo">
        <div className="articleImageWrap">
          <GideImage 
            className="articleImage"
            src={storyTellingImage}
            alt={collection.title}
            onClick={handleClick}
          />
        </div>
        <div className="articleContent" onClick={handleClick}>
          <div className="articleTitle" onClick={handleClick}>
            {collection.title}
          </div>
          <div className="articleHeader" onClick={handleUserClick}>
            <GideImage 
              src={collection.author.image}
              alt={collection.author.username}
              className="articleAuthorImage"
            />
            <div className="articleAuthor">{collection.author.username}</div>
            <div className="articleType">Collection</div>
          </div>
          {collection.type === 'LANDING' && <div>Landing</div>}

          <div className="articleStats">
            {collection.type !== 'LANDING' && (
              <div>
                <GideImage 
                  style={{ height: '20px' }}
                  src="/icons/slidetype/contact/main.svg"
                  alt={`□`}
                />0 Followers{' '}
                <GideImage 
                  className="color-primary-500-svg"
                  src="/icons/content-alteration/newgide.svg"
                  alt={`□`}
                  style={{ height: '10px' }}
                />{' '}
                {collection.articles ? collection.articles.length : `0`} items
              </div>
            )}
            {collection.title !== 'Drafts' && (
              <Dropdown
                icon="ellipsis horizontal"
                size="large"
                style={{ float: 'right' }}
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => onRemove(collection)}>
                    <GideImage 
                      className={`svgIcon pointer color-secondary-500-svg`}
                      src="/icons/content-alteration/delete.svg"
                      alt={`□`}
                    />{' '}
                    Trash
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CollectionPreviewIterate = ({ collection, showRemove, onRemove, currentUser, onDeleteArticle, justifyCenter }) => {
  function removeCollection() {
    onRemove(collection);
  }
  return (
    <div>
      {justifyCenter && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            width: '100%',
          }}
        >
          <div className="gideSectionTitle">
            <span
              className="gideSectionTitleHeader"
              // ref={ref => {
              //   this.$gidesRef = ref;
              // }}
            >
              {collection.title}{' '} {showRemove && '(Landing)'}
              {showRemove && (
                <GideImage 
                  className={`svgIcon small pointer color-secondary-500-svg`}
                  src="/icons/content-alteration/delete.svg"
                  alt={`□`}
                  onClick={removeCollection}
                />
              )}
            </span>
            {/* CSS Centering Trick */}
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
            <span className="emptyGideTitleSpan" />
          </div>
        </div>
      )}
      {!justifyCenter && (
        <h3>
          {collection.title}{' '} {showRemove && '(Landing)'}
          {showRemove && (
            <GideImage 
              className={`svgIcon small pointer color-secondary-500-svg`}
              src="/icons/content-alteration/delete.svg"
              alt={`□`}
              onClick={removeCollection}
            />
          )}
        </h3>
      )}
      <ArticleList articles={collection.articles} layout="SQUARE" currentUser={currentUser} onDeleteArticle={onDeleteArticle} center={justifyCenter} />
    </div>
  );
};

const CollectionList = ({ collections, showRemove, onRemove, iterate, center, currentUser, onDeleteArticle, justifyCenter }) => {
  if (!collections) {
    return null;
  }

  if (collections.length === 0) {
    return <div className="channel-preview"></div>;
  }
  const collectionListStyle = center ? {} : { display: 'flex' };
  const classNames = `collectionList${center ? ' center' : ''}`;
  return (
    <div style={collectionListStyle}>
      <div className={classNames}>
        {collections.map((collection, i) => {
          if (iterate) {
            return (
              <CollectionPreviewIterate
                collection={collection}
                key={i}
                showRemove={showRemove}
                onRemove={onRemove}
                currentUser={currentUser}
                onDeleteArticle={onDeleteArticle}
                justifyCenter={justifyCenter}
              />
            );
          } else {
            return (
              <CollectionPreview
                collection={collection}
                key={i}
                showRemove={showRemove}
                onRemove={onRemove}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default CollectionList;
