import React, { Component } from 'react';
import { SlideList } from '../../Gide/SlideList/SlideList';
import { getChildArticleForSlide } from '../../../utils/helperFunctions';
import {
  getChildArticleTypeEnum,
  getChildArticleDisplayName,
} from '../../../models/ArticleLayoutEnum';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { Article } from '../../../models/Article';
import GideImage from '../../Shared/Image/GideImage';
import { ViewMode } from '../../Gide/SlideView/SlideView';
import isNil from 'ramda/es/isNil';

export interface ChildArticleViewerProps {
  article?: Article;
  currentUser: User;
  view?: string;
  slideId: string;
  articleType: string;
  slide: Slide;
  hideFooter: boolean;
  removeBorder?: boolean;
  viewMode: ViewMode;
  nextViewMode?: ViewMode;
  articleEditMode?: string;
  slideSelectionModeDetail?: SlideSelectionInfo;
  collapsedSlides?: Slide[];
  collapseSlides?: (slide: Slide) => void;
  toggleChildArticleViewMode?: (
    payload: { slideId: string; articleType: string },
  ) => void;
}
interface ChildArticleViewerState {
  slides: Slide[];
}
export default class ChildArticleViewer extends Component<
  ChildArticleViewerProps,
  ChildArticleViewerState
> {
  constructor(props: ChildArticleViewerProps) {
    super(props);
    this.state = { slides: [] };
  }
  closeViewer(): void {
    if (this.props.toggleChildArticleViewMode) {
      this.props.toggleChildArticleViewMode({
        slideId: this.props.slideId,
        articleType: this.props.articleType,
      });
    }
  }
  async load() {
    const response: any = await getChildArticleForSlide(
      this.props.slide,
      getChildArticleTypeEnum(this.props.articleType),
    );
    this.setState({ slides: response.slides });
  }
  componentDidMount() {
    this.load();
  }
  render() {
    return (
      <div
        className={`childArticleViewer${
          this.props.removeBorder ? ' removeBorder' : ''
        }`}
      >
        <SlideList
          slides={this.state.slides}
          currentUser={this.props.currentUser}
          classes={`slideAttachmentEditor`}
          view={this.props.view ? this.props.view : ''}
          viewMode={this.props.viewMode}
          nextViewMode={this.props.nextViewMode}
          article={this.props.article}
          articleEditMode={this.props.articleEditMode}
          slideSelectionModeDetail={this.props.slideSelectionModeDetail}
          collapsedSlides={this.props.collapsedSlides}
          collapseSlides={this.props.collapseSlides}
          viewOnly={true}
          // slides={this.state.slides}
          // currentUser={this.props.currentUser}
          // viewingChildArticle={true}
          // parentArticleType={this.props.articleType}
          // view={this.props.view ? this.props.view : ''}
        />
        {!this.props.hideFooter && (
          <div className="childArticleViewerHeader">
            <div className="childArticleViewerLeft">
              <GideImage 
                style={{ height: '30px', width: '30px' }}
                className={`pointer`}
                onClick={this.closeViewer.bind(this)}
                src="/icons/nav/exit/main.svg"
                alt={`Exit Inline Viewer`}
              />
              <span className="slideAttatchmentText">
                {`slide ${getChildArticleDisplayName(this.props.articleType)}`}
              </span>
            </div>
            <div className="childArticleViewerRight">
              <span className="slideNumberText">slide no</span>
              <span className="flexRowFlexNormal childArticleSlideNumberContainer">
                <GideImage 
                  style={{ height: '34px', width: '34px' }}
                  className={`pointer`}
                  src="/icons/child-article-viewer/slide-number-circle.svg"
                  alt={`□`}
                />
                <span className={`ownerSlideNumber`}>
                  {!isNil(this.props.slide.position)
                    ? this.props.slide.position + 1
                    : 0}
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
