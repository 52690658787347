import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Loader } from 'semantic-ui-react';

import agent from '../../agent';
import { SlideList } from '../Gide/SlideList/SlideList';
import { ADD_SLIDE, MODAL_CLOSE } from '../../constants/actionTypes';

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  viewMode: state.common.viewMode,
  slides: state.article.slides,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});
/**
 * Deprecated: Using inline ChildArticleEditorSlide for attachments now.
 */
export class SlideSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true };
    this.load = this._load.bind(this);
  }

  _load() {
    agent.Slides.getSettings(this.props.slide.id).then(resp => {
      agent.Slides.forArticle(resp.article).then(resp => {
        this.setState({ slides: resp.slides, loading: false });
      });
    });
  }

  componentWillMount() {
    this.load();
  }

  render() {
    const { slides, loading } = this.state;

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="miniGideModal"
        size="large"
        dimmer="inverted"
        closeOnDocumentClick={true}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="modalHeader">
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Slide Attachments</span>
          </div>
          <section>
            {loading && <Loader active inline="centered" />}
            {slides &&
              <SlideList
                slides={slides}
                // hideNumbers
              />}
          </section>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideSettingsModal);
