import React, { Component } from 'react';
import { User } from '../../../models/User';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { Modal, Image } from 'semantic-ui-react';
import {
  getIconForChildArticleType,
  ChildArticleType,
  getDisplayTextForChildArticleType,
  getChildArticleTypeName,
  getChildArticleTypeEnum,
  getChildArticleDisplayName,
} from '../../../models/ArticleLayoutEnum';
import { attachmentMetaData } from '../../../constants/contentMetaData';
import GideViewer from '../GideViewer/GideViewer';
import { Article } from '../../../models/Article';
import {
  InlineViewSlides,
  MultiSlideSelectionDetail,
} from '../../../reducers/article';
import { Dictionary } from '../../../models/SlideShowSlide';
import {
  getChildArticlesForSlide,
  getChildArticlesForArticle,
  getAvailableHeaderLevelsForSlidePosition,
  articleDisplayTime,
  noop,
} from '../../../utils/helperFunctions';
import agent from '../../../agent';
import { isNullOrUndefined } from 'util';
import Footer from '../../Footer/Footer';
import { SlideView, InlineSlideTextEditInfo, ViewMode } from '../SlideView/SlideView';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { InputBarOpenMenu } from '../../../constants/strings';
import { append, contains, reject, sort } from 'ramda';
import {
  InquiryResponseMode,
  InquiryResponseHeader,
} from '../../Shared/InquiryResponseHeader/InquiryResponseHeader';
import {
  RadioButtonComponent,
  ChangeArgs,
} from '@syncfusion/ej2-react-buttons';
import GideImage from '../../Shared/Image/GideImage';

export interface SlidePositionInfo {
  id: string;
  position: number;
}

export interface SlidesPositionInfo {
  slides: Slide[];
  position: number;
}

export interface MultiSlideInsertDetails {
  slidesToInsert: Slide[];
  insertedPosition: number;
  slideCount: number;
}

export interface GideViewerManagerModalProps {
  currentUser: User;
  view: string;
  viewMode: ViewMode;
  nextViewMode: ViewMode;
  articleEditMode: string;
  viewOnly?: boolean;
  ownerSlide: Slide;
  selectedArticleType: ChildArticleType;
  selectedArticleId?: string;
  articleTypeList: ChildArticleType[];
  disableInlineTextEditing: boolean;
  openModal: (payload: any) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  closeModal: () => void;
  closeGideViewer: (
    ownerSlideId?: string,
    articleInlineSlideChangesDictionary?: Dictionary<InlineViewSlides>,
  ) => void;
}

export interface ArticleViewDetail {
  article: Article;
  slides: Slide[];
  currentSlidePosition?: number;
  collapsedSlides: Slide[];
  parentArticleId?: string;
}

interface GideViewerManagerModalState {
  selectedArticleType: ChildArticleType;
  editingSelectedArticle?: boolean;
  selectedArticleViewDetail?: ArticleViewDetail;
  parentArticleViewDetail?: ArticleViewDetail;
  showOwnerSlide: boolean;
  articleInlineSlideChangesDictionary: Dictionary<InlineViewSlides>;
  isLoading: boolean;
  articleTypeLoaded: ChildArticleType[];
  articleViewDetailList: ArticleViewDetail[];
  scrollToSlideId?: string;
  slideIdOfSlideOutOfOrder?: string;
  textSlideEditing: Slide | null;
  multiSlideSelectionDetail: MultiSlideSelectionDetail;
  slideSelectionModeDetail?: SlideSelectionInfo;
  openMenu: InputBarOpenMenu;
  showTableOfContentsView: boolean;
  renderColumns: boolean;
  showBlame: boolean;
  hideEndSlides: boolean;
  displayCollapsedHeaders: boolean;
  currentSlideType: string | null;
  inlineSlideTextEditInfo?: InlineSlideTextEditInfo;
  inlineEditedSlide?: Slide;
  displayPendingArticles: boolean;
}

export default class GideViewerManagerModal extends Component<
  GideViewerManagerModalProps,
  GideViewerManagerModalState
> {
  constructor(props: GideViewerManagerModalProps) {
    super(props);
    this.state = {
      isLoading: false,
      showOwnerSlide: false,
      articleInlineSlideChangesDictionary: {},
      articleViewDetailList: [],
      articleTypeLoaded: [],
      selectedArticleType: props.selectedArticleType,
      textSlideEditing: null,
      slideIdOfSlideOutOfOrder: undefined,
      multiSlideSelectionDetail: {
        showMultiSelectSlideView: false,
        selectedSlideIds: [],
        mode: undefined,
      },
      slideSelectionModeDetail: undefined,
      openMenu: InputBarOpenMenu.NONE,
      showTableOfContentsView: false,
      renderColumns: false,
      showBlame: false,
      hideEndSlides: false,
      displayCollapsedHeaders: false,
      inlineSlideTextEditInfo: undefined,
      inlineEditedSlide: undefined,
      selectedArticleViewDetail: undefined,
      displayPendingArticles: false,
      currentSlideType: null
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      await this.loadArticles(this.props.selectedArticleType);
      this.setState({ isLoading: false });
    }
  }

  setInlineSlideTextEditInfo = (payload: any) => {
    this.setState({
      inlineSlideTextEditInfo: payload.inlineSlideTextEditInfo,
      inlineEditedSlide: undefined,
    });
  };

  updateSlideWithInlineEdits = (inlineEditedSlide: Slide) => {
    this.setState({
      inlineEditedSlide: inlineEditedSlide,
    });
  };

  copySlides = (copySlidesInfo: SlidesPositionInfo) => {
    if (this.state.selectedArticleViewDetail) {
      const slidesPrecedingCopiedSlides = this.state.selectedArticleViewDetail.slides.filter(
        (s: Slide) =>
          !isNullOrUndefined(s.position) &&
          s.position < copySlidesInfo.position,
      );
      const slidesAfterCopiedSlides = this.state.selectedArticleViewDetail.slides
        .filter(
          (s: Slide) =>
            !isNullOrUndefined(s.position) &&
            s.position >= copySlidesInfo.position,
        )
        .map(s => {
          return {
            ...s,
            position: s.position + copySlidesInfo.slides.length,
          } as Slide;
        });

      const updatedSlides = [
        ...(slidesPrecedingCopiedSlides ? slidesPrecedingCopiedSlides : []),
        ...copySlidesInfo.slides,
        ...(slidesAfterCopiedSlides ? slidesAfterCopiedSlides : []),
      ];

      const articleViewDetail = {
        ...this.state.selectedArticleViewDetail,
        slides: updatedSlides,
        currentSlidePosition: copySlidesInfo.position,
      };

      const newArticleViewDetailList = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        this.state.articleViewDetailList,
      );
      newArticleViewDetailList.push(articleViewDetail);

      this.setState({
        selectedArticleViewDetail: articleViewDetail,
        articleViewDetailList: newArticleViewDetailList,
        scrollToSlideId: articleViewDetail.slides[copySlidesInfo.position]
          ? articleViewDetail.slides[copySlidesInfo.position].id
          : undefined,
        slideSelectionModeDetail: undefined,
      });
    }
  };

  moveSlides = async (selectedSlideIds: string[], moveToPosition: number) => {
    if (this.state.selectedArticleViewDetail) {
      const payload = await agent.Articles.moveSlides(
        this.state.selectedArticleViewDetail.article,
        moveToPosition,
        selectedSlideIds,
      );
      const slides = this.state.selectedArticleViewDetail.slides
        ? this.state.selectedArticleViewDetail.slides
        : [];
      const slideInfoList: SlidePositionInfo[] = payload.movedSlideInfoList;
      const updatedSlides = sort(
        (s1: Slide, s2: Slide) => {
          return s1.position - s2.position;
        },
        slides.map((s: Slide) => {
          const slideInfo = slideInfoList.find(si => si.id === s.id);
          if (slideInfo) {
            return {
              ...s,
              position: slideInfo.position,
            };
          } else {
            return s;
          }
        }),
      );

      const articleViewDetail = {
        ...this.state.selectedArticleViewDetail,
        slides: updatedSlides,
        currentSlidePosition: moveToPosition,
      };

      const newArticleViewDetailList = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        this.state.articleViewDetailList,
      );
      newArticleViewDetailList.push(articleViewDetail);

      this.setState({
        selectedArticleViewDetail: articleViewDetail,
        articleViewDetailList: newArticleViewDetailList,
        scrollToSlideId: articleViewDetail.slides[moveToPosition]
          ? articleViewDetail.slides[moveToPosition].id
          : undefined,
        slideSelectionModeDetail: undefined,
      });
    }
  };

  deleteSlide = (deletedSlideId: string) => {
    if (this.state.selectedArticleViewDetail) {
      const deletedSlide = this.state.selectedArticleViewDetail.slides.find(
        (s: Slide) => s.id === deletedSlideId,
      );
      const updatedSlides = reject(
        s => s.id === deletedSlideId,
        this.state.selectedArticleViewDetail.slides,
      );
      const reorderedSlides = updatedSlides.map((s: Slide) => {
        return {
          ...s,
          position:
            deletedSlide &&
            !isNullOrUndefined(deletedSlide.position) &&
            !isNullOrUndefined(s.position) &&
            deletedSlide.position < s.position
              ? s.position - 1
              : s.position,
        };
      });
      if (reorderedSlides) {
        const articleViewDetail = {
          ...this.state.selectedArticleViewDetail,
          slides: reorderedSlides,
        };

        const newArticleViewDetailList = reject(
          ad => ad.article.id === articleViewDetail.article.id,
          this.state.articleViewDetailList,
        );
        newArticleViewDetailList.push(articleViewDetail);

        this.setState({
          selectedArticleViewDetail: articleViewDetail,
          articleViewDetailList: newArticleViewDetailList,
          scrollToSlideId:
            deletedSlide && this.state.scrollToSlideId === deletedSlide.id
              ? undefined
              : this.state.scrollToSlideId,
        });
      }
    }
  };

  onSubmitMultipleSlides = (
    multiSlideInsertDetails: MultiSlideInsertDetails,
  ) => {
    if (this.state.selectedArticleViewDetail) {
      let slides = this.state.selectedArticleViewDetail.slides;
      slides
        .filter(
          (s: Slide) =>
            !isNullOrUndefined(s.position) &&
            s.position >= multiSlideInsertDetails.insertedPosition,
        )
        .forEach(s => {
          s.position = s.position + multiSlideInsertDetails.slideCount;
        });
      // add the new slides
      slides = [...slides, ...multiSlideInsertDetails.slidesToInsert];
      // sort slides by position
      slides.sort((s1: Slide, s2: Slide) => {
        return s1.position - s2.position;
      });

      // check to see if the current slide position is not the last position in the gide
      const slidesOutOfOrder = slides.filter(
        s =>
          s.position ===
          multiSlideInsertDetails.insertedPosition +
            multiSlideInsertDetails.slidesToInsert.length,
      );

      const articleViewDetail = {
        ...this.state.selectedArticleViewDetail,
        slides: slides,
      };

      const newArticleViewDetailList = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        this.state.articleViewDetailList,
      );
      newArticleViewDetailList.push(articleViewDetail);

      this.setState({
        selectedArticleViewDetail: articleViewDetail,
        articleViewDetailList: newArticleViewDetailList,
        scrollToSlideId: articleViewDetail.slides[
          multiSlideInsertDetails.insertedPosition
        ]
          ? articleViewDetail.slides[multiSlideInsertDetails.insertedPosition]
              .id
          : undefined,
        slideIdOfSlideOutOfOrder:
          slidesOutOfOrder && slidesOutOfOrder.length > 0
            ? slidesOutOfOrder[0].id
            : undefined,
      });
    }
  };

  updateSlideNumber = (currentSlidePosition: number) => {
    if (this.state.selectedArticleViewDetail) {
      const slides = this.state.selectedArticleViewDetail.slides
        ? this.state.selectedArticleViewDetail.slides
        : [];
      // check and see if any of the slides have the same position as the currentSlidePosition
      // this would mean that the slide insert is out of order
      // and the slide should be displayed as out of order
      const slidesOutOfOrder = slides.filter(
        s => s.position === currentSlidePosition,
      );
      this.setState({
        slideIdOfSlideOutOfOrder:
          slidesOutOfOrder && slidesOutOfOrder.length > 0
            ? slidesOutOfOrder[0].id
            : undefined,
      });
    }
  };

  scrollToSlidePosition = (slidePosition: number) => {
    if (this.state.selectedArticleViewDetail) {
      const slides = this.state.selectedArticleViewDetail.slides;

      const scrollToSlide = slides[slidePosition];

      this.setState({
        scrollToSlideId: scrollToSlide
          ? scrollToSlide.id
          : slides.length > 0 ? slides[slides.length - 1].id : undefined,
      });
    }
  };

  /**
   * Collapses all the slides
   *  if the selectedArticleViewDetail exists, then all the slide fom the selectedArticleViewDetail are collapsed
   *  if the selectedArticleViewDetail doesn't exist, then collapses all the slides in the articleViewDetailsDictionary for the selectedArticleType
   */
  collapseAllSlides = () => {
    if (this.state.selectedArticleViewDetail) {
      const slides = this.state.selectedArticleViewDetail.slides;

      this.setState({
        selectedArticleViewDetail: {
          ...this.state.selectedArticleViewDetail,
          collapsedSlides: slides.filter(
            s =>
              (s.slideType === 'HEADER' &&
                s.data.type !== 'END' &&
                s.data.level > 0) ||
              (s.slideType === 'COLLAPSE' && s.data.type === 'BEGIN'),
          ),
        },
      });
    } else {
      const updatedArticleViewDetails = this.state.articleViewDetailList
        .filter(
          ad =>
            getChildArticleTypeEnum(ad.article.type) ===
            this.state.selectedArticleType,
        )
        .map(articleViewDetails => {
          return {
            ...articleViewDetails,
            collapsedSlides: articleViewDetails.slides.filter(
              s =>
                (s.slideType === 'HEADER' &&
                  s.data.type !== 'END' &&
                  s.data.level > 0) ||
                (s.slideType === 'COLLAPSE' && s.data.type === 'BEGIN'),
            ),
          };
        });

      const newArticleViewDetails = reject(
        ad =>
          getChildArticleTypeEnum(ad.article.type) ===
          this.state.selectedArticleType,
        this.state.articleViewDetailList,
      );

      this.setState({
        articleViewDetailList: [
          ...newArticleViewDetails,
          ...updatedArticleViewDetails,
        ],
      });
    }
  };

  /**
   * Expands all the slides
   *  if the selectedArticleViewDetail exists, then all the slide fom the selectedArticleViewDetail are expanded
   *  if the selectedArticleViewDetail doesn't exist, then expands all the slides in the articleViewDetailsDictionary for the selectedArticleType
   */
  expandAllSlides = () => {
    if (this.state.selectedArticleViewDetail) {
      this.setState({
        selectedArticleViewDetail: {
          ...this.state.selectedArticleViewDetail,
          collapsedSlides: [],
        },
      });
    } else {
      const updatedArticleViewDetails = this.state.articleViewDetailList
        .filter(
          ad =>
            getChildArticleTypeEnum(ad.article.type) ===
            this.state.selectedArticleType,
        )
        .map(articleViewDetails => {
          return {
            ...articleViewDetails,
            collapsedSlides: [],
          };
        });
      const newArticleViewDetails = reject(
        ad =>
          getChildArticleTypeEnum(ad.article.type) ===
          this.state.selectedArticleType,
        this.state.articleViewDetailList,
      );

      this.setState({
        articleViewDetailList: [
          ...newArticleViewDetails,
          ...updatedArticleViewDetails,
        ],
      });
    }
  };

  /**
   * collapses the slide in the article with the id of articleId for the selectedArticleType
   */
  collapseSlides = (articleId: string, slide: Slide) => {
    const articleIndex = this.state.articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const articleViewDetail = {
        ...this.state.articleViewDetailList[articleIndex],
        collapsedSlides:
          this.state.articleViewDetailList[
            articleIndex
          ].collapsedSlides.findIndex(s => s.id === slide.id) === -1
            ? append(
                slide,
                this.state.articleViewDetailList[articleIndex].collapsedSlides,
              )
            : this.state.articleViewDetailList[
                articleIndex
              ].collapsedSlides.filter(s => s.id !== slide.id),
      };
      const newArticleViewDetails = reject(
        ad => ad.article.id === articleId,
        this.state.articleViewDetailList,
      );
      newArticleViewDetails.push(articleViewDetail);

      this.setState({
        articleViewDetailList: newArticleViewDetails,
      });
    }
  };

  handleDistribute = () => {
    if (this.state.slideSelectionModeDetail) {
      this.props.openModal({
        modalType: 'DistributeModal',
      });
    }
  };

  toggleTableOfContents = () => {
    this.setState({
      showTableOfContentsView: !this.state.showTableOfContentsView,
    });
  };

  toggleColumns = () => {
    this.setState({
      renderColumns: !this.state.renderColumns,
    });
  };

  toggleBlame = () => {
    this.setState({
      showBlame: !this.state.showBlame,
    });
  };

  toggleCollapsedHeaderDisplay = () => {
    this.setState({
      displayCollapsedHeaders: !this.state.displayCollapsedHeaders,
    });
  };

  toggleEndSlideDisplay = () => {
    this.setState({
      hideEndSlides: !this.state.hideEndSlides,
    });
  };

  setOpenMenu = (openMenuStatus: InputBarOpenMenu) => {
    this.setState({ openMenu: openMenuStatus });
  };

  turnOverlayOff = () => {
    this.setState({ openMenu: InputBarOpenMenu.NONE });
  };

  onEditingText = (slide: Slide) => {
    this.setState({ textSlideEditing: slide });
  };

  enterSlideSelectionMode = (slideSelectionModeDetail: SlideSelectionInfo) => {
    this.setState({ slideSelectionModeDetail: slideSelectionModeDetail });
  };

  multiSlideSelectionChanged = (slideId: string, isSelected: boolean) => {
    if (
      isSelected &&
      !contains(slideId, this.state.multiSlideSelectionDetail.selectedSlideIds)
    ) {
      const multiSlideSelectionDetail = {
        ...this.state.multiSlideSelectionDetail,
        selectedSlideIds: [
          ...this.state.multiSlideSelectionDetail.selectedSlideIds,
          ...[slideId], // TODO: Not sure why I am adding all ids here. VERIFY THIS
        ],
      };
      this.setState({ multiSlideSelectionDetail: multiSlideSelectionDetail });
    } else {
      const multiSlideSelectionDetail = {
        ...this.state.multiSlideSelectionDetail,
        selectedSlideIds: reject(
          sId =>
            contains(
              slideId,
              this.state.multiSlideSelectionDetail.selectedSlideIds,
            ),
          this.state.multiSlideSelectionDetail.selectedSlideIds,
        ),
      };
      this.setState({ multiSlideSelectionDetail: multiSlideSelectionDetail });
    }
  };

  // Get the available header choices for the current slide position
  getAvailableHeaderLevelsForCurrentSlidePosition = () => {
    if (this.state.selectedArticleViewDetail) {
      const nextSlideNumber = !isNullOrUndefined(
        this.state.selectedArticleViewDetail.currentSlidePosition,
      )
        ? this.state.selectedArticleViewDetail.currentSlidePosition
        : this.state.selectedArticleViewDetail.slides
          ? this.state.selectedArticleViewDetail.slides.length
          : 0;

      return getAvailableHeaderLevelsForSlidePosition(
        nextSlideNumber,
        this.state.selectedArticleViewDetail.slides
          ? this.state.selectedArticleViewDetail.slides
          : [],
      );
    }
  };

  onReply = async (
    parentArticleId: string,
    parentPendingApproval?: boolean,
  ) => {
    if (parentPendingApproval) {
      await this.onUpdateArticleStatus(parentArticleId, 'APPROVED');
    }
    this.addArticleArticle(ChildArticleType.Response, parentArticleId);
  };

  addArticleArticle = async (
    articleType: ChildArticleType,
    parentArticleId: string,
  ) => {
    // create additionType article article
    const article = {
      title: `ARTICLEARTICLE - ${getChildArticleTypeName(articleType)}`,
      type: getChildArticleTypeName(articleType),
      article: parentArticleId,
      status:
        this.props.ownerSlide.author.id !== this.props.currentUser.id
          ? 'PENDING'
          : 'APPROVED',
      statusDate: new Date(),
    };
    const articleViewDetail = await this.createAdditonArticle(
      article,
      parentArticleId,
    );
    const parentArticleViewDetail = this.state.articleViewDetailList.find(
      ad => ad.article.id === parentArticleId,
    );

    this.setState({
      articleViewDetailList: [
        ...this.state.articleViewDetailList,
        ...[articleViewDetail],
      ],
      selectedArticleViewDetail: articleViewDetail,
      parentArticleViewDetail: parentArticleViewDetail,
    });
  };

  addSlideArticle = async (articleType: ChildArticleType): Promise<Article> => {
    // create additionType slide article
    const article = {
      title: `SLIDEARTICLE - ${getChildArticleTypeName(articleType)}`,
      type: getChildArticleTypeName(articleType),
      slide: this.props.ownerSlide.id,
      status:
        this.props.ownerSlide.author.id !== this.props.currentUser.id
          ? 'PENDING'
          : 'APPROVED',
      statusDate: new Date(),
    };
    const articleViewDetail = await this.createAdditonArticle(article);

    this.setState({
      articleViewDetailList: [
        ...this.state.articleViewDetailList,
        ...[articleViewDetail],
      ],
      selectedArticleViewDetail: articleViewDetail,
    });
    // return new Promise(resolve => resolve(articleViewDetail.article));
    return articleViewDetail.article;
  };

  createAdditonArticle = async (
    article: {
      title: string;
      type: string;
      article?: string;
      slide?: string;
    },
    parentArticleId?: string,
  ) => {
    const articleResponse = await agent.Articles.create(article);
    const articleViewDetails: ArticleViewDetail = {
      article: articleResponse.article,
      slides: [],
      collapsedSlides: [],
      parentArticleId: parentArticleId,
      currentSlidePosition: 0,
    };

    return articleViewDetails;
  };

  onSubmitArticle = async (
    article: Article,
    slides: Slide[],
    currentSlidePosition?: number,
  ) => {
    const articleResponse = await agent.Articles.update(article);

    const articleViewDetail: ArticleViewDetail = {
      article: articleResponse.article,
      slides: slides,
      currentSlidePosition: currentSlidePosition,
      collapsedSlides: [],
      parentArticleId: this.state.parentArticleViewDetail
        ? this.state.parentArticleViewDetail.article.id
        : undefined,
    };

    const articleIndex = this.state.articleViewDetailList.findIndex(
      avd => avd.article.id === articleViewDetail.article.id,
    );
    if (articleIndex > -1) {
      const newArticleViewDetails = reject(
        ad => ad.article.id === articleViewDetail.article.id,
        this.state.articleViewDetailList,
      );

      this.setState({
        articleViewDetailList: [
          ...newArticleViewDetails,
          ...[articleViewDetail],
        ],
        selectedArticleViewDetail: undefined,
        parentArticleViewDetail: undefined,
      });
    } else {
      this.setState({
        articleViewDetailList: [
          ...this.state.articleViewDetailList,
          ...[articleViewDetail],
        ],
        selectedArticleViewDetail: undefined,
        parentArticleViewDetail: undefined,
      });
    }
  };

  submitSlide = (addSlideDetail: SlideUpdateInfo) => {
    if (this.state.selectedArticleViewDetail) {
      const updatedSelectedArticleViewDetail = this.state.selectedArticleViewDetail;
      const currentSlides = [...updatedSelectedArticleViewDetail.slides];
      const idx = currentSlides.findIndex(
        (s: Slide) => s.id === addSlideDetail.slide.id,
      );
      const previousPosition = idx > -1 ? currentSlides[idx].position : -1;
      let slides: Slide[] = [];
      let oldSlides: Slide[] = [];

      if (previousPosition === -1) {
        oldSlides = currentSlides.map((s: Slide) => {
          if (
            !isNullOrUndefined(s.position) &&
            s.position >= addSlideDetail.slide.position
          ) {
            s.position += 1;
          }
          return s;
        });
      }
      if (idx !== -1) {
        slides = currentSlides.slice();
        slides[idx] = addSlideDetail.slide;
      } else {
        slides = (oldSlides || []).concat([addSlideDetail.slide]);
      }
      const sortedSlides = sort((l, r) => l.position - r.position, slides);
      const newSlideIndex = sortedSlides.findIndex(
        s => s.id === addSlideDetail.slide.id,
      );
      const newSlidePosition =
        newSlideIndex > -1 && sortedSlides[newSlideIndex]
          ? sortedSlides[newSlideIndex].position + 1
          : updatedSelectedArticleViewDetail.currentSlidePosition
            ? updatedSelectedArticleViewDetail.currentSlidePosition + 1
            : 1;

      const articleViewDetail = {
        ...updatedSelectedArticleViewDetail,
        slides: sortedSlides,
        currentSlidePosition: newSlidePosition,
      };

      this.setState({
        selectedArticleViewDetail: articleViewDetail,
        scrollToSlideId: !addSlideDetail.preventScrollToSlide
          ? addSlideDetail.slide.id
          : undefined,
      });
    }
  };

  onCreateGide = async (): Promise<Article> => {
    // if the new article's type is one that is associated with InquriyResponse, which is an addition to a slide or article
    if (
      this.state.selectedArticleType === ChildArticleType.Questions ||
      this.state.selectedArticleType === ChildArticleType.Comments ||
      this.state.selectedArticleType === ChildArticleType.Attachments ||
      this.state.selectedArticleType === ChildArticleType.PrivateNotes
    ) {
      return await this.addSlideArticle(this.state.selectedArticleType);
    } else {
      const article = {
        title: 'Untitled',
      };
      const articleResponse = await agent.Articles.create(article);
      this.onSubmitArticle(
        articleResponse.article,
        articleResponse.article.slides,
      );
      return articleResponse.article;
    }
  };

  onUpdateArticleStatus = async (articleId: string, articleStatus: string) => {
    const articleIndex = this.state.articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const existingArticleViewDetail = this.state.articleViewDetailList[
        articleIndex
      ];

      const statusUpdateResponse = existingArticleViewDetail.parentArticleId
        ? await agent.Articles.updateArticleStatusOnArticle(
            articleId,
            existingArticleViewDetail.parentArticleId,
            articleStatus,
          )
        : await agent.Articles.updateArticleStatusOnSlide(
            articleId,
            this.props.ownerSlide.id,
            articleStatus,
          );

      const articleViewDetail: ArticleViewDetail = {
        ...existingArticleViewDetail,
        article: statusUpdateResponse.article,
      };

      const newArticleViewDetails = reject(
        ad => ad.article.id === articleId,
        this.state.articleViewDetailList,
      );

      this.setState({
        articleViewDetailList: [
          ...newArticleViewDetails,
          ...[articleViewDetail],
        ],
      });
    }
  };

  onDeleteArticle = async (articleId: string) => {
    const articleIndex = this.state.articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const article = this.state.articleViewDetailList[articleIndex].article;
      await agent.Articles.deleteOwnerSlideArticle(
        article.id,
        this.props.ownerSlide.id,
        {
          full: article.trashedDate !== null,
        },
      );

      this.setState({
        selectedArticleViewDetail: undefined,
        parentArticleViewDetail: undefined,
        articleViewDetailList: reject(
          ad => ad.article.id === articleId,
          this.state.articleViewDetailList,
        ),
      });
    }
  };

  onEditArticle = (articleId: string) => {
    const articleIndex = this.state.articleViewDetailList.findIndex(
      avd => avd.article.id === articleId,
    );
    if (articleIndex > -1) {
      const parentArticleId = this.state.articleViewDetailList[articleIndex]
        .parentArticleId;
      if (parentArticleId) {
        const parentArticleIndex = this.state.articleViewDetailList.findIndex(
          avd => avd.article.id === parentArticleId,
        );
        if (parentArticleIndex > -1) {
          this.setState({
            selectedArticleViewDetail: this.state.articleViewDetailList[
              articleIndex
            ],
            parentArticleViewDetail: this.state.articleViewDetailList[
              parentArticleIndex
            ],
          });
        }
      } else {
        this.setState({
          selectedArticleViewDetail: this.state.articleViewDetailList[
            articleIndex
          ],
          parentArticleViewDetail: undefined,
        });
      }
    }
  };

  loadArticles = async (articleType: ChildArticleType) => {
    // check if article and slides have been or are being populated for the articleType, if not then populate them
    if (!this.state.articleTypeLoaded.includes(articleType)) {
      this.setState({
        articleTypeLoaded: [...this.state.articleTypeLoaded, ...[articleType]],
      });
      const articleResponse = await getChildArticlesForSlide(
        this.props.ownerSlide,
        articleType,
      );
      // load the slides for the articles
      articleResponse.articles.map(async (article: Article) => {
        const slidesResponse = await agent.Slides.forArticle(article);
        let currentSlidePosition = -1;
        if (slidesResponse) {
          currentSlidePosition = slidesResponse.slides
            ? slidesResponse.slides.length
            : undefined;
        }

        const articleViewDetail: ArticleViewDetail = {
          article: article,
          slides: slidesResponse.slides,
          currentSlidePosition: currentSlidePosition,
          collapsedSlides: [],
        };

        if (
          this.props.selectedArticleType === ChildArticleType.Questions ||
          this.props.selectedArticleType === ChildArticleType.Comments
        ) {
          // populate article responses
          const childArticleArticleResponse = await getChildArticlesForArticle(
            article.id,
            ChildArticleType.Response,
          );

          childArticleArticleResponse.articles.map(
            async (childArticle: Article) => {
              const childArticleSlidesResponse = await agent.Slides.forArticle(
                childArticle,
              );
              let currentSlidePosition = -1;
              if (childArticleSlidesResponse) {
                currentSlidePosition = childArticleSlidesResponse.slides
                  ? childArticleSlidesResponse.slides.length
                  : undefined;
              }

              const articleViewDetail: ArticleViewDetail = {
                article: childArticle,
                slides: childArticleSlidesResponse.slides,
                currentSlidePosition: currentSlidePosition,
                collapsedSlides: [],
                parentArticleId: article.id,
              };

              this.setState({
                articleViewDetailList: [
                  ...this.state.articleViewDetailList,
                  ...[articleViewDetail],
                ],
                selectedArticleViewDetail:
                  this.props.selectedArticleId &&
                  articleViewDetail.article.id === this.props.selectedArticleId
                    ? articleViewDetail
                    : this.state.selectedArticleViewDetail,
              });
            },
          );
          this.setState({
            articleViewDetailList: [
              ...this.state.articleViewDetailList,
              ...[articleViewDetail],
            ],
            selectedArticleViewDetail:
              this.props.selectedArticleId &&
              articleViewDetail.article.id === this.props.selectedArticleId
                ? articleViewDetail
                : this.state.selectedArticleViewDetail,
          });
        } else {
          const selectedArticleViewDetail =
            this.props.selectedArticleType !== ChildArticleType.Response
              ? articleViewDetail
              : this.state.selectedArticleViewDetail;
          this.setState({
            articleViewDetailList: [
              ...this.state.articleViewDetailList,
              ...[articleViewDetail],
            ],
            selectedArticleViewDetail: selectedArticleViewDetail,
          });
        }
      });
    }
  };

  onSwitchArticleTab = (selectedArticleType: ChildArticleType) => {
    this.setState({ selectedArticleType: selectedArticleType });
    this.loadArticles(selectedArticleType);
  };

  toggleOwnerSlide = () => {
    this.setState({ showOwnerSlide: !this.state.showOwnerSlide });
  };

  buildArticleTab = (
    tabArticleType: ChildArticleType,
    index: number,
  ): JSX.Element => {
    return (
      <span
        key={index}
        className={
          this.state.selectedArticleType === tabArticleType
            ? 'tabText selectedTab'
            : 'tabText'
        }
        onClick={e => this.onSwitchArticleTab(tabArticleType)}
      >
        {getDisplayTextForChildArticleType(tabArticleType)}
      </span>
    );
  };

  onArticleInlineSlidesChanged = (
    articleId: string,
    inlineViewSlides: InlineViewSlides,
  ) => {
    const articleInlineSlideChangesDictionary = this.state
      .articleInlineSlideChangesDictionary;
    articleInlineSlideChangesDictionary[articleId] = inlineViewSlides;
    this.setState({
      articleInlineSlideChangesDictionary: articleInlineSlideChangesDictionary,
    });
  };

  onCloseGideViewerManager = () => {
    // if the selectedArticleViewDetail, then set it to undefined
    if (
      this.state.selectedArticleViewDetail &&
      (this.state.selectedArticleType === ChildArticleType.PrivateNotes ||
        this.state.selectedArticleType === ChildArticleType.Attachments)
    ) {
      const articleInlineSlideChangesDictionary = this.state
        .articleInlineSlideChangesDictionary;
      articleInlineSlideChangesDictionary[this.state.selectedArticleType] = {
        articleType: getChildArticleTypeName(this.state.selectedArticleType),
        slides: this.state.selectedArticleViewDetail.slides,
      };
      this.props.closeGideViewer(
        this.props.ownerSlide && this.props.ownerSlide.id,
        articleInlineSlideChangesDictionary,
      );
    } else {
      this.props.closeGideViewer(
        this.props.ownerSlide && this.props.ownerSlide.id,
        this.state.articleInlineSlideChangesDictionary,
      );
    }
  };

  render() {
    const classNames = `${this.props.viewMode} gideViewModal`;
    const showDoneButton =
      this.state.selectedArticleType !== ChildArticleType.Comments &&
      this.state.selectedArticleType !== ChildArticleType.Questions;
    const selectedArticleViewDetails = this.state.articleViewDetailList.filter(
      adv =>
        getChildArticleTypeEnum(adv.article.type) ===
        this.state.selectedArticleType,
    );
    const footerArticle = this.state.selectedArticleViewDetail
      ? this.state.selectedArticleViewDetail.article
      : undefined;
    const footerPlaceHolderText =
      !this.state.selectedArticleViewDetail &&
      this.state.selectedArticleType === ChildArticleType.Questions
        ? 'Ask something'
        : 'Say something';
    const pendingArticleViewDetails = selectedArticleViewDetails
      ? this.props.ownerSlide.author.id === this.props.currentUser.id
        ? selectedArticleViewDetails.filter(
            avd => avd.article.status && avd.article.status === 'PENDING',
          )
        : selectedArticleViewDetails.filter(
            avd =>
              avd.article.author.id === this.props.currentUser.id &&
              avd.article.status &&
              avd.article.status === 'PENDING',
          )
      : undefined;
    const approvedArticleViewDetails = selectedArticleViewDetails
      ? selectedArticleViewDetails.filter(
          avd => avd.article.status && avd.article.status === 'APPROVED',
        )
      : undefined;
    const approvedParentWithChildPending =
      approvedArticleViewDetails &&
      approvedArticleViewDetails.filter(avd =>
        this.state.articleViewDetailList.some(
          pavd =>
            pavd.article.status === 'PENDING' &&
            pavd.parentArticleId === avd.article.id &&
            (this.props.ownerSlide.author.id === this.props.currentUser.id ||
              pavd.article.author.id === this.props.currentUser.id),
        ),
      );

    const hasPendingArticles =
      (pendingArticleViewDetails && pendingArticleViewDetails.length > 0) ||
      (approvedParentWithChildPending &&
        approvedParentWithChildPending.length > 0);

    const filteredArticleViewDetails =
      this.state.displayPendingArticles && hasPendingArticles
        ? sort(
            (a1: ArticleViewDetail, a2: ArticleViewDetail) => {
              return (
                new Date(a2.article.updatedAt).getTime() -
                new Date(a1.article.updatedAt).getTime()
              );
            },
            [
              ...(approvedParentWithChildPending
                ? approvedParentWithChildPending
                : []),
              ...(pendingArticleViewDetails ? pendingArticleViewDetails : []),
            ],
          )
        : sort((a1: ArticleViewDetail, a2: ArticleViewDetail) => {
            return (
              new Date(a2.article.updatedAt).getTime() -
              new Date(a1.article.updatedAt).getTime()
            );
          }, approvedArticleViewDetails ? approvedArticleViewDetails : []);

    return (
      <Modal
        className={classNames}
        closeOnEscape={true}
        onClose={this.props.closeModal}
        size="fullscreen"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        {this.state.parentArticleViewDetail && (
          <Modal.Header className="gideViewHeader">
            <div className="gideViewerContainer">
              <InquiryResponseHeader
                articleId={this.state.parentArticleViewDetail.article.id}
                author={this.state.parentArticleViewDetail.article.author}
                headingText={articleDisplayTime(
                  this.state.parentArticleViewDetail.article,
                )}
                inquiryResponseMode={InquiryResponseMode.Response}
                onBack={this.onCloseGideViewerManager}
                toggleOwnerSlide={this.toggleOwnerSlide}
                ownerType={getChildArticleDisplayName(
                  this.state.parentArticleViewDetail.article.type,
                )}
                displayingOwner={this.state.showOwnerSlide}
              />
              {this.state.showOwnerSlide && (
                <div className="gideViewerDetails">
                  <div
                    className={`${
                      this.state.parentArticleViewDetail.article.type
                    }`}
                  >
                    <GideViewer
                      key={this.state.parentArticleViewDetail.article.id}
                      currentUser={this.props.currentUser}
                      view={this.props.view}
                      viewMode={'SLIDE'}
                      nextViewMode={this.props.nextViewMode}
                      articleViewDetail={this.state.parentArticleViewDetail}
                      articleEditMode={''}
                      viewOnly={true}
                      showInquiryResponseHeader={false}
                      onSubmitArticle={this.onSubmitArticle}
                      showNotification={this.props.showNotification}
                      openModal={this.props.openModal}
                      closeModal={this.props.closeModal}
                      onArticleInlineSlidesChanged={
                        this.onArticleInlineSlidesChanged
                      }
                      disableInlineTextEditing={this.props.disableInlineTextEditing}
                      collapseSlides={this.collapseSlides}
                      onCreateGide={this.onCreateGide}
                      toggleTableOfContents={this.toggleTableOfContents}
                      onEditArticle={this.onEditArticle}
                      onDeleteArticle={this.onDeleteArticle}
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Header>
        )}
        {!this.state.parentArticleViewDetail && (
          <Modal.Header className="gideViewHeader">
            <div className="navigationBar">
              <div className="completeAction">
                <GideImage src="/icons/nav/arrow/arrow-left.svg"
                  className="hoverIcon color-secondary-800-svg"
                  onClick={this.onCloseGideViewerManager}
                  alt="close dialog"
                />
                {showDoneButton === true && (
                  <div
                    className="button done"
                    onClick={this.onCloseGideViewerManager}
                  >
                    <div>Done</div>
                    <Image
                      className="gideTypeImage"
                      src={getIconForChildArticleType(
                        this.state.selectedArticleType,
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="slideTypeIndicator">
                <Image
                  className="slideTypeImage sortFilter color-secondary-800-svg"
                  src="/icons/nav/sort&filter.svg"
                />
                <div className="separatorBar" />
                {this.props.ownerSlide && (
                  <>
                    <Image
                      className="slideTypeImage"
                      src={
                        (attachmentMetaData as any)[
                          this.props.ownerSlide.slideType as string
                        ]
                          ? (attachmentMetaData as any)[
                              this.props.ownerSlide.slideType
                            ].icon
                          : ''
                      }
                    />
                    <GideImage 
                      src={`/icons/nav/arrow/${
                        this.state.showOwnerSlide
                          ? 'arrow-up.svg'
                          : 'arrow-down.svg'
                      }`}
                      className="hoverIcon color-secondary-800-svg"
                      onClick={this.toggleOwnerSlide}
                      alt="toggle dialog display view"
                    />
                  </>
                )}
              </div>
            </div>
            {this.props.ownerSlide &&
              this.state.showOwnerSlide && (
                <div className="ownerSlide">
                  <div className="slideWrap">
                    <SlideView
                      slide={this.props.ownerSlide}
                      slidePosition={this.props.ownerSlide.position}
                      currentUser={this.props.currentUser}
                      view={this.props.view}
                      viewMode={this.props.viewMode}
                      nextViewMode={this.props.nextViewMode}
                      article={
                        this.state.selectedArticleViewDetail
                          ? this.state.selectedArticleViewDetail.article
                          : undefined
                      }
                      viewOnly={true}
                      hideSlideInformationSection={true}
                    />
                  </div>
                </div>
              )}
            <div className="gideViewTab">
              {this.props.articleTypeList.map(
                (articleType: ChildArticleType, index) =>
                  this.buildArticleTab(articleType, index),
              )}
            </div>
          </Modal.Header>
        )}

        <Modal.Content
          className={`gideViewerManagerContent ${
            this.state.selectedArticleViewDetail ? ' hidden' : ''
          }`}
        >
          {hasPendingArticles && (
            <div className="articleApprovalContainer">
              <div
                className={`articleApprovalFilter ${
                  !this.state.displayPendingArticles ? 'selected' : ''
                }`}
              >
                <RadioButtonComponent
                  checked={!this.state.displayPendingArticles}
                  label="Approved"
                  name="approvedArticles"
                  value={'APPROVED'}
                  change={(args?: ChangeArgs) => {
                    if (args && args.value === 'APPROVED') {
                      this.setState({ displayPendingArticles: false });
                    }
                  }}
                />
              </div>
              <div
                className={`articleApprovalFilter ${
                  this.state.displayPendingArticles ? 'selected' : ''
                }`}
              >
                <RadioButtonComponent
                  checked={this.state.displayPendingArticles}
                  label="Pending approval"
                  name="pendingArrovalArticles"
                  value={'PENDING'}
                  change={(args?: ChangeArgs) => {
                    if (args && args.value === 'PENDING') {
                      this.setState({ displayPendingArticles: true });
                    }
                  }}
                />
              </div>
            </div>
          )}

          <div className="gideViewerManagerContainer">
            {filteredArticleViewDetails &&
              filteredArticleViewDetails.map(
                (articleViewDetail: ArticleViewDetail) => {
                  const isInquiryResponse =
                    articleViewDetail.article.type === 'QUESTIONS' ||
                    articleViewDetail.article.type === 'COMMENTS' ||
                    articleViewDetail.article.type === 'RESPONSE';
                  const canApprove =
                    isInquiryResponse &&
                    articleViewDetail.article.requireApprovalForInquiries &&
                    articleViewDetail.article.status === 'PENDING' &&
                    this.props.ownerSlide.author.id ===
                      this.props.currentUser.id;

                  const sortedChildArticles = sort(
                    (a1: ArticleViewDetail, a2: ArticleViewDetail) => {
                      return (
                        new Date(a2.article.updatedAt).getTime() -
                        new Date(a1.article.updatedAt).getTime()
                      );
                    },
                    this.state.articleViewDetailList.filter(
                      avd =>
                        avd.parentArticleId === articleViewDetail.article.id &&
                        avd.article.status &&
                        ((avd.article.status === 'APPROVED' &&
                          (!hasPendingArticles ||
                            !this.state.displayPendingArticles)) ||
                          (avd.article.status === 'PENDING' &&
                            this.state.displayPendingArticles)),
                    ),
                  );
                  return (
                    <div
                      className="gideViewerDetails view"
                      key={articleViewDetail.article.id}
                    >
                      <div
                        className={`slideList ${
                          sortedChildArticles.length > 0 ? ' inline' : ''
                        }`}
                      >
                        <div className={`${articleViewDetail.article.type}`}>
                          <GideViewer
                            key={articleViewDetail.article.id}
                            currentUser={this.props.currentUser}
                            view={this.props.view}
                            viewMode={this.props.viewMode}
                            nextViewMode={this.props.nextViewMode}
                            articleViewDetail={articleViewDetail}
                            showInquiryResponseHeader={isInquiryResponse}
                            articleEditMode={this.props.articleEditMode}
                            viewOnly={this.props.viewOnly}
                            canApprove={canApprove}
                            disableInlineTextEditing={this.props.disableInlineTextEditing}
                            slideSelectionModeDetail={
                              this.state.slideSelectionModeDetail
                            }
                            displayCollapsedHeaders={
                              this.state.displayCollapsedHeaders
                            }
                            inlineSlideTextEditInfo={
                              this.state.inlineSlideTextEditInfo
                            }
                            showTableOfContentsView={
                              this.state.showTableOfContentsView
                            }
                            inlineEditedSlide={this.state.inlineEditedSlide}
                            inquiryResponseMode={InquiryResponseMode.View}
                            onSubmitArticle={this.onSubmitArticle}
                            showNotification={this.props.showNotification}
                            openModal={this.props.openModal}
                            closeModal={this.props.closeModal}
                            onArticleInlineSlidesChanged={
                              this.onArticleInlineSlidesChanged
                            }
                            addSlideArticle={this.addSlideArticle}
                            onEditArticle={this.onEditArticle}
                            onDeleteArticle={this.onDeleteArticle}
                            onUpdateArticleStatus={this.onUpdateArticleStatus}
                            onReply={this.onReply}
                            onEditingText={this.onEditingText}
                            collapseSlides={this.collapseSlides}
                            enterSlideSelectionMode={
                              this.enterSlideSelectionMode
                            }

                            setInlineSlideTextEditInfo={
                              this.setInlineSlideTextEditInfo
                            }
                            updateSlideWithInlineEdits={
                              this.updateSlideWithInlineEdits
                            }
                            onCreateGide={this.onCreateGide}
                            submitSlide={this.submitSlide}
                            updateSlideNumber={this.updateSlideNumber}
                            deleteSlide={this.deleteSlide}
                            copySlides={this.copySlides}
                            moveSlides={this.moveSlides}
                            toggleTableOfContents={this.toggleTableOfContents}
                          />
                        </div>
                        {sortedChildArticles.map(
                          (
                            inlineArticleViewDetail: ArticleViewDetail,
                            index: number,
                          ) => {
                            const canApprove =
                              isInquiryResponse &&
                              inlineArticleViewDetail.article
                                .requireApprovalForInquiries &&
                              inlineArticleViewDetail.article.status ===
                                'PENDING' &&
                              this.props.ownerSlide.author.id ===
                                this.props.currentUser.id;
                            return (
                              <div
                                className="responseContainer"
                                key={inlineArticleViewDetail.article.id}
                              >
                                <div
                                  className={`${
                                    inlineArticleViewDetail.article.type
                                  }`}
                                  key={index}
                                >
                                  <GideViewer
                                    key={inlineArticleViewDetail.article.id}
                                    currentUser={this.props.currentUser}
                                    view={this.props.view}
                                    viewMode={this.props.viewMode}
                                    nextViewMode={this.props.nextViewMode}
                                    articleViewDetail={inlineArticleViewDetail}
                                    showInquiryResponseHeader={true}
                                    articleEditMode={this.props.articleEditMode}
                                    viewOnly={this.props.viewOnly}
                                    canApprove={canApprove}
                                    disableInlineTextEditing={this.props.disableInlineTextEditing}
                                    slideSelectionModeDetail={
                                      this.state.slideSelectionModeDetail
                                    }
                                    displayCollapsedHeaders={
                                      this.state.displayCollapsedHeaders
                                    }
                                    inlineSlideTextEditInfo={
                                      this.state.inlineSlideTextEditInfo
                                    }
                                    showTableOfContentsView={
                                      this.state.showTableOfContentsView
                                    }
                                    inlineEditedSlide={
                                      this.state.inlineEditedSlide
                                    }
                                    inquiryResponseMode={
                                      InquiryResponseMode.View
                                    }
                                    onSubmitArticle={this.onSubmitArticle}
                                    showNotification={
                                      this.props.showNotification
                                    }
                                    openModal={this.props.openModal}
                                    closeModal={this.props.closeModal}
                                    onArticleInlineSlidesChanged={
                                      this.onArticleInlineSlidesChanged
                                    }
                                    addSlideArticle={this.addSlideArticle}
                                    onEditArticle={this.onEditArticle}
                                    onDeleteArticle={this.onDeleteArticle}
                                    onUpdateArticleStatus={
                                      this.onUpdateArticleStatus
                                    }
                                    onReply={this.onReply}
                                    onEditingText={this.onEditingText}
                                    collapseSlides={this.collapseSlides}
                                    enterSlideSelectionMode={
                                      this.enterSlideSelectionMode
                                    }
                                    setInlineSlideTextEditInfo={
                                      this.setInlineSlideTextEditInfo
                                    }
                                    updateSlideWithInlineEdits={
                                      this.updateSlideWithInlineEdits
                                    }
                                    onCreateGide={this.onCreateGide}
                                    submitSlide={this.submitSlide}
                                    updateSlideNumber={this.updateSlideNumber}
                                    deleteSlide={this.deleteSlide}
                                    copySlides={this.copySlides}
                                    moveSlides={this.moveSlides}
                                    toggleTableOfContents={
                                      this.toggleTableOfContents
                                    }
                                  />
                                </div>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                  );
                },
              )}
          </div>
        </Modal.Content>
        <Modal.Content
          className={`gideViewerManagerContent ${
            !this.state.selectedArticleViewDetail ? ' hidden' : ''
          } ${this.state.parentArticleViewDetail ? ' withParent' : ''}`}
        >
          <div className="gideViewerManagerContainer">
            {this.state.selectedArticleViewDetail && (
              <div
                className="gideViewerDetails"
                key={this.state.selectedArticleViewDetail.article.id}
              >
                <div
                  className={`${
                    this.state.selectedArticleViewDetail.article.type
                  }`}
                >
                  <GideViewer
                    key={this.state.selectedArticleViewDetail.article.id}
                    currentUser={this.props.currentUser}
                    view={this.props.view}
                    viewMode={'SLIDE'}
                    nextViewMode={this.props.nextViewMode}
                    articleViewDetail={this.state.selectedArticleViewDetail}
                    articleEditMode={'advancedEdit'}
                    viewOnly={false}
                    slideSelectionModeDetail={
                      this.state.slideSelectionModeDetail
                    }
                    displayCollapsedHeaders={this.state.displayCollapsedHeaders}
                    inlineSlideTextEditInfo={this.state.inlineSlideTextEditInfo}
                    showTableOfContentsView={this.state.showTableOfContentsView}
                    disableInlineTextEditing={this.props.disableInlineTextEditing}
                    showInquiryResponseHeader={
                      this.state.selectedArticleType ===
                        ChildArticleType.Questions ||
                      this.state.selectedArticleType ===
                        ChildArticleType.Comments ||
                      this.state.selectedArticleType ===
                        ChildArticleType.Response
                    }
                    inlineEditedSlide={this.state.inlineEditedSlide}
                    inquiryResponseMode={InquiryResponseMode.Edit}
                    onSubmitArticle={this.onSubmitArticle}
                    showNotification={this.props.showNotification}
                    openModal={this.props.openModal}
                    closeModal={this.props.closeModal}
                    onArticleInlineSlidesChanged={
                      this.onArticleInlineSlidesChanged
                    }
                    addSlideArticle={this.addSlideArticle}
                    onEditArticle={this.onEditArticle}
                    onDeleteArticle={this.onDeleteArticle}
                    onReply={this.onReply}
                    onEditingText={this.onEditingText}
                    collapseSlides={this.collapseSlides}
                    enterSlideSelectionMode={this.enterSlideSelectionMode}
                    setInlineSlideTextEditInfo={this.setInlineSlideTextEditInfo}
                    updateSlideWithInlineEdits={this.updateSlideWithInlineEdits}
                    onCreateGide={this.onCreateGide}
                    submitSlide={this.submitSlide}
                    updateSlideNumber={this.updateSlideNumber}
                    deleteSlide={this.deleteSlide}
                    copySlides={this.copySlides}
                    moveSlides={this.moveSlides}
                    toggleTableOfContents={this.toggleTableOfContents}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
        <Modal.Content className="gideViewerManagerFooter">
          <Footer
            currentUser={this.props.currentUser}
            article={
              this.state.selectedArticleViewDetail
                ? this.state.selectedArticleViewDetail.article
                : undefined
            }
            viewMode={this.props.viewMode}
            numberOfSlides={
              this.state.selectedArticleViewDetail
                ? this.state.selectedArticleViewDetail.slides.length
                : 0
            }
            currentSlidePosition={
              this.state.selectedArticleViewDetail
                ? this.state.selectedArticleViewDetail.currentSlidePosition
                : 0
            }
            // scrollToSlideById={this.scrollToSlideById}
            headerSlides={this.state.selectedArticleViewDetail ? this.state.selectedArticleViewDetail.slides.filter(s => s.slideType === 'HEADER') : undefined}
            canDisplayViewBar={false}
            viewBarAlwaysDisplayed={false}
            allowBlame={footerArticle ? footerArticle.allowBlame : false}
            showBlame={this.state.showBlame}
            allowSlideComments={
              footerArticle ? footerArticle.allowSlideComments : false
            }
            pauseSlideComments={
              footerArticle ? footerArticle.pauseSlideComments : false
            }
            allowSlideQuestions={
              footerArticle ? footerArticle.allowSlideQuestions : false
            }
            pauseSlideQuestions={
              footerArticle ? footerArticle.pauseSlideQuestions : false
            }
            renderColumns={this.state.renderColumns}
            displayCollapsedHeaders={this.state.displayCollapsedHeaders}
            hideEndSlides={this.state.hideEndSlides}
            textSlideEditing={this.state.textSlideEditing}
            placeholderText={footerPlaceHolderText}
            slideType={this.state.currentSlideType}
            openMenu={this.state.openMenu}
            onCollapseAllSlides={this.collapseAllSlides}
            onExpandAllSlides={this.expandAllSlides}
            onToggleColumns={this.toggleColumns}
            onToggleBlame={this.toggleBlame}
            toggleTableOfContents={this.toggleTableOfContents}
            showNotification={this.props.showNotification}
            toggleCollapsedHeaderDisplay={this.toggleCollapsedHeaderDisplay}
            toggleEndSlideDisplay={this.toggleEndSlideDisplay}
            enterSlideSelectionMode={this.enterSlideSelectionMode}
            // distribute={this.handleDistribute}
            createArticle={this.onCreateGide}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            getAvailableHeaderLevelsForCurrentSlidePosition={
              this.getAvailableHeaderLevelsForCurrentSlidePosition
            }
            onSubmitSlide={this.submitSlide}
            onSubmitMultipleSlides={this.onSubmitMultipleSlides}
            updateSlideNumber={this.updateSlideNumber}
            scrollToSlidePosition={this.scrollToSlidePosition}
            setOpenMenu={this.setOpenMenu}
            showLeftSidebar={false}
            showRightSidebar={false}
            showSignup={noop}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
