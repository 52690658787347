import * as React from 'react';
import { hasValue } from '../../../utils/helperFunctions';
import { CircleIconButton } from '../CircleIconButton/CircleIconButton';
import GideImage from '../Image/GideImage';

export enum HorizontalAlignment {
    Left = 1,
    Right = 2,
    Center = 3,
}
export interface ExpandingIconButtonProps {
    src: string;
    expandedSrc?: string;
    alt: string;
    label: string;
    expanded: boolean;
    visible?: boolean;
    disabled?: boolean;
    expandWidth: number;
    labelLocation: HorizontalAlignment;
    labelClass?: string;
    iconBackgroundColor?: string;
    iconCssClass?: string;
    expandedIconCssClass?: string;
    circleIconButtonCssClass?: string;
    style?: any;
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, expandingButtonComponent: ExpandingIconButton) => void;
}

export interface ExpandingIconButtonState {
    expanded: boolean;
    visible: boolean;
}

export default class ExpandingIconButton extends React.Component<ExpandingIconButtonProps, ExpandingIconButtonState> {
  constructor(props: ExpandingIconButtonProps) {
    super(props);
    this.state = {
        expanded: props.expanded,
        visible: hasValue(props.visible) ? (props.visible as boolean) : true,
    }
  }

  expand(expanded: boolean) {
    this.setState({
      expanded: expanded
    })
  }
  setVisibility(visible: boolean) {
    this.setState({
      visible: visible
    });
  }
  public render() {
    const style = {
      ...(this.props.style), // parenthesis are in case style is not defined.
      width: this.props.expanded === true ? `${this.props.expandWidth}px` : '24px',
      minWidth: this.props.expanded === true ? `${this.props.expandWidth}px` : '24px',
    }
    return (
      <div className={`expandingIconButton${this.props.expanded === true
        ? this.props.expandedSrc ? ' alternateExpanded' : ' expanded' : ''}${this.props.visible !== true ? ' hidden' : ''}`}
        onClick={(e: any) => {
          if(!this.props.disabled) {
            this.props.onClick(e, this);
          }
        }}
        style={style}
      >
        {this.props.expanded === true && this.props.labelLocation === HorizontalAlignment.Left && (
          <span 
            className={this.props.labelClass ? this.props.labelClass :`expandingIconButtonLabel${                
                this.props.expandedSrc ? ' alternateExpandedLabel': ''
              }`}>
            {this.props.label}
          </span>
        )}
        {this.props.expanded && this.props.expandedSrc && (
          <CircleIconButton
            onClick={() => {}}
            className={this.props.circleIconButtonCssClass}
            iconCssClass={this.props.expandedIconCssClass ? this.props.expandedIconCssClass : this.props.iconCssClass}
            image={this.props.expandedSrc}
            alt={this.props.alt}
            backgroundColor={this.props.iconBackgroundColor ? this.props.iconBackgroundColor : 'white'}
            style={{ boxShadow: "initial" }}
          />
        )}
        {(!this.props.expanded || !this.props.expandedSrc) && (
          <GideImage            
            className={`expandingIconButtonImage ${this.props.iconCssClass ? this.props.iconCssClass : 'color-secondary-600-svg'}`}
            src={this.props.src}
            alt={this.props.alt}
          />
        )}
        {this.props.expanded === true && this.props.labelLocation === HorizontalAlignment.Right && (
          <span
            className={`expandingIconButtonLabel${this.props.expandedSrc ? ' alternateExpandedLabel': ''}`}>
            {this.props.label}
          </span>
        )}
      </div>
    );
  }
}
