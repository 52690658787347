import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, List, Image } from 'semantic-ui-react';
import classNames from 'classnames';

import agent from '../../agent';
import { SlideList } from '../Gide/SlideList/SlideList';
import { history } from '../../store';
import {
  ADD_TAG,
  ADD_USER,
  ADD_USERS,
  ADD_VIEWERS,
  EDITOR_PAGE_LOADED,
  REMOVE_TAG,
  REMOVE_USER,
  REMOVE_VIEWER,
  ARTICLE_SUBMITTED,
  EDITOR_PAGE_UNLOADED,
  UPDATE_FIELD_EDITOR,
  USERS_LOADED,
  SET_VIEW_MODE,
  WEBSITE_BODY_ARTICLE_LOADED,
  WEBSITE_HEADER_ARTICLE_LOADED,
  WEBSITE_FOOTER_ARTICLE_LOADED,
  WEBSITE_SIDEBAR_ARTICLE_LOADED,
  COLLAPSE_SLIDE_LIST,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.editor,
    authorizedSlides: state.channel.authorizedSlides,
    slug: ownProps.match ? ownProps.match.params.slug : ownProps.slug,
    users: state.common.users,
    channels: state.home.channels,
    currentUser: state.common.currentUser,
    profile: state.profile,
    websiteHeader: state.channel.websiteHeader,
    websiteBody: state.channel.websiteBody,
    websiteFooter: state.channel.websiteFooter,
    websiteSidebar: state.channel.websiteSidebar,
  };
};

const mapDispatchToProps = dispatch => ({
  onAddTag: () => dispatch({ type: ADD_TAG }),
  onAddUser: () => dispatch({ type: ADD_USER }),
  onAddUsers: users => dispatch({ type: ADD_USERS, users }),
  onAddViewers: users => dispatch({ type: ADD_VIEWERS, users }),
  onLoad: payload => dispatch({ type: EDITOR_PAGE_LOADED, payload }),
  onRemoveTag: tag => dispatch({ type: REMOVE_TAG, payload: {tag} }),
  onRemoveUser: user => dispatch({ type: REMOVE_USER, user }),
  onRemoveViewer: user => dispatch({ type: REMOVE_VIEWER, user }),
  onSubmit: payload => {
    dispatch({ type: ARTICLE_SUBMITTED, payload });
  },
  onLoadWebsiteBodyArticle: payload =>
    dispatch({ type: WEBSITE_BODY_ARTICLE_LOADED, payload }),
  onLoadWebsiteHeaderArticle: payload =>
    dispatch({ type: WEBSITE_HEADER_ARTICLE_LOADED, payload }),
  onLoadWebsiteFooterArticle: payload =>
    dispatch({ type: WEBSITE_FOOTER_ARTICLE_LOADED, payload }),
  onLoadWebsiteSidebarArticle: payload =>
    dispatch({ type: WEBSITE_SIDEBAR_ARTICLE_LOADED, payload }),
  onUnload: payload => dispatch({ type: EDITOR_PAGE_UNLOADED }),
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_EDITOR, payload: {key, value} }),
  onUsersLoaded: payload => dispatch({ type: USERS_LOADED, payload }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  onCollapseSlideList: payload =>
    dispatch({ type: COLLAPSE_SLIDE_LIST, payload }),
});

const Tab = props => {
  if (!props.tab.article) {
    return null;
  }
  const onClick = () => {
    props.handleClick(props.tab);
  };

  return (
    <List.Item
      onClick={onClick}
      className={props.path === props.tab.article.slug ? 'active' : ''}
    >
      <List.Content>{props.tab.text || props.tab.article.title}</List.Content>

      {props.tab.subtabs &&
        props.tab.subtabs.length > 0 && (
          <List className="tabMenu" horizontal>
            {props.tab.subtabs.map((subtab, i) => {
              return (
                <SubTab
                  key={i}
                  tab={subtab}
                  path={props.path}
                  handleClick={props.handleClick}
                />
              );
            })}
          </List>
        )}
    </List.Item>
  );
};

const SubTab = props => {
  const onClick = e => {
    props.handleClick(props.tab);
    e.stopPropagation();
  };

  return (
    <List.Item
      onClick={onClick}
      className={props.path === props.tab.article.slug ? 'active' : ''}
    >
      <List.Content>{props.tab.article.title}</List.Content>
    </List.Item>
  );
};

class Website extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.load = this._load.bind(this);
    this.handleTabClick = this._handleTabClick.bind(this);
    this.handleHomeClick = this._handleHomeClick.bind(this);
    this.shouldShowSidebar = this._shouldShowSidebar.bind(this);
  }

  _shouldShowSidebar() {

    const { channel, tabActive } = this.state;

    if (!channel) return false;

    if (tabActive) {
      if (tabActive.sidebar) {
        return true;
      } else {
        // Check if this is a subtab, in which case use the parent's sidebar
        let tab, subtab;
        channel.tabs.forEach(t => {
          subtab = t.subtabs.find(t => t.article.slug === this.props.path);
          if (subtab) {
            tab = t;
          }
        });
        if (tab && tab.sidebar) {
          return true;
        }
      }
    } else {
      return channel.sidebarHome;
    }
  }

  setFavicon(channel) {
    if (!channel.favicon) return;
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = channel.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  async _handleHomeClick() {
    const { channel } = this.state;
    let article = this.state.articles.find(a => a.title === 'Home');
    if (article) {
      history.push('');
      let resp = await agent.Slides.forArticle(article);
      this.props.onLoadWebsiteBodyArticle({
        article: article,
        slides: resp.slides ? resp.slides : [],
      });
      if (channel.sidebarHome && channel.sidebarGide) {
        let respSidebar = await agent.Slides.forArticle(this.state.channel.sidebarGide);
        this.props.onLoadWebsiteSidebarArticle({
          article: this.state.channel.sidebarGide,
          slides: respSidebar.slides ? respSidebar.slides : [],
        });
      }
      if (article.headersCollapsed) {
        this.props.onCollapseSlideList({ slides: resp.slides });
      }
      this.setState({ tabActive: null });
    }
  }

  async _handleTabClick(tab, skipHistoryUpdate) {
    if (tab && tab.article) {
      if (!skipHistoryUpdate) {
        history.push(tab.article.slug);
      }

      let resp = await agent.Slides.forArticle(tab.article);
      this.props.onLoadWebsiteBodyArticle({
        article: tab.article,
        slides: resp.slides ? resp.slides : [],
      });

      if (tab.sidebar && tab.sidebarArticle) {
        let respSidebar = await agent.Slides.forArticle(tab.sidebarArticle);
        this.props.onLoadWebsiteSidebarArticle({
          article: tab.sidebarArticle,
          slides: respSidebar.slides ? respSidebar.slides : [],
        });
      }
      if (tab.headersCollapsed) {
        this.props.onCollapseSlideList({ slides: resp.slides });
      }
      this.setState({ tabActive: tab });
    }
  }

  async _load(id) {
    let slug = id ? id : this.props.match && this.props.match.params.slug;
    if (!slug) {
      return;
    }
    try {
      const resp = await agent.Channels.getWebsite(slug);
      let articleOptions = resp.articles.map(article => {
        return {
          text: article.title,
          value: article._id,
          headersCollapsed: article.headersCollapsed,
        };
      });

      this.setState({
        channel: resp.channel,
        articleOptions,
        articles: resp.articles,
        loading: false,
      });

      document.title = resp.channel.title;
      this.setFavicon(resp.channel);
      let tab;
      if (this.props.path) {
        tab = resp.channel.tabs.find(t => t.article.slug === this.props.path);
        let subtab;
        if (!tab) {
          resp.channel.tabs.forEach(t => {
            subtab = t.subtabs.find(t => t.article.slug === this.props.path);
            if (subtab) {
              tab = t;
            }
          });
        }
        if (tab) {
          if (subtab) {
            subtab.sidebar = tab.sidebar;
            subtab.sidebarArticle = tab.sidebarArticle;
            this.handleTabClick(subtab, true);
          } else {
            this.handleTabClick(tab, true);
          }
        }
      } else {
        this.handleHomeClick();
      }

      this.props.onLoad();

      if (resp.channel.headerGide) {
        let headerResp = await agent.Slides.forArticle(resp.channel.headerGide);
        this.props.onLoadWebsiteHeaderArticle({
          article: resp.channel.headerGide,
          slides: headerResp.slides,
        });
      }

      if (resp.channel.footerGide) {
        let footerResp = await agent.Slides.forArticle(resp.channel.footerGide);
        this.props.onLoadWebsiteFooterArticle({
          article: resp.channel.footerGide,
          slides: footerResp.slides,
        });
      }

      if (tab && tab.sidebar && tab.sidebarArticle) {
        let sidebarResp = await agent.Slides.forArticle(tab.sidebarArticle);
        this.props.onLoadWebsiteSidebarArticle({
          article: tab.sidebarArticle,
          slides: sidebarResp.slides,
        });
      } else if (resp.channel.sidebarGide) {
        let sidebarResp = await agent.Slides.forArticle(resp.channel.sidebarGide);
        this.props.onLoadWebsiteSidebarArticle({
          article: resp.channel.sidebarGide,
          slides: sidebarResp.slides,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentWillMount() {
    if (this.props.slug) {
      this.load(this.props.slug);
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      let tab = this.state.channel.tabs.find(t => t.article.slug === this.props.path);
      if (tab) {
        this.handleTabClick(tab, true);
      } else {

      }
    }
  }

  render() {
    const { channel } = this.state;
    const { path } = this.props;
    const shouldShowSidebar = this.shouldShowSidebar();
    const headerSlides =
      this.props.websiteHeader && this.props.websiteHeader.slides
        ? this.props.websiteHeader.slides
        : [];
    const footerSlides =
      this.props.websiteFooter && this.props.websiteFooter.slides
        ? this.props.websiteFooter.slides
        : [];
    const websiteSlides =
      this.props.websiteBody && this.props.websiteBody.slides
        ? this.props.websiteBody.slides
        : [];
    const sidebarSlides =
      this.props.websiteSidebar && this.props.websiteSidebar.slides
        ? this.props.websiteSidebar.slides
        : [];
    if (!channel) {
      return <Loader active inline="centered" />;
    }
    let style = {};
    if (channel.font) {
      style.fontFamily = channel.font;
    }
    const classes = classNames('website', channel.tabPreset);
    const logoClasses = classNames('logo', channel.logoPosition);
    const tabClasses = classNames('tabMenu', channel.tabAlignment);
    return (
      <div className={classes} style={style}>
        <div
          className="container page"
          style={{ backgroundColor: channel.backgroundColor }}
        >
          <div className="row">
            <div className="col-xs-12">
              <SlideList slides={headerSlides} hideNumbers view="website" />
              {!channel.logoInline && (
                <Image
                  src={channel.image}
                  alt={`□`}
                  onClick={this.handleHomeClick}
                  className={logoClasses}
                />
              )}
              {(channel.showHomeTab ||
                (channel.tabs && channel.tabs.length > 0)) && (
                <List
                  className={tabClasses}
                  horizontal
                  style={{
                    backgroundColor: channel.menuBackgroundColor,
                    color: channel.menuFontColor,
                  }}
                >
                {channel.logoInline && channel.logoPosition === 'LEFT' && (
                  <GideImage 
                    src={channel.image}
                    alt={`□`}
                    onClick={this.handleHomeClick}
                    style={{float:'left', height: '35px'}}
                  />
                )}
                  {channel.showHomeTab && (
                    <List.Item onClick={this.handleHomeClick}>
                      <List.Content>Home</List.Content>
                    </List.Item>
                  )}
                  {channel.tabs.map((tab, i) => {
                    return (
                      <Tab
                        key={i}
                        tab={tab}
                        path={path}
                        handleClick={this.handleTabClick}
                      />
                    );
                  })}
                  {channel.logoInline && channel.logoPosition === 'RIGHT' && (
                    <GideImage 
                      src={channel.image}
                      alt={`□`}
                      onClick={this.handleHomeClick}
                      style={{float:'right', height: '35px'}}
                    />
                  )}
                </List>
              )}
            </div>
          </div>
          <div className="row content">
            <div className={shouldShowSidebar ? `col-xs-10` : `col-xs-12`}>
                <SlideList slides={websiteSlides} hideNumbers view="website" />
            </div>
            {shouldShowSidebar &&
            <div className="col-xs-2">
                <SlideList slides={sidebarSlides} hideNumbers view="website" />
            </div>
            }
          </div>
          <SlideList slides={footerSlides} hideNumbers view="website" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Website);
