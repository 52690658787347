import React, { Component } from 'react';
import { connect } from 'react-redux';
import superagent from 'superagent';
import {
  Modal,
  Checkbox,
  List,
  Image,
  Button,
  Grid,
  Icon,
} from 'semantic-ui-react';
import DropZone from 'react-dropzone';
import GideImage from '../Shared/Image/GideImage';
import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import { API_ROOT } from '../../constants/paths';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import isNil from 'ramda/es/isNil';

const dropZoneStyles = {
  marginTop: '10px',
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class AudioModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'AUDIO',
        autoplay: false,
        showFileNames: false,
        hasSlideshow: false,
        data: {
          audioCaption: null,
          caption: '',
          files: [],
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    this.onRemoveAudio = audioIdx => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      let files = slide.data.files.slice();
      files.splice(audioIdx, 1);
      slide.data.files = files;
      this.setState({ slide });
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataStateCheckbox = field => (ev, data) => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
      if (!this.state.slide.data.files || !this.state.slide.data.files.length) {
        return alert('Add file(s).');
      }
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide(payload) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.onStartRecordClick = this._onStartRecordClick.bind(this);
    this.onStopRecordClick = this._onStopRecordClick.bind(this);
    this.onPlayClick = this._onPlayClick.bind(this);
    this.linkSlide = this._linkSlide.bind(this);
  }

  _linkSlide() {
    this.props.openModal({
      modalType: 'SlideSelectModal',
      modalProps: {},
    });
  }

  _onPlayClick() {
    if (!this.state.audio) return;
    this.state.audio.play();
  }

  async _onStopRecordClick() {
    if (!this.state.mediaRecorder) return;

    if (this.state.mediaRecorder.state === 'inactive') {
      alert('Not recording.');
      return;
    }
    if (this.state.mediaRecorder.state === 'recording') {
      const audio = await this.state.recorder.stop();
      this.setState({ audio });
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', audio.audioBlob, 'foo.m4a')
        .end((err, res) => {
          if (err) {
            return alert('Please try again.');
          }
          let f = {
            name: 'recorded audio',
            url: res.body.url,
            // url: audio.audioUrl,
          };
          this.addFile(f);
        });
    }
    let tracks = this.state.mediaRecorder.stream.getTracks();
    tracks.forEach(t => {
      t.stop();
    });
    this.setState({ mediaRecorder: null });
  }

  async _onStartRecordClick() {
    const recorder = await this.recordAudio();
    recorder.start();
    this.setState({ recorder });
  }

  recordAudio() {
    return new Promise(resolve => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'video/webm',
        });
        const audioChunks = [];

        mediaRecorder.addEventListener('dataavailable', event => {
          audioChunks.push(event.data);
        });

        this.setState({ mediaRecorder });

        const start = () => {
          mediaRecorder.start();
        };

        const stop = () => {
          return new Promise(resolve => {
            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              const play = () => {
                audio.play();
              };

              resolve({ audioBlob, audioUrl, play });
            });

            mediaRecorder.stop();
          });
        };

        resolve({ start, stop });
      });
    });
  }

  addFile(f) {
    let slide = Object.assign({}, this.state.slide);
    if (slide.data.files) {
      slide.data.files = [...slide.data.files, f];
    } else {
      slide.data.files = [f];
    }
    this.setState({ slide });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    acceptedFiles.forEach(file => {
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', file)
        .end((err, res) => {
          if (err) console.log(err);
          let f = {
            name: file.name,
            url: res.body.url,
          };
          this.addFile(f);
        });
    });
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="audioModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
        style={{ background: 'rgb(59, 128, 169)' }}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(59, 128, 169)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Audio Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-audio.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description
              style={{ background: 'rgb(59, 128, 169)' }}
              className="dataEntry"
            >
              <section>
                <aside>
                  <List>
                    {this.state.slide.data.files &&
                      this.state.slide.data.files.map((f, i) => (
                        <List.Item key={i}>
                          <List.Content>
                            <p style={{ margin: '3px' }}>{f.name}</p>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <audio
                                controls
                                className="audio"
                                autoPlay={false}
                              >
                                <source
                                  src={f.url}
                                  type="audio/aac"
                                  controlsList="nodownload"
                                />
                                Your browser does not support the audio element.
                              </audio>

                              <Button
                                style={{ marginLeft: '5px' }}
                                icon
                                onClick={() => this.onRemoveAudio(i)}
                              >
                                <Icon name="trash" />
                              </Button>
                            </div>
                          </List.Content>
                        </List.Item>
                      ))}
                  </List>
                </aside>
                <aside>
                  <div className="dropzone">
                    <DropZone
                      onDrop={this.onDrop.bind(this)}
                      style={dropZoneStyles}
                    >
                      <p>Drop files or select.</p>
                    </DropZone>
                  </div>
                </aside>
              </section>
              <div className="recordingBtns">
                <Button.Group>
                  {this.state.mediaRecorder ? (
                    <div onClick={this.onStopRecordClick} className="pointer">
                      <GideImage src="/images/mic2.png" alt={`□`} />
                    </div>
                  ) : (
                    <div className="pointer" onClick={this.onStartRecordClick}>
                      <GideImage src="/images/mic1.png" alt={`□`} />
                    </div>
                  )}
                </Button.Group>
              </div>
              <div>
                <Checkbox
                  label="Autoplay"
                  onChange={this.updateDataStateCheckbox('autoplay')}
                  checked={this.state.slide.data.autoplay}
                />
              </div>
              <div>
                <Checkbox
                  label="Show File Names"
                  onChange={this.updateDataStateCheckbox('showFileNames')}
                  checked={this.state.slide.data.showFileNames}
                />
              </div>
              <div>
                <Checkbox
                  label="Create Slideshow"
                  onChange={this.updateDataStateCheckbox('hasSlideshow')}
                  checked={this.state.slide.data.hasSlideshow}
                />
              </div>
              {this.state.hasSlideshow && (
                <div className="slideShowPreview">
                  <Grid relaxed columns={4} onClick={this.linkSlide}>
                    <Grid.Column />
                    <Grid.Column />
                    <Grid.Column />
                  </Grid>
                </div>
              )}
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioModal);
