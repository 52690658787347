
import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import styles from './_sidebar.module.scss';
import classNames from 'classnames';

export interface SidebarProps {
    style?: React.CSSProperties;
    className?: string;
    mainContentClassName?: string;
    sidebarContentClassName?: string;
    leftContent?: React.ReactNode;
    leftWidth?: string;
    leftIsVisible?: boolean;
    rightContent?: React.ReactNode;
    rightWidth?: string;
    rightIsVisible?: boolean;
    overlay?: boolean;
    isOverlayInverted?: boolean;
    transitionSpeed?: string;
    onHide?: () => void;
    onShow?: () => void;
    onOverlayClicked?: () => void;
}

export const Sidebar: React.FC<SidebarProps> = (props, context) => {
    const leftWidth = (props.leftWidth || "340px");
    const rightWidth = (props.rightWidth || "340px");
    const transitionSpeed = props.transitionSpeed || "0.3s";
    const visible = props.leftIsVisible || props.rightIsVisible;
    
    return (
        <div style={{...props.style, display: "flex" }} className={classNames(props.className, props.overlay ? styles.forceOverlay : undefined)}>
            { props.leftContent && (
                <SidebarContent isLeft                    
                    className={props.sidebarContentClassName}
                    transitionSpeed={transitionSpeed}
                    visible={props.leftIsVisible}
                    width={leftWidth}
                    >
                    {props.leftContent}
                </SidebarContent>
            )}
            <div style={{ flex: "1 1 auto" }} className={props.mainContentClassName}>
                {props.children}                
            </div>
            { props.rightContent && (
                <SidebarContent isRight
                    className={props.sidebarContentClassName}
                    transitionSpeed={transitionSpeed}
                    visible={props.rightIsVisible}
                    width={rightWidth}
                    >
                    {props.rightContent}
                </SidebarContent>
            )}
            { visible && 
                <Dimmer.Dimmable className={styles.sidebarDimmer} style={{zIndex:2}}
                        onClick={() => props.onOverlayClicked && props.onOverlayClicked()}>
                    <Dimmer active={visible} inverted={!props.isOverlayInverted} />
                </Dimmer.Dimmable>
            }
        </div>
    );
}

interface SidebarContentProps {
    className?: string;
    visible?: boolean;
    transitionSpeed?: string;
    isLeft?: boolean;
    isRight?: boolean;
    width?: string;
}
const SidebarContent: React.FC<SidebarContentProps> = (props) => (
    <div className={classNames(props.className, styles.sidebar)} style={{ 
        transition: `width ${props.transitionSpeed}, left ${props.transitionSpeed}, right ${props.transitionSpeed}`,
        width: props.visible ? props.width : '0px',
        left: !props.isLeft ? undefined : 0,
        right: !props.isRight ? undefined : 0,
    }}>
        <div className={styles.sidebarInner} style={{ width: props.width }}>
            {props.children}
        </div>
    </div>
);
