import React, { useState, useCallback } from 'react';
import { MediaFile, ImageFile } from '../../../models/SlideFile';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import SlideEditorCommandBar from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import icons from '../../../assets/icons';
import MediaChooser, { MultiMediaType, MediaChooserAction } from '../../DataEntry/CreationProcess/MediaChooser/MediaChooser';
import { v4 } from 'uuid';
import ProgressBar from '../ProgressBar/ProgressBar';
import { darkTheme } from '../../../themes/custom-theme';
import { NotificationType } from '../../../constants/strings';
import { ensureImageFileOrientation } from '../../../utils/helperFunctions';
import ImageEditor from '../../Viewers/ImageViewers/ImageEditor/ImageEditor';

type ImagePickerMode = 'New' | 'Camera' | 'Uploading' | 'Crop' | 'CroppingImage';

export interface ImagePickerProps {
  title: string;
  onSave: (image: ImageFile) => void;
  onCancel: () => void;
  onError: (error: string) => void;
  skipCropping?: boolean;
  aspectRatio?: number;
}

export const ImagePicker: React.FC<ImagePickerProps> = (props) => {
  const [mode, setMode] = useState<ImagePickerMode>('New');
  const [mediaChooserAction, setMediaChooserAction] = useState<MediaChooserAction>();
  const [image, setImage] = useState<ImageFile>();

  const navigateBack = useCallback(() => {
    setMediaChooserAction({type: 'StopCamera'});
    if (mode === 'Camera' || mode === 'Uploading') {
      setMode('New');
    } else {
      props.onCancel();
    }
  }, [mode, setMode, props.onCancel]);

  return (
    <div style={{
      backgroundColor: 'black',
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <SlideEditorCommandBar
        backIconMode={BackIconMode.Light}
        showBackNavigationButton={true}
        showDefaultActions={false}
        onExit={navigateBack}
        onNavigateBack={navigateBack}
        theme={darkTheme}
      >
        <EditorState title={props.title}
          labelColor="var(--WHITES-NORMAL-1000)"
          backgroundColor="var(--COLOR-BLUE-100)"
          icon={<icons.ContentAlteration_Camera_Default color="var(--WHITES-NORMAL-1000)" />}
        />
      </SlideEditorCommandBar>
      <div style={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 52px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: mode === 'Uploading' ? 'center' : 'initial',
        overflow: 'auto',
      }}>
        {(mode === 'New' || mode === 'Camera') &&
          <MediaChooser
            style={{
              maxWidth: '768px',
            }}
            mediaType={MultiMediaType.Image}
            maxUploadSize={10}
            onCameraStarted={(cameraType) => setMode(cameraType === 'InApp' ? 'Camera' : 'Uploading')}
            onMediaCreated={(mediaUrls, fileType) => {
              const newFiles: MediaFile[] = [];
              mediaUrls.forEach((mediaUrl: any) => {
                newFiles.push({
                  id: v4(),
                  name: 'name',
                  url: URL.createObjectURL(mediaUrl),
                  dataUrl: mediaUrl,
                  processingId: v4(),
                  type: fileType,
                  caption: '',
                });
              });
              const firstFile = newFiles[0]; // Only take the first file. (Maybe show an error if you selected multiple?)
              setImage(firstFile);
              if (props.skipCropping) {
                props.onSave(firstFile);
              } else {
                setMode('Crop');
              }
            }}
            onSavingMedia={() => setMode('Uploading')}
            onError={props.onError}
            action={mediaChooserAction}
          />
        }
        {mode === 'Uploading' &&
          <div className="loadingOverlay">
            <div className="progressIndicator">
              <ProgressBar indicatorWidth={30} />
            </div>
            <span className="loadingMessage">
              Processing image
            </span>
          </div>
        }
        {mode === 'Crop' &&
          <div style={{
            display: 'flex',
            flex: 1,
            maxWidth: '100%',
            height: '100%',
            flexDirection: 'row'}}>
            <ImageEditor
              image={{url: image?.url || '', type: 'IMAGE'}}
              saveImage={async (image: any) => {
                setMode('CroppingImage');
                // catch the returned data and add to list. will need to save it
                const imageFile = await ensureImageFileOrientation(image);
                const croppedFile: ImageFile = {
                  id: v4(),
                  name: 'name',
                  url: URL.createObjectURL(imageFile),
                  dataUrl: imageFile,
                  processingId: v4(),
                  type: 'UPLOAD',
                  caption: '',
                };
                props.onSave(croppedFile);
              }}
              responseType='base64'
              cropperOptions={{
                guides : true,
                autoCropArea: 1,
                rotatable: true,
                aspectRatio: props.aspectRatio
              }}
            />
          </div>
        }
        {mode === 'CroppingImage' &&
          <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <div className="loadingOverlay">
              <div className="progressIndicator">
                <ProgressBar indicatorWidth={30} />
              </div>
              <span className="loadingMessage">
                Cropping image
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
