import React, { useRef, useCallback, useEffect, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import PopperJs, { ReferenceObject } from 'popper.js';
import Viewbar, { ViewbarProps } from './Viewbar';
import ResizeObserver from "resize-observer-polyfill";

export interface ViewbarPopperProps extends ViewbarProps {
  anchorEl?: Element;
  resizeEl?: Element;
  style?: React.CSSProperties;
}

export const ViewbarPopper: React.FC<ViewbarPopperProps> = (props) => {
  const { anchorEl, ...viewbarProps } = props;
  const popperRef = useRef<PopperJs>(null);

  const [resizeElHeight, setResizeElHeight] = useState(() => props.resizeEl && props.resizeEl.clientHeight);
  useEffect(() => {
    if (props.resizeEl) {
      setResizeElHeight(props.resizeEl.clientHeight);
    }
  }, [props.resizeEl]);

  const onRenderingViewbar = useCallback(() => {
    if (props.resizeEl) {
      setResizeElHeight(props.resizeEl.clientHeight);
    }
    popperRef.current?.update();
    props.onRenderingViewbar && props.onRenderingViewbar();
  }, [props.onRenderingViewbar]);
  const [resizeObserver] = useState(() => new ResizeObserver(onRenderingViewbar));
  const viewbarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    props.resizeEl && resizeObserver.observe(props.resizeEl);
    props.anchorEl && resizeObserver.observe(props.anchorEl);
    viewbarRef.current && resizeObserver.observe(viewbarRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [props.anchorEl, props.resizeEl, viewbarRef.current]);

  return (<>
    {props.anchorEl &&
      <Popper
        anchorEl={props.anchorEl}
        placement="top"
        open={true}
        popperRef={popperRef}
        popperOptions={{
          modifiers: {
            flip: {
              enabled: false,
            },
            offset: {
              enabled: false,
              // offset: "-4, 0",
            },
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
        }}
        style={props.style}
      >
        <Viewbar {...viewbarProps} maxHeight={resizeElHeight} onRenderingViewbar={onRenderingViewbar} viewbarRef={viewbarRef} />
      </Popper>
    }
    </>
  );
}

export default ViewbarPopper;
