import React, { FunctionComponent } from 'react';
import { Author } from '../../../models/Author';
import GideImage from '../Image/GideImage';

export enum InquiryResponseMode {
  Edit = 1,
  View = 2,
  Approval = 3,
  Response = 4,
  Create = 5,
}

export interface InquiryResponseHeaderProps {
  articleId: string;
  author: Author;
  headingText: string;
  inquiryResponseMode: InquiryResponseMode;
  isInlineView?: boolean;
  ownerType?: string;
  displayingOwner?: boolean;
  onBack?: () => void;
  onPost?: () => void;
  onEdit?: () => void;
  onReply?: () => void;
  onApprove?: (articleId: string) => void;
  onCancel?: (articleId: string) => void;
  onDelete?: (articleId: string) => void;
  toggleOwnerSlide?: () => void;
}

export const InquiryResponseHeader: FunctionComponent<
  InquiryResponseHeaderProps
> = (props: InquiryResponseHeaderProps) => {
  return (
    <span
      className={`inquiryResonseHeaderContainer ${
        props.isInlineView ? 'inlineView' : ''
      }`}
    >
      <div className="inquiryResponseHeader">
        {props.inquiryResponseMode === InquiryResponseMode.Response && (
          <GideImage src="/icons/nav/arrow/arrow-left.svg"
            className="hoverIcon"
            onClick={props.onBack}
            alt="close dialog"
          />
        )}
        <GideImage 
          className="authorHeaderImage"
          src={props.author.image}
          alt={props.author.image}
        />
        <div className="authorHeaderTitle">{props.author.username}</div>
        <div className="inquiryResponseHeaderText">{props.headingText}</div>
      </div>
      <div className="inquiryResponseActions" style={{ marginRight: '8px' }}>
        {props.inquiryResponseMode !== InquiryResponseMode.View &&
          props.onCancel && (
            <div
              className="action cancel"
              onClick={() => {
                props.onCancel && props.onCancel(props.articleId);
              }}
            >
              delete
            </div>
          )}
        {props.inquiryResponseMode !== InquiryResponseMode.View &&
          props.onPost && (
            <div className="action post" onClick={props.onPost}>
              Post
            </div>
          )}
        {props.inquiryResponseMode === InquiryResponseMode.View &&
          props.onEdit && (
            <>
              {/* <div className="button edit" onClick={props.onEdit}>
                Edit
              </div> */}
              <GideImage 
                onClick={props.onEdit}
                style={{ cursor: 'pointer', marginRight: '18px' }}
                className="color-secondary-600-svg"
                src="/icons/nav/edit/edit-main.svg"
                alt="edit"
              />
            </>
          )}
        {props.inquiryResponseMode === InquiryResponseMode.View &&
          props.onDelete && (
            <GideImage 
              style={{ cursor: 'pointer', marginRight: '18px' }}
              onClick={() => {
                props.onDelete && props.onDelete(props.articleId);
              }}
              className="color-secondary-600-svg"
              src="/icons/content-alteration/delete.svg"
              alt="reply"
            />
          )}
        {props.inquiryResponseMode === InquiryResponseMode.View &&
          props.onApprove && (
            <>
              <GideImage 
                onClick={() => {
                  props.onApprove && props.onApprove(props.articleId);
                }}
                style={{ cursor: 'pointer', marginRight: '18px' }}
                className="color-secondary-600-svg"
                src="/icons/content-alteration/check/main.svg"
                alt="approve"
              />
            </>
          )}
        {props.inquiryResponseMode === InquiryResponseMode.View &&
          props.onReply && (
            <GideImage 
              style={{ cursor: 'pointer', marginRight: '18px' }}
              onClick={props.onReply}
              className="color-secondary-600-svg"
              src="/icons/content-alteration/reply.svg"
              alt="reply"
            />
          )}

        {props.inquiryResponseMode === InquiryResponseMode.View && (
          <GideImage 
            style={{ cursor: 'pointer', marginRight: '6px' }}
            src="/icons/nav/threedots/vertical.svg"
            alt="menu"
          />
        )}
        {props.inquiryResponseMode === InquiryResponseMode.Response &&
          props.toggleOwnerSlide && (
            <>
              <div className="button edit" onClick={props.toggleOwnerSlide}>
                {props.ownerType}
              </div>
              <GideImage 
                src={`/icons/nav/arrow/${
                  props.displayingOwner ? 'arrow-up.svg' : 'arrow-down.svg'
                }`}
                className="hoverIcon color-secondary-800-svg"
                onClick={props.toggleOwnerSlide}
                alt="toggle dialog display view"
              />
            </>
          )}
      </div>
    </span>
  );
};
