import React, { Component } from 'react';
import AudioView from '../AudioView/AudioView';
import { Slide } from '../../../../models/Slide';
import { getPreviewOverlayIcon, SlideType } from '../../../../utils/helperFunctions';
import { v4 } from 'uuid';
import { CarouselHeightSettings } from '../../ImageViewers/ImageCarousel/ImageCarousel';
import GideImage from '../../../Shared/Image/GideImage';
import { AudioSlideFile } from '../../../../models/SlideFile';

export interface AudioCarouselProps {
  slide: Slide;
  maxResponsiveHeight: number;
  maxWidescreenHeight: number;
  respectEdge: boolean;
  onClick?: () => void;
}

export interface AudioCarouselState {
  selectedItemIndex: number;
  maxCarouselHeight: number;
  carouselHeightSettings?: CarouselHeightSettings;
  captionHeight: number;
}

export default class AudioCarousel extends Component<AudioCarouselProps, AudioCarouselState> {
  constructor(props: AudioCarouselProps) {
    super(props);
    this.state = {
      selectedItemIndex: 0,
      maxCarouselHeight: window.innerWidth <= 520 ? 124 : 124, // TODO: Decide what these values should be
      captionHeight: 0,
    };
  }

  private updateWindowDimensions = async (): Promise<void> => {    
    if (this.state.carouselHeightSettings) {
      // console.log(
      //   'updateWindowDimensions: ',
      //   'state.maxCarouselHeight: ', this.state.maxCarouselHeight, 
      //   'props.maxResponsiveHeight: ', this.props.maxResponsiveHeight, 
      //   'window.innerWidth: ', window.innerWidth,
      //   'state.carouselHeightSettings.maxWidescreenHeight: ', this.state.carouselHeightSettings.maxWidescreenHeight,
      //   'state.carouselHeightSettings.maxResponsiveHeight: ', this.state.carouselHeightSettings.maxResponsiveHeight
      //   );
      if (this.state.maxCarouselHeight > this.props.maxResponsiveHeight && window.innerWidth <= 520) {
        this.setState({
          maxCarouselHeight: this.state.carouselHeightSettings.maxResponsiveHeight
        });
      } else if (this.state.maxCarouselHeight > this.props.maxResponsiveHeight && window.innerWidth <= 752) {
        this.setState({
          maxCarouselHeight: 400
        });
      } else if (this.state.maxCarouselHeight < this.state.carouselHeightSettings.maxWidescreenHeight && window.innerWidth > 520) {
        this.setState({
          maxCarouselHeight: this.state.carouselHeightSettings.maxWidescreenHeight
        });
      }
    }
  }

  async componentDidMount() {
    const carouselHeightSettings = await this.getmaxCarouselHeight(this.props.slide.data.files);
    this.setState({
      maxCarouselHeight: window.innerWidth <= 520
        ? carouselHeightSettings.maxResponsiveHeight
        : carouselHeightSettings.maxWidescreenHeight,
      carouselHeightSettings
    });
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: AudioCarouselProps, prevState: AudioCarouselState) {
    if (this.audioCaption && prevState.captionHeight !== this.audioCaption.clientHeight) {
      this.setState({ captionHeight: this.audioCaption.clientHeight })
    }
    if (prevState.carouselHeightSettings && this.props.maxWidescreenHeight !== prevProps.maxWidescreenHeight) {
      const carouselHeightSettings: CarouselHeightSettings = {
        ...prevState.carouselHeightSettings,
        maxWidescreenHeight: Math.min(prevState.carouselHeightSettings.calculatedMaxCarouselHeight, this.props.maxWidescreenHeight),
      }
      this.setState({
        carouselHeightSettings,
        maxCarouselHeight: window.innerWidth <= 520 ? 300 : window.innerWidth <= 752 ? 400 : carouselHeightSettings.maxWidescreenHeight,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  private async getmaxCarouselHeight(audioSlideFiles: AudioSlideFile[]): Promise<CarouselHeightSettings> {
    let maxCarouselHeight = 83.5; // Min value for EMBED
    for (let i = 0; i < audioSlideFiles.length; i++) {
      // TODO: Determine the max height of the slides available. 
      if(audioSlideFiles[i].type === 'SLIDEFILE') {
        maxCarouselHeight = Math.max(maxCarouselHeight, 124);
      } else if (audioSlideFiles[i].type === 'EMBED') {
        maxCarouselHeight = Math.max(maxCarouselHeight, 83.5);
      } else { // UPLOAD and RECORDING
        maxCarouselHeight = Math.max(maxCarouselHeight, 100);
      }
    }
    return {
      maxWidescreenHeight: Math.min(maxCarouselHeight, this.props.maxWidescreenHeight),
      maxResponsiveHeight: Math.min(maxCarouselHeight, this.props.maxResponsiveHeight),
      calculatedMaxCarouselHeight: maxCarouselHeight,
    };
  }

  audioCaption?: HTMLDivElement | null;
  public render() {
    const selectedImage = this.props.slide.data.files[this.state.selectedItemIndex];
    const audioHeight = selectedImage.audioCaption ? 24 : 0;
    const attributionHeight = selectedImage.attribution || selectedImage.type === "LINK" ? 20 : 0;
    const maxHeight = this.state.maxCarouselHeight - this.state.captionHeight - audioHeight - attributionHeight;
    const marginTop = 
      this.props.slide.data.files.find((s: AudioSlideFile) => s.type === "SLIDE")
      && (selectedImage.type === 'UPLOAD' || selectedImage.type === 'RECORDING')
      ? '62px' : '0px';

    return (      
      <div className="audioCarouselContainer">
        <div className="audioCarouselAudioPlayer"
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
          style={{
            maxHeight: maxHeight,
            height: maxHeight,
            minHeight: maxHeight,
          }}
        >
          {this.props.slide.data.files.map((audioFile: AudioSlideFile, index: number) => (
            <AudioView
              style={{marginTop: marginTop,}}
              key={v4()}
              slide={this.props.slide}
              autoPlay={false} // TODO: Need to handle autoplaying multiple audio files in succession.
              audioEnded={() => console.log('TODO: Handle Audio Ended')}
              audioFile={audioFile}
              isReferenceType={audioFile.type === 'SLIDEFILE'}
              hidden={index !== this.state.selectedItemIndex}
            />)
          )}

        </div>
        <div className="thumbnailPanel">
          {this.props.slide.data.files.map((audioFile: AudioSlideFile, i: number) => {
            return (
              <div key={`thumbnailPanel${i}`} className={`thumbnailContainer  ${i === this.state.selectedItemIndex ? '' : ' inactive'}`}>
                <GideImage 
                  className={`thumbnailImage whites-normal-1000-svg`}
                  key={`thumbnail${i}`}
                  src={getPreviewOverlayIcon(SlideType.Audio, audioFile.type)}
                  alt={audioFile.name ? audioFile.name : 'thumbnail'}
                  onClick={() => {
                    this.setState({ selectedItemIndex: i });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
