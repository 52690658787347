import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Icon,
  List,
  Image,
  Input,
  Menu,
  Dropdown,
  Button,
  Grid,
  Container,
} from 'semantic-ui-react';

import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  USERS_LOADED,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import { contentMetaData } from '../../constants/contentMetaData';
import slideTools from '../../slideTools';
import GideImage from '../Shared/Image/GideImage';
import isNil from 'ramda/es/isNil';

const socials = [
  { name: 'Facebook', image: 'facebook.png' },
  { name: 'Instagram', image: 'instagram.png' },
  { name: 'Snapchat', image: 'snapchat.png' },
  { name: 'YouTube', image: 'youtube.png' },
  { name: 'LinkedIn', image: 'linkedin.png' },
  { name: 'Reddit', image: 'reddit.png' },
];

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  users: state.common.users,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  onUsersLoaded: payload => dispatch({ type: USERS_LOADED, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class ContactsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactType: '',
      name: '',
      link: '',
      phone: '',
      slide: {
        slideType: 'CONTACTS',
        data: {
          audioCaption: null,
          caption: '',
          contacts: [],
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    // TODO: REPLACE was from version 1 of the Template. With the new version check to see if this is no longer used and needs to be removed.
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };
    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = data.checked;
      this.setState({ slide });
    };

    this.updateDataStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };
    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.handleBackClicked = ev => {
      this.setState({ contactType: '' });
    };

    this.handlePhoneClicked = ev => {
      this.setState({ contactType: 'PHONE' });
    };

    this.handleSocialClicked = ev => {
      this.setState({ contactType: 'SOCIAL' });
    };

    this.handleGidesClicked = ev => {
      this.props.onUsersLoaded(agent.Users.getAll());
      this.setState({ contactType: 'GIDES' });
    };

    this.setName = ev => {
      this.setState({ name: ev.target.value });
    };

    this.setLink = ev => {
      this.setState({ link: ev.target.value });
    };

    this.setPhone = ev => {
      this.setState({ phone: ev.target.value });
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.addContact = ev => {
      let slide = Object.assign({}, this.state.slide);
      let c = {
        name: this.state.name,
        link: this.state.link,
        phone: this.state.phone,
      };
      slide.data.contacts = [...slide.data.contacts, c];
      this.setState({
        name: '',
        link: '',
        phone: '',
        slide,
      });
    };

    this.createSlide = async () => {
      let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.props.editSlide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.onRemove = this._onRemove.bind(this);
    this.handleAddUsers = this._handleAddUsers.bind(this);
    this.handleSelectedUser = this._handleSelectedUser.bind(this);
  }

  _handleSelectedUser(ev, data) {
    this.setState({ usersSelected: data.value });
  }

  _onRemove(idx) {
    if (!window.confirm('Are you sure?')) return;
    let slide = Object.assign({}, this.state.slide);
    let contacts = slide.data.contacts.slice();
    contacts.splice(idx, 1);
    slide.data.contacts = contacts;
    this.setState({ slide });
  }

  _handleAddUsers(ev) {
    let contacts = [];
    this.state.usersSelected.forEach(id => {
      let u = this.props.users.find(user => user._id === id);
      let contact = {
        name: u.username,
        link: `http://localhost:4100/@${u.username}`,
        image: u.image,
        id: u._id,
        type: 'GIDES',
      };
      contacts.push(contact);
    });
    const slide = Object.assign({}, this.state.slide);
    slide.data.contacts = contacts;
    this.setState({ slide, usersSelected: [] });
  }

  render() {
    const { slide } = this.state;
    const { users } = this.props;
    let userOptions = [];
    if (users) {
      userOptions = users.map(user => {
        return {
          text: user.username,
          value: user._id,
          image: {
            avatar: true,
            src: user.image,
          },
        };
      });
    }
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="contactModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="modalHeader">
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Contact Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-contact.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              {this.state.contactType === '' && (
                <Menu fluid vertical style={{ margin: '0 0 0 0' }}>
                  <Menu.Item onClick={this.handlePhoneClicked}>
                    Phone Contact
                  </Menu.Item>
                  <Menu.Item onClick={this.handleSocialClicked}>
                    Social Media Contact
                  </Menu.Item>
                  <Menu.Item onClick={this.handleGidesClicked}>
                    Gides User
                  </Menu.Item>
                </Menu>
              )}
              {this.state.contactType === 'PHONE' && (
                <div className="dataEntry">
                  <p>Contacts:</p>
                  <section>
                    <Input
                      fluid
                      placeholder="Name"
                      value={this.state.name}
                      onChange={this.setName}
                    />
                  </section>
                  <section>
                    <Input
                      fluid
                      placeholder="phone number"
                      value={this.state.phone}
                      onChange={this.setPhone}
                    />
                  </section>
                  <section>
                    <Button
                      type="button"
                      onClick={this.addContact}
                      disabled={!this.state.name.length}
                    >
                      <Icon name="add" disabled={!this.state.name.length} />
                    </Button>
                  </section>
                </div>
              )}
              {this.state.contactType === 'SOCIAL' && (
                <div className="dataEntry">
                  <Container>
                    <Grid columns={3} padded centered className="socialChoices">
                      {socials.map((s, i) => {
                        return (
                          <Grid.Column
                            key={i}
                            className={
                              this.state.social === s.name ? 'active' : ''
                            }
                            onClick={() => {
                              this.setState({ social: s.name });
                            }}
                          >
                            <GideImage 
                              src={`/images/social/${s.image}`}
                              alt={s.name}
                            />
                          </Grid.Column>
                        );
                      })}
                    </Grid>
                    <section>
                      <Input
                        fluid
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.setName}
                      />
                    </section>
                    <section>
                      <Input
                        fluid
                        placeholder="URL link"
                        value={this.state.link}
                        onChange={this.setLink}
                      />
                    </section>
                    <section>
                      <Button
                        type="button"
                        onClick={this.addContact}
                        disabled={!this.state.name.length}
                      >
                        <Icon name="add" disabled={!this.state.name.length} />
                      </Button>
                    </section>
                  </Container>
                </div>
              )}
              {this.state.contactType === 'GIDES' && (
                <div className="dataEntry">
                  <fieldset>
                    <h2>Users</h2>
                    <Dropdown
                      value={this.state.usersSelected || []}
                      placeholder="Select User(s)"
                      search
                      fluid
                      selection
                      multiple
                      closeOnChange
                      options={userOptions}
                      onChange={this.handleSelectedUser}
                    />
                    <Button onClick={this.handleAddUsers} icon>
                      <Icon name="add" />
                    </Button>
                  </fieldset>
                </div>
              )}
              {this.state.contactType !== '' && (
                <div className="dataEntry">
                  <List selection>
                    {slide.data.contacts.map((contact, i) => {
                      return (
                        <List.Item key={i}>
                          {/* <List.Content floated="right">
                            <Button icon onClick={() => this.onRemove(i)}>
                              <Icon name="trash" />
                            </Button>
                          </List.Content> */}
                          <List.Content>
                            <div className="flexRowFull">
                              <div className="flexRowFull">
                                <Image
                                  avatar
                                  src={
                                    contact.image ||
                                    'https://static.productionready.io/images/smiley-cyrus.jpg'
                                  }
                                />
                                <List.Header as="a">{contact.name}</List.Header>
                                <List.Description>
                                  {contact.link} {contact.phone}
                                </List.Description>
                              </div>
                              <Button icon onClick={() => this.onRemove(i)}>
                                <Icon name="trash" />
                              </Button>
                            </div>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </div>
              )}
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {this.state.contactType !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['CONTACTS'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateState('caption').bind(this)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={() => this.handleBackClicked.bind(this)}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide.bind(this)}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsModal);
