import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState,
  ProgressIndicatorType,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { Slide } from '../../../models/Slide';
import { darkTheme, lightTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import { LabeledTextInput } from '../../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput';
import ImageView from '../../Viewers/ImageViewers/ImageView/ImageView';
import { v4 } from 'uuid';
import VideoViewer from '../../Viewers/VideoViewers/VideoViewer/VideoViewer';
import LinkView from '../../Viewers/LinkViewers/LinkView/LinkView';
import { HyperLinkLayout } from '../../../models/CommonEnums';
import { getLinkPreviewDetails, isValidURL } from '../../../utils/helperFunctions';
import { addHttpIfAbsentUrl, getImageMeta, getMediaLinkUrl } from '../../../utils/helperFunctions';
import { NotificationType } from '../../../constants/strings';
import { NotificationInline } from '../../Shared/Notification/NotificationInline';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import icons from '../../../assets/icons';
import { LinkModes } from './SharedLogicForLinkSlideEditor';
import { AnonymousUser } from '../../../models/User';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isImageSlide, isVideoSlide, isLinkSlide } = context;

  const title =
    isImageSlide ? 'an image' :
    isVideoSlide ? 'a video' :
    isLinkSlide ? 'a url' :
      "ERROR";

  return {
    ...commonSlideEditorModalProps,
    onSlideUpdated: (slide: Slide) => {
      setState({
        ...state,
        mode: state.mode,
        slide: {
          ...slide,
          data: {
            ...slide.data,
            title: slide.data.caption as string,
          },
        },
      });
    },
    onDisplaySlideWidthSettings: () => {
      setState({ ...state, mode: CommonModes.Dimensions });
    },
    backIconMode: isLinkSlide ? BackIconMode.Light : BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: state.loadingInfo,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: !isLinkSlide || state.slide.data.files.length >= 1,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon(`Link ${title}`, '/icons/slidetype/link/main.svg', isLinkSlide ? 'black' : 'white', 'blue'),
    onNavigateBack: () => {
      const mode = !state.slide.data.files.length ? CommonModes.New 
        : state.selectedItemIndex > -1 ? CommonModes.Edit
        : CommonModes.Preview;
      setState({ ...state, mode });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps, theme: any) => {
  const { state, setState, isImageSlide, isVideoSlide, isLinkSlide } = context;
  const link = state.url;
  const type = 
    isImageSlide ? 'LINK' :
    isVideoSlide ? 'URL' :
    isLinkSlide ? 'URL' :
      'ERROR';
  const newFile = {
    id: v4(),
    displayMode: HyperLinkLayout.HyperLink,
    url: link,
    caption: '',
    type,
    name: '', 
    attribution: '',
    title: undefined as string | undefined,
    image: undefined as any,
    description: undefined as string | undefined,
  };

  const onConfirm = async (text: string) => {
    
    let newFileWithDetails = newFile;

    if (isLinkSlide) {
      const linkFileDetails = await getLinkPreviewDetails(text);

      const displayMode = linkFileDetails.title && linkFileDetails.image
        ? HyperLinkLayout.BlogImageLeft
        : HyperLinkLayout.HyperLink;

      newFileWithDetails = {
        ...newFile,
        displayMode,
        url: linkFileDetails.url,
        title: linkFileDetails.title,
        image: linkFileDetails.image,
        description: linkFileDetails.description,
      };
    }

    const slide = {
      ...state.slide,
      data: {
        ...state.slide.data,
        files: [...state.slide.data.files, newFileWithDetails],
      },
    };
    setState({
      ...state,
      mode: CommonModes.Edit,
      slide,      
      selectedItemIndex: slide.data.files.length - 1,
      url: undefined,
    });
  }

  const title =
    isImageSlide ? 'an image' :
    isVideoSlide ? 'a video' :
    isLinkSlide ? 'a url' :
      "ERROR";

  const getFileView = () => 
    context.isImageSlide ? (
      <ImageView
        imageFile={newFile}
        isReferenceType={false}
        className="containImage"
      />
    ) :
    context.isVideoSlide ? (
      <VideoViewer 
        videoFile={newFile}
      />
    ) :
    context.isLinkSlide ? (
      <div style={{width: "100%", height: "100%", maxWidth: '752px', justifyContent: 'center'}}>        
      
       <LinkView
         linkFile={newFile}
         isReferenceType={false}
         currentUser={AnonymousUser}
         view={''}
         viewMode={'SLIDE'}
         overrideWidth={state.slide.width}
       />
      </div>
    ) :
      "ERROR";

  const onAccept = async (text: string) => {
    //context.notificationDispatch({ type: 'clear' });
    context.commonSlideEditorModalProps.showNotification();
    if (!text) {
      return false;
    } else {
      const url = addHttpIfAbsentUrl(text);
      let isValid = false;
      if (isImageSlide) { 
        try {
          setState(state => ({...state, loadingInfo: { progressIndicatorType: ProgressIndicatorType.Indeterminate }}));
          const image = await getImageMeta(url);
          isValid = !!image;
        } catch (error) {
          isValid = false;
        } finally {
          setState(state => ({...state, loadingInfo: undefined }));
        }
      } else if (isVideoSlide) { 
        const meta = getMediaLinkUrl(url);
        if (meta.type === 'video') {
          isValid = true;
        }
      } else if (isLinkSlide) {
        isValid = isValidURL(url);
      }
      if (isValid) {
        setState(state => ({
          ...state,
          url: text,
        }));
        return true; // Accepted.
      } else {
        // context.notificationDispatch({ type: 'inline', notification: { type: NotificationType.ERROR, title: "Invalid link!", message: 'The link you want is not valid.' } });
        context.commonSlideEditorModalProps.showNotification({ target: 'inline', type: NotificationType.ERROR, title: "Invalid link!", message: 'The link you want is not valid.' });
        return false;
      }
    }
  }

  const onClear = () => {
    context.commonSlideEditorModalProps.showNotification({
      target: 'inline',
      type: NotificationType.INFO,
      title: '',
      message: '',
      timeoutMilliseconds: 0,
    })
  }

  const additionalActions = (isLinkSlide && !state.url && !state.slide.data.files.length) ? [
    {
      label: "Channel",
      button:
        <CircleIconButton
          backgroundColor='var(--COLOR-BLUE-100)'
          onClick={() => {
            setState({
              ...state,
              mode: LinkModes.ChooseChannel,
            })
          }}
        >
          <icons.Nav_Channels
            color='var(--WHITES-NORMAL-1000)'
          />
        </CircleIconButton>
    },
    {
      label: "Slide",
      button:
        <CircleIconButton
          backgroundColor='var(--COLOR-BLUE-100)'
          onClick={() => {
            setState({
              ...state,
              mode: LinkModes.ChooseSlide,
            })
          }}
        >
          <icons.CreationProcess_Slide 
            color='var(--WHITES-NORMAL-1000)'
          />
        </CircleIconButton>
    },
    {
      label: "Gide",
      button:
        <CircleIconButton
          backgroundColor='var(--COLOR-BLUE-100)'
          onClick={() => {
            setState({
              ...state,
              mode: LinkModes.ChooseGide,
            })
          }}
        >
          <icons.Nav_Logo_LogoIconMd
            color='var(--WHITES-NORMAL-1000)'
          />
        </CircleIconButton>
    },
  ] : undefined;

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}>
      <NotificationInline target="inline" />
      {link ? getFileView() : (
        <div style={{width: "100%", height: "100%"}}>        
        </div>
      )
      }
      <LabeledTextInput 
        style={{
          padding: "14px 7px",
          width: "100%",
          maxWidth: "500px",
        }}
        label={!link ? `Link ${title} from the web` : ""}
        sublabel={!link ? "Copy and paste a link from the web to embed it in your slide" : ""}
        placeholder="Paste or type a link"
        isAcceptable={text => !!text}
        onAccept={onAccept}
        onConfirm={onConfirm}
        onClear={onClear}
        text={link || ""}
        theme={theme.mode}
        additionalActions={additionalActions}
        onBlur={text => {
          if (!text) {
            setState({
              ...state,
              url: undefined,
            })
          }
        }}
      />
    </div>
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const theme = context.isLinkSlide ? lightTheme : darkTheme;
  const content = getContent(context, slideEditorModalProps, theme);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: theme,
  };
};

export const Link = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
