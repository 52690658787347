import React, { ReactNode } from 'react';
import ResizeableModal from '../../modals/ResizeableModal/ResizeableModal';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import icons from '../../../assets/icons';
import styles from './_quick-slide-type-chooser.module.scss';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';

export interface SlideTypeButton {
  icon: ReactNode,
  sortIndex: number;
}

export interface  QuickSlideTypeChooserProps {
  closeModal: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  className?: string;
  slideTypeButtons: SlideTypeButton[];
}

export default function QuickSlideTypeChooser (props:  QuickSlideTypeChooserProps) {   
  return (
    <ResizeableModal
      canResize={false}
      closeModal={() => props.closeModal()}
      className={`useMaxWidth${props.className ? ' ' + props.className : ''}`}
      showInputBar={true}
      mode='auto'
      width={382}
      hideCommandBar={true}
      closeOnDimmer={true}  
    >
      <div className={styles.quickSlideTypeChooserContainer}>
        <div className={styles.settings}>
          <RoundedCornerButton
            label="Text Settings"
            imagePosition="right"
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"            
            icon={<icons.Nav_Settings
              color="var(--WHITES-NORMAL-1000)" />
            }
            style={{
              width: '135px',
              backgroundColor: 'var(--COLOR-PRIMARY-700)'
            }}
          />
          <icons.Nav_Exit_Alternative 
            onClick={props.closeModal}
            color="var(--COLOR-SECONDARY-600)"
            className="hover"
          />
        </div>
        <div className={styles.quickSlideTypeSelections}>  
          {props.slideTypeButtons.map((slideTypeButton: SlideTypeButton, index: number) =>                       
            <React.Fragment key={index}>
              {slideTypeButton.icon}
            </React.Fragment>   
          )}
        </div>
      </div>
    </ResizeableModal>
  );
}
