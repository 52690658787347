import React, { ReactNode, CSSProperties } from 'react';
import styles from './_gide-tile.module.scss';
import icons from '../../../../../assets/icons';
import { PriceType, SubscriptionPlan, getSubscriptionPlanDisplayName } from '../../../../../models/GideEnum';
import { isNullOrUndefined } from 'util';
import PreviewAvatar from '../../PreviewAvatar/PreviewAvatar';
import flagDictionary from '../../../../../assets/icons/flag-lookup';
import { urlForArticle } from '../../../../../utils/helperFunctions';
import { Link } from 'react-router-dom';
import isNil from 'ramda/es/isNil';
import GidePreviewFooterMenu from '../GidePreviewFooterMenu/GidePreviewFooterMenu';
import GideImage from '../../../Image/GideImage';

export interface GideTilePreviewProps {
  id: string;
  slug: string;
  image: string;
  title: string;
  language: string;
  description?: string;
  authorImage: string;
  author: string;
  createdAt: Date;
  updatedAt: Date;
  views: number;
  slideCount?: number;
  slidePosition?: number;
  isOfficalGide?: boolean;
  priceType?: PriceType;
  subscriptionPlan?: SubscriptionPlan;
  gideCost?: number;
  isOwner?: boolean;
  disableLink?: boolean;
  isReadOnly: boolean;
  draftGide?: boolean;
  style?: CSSProperties;
  onClick?: (gideId: string) => void;
}

function daysBetween(startDate: Date, endDate: Date) {
  // The number of milliseconds in all UTC days
  const oneDay = 1000 * 60 * 60 * 24;

  const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  return (start - end) / oneDay;
}

export default function GideTilePreview(props: GideTilePreviewProps) {
  const createDate = `${props.createdAt.toLocaleDateString('en-US', { month: 'short' })} '${props.createdAt.toLocaleDateString('en-US', {
    year: '2-digit',
  })}`;
  const numberOfViews = props.views < 10000 ? props.views.toString() : `${Math.floor(props.views / 1000).toString()}K`;
  const daysSinceUpdated = daysBetween(props.updatedAt, new Date());
  const isDonate = !isNullOrUndefined(props.priceType) && props.priceType === PriceType.Donate;
  const priceDisplay = isDonate ? 'Donate' : props.gideCost ? `$${props.gideCost.toString()}` : undefined;
  const subscriptionDisplay = !isNil(props.subscriptionPlan) ? getSubscriptionPlanDisplayName(props.subscriptionPlan) : undefined;
  const GideTile: () => JSX.Element = () =>
    <div className={styles.gideTilePreviewDetails} style={props.style}
      onClick={() => {
        if(props.onClick) {
          props.onClick(props.id);
        }
      }}
    >
      <div className={styles.gideTilePreviewHeader}>
        <div className={styles.gideTileImage}>
          <GideImage src={props.image} alt={props.title} width="176" height="118" />
        </div>
        <div className={styles.logoBackground}>
          <icons.Nav_Logo_LogoIconSm width={24} height={24} color={'var(--WHITES-NORMAL-1000)'} />
        </div>
        <div className={styles.gideLanguage}>
          <GideImage style={{ height: '16px' }} src={flagDictionary[props.language]} />
        </div>
        {!isNullOrUndefined(priceDisplay) && (
          <div
            className={`${styles.purchaseGide} ${isDonate ? styles.donate : ''} ${
              !isNullOrUndefined(props.subscriptionPlan) ? styles.subscription : ''
            }`}
          >
            {!isNil(subscriptionDisplay) && <div>{subscriptionDisplay}</div>}
            <div>{priceDisplay}</div>
          </div>
        )}
      </div>
      <div className={`${styles.gideTileTitle}`}>{props.title}</div>
      {props.isOfficalGide && <div className={styles.officalGide}>OFFICIAL GIDE</div>}
      {!props.isOfficalGide && (
        <div className={styles.gideViewDetails}>
          <div className={styles.createDate}>
            <icons.CreationProcess_Events className={styles.calendarImage} />
            <div>{createDate}</div>
          </div>
          {daysSinceUpdated > 365 && <div>Updated over a year ago</div>}
        </div>
      )}
      <div className={styles.gideViewDetails}>
        {(props.slideCount !== undefined) && <div>{props.slideCount} slides</div>}
        {(props.slidePosition !== undefined && props.slidePosition >= 0) && <div>Slide {props.slidePosition + 1}</div>}
        {props.draftGide && (
          <div className={styles.draftGide}>GIDE IN DRAFTS</div>
        )}
        {!props.draftGide && (
          <div>{numberOfViews} views</div>
        )}
      </div>
    </div>;

  return (
    <div className={styles.gideTilePreviewContainer} style={{ borderRadius: "5px" }}>
      {props.disableLink !== true && (
        <Link to={`${urlForArticle({ slug: props.slug, author: { username: props.author } })}${props.slidePosition !== undefined ? (`?slide=${props.slidePosition + 1}`) : ''}`}>
          <GideTile />
        </Link>
      )}
      {props.disableLink === true && (
        <GideTile />
      )}
      <div className={styles.authorDetails}>
        <PreviewAvatar disabled={props.disableLink} authorImage={props.authorImage} author={props.author} />
        {!props.isReadOnly && (
          <GidePreviewFooterMenu gideId={props.id} isOwner={props.isOwner ? props.isOwner : false} />
        )}        
      </div>
    </div>
  );
}
