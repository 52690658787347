import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Checkbox,
  Input,
  Image,
  Button,
  Loader,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import DropZone from 'react-dropzone';
import { SketchField, Tools } from 'react-sketch';
import superagent from 'superagent';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import { API_ROOT } from '../../constants/paths';
import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import { ensureImageFileOrientation } from '../../utils/helperFunctions';

let dropZoneStyles = {
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};

const loadImage = require('blueimp-load-image');

const mapStateToProps = slideTools.mapStateToProps;
const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

const TOOLS = [
  { key: 1, text: 'Pencil', value: Tools.Pencil },
  { key: 2, text: 'Select', value: Tools.Select },
  { key: 3, text: 'Line', value: Tools.Line },
];

const onSelectChoices = [
  {
    key: 'SWIPE',
    value: 'SWIPE',
    text: 'Go to Swipe Mode',
  },
  {
    key: 'MODAL',
    value: 'MODAL',
    text: 'Open in Modal',
  },
  {
    key: 'URL',
    value: 'URL',
    text: 'Open URL',
  },
  {
    key: 'NOTHING',
    value: 'NOTHING',
    text: 'Do Nothing',
  },
];

export class ImageModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'IMAGE',
        data: {
          audioCaption: null,
          files: [],
          caption: '',
          displayInCarousel: false,
        },
      },
      loading: false,
      imageEditing: null,
      tool: TOOLS[0],
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
      if (!props.editSlide.data.files) {
        this.state.slide.data.files = [];
      }
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateDataStateCheckbox = field => (ev, data) => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.onChangeSelectChoice = (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.onSelect = data.value;
      this.setState({ slide });
    };

    this.updateImageEditing = field => ev => {
      const val = ev.target.value;
      this.setState(prevState => {
        let imageEditing = prevState.imageEditing;
        imageEditing.caption = val;
        return {
          imageEditing,
        };
      });
    };

    this.createSlide = async ev => {
      if (!this.state.slide.data.files.length) {
        alert('No images to create yet.');
        return;
      }
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.onImageSelect = file => {
      this.setState({ activeImage: file });
    };

    this.onRemoveImage = imageIdx => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      let files = slide.data.files.slice();
      files.splice(imageIdx, 1);
      slide.data.files = files;
      this.setState({ slide });
    };

    this.editImage = file => {
      this.setState(prevState => {
        let slide = Object.assign({}, this.state.slide);
        if (prevState.imageEditing) {
          slide.data.files.forEach(f => {
            if (f.name === prevState.imageEditing.name) {
              f.sketchJSON = this._sketch.toJSON();
              f.dataURL = this._sketch.toDataURL();
            }
          });
          return {
            imageEditing: file,
            slide,
          };
        } else {
          return {
            imageEditing: file,
          };
        }
      });
    };

    this.selectTool = (e, data) => {
      const arr = TOOLS.filter(tool => {
        return tool.value === data.value;
      });
      if (arr.length) {
        this.setState({
          tool: arr[0],
        });
      }
    };

    this.startVideo = this._startVideo.bind(this);
    this.stopVideo = this._stopVideo.bind(this);
    this.handleVideo = this._handleVideo.bind(this);
    this.onTakePhoto = this._onTakePhoto.bind(this);
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length) {
      this.setState({ loading: true });
      ensureImageFileOrientation(acceptedFiles[0]).then(imageFile => {
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', imageFile)
          .end((err, res) => {
            if (err) console.log(err);
            if (res.body && res.body.url) {
              let slide = Object.assign({}, this.state.slide);
              let image = {
                name: imageFile.name,
                url: res.body.url,
                type: 'UPLOAD',
                caption: '',
              };
              slide.data.files = [...slide.data.files, image];
              this.setState({ slide, loading: false });
            }
          });
        return;
      });
    }
  }

  componentDidUpdate() {
    let sketch = this._sketch;
    if (this.state.imageEditing) {
      if (this.state.imageEditing.sketchJSON) {
        sketch.fromJSON(this.state.imageEditing.sketchJSON);
      } else {
        sketch.clear();
        sketch.setBackgroundFromDataUrl(this.state.imageEditing.dataURL, {
          stretched: false,
          stretchedX: false,
          stretchedY: false,
          originX: 'left',
          originY: 'top',
        });
        // let reader = new FileReader();
        // reader.addEventListener('load', () => sketch.setBackgroundFromDataUrl(reader.result, {
        //     stretched: true,
        //     stretchedX: false,
        //     stretchedY: false,
        //     originX: 'left',
        //     originY: 'top',
        // }), false);
        // reader.readAsDataURL(this.state.imageEditing.browserFile);
      }
    }
  }

  savePhoto(event) {
    let file = {
      name: 'webcam capture',
      url: event.target.result,
      caption: '',
      type: 'LIVE',
    };
    let slide = Object.assign({}, this.state.slide);
    slide.data.files = [...this.state.slide.data.files, file];
    this.setState({ slide });
    this.editImage(file);
    this.stopVideo();
  }

  _onTakePhoto() {
    let video = document.querySelector('video');
    this._canvas
      .getContext('2d')
      .drawImage(video ? video : this._video, 0, 0, 600, 600, 0, 0, 600, 600);
    let dataURL = this._canvas.toDataURL('image/png');
    ensureImageFileOrientation(dataURL).then(imageFile => {
      loadImage.readFile(imageFile, this.savePhoto.bind(this));
    });
  }

  _startVideo() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      navigator.oGetUserMedia;
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { video: true },
        this.handleVideo,
        this.videoError,
      );
    }
  }

  _stopVideo() {
    if (this.state.stream) {
      this.state.stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }
    this.setState({ stream: null, videoSrc: null });
  }

  _handleVideo(stream) {
    // Update the state, triggering the component to re-render with the correct stream
    let video = document.querySelector('video');
    // handle the depricated createObjectURL method in new browser versions
    if (video) {
      if ('srcObject' in video) {
        video.srcObject = stream;
      } else {
        video.src = window.URL.createObjectURL(stream);
      }
      this.setState({
        stream,
        videoSrc: video.srcObject ? video.srcObject : video.src,
      });
      video.play();
    }
    //this.setState({ stream, videoSrc: window.URL.createObjectURL(stream) });
  }

  videoError() {}

  getRandomString() {
    return Math.random()
      .toString(36)
      .substring(2, 16);
  }

  componentWillUnmount() {
    this.stopVideo();
  }

  componentWillMount() {
    const { url } = this.props;
    if (url) {
      let slide = Object.assign({}, this.state.slide);
      let image = {
        name: 'foo',
        type: 'UPLOAD',
        caption: '',
        url,
      };
      slide.data.files = [...slide.data.files, image];
      this.setState({ slide, loading: false });
    }
  }

  render() {
    const { slide, imageEditing, videoSrc, loading } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="imageModal"
        size="large"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(227, 99, 100)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(227, 99, 100)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Image Type</span>
            <div className="recordingBtns">
              <Button.Group>
                <Button
                  icon="eye"
                  onClick={this.startVideo}
                  disabled={videoSrc}
                />
                <Button
                  icon="stop"
                  onClick={this.stopVideo}
                  disabled={!videoSrc}
                />
                <Button
                  icon="record"
                  onClick={this.onTakePhoto}
                  disabled={!videoSrc}
                />
              </Button.Group>
            </div>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-image.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <canvas
                id="c"
                width="600"
                height="600"
                ref={c => (this._canvas = c)}
                style={{ display: 'none' }}
              />
              {loading && <Loader active inline="centered" />}
              <div style={{ margin: '0 auto' }}>
                  {/* Have to hide the video with css or it won't work correctly */}
                  <video
                    src={this.state.videoSrc}
                    autoPlay={true}
                    className={`webcamDisplay${this.state.videoSrc ? '' : ' inactive'}`}
                    ref={v => (this._video = v)}
                  />
                <SketchField
                  width={'500px'}
                  height={this.state.imageEditing ? '380px' : '0'}
                  tool={this.state.tool.value}
                  lineColor="black"
                  lineWidth={3}
                  ref={c => (this._sketch = c)}
                  style={{ margin: '0 auto' }}
                />
                {imageEditing && (
                  <div>
                    <Input
                      fluid
                      placeholder="caption"
                      value={imageEditing.caption}
                      onChange={this.updateImageEditing('caption')}
                    />
                  </div>
                )}
                <section>
                  <aside>
                    {slide.data.files.length > 0 && (
                      <span>{slide.data.files.length} files.</span>
                    )}
                    <ul>
                      {slide.data.files.map((f, i) => (
                        <li key={i}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <Image
                              onClick={() => this.onImageSelect(f)}
                              src={f.url}
                              alt={`□`}
                              style={{ maxHeight: 100 }}
                            />

                            <Button
                              style={{ marginLeft: '5px' }}
                              icon
                              onClick={() => this.onRemoveImage(i)}
                            >
                              <Icon name="trash" />
                            </Button>
                            <span>On Select: </span>
                            <Dropdown
                              placeholder="On Select.."
                              search
                              selection
                              options={onSelectChoices}
                              onChange={(ev, data) => {
                                const slide = Object.assign(
                                  {},
                                  this.state.slide,
                                );
                                slide.data.files[i].onSelect = data.value;
                                this.setState({ slide });
                              }}
                              value={f.onSelect}
                            />
                            {f.onSelect === 'URL' && (
                              <Input
                                placeholder="url"
                                value={f.onSelectUrl}
                                onChange={ev => {
                                  const slide = Object.assign(
                                    {},
                                    this.state.slide,
                                  );
                                  slide.data.files[i].onSelectUrl =
                                    ev.target.value;
                                  this.setState({ slide });
                                }}
                              />
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </aside>
                  <aside>
                    <div className="dropzone">
                      <DropZone
                        onDrop={this.onDrop.bind(this)}
                        style={dropZoneStyles}
                      >
                        <p>Drop files or select.</p>
                      </DropZone>
                    </div>
                  </aside>
                </section>

                {this.state.slide.data.files.length > 1 && (
                  <div style={{ margin: '5px' }}>
                    <Checkbox
                      label="Display In Carousel"
                      onChange={this.updateDataStateCheckbox(
                        'displayInCarousel',
                      )}
                      checked={this.state.slide.data.displayInCarousel}
                    />
                  </div>
                )}
              </div>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide.bind(this)}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
