
export interface LanguageFlagDictionary {
  [language: string]: string;
}

const flagDictionary: LanguageFlagDictionary = {};
flagDictionary['en'] = "/icons/custom/flag-united-states.svg";
flagDictionary['es'] = "/icons/custom/flag-spain.svg";
flagDictionary['fr'] = "/icons/custom/flag-france.svg";
flagDictionary['it'] = "/icons/custom/flag-italy.svg";

export default flagDictionary;