import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Input, Button, Dropdown, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';

import ChannelActions from './ChannelActions';
import ArticleList from '../ArticleList';
import ChannelList from '../ChannelList';
import CollectionList from '../CollectionList';
import agent from '../../agent';
import {
  CHANNEL_PAGE_CHANNEL_LOADED,
  CHANNEL_PAGE_CONTENT_LOADED,
  CHANNEL_PAGE_UNLOADED,
  SET_TOASTER_MESSAGE,
  JOIN_CHANNEL,
  FOLLOW_CHANNEL,
  UNFOLLOW_CHANNEL,
  DELETE_ARTICLE_NO_REDIRECT,
} from '../../constants/actionTypes';
import { NotificationType } from '../../constants/strings';
import GideImage from '../Shared/Image/GideImage';
const mapStateToProps = state => ({
  ...state.channel,
  currentUser: state.common.currentUser,
  attendances: state.article.attendances,
  retainArticle: state.common.retainArticle,
  viewMode: state.common.viewMode,
});

const mapDispatchToProps = dispatch => ({
  onLoadChannel: payload =>
    dispatch({ type: CHANNEL_PAGE_CHANNEL_LOADED, payload }),
  onLoadContent: payload =>
    dispatch({ type: CHANNEL_PAGE_CONTENT_LOADED, payload }),
  onUnload: () => dispatch({ type: CHANNEL_PAGE_UNLOADED }),
  showNotification: payload => dispatch({ type: SET_TOASTER_MESSAGE, payload }),
  onJoin: channel =>
    dispatch({
      type: JOIN_CHANNEL,
      payload: agent.Channels.join(channel),
    }),
  onFollow: channel =>
    dispatch({
      type: FOLLOW_CHANNEL,
      payload: agent.Channels.follow(channel),
    }),
  onUnfollow: channel =>
    dispatch({
      type: UNFOLLOW_CHANNEL,
      payload: agent.Channels.unfollow(channel),
    }),
  onDeleteArticle: payload => dispatch({ type: DELETE_ARTICLE_NO_REDIRECT, payload }),
});

const collectionOptions = [
  { key: 'ANY', value: '', text: 'Any' },
  { key: 'IMAGE', value: 'IMAGE', text: 'Images' },
  { key: 'VIDEO', value: 'VIDEO', text: 'Videos' },
];

const JoinButton = props => {
  const { isUser, currentUser, channel } = props;
  if (isUser || !currentUser || channel.type !== 'REQUEST') {
    // TODO also return null if user already joined
    return null;
  }

  let classes = 'join btn btn-sm action-btn';

  const handleClick = ev => {
    if (channel.type === 'REQUEST') {
      props.join(channel);
      props.showNotification({
        toasterMessageInfo: {
          message: `Request sent.`,
          type: NotificationType.INFO,
        },
      });
    }
  };

  return (
    <button
      className={classes}
      onClick={handleClick}
      style={{ float: 'right' }}
      disabled={channel.joining}
    >
      <span>{channel.joining ? `Waiting for approval` : `Join`}</span>
    </button>
  );
};

const FollowButton = props => {
  const { isUser, currentUser, channel } = props;
  if (isUser || !currentUser) {
    return null;
  }

  let classes = 'join btn btn-sm action-btn';

  const handleClick = ev => {
    // const { channel } = props;
    // TODO reimplement when API endpoint complete
    // agent.Channels.follow(channel);
    let message;
    if (channel.following) {
      props.unfollow(channel);
      message = `Unfollowed.`;
    } else {
      props.follow(channel);
      message = `You will now receive notifcations when this channel is updated.`;
    }
    props.showNotification({
      toasterMessageInfo: {
        message,
        type: NotificationType.INFO,
      },
    });
  };

  return (
    <button
      className={classes}
      onClick={handleClick}
      style={{ float: 'right' }}
    >
      <i className="ion-plus-round" />
      &nbsp;
      {channel.following ? 'Unfollow' : 'Follow'}
    </button>
  );
};

class Channel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newFolderName: '',
      newChannelName: '',
      newCollectionName: '',
      loading: false,
    };

    this.load = this._load.bind(this);
    this.handleAddFolder = this._handleAddFolder.bind(this);
    this.handleAddCollection = this._handleAddCollection.bind(this);
    this.handleRemoveCollection = this._handleRemoveCollection.bind(this);
    this.handleAddChannel = this._handleAddChannel.bind(this);
    this.handleNewChannelAccessChanged = this._handleNewChannelAccessChanged.bind(
      this,
    );

    this.onEmptyTrash = async () => {
      const confirmed = window.confirm('Are you sure?');
      if (!confirmed) return;

      try {
        await agent.Articles.emptyTrash();
        this.load();
      } catch (e) {
        console.log('error', e);
        alert('There was a problem, please try again later.');
      }
    };
  }

  _handleNewChannelAccessChanged(ev, data) {
    this.setState({ newChannelAccess: data.value });
  }

  async _handleAddChannel() {
    if (this.state.newChannelName === 'FYEO') {
      return alert('Channel already exists.');
    }
    const channel = {
      channel: this.props.channel._id,
      title: this.state.newChannelName,
      type: this.props.channel.type,
    };
    try {
      await agent.Channels.create(channel);
      this.load();
      this.setState({ newChannelName: '' });
    } catch (e) {
      alert('Please try again later.');
    }
  }

  async _handleRemoveCollection(collection) {
    let confirmed = window.confirm('Are you sure?');
    if (!confirmed) return;
    try {
      await agent.Collections.remove(collection);
      this.load();
    } catch (e) {
      alert('Please try again.');
    }
  }

  async _handleAddCollection() {
    if (
      this.state.newCollectionName === 'Drafts' &&
      this.state.channel.title === 'FYEO'
    ) {
      return alert('Collection already exists.');
    }
    const collection = {
      channel: this.props.channel._id,
      title: this.state.newCollectionName,
      type: this.state.newCollectionType,
    };
    try {
      await agent.Collections.create(collection);
      this.load();
      this.setState({
        newCollectionName: '',
        newCollectionType: '',
      });
    } catch (e) {
      alert('Please try again later.');
    }
  }

  async _handleAddFolder() {
    const folder = {
      channel: this.props.channel._id,
      title: this.state.newFolderName,
    };
    try {
      await agent.Channels.createFolder(this.props.channel, folder);
      this.load();
      this.setState({
        newFolderName: '',
      });
    } catch (e) {
      alert('Please try again later.');
    }
  }

  async _load(id) {
    this.setState({ loading: true });
    let channelResp;
    try {
      let channelId = id ? id : this.props.match.params.id;
      channelResp = await agent.Channels.get(channelId);
    } catch (err) {
      this.props.showNotification({
        toasterMessageInfo: {
          message: `Channel unavailable.`,
          type: NotificationType.INFO,
        },
      });
    }
    this.setState({ loading: false });
    if (channelResp) {
      this.props.onLoadChannel(channelResp);
    } else {
      return;
    }

    let resp;
    try {
      let channelId = id ? id : this.props.match.params.id;
      resp = await agent.Channels.getContent(channelId);
    } catch (err) {
      let msg = '';
      if (
        err.response &&
        err.response.body &&
        err.response.body &&
        err.response.body.msg
      )
        msg = err.response.body.msg;
      this.props.showNotification({
        toasterMessageInfo: {
          message: msg.length ? msg : `Channel unavailable.`,
          type: NotificationType.INFO,
        },
      });
    }
    if (resp) {
      this.props.onLoadContent(resp);
    }
  }

  componentWillMount() {
    this.load();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.match) return;
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.load(nextProps.match.params.id);
    }
  }

  componentWillUnmount() {
    // TODO keeping the article loaded because it is needed for swipe mode.
    // So find a way to keep it loaded at the right times, but unload in others.
    if (!this.props.retainArticle) this.props.onUnload();
  }

  render() {
    const { loading } = this.state;
    const { currentUser, channel, channels, articles } = this.props;
    let articlesNotInFolders = [];
    if (!channel) {
      return (
        <div>
          {loading && (
            <div className="flexRowFullTop" style={{ paddingTop: '50px' }}>
              <Loader active inline="centered" />
            </div>
          )}
        </div>
      );
    }
    if (articles) {
      articlesNotInFolders = articles.filter(article => {
        let found = false;
        channel.folders.forEach(f => {
          f.articles.forEach(a => {
            if (a._id === article._id) { 
              found = true;
            }
          })
        })
        return !found;
      });
    }

    const isUser =
      currentUser && channel.author.username === currentUser.username;

    // TODO assign correct class
    // const FAVORITED_CLASS = 'btn btn-sm btn-primary';
    // const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';
    // const favoriteButtonClass = this.props.article.favorited
    //   ? FAVORITED_CLASS
    //   : NOT_FAVORITED_CLASS;
    /*
     * TODO enable markup by setting a flag on the content for raw or formatted markup
     */
    //const markup = { __html: marked(this.props.article.body, { sanitize: true }) };
    // const canModify =
    //   this.props.currentUser &&
    //   this.props.currentUser.username === this.props.article.author.username;
    let logoImageSrc = '/icons/nav/channels.svg';

    const articlePageClasses = classNames('article-page', 'channel');

    return (
      <div className={articlePageClasses}>
        <div className="channelTitleBar">
          <span className="flexRowFlexNormal">
            <Image
              className="channelImage color-primary-500-svg" 
              src={logoImageSrc} 
            />
            <h3 className="articleTitle">{channel.title === 'FYEO' ? 'For Your Eyes Only' : channel.title}</h3>
          </span>
          <div className="flexRowFlexNormal channelActions">
            {channel.type !== 'SPECIAL' && (
              <ChannelActions
                channel={channel}
                canModify={
                  this.props.currentUser &&
                  this.props.currentUser.username === channel.author.username
                }
              />
            )}
            <Link to={`/@${channel.author.username}`}>
              <Image
                src={channel.author.image}
                alt={channel.author.username}
                className="userPic"
              />
              {channel.author.username}
            </Link>
          </div>
        </div>
        <div className="channelContainer">
          <div className="container page">
            <div className="banner">
              <GideImage style={{ height: '200px' }} src={channel.image} alt={`□`} />
              <div className="container">
                <JoinButton
                  channel={channel}
                  isUser={isUser}
                  join={this.props.onJoin}
                  currentUser={this.props.currentUser}
                  showNotification={this.props.showNotification}
                />
                <FollowButton
                  channel={channel}
                  isUser={isUser}
                  follow={this.props.onFollow}
                  unfollow={this.props.onUnfollow}
                  currentUser={this.props.currentUser}
                  showNotification={this.props.showNotification}
                />
              </div>
            </div>
            <Divider />

            <div className="row article-content">
              <div className="col-xs-12" style={{ padding: '10px' }}>
                {channel.type === 'SPECIAL' && channel.title === 'Trash' &&
                  <Button
                    secondary
                    size="small"
                    disabled={!articles || articles.length <= 0}
                    negative
                    onClick={this.onEmptyTrash}
                  >
                    Empty
                  </Button>
                }
                {!channel.channel && channel.type !== 'SPECIAL' && (
                  <div>
                    <h2>Sub Channels</h2>
                    {isUser && (
                      <div>
                        <Input
                          value={this.state.newChannelName}
                          onChange={(ev, data) =>
                            this.setState({ newChannelName: data.value })
                          }
                          placeholder="Name"
                        />
                        <Button
                          onClick={this.handleAddChannel}
                          icon="add"
                          content="Add Subchannel"
                          disabled={
                            !this.state.newChannelName ||
                            !this.state.newChannelName.length
                          }
                        />
                      </div>
                    )}
                    <ChannelList channels={channels} />
                    <Divider />
                  </div>
                )}
                <h2>Gides</h2>
                
                <ArticleList articles={articlesNotInFolders} layout="SQUARE" currentUser={currentUser} onDeleteArticle={payload => {this.props.onDeleteArticle(payload); this.load();}} />

                <ul style={{listStyle: 'none'}}>
                  {channel.folders.map((folder, i) => {
                    return (
                      <li key={i}>
                        <h3>{folder.title}</h3>
                        <ArticleList articles={folder.articles} layout="SQUARE" currentUser={currentUser} onDeleteArticle={payload => {this.props.onDeleteArticle(payload); this.load();}} />
                      </li>
                    )
                  })}
                </ul>

                {isUser && (
                  <div>
                    <Input
                      value={this.state.newFolderName || ''}
                      onChange={ev =>
                        this.setState({ newFolderName: ev.target.value })
                      }
                      placeholder="Name"
                    />
                    <Button
                      onClick={this.handleAddFolder}
                      icon="add"
                      content="Add Folder"
                      disabled={!this.state.newFolderName.length}
                    />
                  </div>
                )}
                <Divider />

                <h2>Collections</h2>
                {isUser && (
                  <div>
                    <Input
                      value={this.state.newCollectionName || ''}
                      onChange={ev =>
                        this.setState({ newCollectionName: ev.target.value })
                      }
                      placeholder="Name"
                    />
                    <Dropdown
                      placeholder="Limit to type"
                      selection
                      options={collectionOptions}
                      onChange={(ev, data) =>
                        this.setState({ newCollectionType: data.value })
                      }
                      value={this.state.newCollectionType || ''}
                    />
                    <Button
                      onClick={this.handleAddCollection}
                      icon="add"
                      content="Add Collection"
                      disabled={!this.state.newCollectionName.length}
                    />
                  </div>
                )}
                <CollectionList
                  collections={this.state.collections}
                  onRemove={this.handleRemoveCollection}
                  iterate={true}
                  center={false}
                />
                {isUser && (
                  <div>
                    <h2>Users</h2>
                    <p>None</p>
                    <h2>Requesting access</h2>
                    <p>None</p>
                    <Button onClick={()=>alert('Coming Soon.')}>Invite</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
