import React, { ReactNode, CSSProperties } from 'react';
import styles from './_picker-modal.module.scss';
import { RoundedCornerButton } from '../../../../Shared/RoundedCornerButton/RoundedCornerButton';
import icons, { IconProps } from '../../../../../assets/icons';
import classNames from 'classnames';
export interface PickerModalProps {
  headerContent?: ReactNode;
  children?: ReactNode;
  hideActions?: boolean;  
  className?: string;
  cancelLabel?: string;
  saveLabel?: string;
  style?: CSSProperties;
  saveIcon?: (props: IconProps) => JSX.Element;
  onCancel: () => void;
  onSave: () => void;  
}

export default function PickerModal(props: PickerModalProps) {
  return (
    <div className={classNames(styles.pickerModalContainer, props.className)} style={props.style}>
      
      {props.headerContent && (
        <div className={styles.header}>
          {props.headerContent}
        </div>
      )}

      {props.children}

      {props.hideActions !== true && (<>
      
        <div className={styles.horizontalLine} /> 

        <div className={styles.actions}>
          <RoundedCornerButton
            label={props.cancelLabel || "Cancel"}
            style={{
              backgroundColor: '#fafafa',
              marginRight: '14px',
              width: '79px',
              height: '32px'
            }}
            fontCssClass="TEXTSUBTITLEblackmedium-emphasisleft"
            onClick={props.onCancel}
          />
          <RoundedCornerButton
            icon={props.saveIcon ? <props.saveIcon color="var(--WHITES-NORMAL-1000)" /> : <icons.ContentAlteration_Check_Main color="var(--WHITES-NORMAL-1000)" />}
            imagePosition={'right'}
            label={props.saveLabel || "Done"}
            fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
            style={{ 
              backgroundImage: 'linear-gradient(295deg, var(--COLOR-PRIMARY-700) 103%, #fab317 29%)',
              marginRight: '12px',
              minWidth: '83px',
              height: '32px'
            }}
            onClick={props.onSave}
          />
        </div>
      </>)}
    </div>
  );
}
