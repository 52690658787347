import React, { Component } from 'react';
import { hasValue } from '../../../utils/helperFunctions';

export interface ProgressBarProps {
  indicatorWidth: number; // Must be less than 100
}

export interface ProgressBarState {
  intervalId?: NodeJS.Timeout;
}

export default class ProgressBar extends Component<ProgressBarProps, ProgressBarState> {
  progressBar?: HTMLDivElement | null;

  private start(): void {

    if (this.progressBar !== null && this.progressBar !== undefined) {
      const pb = (this.progressBar as HTMLDivElement);
      let width = 1;
      const id = setInterval(() => {
        if (width >= 100 && width < (100 + this.props.indicatorWidth)) {
          width++;
          pb.style.width = (100 + this.props.indicatorWidth) - width + '%';
          pb.style.left = (width - this.props.indicatorWidth) + '%';

        } else if (width >= (100 + this.props.indicatorWidth)) {
          width = 1;
          pb.style.left = (width - 1) + '%';
        } else if (width < this.props.indicatorWidth) {
          width++;
          pb.style.width = width + '%';
        }
        else {
          width++;
          pb.style.left = (width - this.props.indicatorWidth) + '%';
        }
      }, 17); // 60 frames per second for smoot animation.
      this.setState({intervalId: id});
    }
  }
  private stop(): void {
    if(hasValue(this.state.intervalId)) {
      clearInterval((this.state.intervalId as NodeJS.Timeout));
    }
  }
  componentDidMount() {
    this.start();
  }
  componentWillUnmount() {
    this.stop();
  }
  public render() {
    return (
      <div className="progressBarContainer">
        <div className="progressBar" id="progressBar"
          ref={progressBar => (this.progressBar = progressBar)}
        >
        </div>
      </div>
    );
  }
}
