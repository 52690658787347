import React from 'react';
import { Link } from 'react-router-dom';

import CommentInput from './CommentInput';
import CommentList from './CommentList';

const CommentContainer = ({ show, currentUser, comments, slug, errors }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="col-xs-12 col-md-8 offset-md-2 comments">
      {!currentUser && (
        <p>
          <Link to="/login">Sign in</Link>
          &nbsp;or&nbsp;
          <Link to="/register">sign up</Link>
          &nbsp;to add comments on this article.
        </p>
      )}
      <CommentList comments={comments} slug={slug} currentUser={currentUser} />
      {currentUser && (
        <div>
          <list-errors errors={errors} />
          <CommentInput slug={slug} currentUser={currentUser} />
        </div>
      )}
    </div>
  );
};

export default CommentContainer;
