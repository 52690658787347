import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createBrowserHistory } from 'history';
import { createHashHistory } from 'history';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';

import {rootReducer} from './reducer';
import { promiseMiddleware, localStorageMiddleware } from './middleware';


export const history = window.cordova ? createHashHistory() : createBrowserHistory();
// console.log('history', history);
// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(
      myRouterMiddleware,
      promiseMiddleware,
      localStorageMiddleware,
    );
  } else if(process.env.NODE_ENV === 'development') {
    return applyMiddleware(
      myRouterMiddleware,
      promiseMiddleware,
      localStorageMiddleware,
      // immutableStateInvariantMiddleware(),
      createLogger(),
    );    
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(
      myRouterMiddleware,
      promiseMiddleware,
      localStorageMiddleware,
    );
  }
};
export const store = createStore(rootReducer(history), composeWithDevTools(getMiddleware()));
