import React from 'react';
import { Loader } from 'semantic-ui-react';
import ArticlePreview from './ArticlePreview';
import ArticlePreviewSquare from './ArticlePreviewSquare';
import ListPagination from './ListPagination';
import BlogTile from './BlogTile';

const ArticleList = props => {
  if (!props.articles) {
    return (
      <div className="article-preview">
        {' '}
        <div style={{ paddingTop: '50px' }}>
          <Loader active inline="centered" />
        </div>
      </div>
    );
  }
  let Preview = ArticlePreview;
  if (props.layout === 'SQUARE') {
    Preview = ArticlePreviewSquare;
  } else if (props.layout === 'BLOG') {
    Preview = BlogTile;
  }

  if (props.articles.length === 0) {
    return <div className="article-preview"></div>;
  }

  const classNames = `articleList${props.center ? ' center' : ''}`;
  return (
    <div style={{ display: 'flex', flex: 1, flexFlow: 'column' }}>
      <div className={classNames}>
        {props.articles.map((article, i) => {
          return (
            <div key={i}>
              <Preview
                article={article}
                key={i}
                onDeleteArticle={props.onDeleteArticle}
                onRecoverArticle={props.onRecoverArticle}
                currentUser={props.currentUser}
                absolutePaths={props.layout === 'BLOG'}
              />
              {props.addHorizontalRule && (
                <div className="articleHorizontalRule" />
              )}
            </div>
          );
        })}
        {/* Here I am adding phantom items to handle the centering we want. This is a (hack) workaround but it works :)
            The idea is to put the most items you would ever have on a row. Then using flex and css you can set the height
            to 0 on the spans but they will take up the column and make the bottom row align left while centering everthing.
        */}
        <span className="emptySpan" />
        <span className="emptySpan" />
        <span className="emptySpan" />
        <span className="emptySpan" />
        <span className="emptySpan" />
        <span className="emptySpan" />
        <span className="emptySpan" />
        <span className="emptySpan" />
      </div>
      <ListPagination
        pager={props.pager}
        articlesCount={props.articlesCount}
        currentPage={props.currentPage}
      />
    </div>
  );
};

export default ArticleList;
