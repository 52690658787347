import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  slides: state.article.slides,
});

const ColumnSlide = props => {
  const { slide, slides } = props;

  const getPrecedingColumnSlides = () => {
    if (!slides) return;
    let pos = slides.indexOf(slide);
    let count = 0;
    for (let i = pos; i >= 0; i--) {
      let s = slides[i];
      if (s.slideType === 'COLUMN') {
        if (s.data.type === 'END') {
          break;
        } else {
          count += 1;
        }
      }
    }
    return count;
  };

  let idx = getPrecedingColumnSlides();

  return (
    <div className="paddedCard">
      <div style={{ textAlign: 'center' }}>
        {slide.data.type !== 'WRAP' && idx > 0 && <span>Column {idx}</span>}
        {slide.data.type === 'END' && <span>End Columns</span>}
        {slide.data.columns &&
          slide.data.columns !== '' && (
            <span>
              {' '}
              {slide.data.type === 'FIXED' && `of `}
              {slide.data.columns} {slide.data.type} Columns
            </span>
          )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(ColumnSlide);
