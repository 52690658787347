import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import classNames from 'classnames';
import styles from './_Common.module.scss';
import { 
  CommonContext, 
  CommonDerivedState,
  getSlideView,
  CommonModes,
} from './Common';
import { lightTheme } from '../../../themes/custom-theme';
import { getCreateActions, getPreviewItems } from './SharedLogic';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isLinkSlide } = context;  

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: false,
    hideCommandBar: false,
    editorState: EditorState.Preview,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: false,
    showBackNavigation: false,
    showDefaultActions: true,
    createActions: getCreateActions(context),
    headerActions: (!isLinkSlide &&
      <div className="headerActionsFlexEnd">
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            marginRight: '5px',
            paddingLeft: '9px',
            height: '32px',
            backgroundImage: 'linear-gradient(290deg, #faa114, #fab317)',
          }}
          iconCssClass="color-secondary-300-svg"
          expandedIconCssClass={classNames(styles.formatIcon, 'whites-normal-1000-svg')}
          circleIconButtonCssClass={styles.formatCircleIconButtonCssClass}
          iconBackgroundColor="transparent"
          alt="sticker"
          src="/icons/slidetype/image/main.svg"
          expandedSrc="/icons/slidetype/image/main.svg"
          expandWidth={108}
          label="Format"
          onClick={e => {
            setState({ ...state, mode: CommonModes.Format });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={true}
          visible={true}
        />
      </div>
    ),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.New });
    },
    previewItems: getPreviewItems(-1, context),
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { state } = context;
  const slideView = getSlideView(state.slide);
  return slideView;
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const Preview = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
