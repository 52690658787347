import {
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  FOLLOW_USER,
  UNFOLLOW_USER,
  DELETE_CHANNEL_NO_REDIRECT,
} from '../constants/actionTypes';
import { reject } from 'ramda';
const defaultState = {
  meta: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PROFILE_PAGE_LOADED:
      return {
        ...action.payload[0].profile,
      };
    case PROFILE_PAGE_UNLOADED:
      return {};
    case FOLLOW_USER:
    case UNFOLLOW_USER:
      return {
        ...action.payload.profile,
      };
    case DELETE_CHANNEL_NO_REDIRECT:
      if (state.channels) {
        return {
          ...state,
          channels: reject(c => c._id === action.payload.id, state.channels),
        };
      }
      return state;
    default:
      return state;
  }
};
