import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Loader, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { history } from '../../store';
import { Button, Image } from 'semantic-ui-react';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import classNames from 'classnames';
import ownerPanelStyles from '../../components/Header/GideViewHeader/GideOwnerViewPanel/_gide-owner-view-panel.module.scss';
import {
  slideIsValidForMultiEdit,
  hasValue,
  getSlideAddonInformation,
  getCustomDomain,
  convertToMilliseconds,
  getSlideRangeList,
  urlForArticle,
  shareGide,
  isIOSDevice,
} from '../../utils/helperFunctions';
import AuthorHeader from './AuthorHeader';
import ArticleMeta from './ArticleMeta';
import CommentContainer from './CommentContainer';
import SlideContainer from '../Gide/SlideContainer/SlideContainer';
import agent from '../../agent';
import { contains, any } from 'ramda';
import { NotificationType } from '../../constants/strings';
import {
  ARTICLE_PAGE_LOADED,
  ARTICLE_PAGE_ARTICLE_LOADED,
  ARTICLE_PAGE_SLIDES_LOADED,
  ARTICLE_PAGE_UNLOADED,
  LOAD_COMMENTS,
  ATTENDANCES_LOADED,
  ARTICLE_FAVORITED,
  ARTICLE_UNFAVORITED,
  ARTICLE_SUBSCRIBED,
  ARTICLE_UNSUBSCRIBED,
  MODAL_OPEN,
  VIEW_SLIDE,
  COLLAPSE_ALL_SLIDES,
  DELETE_ARTICLE,
  COPY_ARTICLE,
  TOGGLE_MULTI_SLIDE_SELECTION_MODE,
  ARTICLE_DELETE_SLIDES,
  ARTICLE_COPY_SLIDES,
  ARTICLE_MOVE_SLIDES,
  ARTICLE_EXPORT_SLIDES,
  ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT,
  ARTICLE_MOVE_SLIDES_OUT_OF_GIDE,
  SET_SELECTED_SLIDES,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
  SET_TOASTER_MESSAGE,
  EXIT_CHILD_ARTICLE_EDITOR,
  ENTER_ADVANCED_EDIT_MODE_FOR_SLIDE,
  CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE,
  ADD_SLIDE,
  COLLAPSE_SLIDES,
  MODAL_CLOSE,
  ENTER_CHILD_ARTICLE_EDITOR,
  DELETE_SLIDE,
  ADD_SLIDES_TO_SELECTION,
  REMOVE_SLIDES_FROM_SELECTION,
  UPDATE_SLIDE_NUMBER,
  ENTER_SLIDE_SELECTION_MODE,
  SET_INLINE_SLIDE_TEXT_EDIT_INFO,
  UPDATE_SLIDE_WITH_INLINE_EDITS,
  EDIT_TEXT,
  SET_NEXT_VIEW_MODE,
  SET_VIEW_MODE,
  EXPIRE_ARTICLE,
  AUTHORIZE_SLIDE,
  AUTHORIZE_BLOCK_SLIDE,
  AUTHORIZE_BLOCK_WEBSITE_SLIDE,
  AUTHORIZE_WEBSITE_SLIDE,
  UPDATE_SLIDE_CHILD_GIDES,
  ARTICLE_SUBMITTED,
  EXIT_SLIDE_SELECTION_MODE,
  UPDATE_SLIDE_SELECTION_METHOD,
  UPDATE_SLIDE_SELECTION_OPERATION_MODE,
  UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE,
  TOGGLE_ARTICLE_LAYOUT_VIEW,
  SET_SWIPE_SLIDE_POSITION,
  EXPAND_ALL_SLIDES,
  COLLAPSE_ALL_CHILD_GIDES_BY_GIDE_TYPE,
  ARTICLE_DISPLAY_OWNER_PANEL,
  SLIDE_ZERO_SUBMITTED,
  TOGGLE_NAVIGIDE,
  ARTICLE_UPDATED,
  UNFOLLOW_USER,
  FOLLOW_USER,
} from '../../constants/actionTypes';
import { ChildArticleType, getChildArticleTypeEnum, ArticleLayoutView, ArticleViewMode } from '../../models/ArticleLayoutEnum';
import { isNullOrUndefined } from 'util';
import { Expiration } from '../../models/ExpirationSettings';
import { SlideSelectionOperation, SlideSelectionTransferType, MultiSlideSelectionMethod } from '../../models/SlideSelectionInfo';
import SlideEditPanel, { SlideEditMode } from '../Slides/SlideEditPanel/SlideEditPanel';
import ViewbarPopper from '../Shared/Viewbar/ViewbarPopper';
import SearchContext from '../Shared/SearchBar/SearchContext';
import GideImage from '../Shared/Image/GideImage';
import { EXIT_DISTRIBUTE_GIDE, RENAVIGATE_TO_SAME_ARTICLE } from '../../reducers/common/common.actions';
import { DistributionSettingsEditorModal } from '../modals/DistributionSettingsEditorModal/DistributionSettingsEditorModal';
import { createChannelSearchElementFromChannel } from '../../models/Channel';
import { SlideView } from '../Gide/SlideView/SlideView';
import ImageSlide, { buildImageSlideFromSlideFile } from '../Slides/ImageSlide';
import GideOwnerViewPanel from '../Header/GideViewHeader/GideOwnerViewPanel/GideOwnerViewPanel';
import isNil from 'ramda/es/isNil';

const mapStateToProps = (state, ownProps) => {
  let view;
  let slide;
  if (ownProps.location) {
    const query = new URLSearchParams(ownProps.location.search);
    view = query.get('view');
    slide = query.get('slide');
  } else {
    view = window.location.search.indexOf('website') >= 0 ? 'website' : null;
  }

  const slideIds = state.article.slides.map(s => s.id);
  const titleProcessorItem =
    state.article.article && state.fileProcessor.titleSlideProcessorList.find(t => t.gideId === state.article.article.id);

  return {
    ...state.article,
    article:
      titleProcessorItem && state.article.article && state.article.article.slideZero
        ? {
            ...state.article.article,
            slideZero: {
              ...state.article.article.slideZero,
              data: {
                ...state.article.article.slideZero.data,
                image: titleProcessorItem.dataUrl ? URL.createObjectURL(titleProcessorItem.dataUrl) : '',
              },
            },
          }
        : state.article.article,
    slides: state.article.slides.map(slide => {
      const slideProcessingInfoList = state.fileProcessor.slideFileProcessorList.filter(
        slideFileProccessInfo => slideFileProccessInfo.slideId === slide.id,
      );
      const gallerySlideProcessingInfoList = state.fileProcessor.gallerySlideFileProcessorList.filter(
        gallerySlideFileProccessInfo => gallerySlideFileProccessInfo.slideId === slide.id,
      );

      // If any files are being processed, need to set the temp image files
      if (slideProcessingInfoList.length > 0 || gallerySlideProcessingInfoList.length > 0) {
        return {
          ...slide,
          data: {
            ...slide.data,
            files: slide.data.files.map(slideFile => {
              const slideFileProcessInfo = slideProcessingInfoList.find(item => item.slideFileId === slideFile.id);

              if (slideFileProcessInfo && slideFile.processingId === slideFileProcessInfo.processingId) {
                return {
                  ...slideFile,
                  url: URL.createObjectURL(slideFileProcessInfo.dataUrl),
                };
              } else if (gallerySlideProcessingInfoList.length > 0 && slideFile.imageFiles) {
                // Image Gallery
                return {
                  ...slideFile,
                  imageFiles: slideFile.imageFiles.map(igf => {
                    const gallerySlideFileProccessInfo = gallerySlideProcessingInfoList.find(
                      gsfi => igf.processingId === gsfi.processingId && igf.id === gsfi.galleryFileId,
                    );

                    if (gallerySlideFileProccessInfo) {
                      return {
                        ...igf,
                        url: URL.createObjectURL(gallerySlideFileProccessInfo.dataUrl),
                      };
                    } else {
                      return igf;
                    }
                  }),
                };
              } else {
                return slideFile;
              }
            }),
          },
        };
      } else {
        return slide;
      }
    }),
    currentUser: state.common.currentUser,
    attendances: state.article.attendances,
    retainArticle: state.common.retainArticle,
    multiSlideSelectionDetail: state.article.multiSlideSelectionDetail,
    articleEditMode: state.common.articleEditMode,
    viewMode: state.common.viewMode,
    additionUsername: ownProps.match ? ownProps.match.params.additionUsername : null,
    slideNumber: slide,
    view,
    loading: state.common.loading,
    renderColumns: state.article.renderColumns,
    childArticleEditInfo: state.article.childArticleEditInfo,
    slideSelectionModeDetail: state.article.slideSelectionModeDetail,
    individualSlideIdInAdvancedEditMode: state.article.individualSlideIdInAdvancedEditMode,
    collapsedSlides: view === 'website' || getCustomDomain() ? state.common.collapsedWebsiteSlides : state.article.collapsedSlides,
    displayCollapsedHeaders: state.common.displayCollapsedHeaders,
    wideScreenEditModeEnabled: state.common.wideScreenEditModeEnabled,
    nextSlideNumber: state.common.slideNumber,
    inlineEditedSlide: state.common.inlineEditedSlide,
    inlineSlideTextEditInfo: state.common.inlineSlideTextEditInfo,
    slideInlineViewSlidesDictionary: state.article.slideInlineViewSlidesDictionary,
    articleLayoutView: state.common.articleLayoutView,
    disableInlineTextEditing: state.common.lockInlineTextEdit,
    authorViewing:
      state.common.currentUser && state.article.article && state.article.article.author.username === state.common.currentUser.username,
    displayingNavigide: state.article.displayingNavigide,
    distributingGide: state.common.distributingGide,
    displayGideOwnerPanel: state.article.displayGideOwnerPanel,
    channels: state.common.channels,
    leftSidebarOpen: state.common.showLeftSidebar,
    showGideOwnerPanel: state.article.showGideOwnerPanel,
    preventCopy:
      titleProcessorItem !== undefined ||
      any(sf => contains(sf.slideId, slideIds), state.fileProcessor.slideFileProcessorList) ||
      any(sf => contains(sf.slideId, slideIds), state.fileProcessor.gallerySlideFileProcessorList),
    ignoreArticleNavigation: state.common.ignoreArticleNavigation,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: ARTICLE_PAGE_LOADED, payload }),
  onLoadArticle: payload => dispatch({ type: ARTICLE_PAGE_ARTICLE_LOADED, payload }),
  onLoadSlides: payload => dispatch({ type: ARTICLE_PAGE_SLIDES_LOADED, payload }),
  onUnload: () => dispatch({ type: ARTICLE_PAGE_UNLOADED }),
  onLoadComments: payload => dispatch({ type: LOAD_COMMENTS, payload }),
  onLoadAttendances: payload => dispatch({ type: ATTENDANCES_LOADED, payload }),
  exitGideDistribution: () => dispatch({ type: EXIT_DISTRIBUTE_GIDE }),
  favorite: slug =>
    dispatch({
      type: ARTICLE_FAVORITED,
      payload: agent.Articles.favorite(slug),
    }),
  unfavorite: slug =>
    dispatch({
      type: ARTICLE_UNFAVORITED,
      payload: agent.Articles.unfavorite(slug),
    }),
  updateArticle: payload => {
    dispatch({ type: ARTICLE_UPDATED, payload });
  },
  subscribe: slug =>
    dispatch({
      type: ARTICLE_SUBSCRIBED,
      payload: agent.Articles.subscribe(slug),
    }),
  unsubscribe: slug =>
    dispatch({
      type: ARTICLE_UNSUBSCRIBED,
      payload: agent.Articles.unsubscribe(slug),
    }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  onViewSlide: number => dispatch({ type: VIEW_SLIDE, number }),
  onCollapseAllSlides: (slides) => dispatch({ type: COLLAPSE_ALL_SLIDES, slides: slides }),
  setNextViewMode: mode => dispatch({ type: SET_NEXT_VIEW_MODE, mode }),
  deleteArticle: id => dispatch({ type: DELETE_ARTICLE, payload: { id } }),
  expireArticle: () => {
    dispatch({ type: EXPIRE_ARTICLE });
  },
  deleteSlides: payload => dispatch({ type: ARTICLE_DELETE_SLIDES, payload }),
  copySlides: payload => dispatch({ type: ARTICLE_COPY_SLIDES, payload }),
  copySlidesToArticle: payload => dispatch({ type: ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT, payload }),
  moveSlides: payload => dispatch({ type: ARTICLE_MOVE_SLIDES, payload }),
  exportSlides: payload => dispatch({ type: ARTICLE_EXPORT_SLIDES, payload }),
  copyArticle: payload => dispatch({ type: COPY_ARTICLE, payload }),
  setMultiSelectSlideView: payload => dispatch({ type: TOGGLE_MULTI_SLIDE_SELECTION_MODE, payload }),
  setSelectedSlidesFromRange: payload => dispatch({ type: SET_SELECTED_SLIDES, payload }),
  moveSlidesOutOfGide: payload => dispatch({ type: ARTICLE_MOVE_SLIDES_OUT_OF_GIDE, payload }),
  updateSlideAttachments: payload => dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
  showNotification: toasterMessageInfo => dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }),
  exitInlineSlideEditor: () => dispatch({ type: EXIT_CHILD_ARTICLE_EDITOR }),
  updateSlideAttachmentInfo: payload => dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
  onEnterAdvancedEditForSlide: slideId => dispatch({ type: ENTER_ADVANCED_EDIT_MODE_FOR_SLIDE, slideId }),
  convertTextSlidesToSingleRichTextSlide: payload => dispatch({ type: CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE, payload }),

  collapseSlides: slide => {
    dispatch({ type: COLLAPSE_SLIDES, slide: slide });
  },
  closeModal: closeDialogList => dispatch({ type: MODAL_CLOSE, payload: { closeDialogList } }),
  onSubmitSlide: payload => dispatch({ type: ADD_SLIDE, payload }),
  enterChildArticleEditor: payload => dispatch({ type: ENTER_CHILD_ARTICLE_EDITOR, payload }),
  deleteSlide: slideId => dispatch({ type: DELETE_SLIDE, payload: { slideId } }),
  addSelectedSlideIds: payload => dispatch({ type: ADD_SLIDES_TO_SELECTION, payload }),
  removeSelectedSlideIds: payload => dispatch({ type: REMOVE_SLIDES_FROM_SELECTION, payload }),
  updateSlideNumber: number => dispatch({ type: UPDATE_SLIDE_NUMBER, payload: { slideNumber: number } }),
  enterSlideSelectionMode: payload => dispatch({ type: ENTER_SLIDE_SELECTION_MODE, payload }),
  updateSlideSelectionMethod: payload => dispatch({ type: UPDATE_SLIDE_SELECTION_METHOD, payload }),
  updateSlideSelectionOperationMode: payload => dispatch({ type: UPDATE_SLIDE_SELECTION_OPERATION_MODE, payload }),
  setInlineSlideTextEditInfo: inlineSlideTextEditInfo =>
    dispatch({
      type: SET_INLINE_SLIDE_TEXT_EDIT_INFO,
      payload: inlineSlideTextEditInfo,
    }),
  updateSlideWithInlineEdits: payload => dispatch({ type: UPDATE_SLIDE_WITH_INLINE_EDITS, payload }),
  onEditingText: slide => dispatch({ type: EDIT_TEXT, slide }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  onAuthorizeSlide: async (id, authInfo, isBlockAuthorizationSlide) => {
    const result = await agent.Slides.authorize(id, authInfo);
    if (isBlockAuthorizationSlide) {
      if (result.authorized) {
        dispatch({
          type: AUTHORIZE_BLOCK_SLIDE,
          payload: { ...result, slideId: id },
        });
      }
    } else {
      dispatch({ type: AUTHORIZE_SLIDE, payload: { ...result, slideId: id } });
    }
  },
  onUnauthenticatedAuthorizeSlide: async (id, authInfo, isBlockAuthorizationSlide) => {
    const result = await agent.Slides.unauthenticatedAuthorize(id, authInfo);
    if (isBlockAuthorizationSlide) {
      if (result.authorized) {
        dispatch({
          type: AUTHORIZE_BLOCK_WEBSITE_SLIDE,
          payload: { ...result, slideId: id },
        });
      }
    } else {
      dispatch({
        type: AUTHORIZE_WEBSITE_SLIDE,
        payload: { ...result, slideId: id },
      });
    }
  },
  updateSlideChildGides: payload => dispatch({ type: UPDATE_SLIDE_CHILD_GIDES, payload }),
  updateChildGidesOnSlideForGideType: payload => dispatch({ type: UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE, payload }),
  onSubmitArticle: payload => dispatch({ type: ARTICLE_SUBMITTED, payload }),
  exitSlideSelectionMode: payload => dispatch({ type: EXIT_SLIDE_SELECTION_MODE, payload }),
  toggleArticleLayoutView: payload => dispatch({ type: TOGGLE_ARTICLE_LAYOUT_VIEW, payload }),
  setSwipeSlidePosition: payload => dispatch({ type: SET_SWIPE_SLIDE_POSITION, payload }),
  onExpandAllSlides: () => dispatch({ type: EXPAND_ALL_SLIDES }),
  onCollapseAllChildGidesByType: gideType => dispatch({ type: COLLAPSE_ALL_CHILD_GIDES_BY_GIDE_TYPE, payload: { gideType } }),
  onDisplayGideOwnerPanel: displayGideOwnerPanel => dispatch({ type: ARTICLE_DISPLAY_OWNER_PANEL, payload: { displayGideOwnerPanel } }),
  onSubmitSlideZero: (articleId, slideZero, articleSlug, tagList, description, descriptionSlide) =>
    dispatch({ type: SLIDE_ZERO_SUBMITTED, payload: { articleId, slideZero, tagList, articleSlug, description, descriptionSlide } }),
  onToggleNavigide: (displayingNavigide) => dispatch({ type: TOGGLE_NAVIGIDE, displayingNavigide: displayingNavigide }),
  followUser: (username) =>
  dispatch({
    type: FOLLOW_USER,
    payload: agent.Profile.follow(username),
  }),
  unFollowUser: (username) =>
  dispatch({
    type: UNFOLLOW_USER,
    payload: agent.Profile.unfollow(username),
  }),
  onRenavigateToSameArticle: (ignore) => dispatch({ type: RENAVIGATE_TO_SAME_ARTICLE, payload: { ignore }}),
});

const REFRESH_SECONDS = 20;

class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showComments: false,
      loading: true,
      retainArticle: false,
      SHOW_GIDE_COVER: false,
      initialArticleLoad: false,
      multiEditSelectionType: 'Selection',
      multiEditRanges: '',
      rangeSelectedSlideIds: [],
      viewingChildArticle: false,
      selectedSlide: undefined,
      expandCollapseSlideAdditionInfo: undefined,
    };

    this.viewbarRef = createRef();

    this.load = this._load.bind(this);
    this.toggleComments = this._toggleComments.bind(this);
    this.refresh = this._refresh.bind(this);
    this.handleChangedAttending = this._handleChangedAttending.bind(this);
    this.handleClickFavorite = this._handleClickFavorite.bind(this);
    this.handleClickSubscribe = this._handleClickSubscribe.bind(this);
    this.onUsersClicked = this._onUsersClicked.bind(this);
    this.purchase = this._purchase.bind(this);

    // This method will be passed in to a modal as props to be called when a
    // modal that gathers the necessary details is closed using the Export button.
    this.onExportSlides = async exportInfo => {
      const response = await agent.Articles.exportSlides(exportInfo.articleId, this.props.multiSlideSelectionDetail.selectedSlideIds);
      if (response) {
        this.props.showNotification({
          message: `Successfully exported selected slides to selected gide.`,
          type: NotificationType.INFO,
        });
      }
      this.props.setMultiSelectSlideView({
        showMultiSelectSlideView: false,
      });
      this.setState({ multiEditRanges: '' });
    };
    this.onChangeRange = ev => {
      this.setSelectedSlidesFromRanges(ev.target.value);
    };

    this.multiSlideSelectionChanged = (slideId, isSelected) => {
      if (isSelected) {
        this.props.addSelectedSlideIds({ slideIds: [slideId] });
      } else {
        this.props.removeSelectedSlideIds({ slideIds: [slideId] });
      }
    };

    // This collapses all of the slides for an article when it is initally loaded
    // If the headersCollapsed setting is true
    this.collapseAllSlidesOnLoad = () => {
      if (this.props.article && this.props.article.headersCollapsed && this.state.initialArticleLoad) {
        this.props.onCollapseAllSlides(this.props.slides);
        this.setState({ initialArticleLoad: false });
      }
    };

    this.setSelectedSlidesFromRanges = rangeText => {
      const rangeSlidePositions = [];
      if (this.rangesAreValid(rangeText)) {
        const ranges = rangeText.split(',');
        ranges.forEach(r => {
          const rangeValues = r.split('-');
          for (let i = rangeValues[0]; i <= rangeValues[1]; i++) {
            rangeSlidePositions.push(i - 1);
          }
        });
        const rangeSelectedSlideIds = this.props.slides
          .filter(s => contains(s.position, rangeSlidePositions) && slideIsValidForMultiEdit(s, this.props.multiSlideSelectionDetail.mode))
          .map(s => s.id);
        this.props.setSelectedSlidesFromRange({
          slideIds: rangeSelectedSlideIds,
        });
        return true;
      } else {
        this.props.showNotification({
          message: 'The ranges that you entered are invalid.',
          type: NotificationType.INFO,
        });
        return false;
      }
    };

    this.rangesAreValid = rangeText => {
      if (!hasValue(rangeText)) return false;

      const ranges = rangeText.split(',');
      let isValid = true;
      ranges.forEach(r => {
        const rangeValues = r.split('-');
        if (rangeValues.length !== 2 || isNaN(rangeValues[0].trim()) || isNaN(rangeValues[1].trim()) || rangeValues[0] > rangeValues[1]) {
          isValid = false;
          return;
        }
      });

      return isValid;
    };

    // /**
    //  * This will mova a slide to a new position within the same Gide. This also
    //  * applies to Attachments since they are truly Gides.
    //  */
    // this.moveSlidesWithinGide = async () => {
    //   if (this.props.multiSlideSelectionDetail.selectedSlideIds.length < 1) {
    //     alert('No slides were selected. There must be at least on slide selected');
    //     return;
    //   }
    //   const maxPosition = this.getSlidesForCurrentEditing().length - (this.props.multiSlideSelectionDetail.selectedSlideIds.length - 1);
    //   let moveToPosition = prompt(
    //     `Enter the slide number for the first slide that is being moved. This must be between 1 and ${maxPosition} because you are moving ${
    //       this.props.multiSlideSelectionDetail.selectedSlideIds.length
    //     } slides.`,
    //   );
    //   if (!moveToPosition) return;
    //   moveToPosition = parseInt(moveToPosition, 10) - 1;
    //   const payload = await agent.Articles.moveSlides(
    //     this.getArticleForCurrentEditing(),
    //     moveToPosition,
    //     this.props.multiSlideSelectionDetail.selectedSlideIds,
    //   );
    //   this.props.moveSlides({
    //     movedSlideInfoList: payload.movedSlideInfoList,
    //   });
    //   this.setState({ multiEditRanges: '' });
    // };

    this.moveCurrentArticleSlide = async (selectedSlideIds, moveToPosition) => {
      const payload = await agent.Articles.moveSlides(this.props.article, moveToPosition, selectedSlideIds);
      this.props.moveSlides({
        movedSlideInfoList: payload.movedSlideInfoList,
      });
    };

    /**
     * Move a slide that is an attachment to its parent Gide. This will delete
     * the slide from the attachment in addition to moving it.
     */
    this.moveSlidesToParentGide = async () => {
      const response = await agent.Slides.moveSlidesToParentArticle(
        this.props.article.id,
        this.props.childArticleEditInfo.article.slide,
        this.props.article.type,
        this.props.multiSlideSelectionDetail.selectedSlideIds,
      );
      this.props.moveSlidesOutOfGide({
        articleId: this.props.childArticleEditInfo.article.id, // This case should not need to determine which  this.getArticleForCurrentEditing().id,
        slideIds: this.props.multiSlideSelectionDetail.selectedSlideIds,
      });
      if (response) {
        // Need to update the parent slide, not the slide that was moved.
        getSlideAddonInformation(this.props.childArticleEditInfo.ownerSlide.id, this.props.updateSlideAttachmentInfo);
        this.props.showNotification({
          message: `Successfully moved selected slide(s) to selected gide.`,
          type: NotificationType.SUCCESS,
        });
      } else {
        this.props.showNotification({
          message: `Error moving selected slide(s) to parent gide.`,
          type: NotificationType.ERROR,
        });
      }
      this.setState({ multiEditRanges: '' });
    };

    /**
     * Make a copy the selected slides and then add them in order
     * starting at the selected slide location.
     */
    this.copySlidesWithinGide = async () => {
      if (this.props.multiSlideSelectionDetail.selectedSlideIds.length > 0) {
        let insertedPosition = prompt('Enter the position where the slides will be copied to:');
        if (!insertedPosition) return;
        insertedPosition = parseInt(insertedPosition, 10) - 1;
        const response = await agent.Articles.copySlides(
          this.props.article.id,
          insertedPosition,
          this.props.multiSlideSelectionDetail.selectedSlideIds,
        );
        this.props.copySlides({
          insertedPosition: insertedPosition,
          slides: response.slides,
        });
        this.setState({ multiEditRanges: '' });
      } else {
        this.props.showNotification({
          message: `You must select at least 1 slide to copy.`,
          type: NotificationType.SUCCESS,
        });
      }
    };

    /**
     * This is an attachment slide being copied to its owning Gide
     * The slide will be placed at the end of the Gide.
     */
    this.copySlidesToParentGide = async () => {
      const result = await agent.Articles.copyToParentGide(
        this.props.childArticleEditInfo.article.slide, // Copying from child to parent
        this.props.multiSlideSelectionDetail.selectedSlideIds,
        false,
      );

      // In this case we are copying slides from an inline edited child article to its parent
      this.props.copySlidesToArticle({
        slides: result.slides,
      });
      this.props.showNotification({
        message: `Successfully copied selected slide(s) to parent gide.`,
        type: NotificationType.SUCCESS,
      });
      this.setState({ multiEditRanges: '' });
    };
    this.moveSlidesWithinGide = e => {
      if (this.props.multiSlideSelectionDetail.selectedSlideIds.length > 0) {
        this.props.enterSlideSelectionMode &&
          this.props.enterSlideSelectionMode({
            operation: SlideSelectionOperation.Move,
          });
      } else {
        this.props.showNotification({
          message: `You must select at least 1 slide to move.`,
          type: NotificationType.WARNING,
        });
      }
    };
    /**
     * Deletes the selected slide(s)
     */
    this.deleteSlides = async e => {
      if (this.props.multiSlideSelectionDetail.selectedSlideIds.length > 0) {
        if (window.confirm('Are you sure you want to delete the selected slide(s)?')) {
          await agent.Articles.deleteSlides(this.props.article, this.props.multiSlideSelectionDetail.selectedSlideIds);
          this.props.deleteSlides({
            slideIds: this.props.multiSlideSelectionDetail.selectedSlideIds,
          });
          this.setState({ multiEditRanges: '' });
          if (this.props.childArticleEditInfo) {
            // get the slide's attachment details
            const slideAttachmentResponse = await agent.Slides.getAttachmentDetails(this.props.childArticleEditInfo.article.slide);
            this.props.updateSlideAttachments({
              slideId: this.props.childArticleEditInfo.article.slide.id,
              childArticlesSlideTypes: slideAttachmentResponse.childArticlesSlideTypes,
              childArticlesSlideDetails: slideAttachmentResponse.childArticlesSlideDetails,
            });
          }
          this.props.exitSlideSelectionMode();
        }
      } else {
        this.props.showNotification({
          message: `You must select at least 1 slide to delete.`,
          type: NotificationType.WARNING,
        });
      }
    };

    this.addSlideAddition = async (ownerSlide, additionType) => {
      // if the additionType is an attachment or private note, then check if the article exists
      let additionArticleId = undefined;
      if (
        getChildArticleTypeEnum(additionType) === ChildArticleType.Attachments ||
        getChildArticleTypeEnum(additionType) === ChildArticleType.PrivateNotes
      ) {
        const articleTypeNameParam = additionType === 'attachments' ? 'SETTINGS' : additionType.toUpperCase().replace(/\s/g, '');
        const articleResponse = await agent.Slides.getSettings(ownerSlide.id, articleTypeNameParam);
        additionArticleId = articleResponse.article.id;
      }

      this.props.openModal({
        modalType: 'GideViewerManagerModal',
        modalProps: {
          currentUser: this.props.currentUser,
          view: this.props.view,
          viewMode: this.props.viewMode,
          nextViewMode: this.props.nextViewMode,
          ownerSlide: ownerSlide,
          viewOnly: false,
          selectedArticleType: getChildArticleTypeEnum(additionType),
          selectedArticleId: additionArticleId,
          articleTypeList: [getChildArticleTypeEnum(additionType)],
          // onSubmitArticle: this.props.onSubmitArticle,
          openModal: this.props.openModal,
          closeModal: this.props.closeModal,
          closeGideViewer: this.closeGideViewer,
          disableInlineTextEditing: this.props.disableInlineTextEditing,
          // addSlideAddition: this.addSlideAddition,
        },
      });
    };

    this.openChildArticleModal = async (slide, articleTypeList, selectedArticleType, selectedArticleId, viewOnly) => {
      this.props.openModal({
        modalType: 'GideViewerManagerModal',
        modalProps: {
          currentUser: this.props.currentUser,
          view: this.props.view,
          viewMode: this.props.viewMode,
          viewOnly: viewOnly,
          nextViewMode: this.props.nextViewMode,
          ownerSlide: slide,
          selectedArticleType: selectedArticleType,
          selectedArticleId: selectedArticleId,
          articleTypeList: articleTypeList,
          // onSubmitArticle: this.props.onSubmitArticle,
          openModal: this.props.openModal,
          closeModal: this.props.closeModal,
          closeGideViewer: this.closeGideViewer,
          disableInlineTextEditing: this.props.disableInlineTextEditing,
          // addSlideAddition: this.addSlideAddition,
        },
      });
      // }
    };
  }

  _purchase() {
    const { article } = this.props;
    this.setState({ loading: true });
    agent.Articles.purchase(article).then(resp => {
      if (resp.success) {
        window.setTimeout(() => {
          this.load();
          this.props.showNotification({
            message: `Purchased.`,
            type: NotificationType.INFO,
          });
        }, 2000);
      } else {
        this.setState({ loading: false });
        this.props.showNotification({
          message: resp.msg || `Sorry, please try again.`,
          type: NotificationType.INFO,
        });
      }
    });
  }

  _onUsersClicked() {
    this.props.openModal({
      modalType: 'DistributeModal',
      modalProps: {
        type: 'SEND',
      },
    });
  }

  _handleClickFavorite(ev) {
    ev.preventDefault();
    if (!this.props.currentUser) {
      return alert('Please register or log in first!');
    }
    if (this.props.article.favorited) {
      this.props.unfavorite(this.props.article);
    } else {
      this.props.favorite(this.props.article);
    }
  }

  _handleClickSubscribe(ev) {
    ev.preventDefault();
    if (!this.props.currentUser) {
      return alert('Please register or log in first!');
    }
    if (this.props.article.subscribed) {
      this.props.unsubscribe(this.props.article);
    } else {
      this.props.subscribe(this.props.article);
    }
  }

  _handleChangedAttending(ev, data) {
    if (!data.value) {
      return;
    }
    let attendance = {
      answer: data.value,
    };
    agent.Articles.attend(this.props.article, attendance).then(x => {
      this.props.onLoadAttendances(agent.Articles.attendances(this.props.article));
    });
  }

  _toggleComments() {
    this.props.onLoadComments(agent.Comments.forArticle(this.props.article));
    this.setState(prevState => ({
      showComments: !prevState.showComments,
    }));
  }

  _refresh() {
    let articleId = this.props.match.params.id;
    this.props.onLoad(Promise.all([agent.Articles.get(articleId), agent.Slides.forArticle(articleId)]));

    this.props.onLoadAttendances(agent.Articles.attendances(this.props.article));

    if (this.state.refreshTimeout) {
      clearTimeout(this.state.refreshTimeout);
    }
    const refreshTimeout = window.setTimeout(this.refresh, REFRESH_SECONDS * 1000);
    this.setState({ refreshTimeout });
  }

  async _load(username, slug) {
    const articleLoaded = this.props.article && slug === this.props.article.slug;
    if (this.state.refreshTimeout) {
      clearTimeout(this.state.refreshTimeout);
    }
    if (!this.props.type) {
      let articleResp;
      let slideResp;
      // if the article is not currently loaded, then get it from services
      if (!articleLoaded) {
        try {
          if (this.props.match.params.id) {
            articleResp = await agent.Articles.get(this.props.match.params.id);
          } else if (this.props.match.params.username && this.props.match.params.slug) {
            const u = username || this.props.match.params.username;
            const s = slug || this.props.match.params.slug;
            articleResp = await agent.Articles.getByUsernameAndSlug(u, s);
          }
          // load the article's slides
          slideResp = await agent.Slides.forArticle(articleResp.article);
        } catch (err) {
          this.setState({ loading: false });

          if (err && err.status === 404) {
            // this.props.showNotification({
            //   message: "Unable to find a gide with that url.",
            //   type: NotificationType.ERROR,
            //   timeoutMilliseconds: 5000,
            // });
            history.replace('/404');
            return; // Quit early! (so that we don't throw more errors attempting to load the page).
          } else {
            let error = 'Unknown error';
            if (err && err.status === 403) {
              // error =
              //   'You do not have access to view the contents of this gide. This gide is in a private or restricted channel.';              
              history.replace('/404');
              return; // Quit early! (so that we don't throw more errors attempting to load the page).
            } 

            this.props.showNotification({
              message: error || `Access Denied.`,
              type: NotificationType.ERROR,
              timeoutMilliseconds: 30000,
            });
            
            // this.props.showNotification({
            //   message: err, // `Purchase required.`,
            //   type: NotificationType.INFO,
            // });
          }
        }
      }
      const article = articleLoaded ? this.props.article : articleResp.article;
      const slides = articleLoaded || !slideResp ? this.props.slides : slideResp.slides;

      this.props.onLoadArticle({ article: article });

      if (articleResp && articleResp.article.date) {
        let attendences = await agent.Articles.attendances(article);
        this.props.onLoadAttendances(attendences);
      }

      if (slides) {
        this.props.onLoadSlides(slides);
        this.collapseAllSlidesOnLoad();
        this.setState({ loading: false });

        const { slideNumber } = this.props;
        if (!isNil(slideNumber)) {
          this.scrollToSlide(slideNumber);
        }
        if (article.type === 'CHAT' || article.type === 'POST') {
          const refreshTimeout = window.setTimeout(this.load, 10000);
          this.setState({ refreshTimeout });
        }
      } else if (this.props.type === 'SETTINGS' && this.props.slide && !articleLoaded) {
        const childArticleResp = await agent.Slides.getSettings(this.props.slide, ChildArticleType.Attachments);
        this.props.onLoadArticle({ article: childArticleResp.article });
        let childArticleSlidesResp = await agent.Slides.forArticle(childArticleResp.article);
        let childArticleSlides = childArticleSlidesResp.slides;
        this.props.onLoadSlides({ slides: childArticleSlides });
        this.setState({ loading: false });
      } else if (this.props.type && !articleLoaded) {
        let resp = await agent.Articles.getType(this.props.type, this.props.username);

        this.props.onLoadArticle(resp);
        this.setState({ articleId: resp.article.slug });
        let slides = [];
        if (this.props.type === 'MY' && this.props.gides) {
          slides = this.props.gides.map(gide => {
            let slide = {
              author: {
                username: '',
              },
              slideType: 'GIDE',
              data: {
                gide: gide,
              },
            };
            return slide;
          });
        } else if (this.props.type === 'CALENDAR' && this.props.events) {
          slides = this.props.events.map(event => {
            let slide = {
              author: {
                username: '',
              },
              slideType: 'GIDE',
              data: {
                gide: event,
              },
            };
            return slide;
          });
        } else {
          resp = await agent.Slides.forArticle(resp.article);
          slides = resp.slides;
        }
        this.props.onLoadSlides(slides);
        this.collapseAllSlidesOnLoad();
        this.setState({ loading: false });
      }
    }
  }

  onSwitchArticleLayoutView = layoutView => {
    if (
      (this.props.view !== 'website' && layoutView === ArticleLayoutView.WideScreen) ||
      (this.props.view === 'website' && layoutView === ArticleLayoutView.Responsive)
    ) {
      this.props.toggleArticleLayoutView({ layoutView });
      history.push(`${urlForArticle(this.props.article, layoutView)}`);
    }
  };

  onSwitchArticleViewMode = viewMode => {
    const mode = viewMode === ArticleViewMode.Scroll ? 'SCROLL' : viewMode === ArticleViewMode.Slideshow ? 'SWIPE' : 'SLIDE';
    mode === 'SWIPE' ? this.onEnterSwipeMode() : this.props.onSetViewMode(mode);
  };

  onCreateGide = () => {
    const article = {
      title: 'Untitled',
    };
    this.props.onSubmitArticle(agent.Articles.create(article));
  };

  onSearchArticleForText = searchText => {
    this.setState({ searchText: searchText, searchTextPosition: 0 });
    setTimeout(() => {
      const queryString = 'mark';
      const searchElements = document.querySelectorAll(queryString);
      this.setState({ numberOfSearchElements: searchElements ? searchElements.length : 0 });
    }, 100);
  };

  moveToSearchTextPosition = searchPosition => {
    const els = document.querySelectorAll('mark');
    if (els && els.length > 0) {
      const calcSearchPosition = searchPosition >= 0 ? (searchPosition > els.length - 1 ? 0 : searchPosition) : els.length - 1;
      const el = els[calcSearchPosition];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      this.setState({ searchTextPosition: calcSearchPosition });
    }
  };

  onMoveToNextSearchText = () => {
    this.moveToSearchTextPosition(!isNullOrUndefined(this.state.searchTextPosition) ? this.state.searchTextPosition + 1 : 0);
  };

  onMoveToPrevSearchText = () => {
    this.moveToSearchTextPosition(!isNullOrUndefined(this.state.searchTextPosition) ? this.state.searchTextPosition - 1 : 0);
  };

  onExpandAllSlides = expandType => {
    expandType === 'Headers'
      ? this.props.onExpandAllSlides()
      : this.setState({
          expandCollapseSlideAdditionInfo: { gideType: expandType === 'Notes' ? 'PRIVATENOTES' : 'SETTINGS', action: 'EXPAND' },
        });
  };

  onCollapseAllSlides = (collapseType) => {
    collapseType === 'Headers'
      ? this.props.onCollapseAllSlides(this.props.slides)
      : this.setState({
          expandCollapseSlideAdditionInfo: { gideType: collapseType === 'Notes' ? 'PRIVATENOTES' : 'SETTINGS', action: 'COLLAPSE' },
        });
  };

  getFirstSlideInView = () => {
    const els = document.querySelectorAll(`[data-slide-id]`);
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const slidesInView = Array.from(els).filter(e => {
      const bounding = e.getBoundingClientRect();
      return bounding.bottom >= 0 && bounding.top <= viewportHeight;
    });
    const slideIndex =
      slidesInView && slidesInView.length > 0
        ? this.props.slides
          ? this.props.slides.findIndex(s => s.id === slidesInView[0].dataset.slideId)
          : 0
        : this.props.slides.length - 1;
    return this.props.slides ? this.props.slides[slideIndex] : undefined;
  };

  getSlideShowSlides = slides => {
    const slideShowSlides = [];
    const nonImageSlides = slides.filter(s => s.slideType !== 'IMAGE');
    nonImageSlides.forEach(slide => {
      slideShowSlides.push({
        slide: slide,
        component: (
          <SlideView
            slide={slide}
            disableExpiration={this.props.disableSlideExpiration === true}
            viewingInline={false}
            slidePosition={slide.position}
            currentUser={this.props.currentUser}
            view={this.props.view}
            viewMode={this.props.viewMode}
            nextViewMode={this.props.nextViewMode}
            article={this.props.article}
            articleEditMode={this.props.articleEditMode}
            onEditingText={this.props.onEditingText}
            viewOnly={true}
            totalNumberOfSlides={this.props.slides ? this.props.slides.length : 0}
            showNotification={this.props.showNotification}
            wideScreenEditModeEnabled={this.props.wideScreenEditModeEnabled}
            collapsed={this.props.collapsedSlides && !isNullOrUndefined(this.props.collapsedSlides.find(cs => slide.id === cs.id))}
            hideThreeDotMenu={true}
            collapseSlides={this.props.collapseSlides}
            disableInlineTextEditing={true}
          />
        ),
      });
    });

    const imageSlides = slides.filter(s => s.slideType === 'IMAGE');
    imageSlides.forEach(slide => {
      const slideFiles = slide.data.files.map((image, i) => {
        return buildImageSlideFromSlideFile(slide, image, i);
      });
      const swipeableItems = slideFiles.map((slideShowSlide, i) => {
        slideShowSlide.position = slideShowSlide.position = slide.position + i;
        const component = <ImageSlide key={slide.position + i} slide={slideShowSlide} viewMode="SWIPE" />;
        return {
          component,
          slide,
        };
      });
      swipeableItems.forEach(si => slideShowSlides.push(si));
    });
    return slideShowSlides;
  };

  onEnterSwipeMode = () => {
    this.props.setNextViewMode(this.props.viewMode);
    const slide = this.getFirstSlideInView();
    const slideNumToNavigateTo = slide ? slide.position + 1 : 1;
    this.props.onSetViewMode('SWIPE');
    this.props.setSwipeSlidePosition({
      slidePosition: slideNumToNavigateTo,
    });

    window.setTimeout(() => {
      history.push(`${urlForArticle(this.props.article)}/slide/${slideNumToNavigateTo}`);
    }, 300);
  };

  scheduled = false;
  top = 0;

  onScrollArticle = event => {

    // TEMPORARILY REMOVE UNTIL CAN SPEND TIME TO PROPERLY COME UP WITH WORKAROUND FOR IOS
    // const element = event.target;
    // const top = element.scrollTop;
    // if(top === 0 && !this.props.displayGideOwnerPanel) {
    //   this.top = 0;
    //   // this.props.onDisplayGideOwnerPanel(true);
    // } else if (top !== 0) {
    //   const hideOnScroll = Math.abs(this.top - top) > 25;
    //   if(hideOnScroll && this.props.displayGideOwnerPanel) {
    //     // if(!isIOSDevice) {
    //       this.props.onDisplayGideOwnerPanel(false);
    //       this.top = top;
    //     // }
    //   }
    // }


    if (this.props.displayingNavigide) {
      const slide = this.getFirstSlideInView();
      this.setState({ scrollToSlidePosition: slide ? slide.position + 1 : 1 });
    }
  };

  scrollToSlidePosition = slidePosition => {
    this.props.slides.length > slidePosition && this.props.slides[slidePosition]
      ? this.scrollToSlideById(this.props.slides[slidePosition].id)
      : this.scrollToSlide(slidePosition);
  };

  scrollToSlide(number) {
    window.setTimeout(() => {
      const els = document.querySelectorAll('.slideWrap');
      const el = els[number - 1];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 1000);
  }

  scrollToSlideById(slideId) {
    const queryString = `[data-slide-id="${slideId}"]`;
    const el = document.querySelector(queryString);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }

  componentWillMount() {
    if (this.props.article && this.props.match && this.props.currentUser && this.props.article.slug === this.props.match.params.slug) {
      this.load(this.props.currentUser.username, this.props.article.slug);
    } else {
      this.setState({ loading: true });
      window.scrollTo(0, 1);
      this.load();
    }
  }
  articleDiv;
  componentDidMount() {
    if (this.articleDiv) {
      this.articleDiv.addEventListener('scroll', this.onScrollArticle, { passive: true, capture: true });
    }
  }
  componentWillUnmount() {
    /*
     * Commenting out unblock until I find a way to integrate it with the redirect
     * that happens during Title set from title slide.  That redirect needs to
     * happen because the slug changes based on the title.
     */
    // this.unblock();
    if (this.articleDiv) {
      this.articleDiv.removeEventListener('scroll', this.onScrollArticle, { passive: true });
    }
    if (this.state.refreshTimeout) {
      clearTimeout(this.state.refreshTimeout);
    }
    if (this.state.expireArticleTimeout) {
      clearTimeout(this.state.expireArticleTimeout);
    }
    if (!this.props.retainArticle) this.props.onUnload();
  }
  initializeArticleExpiration() {
    const canExpire =
      this.props.article &&
      this.props.currentUser &&
      this.props.article.author.username !== this.props.currentUser.username &&
      !this.state.isExpired === true &&
      this.props.article.expirationSettings !== undefined;

    let timer = 0; // 0 is equivalent to undefined or false when checking if(timer)
    if (canExpire && this.props.article.expirationSettings && this.props.currentUser && this.props.article.expirationSettings.source) {
      if (this.props.article.expirationSettings.source.expiration === Expiration.DateTime) {
        const duration = new Date(this.props.article.expirationSettings.source.dateTime).getTime() - new Date().getTime();
        // In this case we only need to set it to expired locally. Next time the user loads the gide
        // this article will not be returned as viewable if it is returned at all. It may appear hidden
        // by default if it is set to hide. If it is set to delete then it will be placed in the users
        // Trash channel and not be returned.
        if (duration > 0) {
          timer = window.setTimeout(() => {
            this.props.expireArticle();
          }, duration);
          this.setState({ expireArticleTimeout: timer });
        } else {
          this.props.expireArticle();
        }
      } else {
        // Currently the only other expiration type is duration
        const duration = convertToMilliseconds(
          this.props.article.expirationSettings.source.duration,
          this.props.article.expirationSettings.source.durationUnits,
        );
        // const duration = this.props.article.expirationSettings.source.duration * 1000;
        // This will initialize the itemexpiration entry in the database with the expiration time  so that once it does expire
        agent.Articles.expire(this.props.article.id);
        timer = window.setTimeout(() => {
          this.setState({ isExpired: true });
          // If the user is viewing the article when it expires, it will behave like the setting is
          // set to hide even if set to Bye. Bye will not be returned so if user refreshes at this point on a
          // Bye the user will get a 404.
          this.props.expireArticle();
        }, duration);
        this.setState({ expireArticleTimeout: timer });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.gides !== this.props.gides) {
      this.setState({ loading: true });
      this.load();
    } else if (
      (prevProps.match && prevProps.match.params.username !== this.props.match.params.username) ||
      (prevProps.match && prevProps.match.params.slug !== this.props.match.params.slug)
    ) {
      if (!this.props.ignoreArticleNavigation) {
        this.setState({ loading: true });
        this.load(this.props.match.params.username, this.props.match.params.slug);
        window.scrollTo(0, 1);
    } else {
      this.props.onRenavigateToSameArticle(false);
    }
    } else if (prevProps.slug !== this.props.slug) {
      if (!this.props.ignoreArticleNavigation) {
        this.setState({ loading: true });
        this.load(this.props.slug);
        window.scrollTo(0, 1);
      } else {
        this.props.onRenavigateToSameArticle(false);
      }
    } else if (
      this.props.slides &&
      prevProps.slides &&
      this.props.scrollToPosition &&
      !this.props.childArticleEditInfo &&
      !this.props.preventScrollToSlide &&
      //TODO: replace scrollToPosition with scrollToSlideId
      (prevProps.slides.length !== this.props.slides.length || prevProps.scrollToPosition !== this.props.scrollToPosition)
    ) {
      this.scrollToSlide(this.props.scrollToPosition);
    } else if (
      this.props.slides &&
      prevProps.slides &&
      this.props.scrollToSlideId &&
      !this.props.childArticleEditInfo &&
      !this.props.preventScrollToSlide &&
      (prevProps.slides.length !== this.props.slides.length || prevProps.scrollToSlideId !== this.props.scrollToSlideId)
    ) {
      this.scrollToSlideById(this.props.scrollToSlideId);
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (
      this.props.article &&
      this.props.article.headersCollapsed &&
      (!prevProps.article || this.props.article.slug !== prevProps.article.slug)
    ) {
      this.setState({ initialArticleLoad: true });
    }

    if (
      this.props.article &&
      this.props.article.expirationSettings &&
      !this.state.expireArticleTimeout &&
      !this.props.article.isExpired === true
    ) {
      this.initializeArticleExpiration();
    }

    if (this.props.displayingNavigide && !prevProps.displayingNavigide) {
      const slide = this.getFirstSlideInView();
      this.setState({ scrollToSlidePosition: slide ? slide.position + 1 : 1 });
    }
  }

  getOutOfOrderSlideId = (slidePosition, slides) => {
    const slidesOutOfOrder = slides.filter(s => s.position === slidePosition);
    return slidesOutOfOrder && slidesOutOfOrder.length > 0 ? slidesOutOfOrder[0].id : undefined;
  };

  slideAddedToChildArticle = slide => {
    if (this.props.childArticleEditInfo.ownerSlide) {
      // update the owner slides attachment type list
      getSlideAddonInformation(this.props.childArticleEditInfo.ownerSlide.id, this.props.updateSlideAttachmentInfo);
    }
  };

  closeGideViewer = async (ownerSlideId, slideInlineViewSlidesDictionary) => {
    this.props.closeModal();
    if (!isNullOrUndefined(this.props.slideInlineViewSlidesDictionary[ownerSlideId])) {
      const inlineViewSlidesList = [];
      Object.keys(slideInlineViewSlidesDictionary).forEach(key => {
        let inlineViewSlide = {
          articleType: slideInlineViewSlidesDictionary[key].articleType,
          slides: slideInlineViewSlidesDictionary[key].slides,
        };
        inlineViewSlidesList.push(inlineViewSlide);
      });

      // update inline view
      this.props.updateSlideChildGides({
        slideId: ownerSlideId,
        inlineViewSlidesList: inlineViewSlidesList,
      });
    }
    // get the slide's addition details
    const slideAdditionResponse = await agent.Slides.getAttachmentDetails(ownerSlideId);
    this.props.updateSlideAttachments({
      slideId: ownerSlideId,
      childArticlesSlideTypes: slideAdditionResponse.childArticlesSlideTypes,
      childArticlesSlideDetails: slideAdditionResponse.childArticlesSlideDetails,
    });
  };

  onTransfer = e => {
    if (this.props.multiSlideSelectionDetail.selectedSlideIds && this.props.multiSlideSelectionDetail.selectedSlideIds.length > 0) {
      this.props.enterSlideSelectionMode &&
        this.props.enterSlideSelectionMode({
          options: (
            <div className="slideSelectionTransferType">
              <RadioButtonComponent
                checked={true}
                label="Copy"
                name="transferType"
                value={SlideSelectionTransferType.Copy.toString()}
                change={args => {
                  if (args && args.value === SlideSelectionTransferType.Copy.toString()) {
                    this.props.updateSlideSelectionOperationMode &&
                      this.props.updateSlideSelectionOperationMode({
                        transferType: SlideSelectionTransferType.Copy,
                      });
                  }
                }}
              />
              <RadioButtonComponent
                checked={false}
                label="Move"
                name="transferType"
                value={SlideSelectionTransferType.Move.toString()}
                change={args => {
                  if (args && args.value === SlideSelectionTransferType.Move.toString()) {
                    this.props.updateSlideSelectionOperationMode &&
                      this.props.updateSlideSelectionOperationMode({
                        transferType: SlideSelectionTransferType.Move,
                      });
                  }
                }}
              />
            </div>
          ),
          operation: SlideSelectionOperation.Transfer,
          transferType: SlideSelectionTransferType.Copy,
        });
    } else {
      this.props.showNotification({
        message: 'You must select at least 1 slide to transfer.',
        type: NotificationType.INFO,
      });
    }
  };
  onMoveToAttachments = e => {
    if (this.props.multiSlideSelectionDetail.selectedSlideIds && this.props.multiSlideSelectionDetail.selectedSlideIds.length > 0) {
      this.props.enterSlideSelectionMode &&
        this.props.enterSlideSelectionMode({
          options: (
            <div className="slideSelectionTransferType">
              <RadioButtonComponent
                checked={false}
                label="Copy"
                name="transferType"
                value={SlideSelectionTransferType.Copy.toString()}
                change={args => {
                  if (args && args.value === SlideSelectionTransferType.Copy.toString()) {
                    this.props.updateSlideSelectionOperationMode &&
                      this.props.updateSlideSelectionOperationMode({
                        transferType: SlideSelectionTransferType.Copy,
                      });
                  }
                }}
              />
              <RadioButtonComponent
                checked={true}
                label="Move"
                name="transferType"
                value={SlideSelectionTransferType.Move.toString()}
                change={args => {
                  if (args && args.value === SlideSelectionTransferType.Move.toString()) {
                    this.props.updateSlideSelectionOperationMode &&
                      this.props.updateSlideSelectionOperationMode({
                        transferType: SlideSelectionTransferType.Move,
                      });
                  }
                }}
              />
            </div>
          ),
          operation: SlideSelectionOperation.AttachToSlide,
          transferType: SlideSelectionTransferType.Move,
        });
    } else {
      this.props.showNotification({
        message: 'You must select at least 1 slide to add as an attachment.',
        type: NotificationType.INFO,
      });
    }
  };

  onCopySlidesWithinGide = e => {
    if (this.props.multiSlideSelectionDetail.selectedSlideIds && this.props.multiSlideSelectionDetail.selectedSlideIds.length > 0) {
      this.props.enterSlideSelectionMode &&
        this.props.enterSlideSelectionMode({
          operation: SlideSelectionOperation.Copy,
        });
    } else {
      this.props.showNotification({
        message: 'You must select at least 1 slide to copy.',
        type: NotificationType.INFO,
      });
    }
  };

  onShare = (notificationTarget) => {
    if (this.props.article) {
      shareGide(
        `${this.props.article && this.props.article.slideZero ? this.props.article.slideZero.data.title : 'Gides'}`,
        urlForArticle(this.props.article),
        this.props.showNotification, notificationTarget
      );
    }
  };

  render() {
    const {
      article,
      slides,
      viewMode,
      view,
      multiSlideSelectionDetail,
      setMultiSelectSlideView,
      childArticleEditInfo,
      channels,
    } = this.props;
    const articleLayoutView = view !== 'website' ? ArticleLayoutView.Responsive : ArticleLayoutView.WideScreen;
    const articleViewMode =
      this.props.viewMode === 'SCROLL' || this.props.viewMode === 'SLIDE'
        ? ArticleViewMode.Scroll
        : this.props.viewMode === 'SWIPE'
        ? ArticleViewMode.Slideshow
        : ArticleViewMode.Thumbnail;

    const channel = channels.find(c => article && c._id === article.channel && c.title !== 'Drafts' && c.type !== 'SPECIAL');
    const gideChannel = channel ? createChannelSearchElementFromChannel(channel) : undefined;
    const draftChannel = channels.find(c => c.title === 'Drafts' && c.type === 'SPECIAL');

    const draftChannelId = draftChannel ? draftChannel._id : undefined;
    const webView = view === 'SCROLL';
    let attendances = this.props.attendances || [];
    const FAVORITED_CLASS = 'btn btn-sm btn-primary';
    const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';
    const articlePageClasses = classNames(
      'article-page',
      'flexColumnFull',
      { website: view === 'website' }, // TODO: Find out if this is correct. Looks strange
    );

    const slideIdOfSlideOutOfOrder =
      !isNullOrUndefined(this.props.nextSlideNumber) && this.props.slides
        ? this.getOutOfOrderSlideId(this.props.nextSlideNumber, this.props.slides)
        : undefined;

    return (
      <div
        id="articlePage"
        className={articlePageClasses}
        style={{
          background: article ? article.backgroundColor : 'white',
          fontFamily: article ? article.font : '',
        }}
        ref={ref => {
          this.articleDiv = ref;
        }}
        onClick={e => {
          if (!this.props.childArticleEditInfo && this.props.individualSlideIdInAdvancedEditMode) {
            this.props.onEnterAdvancedEditForSlide({ slideId: null });
          }
        }}
      >
        {article && (
          <>
            {article.type === 'CHAT' && viewMode === 'SCROLL' && (
              <div className="row chatHeader">
                <div className="col-xs-12">
                  <AuthorHeader verb={'Started by:'} author={article.author} date={article.createdAt} />
                  <div className="info">
                    <div className="title">{article.title}</div>
                    <div className="users" onClick={this.onUsersClicked}>
                      <ul className="avatars">
                        {article.userList.map((user, i) => {
                          return (
                            <li key={i}>
                              <GideImage className="userPic" src={user.image} alt="avatar" />
                            </li>
                          );
                        })}
                      </ul>
                      <div className="userCount">{article.userList.length} USERS</div>
                    </div>
                    {slides && <div className="stats">{slides.length} SLIDES</div>}
                  </div>
                </div>
              </div>
            )}

            {article.price !== null &&
              article.price > 0 &&
              !article.purchased &&
              article.author.username !== this.props.currentUser.username && (
                <div style={{ textAlign: 'center', margin: '5px' }}>
                  <section>
                    <Button primary disabled={article.purchased} onClick={this.purchase}>
                      {article.purchased ? 'Purchased' : `$${article.price}`}
                    </Button>
                  </section>
                </div>
              )}
              <GideOwnerViewPanel
                className={!this.props.authorViewing && this.props.displayGideOwnerPanel ? ownerPanelStyles.displayOwnerPanel : ownerPanelStyles.doNotDisplayOwnerPanel}
                gideAuthorName={article.author.username}
                gideAuthorImage={article.author.image}
                isVerified={true}
                leftSidebarOpen={this.props.leftSidebarOpen}
                isFollowing={article.author.following}
                followUser={this.props.followUser}
                unFollowUser={this.props.unFollowUser}
                signUp={this.props.onShowSignup}
                signIn={this.props.onShowSignin}
                isLoggedIn={this.props.currentUser && this.props.currentUser.username}
              />
            {!this.props.slides || this.state.loading ? (
              <div style={{ paddingTop: '50px' }} />
            ) : (
              <div
                className={`container page ${this.props.articleEditMode ? this.props.articleEditMode : ''} ${
                  childArticleEditInfo ? 'inlineChildArticleEditorVisible' : ''
                } ${
                  this.props.slideSelectionModeDetail && this.props.slideSelectionModeDetail.operation !== SlideSelectionOperation.Delete
                    ? 'slideSelectionMode'
                    : ''
                }`}
              >
                <div className="row article-content" style={{ display: 'none' }}>
                  <div className="col-xs-12">
                    {!webView &&
                      this.props.article &&
                      this.props.type !== 'SETTINGS' &&
                      this.props.type !== 'HI' &&
                      this.props.type !== 'MY' &&
                      this.props.type !== 'CALENDAR' &&
                      this.props.article.type !== 'CHAT' && (
                        <div className="banner">
                          <div className="container">
                            <div>
                              {this.props.article.type !== 'CHANNEL' && (
                                <button
                                  className={this.props.article.favorited ? FAVORITED_CLASS : NOT_FAVORITED_CLASS}
                                  onClick={this.handleClickFavorite}
                                >
                                  <i className="ion-heart" /> {this.props.article.favoritesCount}
                                </button>
                              )}
                              {this.props.article.type === 'CHANNEL' && (
                                <button
                                  className={this.props.article.favorited ? FAVORITED_CLASS : NOT_FAVORITED_CLASS}
                                  onClick={this.handleClickSubscribe}
                                >
                                  <GideImage
                                    className="color-primary-500-svg"
                                    style={{ height: '20px' }}
                                    src="/icons/nav/channels.svg"
                                    alt="channels"
                                  />
                                  <span> Subscribe </span>
                                  {this.props.article.subscribersCount}
                                </button>
                              )}
                            </div>
                            <ArticleMeta
                              article={this.props.article}
                              canModify={this.props.currentUser && this.props.currentUser.username === this.props.article.author.username}
                              onChangedAttending={this.handleChangedAttending}
                              attendances={attendances}
                            />
                            {this.state.SHOW_GIDE_COVER && (
                              <div className="gideCover">
                                <GideImage className="gideImage" src={article.image} alt="splash" />
                                <h2>{article.title}</h2>
                                <Link to={`/@${article.author.username}`}>
                                  <Image src={article.author.image} alt={article.author.username} className="userPic" />
                                  {article.author.username}
                                </Link>
                                <div className="slideCount">{slides.length} slides</div>
                                <div className="createdAndUpdated">
                                  <span>Created {new Date(article.createdAt).toDateString()}</span>
                                  {` - `}
                                  <span>Updated {new Date(article.updatedAt).toDateString()}</span>
                                </div>
                                <div className="classification">
                                  {article.public && 'Public'}
                                  {!article.public && 'Private'}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    <ul className="tag-list">
                      {this.props.article &&
                        this.props.article.tagList.map((tag, i) => {
                          return (
                            <li className="tag-default tag-pill tag-outline" key={i}>
                              {tag}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                {viewMode === 'SLIDE' &&
                  this.props.slideSelectionModeDetail &&
                  this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.Select && (
                    <div className="multiSlideEditToolBarContainer" tabIndex={0}>
                      <div className="multiSlideEditToolBar slideEditor">
                        <SlideEditPanel
                          className="slideFooterActions"
                          editMode={SlideEditMode.MultiEdit}
                          onDelete={this.deleteSlides}
                          onMove={this.moveSlidesWithinGide}
                          onTransfer={this.onTransfer}
                          onCopy={this.onCopySlidesWithinGide}
                          onMoveTo={this.onMoveToAttachments}
                          onUndo={e => {
                            this.props.showNotification({
                              message: `Coming Soon.`,
                              type: NotificationType.INFO,
                            });
                          }}
                        />
                        {this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.Select &&
                          this.props.slideSelectionModeDetail.multiSlideSelectionMethod === MultiSlideSelectionMethod.Range && (
                            <div className="slideRangeSelectorContainer" style={{ marginTop: '7px' }}>
                              <div className="slideRangeSelector">
                                {/* This is duplicated in a few places. Need to make a component and replace */}
                                <div className="messageDialogContainer">
                                  <div className="centerModalPointerTriangle" />
                                  <div className="messageDialog">
                                    <span className="messageDialogTitle">
                                      Enter slide numbers<span className="TEXTSUBTITLEorangecenter">*</span>
                                    </span>
                                    <div className="slidePositionContainer">
                                      <Input
                                        type="text"
                                        value={this.state.multiEditRanges}
                                        placeholder="copy to position"
                                        onChange={(event, data) => {
                                          this.setState({ multiEditRanges: data.value });
                                        }}
                                      />
                                    </div>
                                    <div className="messageDialogActions">
                                      <div
                                        className={classNames('messageDialogAction', 'cancelButton')}
                                        onClick={() =>
                                          this.props.updateSlideSelectionMethod({
                                            multiSlideSelectionMethod: MultiSlideSelectionMethod.Selection,
                                          })
                                        }
                                      >
                                        <span>Cancel</span>
                                      </div>

                                      <div
                                        className={classNames('messageDialogAction', 'doneButton')}
                                        onClick={() => {
                                          if (this.setSelectedSlidesFromRanges(this.state.multiEditRanges)) {
                                            this.props.updateSlideSelectionMethod({
                                              multiSlideSelectionMethod: MultiSlideSelectionMethod.Selection,
                                            });
                                          }
                                        }}
                                      >
                                        <span>Done</span>
                                        <GideImage src="/icons/content-alteration/copy.svg" alt="Done" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                {article && (
                  <div className="row gideSlideContainer" style={{paddingBottom: '50px'}}>
                    <SearchContext.Provider value={{ searchText: this.state.searchText }}>
                      <SlideContainer
                        currentUser={this.props.currentUser}
                        slides={slides}
                        article={article}
                        loading={this.state.loading}
                        view={this.props.view}
                        viewMode={this.props.viewMode}
                        authorViewing={this.props.authorViewing}
                        nextViewMode={this.props.nextViewMode}
                        articleEditMode={this.props.articleEditMode}
                        slideSelectionModeDetail={this.props.slideSelectionModeDetail}
                        collapsedSlides={this.props.collapsedSlides}
                        displayCollapsedHeaders={this.props.displayCollapsedHeaders}
                        slideIdOfSlideOutOfOrder={slideIdOfSlideOutOfOrder}
                        slideIdOfSlideInAdvancedEditMode={
                          !isNullOrUndefined(this.props.individualSlideIdInAdvancedEditMode)
                            ? this.props.individualSlideIdInAdvancedEditMode
                            : undefined
                        }
                        disableInlineTextEditing={this.props.disableInlineTextEditing}
                        expandCollapseSlideAdditionInfo={this.state.expandCollapseSlideAdditionInfo}
                        multiSlideSelectionDetail={multiSlideSelectionDetail}
                        wideScreenEditModeEnabled={this.props.wideScreenEditModeEnabled}
                        inlineSlideTextEditInfo={this.props.inlineSlideTextEditInfo}
                        inlineEditedSlide={this.props.inlineEditedSlide}
                        slideInlineViewSlidesDictionary={this.props.slideInlineViewSlidesDictionary}
                        setNextViewMode={this.props.setNextViewMode}
                        collapseSlides={this.props.collapseSlides}
                        showNotification={this.props.showNotification}
                        openModal={this.props.openModal}
                        onSubmitSlide={this.props.onSubmitSlide}
                        onEditingText={this.props.onEditingText}
                        onEnterAdvancedEditForSlide={this.props.onEnterAdvancedEditForSlide}
                        deleteSlide={this.props.deleteSlide}
                        multiSlideSelectionChanged={this.multiSlideSelectionChanged}
                        updateSlideNumber={this.props.updateSlideNumber}
                        enterSlideSelectionMode={this.props.enterSlideSelectionMode}
                        updateSlideSelectionMethod={this.props.updateSlideSelectionMethod}
                        updateSlideSelectionOperationMode={this.props.updateSlideSelectionOperationMode}
                        copySlides={this.props.copySlides}
                        moveSlides={this.moveCurrentArticleSlide}
                        setInlineSlideTextEditInfo={this.props.setInlineSlideTextEditInfo}
                        updateSlideWithInlineEdits={this.props.updateSlideWithInlineEdits}
                        onSetViewMode={this.props.onSetViewMode}
                        setMultiSelectSlideView={setMultiSelectSlideView}
                        updateChildGidesOnSlideForGideType={this.props.updateChildGidesOnSlideForGideType}
                        openChildArticleModal={this.openChildArticleModal}
                        addSlideAddition={this.addSlideAddition}
                        onAuthorizeSlide={this.props.onAuthorizeSlide}
                        onUnauthenticatedAuthorizeSlide={this.props.onUnauthenticatedAuthorizeSlide}
                        exitSlideSelectionMode={this.props.exitSlideSelectionMode}
                        onSubmitSlideZero={this.props.onSubmitSlideZero}
                        onShowSignup={this.props.onShowSignup}
                        onShowSignin={this.props.onShowSignin}
                        onRenavigateToSameArticle={this.props.onRenavigateToSameArticle}
                      />
                    </SearchContext.Provider>
                  </div>
                )}

                {this.props.article && this.props.article.allowComments && this.props.match && this.props.match.params && (
                  <section>
                    <div style={{ textAlign: 'center', margin: '30px' }}>
                      <div className="showHideComments" onClick={this.toggleComments}>
                        {this.state.showComments ? 'Hide' : 'Show'} Comments
                      </div>
                    </div>
                    <div className="row">
                      <CommentContainer
                        show={this.state.showComments}
                        comments={this.props.comments || []}
                        errors={this.props.commentErrors}
                        slug={this.props.match.params.id}
                        currentUser={this.props.currentUser}
                      />
                    </div>
                  </section>
                )}
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }} ref={this.viewbarRef}>
              {(!hasValue(this.props.slideSelectionModeDetail) ||
                this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideAbove ||
                this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideBelow) && !this.state.loading && (
                <ViewbarPopper
                  style={{
                    pointerEvents: 'none',
                  }}
                  anchorEl={this.viewbarRef.current}
                  resizeEl={this.articleDiv}
                  authorViewing={this.props.authorViewing}
                  viewbarBottom={this.props.currentUser && article.author.username === this.props.currentUser.username ? 140 : 72}
                  className={this.props.currentUser && article.author.username === this.props.currentUser.username ? 'inputBar' : undefined}
                  enterSlideSelectionMode={this.props.enterSlideSelectionMode}
                  createGide={this.onCreateGide}
                  numberOfSlides={this.props.slides ? this.props.slides.length : 0}
                  allowSlideComments={article.allowSlideComments && !article.pauseSlideComments}
                  allowSlideQuestions={article.allowSlideQuestions && !article.pauseSlideQuestions}
                  currentSlidePosition={this.state.scrollToSlidePosition ? this.state.scrollToSlidePosition : 1}
                  headerSlides={
                    this.props.slides ? this.props.slides.filter(s => s.slideType === 'HEADER' && s.data.type !== 'END') : undefined
                  }
                  slideRangeList={this.props.slides ? getSlideRangeList(this.props.slides) : undefined}
                  articleLayoutView={articleLayoutView}
                  articleViewMode={articleViewMode}
                  onSwitchArticleViewMode={this.onSwitchArticleViewMode}
                  onSwitchArticleLayoutView={this.onSwitchArticleLayoutView}
                  scrollToSlidePosition={this.scrollToSlidePosition}
                  onSearchTextChange={this.onSearchArticleForText}
                  numberOfSearchElements={this.state.numberOfSearchElements}
                  onMoveToNextSearchText={this.onMoveToNextSearchText}
                  onMoveToPrevSearchText={this.onMoveToPrevSearchText}
                  onCollapseAllSlides={this.onCollapseAllSlides}
                  onExpandAllSlides={this.onExpandAllSlides}
                  showNotification={this.props.showNotification}
                  onToggleNavigide={this.props.onToggleNavigide}
                  openModal={this.props.openModal}
                  closeModal={this.props.closeModal}
                  isLoggedIn={this.props.currentUser && this.props.currentUser.username}
                  signUp={this.props.onShowSignup}
                  signIn={this.props.onShowSignin}
                />
              )}
            </div>
            {this.props.distributingGide && (
              <div className="distributionSettingsEditorModal">
                <DistributionSettingsEditorModal
                  gide={article}
                  closeModal={() => {
                    this.props.exitGideDistribution();
                  }}
                  openModal={this.props.openModal}
                  onSubmitSlideZero={this.props.onSubmitSlideZero}
                  articleSubmitted={this.props.onSubmitArticle}
                  updateArticle={this.props.updateArticle}
                  deleteArticle={this.props.deleteArticle}
                  showNotification={this.props.showNotification}
                  channel={gideChannel}
                  channels={this.props.channels}
                  draftChannelId={draftChannelId}
                  currentUser={this.props.currentUser}
                  agent={agent}
                  slides={slides}
                  shareGide={this.onShare}
                  distributionSettings={{
                    channelId: article.channel ? article.channel.toString() : draftChannelId,
                    descriptionSlideId: article.descriptionSlide
                      ? article.descriptionSlide._id
                        ? article.descriptionSlide._id.toString()
                        : article.descriptionSlide
                      : undefined,
                    description: article.description,
                  }}
                  allowCopy={!this.props.preventCopy}
                />
              </div>
            )}
          </>
        )}
        {(!article || this.state.loading) && (
          <div style={{ display: 'flex', flex: 1, marginTop: '150px' }}>
            <Loader active inline="centered" />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
