import React from 'react';
import { CarouselLabeledButtonComponent } from './component';
import icons from '../../../../assets/icons';
import styles from './_styles.module.scss';
import { DivMultiImageInfo } from '../../../Shared/Image/MultiImage/MultiImage';


export const homeCarouselImageSet = (
    signup: () => void,
    viewAbout: () => void,
    viewInstructions: () => void
  ): DivMultiImageInfo[] =>  [
  // Carousel 1
  {
    imageSet: [
      // Top Left Corner Image
      {
        position: 'left top',
        repeat: 'no-repeat',
        desktopUrl: '/images/home-carousel/carousel.1/layer.2/desktop.png',
        tabletUrl: '/images/home-carousel/carousel.1/layer.2/tablet.png',
        mobileUrl: '/images/home-carousel/carousel.1/layer.2/mobile.png',
        desktopSize: '458px',
        tabletSize: '248px 96px',
        mobileSize: '375px 225px',
        visibilityMode: 'all'
      },
      // Bottom Right Corner Image
      {
        position: 'right bottom',
        repeat: 'no-repeat',
        desktopUrl: '/images/home-carousel/carousel.1/layer.1/desktop.png',
        tabletUrl: '/images/home-carousel/carousel.1/layer.1/tablet.png',
        visibilityMode: 'desktop and tablet',
        desktopSize: 'contain',
        tabletSize: '169px 115px',
        mobileSize: 'contain',
      },
      // Full Background Image
      {
        position: 'center',
        desktopVerticalOffset: 48,
        repeat: 'repeat',
        desktopUrl: '/images/home-carousel/carousel.1/layer.3.jpg',
        desktopSize: 'cover',
        tabletSize: 'cover',
        mobileSize: 'cover',
        visibilityMode: 'all'
      }
    ],
    positionedComponent: {
      component: <CarouselLabeledButtonComponent
        onClick={viewAbout}
        title="Learn about "
        buttonLabel="View About Us Gide"
        icon={<icons.Nav_Logo_LogoColorEdit className={styles.desktopIcon} color='white' width={220} height={91} style={{marginLeft: '6px'}} />}
        mobileIcon={<icons.Nav_Logo_LogoColorEdit className={styles.mobileIcon} color='white' width={123} height={53} style={{marginLeft: '6px'}} />}
      />,
      bottom: 60,
      left: 54
    }
  },
  // Carousel 2
  // {
  //   imageSet: [
  //     // Top Left Corner Image
  //     {
  //       position: 'left top',
  //       repeat: 'no-repeat',
  //       desktopUrl: '/images/home-carousel/carousel.2/layer.2/desktop&tablet.png',
  //       mobileUrl: '/images/home-carousel/carousel.2/layer.2/mobile.png',
  //       desktopSize: '294px',
  //       tabletSize: '294px',
  //       mobileSize: '165px 104px',
  //       visibilityMode: 'all'
  //     },
  //     // Bottom Right Corner Image
  //     {
  //       position: 'right bottom',
  //       repeat: 'no-repeat',
  //       desktopUrl: '/images/home-carousel/carousel.2/layer.1/desktop.png',
  //       tabletUrl: '/images/home-carousel/carousel.2/layer.1/tablet.png',
  //       mobileUrl: '/images/home-carousel/carousel.2/layer.1/mobile.png',
  //       visibilityMode: 'all',
  //       desktopSize: 'contain',
  //       tabletSize: '217px 121px',
  //       mobileSize: '165px 93px',
  //     },
  //     // Background Full Image
  //     {
  //       position: 'center',
  //       desktopVerticalOffset: 58.4,
  //       repeat: 'repeat',
  //       desktopUrl: '/images/home-carousel/carousel.2/layer.3.jpg',
  //       desktopSize: 'cover',
  //       tabletSize: 'cover',
  //       mobileSize: 'cover',
  //       visibilityMode: 'all'
  //     }
  //   ],
  //   positionedComponent: {component:
  //     <CarouselLabeledButtonComponent
  //       title="How to use "
  //       buttonLabel="View Instructions Gide"
  //       class={styles.viewInstructionsButton}
  //       onClick={viewInstructions}
  //       icon={<icons.Nav_Logo_LogoColorEdit className={styles.desktopIcon} color='white' width={220} height={91} style={{marginLeft: '6px'}} />}
  //       mobileIcon={<icons.Nav_Logo_LogoColorEdit className={styles.mobileIcon} color='white' width={123} height={53} style={{marginLeft: '6px'}} />}
  //     />,
  //     bottom: 60,
  //     left: 54
  //   }
  // },
  // Carousel 3
  {
    imageSet: [
      // Top Left Corner Image
      {
        position: 'left top',
        repeat: 'no-repeat',
        desktopUrl: '/images/home-carousel/carousel.3/layer.2/desktop.png',
        mobileUrl: '/images/home-carousel/carousel.3/layer.2/tablet.png',
        tabletUrl: '/images/home-carousel/carousel.3/layer.2/tablet.png',
        desktopSize: '478px',
        tabletSize: '294px 94px',
        mobileSize: '294px 94px',
        visibilityMode: 'all'
      },
      // Bottom Right Corner Image
      {
        position: 'right bottom',
        repeat: 'no-repeat',
        desktopUrl: '/images/home-carousel/carousel.3/layer.1/desktop.png',
        tabletUrl: '/images/home-carousel/carousel.3/layer.1/mobile.png',
        mobileUrl: '/images/home-carousel/carousel.3/layer.1/mobile.png',
        visibilityMode: 'all',
        desktopSize: '165px 264px',
        tabletSize: '244px 121px',
        mobileSize: '244px 115px',
      },
      // Full Background Image
      {
        position: 'center',
        desktopVerticalOffset: 48,
        repeat: 'repeat',
        desktopUrl: '/images/home-carousel/carousel.3/layer.3.jpg',
        desktopSize: 'cover',
        tabletSize: 'cover',
        mobileSize: 'cover',
        visibilityMode: 'all'
      }
    ],
    positionedComponent: {component:
      <CarouselLabeledButtonComponent
        title="Get your username "
        buttonLabel="Sign up now!"
        onClick={signup}
      />,
      bottom: 60,
      left: 54
    }
  },
];
