import React, { Component } from 'react';
import { connect } from 'react-redux';

import Banner from './Banner';
import MainView from './MainView';
import Tags from './Tags';
import Website from '../Channel/Website';
import agent from '../../agent';
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  SET_VIEW_MODE,
} from '../../constants/actionTypes';
import { getCustomDomain } from '../../utils/helperFunctions';
import { Loader } from 'semantic-ui-react';

const Promise = global.Promise;

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.home,
    currentUser: state.common.currentUser,
    appName: state.common.appName,
    token: state.common.token,
    viewMode: state.common.viewMode,
    path: ownProps.match ? ownProps.match.params.path : null,
    loading: state.common.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
});

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SHOW_CATEGORIES: false,
    };
  }
  componentWillMount() {
    this.props.onSetViewMode('SLIDE');
    // const tab = this.props.token ? 'feed' : 'all';
    const tab = 'all';
    const articlesPromise = this.props.token
      ? agent.Articles.feed
      : agent.Articles.all;

    this.props.onLoad(
      tab,
      articlesPromise,
      Promise.all([
        agent.Tags.getAll(),
        agent.Articles.all(),
        // This seems wrong because if you are logged in you will not be able to go to another users's website.
        // Need to verify.
        this.props.currentUser ? agent.Channels.byAuthor(this.props.currentUser.username, true) : agent.Channels.all(),
        agent.Collections.landing(),
        agent.Users.getAll(),
      ]),
    );
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { SHOW_CATEGORIES } = this.state;
    const { channels, path } = this.props;
    const customDomain = getCustomDomain();
    let websiteSlug;
    if (customDomain && channels) {
      const channel = channels.find(c => {
        return c.domain === customDomain;
      });
      if (channel) {
        websiteSlug = channel.slug;
      }
    }    
    return (
      <>
   
        <div
          className="home-page flexColumnFull"
        >
          {this.props.loading && (
            <Loader active inline="centered" style={{marginTop: '150px'}} />
          )}
          {!customDomain && !this.props.loading && (
            <Banner token={this.props.token} appName={this.props.appName} />
          )}
          {!this.props.loading && (
            <div
              className="container page flexColumnFull"
            >
              <div className="row">
                {customDomain && websiteSlug && <Website slug={websiteSlug} path={path} /> }
                {!customDomain && <MainView />}

                {SHOW_CATEGORIES && (
                  <div className="col-md-1">
                    <div className="sidebar">
                      <p>Categories</p>
                      <Tags
                        tags={this.props.tags}
                        onClickTag={this.props.onClickTag}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
            {/* {!customDomain && <Footer />} */}
          </div>
        

    </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
