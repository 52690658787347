import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GideImage from './Shared/Image/GideImage';

export default class ImageMapper extends Component {
  constructor(props) {
    super(props);
    ['drawrect', 'drawcircle', 'drawpoly', 'initCanvas'].forEach(
      f => (this[f] = this[f].bind(this)),
    );
    this.styles = this.stylesForProps(props);
  }

  componentWillUpdate(nextProps) {
    this.styles = this.stylesForProps(nextProps);
  }

  componentDidUpdate() {
    if (this.props.alwaysShow && this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawAreas();
    }
  }

  stylesForProps(props) {
    let absPos = { position: 'absolute', top: 0, left: 0 };
    return {
      container: {
        position: props.stacked ? 'absolute' : 'relative',
        zIndex: props.onTop ? 2 : 0,
      },
      canvas: { ...absPos, pointerEvents: 'none', zIndex: 2 },
      img: { ...absPos, zIndex: 1, userSelect: 'none' },
    };
  }

  drawAreas() {
    this.props.map.areas.forEach(({ shape, coords }, i) => {
      const selected = this.props.selectedPoint === i;
      this['draw' + shape](coords, selected);
    });
  }

  drawrect(coord) {
    if (!Array.isArray(coord)) coord = coord.split(',');
    let [left, top, right, bot] = coord;
    this.ctx.strokeRect(left, top, right - left, bot - top);
    this.ctx.fillRect(left, top, right - left, bot - top);
  }

  drawcircle(coords, selected) {
    if (!Array.isArray(coords)) coords = coords.split(',');
    const rad = selected ? coords[2] * 2 : coords[2];
    this.ctx.beginPath();
    this.ctx.arc(coords[0], coords[1], rad, 0, 2 * Math.PI);
    this.ctx.closePath();
    this.ctx.stroke();
    this.ctx.fill();
  }

  drawpoly(coords) {
    if (!Array.isArray(coords)) coords = coords.split(',');
    coords = coords.reduce(
      (a, v, i, s) => (i % 2 ? a : [...a, s.slice(i, i + 2)]),
      [],
    );
    this.ctx.beginPath();
    let first = coords.unshift();
    this.ctx.moveTo(first[0], first[1]);
    coords.forEach(c => this.ctx.lineTo(c[0], c[1]));
    this.ctx.closePath();
    this.ctx.stroke();
    this.ctx.fill();
  }

  initCanvas() {
    if (this.props.width) this.img.width = this.props.width;
    this.canvas.width = this.img.clientWidth;
    this.canvas.height = this.img.clientHeight;
    this.container.style.width = this.img.clientWidth + 'px';
    this.container.style.height = this.img.clientHeight + 'px';
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = this.props.fillColor;
    this.ctx.strokeStyle = this.props.strokeColor;
    this.ctx.lineWidth = this.props.lineWidth;
    if (this.props.onLoad) this.props.onLoad();

    if (this.props.alwaysShow) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawAreas();
    }
  }

  hoverOn(area, index, event) {
    const shape = event.target.getAttribute('shape');
    if (this.props.active && this['draw' + shape] && !this.props.alwaysShow)
      this['draw' + shape](event.target.getAttribute('coords'));
    if (this.props.onMouseEnter) this.props.onMouseEnter(area, index, event);
  }

  hoverOff(area, index, event) {
    if (this.props.active && !this.props.alwaysShow)
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    if (this.props.onMouseLeave) this.props.onMouseLeave(area, index, event);
  }

  click(area, index, event) {
    if (this.props.onClick) {
      event.preventDefault();
      this.props.onClick(area, index, event);
    }
  }

  renderAreas() {
    return this.props.map.areas.map((area, index) => (
      <area
				alt={index}
        key={area._id || index}
        shape={area.shape}
        coords={area.coords.join(',')}
        onMouseEnter={this.hoverOn.bind(this, area, index)}
        onMouseLeave={this.hoverOff.bind(this, area, index)}
        onClick={this.click.bind(this, area, index)}
        href={area.href}
        title={area.label}
      />
    ));
  }

  render() {
    // const { onTop } = this.props;
    // let styles = this.styles;
    // if (stacked) {
    // 	styles.container.position = 'absolute';
    // }
    // if (!onTop) {
    // 	styles.container.zIndex = 0;
    // }
    return (
      <div style={this.styles.container} ref={node => (this.container = node)} className="imageMapper">
        <GideImage 
          style={this.styles.img}
          src={this.props.src}
          useMap={`#${this.props.map.name}`}
          alt={`□`}
          ref={node => (this.img = node)}
          onLoad={this.initCanvas}
          onClick={this.props.onImageClick}
        />
        <canvas ref={node => (this.canvas = node)} style={this.styles.canvas} />
        <map name={this.props.map.name}>{this.renderAreas()}</map>
      </div>
    );
  }
}

ImageMapper.defaultProps = {
  active: true,
  fillColor: 'rgba(255, 255, 255, 0.5)',
  lineWidth: 1,
  map: {
    areas: [],
    name: 'image-map-' + Math.random(),
  },
  strokeColor: 'rgba(0, 0, 0, 0.5)',
};

ImageMapper.propTypes = {
  active: PropTypes.bool,
  fillColor: PropTypes.string,
  height: PropTypes.number,
  lineWidth: PropTypes.number,
  map: PropTypes.shape({
    areas: PropTypes.arrayOf(
      PropTypes.shape({
        area: PropTypes.shape({
          coords: PropTypes.arrayOf(PropTypes.number),
          href: PropTypes.string,
          shape: PropTypes.string,
        }),
      }),
    ),
    name: PropTypes.string,
  }),
  onClick: PropTypes.func,
  onImageClick: PropTypes.func,
  onLoad: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  src: PropTypes.string.isRequired,
  strokeColor: PropTypes.string,
  width: PropTypes.number,
  alwaysShow: PropTypes.bool,
  selectedPoint: PropTypes.number,
};
