import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import agent from '../../../../../agent';
import { history } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { MODAL_OPEN, DELETE_ARTICLE, RECOVER_ARTICLE } from '../../../../../constants/actionTypes';
import GideImage from '../../../Image/GideImage';

export interface GidePreviewFooterMenuProps {
  gideId: string;
  isOwner: boolean;
}
interface GidePreviewFooterMenuActions {
  openModal: (payload: any) => void;
  onDeleteArticle: (payload: any) => void;
  onRecoverArticle: (payload: any) => void;
}
export default function GidePreviewFooterMenu(props: GidePreviewFooterMenuProps) {
  const dispatch = useDispatch();
  const actions: GidePreviewFooterMenuActions = {
    openModal: (payload: any) => dispatch({ type: MODAL_OPEN, payload }),
    onDeleteArticle: (payload: any) => dispatch({ type: DELETE_ARTICLE, payload }),
    onRecoverArticle: (payload: any) => dispatch({ type: RECOVER_ARTICLE, payload }),
  };
  const articleMenuTrigger = <GideImage src="/icons/nav/threedots/horizontal.svg" alt={`□`} />;
  const onAddToCollectionClick = (gideId: string) => {
    actions.openModal({
      modalType: 'SelectCollectionModal',
      modalProps: {
        saveCallback: (collectionInfo: { collection: any; collectionId: any }) => {
          agent.Collections.addItem(collectionInfo.collection, { id: gideId });
        },
        saveButtonTitle: 'OK',
      },
    });
  };
  const onDeleteGideClick = async (gideId: string) => {
    if (!window.confirm('Are you sure?')) return;
    await agent.Articles.del({ id: gideId }, { full: false });
    actions.onDeleteArticle({ id: gideId });
  };
  return (
    <Dropdown trigger={articleMenuTrigger} icon={null} floating>
      <Dropdown.Menu style={{ left: 'auto', right: '0' }}>
        {props.isOwner && (
          <Dropdown.Item
            text={'Settings'}
            icon="setting"
            onClick={() => {
              history.push(`/editor/${props.gideId}`);
            }}
          />
        )}
        {props.isOwner && actions.onDeleteArticle && (
          <Dropdown.Item
            text={'Trash'}
            icon="trash"
            onClick={() => {
              onDeleteGideClick(props.gideId);
            }}
          />
        )}
        <Dropdown.Item
          text={'Add to Collection'}
          icon="add"
          onClick={() => {
            onAddToCollectionClick(props.gideId);
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
