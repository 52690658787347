import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { lightTheme } from '../../../themes/custom-theme';
import KeywordSelector from '../../DataEntry/Pickers/Modals/KeywordSelector/KeywordSelector';
import { uniq, reject } from 'ramda';
import { 
  CommonContext, 
  CommonDerivedState, 
  getSlideView,
  CommonModes,
} from './Common';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;

  const navigateBack = (tagList?: string[]) => {
    tagList = tagList || state.tagList;
    const mode = state.selectedItemIndex > -1 ? CommonModes.Edit : CommonModes.Preview;
    setState({
      ...state,
      tagList: [...tagList],
      mode,
    });
  };

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: false,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.All,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: false,
    showDefaultActions: false,
    createActions: [] as any,
    onHashtagClicked: () => {
      setState({ ...state, mode: CommonModes.Tag });
    },
    headerActions: (<></>),
    onNavigateBack: navigateBack,
  };
}

const getContent = (context: CommonContext): React.ReactNode => {
  const { state, setState } = context;
  const slideView = getSlideView(state.slide);

  const navigateBack = (tagList?: string[]) => {
    tagList = tagList || state.tagList;
    const mode = state.selectedItemIndex > -1 ? CommonModes.Edit : CommonModes.Preview;
    setState({
      ...state,
      tagList: [...tagList],
      mode,
    });
  };

  return (<>
    <div style={{ position: 'relative', justifyContent: 'center',  display: 'flex' }}>
      <KeywordSelector
        style={{position: 'absolute', top: '20px', maxWidth: '100vw', width: '360px', backgroundColor: 'white', zIndex: 2001}}
        onCancel={navigateBack}
        onAddKeywords={(keywords: string[]) => {
          const updatedKeywords = [...state.tagList];
          keywords.forEach(kw => {
            updatedKeywords.push(kw);
          })
          setState({
            ...state,
            tagList: uniq(updatedKeywords)
          });
        }}
        onRemoveKeyword={(keyword: string) => {
          setState({
            ...state,
            tagList: reject(kw => kw === keyword, state.tagList)
          });
        }}
        onSave={navigateBack}
        keywords={state.tagList}
      />
    </div>
    {slideView}
  </>);
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const Tag = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
