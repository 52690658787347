import React, { ReactNode, CSSProperties } from 'react';
import styles from './_tag-button.module.scss';
export interface  TagButtonProps {
  labelCssClass: string;
  label: string;
  icon: ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
}

export default function TagButton (props:  TagButtonProps) {
  return (
    <div className={styles.tagButtonContainer} 
      style={props.style}>
      <span className={props.labelCssClass} style={{ marginRight: '10px', wordBreak: 'break-all' }}>
        {props.label}
      </span>
      {props.icon}
    </div>
  );
}
