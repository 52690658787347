import jwtDecode from 'jwt-decode';

export interface JwtToken {
  id: string;
  username: string;
  exp: number;
  iat: number;
};

export interface AuthToken {
  id: string;
  username: string;
  expirationTime: Date;
  issuedAt: Date;
};

export const decodeAuthToken = (token: string): AuthToken => {
  const jwtToken = jwtDecode(token) as JwtToken;
  const expirationTime = new Date();
  const issuedAt = new Date();
  expirationTime.setTime(jwtToken.exp);
  issuedAt.setTime(jwtToken.iat);
  const authToken: AuthToken = {
    id: jwtToken.id,
    username: jwtToken.username,
    expirationTime,
    issuedAt,
  };
  return authToken;
}

