import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import { MODAL_CLOSE } from '../../constants/actionTypes';
import icons from '../../assets/icons';
import { SlideView } from '../Gide/SlideView/SlideView';

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
});

export class ImageViewModal extends Component {
  render() {
    const { slide, currentUser,  closeModal } = this.props;
    return (
      <Modal
        closeOnEscape={true}
        onClose={closeModal}
        size="fullscreen"
        closeOnDocumentClick={true}
        open={true}
        className="imageViewModal"
        closeOnDimmerClick={true}        
      >
        <Modal.Header style={{padding: '10px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <icons.Nav_Exit_Main color='var(--COLOR-SECONDARY-600)'
              style={{cursor: 'pointer'}}
              onClick={() => closeModal()}
             />
          </div>
        </Modal.Header>
        <Modal.Content className="inner" style={{paddingTop: '15px'}}>
          <Modal.Description>
            <SlideView
              slide={slide}
              currentUser={currentUser}
              disableInlineTextEditing={true}
              slidePosition={slide.position}
              showAuthorHeader={false}
              view={'SCROLL'}
              viewMode='SCROLL'
              collapsed={false}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(ImageViewModal);
