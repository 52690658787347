import React, { MouseEventHandler } from 'react';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';

export interface SlideFileTypeSelectorProps {
  slideFileTypes: SlideFileType[];
  theme?: "light" | "dark";
}

export interface SlideFileType {
  image: string,
  alt: string,
  onClick: MouseEventHandler,
  title: string,
  message: string,
}

export default function SlideFileTypeSelector (props:  SlideFileTypeSelectorProps) {
  let cards = props.slideFileTypes.map((t, i) => (
    <div className={`slideFileTypeSelectorCard ${props.theme}`}
      key={`slideFileTypeSelectorCard${i}`}
      onClick={t.onClick}>
      <div className="slideFileTypeSelectorCardIcon">
        <CircleIconButton
          style={{ margin: '17px' }}
          alt={t.alt}
          backgroundColor="var(--COLOR-BLUE-100)"
          iconCssClass="whites-normal-1000-svg"
          image={t.image}
        />
      </div>
      <div className="slideFileTypeSelectorCardContent">
        <div className={`slideFileTypeSelectorCardContentTitle ${props.theme}`}>{t.title}</div>
        <div className={`slideFileTypeSelectorCardContentMessage ${props.theme}`}>{t.message}</div>
      </div>
    </div>
  ));
  return (
    <div className="slideFileTypeSelectorContainer">
      {cards}
    </div>
  );
}
