import React from 'react';
import styles from './_tabMenu.module.scss';
import classNames from 'classnames';

export interface TabMenuProps {
    tabs: {
        label?: string;
        icon?: React.ReactNode;
    }[];
    selectedTab: number;
    onTabClicked: (i: number) => void;
    style?: React.CSSProperties;
    tabStyle?: 'default';
    className?: string;
}

export const TabMenu: React.FC<TabMenuProps> = (props) => {
    return (
        <div className={classNames(styles.tabMenu, props.className)} style={props.style}>
            { props.tabs && props.tabs.map((t, i) => (
                <div key={i} className={classNames(styles.tab, i === props.selectedTab ? styles.active : '', props.tabStyle === 'default' ? styles.default : undefined)}                  
                  onClick={() => props.onTabClicked(i)}>
                  {t.icon}
                  {t.label}
                </div>
            ))}
        </div>
    );
}

export default TabMenu;
