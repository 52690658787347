import React, { Component } from 'react';
import ReactPlayer from 'react-player';

export interface MediaPlayerProps {
  mediaUrl: string;
  rangeMin: number;
  rangeMax?: number;
  sliderValue: number;
  showControls?: boolean;
  onUpdateSeekTime: (seekTimeInSeconds: number) => void;
  onUpdateRange: (rangeMin: number, rangeMax: number) => void;
  onUpdateDuration: (duration: number) => void;
}

interface MediaPlayerState {
  playing: boolean;
  played: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  seeking: boolean;
}

export class MediaPlayer extends Component<MediaPlayerProps, MediaPlayerState> {
  player: ReactPlayer | null | undefined;
  constructor(props: MediaPlayerProps) {
    super(props);

    this.state = {
      playing: false,
      played: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      seeking: false,
    };
  }

  onPlay = () => {
    this.setState({ playing: true });
  };

  onPause = () => {
    this.setState({ playing: false });
  };

  onSeekMouseDown = (e: any) => {
    this.setState({ seeking: true });
  };

  onSeekChange = (e: any) => {
    const durationCal = this.state.duration - 0.01;
    const seekValue =
      parseFloat(e.target.value) >= durationCal
        ? durationCal
        : parseFloat(e.target.value);
    this.setState({ played: seekValue });
    this.props.onUpdateSeekTime(this.state.duration * this.state.played);
  };

  onSeekMouseUp = (e: any) => {
    if (this.player) {
      this.setState({ seeking: false });
      const durationCal = this.state.duration - 0.01;
      const seekValue =
        parseFloat(e.target.value) >= durationCal
          ? durationCal
          : parseFloat(e.target.value);
      this.player.seekTo(seekValue);
      this.props.onUpdateSeekTime(this.state.duration * this.state.played);
    }
  };

  onEnded = () => {
    this.setState({ playing: this.state.loop });
  };

  ref = (player: ReactPlayer) => {
    this.player = player;
  };

  render() {
    const { mediaUrl, showControls } = this.props;
    const { playing, playbackRate, loop } = this.state;

    return (
      <div>
        {mediaUrl && (
          <div className="player-wrapper">
            <ReactPlayer
              ref={this.ref}
              className="react-player"
              height="35px"
              width="100%"
              url={mediaUrl}
              playing={playing}
              controls={showControls}
              loop={loop}
              playbackRate={playbackRate}
              // onReady={() => console.log('onReady')}
              // onStart={() => console.log('onStart')}
              onPlay={this.onPlay}
              onPause={this.onPause}
              // onBuffer={() => console.log('onBuffer')}
              // onSeek={e => console.log('onSeek', e)}
              onEnded={this.onEnded}
              // onError={e => console.log('onError', e)}
              onDuration={this.props.onUpdateDuration}
            />
          </div>
        )}
      </div>
    );
  }
}
