import React from 'react';

import Comment from './Comment';

const CommentList = props => {
  return (
    <div>
      {props.comments.map((comment, i) => {
        return (
          <Comment
            comment={comment}
            currentUser={props.currentUser}
            slug={props.slug}
            key={i}
          />
        );
      })}
    </div>
  );
};

export default CommentList;
