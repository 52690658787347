import React, {useEffect, useState, useRef, MutableRefObject} from 'react';
import { ImageGalleryFile, ImageGalleryViewerMode } from '../../../SlideEditors/ImageGallerySlideEditor/ImageGallerySlideEditor';
import { ImageFile } from '../../../../models/SlideFile';
import GideImage from '../../../Shared/Image/GideImage';

export interface ImageGalleryImageSelectEvent {
  imageGalleryFileId: string;
  imageFile: ImageFile;
  imageIndex: number;
}
export interface  ImageGalleryProps {
  imageGallery: ImageGalleryFile;
  imageGalleryViewerMode: ImageGalleryViewerMode;
  marginBottom: string;
  onClick?: (event: ImageGalleryImageSelectEvent) => void;
}

export default function ImageGallery (props:  ImageGalleryProps) {
  const columnWidth = props.imageGalleryViewerMode === ImageGalleryViewerMode.LargeThumbnails
  ? 200
  : props.imageGalleryViewerMode === ImageGalleryViewerMode.MediumThumbnails
    ? 150
    : 100;

  const [imageWidthAndHeight, setImageWidthAndHeight] =
    useState({width: `${columnWidth}px`, height: `${columnWidth}px`});

  const imageGalleryContainerRef: any = useRef(undefined);
  const updateImageWidthAndHeight = () => {
    if(imageGalleryContainerRef.current && imageGalleryContainerRef.current !== null) {
      const width = Math.floor((imageGalleryContainerRef as MutableRefObject<HTMLDivElement>).current.clientWidth) - 1;
      const maxNumberOfColumns = Math.floor(width / columnWidth);
      const widthAndHeight = Math.floor(width / maxNumberOfColumns);
      setImageWidthAndHeight({
        width: `${widthAndHeight}px`,
        height: `${widthAndHeight}px`,
      });
    }
  }
  useEffect(() => {
    const handleResize = () => {
      updateImageWidthAndHeight();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  useEffect(() => {
    updateImageWidthAndHeight();
  }, [props.imageGalleryViewerMode, props.imageGallery, props.marginBottom]);
  return (
    <div className="imageGallery"
      ref={imageGalleryContainerRef}
      style={{marginBottom: props.marginBottom}}
    >
      {props.imageGallery && props.imageGallery.imageFiles.map((file: ImageFile, i: number) => (
        <div
          style={{
            minWidth: `${imageWidthAndHeight.width}`,
            minHeight: `${imageWidthAndHeight.height}`,
            maxWidth: `${imageWidthAndHeight.width}`,
            maxHeight: `${imageWidthAndHeight.height}`,
            position: 'relative',
          }}
          key={i}
        >
          <GideImage src={file.url ? file.url : ''}
            className={`imageGalleryImage${props.onClick ? ' clickable' : ''}`}
            alt="gallery item"
            style={{
              minWidth: '100%',
              minHeight: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              height: '100%',
            }}
            onClick={() => {
              if(props.onClick) {
                props.onClick({imageGalleryFileId: props.imageGallery.id as string,  imageFile: file, imageIndex: i});
              }
            }}
          />
          <GideImage 
            className="imageGalleryImageOverlay whites-normal-800-svg"
            src={file.type === "PHOTO"
              ? '/icons/slidetype/image/main.svg'
              : '/icons/content-alteration/upload.svg'} // Current only other type is UPLOAD
            alt={file.type === "PHOTO" ? 'by photo' : 'by upload'}
          />
        </div>
      ))}
    </div>
  );
}
