import React, { useState } from 'react';
import SlideEditorCommandBar from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import icons from '../../../assets/icons';
import { darkTheme } from '../../../themes/custom-theme';
import { MediaChooser, MultiMediaType, MediaChooserAction } from '../../DataEntry/CreationProcess/MediaChooser/MediaChooser';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { ChannelEditorModeProps } from './ChannelEditorModesShared';
import { MediaFile } from '../../../models/SlideFile';
import { v4 } from 'uuid';
import { NotificationType } from '../../../constants/strings';
import ProgressBar from '../../Shared/ProgressBar/ProgressBar';

export const ChannelEditorModeCamera: React.FC<ChannelEditorModeProps> = (props) => {
  const [mediaChooserAction, setMediaChooserAction] = useState<MediaChooserAction | undefined>(undefined);

  const headerTitle =
    props.mode === 'New' ? "Create your cover image for a channel" :
    props.mode === 'Camera' ? 'Open camera' :
    props.mode === 'Uploading' ? 'Uploading your image' :
      'ERROR';

  const headerIcon =
    props.mode === 'New' ? <icons.Nav_Channels color="var(--WHITES-NORMAL-1000)" /> :
    props.mode === 'Camera' ? <icons.ContentAlteration_Camera_Default color="var(--WHITES-NORMAL-1000)" /> :
    props.mode === 'Uploading' ? <icons.SlideType_Image_Main color="var(--WHITES-NORMAL-1000)" /> :
      <>ERROR</>;

  const navigateBack = () => {
    setMediaChooserAction({ type: 'StopCamera' as 'StopCamera' });

    if (props.mode === 'New') {
      props.onCancel();
    } else if (props.mode === 'Camera') {
      props.setMode('New');
    } else if (props.mode === 'Uploading') {
      props.setMode('New');
    } else {
      throw new Error(`Unknown mode: ${props.mode}`);
    }
  };

  return (
    <div style={{
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <SlideEditorCommandBar
        backIconMode={BackIconMode.Light}
        showBackNavigationButton={true}
        showDefaultActions={false}
        onExit={navigateBack}
        onNavigateBack={navigateBack}
        theme={darkTheme}
      >
        <EditorState title={headerTitle}
          labelColor="var(--WHITES-NORMAL-1000)"
          backgroundColor="var(--COLOR-BLUE-100)"
          icon={headerIcon}
        />
      </SlideEditorCommandBar>
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: props.mode === 'Uploading' ? 'center' : 'initial',
        overflow: 'auto',
      }}>
        {props.mode !== 'Uploading' &&
          <MediaChooser
            style={{
              maxWidth: '768px',
            }}
            mediaType={MultiMediaType.Image}
            maxUploadSize={10}
            onCameraStarted={(cameraType) => props.setMode(cameraType === 'InApp' ? 'Camera' : 'Uploading')}
            onMediaCreated={(mediaUrls, fileType) => {
              const newFiles: MediaFile[] = [];
              mediaUrls.forEach((mediaUrl: any) => {
                newFiles.push({
                  id: v4(),
                  name: 'name',
                  url: URL.createObjectURL(mediaUrl),
                  dataUrl: mediaUrl,
                  processingId: v4(),
                  type: fileType,
                  caption: '',
                });
              });
              const firstFile = newFiles[0]; // Only take the first file. (Maybe show an error if you selected multiple?)
              props.setImage(firstFile);
              props.setMode('Edit');
            }}
            onSavingMedia={() => props.setMode('Uploading')}
            onError={(error) => {
              props.showNotification({ message: error, type: NotificationType.ERROR });
            }}
            action={mediaChooserAction}
          />
        }
        {props.mode === 'Uploading' &&
          <div className="loadingOverlay">
            <div className="progressIndicator">
              <ProgressBar indicatorWidth={30} />
            </div>
            <span className="loadingMessage">
              Processing image
            </span>
          </div>
        }
      </div>
    </div>
  );
}
