import * as React from 'react';
import TextArea from 'react-textarea-autosize';

import { HorizontalAlignment } from '../ExpandingIconButton/ExpandingIconButton';
import { hasValue } from '../../../utils/helperFunctions';
import { withTheme } from 'styled-components';
import GideImage from '../Image/GideImage';

export interface LinkInputProps {
  value: string;
  placeholder?: string;
  icon?: string;
  iconCssClass?: string;
  iconLocation?: HorizontalAlignment;
  theme?: any;
  autoFocus?: boolean;
  dataTestId?: string;
  onChange?: (linkValue: string | number | undefined) => void;
  onFocus?: () => void;
  onEnter?: () => void;
}


function LinkInput(props: LinkInputProps) {
  const iconLocation = props.iconLocation
    ? props.iconLocation === HorizontalAlignment.Left
      ? ' iconLeft'
      : ' iconRight' // Center is not a valid choice. Either left or right        
    : ' iconRight'; // Right is default
  const themeMode = props.theme && props.theme.mode ? props.theme.mode : 'light'
  return (    
    <div className={`linkInputContainer ${themeMode}`}>
      <TextArea
        data-testid={props.dataTestId ? props.dataTestId : 'linkInput'}
        className="linkInputTextBox"
        placeholder={props.placeholder}
        value={props.value}
        autoFocus={props.autoFocus}
        maxRows={3}
        onFocus={() => {
          props.onFocus && props.onFocus();
        }}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          // TODO: Need to handle different types of links such as youtube embed link which
          // does not work as a url. 
          props.onChange && props.onChange(event.target.value);
        }}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            if(props.onEnter) {
              props.onEnter();
            }
          }
        }}
      />

      {!hasValue(props.value) && props.icon && (
        <div className={`linkInputIconContainer${iconLocation}`} >
          <GideImage className={`linkInputIconImage${props.iconCssClass && themeMode !== 'dark' ? ' ' + props.iconCssClass : themeMode === 'dark' ? ' color-secondary-300-svg' : ''}`}
            src={props.icon}
            alt="link"
          />
        </div>
      )}
      {hasValue(props.value) && (
        <div className={`linkInputIconContainer iconRight`} >
          <GideImage className='linkInputIconImage'
            onClick={() => props.onChange && props.onChange('')}
            src="/icons/nav/exit/main.svg"
            alt="link"
          />
        </div>
      )}
    </div>
  );
}

export default withTheme(LinkInput);
