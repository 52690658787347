import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { lightTheme } from '../../../themes/custom-theme';
import styles from './_Common.module.scss';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon, 
  getSlideView,
  CommonModes,
} from './Common';
import { ImageSlideFormatPicker, ImageSlideFormatSettings } from '../../DataEntry/Pickers/Modals/ImageSlideFormatPicker/ImageSlideFormatPicker';
import { ImageViewerMode, ImageObjectFit } from '../../../models/CommonEnums';
import { CarouselNavigationMode } from '../../Viewers/ImageViewers/ImageCarousel/ImageCarousel';
import { getCreateActions, getPreviewItems } from './SharedLogic';
import { VideoSlideFormatPicker, VideoSlideFormatSettings } from '../../DataEntry/Pickers/Modals/VideoSlideFormatPicker/VideoSlideFormatPicker';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: false,
    hideCommandBar: false,
    editorState: EditorState.Preview,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: false,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: getCreateActions(context),
    headerActions: getHeaderTitleAndIcon('Format', '/icons/creationprocess/bloglist/image-top.svg', 'black'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.Preview });
    },
    previewItems: getPreviewItems(-1, context),
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { state, setState, isImageSlide, isVideoSlide } = context;
  const slideView = getSlideView(state.slide);
  
  if (isImageSlide) {
    const updateSlideFormatSettings = (slideFormatSettings: ImageSlideFormatSettings, newMode?: string) => {
      const slide = {
        ...state.slide,
        data: {
          ...state.slide.data,
          slideTypeSpecificSettings: slideFormatSettings.carouselSettingsData ? {
            ...state.slide.data.slideTypeSpecificSettings,
            carouselNavigationMode: slideFormatSettings.carouselSettingsData.carouselNavigationMode,
            carouselAdvanceSpeed: slideFormatSettings.carouselSettingsData.carouselAdvanceSpeed,
            imageObjectFit: slideFormatSettings.carouselSettingsData.imageObjectFit,
            displayType: slideFormatSettings.format
          } : {
            ...state.slide.data.slideTypeSpecificSettings, 
            carouselSettingsData: undefined,
            displayType: slideFormatSettings.format ,
          }
        },
        maxHeight: slideFormatSettings.carouselSettingsData ? slideFormatSettings.carouselSettingsData.maxHeight : undefined
      };
      setState(state => ({
        ...state,
        slide, 
        mode: newMode || state.mode,
      }));
    }
    return (
      <div style={{ position: 'relative' }}>
        <div>
          <ImageSlideFormatPicker 
            className={styles.formatModal}
            imageSlideFormatSettings={{
              format: state.slide.data.slideTypeSpecificSettings.displayType,
              carouselSettingsData: state.slide.data.slideTypeSpecificSettings && state.slide.data.slideTypeSpecificSettings.displayType === ImageViewerMode.Carousel
              ? {
                  carouselAdvanceSpeed:  state.slide.data.slideTypeSpecificSettings.carouselAdvanceSpeed ? state.slide.data.slideTypeSpecificSettings.carouselAdvanceSpeed : 0,
                  carouselNavigationMode: state.slide.data.slideTypeSpecificSettings.carouselNavigationMode ? state.slide.data.slideTypeSpecificSettings.carouselNavigationMode : CarouselNavigationMode.RotatingArrows,
                  imageObjectFit: state.slide.data.slideTypeSpecificSettings.imageObjectFit ? state.slide.data.slideTypeSpecificSettings.imageObjectFit : ImageObjectFit.AutoCrop,
                  maxHeight: state.slide.data.slideTypeSpecificSettings.maxHeight ? state.slide.data.slideTypeSpecificSettings.maxHeight : '400'
              } : undefined
            }}
            onCancel={slideFormatSettings => updateSlideFormatSettings(slideFormatSettings, "Preview")}
            onSave={slideFormatSettings => updateSlideFormatSettings(slideFormatSettings, "Preview")}
            onChange={updateSlideFormatSettings}
            onUnhandled={updateSlideFormatSettings}
          />
        </div>
        {slideView}
      </div>
    );
  } else {
    const updateSlideFormatSettings = (slideFormatSettings: VideoSlideFormatSettings, newMode?: string) => {
      const slide = {
        ...state.slide,
        data: {
          ...state.slide.data,
          slideTypeSpecificSettings: slideFormatSettings.carouselSettingsData ? {
            ...state.slide.data.slideTypeSpecificSettings,
            carouselNavigationMode: slideFormatSettings.carouselSettingsData.carouselNavigationMode,
            carouselAdvanceSpeed: slideFormatSettings.carouselSettingsData.carouselAdvanceSpeed,
            imageObjectFit: slideFormatSettings.carouselSettingsData.imageObjectFit,
            displayType: slideFormatSettings.format
          } : {
            ...state.slide.data.slideTypeSpecificSettings, 
            carouselSettingsData: undefined,
            displayType: slideFormatSettings.format ,
          }
        },
        maxHeight: slideFormatSettings.carouselSettingsData ? slideFormatSettings.carouselSettingsData.maxHeight : undefined
      };
      setState(state => ({
        ...state,
        slide, 
        mode: newMode || state.mode,
      }));
    }
    return (
      <div style={{ position: 'relative' }}>
        <div>
          <VideoSlideFormatPicker 
            className={styles.formatModal}
            videoSlideFormatSettings={{
              format: state.slide.data.slideTypeSpecificSettings.displayType,
              carouselSettingsData: state.slide.data.slideTypeSpecificSettings && state.slide.data.slideTypeSpecificSettings.displayType === ImageViewerMode.Carousel
              ? {
                  carouselAdvanceSpeed:  state.slide.data.slideTypeSpecificSettings.carouselAdvanceSpeed ? state.slide.data.slideTypeSpecificSettings.carouselAdvanceSpeed : 0,
                  carouselNavigationMode: state.slide.data.slideTypeSpecificSettings.carouselNavigationMode ? state.slide.data.slideTypeSpecificSettings.carouselNavigationMode : CarouselNavigationMode.RotatingArrows,
                  imageObjectFit: state.slide.data.slideTypeSpecificSettings.imageObjectFit ? state.slide.data.slideTypeSpecificSettings.imageObjectFit : ImageObjectFit.AutoCrop,
                  maxHeight: state.slide.data.slideTypeSpecificSettings.maxHeight ? state.slide.data.slideTypeSpecificSettings.maxHeight : '400'
              } : undefined
            }}
            onCancel={slideFormatSettings => updateSlideFormatSettings(slideFormatSettings, "Preview")}
            onSave={slideFormatSettings => updateSlideFormatSettings(slideFormatSettings, "Preview")}
            onChange={updateSlideFormatSettings}
            onUnhandled={updateSlideFormatSettings}
          />
        </div>
        {slideView}
      </div>
    );
  }
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const Format = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
