import React, { Component, ReactNode, CSSProperties } from 'react';
import { Modal } from 'semantic-ui-react';
import GideImage from '../../Shared/Image/GideImage';
import classNames from 'classnames';
import icons from '../../../assets/icons';
export interface ResizeableModalProps {
  closeModal: () => void;
  actions?: ReactNode;
  leftToolbarContent?: ReactNode;
  centerToolbarContent?: ReactNode;
  rightToolbarContent?: ReactNode;
  canResize?: boolean;
  className?: string;
  style?: CSSProperties;
  mode: 'displayGideHeader' | 'noMargin' | 'auto' | 'free';
  showInputBar?: boolean;  
  width?: number;
  hideCommandBar?: boolean;
  closeOnDimmer?: boolean;
  navigationState?: 'backNavigation' | 'closeModal';
  navigateBack?: () => void;
}

export interface ResizeableModalState {
  top: string;
  mobileTop: string;
}
// TODO: This dialog will allow you to slide up and down the height like
// you see in different dialogs on phone apps.

export default class ResizeableModal extends Component<ResizeableModalProps, ResizeableModalState> {
  constructor(props: ResizeableModalProps) {
    super(props);
    let top = this.props.canResize ? '52px' : 'auto';
    let mobileTop = this.props.canResize ? '48px' : 'auto';
    if(this.props.mode === "displayGideHeader") {
      top = '52px';
      mobileTop = '48px';      
    } else if (this.props.mode === "noMargin") {
      top = '0px';
      mobileTop = '0px';
    } else {
      top = 'auto'
      mobileTop = 'auto';
    }

    this.state = {
      top: top,
      mobileTop: mobileTop,
    };
  }
  modalRef: any;
  public render() {
        // Don't need to worry about bottom on touch move because it will have the right
    // values by default if that is the mode.
    const bottom = this.props.mode === 'free' ? 'auto' : this.props.showInputBar ? '60px': '0px';
    const bottomModal = this.props.mode === 'free' ? 'auto' : this.props.showInputBar ? '55px' : '0px';
    const s = this.props.style ? {...this.props.style} : {};
    const style = {
      ...s,
      '--modalTop': `${this.state.top}`,
      '--modalMobileTop': `${this.state.mobileTop}`,
      '--modalBottom' : `${bottom}`,
      '--modalMobileBottom' : `${bottomModal}`,
      '--modalWidth': `${this.props.width ? this.props.width + 'px' : '100%'}`
    } as React.CSSProperties;
    return (
      <Modal
        className={classNames('resizeableModal', this.props.className ? this.props.className : '', this.props.mode)}
        closeOnEscape={true}
        onClose={this.props.closeModal}
        size="fullscreen"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={this.props.closeOnDimmer === true}
        ref={modal => (this.modalRef = modal)}
        style={style}
      >
        <Modal.Content className="resizeableModalContentContainer">
          {this.props.canResize && (
            <div draggable={true} className="modalDragHandleContainer"
            
            onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
              if (e.targetTouches.length > 0) {
                let top = e.targetTouches[e.targetTouches.length - 1].clientY;
                if (top < 0) { top = 0; }
                this.setState({ top: `${top}px`, mobileTop: `${top}px` });
              }
            }}
            onDragCapture={(e: React.DragEvent<HTMLDivElement>) => {
              var top = e.clientY < 0 ? 0 : e.clientY;
              this.setState({ top: `${top}px`, mobileTop: `${top}px` });
            }}
            onDragStart={(e: React.DragEvent<HTMLDivElement>) => {
              // Want to hide the ghost image when dragging
              var img = new Image(); 
              img.src = 'empty.gif'; 
              img.style.opacity = "0";
              e.dataTransfer.setDragImage(img, 10, 10);
            }}
            >
              <div
                className="modalDragHandle"
                onDragCapture={(e: React.DragEvent<HTMLDivElement>) => {
                  var top = e.clientY < 0 ? 0 : e.clientY;
                  this.setState({ top: `${top}px`, mobileTop: `${top}` });
                }}            
              />
            </div>
          )}
          {this.props.hideCommandBar !== true && (
            <div className="resizeableModalHeader">
              {this.props.navigationState === "backNavigation" && (
                <icons.Nav_Arrow_ArrowLeft
                  color='var(--COLOR-SECONDARY-600)'
                  onClick={this.props.navigateBack}
                  style={{cursor: 'pointer'}}
                />
              )}
              {this.props.navigationState === "closeModal" && (
                <icons.Nav_Exit_Main
                  color='var(--COLOR-SECONDARY-600)'
                  onClick={this.props.closeModal}
                  style={{cursor: 'pointer'}}
                />
              )}
              <div className={`resizeableModalToolbar`}>
                <div className="resizeableModalLeftToolbarContent">{this.props.leftToolbarContent && this.props.leftToolbarContent}</div>

                <div className="resizeableModalCenterToolbarContent">
                  {this.props.centerToolbarContent && this.props.centerToolbarContent}
                </div>
                <div className="resizeableModalRightToolbarContent">{this.props.rightToolbarContent && this.props.rightToolbarContent}</div>
              </div>
            </div>
          )}
          <div className="resizeableModalContent">{this.props.children}</div>
        </Modal.Content>
        <Modal.Actions className="resizeableModalActions">{this.props.actions}</Modal.Actions>
      </Modal>
    );
  }
}
