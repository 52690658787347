import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropZone from 'react-dropzone';
import {
  Modal,
  Input,
  List,
  Checkbox,
  Image,
  Button,
  Icon,
} from 'semantic-ui-react';

import agent from '../../agent';
import ImageMapper from '../ImageMapper';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  UPDATE_POINTS,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import { contentMetaData } from '../../constants/contentMetaData';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import slideTools from '../../slideTools';
import GideImage from '../Shared/Image/GideImage';

let dropZoneStyles = {
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...slideTools.mapStateToProps(state, ownProps),
    points: state.common.points,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updatePoints: payload => dispatch({ type: UPDATE_POINTS, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

// const URL = 'https://c1.staticflickr.com/5/4052/4503898393_303cfbc9fd_b.jpg';

export class DiagramModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'DIAGRAM',
        data: {
          audioCaption: null,
          caption: '',
          imageURL:
            'https://c1.staticflickr.com/5/4052/4503898393_303cfbc9fd_b.jpg',
          points: [],
        },
      },
      linkLabelChecked: false,
      map: {
        name: 'my-map',
        areas: [],
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.linkSlide = () => {
      this.props.openModal({
        modalType: 'SlideSelectModal',
        modalProps: {},
      });
    };

    this.onUpdatePoint = i => ev => {
      const val = ev.target.value;
      let points = this.props.points.slice();
      points[i].label = val;
      this.props.updatePoints({ points });
      this.setState(prevState => {
        const areas = points.map(point => {
          let area = {
            shape: 'circle',
            coords: [point.x, point.y, 15],
          };
          return area;
        });
        const newState = {
          map: {
            name: prevState.map.name,
            areas: areas,
          },
        };
        return newState;
      });
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const linkLabelChecked = data.checked;
      let instructions = '';
      if (linkLabelChecked) {
        instructions = 'Pick a point';
      }
      this.setState({
        linkLabelChecked,
        instructions,
      });
    };

    this.onRemovePoint = idx => {
      setTimeout(() => {
        let points = this.props.points.slice();
        points.splice(idx, 1);
        this.props.updatePoints({ points });
        this.setState(prevState => {
          const areas = points.map(point => {
            let area = {
              shape: 'circle',
              coords: [point.x, point.y, 15],
            };
            return area;
          });
          const newState = {
            map: {
              name: prevState.map.name,
              areas: areas,
            },
            selectedPoint: null,
          };
          return newState;
        });
      }, 500);
    };

    this.onPointListItemSelected = index => {
      let points = this.props.points.slice();
      points.forEach((p, i) => {
        p.selected = false;
      });
      points[index].selected = true;
      this.props.updatePoints({ points });
      this.setState({
        selectedPoint: index,
        selectedPointByClick: true,
      });
    };
    // this.createSlide = this._createSlide.bind(this);
    this.createSlide = async () => {
      // const data = {
      //   caption: this.state.caption,
      //   imageURL: this.state.imageURL,
      //   points: this.props.points,
      // };
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: this.props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      slide.data.points = this.props.points;

      // const payload = agent.Slides.create(this.props.articleSlug, slide);
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (!acceptedFiles.length) return;
    const file = acceptedFiles[0];
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        let slide = Object.assign({}, this.state.slide);
        slide.data.imageURL = reader.result;
        this.setState({ slide });
      },
      false,
    );
    reader.readAsDataURL(file);
  }

  onAreaClick(area, index, ev) {
    let points = this.props.points.slice();
    points.forEach((p, i) => {
      p.selected = false;
    });
    points[index].selected = true;
    this.props.updatePoints({ points });
    this.setState({
      selectedPoint: index,
      selectedPointByClick: true,
    });
  }

  onAreaEnter(area, index, ev) {
    let points = this.props.points.slice();
    points.forEach((p, i) => {
      p.selected = false;
    });
    points[index].selected = true;
    this.props.updatePoints({ points });
    this.setState({
      selectedPoint: index,
      selectedPointByClick: false,
    });
  }

  onAreaLeave(area, index, ev) {
    if (!this.state.selectedPointByClick) {
      let points = this.props.points;
      points[index].selected = false;
      this.props.updatePoints({ points });
      this.setState({
        selectedPoint: null,
      });
    }
  }
  onImageClick(e) {
    if (!this.state.linkLabelChecked) {
      return;
    }
    const point = {
      name: 'foo',
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      label: '',
      selected: true,
    };
    let points = this.props.points.slice();
    points.forEach(p => {
      p.selected = false;
    });
    points.push(point);
    this.props.updatePoints({ points });
    this.setState(prevState => {
      const areas = points.map(point => {
        let area = {
          shape: 'circle',
          coords: [point.x, point.y, 15],
        };
        return area;
      });
      return {
        selectedPoint: points.length - 1,
        map: {
          name: prevState.map.name,
          areas: areas,
        },
      };
    });
  }
  render() {
    const { points } = this.props;
    const { slide } = this.state;

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        size="small"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(152, 63, 85)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Diagram Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-diagram.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <DropZone
                onDrop={this.onDrop.bind(this)}
                disableClick={false}
                style={dropZoneStyles}
                multiple={false}
                ref={c => (this._dropZone = c)}
              >
                <p>Drop file or select.</p>
              </DropZone>
              <section>
                <ImageMapper
                  src={slide.data.imageURL}
                  map={this.state.map}
                  width={500}
                  onImageClick={this.onImageClick.bind(this)}
                  onClick={this.onAreaClick.bind(this)}
                  onMouseEnter={this.onAreaEnter.bind(this)}
                  onMouseLeave={this.onAreaLeave.bind(this)}
                  selectedPoint={this.state.selectedPoint}
                  strokeColor={'rgba(100, 200, 250, 0.5)'}
                  lineWidth={1}
                  alwaysShow
                />
              </section>
              <section>
                <p className="instructions">{this.state.instructions}</p>
              </section>
              <Checkbox
                label="Link and Label points (adds point on click)"
                checked={this.state.linkLabelChecked}
                onChange={this.updateStateCheckbox('linkLabelChecked')}
              />
              <List divided relaxed>
                {points.map((point, i) => {
                  return (
                    <List.Item
                      key={i}
                      active={i === this.state.selectedPoint}
                      onClick={() => this.onPointListItemSelected(i)}
                    >
                      <List.Icon>
                        <GideImage 
                          className={`svgIcon small pointer color-secondary-500-svg`}
                          src="/icons/content-alteration/delete.svg"
                          alt={`□`}
                          onClick={() => this.onRemovePoint(i)}
                        />
                      </List.Icon>
                      <List.Content>
                        <List.Description>
                          <Input
                            value={point.label}
                            onChange={this.onUpdatePoint(i)}
                          />
                          <span
                            style={{ margin: '0 5px 0 5px' }}
                            className="link underlined"
                            onClick={this.linkSlide}
                          >
                            {!point.slide ? 'Link Slide' : 'Linked slide: '}
                          </span>
                          {point.slide && <span>{point.slide.slideType}</span>}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions
          style={{ background: contentMetaData['DIAGRAM'].primaryColor }}
        >
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagramModal);
