import React, { CSSProperties } from 'react';
import styles from './_picker.module.scss';
import { IconProps } from '../../../../assets/icons';
import { TertiaryButton } from '../../../Shared/Buttons/TertiaryButton/TertiaryButton';

// Move these enums to where they are used from
export enum GideTitleSlideFormat {
  ImageAndText = 1,
  ImageOnly = 2,
  TextOnly = 3,
  NoTitle = 4
}


export interface  PickerProps {
  title: string;
  pickerItems: PickerItem[];
  selectedValue: number; // GideTitleSlideFormat | SlideWidthType;
  onChange: (value: number) => void; // (format: GideTitleSlideFormat | SlideWidthType) => void; 
  style?: CSSProperties;
}
export interface PickerItem {
  icon: (props: IconProps) => JSX.Element;
  label: string;
  value: number;
}

export default function Picker (props:  PickerProps) {
  return (
    <div className={styles.pickerContainer}
      style={props.style}
    >
      <span className={styles.title} style={{marginBottom: '-2px'}}>{props.title}</span>
      <div className={styles.pickerItems}>
        {props.pickerItems.map((pickerItem: PickerItem, i: number) => 
          <TertiaryButton
            icon={pickerItem.icon}
            isSelected={props.selectedValue === pickerItem.value}
            key={i}
            onClick={() => {
              props.onChange(pickerItem.value);
            }}
            label={pickerItem.label}
            style={{margin: '6px'}}
          />
        )}        
        {/* Need these items to push the content to the left */}
        <span className={styles.pickerItemAligner} />
        <span className={styles.pickerItemAligner} />
        <span className={styles.pickerItemAligner} />
        <span className={styles.pickerItemAligner} />
        <span className={styles.pickerItemAligner} />
        <span className={styles.pickerItemAligner} />
      </div>

    </div>
  );
}
