import React from 'react';
import { Slide } from '../../../models/Slide';
import { Checkbox } from 'semantic-ui-react';
import classNames from 'classnames';
import { isNullOrUndefined } from 'util';
import GideImage from '../../Shared/Image/GideImage';
const createDOMPurify = require('dompurify');
export interface SwipeHeaderNavigationProps {
  slide: Slide;
  headerIsCollapsed?: boolean;
  parentHeader?: Slide;
  headerSlideLabel?: string;
  canNavigateToPreviousHeader?: boolean;
  previousHeaderSection?: string;
  nextHeaderSection?: string;
  numberOfSlidesUnderCurrentHeader?: number;
  onCollapseSlide?: (slide: Slide) => void;
  onClickPreviousHeaderSlide?: (e: any) => void;
  onClickNextHeaderSlide?: (e: any) => void;
}

export const SwipeHeaderNavigation = (props: SwipeHeaderNavigationProps) => {
  const infoPanelHeaderClasses = classNames(
    'swipeViewHeaderSlideTitle swipeHeaderEllipsis',
    props.slide && props.slide.slideType === 'HEADER'
      ? props.slide.data.level === 0
        ? 'ti'
        : `headerLevel${props.slide.data.level}`
      : 'noParentHeader',
  );

  return (
    <div>
      {props.slide.slideType === 'HEADER' && (
        <div className="swipeHeaderNavigation">
          <div className={infoPanelHeaderClasses}>
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: createDOMPurify.sanitize(props.slide.data.title
                    .replace('<p>', '')
                    .replace('</p>', '')),
                }}
              />
            }
          </div>
          <div className="swipeHeaderNavigationActions">
            <div className="flexRowCenter">
              <div
                className="headerNavigationButton"
                onClick={props.onClickPreviousHeaderSlide}
              >
                {props.canNavigateToPreviousHeader && (
                  <GideImage 
                    className="headerNavigateButton whites-normal-1000-svg"
                    src="/icons/nav/media/skip/skip-left.svg"
                    alt={`□`}
                  />
                )}
                <span className="previousHeaderSection">
                  {props.previousHeaderSection}
                </span>
              </div>
              {props.headerSlideLabel === 'Title' && (
                <div className="swipeHeaderExpandedSectionCaption">
                  {props.headerSlideLabel}
                </div>
              )}
              {!props.headerIsCollapsed &&
                props.headerSlideLabel !== 'Title' && (
                  <div className="swipeHeaderExpandedSectionCaption">
                    Section {props.headerSlideLabel}
                  </div>
                )}
              {props.headerIsCollapsed &&
                props.headerSlideLabel !== 'Title' && (
                  <div className="swipeHeaderCollapsedSectionCaption">
                    Section {props.headerSlideLabel}
                  </div>
                )}
              <span
                className="headerNavigationButton"
                onClick={props.onClickNextHeaderSlide}
              >
                <span className="nextHeaderSection">
                  {props.nextHeaderSection}
                </span>
                {props.nextHeaderSection && (
                  <GideImage 
                    className="headerNavigateButton whites-normal-1000-svg"
                    src="/icons/nav/skip/skip-right.svg"
                    alt={`□`}
                  />
                )}
              </span>
            </div>
            <div className="swipeHeaderToggle">
              {props.headerIsCollapsed &&
                !isNullOrUndefined(props.onCollapseSlide) && (
                  <span className="collapsedHeaderToggle">
                    <span className="expanderToggleResponsiveLeft">
                      <Checkbox
                        toggle
                        checked={!props.headerIsCollapsed}
                        onChange={(e, data) =>
                          !isNullOrUndefined(props.onCollapseSlide)
                            ? props.onCollapseSlide(props.slide)
                            : {}
                        }
                      />
                      <span className="swipeHeaderCollapsedCaption">
                        collapsed
                      </span>
                    </span>
                    <span className="numberOfSlidesInHeaderCaption">
                      {props.numberOfSlidesUnderCurrentHeader} slides will be
                      skipped
                    </span>
                  </span>
                )}
              {!props.headerIsCollapsed &&
                !isNullOrUndefined(props.onCollapseSlide) && (
                  <span className="expandedHeaderToggle">
                    <span className="expanderToggleResponsiveLeft">
                      <Checkbox
                        toggle
                        checked={!props.headerIsCollapsed}
                        onChange={(e, data) =>
                          !isNullOrUndefined(props.onCollapseSlide)
                            ? props.onCollapseSlide(props.slide)
                            : {}
                        }
                      />
                      <span className="swipeHeaderExpandedCaption">
                        expanded
                      </span>
                    </span>
                    <span className="numberOfSlidesInHeaderCaption">
                      {props.numberOfSlidesUnderCurrentHeader} slides in this
                      section
                    </span>
                  </span>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
