import { ChannelSearchElement } from './../components/GideElementSearch';

import { Author, MockAuthor, } from './Author';

export interface Channel {
  _id: string;
  slug: string;
  title: string;
  description?: string;
  image: string;
  author: Author;
  domain: any;
  type: string;
  public: boolean;
  website: boolean;
  numberOfGides?: number;
  numberOfMembers?: number;
}
export const createChannelSearchElementFromChannel = (channel: Channel): ChannelSearchElement => {
  return {
    id: channel._id,
    imageUrl: channel.image,
    displayName: channel.title,
    authorImageUrl: channel.author.image,
    authorUsername: channel.author.username,
    slug: channel.slug,
    type: channel.type,
    createdAt: new Date(), // TODO: Get Actual created at date
    updatedAt: new Date(), // TODO: Get Actual created at date
    public: channel.public,
    website: channel.website,
    numberOfGides: channel.numberOfGides ? channel.numberOfGides : 0,
    numberOfMembers: channel.numberOfMembers ? channel.numberOfMembers : 0
  };
}
export interface ChannelSummary {
  id: string;
  slug: string;
  title: string;
  description?: string;
  image: string;
  authorImage: string;
  author: string; // username
  type: string;
  public: boolean;
  website: boolean;
  numberOfGides?: number;
  numberOfMembers?: number;
}
export interface ChannelCreate {
  title: string;
  description: string;
  image: string;
  type: string;
}

export const MockChannel: Channel = {
  _id: '1',
  slug: '1',
  author: MockAuthor,
  title: 'Gide through Evolution',
  description: 'Take a gided journey down the long road of evolution to se the origins of man.',
  image: '',
  public: true,
  website: false,
  type: 'PUBLIC',
  domain: '',
  numberOfGides: 5,
  numberOfMembers: 100
}

export const MockChannels: Channel[] = [
  {
    _id: '1',
    image: 'https://images.unsplash.com/photo-1579504113830-b7e6e278309f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 1',
    description: 'Test Channel 1 Description',
    author: MockAuthor,
    public: true,
    website: false,
    slug: '1',
    type: 'PUBLIC',
    domain: '',
    numberOfGides: 5,
    numberOfMembers: 100
  },
  {
    _id: '2',
    image: 'https://images.unsplash.com/photo-1579522595451-3b3b86ed0252?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 2',
    description: 'Test Channel 2 Description',
    domain: '',
    author: MockAuthor,
    public: true,
    website: false,
    slug: '1',
    type: 'PUBLIC',
    numberOfGides: 7,
    numberOfMembers: 100
  },
  {
    _id: '3',
    image: 'https://images.unsplash.com/photo-1579441401150-731d2a86d961?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 3', 
    description: 'Test Channel 3 Description',
    domain: '',
    author: MockAuthor,
    public: true,
    website: false,
    slug: '1',
    type: 'PUBLIC',
    numberOfGides: 9,
    numberOfMembers: 100
  },
  {
    _id: '4',
    image: 'https://images.unsplash.com/photo-1579507905029-b08de2f63b3d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 4', 
    description: 'Test Channel 4 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 10,
    numberOfMembers: 100
  },
  {
    _id: '5',
    image: 'https://images.unsplash.com/photo-1579515272222-adffa97cb506?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 5', 
    description: 'Test Channel 5 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',


    numberOfGides: 22,
    numberOfMembers: 100
  },
  {
    _id: '6',
    image: 'https://images.unsplash.com/photo-1579516432270-eafa23d73c56?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 6',
    description: 'Test Channel 6 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 11,
    numberOfMembers: 100
  },
  {
    _id: '7',
    image: 'https://images.unsplash.com/photo-1579486633172-734d9484e14c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 7',
    description: 'Test Channel 7 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',


    numberOfGides: 15,
    numberOfMembers: 100
  },
  {
    _id: '8',
    image: 'https://images.unsplash.com/photo-1579549695256-8e177e62fdf3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 8',
    description: 'Test Channel 8 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',


    numberOfGides: 8,
    numberOfMembers: 100
  },
  {
    _id: '9',
    image: 'https://images.unsplash.com/photo-1579550752291-74213f625700?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 9',
    description: 'Test Channel 9 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',


    numberOfGides: 18,
    numberOfMembers: 100
  },
  {
    _id: '10',
    image: 'https://images.unsplash.com/photo-1579379235188-009e04d34aee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 10',
    description: 'Test Channel 10 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 5,
    numberOfMembers: 100
  },
  {
    _id: '11',
    image: 'https://images.unsplash.com/photo-1579379234523-aa0099ae66fd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 11', domain: '',
    description: 'Test Channel 11 Description',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 25,
    numberOfMembers: 100
  },
  {
    _id: '12',
    image: 'https://images.unsplash.com/photo-1579509886026-2be1ecd72892?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 12',
    description: 'Test Channel 12 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 35,
    numberOfMembers: 100
  },
  {
    _id: '13',
    image: 'https://images.unsplash.com/photo-1579469928232-cd2742f7a633?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 13',
    description: 'Test Channel 13 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 42,
    numberOfMembers: 100
  },
  {
    _id: '14',
    image: 'https://images.unsplash.com/photo-1579547945478-a6681fb3c3c9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 14',
    description: 'Test Channel 14 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 88,
    numberOfMembers: 100
  },
  {
    _id: '15',
    image: 'https://images.unsplash.com/photo-1579395193774-f95f3f25ac10?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 15',
    description: 'Test Channel 15 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 51,
    numberOfMembers: 100
  },
  {
    _id: '16',
    image: 'https://images.unsplash.com/photo-1579536745800-925dca3a7535?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 16',
    description: 'Test Channel 16 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 15,
    numberOfMembers: 100
  },
  {
    _id: '17',
    image: 'https://images.unsplash.com/photo-1579513309526-3ed27b5a8b32?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 17',
    description: 'Test Channel 17 Description',
    domain: '',
    author: MockAuthor,
    public: true, website: false, slug: '1',
    type: 'PUBLIC',
    numberOfGides: 75,
    numberOfMembers: 100
  },
  {
    _id: '18',
    image: 'https://images.unsplash.com/photo-1579515272214-52397dfedcbf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 18',
    description: 'Test Channel 18 Description',
    domain: '',
    author: MockAuthor,
    public: true,
    website: false,
    slug: '1',
    type: 'PUBLIC',
    numberOfGides: 34,
    numberOfMembers: 100
  },
  {
    _id: '19',
    image: 'https://images.unsplash.com/photo-1558981023-1d4b7dd8dfb9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 19',
    description: 'Test Channel 19 Description',
    domain: '',
    author: MockAuthor,
    public: true,
    website: false,
    slug: '1',
    type: 'PUBLIC',
    numberOfGides: 53,
    numberOfMembers: 100
  },
  {
    _id: '20',
    image: 'https://images.unsplash.com/photo-1579464863901-a07891a09427?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60',
    title: 'Test Channel 20',
    description: 'Test Channel 20 Description',
    domain: '',
    author: MockAuthor,
    public: true,
    website: false,
    slug: '1',
    type: 'PUBLIC',
    numberOfGides: 12,
    numberOfMembers: 100
  }
];
