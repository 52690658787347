import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  List,
  Image,
  Checkbox,
  Dropdown,
  Radio,
  Button,
  Icon,
} from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  UPDATE_POINTS,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import { contentMetaData } from '../../constants/contentMetaData';
import slideTools from '../../slideTools';
import GideImage from '../Shared/Image/GideImage';
import isNil from 'ramda/es/isNil';
const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  points: state.common.points,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updatePoints: payload => dispatch({ type: UPDATE_POINTS, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class ChooseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choiceBody: '',
      slide: {
        slideType: 'CHOOSE',
        data: {
          audioCaption: null,
          caption: '',
          question: '',
          choices: [],
          type: '',
          answerAttempts: 1,
          wrongWaitTime: 1,
        },
      },
      showSettings: false,
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.addChoice = () => {
      if (!this.state.choiceBody.length) {
        return alert('Add choice first.');
      }
      let choices = this.props.points;
      let choice = {
        body: this.state.choiceBody,
      };
      choices.push(choice);
      this.props.updatePoints({ points: choices });
      this.setState({
        choiceBody: '',
      });
    };

    this.createSlide = async () => {
      const choices = this.props.points.map(p => {
        return {
          body: p.body,
          slide: p.slide ? p.slide.id : undefined,
          correct:
            this.state.slide.data.type === 'MULTIPLE_CHOICE'
              ? p.correct
              : undefined,
        };
      });
      let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.props.editSlide.position
          : slidePosition,
      };
      slide.data.choices = choices;
      let payload;
      if (this.props.editSlide) {
        payload = await agent.Slides.update(this.props.editSlide.id, slide);
      } else {
        payload = await agent.Slides.create(this.props.article, slide);
        if (this.props.childArticleEditInfo) {
          slideTools.getSlideAttachmentInfo(
            this.props.childArticleEditInfo.ownerSlide.id,
            this.props.updateSlideAttachmentInfo,
          );
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit({ ...payload, mode: this.props.mode });
      this.props.closeModal();
    };

    this.handleRadioChange = (e, { value }) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.wrongAction = value;
      this.setState({ slide });
    };

    this.setWrongWaitTime = val => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.wrongWaitTime = val;
      this.setState({ slide });
    };

    this.setType = this._setType.bind(this);
    this.onRemove = this._onRemove.bind(this);
    this.setCorrect = this._setCorrect.bind(this);
    this.linkSlide = this._linkSlide.bind(this);
  }

  _linkSlide(idx) {
    this.props.points.forEach(p => (p.selected = false));
    this.props.points[idx].selected = true;
    this.props.updatePoints({ points: this.props.points });
    this.props.openModal({
      modalType: 'SlideSelectModal',
      modalProps: {},
    });
  }

  _onRemove(idx) {
    let choices = this.props.points.slice();
    choices.splice(idx, 1);
    this.props.updatePoints({ points: choices });
  }

  _setCorrect(idx, checked) {
    let choices = this.props.points.slice();
    choices.forEach(c => {
      c.correct = false;
    });
    choices[idx].correct = checked;
    this.props.updatePoints({ points: choices });
  }

  _setType(type) {
    const slide = Object.assign({}, this.state.slide);
    slide.data.type = type;
    this.setState({ slide });
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="modal choose"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(217, 126, 56)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Choose Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-choose.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <div className="inner">
                {slide.data.type === '' && (
                  <div className="chooseTypes">
                    <div
                      className="chooseType saveAnswer"
                      onClick={() => this.setType('MULTIPLE_CHOICE')}
                    >
                      <GideImage src="/images/chooseSaveAnswer.png" alt={`□`} />
                      <div className="words">MULTIPLE CHOICE</div>
                    </div>
                    <div
                      className="chooseType"
                      onClick={() => this.setType('JUMP')}
                    >
                      <GideImage src="/images/chooseJump.png" alt={`□`} />
                      <div className="words">JUMP TO SLIDE</div>
                    </div>
                  </div>
                )}
                {slide.data.type !== '' && (
                  <div>
                    <div>
                      <Input
                        fluid
                        placeholder="Enter Survey or Vote Question Here."
                        value={slide.data.question}
                        onChange={this.updateDataState('question')}
                        autoFocus
                      />
                    </div>
                    <List selection>
                      {this.props.points.map((choice, i) => {
                        return (
                          <List.Item key={i}>
                            <List.Content>
                              {slide.data.type === 'MULTIPLE_CHOICE' ? (
                                <span>
                                  <Checkbox
                                    label={choice.body}
                                    checked={choice.correct}
                                    onChange={(ev, data) =>
                                      this.setCorrect(i, data.checked)
                                    }
                                  />
                                </span>
                              ) : (
                                <div>
                                  <span>{choice.body}</span>
                                  <span
                                    style={{ margin: '0 5px 0 5px' }}
                                    className="link underlined"
                                    onClick={() => this.linkSlide(i)}
                                  >
                                    {!choice.slide
                                      ? 'Link Slide'
                                      : 'Linked slide: '}
                                  </span>
                                  {choice.slide && (
                                    <span>{choice.slide.slideType}</span>
                                  )}
                                </div>
                              )}
                              <GideImage 
                                className={`svgIcon pointer small color-secondary-500-svg`}
                                src="/icons/content-alteration/delete.svg"
                                onClick={() => this.onRemove(i)}
                                alt={`□`}
                                style={{ marginTop: '-4px', float: 'right' }}
                              />
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                    <div>
                      <Input
                        fluid
                        value={this.state.choiceBody}
                        onChange={this.updateState('choiceBody')}
                        placeholder="Enter Choice"
                      />
                      <Button
                        fluid
                        content="Add Choice"
                        icon="add"
                        onClick={this.addChoice}
                        disabled={this.state.choiceBody.length <= 0}
                      />
                    </div>
                    {slide.data.type === 'MULTIPLE_CHOICE' && (
                      <div className="wrongAnswerOptions">
                        <p className="center plum">Answer Attempts</p>
                        <p className="center">
                          <Dropdown
                            className="center attempts"
                            placeholder="Any"
                            value={slide.data.answerAttempts}
                            onChange={(ev, data) =>
                              this.setState({ answerAttempts: data.value })
                            }
                            options={[
                              { key: 'unlimited', text: 'Any', value: '' },
                              { key: 'one', text: '1', value: 1 },
                              { key: 'two', text: '2', value: 2 },
                              { key: 'three', text: '3', value: 3 },
                            ]}
                          />
                        </p>
                        <p className="center">Wrong Answer Options</p>
                        <fieldset>
                          <Radio
                            label="REWIND TO BEGINNING"
                            name="radioGroup"
                            value="REWIND"
                            checked={slide.data.wrongAction === 'REWIND'}
                            onChange={this.handleRadioChange}
                          />
                        </fieldset>
                        <fieldset>
                          <Radio
                            label="WAIT TIME"
                            name="radioGroup"
                            value="WAIT"
                            checked={slide.data.wrongAction === 'WAIT'}
                            onChange={this.handleRadioChange}
                          />
                          {slide.data.wrongAction === 'WAIT' && (
                            <Dropdown
                              placeholder="Wait time"
                              value={slide.data.wrongWaitTime}
                              onChange={(ev, data) =>
                                this.setWrongWaitTime(data.value)
                              }
                              options={[
                                { key: 'one', text: '1 min', value: 1 },
                                { key: 'five', text: '5 min', value: 5 },
                                { key: 'ten', text: '10 min', value: 10 },
                              ]}
                              style={{ margin: '0 0 0 15px' }}
                            />
                          )}
                        </fieldset>
                        <fieldset>
                          <Radio
                            label="CLOSE GIDE"
                            name="radioGroup"
                            value="CLOSE"
                            checked={slide.data.wrongAction === 'CLOSE'}
                            onChange={this.handleRadioChange}
                          />
                        </fieldset>
                        <fieldset>
                          <Radio
                            label="LOCK OUT/DISAPPEAR"
                            name="radioGroup"
                            value="LOCK"
                            checked={slide.data.wrongAction === 'LOCK'}
                            onChange={this.handleRadioChange}
                          />
                        </fieldset>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {slide.data.type !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['CHOOSE'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={() => this.setType('')}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseModal);
