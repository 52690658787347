import React, { useState, useCallback } from 'react';
import styles from './_gide-title-slide.module.scss';
import { Slide } from '../../../models/Slide';
import isNil from 'ramda/es/isNil';
import { GideTitleSlideFormat } from '../../DataEntry/Pickers/Picker/Picker';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { User } from '../../../models/User';
import { Article } from '../../../models/Article';
import GideImage from '../../Shared/Image/GideImage';
import classNames from 'classnames';
import cssAspectRatio from '../../_css-aspect-ratio.module.scss';
import { LabeledTextInput } from '../../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput';
import icons from '../../../assets/icons';

export interface GideTitleSlideProps {
  slide: Slide;
  slideSelectionModeDetail?: SlideSelectionInfo;
  viewMode: string;
  currentUser: User;
  article: Article;
  onSubmitSlideZero?: (articleId: string, slideZero: Slide, articleSlug: string, tagList?: string[], description?: string, descriptionSlide?: string) => void;
  openModal?: (payload: any) => void;
  onClick?: () => void;
  onModifiedTitle?: (newTitle: string) => void;
}

export default function GideTitleSlide(props: GideTitleSlideProps) {
  const canEditTitle = !!props.onModifiedTitle;
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const beginEditingTitle = useCallback(() => {
    canEditTitle && setIsEditingTitle(true);
  }, [setIsEditingTitle]);
  const finishEditingTitle = useCallback((newTitle: string) => {
    setIsEditingTitle(false);
    props.onModifiedTitle && props.onModifiedTitle(newTitle);
  }, [setIsEditingTitle, props.onModifiedTitle]);
  const onChange = useCallback((newTitle: string) => {
    props.onModifiedTitle && props.onModifiedTitle(newTitle);
  }, [props.onModifiedTitle]);

  const gideTitleIsSet = props.slide.data.title;
  const gideImageIsSet = props.slide.data.image;
  const format = !isNil(props.slide.data.slideTypeSpecificSettings) && !isNil(props.slide.data.slideTypeSpecificSettings.format)
    ? props.slide.data.slideTypeSpecificSettings.format
    : GideTitleSlideFormat.ImageAndText;

  const onClear = () => {};

  return (
    <div
      className={styles.gideTitleSlideContainer}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {format === GideTitleSlideFormat.NoTitle && props.viewMode === 'SLIDE' && (
        <div className={styles.noTitleFormatContianer}>
          <div className={styles.message}>
            <span className={styles.bold}>Your title slide</span> <span>will not show to your viewers</span>
          </div>
        </div>
      )}
      {gideImageIsSet && (format === GideTitleSlideFormat.ImageAndText || format === GideTitleSlideFormat.ImageOnly) && (
        <div className={classNames(styles.gideTitleImage, cssAspectRatio.aspectRatio)}>
          <GideImage src={props.slide.data.image} alt={'Gide Image'} />
        </div>
      )}
      {!isEditingTitle && <>
        {!gideTitleIsSet &&
          <div className={`${styles.gideTitle} ${styles.missing}`} onClick={beginEditingTitle}>
            {canEditTitle && <span><icons.Nav_Edit_EditMain /></span>}
            Give the gide a title!
          </div>}
        {gideTitleIsSet && (format === GideTitleSlideFormat.ImageAndText || format === GideTitleSlideFormat.TextOnly) && (
          <div className={styles.gideTitle} onClick={beginEditingTitle}>
            {canEditTitle && <span><icons.Nav_Edit_EditMain /></span>}
            {props.slide.data.title}
          </div>
        )}
      </>
      }
      {isEditingTitle &&
        <LabeledTextInput
          label=""
          sublabel=""
          text={props.slide.data.title}
          theme="light"
          skipAcceptStep
          submitOnEnterKey
          placeholder="Give the gide a title!"
          isAcceptable={(text: string) => true}
          onAccept={async (text: string) => true}
          onConfirm={finishEditingTitle}
          onClear={onClear}
          onChange={onChange}
          onBlur={finishEditingTitle}
        />
      }
    </div>
  );
}
