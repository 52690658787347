import {
  COLLECTION_MODAL_LOADED,
  COLLECTION_MODAL_UNLOADED
} from '../constants/actionTypes';

const initialState = {
  collections: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_MODAL_LOADED:
      return {
        ...state,
        collections: action.payload.collections,
      };
    case COLLECTION_MODAL_UNLOADED:
      return initialState;
    default:
      return state;
  }
};
