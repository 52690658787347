import React from 'react';
import moment from 'moment';
import GideImage from '../Shared/Image/GideImage';

const AuthorHeader = ({ verb, author, date, authorViewing }) => {
  if (!author) {
    return <div />;
  }
  return (
    <div className="slideAuthor">
      <GideImage src={author.image} className="user-pic" alt={author.username} />
      <span>
        <span className="direction">{verb} </span>
        <span className="name">{authorViewing ? 'You' : author.username}</span>
      </span>
      {date && <span className="date">{moment(date).format('LLLL')}</span>}
    </div>
  );
};

export default AuthorHeader;
