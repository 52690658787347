import React, { Component } from 'react';
import { SliderComponent } from '@syncfusion/ej2-react-inputs';
import { formatMinuteAndSeconds } from '../../../utils/helperFunctions';

export interface TimeRangeSliderProps {
  sliderMin: number;
  sliderMax: number;
  rangeMin: number;
  rangeMax: number;
  sliderValue: number;
  onUpdateRange: (rangeMin: number, rangeMax: number) => void;
  onTimeSliderChange: (timeValue: number) => void;
  // onSliderCreated: () => void;
}

export class TimeRangeSlider extends Component<TimeRangeSliderProps> {
  rangeSlider: SliderComponent | null | undefined;
  timeSlider: SliderComponent | null | undefined;

  onTimeTooltipChangeHandler = (args: any) => {
    if (args.value) {
      args.text = formatMinuteAndSeconds(args.value as number);
    }
  };

  onTimeRangeSliderChange = (args: any) => {
    if (this.rangeSlider && this.rangeSlider.value) {
      const rangeValue = this.rangeSlider.value;
      const rangeMin = (rangeValue as number[])[0];
      const rangeMax = (rangeValue as number[])[1];
      this.props.onUpdateRange(rangeMin, rangeMax);
    }
  };

  onTimeSliderChange = (args: any) => {
    if (args.value && args.value !== this.props.sliderValue) {
      const slideStartTime = args.value as number;
      this.props.onTimeSliderChange(slideStartTime);
    }
  };

  onRangeTooltipChangeHandler = (args: any) => {
    if (this.rangeSlider && this.rangeSlider.value) {
      const rangeValue = this.rangeSlider.value;

      const rangeMin = (rangeValue as number[])[0];
      const rangeMax = (rangeValue as number[])[1];

      const min = formatMinuteAndSeconds(rangeMin);
      const max = formatMinuteAndSeconds(rangeMax);
      // Assigning our custom text to the tooltip value.
      args.text = min + ' - ' + max;
    }
  };

  render() {
    return (
      <div
        style={{
          height: '70px',
          width: '100%',
          padding: '2%',
          backgroundColor: '#f1f3f4',
        }}
      >
        <div style={{ position: 'relative', margin: '0, 2%' }}>
          <SliderComponent
            id="range_slider"
            type="Range"
            min={this.props.sliderMin}
            max={this.props.sliderMax}
            value={[this.props.rangeMin, this.props.rangeMax]}
            tooltip={{
              cssClass: 'e-tooltip-slideshow-slide',
              placement: 'Before',
              isVisible: true,
              showOn: 'Always',
            }}
            tooltipChange={this.onRangeTooltipChangeHandler}
            ticks={{
              placement: 'After',
              largeStep: 20,
              smallStep: 10,
              showSmallTicks: true,
            }}
            renderingTicks={(args: any) => {
              args.text = formatMinuteAndSeconds(args.value);
            }}
            ref={range_slider => {
              this.rangeSlider = range_slider;
            }}
            change={this.onTimeRangeSliderChange}
          />
          <SliderComponent
            id="slide_slider"
            min={this.props.sliderMin}
            max={this.props.sliderMax}
            value={[this.props.sliderValue, this.props.sliderValue]}
            tooltip={{
              cssClass: 'e-tooltip-slideshow-slide',
              placement: 'Before',
              isVisible: true,
            }}
            tooltipChange={this.onTimeTooltipChangeHandler}
            limits={{
              enabled: true,
              minStart: this.props.rangeMin,
              minEnd: this.props.rangeMax,
            }}
            ref={slide_slider => {
              this.timeSlider = slide_slider;
            }}
            change={this.onTimeSliderChange}
          />
        </div>
      </div>
    );
  }
}

export interface DurationProps {
  seconds: number;
}

export const Duration = (props: DurationProps) => {
  const { seconds } = props;
  return (
    <time dateTime={`P${Math.round(seconds)}S`}>
      {formatMinuteAndSeconds(seconds)}
    </time>
  );
};
