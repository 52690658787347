import React from 'react';
import { User } from '../../../models/User';
import { Slide } from '../../../models/Slide';
import SlideView, { InlineSlideTextEditInfo, ViewMode } from '../../Gide/SlideView/SlideView';
import { Article } from '../../../models/Article';
import { SlideSelectionInfo, SlideSelectionOperation, MultiSlideSelectionMethod, SlideSelectionTransferType } from '../../../models/SlideSelectionInfo';
import {
  getSlidesInCollapsedRanges,
  populateDisplayTitleForEndSlides,
  generateSlideRangeList,
  slideIsValidForMultiEdit,
  getColumnClassName,
} from '../../../utils/helperFunctions';
import { isNullOrUndefined } from 'util';
import { MultiSlideSelectionDetail, SlideInlineViewSlidesDictionary, ExpandCollapseSlideAdditionInfo } from '../../../reducers/article';
import classNames from 'classnames';
import { ChildArticleType } from '../../../models/ArticleLayoutEnum';
import { SlideFilterState } from '../SlideFilter/SlideFilter';
import { contains } from 'ramda';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';

export interface SlideListProps {
  currentUser: User;
  classes?: string;
  slides: Slide[];
  view: string;
  viewMode: ViewMode;
  nextViewMode?: ViewMode;
  article?: Article;
  articleEditMode?: string;
  viewOnly?: boolean;
  slideSelectionModeDetail?: SlideSelectionInfo;
  collapsedSlides?: Slide[];
  displayCollapsedHeaders?: boolean;
  slideIdOfSlideOutOfOrder?: string;
  slideIdOfSlideInAdvancedEditMode?: string;
  multiSlideSelectionDetail?: MultiSlideSelectionDetail;
  wideScreenEditModeEnabled?: boolean;
  inlineSlideTextEditInfo?: InlineSlideTextEditInfo;
  inlineEditedSlide?: Slide;
  slideInlineViewSlidesDictionary?: SlideInlineViewSlidesDictionary;
  slideFilter?: SlideFilterState;
  disableSlideExpiration?: boolean;
  hideThreeDotMenu?: boolean;
  expandCollapseSlideAdditionInfo?: ExpandCollapseSlideAdditionInfo;
  setNextViewMode?: (mode: ViewMode) => void;
  collapseSlides?: (slide: Slide) => void;
  showNotification?: (toasterMessageInfo: ToasterMessageInfo) => void;
  openModal?: (payload: any) => void;
  onSubmit?: (payload: any) => void;
  onEditingText?: (slide: Slide) => void;
  onEnterAdvancedEditForSlide?: (slideId: string) => void;
  deleteSlide?: (slideId: string) => void;
  multiSlideSelectionChanged?: (slideId: string, checked: boolean) => void;
  updateSlideNumber?: (number: number) => void;
  enterSlideSelectionMode?: (payload: SlideSelectionInfo) => void;
  updateSlideSelectionMethod?: (payload: {multiSlideSelectionMethod: MultiSlideSelectionMethod}) => void;
  updateSlideSelectionOperationMode?: (payload: {transferType: SlideSelectionTransferType}) => void;
  copySlides?: (payload: any) => void;
  moveSlides?: (selectedSlideIds: string[], moveToPosition: number) => void;
  setInlineSlideTextEditInfo?: (inlineSlideTextEditInfo?: InlineSlideTextEditInfo) => void;
  updateSlideWithInlineEdits?: (payload: any) => void;
  onSetViewMode?: (mode: ViewMode) => void;
  setMultiSelectSlideView?: (payload: any) => void;
  openChildArticleModal?: (slide: Slide, childArticleTypeList: ChildArticleType[], selectedArticleType: ChildArticleType, viewOnly?: boolean) => void;
  onAuthorizeSlide?: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  onUnauthenticatedAuthorizeSlide?: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  exitSlideSelectionMode?: () => void;
  addSlideAddition?: (slide: Slide, additionType: string) => void;
  updateChildGidesOnSlideForGideType?: (payload: {slideId: string, articleType: string, slides: Slide[]}) => void;
  disableInlineTextEditing?: boolean;
}

export const SlideList = (props: SlideListProps) => {
  const filterCollapsedSlides = (
    slides: Slide[],
    collapsedSlides: Slide[],
    slideRangeList: any,
    displayCollapsedHeaders: boolean,
  ) => {
    const slidesInCollapsedRanges = getSlidesInCollapsedRanges(
      slides,
      slideRangeList,
      collapsedSlides,
      displayCollapsedHeaders,
    );
    return slides.filter(s => !slidesInCollapsedRanges.includes(s));
  };
  const updatedSlides = populateDisplayTitleForEndSlides(props.slides);
  const slideRangeList = generateSlideRangeList(props.slides);
  const getFilteredSlides = (slides: Slide[], slideFilter: SlideFilterState): Slide[] => {
    return slides.filter((s: Slide) => contains(s.slideType, slideFilter.selectedSlideTypes)
    );
  }

  const nonCollapsedSlides =
    !props.collapsedSlides || props.collapsedSlides.length <= 0
      ? updatedSlides
      : filterCollapsedSlides(
          updatedSlides,
          props.collapsedSlides,
          slideRangeList,
          props.displayCollapsedHeaders ? props.displayCollapsedHeaders : false,
        );

  const filteredSlides = props.slideFilter
    ? getFilteredSlides(nonCollapsedSlides, props.slideFilter)
    : nonCollapsedSlides;

  const rowStyle = (columnCount: number, layout: string) => {
    let style = {};
    if (columnCount) {
      const width = 100 / columnCount;
      let widthString = `${width}%`;
      if (layout === 'NONRESPONSIVE') {
        style = { width: widthString };
      }
    }
    return style;
  };

  const wrap = (el: any, classes: string, i: any) => {
    return (
      <div className={classes} key={i}>
        {el}
      </div>
    );
  };

  const getRows = (filteredSlides: Slide[]) => {
    let rows: any[] = [];
    let row: any;
    let columnCur: any;
    filteredSlides.forEach((s, i) => {
      if (s.slideType === 'COLUMN') {
        if (s.data.type === 'END') {
          row = null;
          columnCur = null;
        } else {
          if (!row) {
            row = {
              column: s,
            };
            if (s.data.type === 'WRAP') {
              row.slides = [];
            } else {
              row.columns = [];
              const columnCount = parseInt(s.data.columns, 10);
              for (var c = 0; c < columnCount; c++) {
                row.columns.push({ slides: [] });
              }
              columnCur = 0;
            }
            rows.push(row); // Prepared row to accumlate slides in.
          } else {
            columnCur++;
          }
        }
      } else {
        if (row) {
          if (row.slides) {
            row.slides.push(s); // Accumulate slides in a prepared row.
            // Line below was causing error due to row.columns[columnCur] not existing.
            // Check here if some columns are not accumulating correctly.
          } else if (
            row.columns &&
            row.columns[columnCur] &&
            row.columns[columnCur].slides
          ) {
            row.columns[columnCur].slides.push(s);
          }
        } else {
          rows.push({ slide: s }); // Let the slide stand on its own.
        }
      }
    });
    return rows;
  };

  if (!filteredSlides) {
    return null;
  }
  if (props.view !== 'website') {
    return (
      <>
        {filteredSlides.map(slide => (
        <div key={slide.id}
          className={`${props.classes}${props.slideSelectionModeDetail
            && props.slideSelectionModeDetail.operation === SlideSelectionOperation.Delete
            && props.slideSelectionModeDetail.originSlideId === slide.id
            ? ' selectedSlide'
            : ''}`
          }
        >
          <SlideView
            slide={slide}
            disableExpiration={props.disableSlideExpiration === true}
            viewingInline={false}
            slidePosition={slide.position}
            currentUser={props.currentUser}
            view={props.view}
            viewMode={props.viewMode}
            nextViewMode={props.nextViewMode}
            article={props.article}
            articleEditMode={props.articleEditMode}
            onEditingText={props.onEditingText}
            viewOnly={props.viewOnly}
            totalNumberOfSlides={props.slides ? props.slides.length : 0}
            slideSelectionModeDetail={props.slideSelectionModeDetail}
            multiSlideSelectionDetail={props.multiSlideSelectionDetail}
            isOutOfOrder={
              !isNullOrUndefined(props.slideIdOfSlideOutOfOrder) &&
              props.slideIdOfSlideOutOfOrder === slide.id
            }
            slideInAdvancedEditMode={
              !isNullOrUndefined(props.slideIdOfSlideInAdvancedEditMode) &&
              props.slideIdOfSlideInAdvancedEditMode === slide.id
            }
            canPerformMultipleEditAction={
              props.viewMode === 'SLIDE' &&
              props.multiSlideSelectionDetail &&
              props.multiSlideSelectionDetail.showMultiSelectSlideView &&
              slideIsValidForMultiEdit(
                slide,
                props.multiSlideSelectionDetail
                  ? (props.multiSlideSelectionDetail.mode as string)
                  : '',
              )
            }
            showNotification={props.showNotification}
            wideScreenEditModeEnabled={props.wideScreenEditModeEnabled}
            inlineSlideTextEditInfo={props.inlineSlideTextEditInfo}
            inlineEditedSlide={props.inlineEditedSlide}
            inlineViewSlides={props.slideInlineViewSlidesDictionary && props.slideInlineViewSlidesDictionary[slide.id]
              ? props.slideInlineViewSlidesDictionary[slide.id].inlineViewSlides
              : undefined
            }
            expandCollapseSlideAdditionInfo={props.expandCollapseSlideAdditionInfo}
            collapsed={
              props.collapsedSlides &&
              !isNullOrUndefined(
                props.collapsedSlides.find(cs => slide.id === cs.id),
              )
            }
            hideThreeDotMenu={props.hideThreeDotMenu}
            openModal={props.openModal}
            onSubmit={props.onSubmit}
            onEnterAdvancedEditForSlide={props.onEnterAdvancedEditForSlide}
            deleteSlide={props.deleteSlide}
            multiSlideSelectionChanged={props.multiSlideSelectionChanged}
            updateSlideNumber={props.updateSlideNumber}
            enterSlideSelectionMode={props.enterSlideSelectionMode}
            updateSlideSelectionMethod={props.updateSlideSelectionMethod}
            updateSlideSelectionOperationMode={props.updateSlideSelectionOperationMode}
            copySlides={props.copySlides}
            moveSlides={props.moveSlides}
            setInlineSlideTextEditInfo={props.setInlineSlideTextEditInfo}
            updateSlideWithInlineEdits={props.updateSlideWithInlineEdits}
            collapseSlides={props.collapseSlides}
            setNextViewMode={props.setNextViewMode}
            onSetViewMode={props.onSetViewMode}
            setMultiSelectSlideView={props.setMultiSelectSlideView}
            openChildArticleModal={props.openChildArticleModal}
            onAuthorizeSlide={props.onAuthorizeSlide}
            onUnauthenticatedAuthorizeSlide={props.onUnauthenticatedAuthorizeSlide}
            exitSlideSelectionMode={props.exitSlideSelectionMode}
            addSlideAddition={props.addSlideAddition}
            disableInlineTextEditing={props.disableInlineTextEditing}
            updateChildGidesOnSlideForGideType={props.updateChildGidesOnSlideForGideType}
          />
        </div>
        ))}
      </>
    );
  } else {
    const rows = getRows(filteredSlides);
    return (
      <div>
        {rows.map((row, i) => {
          if (row.column) {
            // Slides within Wrap Column slides.
            if (row.slides) {
              return (
                <div key={i} className="row">
                  {row.slides.map((s: Slide, i: any) => {
                    let slide = (
                      <div
                        className={`slideWrap${props.slideSelectionModeDetail
                          && props.slideSelectionModeDetail.operation === SlideSelectionOperation.Delete
                          && props.slideSelectionModeDetail.originSlideId === s.id
                          ? ' selectedSlide'
                          : ''}`
                        }
                        style={rowStyle(
                          row.column.data.columns,
                          row.column.data.layout,
                        )}
                      >
                        <SlideView
                          slide={s}
                          disableExpiration={props.disableSlideExpiration === true}
                          slidePosition={s.position}
                          currentUser={props.currentUser}
                          view={props.view}
                          viewMode={props.viewMode}
                          nextViewMode={props.nextViewMode}
                          article={props.article}
                          articleEditMode={props.articleEditMode}
                          onEditingText={props.onEditingText}
                          viewOnly={props.viewOnly}
                          slideSelectionModeDetail={
                            props.slideSelectionModeDetail
                          }
                          multiSlideSelectionDetail={props.multiSlideSelectionDetail}
                          isOutOfOrder={
                            !isNullOrUndefined(
                              props.slideIdOfSlideOutOfOrder,
                            ) && props.slideIdOfSlideOutOfOrder === s.id
                          }
                          slideInAdvancedEditMode={
                            !isNullOrUndefined(
                              props.slideIdOfSlideInAdvancedEditMode,
                            ) && props.slideIdOfSlideInAdvancedEditMode === s.id
                          }
                          canPerformMultipleEditAction={
                            props.viewMode === 'SLIDE' &&
                            props.multiSlideSelectionDetail &&
                            props.multiSlideSelectionDetail
                              .showMultiSelectSlideView &&
                            slideIsValidForMultiEdit(
                              s,
                              props.multiSlideSelectionDetail
                                ? (props.multiSlideSelectionDetail
                                    .mode as string)
                                : '',
                            )
                          }
                          showNotification={props.showNotification}
                          wideScreenEditModeEnabled={
                            props.wideScreenEditModeEnabled
                          }
                          inlineSlideTextEditInfo={
                            props.inlineSlideTextEditInfo
                          }
                          inlineEditedSlide={props.inlineEditedSlide}
                          collapsed={
                            props.collapsedSlides &&
                            !isNullOrUndefined(
                              props.collapsedSlides.find(cs => s.id === cs.id),
                            )
                          }
                          hideThreeDotMenu={props.hideThreeDotMenu}
                          openModal={props.openModal}
                          onSubmit={props.onSubmit}
                          onEnterAdvancedEditForSlide={
                            props.onEnterAdvancedEditForSlide
                          }
                          deleteSlide={props.deleteSlide}
                          multiSlideSelectionChanged={
                            props.multiSlideSelectionChanged
                          }
                          updateSlideNumber={props.updateSlideNumber}
                          enterSlideSelectionMode={
                            props.enterSlideSelectionMode
                          }
                          updateSlideSelectionMethod={props.updateSlideSelectionMethod}
                          updateSlideSelectionOperationMode={props.updateSlideSelectionOperationMode}
                          copySlides={props.copySlides}
                          moveSlides={props.moveSlides}
                          setInlineSlideTextEditInfo={
                            props.setInlineSlideTextEditInfo
                          }
                          updateSlideWithInlineEdits={
                            props.updateSlideWithInlineEdits
                          }
                          collapseSlides={props.collapseSlides}
                          setNextViewMode={props.setNextViewMode}
                          onSetViewMode={props.onSetViewMode}
                          openChildArticleModal={props.openChildArticleModal}
                          setMultiSelectSlideView={props.setMultiSelectSlideView}
                          onAuthorizeSlide={props.onAuthorizeSlide}
                          onUnauthenticatedAuthorizeSlide={props.onUnauthenticatedAuthorizeSlide}
                          exitSlideSelectionMode={props.exitSlideSelectionMode}
                          addSlideAddition={props.addSlideAddition}
                          disableInlineTextEditing={props.disableInlineTextEditing}
                          updateChildGidesOnSlideForGideType={props.updateChildGidesOnSlideForGideType}
                        />
                      </div>
                    );
                    if (row.column.data.layout === 'RESPONSIVE') {
                      let colSizeClass = 12 / row.column.data.columns;
                      let className = `col-lg-${colSizeClass} col-xs-${colSizeClass *
                        3}`;
                      const classes = classNames(className, {
                        borders: row.column.data.showBorders,
                      });
                      return wrap(slide, classes, i);
                    } else {
                      return slide;
                    }
                  })}
                </div>
              );
            } else if (row.columns) {
              // Slides and columns within Fixed Column slides.
              return (
                <div key={i} className="row">
                  {row.columns.map((c: any, i: any) => {
                    let slides = c.slides.map((sl: Slide, j: any) => {
                      return (
                        <SlideView
                          key={`$column{i}:slide${j}`}
                          slide={sl}
                          disableExpiration={props.disableSlideExpiration === true}
                          slidePosition={sl.position}
                          currentUser={props.currentUser}
                          view={props.view}
                          viewMode={props.viewMode}
                          nextViewMode={props.nextViewMode}
                          article={props.article}
                          articleEditMode={props.articleEditMode}
                          onEditingText={props.onEditingText}
                          viewOnly={props.viewOnly}
                          slideSelectionModeDetail={
                            props.slideSelectionModeDetail
                          }
                          multiSlideSelectionDetail={props.multiSlideSelectionDetail}
                          isOutOfOrder={
                            !isNullOrUndefined(
                              props.slideIdOfSlideOutOfOrder,
                            ) && props.slideIdOfSlideOutOfOrder === sl.id
                          }
                          slideInAdvancedEditMode={
                            !isNullOrUndefined(
                              props.slideIdOfSlideInAdvancedEditMode,
                            ) &&
                            props.slideIdOfSlideInAdvancedEditMode === sl.id
                          }
                          canPerformMultipleEditAction={
                            props.viewMode === 'SLIDE' &&
                            props.multiSlideSelectionDetail &&
                            props.multiSlideSelectionDetail
                              .showMultiSelectSlideView &&
                            slideIsValidForMultiEdit(
                              sl,
                              props.multiSlideSelectionDetail
                                ? (props.multiSlideSelectionDetail
                                    .mode as string)
                                : '',
                            )
                          }
                          showNotification={props.showNotification}
                          wideScreenEditModeEnabled={
                            props.wideScreenEditModeEnabled
                          }
                          inlineSlideTextEditInfo={
                            props.inlineSlideTextEditInfo
                          }
                          inlineEditedSlide={props.inlineEditedSlide}
                          collapsed={
                            props.collapsedSlides &&
                            !isNullOrUndefined(
                              props.collapsedSlides.find(cs => sl.id === cs.id),
                            )
                          }
                          hideThreeDotMenu={props.hideThreeDotMenu}
                          openModal={props.openModal}
                          onSubmit={props.onSubmit}
                          onEnterAdvancedEditForSlide={
                            props.onEnterAdvancedEditForSlide
                          }
                          deleteSlide={props.deleteSlide}
                          multiSlideSelectionChanged={
                            props.multiSlideSelectionChanged
                          }
                          updateSlideNumber={props.updateSlideNumber}
                          enterSlideSelectionMode={
                            props.enterSlideSelectionMode
                          }
                          updateSlideSelectionMethod={props.updateSlideSelectionMethod}
                          updateSlideSelectionOperationMode={props.updateSlideSelectionOperationMode}
                          copySlides={props.copySlides}
                          moveSlides={props.moveSlides}
                          setInlineSlideTextEditInfo={
                            props.setInlineSlideTextEditInfo
                          }
                          updateSlideWithInlineEdits={
                            props.updateSlideWithInlineEdits
                          }
                          collapseSlides={props.collapseSlides}
                          setNextViewMode={props.setNextViewMode}
                          onSetViewMode={props.onSetViewMode}
                          openChildArticleModal={props.openChildArticleModal}
                          setMultiSelectSlideView={props.setMultiSelectSlideView}
                          onAuthorizeSlide={props.onAuthorizeSlide}
                          onUnauthenticatedAuthorizeSlide={props.onUnauthenticatedAuthorizeSlide}
                          exitSlideSelectionMode={props.exitSlideSelectionMode}
                          addSlideAddition={props.addSlideAddition}
                          disableInlineTextEditing={props.disableInlineTextEditing}
                          updateChildGidesOnSlideForGideType={props.updateChildGidesOnSlideForGideType}
                        />
                      );
                    });
                    let colSizeClass;
                    if (row.column.data.columnDists) {
                      // Convert % distribution to a round number between 1 and 12, since that's what numbers are valid in the grid system classes.
                      let num = row.column.data.columnDists[i].dist / 100 * 12;
                      colSizeClass = Math.round(num);
                    } else {
                      colSizeClass = 12 / row.column.data.columns;
                    }
                    let verticalAlignmentClass = 'columnVerticalAlignmentTop';
                    if (row.column.data.columnVerticalAligments) {
                      verticalAlignmentClass = getColumnClassName(
                        row.column.data.columnVerticalAligments[i]
                          .verticalAlignment,
                      );
                    }
                    if (row.column.data.layout === 'RESPONSIVE') {
                      let className = `col-lg-${colSizeClass} col-xs-${colSizeClass *
                        3} ${verticalAlignmentClass}`;
                      const classes = classNames(className, {
                        borders: row.column.data.showBorders,
                      });

                      return wrap(slides, classes, i);
                    } else {
                      let className = `col-xs-${colSizeClass} ${verticalAlignmentClass}`;
                      const classes = classNames(className, {
                        borders: row.column.data.showBorders,
                      });
                      return (
                        <div className={classes} key={i}>
                          {slides}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            } else {
              return null;
            }
          } else {
            // Plain old slide, but wrapped in row object for uniformity
            // with the other rows which the adapter getRows calculated.
            return (
              <div key={i} className="row"
                style={{backgroundColor: row.slide.backgroundColor}}
              >
                <div className="col-xs-12">
                  <SlideView
                    slide={row.slide}
                    disableExpiration={props.disableSlideExpiration === true}
                    slidePosition={row.slide.position}
                    currentUser={props.currentUser}
                    view={props.view}
                    viewMode={props.viewMode}
                    nextViewMode={props.nextViewMode}
                    article={props.article}
                    articleEditMode={props.articleEditMode}
                    onEditingText={props.onEditingText}
                    viewOnly={props.viewOnly}
                    slideSelectionModeDetail={props.slideSelectionModeDetail}
                    multiSlideSelectionDetail={props.multiSlideSelectionDetail}
                    isOutOfOrder={
                      !isNullOrUndefined(props.slideIdOfSlideOutOfOrder) &&
                      props.slideIdOfSlideOutOfOrder === row.slide.id
                    }
                    slideInAdvancedEditMode={
                      !isNullOrUndefined(
                        props.slideIdOfSlideInAdvancedEditMode,
                      ) &&
                      props.slideIdOfSlideInAdvancedEditMode === row.slide.id
                    }
                    canPerformMultipleEditAction={
                      props.viewMode === 'SLIDE' &&
                      props.multiSlideSelectionDetail &&
                      props.multiSlideSelectionDetail
                        .showMultiSelectSlideView &&
                      slideIsValidForMultiEdit(
                        row.slide,
                        props.multiSlideSelectionDetail
                          ? (props.multiSlideSelectionDetail.mode as string)
                          : '',
                      )
                    }
                    showNotification={props.showNotification}
                    wideScreenEditModeEnabled={props.wideScreenEditModeEnabled}
                    inlineSlideTextEditInfo={props.inlineSlideTextEditInfo}
                    inlineEditedSlide={props.inlineEditedSlide}
                    collapsed={
                      props.collapsedSlides &&
                      !isNullOrUndefined(
                        props.collapsedSlides.find(
                          cs => row.slide.id === cs.id,
                        ),
                      )
                    }
                    hideThreeDotMenu={props.hideThreeDotMenu}
                    openModal={props.openModal}
                    onSubmit={props.onSubmit}
                    onEnterAdvancedEditForSlide={
                      props.onEnterAdvancedEditForSlide
                    }
                    deleteSlide={props.deleteSlide}
                    multiSlideSelectionChanged={
                      props.multiSlideSelectionChanged
                    }
                    updateSlideNumber={props.updateSlideNumber}
                    enterSlideSelectionMode={props.enterSlideSelectionMode}
                    updateSlideSelectionMethod={props.updateSlideSelectionMethod}
                    updateSlideSelectionOperationMode={props.updateSlideSelectionOperationMode}
                    copySlides={props.copySlides}
                    moveSlides={props.moveSlides}
                    setInlineSlideTextEditInfo={
                      props.setInlineSlideTextEditInfo
                    }
                    updateSlideWithInlineEdits={
                      props.updateSlideWithInlineEdits
                    }
                    collapseSlides={props.collapseSlides}
                    setNextViewMode={props.setNextViewMode}
                    onSetViewMode={props.onSetViewMode}
                    setMultiSelectSlideView={props.setMultiSelectSlideView}
                    openChildArticleModal={props.openChildArticleModal}
                    onAuthorizeSlide={props.onAuthorizeSlide}
                    onUnauthenticatedAuthorizeSlide={props.onUnauthenticatedAuthorizeSlide}
                    exitSlideSelectionMode={props.exitSlideSelectionMode}
                    addSlideAddition={props.addSlideAddition}
                    disableInlineTextEditing={props.disableInlineTextEditing}
                    updateChildGidesOnSlideForGideType={props.updateChildGidesOnSlideForGideType}
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
};
