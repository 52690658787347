import React, { Component } from 'react';
import { SlideList } from '../../Gide/SlideList/SlideList';
import {
  getChildArticleTypeName,
  ChildArticleType,
} from '../../../models/ArticleLayoutEnum';
import { ChildArticleEditorInfo } from '../../../models/ChildArticleEditorInfo';
import { getChildArticleForSlide } from '../../../utils/helperFunctions';
import { User } from '../../../models/User';
import { Article } from '../../../models/Article';
import agent from '../../../agent';
import { Slide, SlideModel } from '../../../models/Slide';
import { contains } from 'ramda';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import GideImage from '../../Shared/Image/GideImage';
import { ViewMode } from '../../Gide/SlideView/SlideView';

export interface ChildArticleEditorProps {
  article: Article;
  childArticleEditInfo: ChildArticleEditorInfo;
  currentUser: User;
  view?: string;
  slide: Slide;
  viewMode: ViewMode;
  nextViewMode?: ViewMode;
  articleEditMode?: string;
  slideSelectionModeDetail?: SlideSelectionInfo;
  collapsedSlides?: Slide[];
  collapseSlides?: (slide: Slide) => void;
  addInquirySlide?: (
    payload: { slide: SlideModel; article: Article; ownerSlide: Slide },
  ) => void;
  setChildSubArticleAndSlides?: (
    payload: { article: Article; slides: Slide[] },
  ) => void;
  exitChildArticleEditor?: () => void;
  enterChildArticleEditor?: (editorInfo: any) => void;
}
export default class ChildArticleEditorSlide extends Component<ChildArticleEditorProps> {
  shouldComponentUpdate(nextProps: ChildArticleEditorProps) {
    const shouldUpdate =
      nextProps.article !== this.props.article ||
      nextProps.childArticleEditInfo !== this.props.childArticleEditInfo ||
      nextProps.slide !== this.props.slide;

    return shouldUpdate;
  }
  render() {
    const displayTabsForPrivateNotesCommentsQuestions =
      this.props.childArticleEditInfo.article.type ===
        getChildArticleTypeName(ChildArticleType.Comments) ||
      this.props.childArticleEditInfo.article.type ===
        getChildArticleTypeName(ChildArticleType.PrivateNotes) ||
      this.props.childArticleEditInfo.article.type ===
        getChildArticleTypeName(ChildArticleType.Questions);
    const isInquiryResponseArticleType =
      this.props.childArticleEditInfo.article.type ===
        getChildArticleTypeName(ChildArticleType.Comments) ||
      this.props.childArticleEditInfo.article.type ===
        getChildArticleTypeName(ChildArticleType.Questions);

    return (
      <div className="childArticleEditorSlide">
        {/* Questions, Comments, and Private Notes Tabs */}
        {displayTabsForPrivateNotesCommentsQuestions && (
          <div className="childArticleEditorTabs">
            {this.props.article.allowSlideComments &&
              !this.props.article.pauseSlideComments && (
                <div
                  className="tab"
                  onClick={async () => {
                    if (this.props.enterChildArticleEditor) {
                      this.props.enterChildArticleEditor(
                        await getChildArticleForSlide(
                          this.props.childArticleEditInfo.ownerSlide,
                          ChildArticleType.Comments,
                        ),
                      );
                    }
                  }}
                >
                  <div
                    className={`tabDisplay ${
                      this.props.childArticleEditInfo.article.type ===
                      getChildArticleTypeName(ChildArticleType.Comments)
                        ? ' active'
                        : ''
                    }`}
                  >
                    <GideImage 
                      className="tabIcon"
                      src="/icons/slide-footer-actions/comments.svg"
                      alt="Comments"
                    />
                    <span>Comments</span>
                  </div>
                </div>
              )}
            {this.props.article.allowSlideQuestions &&
              !this.props.article.pauseSlideQuestions && (
                <div
                  className="tab"
                  onClick={async () => {
                    if (this.props.enterChildArticleEditor) {
                      this.props.enterChildArticleEditor(
                        await getChildArticleForSlide(
                          this.props.childArticleEditInfo.ownerSlide,
                          ChildArticleType.Questions,
                        ),
                      );
                    }
                  }}
                >
                  <div
                    className={`tabDisplay ${
                      this.props.childArticleEditInfo.article.type ===
                      getChildArticleTypeName(ChildArticleType.Questions)
                        ? ' active'
                        : ''
                    }`}
                  >
                    <GideImage 
                      className="tabIcon"
                      src="/icons/slide-footer-actions/question.svg"
                      alt="Questions"
                    />
                    <span>Questions</span>
                  </div>
                </div>
              )}
            <div
              className="tab"
              onClick={async () => {
                if (this.props.enterChildArticleEditor) {
                  this.props.enterChildArticleEditor(
                    await getChildArticleForSlide(
                      this.props.childArticleEditInfo.ownerSlide,
                      ChildArticleType.PrivateNotes,
                    ),
                  );
                }
              }}
            >
              <div
                className={`tabDisplay ${
                  this.props.childArticleEditInfo.article.type ===
                  getChildArticleTypeName(ChildArticleType.PrivateNotes)
                    ? ' active'
                    : ''
                }`}
              >
                <GideImage 
                  className="tabIcon"
                  src="/icons/slide-footer-actions/notes.svg"
                  alt="Notes"
                />
                <span>Notes</span>
              </div>
            </div>
          </div>
        )}
        {/* Displays for a viewer to add a new Question or Comment */}

        {this.props.childArticleEditInfo.ownerSlide &&
          this.props.childArticleEditInfo.ownerSlide.author &&
          // (this.props.childArticleEditInfo.article.type ===
          //   getChildArticleTypeName(ChildArticleType.Questions) ||
          //   this.props.childArticleEditInfo.article.type ===
          //     getChildArticleTypeName(ChildArticleType.Comments)) &&
          isInquiryResponseArticleType &&
          this.props.currentUser.username !==
            this.props.childArticleEditInfo.ownerSlide.author.username && (
            <div className="addInquiryActionContainer">
              <div
                className="addInquiryButton"
                onClick={async () => {
                  // create a question slide and add the
                  // create 2 articles (1 for the question, 1 for the response)
                  const inquiryResponseSlide: SlideModel = {
                    slideType: 'INQUIRYRESPONSE',
                    position: this.props.childArticleEditInfo.slideNumber,
                    data: {
                      type: 'INQUIRYRESPONSE',
                    },
                    author: this.props.childArticleEditInfo.ownerSlide.author,
                  };
                  const response: any = await agent.Slides.createInquiryResponseSlide(
                    // TODO:  change any to CreateInquiryResponseResponse when agent is typed.
                    this.props.childArticleEditInfo.article.id,
                    inquiryResponseSlide,
                  );

                  // Here we need to add the new inquiry to the childarticle
                  if (this.props.addInquirySlide) {
                    this.props.addInquirySlide({
                      slide: response.inquirySlide,
                      article: response.inquiryArticle,
                      ownerSlide: response.ownerSlide,
                    });
                  }
                  // then make it active
                  // this.props.setChildSubArticleAndSlides({
                  //   article: response.inquiryArticle,
                  //   slides: [],
                  // });
                }}
              >
                <span className="addInquiryLabel">+</span>
              </div>
            </div>
          )}

        <SlideList
          slides={this.props.childArticleEditInfo.slides}
          currentUser={this.props.currentUser}
          classes={`slideAttachmentEditor`}
          view={this.props.view ? this.props.view : ''}
          viewMode={this.props.viewMode}
          nextViewMode={this.props.nextViewMode}
          article={this.props.article}
          articleEditMode={this.props.articleEditMode}
          slideSelectionModeDetail={this.props.slideSelectionModeDetail}
          collapsedSlides={this.props.collapsedSlides}
          collapseSlides={this.props.collapseSlides}
          // isAttachmentSlideList={true}
          // className={`slideAttachmentEditor`}
          // parentArticleType={this.props.slide.slideType}
        />
        {this.props.childArticleEditInfo.slides.length < 1 && (
          <span className="emptyChildArticleContent">
            {this.props.slide.data.editorType === 'attachments' && (
              <span>
                Add Attachments using the input bar. Click Finish when done.
              </span>
            )}
            {this.props.slide.data.editorType === 'notes' && (
              <span>
                Add Notes using the input bar. Click Finish when done.
              </span>
            )}
          </span>
        )}
        {!contains(
          this.props.slide.slideType,
          ['COLLAPSE', 'AUTHORIZEBLOCK', 'SLIDESHOW'],
        ) && (
          <div className="completeChildArticleEditingContainer">
            <div
              className="finishChildArticleEditingButton"
              onClick={this.props.exitChildArticleEditor}
            >
              <span style={{ marginLeft: '5px' }}>Finish</span>
              {/* TODO: Conditionally show icon based on editorType */}
              {/* TODO: <GideImage src={`/icons/slide-footer-actions/${getChildArticleTypeName(this.props.slide.data.editorType)}-active.svg`} */}
              <GideImage 
                src={`/icons/slide-footer-actions/attachments-active.svg`}
                alt="finish"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
