import React, { CSSProperties } from 'react';

import AudioPlayer, { AudioPlayerType } from '../../../media/AudioPlayer/AudioPlayer';
import { ArticleSlideReferenceHeader } from '../../../Shared/ArticleSlideReferenceHeader/ArticleSlideReferenceHeader';
import { SlideFileReference } from '../../../../models/SlideFileReference';
import { ArticleSummary } from '../../../../models/ArticleSummary';
import {  extractHostname, getSlideFileUrl, getMediaLinkUrl, getEmbedMediaSource, getPreviewOverlayIcon, SlideType } from '../../../../utils/helperFunctions';
import { EmbedMediaSource } from '../../../../models/EmbedMediaSource';
import SlideFileCaption from '../../../Slides/SlideFileCaption/SlideFileCaption';
import { Slide } from '../../../../models/Slide';
import GideImage from '../../../Shared/Image/GideImage';
import { AudioSlideFile } from '../../../../models/SlideFile';

export interface AudioViewProps {
  audioFile: AudioSlideFile;
  slide?: Slide;
  className?: string;
  isReferenceType: boolean;
  hidden?: boolean;
  autoPlay: boolean;
  style?: CSSProperties;
  audioEnded?: (this: GlobalEventHandlers, ev: Event) => void;
}

export default function AudioView(props: AudioViewProps) {
  const embedIcon = props.audioFile && props.audioFile.type === 'EMBED'
    ? getEmbedMediaSource(props.audioFile.url as string)
    : EmbedMediaSource.NotFound;

  const embedIconName = embedIcon === EmbedMediaSource.Spotify
    ? 'spotify'
    : embedIcon === EmbedMediaSource.SoundCloud
      ? 'soundcloud'
      : embedIcon === EmbedMediaSource.YouTube
        ? 'youtube'
        : 'unknown';

  const mediaLinkUrlInfo = getMediaLinkUrl(props.audioFile.url as string);
  const isValidMediaLinkUrl = mediaLinkUrlInfo.type === 'audio';

  return (
    <div className={`audioViewContainer${props.hidden ? ' hidden' : ''}${props.className ? ' ' + props.className : ''}`}
      style={props.style}
    >
      {(props.audioFile.type === 'RECORDING' || props.audioFile.type === 'UPLOAD') && (
        <AudioPlayer
          playerType={AudioPlayerType.AudioSlide}
          fileTypeIcon={getPreviewOverlayIcon(SlideType.Audio, props.audioFile.type)}
          autoPlay={props.autoPlay}
          src={props.audioFile.url as string}
          audioEnded={props.audioEnded}
          color={'blue'}
        />
      )}
      {props.audioFile.type === 'EMBED' && isValidMediaLinkUrl && (
        <>
          <iframe
            title="Embed Frame"
            src={mediaLinkUrlInfo.url}
            width="100%"
            height="80"
            frameBorder="0"
            allowTransparency
          ></iframe>

          <div className={`embedCaption${embedIcon === EmbedMediaSource.NotFound ? ' hidden' : ''}`}>
            <div className="embedCaption">
              <GideImage
                src={`/icons/socialmedia/${embedIconName}.svg`}
                alt={`${embedIconName}`}
              />
              <span className="TEXTSUBTITLEblackmedium-emphasisleft">{`visit ${embedIconName}.com`}</span>
            </div>
          </div>
        </>
      )}
      {props.audioFile.type === 'EMBED' && !isValidMediaLinkUrl && (
        "Invalid Audio Link"
      )}
      {props.isReferenceType && props.audioFile.slideFileReference && (
        <div className='slideReferenceContainer'>
          <ArticleSlideReferenceHeader
            articleAuthorImageUrl={((props.audioFile.slideFileReference as SlideFileReference).article as ArticleSummary).authorImage}
            articleAuthor={((props.audioFile.slideFileReference as SlideFileReference).article as ArticleSummary).author}
            articleTitle={((props.audioFile.slideFileReference as SlideFileReference).article as ArticleSummary).title}
            articleImage={((props.audioFile.slideFileReference as SlideFileReference).article as ArticleSummary).image}
            createdAt={((props.audioFile.slideFileReference as SlideFileReference).article as ArticleSummary).createdAt}
            numberOfSlides={((props.audioFile.slideFileReference as SlideFileReference).article as ArticleSummary).numberOfSlides}
          />
          <AudioView
            audioFile={{
              ...((props.audioFile.slideFileReference as SlideFileReference).referenceFile as AudioSlideFile),
              onSelect: props.audioFile.onSelect,
              autoPlay: props.audioFile.autoPlay,
              autoAdvance: props.audioFile.autoAdvance
            }}
            autoPlay={false} // TODO: Need to handle playing multiple audio files in succession.
            isReferenceType={false}
          />
        </div>
      )}
      {props.isReferenceType && !props.audioFile.slideFileReference && (
        <div className='noLongerAvailable'>
          <GideImage src="/icons/delete-when-replaced/content-unavailable.png"
            alt="content is no longer available"
          />
        </div>
      )}

      <SlideFileCaption
        slideType="Audio"
        slideFile={props.audioFile}
        linkedFileAttribution={props.audioFile.type === 'LINK' ? extractHostname(getSlideFileUrl(props.audioFile)) : undefined}
      />
    </div>
  );
}
