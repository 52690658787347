import React, { CSSProperties } from 'react';
import { Article } from '../../../models/Article';
import styles from './_gide-preview-list.module.scss';
import GideTilePreview from '../../Shared/Previews/GidePreview/GideTilePreview/GideTilePreview';
import isNil from 'ramda/es/isNil';
import { User } from '../../../models/User';

export interface GidePreviewListProps {
  currentUser?: User | null;
  gidePreviewTitle?: string;
  gideList: Article[];
  searchText?: string;
  style?: CSSProperties;
}

export default function GidePreviewList(props: GidePreviewListProps) {
  const buildGidePreviewGrid = (gideList: Article[]) => {
    return gideList.map((gide: Article) => (
      <GideTilePreview        
        id={gide.id}
        slug={gide.slug}
        key={gide.slug}
        image={!isNil(gide.slideZero) && !isNil(gide.slideZero.data) ? (gide.slideZero.data.image as string) 
          : !isNil(gide.image) ? gide.image : ''}
        title={gide.title}
        description={gide.description}
        authorImage={gide.author.image}
        author={gide.author.username}
        language={!isNil(gide.language) ? (gide.language as string) : 'English'}
        views={gide.viewCount}
        slideCount={!isNil(gide.slideCount) ? (gide.slideCount as number) : 0}
        gideCost={gide.price}
        priceType={gide.priceType}
        isOwner={!isNil(props.currentUser) && props.currentUser.username === gide.author.username}
        subscriptionPlan={gide.subscribeType}
        createdAt={new Date(gide.createdAt)}
        updatedAt={new Date(gide.updatedAt)}
        isReadOnly={!Boolean(props.currentUser)}
      />
    ));
  };

  return (
    <div className={styles.gidePreviewListContainer} style={props.style}>     
      <div className={`${styles.gidePreviewList} ${props.searchText ? styles.searchResults : ''}`}>
        <div className={styles.gidePreviewTitleContainer}>
          {props.gidePreviewTitle && (
            <div className={styles.gidePreviewTitle}>
              {props.gidePreviewTitle}
            </div>
          )}
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
        </div>
        <div className={styles.gidePreviewGrid}>
          {buildGidePreviewGrid(props.gideList)}
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
          <div className={styles.emptyGidePreview} />
        </div>
      </div>
    </div>
  );
}
