import React, { CSSProperties } from 'react';
import styles from './_gide-blog-image-top-preview.module.scss';
import GideImage from '../../../Image/GideImage';
import icons from '../../../../../assets/icons';
import Avatar from '../../../Avatar/Avatar';

export interface GideBlogImageTopPreviewProps {
  image: string;
  url: string;
  author: string;
  authorImage: string;
  views?: number;
  title?: string;
  createdAt: string;
  description?: string;
  style?: CSSProperties;
}

export function GideBlogImageTopPreview(props: GideBlogImageTopPreviewProps) {
  let previewLink: any;
  return (
    <div
      className={styles.gideBlogImageTopPreviewContainer}
      style={props.style}
      onClick={() => {
        if (previewLink) {
          previewLink.click();
        }
      }}
    >
      <div className={styles.linkPreviewImage}>
        <GideImage className={styles.primaryImage} src={props.image} alt={props.title ? props.title : ''} />
        <div className={styles.circleBackground}>
          <icons.Nav_Logo_LogoIconMd width={48} height={48} color="var(--WHITES-NORMAL-1000)" />
        </div>
      </div>
      <div className={styles.linkPreviewDetails}>
        <div className={styles.linkPreviewTitleContainer}>
          <div className={styles.linkPreviewTitle}>{props.title ? props.title : ''}</div>
          <div className={styles.linkPreviewDescription}>{props.description ? props.description : ''}</div>
          <div className={styles.footerInfo}>
              <Avatar
                avatarImage={props.authorImage}
                imgStyle={{ width: '24px', height: '24px' }}
                alt={props.author}
                displayOuterBorder={false}
                outerBorderColor="var(--COLOR-PRIMARY-700)"
              />
              <span
                className="TEXTSUBTITLE-2blackhigh-emphasisleft"
                style={{ marginLeft: '5px' }}
              >
                {props.author}
              </span>
              <span
                className="TEXTSUBTITLE-2blackmedium-emphasisleft"
                style={{ marginLeft: '5px' }}
              >
                {`3.2 stars • ${props.views ? props.views : 0} views • Created ${props.createdAt} ago`}
              </span>
            </div>
        </div>
      </div>
    </div>    
  );
}
