import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { NotificationType } from '../../../constants/strings';
import styles from './_NotificationInline.module.scss';
import classNames from 'classnames';
import icons from '../../../assets/icons';
import { useNotification, useNotificationDispatch } from './NotificationContext';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 } from 'uuid';


export interface NotificationInlineProps {
  target?: string;
  notification?: ToasterMessageInfo;
  onClose?: (notification: ToasterMessageInfo) => void;
  preventDispatch?: boolean;
  timeoutMilliseconds?: number;
}

export const NotificationInline: React.FC<NotificationInlineProps> = (props) => {
  const notificationContext = useNotification();
  const dispatch = useNotificationDispatch();

  const isMatchingTarget = () => {
    return !notificationContext.notification?.target && !props.target
      || notificationContext.notification?.target == props.target;
  };

  const clear = () => {
    if (!props.preventDispatch && isMatchingTarget()) {
      dispatch({ type: 'clear' });
    }
  }
  useEffect(() => {
    clear();
    return () => {
      clear();
    }
  }, []);

  const notification = props.notification 
    || (isMatchingTarget() ? notificationContext.notification : undefined);

  const isZeroTimeout = (notification?.timeoutMilliseconds !== undefined && notification.timeoutMilliseconds === 0);

  const key = useMemo(() => {
    return v4();
  }, [notification]);

  useEffect(() => {
    const timeoutMilliseconds = notification?.timeoutMilliseconds || props.timeoutMilliseconds;
    if (notification && timeoutMilliseconds) {
      const timeout = setTimeout(() => { clear(); }, timeoutMilliseconds);
      return () => { 
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }    
  }, [notification]);
  
  const close = useCallback(() => {
    clear();
    if (props.onClose && notification) {
      props.onClose(notification);
    }
  }, [dispatch, notification, props.preventDispatch, props.onClose]);

  const types = {
    [NotificationType.ERROR]: styles.error,
    [NotificationType.INFO]: styles.info,
    [NotificationType.MESSAGE]: styles.message,
    [NotificationType.SUCCESS]: styles.success,
    [NotificationType.WARNING]: styles.warning,
  };

  const type = (notification && types[notification.type]) || styles.error;

  const child = notification && !isZeroTimeout && (
    <motion.div className={styles.container}
      key={key}
      positionTransition
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      >
      <div className={classNames(styles.notification, type)}>
        <div className={styles.title}>{notification.title}</div>
        <div className={styles.message}>{notification.message}</div>
        <div className={styles.closeButton}>
          <icons.Nav_Exit_Alternative
            title="Close message"
            onClick={close}
          />
        </div>
      </div>
    </motion.div>
  );

  return (
    <AnimatePresence>
      {child}
    </AnimatePresence>
  );
}
