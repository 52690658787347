export interface Author {
  connections: any[];
  favorites: any[];
  following: boolean;
  id: string;
  username: string;
  image: string;
  verified: boolean;
  views: any[];
}

export const NullAuthor: Author = {
  connections: [],
  favorites: [],
  following: false,
  id: '',
  username: '',
  image: '',
  verified: true,
  views: []
}

export const MockAuthor: Author = {
  connections: [],
  favorites: [],
  following: false,
  id: '1',
  username: '',
  image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFs55HhEt6w0kYAHuY89BygETYobDZWOyn1rInBkxqBc_lIWPs&s',
  verified: true,
  views: []
}
