import React from 'react';
import { Slide, SlideUpdateInfo } from "../../../models/Slide";
import { SlideEditorModalProps, SlideEditorProps, LoadingInfo, QuestionInfo } from "../../modals/SlideEditorModal/SlideEditorModal";
import classNames from "classnames";
import SlideView from "../../Gide/SlideView/SlideView";
import { CircleIconButton } from "../../Shared/CircleIconButton/CircleIconButton";
import { ToasterMessageInfo } from "../../../models/ToasterMessageInfo";
import styles from "./_Common.module.scss";
import { MediaChooserAction } from '../../DataEntry/CreationProcess/MediaChooser/MediaChooser';
import { SlideFileProcessorInfo } from '../../../reducers/FileProcessing/fileProcessing';
import { Article } from '../../../models/Article';
import { User, AnonymousUser } from '../../../models/User';
import ImageView from '../../Viewers/ImageViewers/ImageView/ImageView';
import VideoViewer from '../../Viewers/VideoViewers/VideoViewer/VideoViewer';
import { isMobileDevice } from '../../../utils/helperFunctions';
import { NotificationType } from '../../../constants/strings';
import LinkView from '../../Viewers/LinkViewers/LinkView/LinkView';
import { deprecate } from 'util';
import { ImageFile } from '../../../models/SlideFile';


export interface ModeHelperFunctions {
  // getSlideEditorModalProps: (context: CommonContext) => SlideEditorModalProps,
  // getContext: (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => React.ReactNode,
  getDerivedState: (context: CommonContext) => CommonDerivedState,
};

export const CommonModes = {
  New: 'New' as 'New',
  Preview: 'Preview' as 'Preview',
  Camera: 'Camera' as 'Camera',
  Uploading: 'Uploading' as 'Uploading',
  Link: 'Link' as 'Link',
  Edit: 'Edit' as 'Edit',
  Format: 'Format' as 'Format',
  Delete: 'Delete' as 'Delete',
  Tag: 'Tag' as 'Tag',
  Dimensions: 'Dimensions' as 'Dimensions',
  Attribute: 'Attribute' as 'Attribute',
  Audio: 'Audio' as 'Audio',
  ChooseGideFile: 'ChooseGideFile' as 'ChooseGideFile',
};

export interface CommonSlideEditorProps extends SlideEditorProps {
  set?: { mode: string };
  startCamera?: boolean;
  file?: ImageFile;
}

export interface CommonState {
  mode: string;
  slide: Slide;
  mediaChooserAction?: MediaChooserAction;
  hiddenMediaChooserAction?: MediaChooserAction;
  tagList: string[];
  selectedItemIndex: number;
  loadingInfo?: LoadingInfo;
  url?: string;
}

export interface CommonDerivedState extends CommonState {
  slideEditorModalProps: SlideEditorModalProps;
  content: React.ReactNode;
  theme: { mode: string };
}

export interface CommonSlideEditorModalProps {
  slide: Slide;
  article: Article;
  currentUser: User;
  position: number;
  onSubmitSlide: (payload: SlideUpdateInfo) => Promise<void>;
  closeModal: () => void;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
  addMediaFileProcessingInfo: (slidefileProcessingInfoList: SlideFileProcessorInfo[]) => void;
  onAnswerQuestion: () => void,
  onCloseAllModals:  () => void,
  onShowPreview:  () => void,
  displaySlideAttribution: boolean,
  question?: QuestionInfo,
  showDeleteFile: boolean,
  tagList: string[],
  onSlideUpdated: (slide: Slide) => void,
  onDisplaySlideWidthSettings: () => void,
  onHashtagClicked: () => void,
  onShowAudioCaptionControls: () => void,
  onHideAudioCaptionControls: () => void,
}

export interface Context<TState extends CommonState, TProps extends CommonSlideEditorProps = CommonSlideEditorProps> {
  props: TProps;
  state: TState;
  setState: (value: React.SetStateAction<TState>) => void;
  commonSlideEditorModalProps: CommonSlideEditorModalProps;
  isImageSlide: boolean;
  isVideoSlide: boolean;
  isLinkSlide: boolean;
}

export type CommonContext = Context<CommonState>;

export const getSlideView = (slide: Slide) => (
  <div
    className={classNames(styles.slideView, 'website')}
    style={{
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: 'calc(100vh - 102px)',
      paddingBottom: "94px",
    }}
  >
    <SlideView
      slide={slide as Slide}
      slidePosition={(slide as Slide).position}
      currentUser={AnonymousUser}
      viewMode={'SCROLL'}
      viewOnly={true}
      view={'website'}
      disableExpiration={true}
      ignoreTheme={true}
    />
  </div>
);

export const getFileView = (context: CommonContext, className?: string) => {
  const { props, state } = context;
  const selectedFile = state.slide.data.files[state.selectedItemIndex];
  if (context.isImageSlide) {
    return (
      <div className={`imageEditorSelectedImageOuterContainer${selectedFile.type === "LINK" ? ' linkImage' : ''}`}>
        <ImageView
          imageFile={selectedFile}
          isReferenceType={selectedFile.type === 'SLIDEFILE'}
          className={classNames("containImage", className)}
        />
      </div>
      );
  } else if (context.isVideoSlide) {
    return (
      <div className="videoEditorSelectedVideoOuterContainer">
        <VideoViewer
          videoFile={selectedFile}
          className={classNames(className)}
        />
      </div>
    );
  } else if (context.isLinkSlide) {
    return (
      <div className="linkSlideEditorEditContainer">
        <LinkView
          linkFile={selectedFile}
          isReferenceType={selectedFile.type === 'SLIDE'}
          currentUser={props.currentUser}
          view={''}
          viewMode={'SLIDE'}
          overrideWidth={state.slide.width}
        />
      </div>
    );
  } else {
    return "ERROR";
  }
}

export const HeaderTitleAndIcon = (title: string, icon: string, color: 'black' | 'white', iconColor?: 'orange' | 'blue') => {
  return (
    <div className={`headerActionsFlexEnd TEXTSUBTITLE${color}high-emphasisleft`}>
      <div
        style={{
          marginRight: '6px',
        }}
      >
        {title}
      </div>
      <CircleIconButton
        width={30}
        height={30}
        alt={title}
        backgroundColor={iconColor === 'blue' || color === 'white' ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-PRIMARY-600)'}
        iconCssClass="whites-normal-1000-svg"
        image={icon}
      />
    </div>
  );
};
/**
 * deprecated. Use HeaderTitleAndIcon instead
 */
export const getHeaderTitleAndIcon = (title: string, icon: string, color: 'black' | 'white', iconColor?: 'orange' | 'blue') => {
  return HeaderTitleAndIcon(title, icon, color, iconColor);
}
export const getCreateActionCallbacks = (context: CommonContext, fileType: "images" | "videos") => {
  const { state, setState, commonSlideEditorModalProps:{ showNotification } } = context;

  const allowedToAdd = () => {
    // Default for Mobile. TODO
    if (state.slide.data.files.length > 6) {
      showNotification({
        message: `You can only add 7 ${fileType}.`,
        type: NotificationType.INFO,
      });
      return false;
    } else {
      return true;
    }
  };
  const actionBrowseFile = () => {
    if (allowedToAdd()){
      setState({
        ...state,
        mediaChooserAction: undefined,
        hiddenMediaChooserAction: { type: 'UploadFile' },
      });
    }
  };
  const actionChooseSlide = () => {
    if (allowedToAdd()){
      setState({
        ...state,
        mode: CommonModes.ChooseGideFile,
        mediaChooserAction: undefined,
        hiddenMediaChooserAction: undefined,
      });
    }
  };
  const actionUseLink = () => {
    if (allowedToAdd()){
      setState({
        ...state,
        mode: CommonModes.Link,
        url: '',
        mediaChooserAction: undefined,
        hiddenMediaChooserAction: undefined,
      });
    }
  };
  const actionStartCamera = () => {
    if (allowedToAdd()){
      if (!isMobileDevice()) {
        setState({
          ...state,
          mode: CommonModes.Camera,
          mediaChooserAction: { type: 'StartCamera' },
          hiddenMediaChooserAction: undefined,
        });
      } else if ((window as any).cordova) {
        setState({
          ...state,
          mode: CommonModes.Uploading,
          mediaChooserAction: undefined,
          hiddenMediaChooserAction: { type: 'StartCamera' },
        })
      } else {
        setState({
          ...state,
          mediaChooserAction: undefined,
          hiddenMediaChooserAction: { type: 'StartCamera' },
        });
      }
    }
  };

  return {
    actionBrowseFile,
    actionChooseSlide,
    actionUseLink,
    actionStartCamera
  };
};
