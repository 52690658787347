import React from 'react';
import { Article } from '../models/Article';
import { User } from '../models/User';
import { urlForArticle } from '../utils/helperFunctions';
import Link from './Link';
import GideImage from './Shared/Image/GideImage';

interface BlogTileProps {
  article: Article;
  onDeleteArticle: () => void;
  currentUser: User;
  absolutePaths: boolean;
}
export default function BlogTile (props:BlogTileProps) {
  return (
    <Link to={urlForArticle(props.article)}>
      <div className="blogTile">
        <GideImage className="blogImage"
          src={props.article.image? props.article.image : ''} 
          alt="blog pic"
        />
        <div className="blogDetail">
          <span className="blogTitle">
            {props.article.title}
          </span>
          <span className="blogDescription">
            {props.article.description}
          </span>
          <span className="blogFooter">
            <span className="flexRowFlexNormal">
              <GideImage className="blogAuthorImage"
                alt="blog author"
                src={props.article.author.image} />
              {props.article.author.username}
            </span>
            <span className="flexRowFlexNormal" style={{ marginLeft: '8px' }}>
              <GideImage src="/icons/nav/eye/eye-open.svg"
                className="pointer color-secondary-500-svg"
                alt={`${props.article.viewerList ? props.article.viewerList.length : 0} viewers`}
              />
              <span style={{ marginLeft: '4px', width: '53px' }}>
                {props.article.viewerList ? props.article.viewerList.length : 0}
              </span>
            </span>
            <span className="flexRowFlexNormal" style={{ marginLeft: '2px' }}>
              <GideImage src="/icons/nav/thumbs-up.svg"
                className="pointer color-secondary-500-svg"
                alt={`${props.article.favoritesCount} likes`}
              />
              <span style={{ marginLeft: '2px' }}>{props.article.favoritesCount}</span>
            </span>
          </span>
        </div>
      </div>
    </Link>
  );
}
