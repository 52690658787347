import React, { Component } from 'react';
import { List, Checkbox } from 'semantic-ui-react';
import { reject, append, contains } from 'ramda';
import agent from '../../agent';
import {
  CHECKABLE_LISTITEM_USER,
  CHECKABLE_LISTITEM_EXHIBIT
} from '../../constants/strings';
import * as deepDiff from 'deep-diff';

class ListSlide extends Component {

  constructor(props) {
    super(props);
    this.state = { checkedItems: [] };
    this.load = this._load.bind(this);

    this.handleCheckChange = index => async (ev, data) => {
      // If listType is EXIBIT, only the user that created the slide can save his/her checked items.
      // If listType is USER, anyone can save his/her checked items.
      const needsSave =
        (this.props.currentUser && this.props.currentUser.username === this.props.slide.author.username
          && this.props.slide.data.listType === CHECKABLE_LISTITEM_EXHIBIT)
          || (this.props.currentUser && this.props.slide.data.listType === CHECKABLE_LISTITEM_USER);

      let checkedItems;
      if (data.checked === true) {
        checkedItems = append(index, this.state.checkedItems);
      } else {
        checkedItems = reject(i => i === index, this.state.checkedItems);
      }
      if (needsSave) {
        try {
          let resp = await agent.Slides.updateChecks(this.props.slide.id, checkedItems);
          this.setState({ checkedItems: resp.checkedData.checkedItems });
        } catch (e) {
          console.log('error', e);
        }
      } else {
        this.setState( {checkedItems});
      }
    };

    // Items can be checked by anyone if listType is not EXIBIT. If listType is EXIBIT then
    // only the user that created the slide can check the list items.
    this.disableCheckingListItems = () => {
      return (this.props.slide.data.listType === CHECKABLE_LISTITEM_EXHIBIT
      && this.props.currentUser && this.props.currentUser.username !== this.props.slide.author.username);
    };
  }

  async _load() {
    // Only need to fetch the checked items if they can be persisted for the given ListSlide's listType
    const needsCheckedItemIndexes =
      (this.props.slide.data.listType === CHECKABLE_LISTITEM_EXHIBIT)
        || this.props.slide.data.listType === CHECKABLE_LISTITEM_USER;

    if (this.props.currentUser && needsCheckedItemIndexes) {
      try {
          const resp = await agent.Slides.getChecks(this.props.slide.id);
          this.setState({ checkedItems: resp.checkedItems });
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    // This means I can clear the checks because the slide data was modified and checks were cleared on the server
    // This only applies to slides that are of type exhibit

    // TODO: Need to discuss with Grant how to handle this. The issue is that when a user makes any changes
    // to a list slide's (EXHBIT) data (caption, title, items) the checks need to be cleared.
    if (this.props.slide.slideType === 'LIST' && (this.props.slide.data.listType === CHECKABLE_LISTITEM_EXHIBIT
        || this.props.slide.data.listType === CHECKABLE_LISTITEM_USER) &&
        this.props.currentUser && this.props.currentUser.username === this.props.slide.author.username) {
      const changes = deepDiff.diff(prevProps.slide.data, this.props.slide.data);
      if (changes) {
        this.setState({ checkedItems: [] });
      }
    }
  }

  render() {
    const { slide } = this.props;
    const numbered = slide.data.listType === 'NUMBERED';
    return (
      <div className="paddedCard">
        <h4 style={{ textAlign: 'center' }}>
          {slide.data.title || 'List'}
        </h4>
        <List {...(numbered ? { ordered: true } : { bulleted: false })}>
          {slide.data.items.map((item, i) => {
            return (
              <List.Item key={i}>
                <List.Content>
                  {!numbered ? (
                    <Checkbox label={item.name}
                      disabled={this.disableCheckingListItems()}
                      onChange={this.handleCheckChange(i).bind(this)}
                      checked={contains(i, this.state.checkedItems)} />
                  ) : (
                      <span>{item.name}</span>
                    )}
                  <p>{item.comment}</p>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }
}

export default ListSlide;
