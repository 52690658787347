import { Link } from 'react-router-dom';
import ListErrors from './ListErrors';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';

import agent from '../agent';
import {
  UPDATE_FIELD_AUTH,
  REGISTER,
  REGISTER_PAGE_UNLOADED,
  REGISTER_PAGE_LOADED,
} from '../constants/actionTypes';

const mapStateToProps = state => (
  { 
    ...state.auth,
    config: state.common.config
  }
);

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onChangeUsername: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),
  onSubmit: (username, email, password, accessCode) => {
    const payload = agent.Auth.register(username, email, password, accessCode);
    dispatch({ type: REGISTER, payload });
  },
  onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
  onRegisterPageLoaded: () => dispatch ({type: REGISTER_PAGE_LOADED}),
});

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabled: true,  // TODO use backend's active Config instance
      accessCode: '',
    };

    this.handleRefUsername = c => {
      this.refUsername = c;
    };

    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.changeUsername = ev => this.props.onChangeUsername(ev.target.value);
    this.submitForm = (username, email, password) => ev => {
      ev.preventDefault();
      if (this.state.enabled) {
        this.props.onSubmit(username, email, password, this.state.accessCode);
      } else {
        return alert('Sorry, registration is not currently open.');
      }
    };
    this.props.onRegisterPageLoaded();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { enabled } = this.state;
    const email = this.props.email;
    const password = this.props.password;
    const username = this.props.username;

    return (
      <div className="auth-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">Sign Up</h1>
              <p className="text-xs-center">
                <Link to="/login">Have an account?</Link>
              </p>
              {!enabled && (
                <p className="text-xs-center">
                  Sorry, registration is not currently open.
                </p>
              )}

              <ListErrors errors={this.props.errors} />

              {this.props.config && this.props.config.signupType === 'APPROVED_ONLY' &&
                <p className="text-xs-center">
                  <Input
                    fluid
                    type="password"
                    placeholder="Enter early access code"
                    value={this.state.accessCode}
                    onChange={(ev, data) =>
                      this.setState({ accessCode: data.value })
                    }
                    name="accessCode"
                    autoComplete="off"
                  />
                </p>
              }

              {enabled && (
                <form onSubmit={this.submitForm(username, email, password)}>
                  <fieldset>
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Username"
                        value={this.props.username || ''}
                        onChange={this.changeUsername}
                        ref={this.handleRefUsername}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg"
                        type="email"
                        placeholder="Email"
                        value={this.props.email || ''}
                        onChange={this.changeEmail}
                      />
                    </fieldset>
                    <fieldset className="form-group">
                      <input
                        className="form-control form-control-lg"
                        type="password"
                        placeholder="Password"
                        value={this.props.password || ''}
                        onChange={this.changePassword}
                      />
                    </fieldset>
                    <button
                      className="btn btn-lg btn-primary pull-xs-right"
                      type="submit"
                      disabled={this.props.inProgress || !this.state.enabled}
                    >
                      Sign up
                    </button>
                  </fieldset>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
