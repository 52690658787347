import React, { Component } from 'react';
import { TextBoxComponent, ChangedEventArgs } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent, RadioButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import agent from '../agent';
import { append, reject, contains } from 'ramda';
import GideImage from './Shared/Image/GideImage';
import { Channel } from '../models/Channel';
import { Slide } from '../models/Slide';

export interface BaseSearchElement {
  id: string;
  imageUrl: string;
  displayName: string;
}
export interface GideSearchElement extends BaseSearchElement {
  authorImageUrl: string;
  authorUsername: string;
  description?: string;
  descriptionSlide?: Slide;
  slug: string;
  type: string;
  slidesLength?: number;
  viewCount?: number;
  isSelected?: boolean;
  createdAt: Date;
  updatedAt: Date;
}
export interface ChannelSearchElement extends GideSearchElement {
  public: boolean;
  website: boolean;
  numberOfGides: number;
  channel?: Channel;
  numberOfMembers: number;
}
export interface GideElementSearchProps {
  // API to get the user's search results
  loadGideElementSearchResults: (payload: {searchResults: BaseSearchElement[]}) => void;
  clearGideElementSearchResults: () => void;
  searchResults: BaseSearchElement[];
  action: (selectedItems: BaseSearchElement[], type: string) => void;
  actionLabel: string;
}
export interface GideElementSearchState {
  searchElementType: string;
  searchText?: string;
  selectedItemIds: string[];
  // gideSearchItems: BaseSearchElement[];
}
export class GideElementSearch extends Component<GideElementSearchProps, GideElementSearchState> {
  constructor(props: Readonly<GideElementSearchProps>) {
    super(props);
    this.state = {
      searchElementType: "Article",
      searchText: '',
      selectedItemIds: [],
    };
  }
  private performAction(): void {
    const selectedItems = this.props.searchResults.filter(sr => contains(sr.id, this.state.selectedItemIds));
    this.props.action(selectedItems, this.state.searchElementType);
  }
  public async searchGideElement() {
    const searchResults: BaseSearchElement[] = await agent.Users.search(this.state.searchElementType, { searchText: this.state.searchText });
    this.props.loadGideElementSearchResults({searchResults});
  }
  private setSelectedElementType(type: string) {
      this.setState({
        searchElementType: type,
      });
      this.props.clearGideElementSearchResults();
  }
  public toggleSearchResult(searchResult: BaseSearchElement, checked: boolean): void {
    if (checked === true) {
      this.setState({selectedItemIds: append(searchResult.id, this.state.selectedItemIds)});
    } else {
      this.setState({selectedItemIds: reject((id: string) => contains(id, this.state.selectedItemIds), this.state.selectedItemIds) });
    }
  }
  public searchResultsTemplate(searchResult: BaseSearchElement) {
    return (
      <span className={`searchResultItem`}>
        <GideImage src={`${searchResult.imageUrl}`}
          className={`shortcutImage ${"Article"}`}
          alt={`${searchResult.displayName}`} />
        <span className={`shortcutTitle ${"Article"}`} >{searchResult.displayName}</span>
      </span>
    );
  }
  public render() {
    return (
      <div className="gideElementSearchContainer">
        <div>
          <span className="searchType">
            <RadioButtonComponent
              checked={
                this.state.searchElementType === 'Article'
              }
              label="Gides"
              name="searchElementType"
              value="Article"
              change={() => this.setSelectedElementType("Article")}
            />
          </span>
          <span className="searchType">
            <RadioButtonComponent
              checked={
                this.state.searchElementType === 'User'
              }
              label="People"
              name="searchElementType"
              value="User"
              change={() => this.setSelectedElementType("User")}
            />
          </span>
          <span className="searchType">
            <RadioButtonComponent
              checked={
                this.state.searchElementType === 'Channel'
              }
              label="Channels"
              name="searchElementType"
              value="Channel"
              change={() => this.setSelectedElementType("Channel")}
            />
          </span>
        </div>
        <div className="searchElements">
          <TextBoxComponent placeholder="enter search ..." floatLabelType="Auto"
            value={this.state.searchText}
            change={(e: ChangedEventArgs | undefined) => this.setState({ searchText: e ? e.value : '' })}
          />
          <ButtonComponent
            style={{marginLeft: '10px'}}
            cssClass="e-outline"
            onClick={this.searchGideElement.bind(this)}

          >
            Search
          </ButtonComponent>
          {/* <Button onClick={this.searchGideElement.bind(this)}
          > Search
          </Button> */}
        </div>
        <div className="searchResults">
          {this.props.searchResults.map((searchResult: BaseSearchElement, key: number) => {
            return (
              <span key={key} className={`searchResultItem`}>
                <CheckBoxComponent
                  change={(e: any) => this.toggleSearchResult(searchResult, e.checked)}
                />
                <GideImage src={`${searchResult.imageUrl}`}
                  className={`shortcutImage ${this.state.searchElementType}`}
                  alt={`${searchResult.displayName}`} />
                <span className={`shortcutTitle ${this.state.searchElementType}`}>{searchResult.displayName}</span>
              </span>
            );
          })}

          {/* Contact Syncfusion. This is not rerendering as it should. */}
          {/* <ListViewComponent
            dataSource={this.props.searchResults}
            headerTitle='Shortcuts'
            showHeader={true}
            cssClass='e-list-template'
            template={this.searchResultsTemplate}
          >
          </ListViewComponent> */}
        </div>
        <ButtonComponent
          disabled={this.state.selectedItemIds.length < 1}
          onClick={this.performAction.bind(this)}
        >
          {this.props.actionLabel}
        </ButtonComponent>

      </div>
    );
  }
}

export default GideElementSearch;
