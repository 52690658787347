import React, { useState } from 'react';
import classNames from 'classnames';
import { ChildArticleType } from '../../../models/ArticleLayoutEnum';
import GideImage from '../../Shared/Image/GideImage';
export enum SlideEditMode {
  CleanEdit = 1,
  AdvancedEdit = 2,
  SlideSelection = 3,
  ViewOnly = 4,
  MultiEdit = 5,
}
export interface SlideEditPanelProps {
  editMode: SlideEditMode;
  className?: string;
  slideNumber?: number;
  questionCommentInfo?: { questionsCount: number; commentsCount: number };
  onViewChildGide?: (childGideType: ChildArticleType) => void;
  onEdit?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onAddBelow?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onMove: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onAttach?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onSelect?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onAddAbove?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onTransfer: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onCopy: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onMoveTo: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onUndo?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

export default function SlideEditPanel(props: SlideEditPanelProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const slidePanelActionClasses = classNames(
    'slideEditPanelAction',
    isExpanded || props.editMode === SlideEditMode.MultiEdit ? 'displayAllActions' : props.editMode === SlideEditMode.CleanEdit ? 'displayQuestionCommentIcons' : 'undefined',
    props.editMode === SlideEditMode.AdvancedEdit
      ? ' advancedEdit'
      : props.editMode === SlideEditMode.SlideSelection ? ' slideSelection' : ' cleanEdit',
  );
  const expandCollapseIcon =
    props.editMode === SlideEditMode.AdvancedEdit
      ? isExpanded ? '/icons/nav/arrow/arrow-up.svg' : '/icons/nav/arrow/arrow-down.svg'
      : isExpanded ? '/icons/nav/exit/alternative.svg' : '/icons/nav/edit/edit-main.svg';

  return (
    <div tabIndex={props.slideNumber} className={`slideEditPanelContainer${
          props.className ? ' ' + props.className : ''}${isExpanded ? ' expanded' : ''}${props.editMode === SlideEditMode.MultiEdit ? ' multiEdit' : ''}`
        }
        onBlur={() => setIsExpanded(false)}
      >
      <div className={`slideEditPanelActions${isExpanded ? ' expanded' : ''}${props.editMode === SlideEditMode.MultiEdit ? ' multiEdit' : ''}`}>
        {props.editMode !== SlideEditMode.MultiEdit && (
          <div className={`slideEditPanelActionRow${!isExpanded && props.editMode !== SlideEditMode.AdvancedEdit ? ' setFlexEnd' : ''}`}>
            {/* These will display if in advancedEdit mode or if expanded is true-(cleanEdit or advancedEdit) */}
            {props.editMode !== SlideEditMode.ViewOnly &&
              (isExpanded || props.editMode === SlideEditMode.AdvancedEdit) && (
                <>
                  <div className={slidePanelActionClasses}
                    onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                      setIsExpanded(false);
                      props.onEdit && props.onEdit(e);
                    }}
                  >
                    <GideImage
                      className="color-secondary-600-svg"
                      src="/icons/content-alteration/edit-slide.svg"
                      alt="edit slide"
                    />
                    <span>Edit slide</span>
                  </div>
                  <div className={slidePanelActionClasses}
                    onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                      setIsExpanded(false);
                      props.onAddBelow && props.onAddBelow(e);
                    }}
                  >
                    <GideImage
                      className="color-secondary-600-svg"
                      src="/icons/nav/arrow/add-below.svg"
                      alt="add slide below"
                    />
                    <span>Add below</span>
                  </div>
                  <div className={slidePanelActionClasses}
                    onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                      setIsExpanded(false);
                      props.onDelete(e);
                    }}
                  >
                    <GideImage
                      className="color-secondary-600-svg"
                      src="/icons/content-alteration/delete.svg"
                      alt="delete slide"
                    />
                    <span>Delete</span>
                  </div>
                  <div className={slidePanelActionClasses}
                    onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                      setIsExpanded(false);
                      props.onMove(e);
                    }}
                  >
                    <GideImage
                      className="color-secondary-600-svg"
                      src="/icons/nav/arrow/arrow-up-down.svg"
                      alt="move slide"
                    />
                    <span>Move</span>
                  </div>
                  <div className={slidePanelActionClasses}
                    onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                      setIsExpanded(false);
                      props.onAttach && props.onAttach(e);
                    }}
                  >
                    <GideImage
                      className="color-secondary-600-svg"
                      src="/icons/content-alteration/attach.svg"
                      alt="add attachments to slide"
                    />
                    <span>Attach</span>
                  </div>
                </>
              )}
            {/* Question and Comments indicator/navigate icons only show in clean edit */}
            {!isExpanded &&
              (props.editMode === SlideEditMode.CleanEdit || props.editMode === SlideEditMode.ViewOnly) &&
              (props.questionCommentInfo &&
                (props.questionCommentInfo.questionsCount > 0 || props.questionCommentInfo.commentsCount > 0)) && (
                <div className="slideAdditionsInfoContainer">
                  {props.questionCommentInfo.commentsCount > 0 && (
                    <div
                      className="slideAdditionItemDetail"
                      onClick={() => {
                        if (props.onViewChildGide) {
                          props.onViewChildGide(ChildArticleType.Comments);
                        }
                      }}
                    >
                      <span>
                        {props.questionCommentInfo.commentsCount} {props.questionCommentInfo.commentsCount > 1 ? 'comments' : 'comment'}
                      </span>
                      <GideImage src="/icons/content-alteration/comments.svg" alt="comments" className="color-secondary-600-svg" />
                    </div>
                  )}
                  {props.questionCommentInfo.questionsCount > 0 && (
                    <div
                      className="slideAdditionItemDetail"
                      onClick={() => {
                        if (props.onViewChildGide) {
                          props.onViewChildGide(ChildArticleType.Comments);
                        }
                      }}
                    >
                      <span>
                        {props.questionCommentInfo.questionsCount} {props.questionCommentInfo.questionsCount > 1 ? 'questions' : 'question'}
                      </span>
                      <GideImage src="/icons/content-alteration/questions.svg" alt="questions" className="color-secondary-600-svg" />
                    </div>
                  )}
                </div>
              )}
            {props.editMode !== SlideEditMode.ViewOnly && (
              <div className="expandCollapse">
                {props.editMode === SlideEditMode.AdvancedEdit && <span className="slideEditSlideNumber">{props.slideNumber}</span>}
                <GideImage
                  className={`color-secondary-600-svg${props.editMode === SlideEditMode.AdvancedEdit ? ' advancedMode' : ''}`}
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                  src={expandCollapseIcon}
                  alt={isExpanded ? 'collapse' : 'expand'}
                />
              </div>
            )}
          </div>
        )}
        {props.editMode !== SlideEditMode.MultiEdit &&
          isExpanded && (
            <div className="slideEditPanelActionRow row2">
              <div className={slidePanelActionClasses}
                onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                  setIsExpanded(false);
                  props.onSelect && props.onSelect(e);
                }}
              >
                <GideImage
                  className="color-secondary-600-svg"
                  src="/icons/content-alteration/checkbox/checkbox-active.svg"
                  alt="select"
                />
                <span>Select</span>
              </div>
              <div className={slidePanelActionClasses}
                onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                  setIsExpanded(false);
                  props.onAddAbove && props.onAddAbove(e);
                }}
              >
                <GideImage
                  className="color-secondary-600-svg"
                  src="/icons/nav/arrow/add-above.svg"
                  alt="add slide above"
                />
                <span>Add above</span>
              </div>
              <div
                className={slidePanelActionClasses}
                onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                  setIsExpanded(false);
                  props.onTransfer(e);
                }}
              >
                <div className="multiIcon">
                  <GideImage
                    style={{ width: '18px', height: '18px' }}
                    className="color-secondary-600-svg"
                    src="/icons/creationprocess/slide.svg"
                    alt="transfer slide to"
                  />
                  <GideImage src="/icons/nav/accordion/expanded.svg" alt="transfer slide to" />
                  <GideImage
                    style={{ width: '14px', height: '14px', marginLeft: '-10px' }}
                    src="/icons/nav/arrow/arrow-right.svg"
                    alt="transfer slide to"
                  />
                  <GideImage
                    style={{ width: '18px', height: '18px' }}
                    className="color-secondary-600-svg"
                    src="/icons/nav/logo/logo-icon-md.svg"
                    alt="transfer slide to"
                  />
                </div>
                <span>Transfer</span>
              </div>
              <div className={slidePanelActionClasses}
                onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                  setIsExpanded(false);
                  props.onCopy(e);
                }}
              >
                <GideImage
                  className="color-secondary-600-svg"
                  src="/icons/content-alteration/copy.svg"
                  alt="copy slide"
                />
                <span>Copy</span>
              </div>
              <div
                className={slidePanelActionClasses}
                onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                  setIsExpanded(false);
                  props.onMoveTo(e);
                }}
              >
                <div className="multiIcon">
                  <GideImage
                    style={{ width: '18px', height: '18px' }}
                    className="color-secondary-600-svg"
                    src="/icons/content-alteration/attach.svg"
                    alt="move attachments to gide"
                  />
                  <GideImage src="/icons/nav/arrow/arrow-right.svg" />
                  <GideImage
                    style={{ width: '18px', height: '18px' }}
                    className="color-secondary-600-svg"
                    src="/icons/nav/logo/logo-icon-md.svg"
                    alt="move attachments to gide"
                  />
                </div>
                <span>Move to</span>
              </div>
              <GideImage src="/icons/content-alteration/undo.svg" alt="undo" />
            </div>
          )}
        {props.editMode === SlideEditMode.MultiEdit && (
          <div className="slideEditPanelActionRow">
            <div className={slidePanelActionClasses}
              onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                props.onDelete(e);
              }}
            >
              <GideImage
                className="color-secondary-600-svg"
                src="/icons/content-alteration/delete.svg"
                alt="delete slide"
              />
              <span>Delete</span>
            </div>
            <div className={slidePanelActionClasses}
              onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                props.onMove(e);
              }}
            >
              <GideImage
                className="color-secondary-600-svg"
                src="/icons/nav/arrow/arrow-up-down.svg"
                alt="move slide"
              />
              <span>Move</span>
            </div>
            <div
              className={slidePanelActionClasses}
              onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                setIsExpanded(false);
                props.onTransfer(e);
              }}
            >
              <div className="multiIcon">
                <GideImage
                  style={{ width: '18px', height: '18px' }}
                  className="color-secondary-600-svg"
                  src="/icons/creationprocess/slide.svg"
                  alt="transfer slide to"
                />
                <GideImage src="/icons/nav/accordion/expanded.svg" alt="transfer slide to" />
                <GideImage
                  style={{ width: '14px', height: '14px', marginLeft: '-10px' }}
                  src="/icons/nav/arrow/arrow-right.svg"
                  alt="transfer slide to"
                />
                <GideImage
                  style={{ width: '18px', height: '18px' }}
                  className="color-secondary-600-svg"
                  src="/icons/nav/logo/logo-icon-md.svg"
                  alt="transfer slide to"
                />
              </div>
              <span>Transfer</span>
            </div>
            <div className={slidePanelActionClasses}
              onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                props.onCopy(e);
              }}
            >
              <GideImage
                className="color-secondary-600-svg"
                src="/icons/content-alteration/copy.svg"
                alt="copy slide"
              />
              <span>Copy</span>
            </div>
            <div
              className={slidePanelActionClasses}
              onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                props.onMoveTo(e);
              }}
            >
              <div className="multiIcon">
                <GideImage
                  style={{ width: '18px', height: '18px' }}
                  className="color-secondary-600-svg"
                  src="/icons/nav/logo/logo-icon-md.svg"
                  alt="move slides as attachment to slide"
                />
                <GideImage src="/icons/nav/arrow/arrow-right.svg" />
                <GideImage
                  style={{ width: '18px', height: '18px' }}
                  className="color-secondary-600-svg"
                  src="/icons/content-alteration/attach.svg"
                  alt="move slides as attachment to slide"
                />
              </div>
              <span>Move to</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
