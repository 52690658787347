import React, { useEffect } from 'react';
import { useScript } from '../../utils/useScript';
const createDOMPurify = require('dompurify');

const EmbedSlide = props => {
  const { slide } = props;
  useScript("https://platform.twitter.com/widgets.js");
  useScript("https://www.instagram.com/embed.js");

  useEffect(() => {
    window && window.instgrm 
    && window.instgrm.Embeds && window.instgrm.Embeds.process 
    && window.instgrm.Embeds.process();
  }, [props?.data?.body]);

  const body = slide?.data?.body;
  const sanitizedHtml = createDOMPurify.sanitize(body);
  let element = <span style={{ display: 'flex', justifyContent: 'center' }}
    dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;

  if (body) {
    const youtube = /"(https:\/\/www.youtube.com\/embed\/\S*)"/.exec(body);
    const facebook = /"(https:\/\/www.facebook.com\/plugins\/\S*)"/.exec(body);
    const spotify = /"(https:\/\/embed.spotify.com\/\S*)"/.exec(body);
    const twitter = /https:\/\/t.co\//.exec(body);
    const instagram = /https:\/\/www.instagram.com\/p\//.exec(body);

    if (youtube) {
      element = <span>
        <iframe width="100%" height="315" src={youtube[1]} frameBorderorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
      </span>
    } else if (facebook) {
      element = <span>
        <iframe src={facebook[1]} width="476" height="630" style={{border:'none', overflow:'hidden'}} scrolling="no" frameBorder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
      </span>
    } else if (spotify) {
      element = <span>
        <iframe src={spotify[1]} width="100%" height="80" frameBorder="0" allowTransparency="true"></iframe>
      </span>
    } else if (twitter) {
      // Twitter and Instagram can be embedded directly (we will load a script for them).
    } else if (instagram) {
      // Twitter and Instagram can be embedded directly (we will load a script for them).
    }
    
  }

  return (
    <div>
      {element}
    </div>
  );
};

export default EmbedSlide;
