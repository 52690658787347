/* eslint-disable no-redeclare */
const GOOGLE_MAPS_KEY = process.env.REACT_APP__GOOGLE_MAPS_KEY; // 'AIzaSyBHoY3osUZEmJB4abchGFuUCDupQ5T0k50';
export const GOOGLE_MAPS_URL_EXP = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAPS_KEY}`;
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.31&libraries=geometry,drawing,places&key=${GOOGLE_MAPS_KEY}`;

// const API_ROOT_LOCAL = 'http://localhost:3000/api';
// const API_ROOT_REMOTE_SLIDESWIPER_ARCHIVE = 'https://whispering-beyond-94415.herokuapp.com/api';
// const API_ROOT_REMOTE_SLIDESWIPER = 'https://obscure-headland-15197.herokuapp.com/api';
// const API_ROOT_REMOTE_DYVVEE = 'https://pacific-castle-94143.herokuapp.com/api';
// const API_ROOT_REMOTE_QA = 'https://fast-shelf-56553.herokuapp.com/api';

let api_root = process.env.REACT_APP__API_ROOT;
if (URL && typeof(URL) === "function") {
    // Check if api_root is a valid url; if not, then try to append it to current hostname.
    try {
        var valid_url = new URL(api_root);
    } catch (error) {
        var combined_api_root = `${document.location.protocol}//${document.location.hostname}${api_root}`;
        try {
            var valid_url = new URL(combined_api_root);
            api_root = combined_api_root;
            console.info(`'${api_root}' is being used as the API_ROOT.`);
        } catch (error) { 
            console.error(`'${combined_api_root}' is an invalid API_ROOT.\n\n${error}`);
        }
    }
}
export const API_ROOT = api_root;
