import React, { useState, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../../themes/custom-theme';
import { Channel } from '../../models/Channel';
import { ChannelEditorMode, ChannelEditorModeProps } from './Modes/ChannelEditorModesShared';
import { MediaFile } from '../../models/SlideFile';
import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { ChannelEditorModeCamera } from './Modes/ChannelEditorModeCamera';
import { ChannelEditorModeCrop } from './Modes/ChannelEditorModeCrop';
import { ChannelEditorModeDelete } from './Modes/ChannelEditorModeDelete';
import { ChannelEditorModeEdit } from './Modes/ChannelEditorModeEdit';


export interface ImageTitleInfo {
  image: string;
  title: string;
  description: string;
}
export interface ChannelEditorProps {
  channel?: ImageTitleInfo;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
  initialMode?: ChannelEditorMode;
  onSave: (image: MediaFile, title: string, description: string) => Promise<boolean>;
  onCancel: () => void;
};

export const ChannelEditor: React.FC<ChannelEditorProps> = (props) => {
  const [mode, setMode] = useState<ChannelEditorMode>(props.initialMode || 'New');
  const [image, setImage] = useState<MediaFile | undefined>(() => {
    if (props.channel?.image) {
      var mediaFile: MediaFile = {
        type: '',
        url: props.channel?.image,
      };
      return mediaFile;
    } else {
      return undefined;
    }
  });
  const [title, setTitle] = useState(props.channel?.title || '');
  const [description, setDescription] = useState(props.channel?.description || '');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const beginEditingTitle = useCallback(() => {
    setIsEditingTitle(true);
  }, [setIsEditingTitle]);
  const finishEditingTitle = useCallback((newTitle: string) => {
    setIsEditingTitle(false);
    setTitle(newTitle);
  }, [setIsEditingTitle, setTitle]);

  const theme = (mode === 'New'
    || mode === 'Camera'
    || mode === 'Uploading'
    || mode === 'Crop'
    || mode === 'CroppingImage')
    ? darkTheme 
    : lightTheme;

  const modes: Record<ChannelEditorMode, React.FC<ChannelEditorModeProps>> = {
    'New': ChannelEditorModeCamera,
    'Camera': ChannelEditorModeCamera,
    'Uploading': ChannelEditorModeCamera,
    'Crop': ChannelEditorModeCrop,
    'CroppingImage': ChannelEditorModeCrop,
    'Delete': ChannelEditorModeDelete,
    'Edit': ChannelEditorModeEdit,
  };

  const ModeComponent = modes[mode];
  const modeComponentProps: ChannelEditorModeProps = {
    onSave: props.onSave,
    onCancel: props.onCancel,
    showNotification: props.showNotification,
    mode,
    setMode,
    image,
    setImage,
    title,
    setTitle,
    description, 
    setDescription,
    isEditingTitle,
    beginEditingTitle,
    finishEditingTitle,
  };

  const content = <ModeComponent {...modeComponentProps} />;

  return (
    <ThemeProvider theme={theme}>
      {content}
    </ThemeProvider>
  );
}
