import React, { Component } from 'react';
import { connect } from 'react-redux';
import superagent from 'superagent';
import DropZone from 'react-dropzone';
import { Modal, Input, Image, Button, Icon, Loader } from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import { API_ROOT } from '../../constants/paths';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';

let dropZoneStyles = {
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};
const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class VideoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      muted: true,
      controls: false,
      loading: false,
      slide: {
        slideType: 'VIDEO',
        data: { audioCaption: null, files: [], caption: '' },
      },
      url: '',
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      const slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: this.props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      this.props.closeModal();
    };

    this.onSelect = idx => {
      let video = this.state.slide.data.files[idx];
      if (video.type === 'URL') {
        this.setState({
          ytURL: video.url,
          videoSrc: null,
          muted: false,
          controls: true,
        });
      } else if (video.type === 'UPLOAD' || video.type === 'RECORDED') {
        this.setState({
          videoSrc: video.url,
          ytURL: null,
          muted: false,
          controls: true,
        });
      }
    };

    this.onAddByUrl = this._onAddByUrl.bind(this);
    this.startVideo = this._startVideo.bind(this);
    this.stopVideo = this._stopVideo.bind(this);
    this.handleVideo = this._handleVideo.bind(this);
    this.onRemove = this._onRemove.bind(this);
    this.onRecord = this._onRecord.bind(this);

    this.onStartRecordClick = this._onStartRecordClick.bind(this);
    this.onStopRecordClick = this._onStopRecordClick.bind(this);
  }

  _onRemove(idx) {
    if (!window.confirm('Are you sure?')) return;
    let slide = Object.assign({}, this.state.slide);
    let files = slide.data.files.slice();
    files.splice(idx, 1);
    slide.data.files = files;
    this.setState({ videoSrc: null, ytURL: null, slide });
  }

  _onAddByUrl(e) {
    if (!this.state.url.length) return;
    let id, urlParts;
    if (this.state.url.indexOf('youtu.be/') >= 0) {
      // Matched format https://youtu.be/219L5lN3fpk
      urlParts = this.state.url.split('https://youtu.be/');
    } else if (this.state.url.indexOf('?v=') >= 0) {
      // Matched format https://www.youtube.com/watch?v=219L5lN3fpk
      urlParts = this.state.url.split('?v=');
    }
    id = urlParts[1];
    if (!id) {
      return alert('Please enter a valid YouTube URL.');
    }
    let url = `https://www.youtube.com/embed/${id}`;
    let file = {
      url: url,
      name: url,
      type: 'URL',
    };
    let slide = Object.assign({}, this.state.slide);
    slide.data.files = [...this.state.slide.data.files, file];
    this.setState({ slide, url: '' });
  }

  onDrop(acceptedFiles, rejectedFiles) {
    this.setState({ loading: true });
    acceptedFiles.forEach(file => {
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', file)
        .end((err, res) => {
          if (err) console.log(err);
          const f = {
            url: res.body.url,
            name: file.name,
            type: 'UPLOAD',
          };
          let slide = Object.assign({}, this.state.slide);
          slide.data.files = [...this.state.slide.data.files, f];
          this.setState({ slide, loading: false });
        });
    });
  }

  _startVideo() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      navigator.oGetUserMedia;
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { video: true },
        this.handleVideo,
        this.videoError,
      );
    }
  }

  _onRecord() {
    if (this.state.stream) {
      this.stopVideo();
    } else {
      this.startVideo();
    }
  }

  _stopVideo() {
    if (this.state.stream) {
      this.state.stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }
    this.setState({ stream: null, videoSrc: null });
  }

  _handleVideo(stream) {
    // Update the state, triggering the component to re-render with the correct stream
    let video = document.querySelector('video');
    // handle the depricated createObjectURL method in new browser versions
    if (video) {
      if ('srcObject' in video) {
        video.srcObject = stream;
      } else {
        video.src = window.URL.createObjectURL(stream);
      }
      this.setState({
        stream,
        videoSrc: video.srcObject ? video.srcObject : video.src,
      });
    }
  }

  videoError() {}

  componentDidMount() {}

  componentWillUnmount() {
    this.stopVideo();
  }

  async _onStartRecordClick() {
    const recorder = await this.recordAudio();
    recorder.start();
    this.setState({ recorder, muted: true, controls: false });
  }

  async _onStopRecordClick() {
    if (!this.state.mediaRecorder) return;

    if (this.state.mediaRecorder.state === 'inactive') {
      alert('Not recording.');
      return;
    }
    if (this.state.mediaRecorder.state === 'recording') {
      const audio = await this.state.recorder.stop();
      this.setState({ audio, loading: true });
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', audio.audioBlob, 'foo.m4a')
        .end((err, res) => {
          if (err) {
            return alert('Please try again.');
          }
          let f = {
            name: 'original video recording',
            url: res.body.url,
            type: 'RECORDED',
          };
          this.addFile(f);
          this.setState({ loading: false });
        });
    }
    let tracks = this.state.mediaRecorder.stream.getTracks();
    tracks.forEach(t => {
      t.stop();
    });
    this.setState({ mediaRecorder: null, videoSrc: null });
  }

  addFile(f) {
    let slide = Object.assign({}, this.state.slide);
    if (slide.data.files) {
      slide.data.files = [...slide.data.files, f];
    } else {
      slide.data.files = [f];
    }
    this.setState({ slide });
  }

  recordAudio() {
    return new Promise(resolve => {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(stream => {
          // handle depricated createObjectURL method in new browser versions
          let video = document.querySelector('video');
          if (video) {
            if ('srcObject' in video) {
              video.srcObject = stream;
            } else {
              video.src = window.URL.createObjectURL(stream);
            }
            this.setState({
              stream,
              videoSrc: video.srcObject ? video.srcObject : video.src,
            });
            video.play();
          }
          const mediaRecorder = new MediaRecorder(stream, {
            mimeType: 'video/webm',
          });
          const audioChunks = [];

          mediaRecorder.addEventListener('dataavailable', event => {
            audioChunks.push(event.data);
          });

          this.setState({ mediaRecorder });

          const start = () => {
            mediaRecorder.start();
          };

          const stop = () => {
            return new Promise(resolve => {
              mediaRecorder.addEventListener('stop', () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
                const audioUrl = URL.createObjectURL(audioBlob);
                const audio = new Audio(audioUrl);
                const play = () => {
                  audio.play();
                };

                resolve({ audioBlob, audioUrl, play });
              });

              mediaRecorder.stop();
            });
          };

          resolve({ start, stop });
        });
    });
  }

  render() {
    const { slide, loading } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="videoModal"
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(122, 96, 155)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(122, 96, 155)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Video Type</span>
            <div className="recordingBtns">
              <Button.Group>
                <Button icon="stop" onClick={this.onStopRecordClick} />
                <Button icon="record" onClick={this.onStartRecordClick} />
              </Button.Group>
            </div>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-video.svg" />
            {loading && <Loader active inline="centered" />}
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              {this.state.videoSrc && (
                <video
                  autoPlay={true}
                  controls={this.state.controls}
                  src={this.state.videoSrc}
                  muted={this.state.muted}
                  style={{ width: '100%' }}
                />
              )}
              {this.state.ytURL && (
                <iframe
                  title={this.state.ytURL}
                  src={this.state.ytURL}
                  frameBorder="0"
                  allowFullScreen
                  className="video"
                />
              )}
              <section>
                <aside>
                  <ul>
                    {slide.data.files.map(({ name }, i) => (
                      <li key={i}>
                        <div>
                          <Button icon onClick={() => this.onSelect(i)}>
                            <Icon name="play" />
                          </Button>
                          <Button icon onClick={() => this.onRemove(i)}>
                            <Icon name="trash" />
                          </Button>
                        </div>
                        <div>{name}</div>
                      </li>
                    ))}
                  </ul>
                </aside>
                <aside>
                  <div className="dropzone">
                    <DropZone
                      accept="video/mp4,video/x-m4v,video/*"
                      onDrop={this.onDrop.bind(this)}
                      style={dropZoneStyles}
                    >
                      <p>Drop files or click to select.</p>
                    </DropZone>
                  </div>
                </aside>
              </section>
              <section>
                <Input
                  fluid
                  value={this.state.url}
                  onChange={(ev, data) => this.setState({ url: data.value })}
                  placeholder="YouTube URL, example: https://www.youtube.com/watch?v=rvNKuJFoP0E"
                  action={{
                    content: 'Add',
                    icon: 'add',
                    onClick: this.onAddByUrl,
                  }}
                />
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal);
