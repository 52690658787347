import React, { useState, RefObject, useCallback, useEffect } from 'react';
import { CircleIconButton } from '../CircleIconButton/CircleIconButton';
import AdditionAdder from '../AdditionAdder/AdditionAdder';
import { SlideSelectionOperation, SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { ChildArticleType, ArticleLayoutView, ArticleViewMode } from '../../../models/ArticleLayoutEnum';
import { Slide } from '../../../models/Slide';
import Navigide from '../Navigide/Navigide';
import { SlideRange } from '../../../models/SlideRange';
import SearchBar from '../SearchBar/SearchBar';
import ViewSettings from '../ViewSettings/ViewSettings';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType } from '../../../constants/strings';
import GideImage from '../Image/GideImage';
import ViewbarSignupForm from '../ViewbarSignupForm/ViewbarSignupForm';
import isNil from 'ramda/es/isNil';

enum ViewbarActionType {
  Navigide = 0,
  ViewSettings = 1,
  Search = 2,
  Addition = 3,
  Rate = 4,
  Create = 5,
}

export interface ViewbarProps {
  className?: string;
  authorViewing: boolean;
  viewbarBottom: number;
  headerSlides?: Slide[];
  slideRangeList?: SlideRange[];
  numberOfSlides: number;
  currentSlidePosition?: number;
  articleLayoutView?: ArticleLayoutView;
  articleViewMode?: ArticleViewMode;
  numberOfSearchElements?: number;
  allowSlideComments?: boolean;
  allowSlideQuestions?: boolean;  
  onSwitchArticleViewMode: (viewMode: ArticleViewMode) => void;
  onSwitchArticleLayoutView: (layoutView: ArticleLayoutView) => void;
  createGide: () => void;
  enterSlideSelectionMode: (slideSelectionModeDetail: SlideSelectionInfo) => void;
  scrollToSlidePosition: (slidePosition: number) => void;
  onSlideFilterClicked?: () => void;
  onSearchTextChange: (searchText?: string) => void;
  onMoveToNextSearchText: () => void;
  onMoveToPrevSearchText: () => void;
  onCollapseAllSlides: (collapseType: string) => void;
  onExpandAllSlides: (expandType: string) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  onToggleNavigide: (displayingNavigide: boolean) => void;
  onRenderingViewbar?: () => void;  
  openModal: (payload: { modalType: string, modalProps?: any}) => void;
  closeModal: (closeDialogList?: string[]) => void;
  signUp: () => void;
  signIn: () => void;
  isLoggedIn: boolean;
  viewbarRef?: RefObject<HTMLDivElement>;
  slides: Slide[];
  maxHeight?: number;
}

export default function Viewbar(props: ViewbarProps) {
  props.onRenderingViewbar && props.onRenderingViewbar();
  const useExpander = false;
  const [selectedActionType, setSelectedAction] = useState<ViewbarActionType | undefined>(undefined);
  const [isExpanded, setIsExpanded] = useState(!useExpander);
  const exitSearch = () => {
    onSetSelectedAction(undefined);
  };
  const onSetSelectedAction = (action?: ViewbarActionType) => {
    if (selectedActionType === ViewbarActionType.Search) {
      props.onSearchTextChange(undefined);
    }
    if (selectedActionType === ViewbarActionType.Navigide || action === ViewbarActionType.Navigide) {
      props.onToggleNavigide(action === ViewbarActionType.Navigide);
    }
    if (action === undefined) {
      setSelectedAction(undefined);
    } else {
      selectedActionType === action
        ? setSelectedAction(undefined)
        : setSelectedAction(action);
    }
  }

  const onClickRate = useCallback(() => {
    if (props.isLoggedIn) {
      if (props.numberOfSlides > 0) {
        props.onSwitchArticleViewMode(ArticleViewMode.Slideshow);
      } else {
        props.showNotification({
          message: 'Unable to show slideshow for gide with no slides.',
          type: NotificationType.WARNING,
        })
      }
    } else {
      onSetSelectedAction(ViewbarActionType.Rate);
    }
  }, [selectedActionType, props.isLoggedIn, props.onSwitchArticleViewMode, props.numberOfSlides]);

  const createGide = useCallback(() => {
    if (props.isLoggedIn) {
      props.createGide();
      onSetSelectedAction(undefined);
    } else {
      onSetSelectedAction(ViewbarActionType.Create);
    }
  }, [selectedActionType, props.isLoggedIn, props.createGide]);


  return (
    <div className={`viewbarContainer ${props.className ? props.className : ''}`} ref={props.viewbarRef}>
      {useExpander && 
        <div
          className="viewbarExpander"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
            if (e.targetTouches.length > 0 && e.targetTouches[e.targetTouches.length - 1].clientY > 0) {
              const top = window.innerHeight - e.targetTouches[e.targetTouches.length - 1].clientY;
              if (top < props.viewbarBottom || (selectedActionType === ViewbarActionType.Addition && top < props.viewbarBottom + 100)) {
                onSetSelectedAction(undefined);
              }
            }
          }}
        >
          {selectedActionType !== ViewbarActionType.Search && (
            <GideImage src={`/icons/nav/viewbar-extension/${isExpanded ? 'expanded' : 'colapsed'}.svg`} alt="expand viewbar" />
          )}
        </div>
      }
      {selectedActionType === ViewbarActionType.Search && (
        <SearchBar
          onSearchTextChange={props.onSearchTextChange}
          numberOfSearchElements={props.numberOfSearchElements}
          onExitSearch={exitSearch}
          onMoveToNextSearchText={props.onMoveToNextSearchText}
          onMoveToPrevSearchText={props.onMoveToPrevSearchText}
        />
      )}
      <div className={`viewbarActions  ${!isExpanded ? 'hide' : ''}`}>
        {selectedActionType !== undefined &&
          selectedActionType !== ViewbarActionType.Search && (
            <div className={`viewbarSelectedAction`}>
              <div className="actionHeader">
                <GideImage src="/icons/nav/exit/main.svg"
                  alt="exit"
                  className="exitAction"
                  onClick={() => {
                    onSetSelectedAction(undefined);
                  }}
                />
                {selectedActionType === ViewbarActionType.Addition && (
                  <div className="actionType">
                    Viewer feedback
                    <CircleIconButton
                      className="actionIcon"
                      alt="slide additions action"
                      onClick={() => {}}
                      iconCssClass="whites-normal-1000-svg"
                      backgroundColor="var(--COLOR-PRIMARY-600)"
                      image="/icons/content-alteration/slide-additions.svg"
                    />
                  </div>
                )}
                {selectedActionType === ViewbarActionType.Navigide && (
                  <div className="actionType">
                    Navigide
                    <CircleIconButton
                      className="actionIcon"
                      alt="slide additions action"
                      onClick={() => {}}
                      iconCssClass="whites-normal-1000-svg"
                      backgroundColor="var(--COLOR-PRIMARY-600)"
                      image="/icons/content-alteration/slide-additions.svg"
                    />
                  </div>
                )}
                {selectedActionType === ViewbarActionType.ViewSettings && (
                  <div className="actionType">
                    View settings
                    <CircleIconButton
                      className="actionIcon"
                      alt="view settings action"
                      onClick={() => {}}
                      iconCssClass="whites-normal-1000-svg"
                      backgroundColor="var(--COLOR-PRIMARY-600)"
                      image="/icons/nav/viewsettings.svg"
                    />
                  </div>
                )}
                {selectedActionType === ViewbarActionType.Rate && (
                  <div className="actionType">
                    Rate
                    <CircleIconButton
                      className="actionIcon"
                      alt="like action"
                      onClick={() => {}}
                      iconCssClass="whites-normal-1000-svg"
                      backgroundColor="var(--COLOR-PRIMARY-600)"
                      image="/icons/content-alteration/like/de-active.svg"
                    />
                  </div>
                )}
                {selectedActionType === ViewbarActionType.Create && (
                  <div className="actionType">
                    Create a new gide
                    <CircleIconButton
                      className="actionIcon"
                      alt="create action"
                      onClick={() => {}}
                      iconCssClass="whites-normal-1000-svg"
                      backgroundColor="var(--COLOR-PRIMARY-600)"
                      image="/icons/nav/logo/logo-icon-sm.svg"
                    />
                  </div>
                )}
              </div>
              <div className="actionHeaderSeparator" />
              {selectedActionType === ViewbarActionType.ViewSettings && (
                <ViewSettings
                  articleLayoutView={props.articleLayoutView}
                  articleViewMode={props.articleViewMode}
                  isLoggedIn={props.isLoggedIn}
                  onSwitchArticleViewMode={props.onSwitchArticleViewMode}
                  onSwitchArticleLayoutView={props.onSwitchArticleLayoutView}
                  onCollapseAllSlides={props.onCollapseAllSlides}
                  onExpandAllSlides={props.onExpandAllSlides}
                  showNotification={props.showNotification}                  
                  openModal={props.openModal}
                  closeModal={props.closeModal}
                  onChangedViewMode={props.onRenderingViewbar}
                  signIn={props.signIn}
                  signUp={props.signUp}
                />
              )}
              {selectedActionType === ViewbarActionType.Addition && (
                <ViewbarSignupForm 
                  isLoggedIn={props.isLoggedIn}
                  showNotification={props.showNotification}
                  openModal={props.openModal}
                  closeModal={props.closeModal}
                  signUp={props.signUp}
                  signIn={props.signIn}
                />
                // <AdditionAdder
                //   allowSlideComments={props.allowSlideComments}
                //   allowSlideQuestions={props.allowSlideQuestions}
                //   addAdditionToGide={(additionType: ChildArticleType) => {
                //     props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
                //   }}
                //   addAdditionToSlide={(additionType: ChildArticleType) => {
                //     props.enterSlideSelectionMode({
                //       operation: SlideSelectionOperation.AddSlideAddition,
                //       childArticleType: additionType,
                //     });
                //   }}
                // />
              )}
              {selectedActionType === ViewbarActionType.Navigide &&
                props.headerSlides && (
                  <Navigide
                    showSlideNumbers={props.authorViewing}
                    numberOfSlides={props.numberOfSlides}
                    headerSlides={props.headerSlides}
                    slideRangeList={props.slideRangeList}
                    onNavigateToSlidePosition={props.scrollToSlidePosition}
                    currentSlidePosition={!isNil(props.currentSlidePosition) && props.currentSlidePosition > 0 ? props.currentSlidePosition : 1}
                    onCollapseAllSlides={props.onCollapseAllSlides}
                    onExpandAllSlides={props.onExpandAllSlides}
		                isAuthor={props.authorViewing}
                    style={{
                      maxHeight: Math.max(0, Math.min(530, props.maxHeight ? (props.maxHeight - 96) : 530)),
                    }}
                  />
                )}
              {selectedActionType === ViewbarActionType.Rate &&
                <ViewbarSignupForm 
                  isLoggedIn={props.isLoggedIn}
                  showNotification={props.showNotification}
                  openModal={props.openModal}
                  closeModal={props.closeModal}
                  signIn={props.signIn}
                  signUp={props.signUp}
                />
              }
              {selectedActionType === ViewbarActionType.Create &&
                <ViewbarSignupForm 
                  isLoggedIn={props.isLoggedIn}
                  showNotification={props.showNotification}
                  openModal={props.openModal}
                  closeModal={props.closeModal}
                  signIn={props.signIn}
                  signUp={props.signUp}
                />
              }
            </div>
          )}
        <div className="rootViewbar">
          <GideImage src="/icons/nav/navigide.svg"
            alt="navigide"
            className={`viewbarAction ${
              selectedActionType === ViewbarActionType.Navigide ? 'color-primary-700-svg' : 'color-secondary-600-svg'
            }`}
            style={{ marginLeft: '13px' }}
            onClick={() => { onSetSelectedAction(ViewbarActionType.Navigide); }}
          />
          <GideImage src="/icons/nav/viewsettings.svg"
            alt="view settings"
            className={`viewbarAction ${
              selectedActionType === ViewbarActionType.ViewSettings ? 'color-primary-700-svg' : 'color-secondary-600-svg'
            }`}
            onClick={() => { onSetSelectedAction(ViewbarActionType.ViewSettings); }}
          />
          <GideImage src="/icons/nav/search.svg"
            alt="search"
            className={`flipHorizontal viewbarAction ${
              selectedActionType === ViewbarActionType.Search ? 'color-primary-700-svg' : 'color-secondary-600-svg'
            }`}
            onClick={() => { onSetSelectedAction(ViewbarActionType.Search); }}
            style={{ transform: "scaleX(-1)" }}
          />
          <GideImage
            className={`viewbarAction ${
              selectedActionType === ViewbarActionType.Addition ? 'color-primary-700-svg' : 
              props.isLoggedIn ? 'color-secondary-600-svg' :
                'color-secondary-300-svg'
            }`}
            src="/icons/content-alteration/slide-additions.svg"
            alt="slide additions"
            onClick={() => { onSetSelectedAction(ViewbarActionType.Addition); }}
          />
          <GideImage src="/icons/content-alteration/like/de-active.svg"
            alt="rate"
            className={`viewbarAction ${
              selectedActionType === ViewbarActionType.Rate ? 'color-primary-700-svg' : 
              props.isLoggedIn ? 'color-secondary-600-svg' :
                'color-secondary-300-svg'
            }`}
            onClick={onClickRate}
          />
          <GideImage src="/icons/content-alteration/newgide.svg"
            onClick={createGide}
            alt="new gide"
            className={`viewbarAction ${
              selectedActionType === ViewbarActionType.Create ? 'color-primary-700-svg' :
              props.isLoggedIn ? 'color-secondary-600-svg' :
                'color-secondary-300-svg'
            }`}
            style={{ marginRight: '11px' }}
          />
        </div>
      </div>
    </div>
  );
}
