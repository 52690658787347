export const fonts = [
  {
    key: 'Georgia, serif',
    value: 'Georgia, serif',
    text: 'Georgia (serif)',
  },
  {
    key: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    text: 'Palatino (serif)',
  },
  {
    key: '"Times New Roman", Times, serif',
    value: '"Times New Roman", Times, serif',
    text: 'Times New Roman (serif)',
  },
  {
    key: 'Arial, Helvetica, sans-serif',
    value: 'Arial, Helvetica, sans-serif',
    text: 'Helvetica',
  },
  {
    key: '"Arial Black", Gadget, sans-serif',
    value: '"Arial Black", Gadget, sans-serif',
    text: 'Arial Black',
  },
  {
    key: '"Comic Sans MS", cursive, sans-serif',
    value: '"Comic Sans MS", cursive, sans-serif',
    text: 'Comic Sans',
  },
  {
    key: 'Impact, Charcoal, sans-serif',
    value: 'Impact, Charcoal, sans-serif',
    text: 'Impact',
  },
  {
    key: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    text: 'Lucida',
  },
  {
    key: 'Tahoma, Geneva, sans-serif',
    value: 'Tahoma, Geneva, sans-serif',
    text: 'Tahoma',
  },
  {
    key: '"Trebuchet MS", Helvetica, sans-serif',
    value: '"Trebuchet MS", Helvetica, sans-serif',
    text: 'Trebuchet',
  },
  {
    key: 'Verdana, Geneva, sans-serif',
    value: 'Verdana, Geneva, sans-serif',
    text: 'Verdana',
  },
  {
    key: '"Courier New", Courier, monospace',
    value: '"Courier New", Courier, monospace',
    text: 'Courier (monospace)',
  },
  {
    key: '"Lucida Console", Monaco, monospace',
    value: '"Lucida Console", Monaco, monospace',
    text: 'Lucida Console (monospace)',
  },
];
