import React, { useState, useEffect, useRef } from 'react';
import PickerModal from '../PickerModal/PickerModal';
import { SlideItemSelectionSettings } from '../../../../../models/SlideFile';
import classNames from 'classnames';
import styles from './_slide-file-touch-action-picker.module.scss';
import SlideItemTouchSettings from '../../../../SlideEditors/SlideItemTouchSettings/SlideItemTouchSettings';
import { User } from '../../../../../models/User';

export interface  SlideFileTouchActionPickerProps {
  fileType: string;
  currentUser: User;
  onCancel: (originalSettings: SlideItemSelectionSettings) => void;
  onSave: (value: SlideItemSelectionSettings) => void;
  onChange: (value: SlideItemSelectionSettings) => void;
  onMessage: (message: string) => void;
  onUnhandled: (originalSettings: SlideItemSelectionSettings) => void;
  slideItemSelectionSettings: SlideItemSelectionSettings;
  className?: string;
}

export default function SlideFileTouchActionPicker (props:  SlideFileTouchActionPickerProps) {
  const [originalSettings] = useState<SlideItemSelectionSettings>(props.slideItemSelectionSettings);
  const handled = useRef(false);
  useEffect(() => {
    return () => {
      if (!handled.current) {
        props.onUnhandled(originalSettings);
      }
    };
  }, []);

  const onCancel = () => {
    handled.current = true;
    props.onCancel(originalSettings);
  };
  const onSave = (slideItemSelectionSettings: SlideItemSelectionSettings) => {
    handled.current = true;
    props.onSave(slideItemSelectionSettings);
  };
  const onChange = (slideItemSelectionSettings: SlideItemSelectionSettings) => {
    props.onChange(slideItemSelectionSettings);
  };

  return (
    <PickerModal
      style={{maxWidth: '290px'}}
      className={classNames(styles.slideFileTouchActionPickerContainer, props.className)}
      onCancel={onCancel}
      onSave={() => onSave(props.slideItemSelectionSettings)}
      hideActions={false}
    >
      <SlideItemTouchSettings
        onFinish={(slideItemSelectionSettings: SlideItemSelectionSettings) => onSave(slideItemSelectionSettings)}
        onChange={(slideItemSelectionSettings: SlideItemSelectionSettings) => onChange(slideItemSelectionSettings)}
        currentUser={props.currentUser}
        onMessage={props.onMessage}
        slideItemSelectionSettings={props.slideItemSelectionSettings}
        fileType={props.fileType}
      />
    </PickerModal>
  );
}
