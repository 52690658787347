import React, { Component } from 'react';
import Swipeable from 'react-swipeable';
import { Modal } from 'semantic-ui-react';
import Header from '../../Header';
import { User } from '../../../models/User';
import classNames from 'classnames';
import { Slide } from '../../../models/Slide';
import AudioPlayer, { AudioPlayerType } from '../../media/AudioPlayer/AudioPlayer';
import GideImage from '../../Shared/Image/GideImage';

export interface ISlideShowSlide {
  getSlideShowSlides: () => HTMLElement[];
}
export interface SwipeViewModalProps {
  swipeableItems: { slide: Slide, component: any }[];
  closeOnNavigatePastLastItem: boolean;
  currentUser: User;
  showHeader: boolean;
  totalNumberOfSlides: number;
  startAtIndex?: number;
  closeModal: () => void;
}

export interface SwipeViewModalState {
  currentIndex: number;
  showChrome: boolean;
  showCaptionPanel: boolean;
  showViewBar: boolean;
  touchingStartedAt?: Date;
  touchX?: number;
  touchY?: number;
}

export default class SwipeViewModal extends Component<SwipeViewModalProps, SwipeViewModalState> {
  constructor(props: SwipeViewModalProps) {
    super(props);

    this.state = {
      currentIndex: props.startAtIndex ? props.startAtIndex : 0,
      showChrome: true,
      showCaptionPanel: true,
      showViewBar: false,
    }
  }
  swipeViewContainer?: HTMLDivElement | null;

  getPercentComplete(slidePosition: number) {
    if (this.props.totalNumberOfSlides && this.props.totalNumberOfSlides > 0) {
      return Math.round((slidePosition / this.props.totalNumberOfSlides) * 100);
    } else {
      return 'N/A';
    }
  }
  moveNext = (): void => {
    if (this.state.currentIndex === this.props.swipeableItems.length - 1) {
      if (this.props.closeOnNavigatePastLastItem === true) {
        this.props.closeModal();
      } else {
        this.setState({ currentIndex: 0 });
      }
    } else {
      this.setState({ currentIndex: this.state.currentIndex + 1 });
    }
  }
  movePrevious = (): void => {
    if (this.state.currentIndex === 0) {
      if (this.props.closeOnNavigatePastLastItem === true) {
        this.props.closeModal();
      } else {
        this.setState({ currentIndex: this.props.swipeableItems.length - 1 });
      }
    } else {
      this.setState({ currentIndex: this.state.currentIndex - 1 });
    }

  }
  public render() {
    const slide = this.props.swipeableItems[this.state.currentIndex].slide;
    const captionPanelClasses = classNames(
      'overlayCaption',
      this.state.showChrome ? '' : 'hideCaptionPanel',
    );

    const slideCaptionClasses = classNames(
      'flexColumnFull',
      'slideCaptionInnerContainer',
      slide
        ? this.state.showCaptionPanel &&
          slide.data.caption &&
          slide.data.audioCaption
          ? ''
          : 'singleLineOverlay'
        : '',
    );
    const Component: any = this.props.swipeableItems[this.state.currentIndex].component;
    const percentArticleCompleted = this.getPercentComplete(slide.position + 1);
    return (
      <Modal
        className="swipeViewModal"
        closeOnEscape={true}
        onClose={this.props.closeModal}
        size="fullscreen"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content className={`swipeViewModalContent`}
        >
          {/* <Modal.Description
            className="flexRowSpaceBetween"
            style={{ padding: '10px' }}
          >
            <GideImage src="/icons/nav/exit/main.svg"
              className="hoverIcon"
              onClick={this.props.closeModal.bind(this)}
              alt="close dialog"
            />

          </Modal.Description> */}
          {this.props.showHeader && (
            <div className={this.state.showChrome ? '' : `overlaySwipeHeader`}

            >                   
              <Header
                showLeftSidebar={false}
                currentUser={this.props.currentUser}
                percentArticleCompleted={percentArticleCompleted}
                viewMode="SWIPE"
                onExitSwipeMode={() => {
                  this.props.closeModal();
                }}
                toggleChrome={() => {
                  this.setState({ showChrome: !this.state.showChrome });
                }}
              />
            </div>
          )}
          <div className="swipeNavigatePreviousOverlay">
            <GideImage 
              onClick={this.movePrevious}
              className="swipeOveralyActionIcon"
              src="/icons/nav/arrow/arrow-left.svg"
              alt={`□`}
            />
          </div>

          <div className="swipeNavigateNextOverlay">
            <GideImage 
              onClick={this.moveNext}
              className="swipeOveralyActionIcon swipeRight"
              src="/icons/nav/arrow/arrow-right.svg"
              alt={`□`}
            />
          </div>

          <Swipeable
            ref={(ref: any) => this.swipeViewContainer = ref}
            className="swipeableContainer flexColumnFull"
            onSwipedLeft={this.moveNext}
            onSwipedRight={this.movePrevious}
            onTap={(e: any) => this.setState({ showChrome: !this.state.showChrome })}
          >
            {Component}
          </Swipeable>
          <div style={{ display: 'flex' }} className={captionPanelClasses}>
            <div className={slideCaptionClasses}>
              {slide.data.caption && (
                <div className="flexRowCenter">
                  <div className="slideTextCaption">
                    {slide.data.caption}
                  </div>
                </div>
              )}
              {/* TODO: Attribution (Now Media Credit)  */}
              {/* TODO: Attachments  */}
              {/* {(props.hasChildArticleSlideTypes || slide.link) && (
                  <div
                    className="captionAreaAttachments"
                    onClick={e => props.handleSettingsPressed(e, slide)}
                  >
                    <span>
                      <GideImage src="/icons/paper-clip-arrow.svg"
                        style={{ width: '34px', height: '34px' }}
                        alt={`□`}
                      />
                    </span>
                    {slide.link && (
                      <span>
                        <Image src="/images/slide-icons/icon/PNGS/hyperlink.png" />
                      </span>
                    )}
                  </div>
                )} */}
              {slide.data.audioCaption && (
                <div className="slideAudioCaptionContainer">
                  <AudioPlayer
                    playerType={AudioPlayerType.AudioCaption}
                    autoPlay={
                      slide.autoPlayAudioCaption ? true : false
                    }
                    src={slide.data.audioCaption.url}
                    audioEnded={() => {
                      if (slide.autoAdvanceSlide) {
                        this.moveNext();
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
