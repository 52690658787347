import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import GideImage from '../Shared/Image/GideImage';

const mapStateToProps = state => ({
  viewMode: state.common.viewMode,
});

class AuthorizeSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      fetchingGPS: false,
      lat: '',
      long: '',
    };
    this.changePassword = ev => this.setState({ password: ev.target.value });
    // callback function for navigator.geolocation.getCurrentPosition when a position is found
    this.currentPositionFound = position => {
      this.setState({
        fetchingGPS: false,
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
      if (!isNullOrUndefined(this.props.currentUser)) {
        this.props.onAuthorizeSlide(this.props.slide.id, {
          lat: this.state.lat,
          long: this.state.long,
        });
      } else {
        this.props.onUnauthenticatedAuthorizeSlide(this.props.slide.id, {
          lat: this.state.lat,
          long: this.state.long,
        });
      }
    };
    // error callback function for navigator.geolocation.getCurrentPosition
    this.errorFindingCurrentPosition = error => {
      this.setState({ fetchingGPS: false, lat: '', long: '' });
      console.warn(`ERROR(${error.code}): ${error.message}`);
    };

    this.onSubmit = this._onSubmit.bind(this);
  }

  _onSubmit() {
    if (this.props.slide.data.passcodeType === 'TEXT') {
      if (this.props.currentUser) {
        this.props.onAuthorizeSlide(this.props.slide.id, {
          password: this.state.password,
        });
      } else {
        this.props.onUnauthenticatedAuthorizeSlide(this.props.slide.id, {
          password: this.state.password,
        });
      }
    } else if (this.props.slide.data.passcodeType === 'GPS') {
      if (
        !this.state.fetchingGPS &&
        this.state.lat === '' &&
        this.state.long === ''
      ) {
        // Need to determine the user's current location
        if (navigator.geolocation.getCurrentPosition) {
          this.setState({ fetchingGPS: true });
          navigator.geolocation.getCurrentPosition(
            this.currentPositionFound,
            this.errorFindingCurrentPosition,
          );
        } else {
          this.setState({ fetchingGPS: false, lat: '', long: '' });
          alert(
            'Sorry, geolocation does not seem to be supported by your browser.',
          );
        }
      }
    } else {
      // TIME
      if (this.props.currentUser) {
        this.props.onAuthorizeSlide(this.props.slide.id, {
          /* All handled on the server */
        });
      } else {
        this.props.onUnauthenticatedAuthorizeSlide(this.props.slide.id, {
          /* All handled on the server */
        });
      }
    }
  }

  render() {
    const { slide, viewMode } = this.props;
    const { validated } = this.state;

    let type = slide.data.passcodeType;
    if (type) {
      if (type === 'GPS') {
        type = 'Location';
      } else {
        type = type.toLowerCase();
        type = `${type[0].toUpperCase()}${type.slice(1)}`;
      }
    }

    if (validated) {
      return <div>Validated!</div>;
    }

    if (viewMode === 'SLIDE' || viewMode === 'SCROLL' || viewMode === 'SWIPE') {
      return (
        <div>
          <div>
            {slide.data.passcodeType === 'TEXT' && (
              <span className="tIcon">T</span>
            )}
            {slide.data.passcodeType === 'GPS' && (
              <GideImage src="/icons/slidetype/location/location.svg" className="svgIcon" alt={`□`} />
            )}
            {slide.data.passcodeType === 'TIME' && (
              <GideImage src="/icons/creationprocess/events.svg" className="svgIcon" alt={`□`} />
            )}
            {slide.data.passcodeType === 'USER' && (
              <GideImage src="/icons/content-alteration/followers.svg" className="svgIcon" alt={`□`} />
            )}
          </div>
          <div>
            <div className="type" style={{ display: 'flex' }}>
              {type} Passcode
              {this.props.slide.data.authorized === true && (
                <h4 style={{ marginLeft: '5px' }}>(Authorized)</h4>
              )}
              {!this.props.slide.data.authorized === true &&
                this.props.slide.data.maxAttemptsReached && (
                  <h4 style={{ marginLeft: '5px' }}>(Max Attempts Reached)</h4>
                )}
              {!this.props.slide.data.authorized === true &&
                !this.props.slide.data.maxAttemptsReached &&
                this.props.slide.data.message && (
                  <h4 style={{ marginLeft: '5px', color: 'red' }}>
                    {' '}
                    - {this.props.slide.data.message}
                  </h4>
                )}
              {!this.props.slide.data.authorized === true &&
                slide.data.passcodeType === 'USER' &&
                this.props.currentUser &&
                this.props.slide.author.username !==
                  this.props.currentUser.username && (
                  <h4 style={{ marginLeft: '5px' }}>(Not Authorized)</h4>
                )}
            </div>
            {slide.data.passcodeType === 'TEXT' &&
              !this.props.slide.data.authorized === true &&
              !this.props.slide.data.maxAttemptsReached === true && (
                <div>
                  <Input
                    fluid
                    readOnly={
                      this.props.currentUser &&
                      this.props.slide.author.username ===
                        this.props.currentUser.username
                    }
                    placeholder="Enter Passcode"
                    type="password"
                    onChange={this.changePassword}
                  />
                  <Button
                    basic
                    disabled={
                      this.props.currentUser &&
                      this.props.slide.author.username ===
                        this.props.currentUser.username
                    }
                    content={`Submit Passcode`}
                    onClick={this.onSubmit}
                  />
                </div>
              )}
            {slide.data.passcodeType === 'GPS' &&
              !this.state.fetchingGPS === true &&
              !this.props.slide.data.authorized === true && (
                <Button
                  basic
                  disabled={
                    this.props.currentUser &&
                    this.props.slide.author.username ===
                      this.props.currentUser.username
                  }
                  content={`Submit Location`}
                  onClick={this.onSubmit}
                />
              )}
            {slide.data.passcodeType === 'GPS' &&
              this.state.fetchingGPS === true && (
                <h4 style={{ marginLeft: '5px' }}>
                  Finding Your Location... (Please Wait)
                </h4>
              )}
            {slide.data.passcodeType === 'TIME' &&
              !this.props.slide.data.authorized === true && (
                <Button
                  basic
                  disabled={
                    this.props.currentUser &&
                    this.props.slide.author.username ===
                      this.props.currentUser.username
                  }
                  content={`Submit Time`}
                  onClick={this.onSubmit}
                />
              )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps, null)(AuthorizeSlide);
