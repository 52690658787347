import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Image, Header } from 'semantic-ui-react';

import { ADD_SLIDE, MODAL_CLOSE } from '../../constants/actionTypes';

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  viewMode: state.common.viewMode,
  slides: state.article.slides,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});

export class SwipeModeModal extends Component {
  render() {
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="additionSlidesModal"
        size="large"
        closeOnDocumentClick={true}
        closeIcon="close"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Gide Title</Modal.Header>
        <Modal.Content image>
          <Image
            wrapped
            size="medium"
            src="https://react.semantic-ui.com/assets/images/avatar/large/rachel.png"
          />
          <Modal.Description>
            <Header>Default Profile Image</Header>
            <p>
              Lorem ipsom dolar.
            </p>
            <p>Lorem ipsom dolar. Lorem ipsom dolar. Lorem ipsom dolar.</p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwipeModeModal);
