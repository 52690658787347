import React from 'react';
import { ToasterMessageInfo } from "../../../models/ToasterMessageInfo";
import { MediaFile } from "../../../models/SlideFile";
import icons from '../../../assets/icons';
import classNames from "classnames";
import GideImage from "../../Shared/Image/GideImage";
import cssAspectRatio from '../../_css-aspect-ratio.module.scss';
import styles from './_ChannelEditorModeStyles.module.scss'
import { LabeledTextInput } from "../../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput";

export type ChannelEditorMode = 'New' | 'Camera' | 'Uploading' 
  | 'Edit' | 'Delete'
  | 'Crop' | 'CroppingImage' ;
  
export interface ChannelEditorModeProps {
  onSave: (image: MediaFile, title: string, description: string) => void;
  onCancel: () => void;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
  mode: ChannelEditorMode;
  setMode: (mode: ChannelEditorMode) => void;
  image?: MediaFile;
  setImage: (image?: MediaFile) => void;
  title?: string;
  setTitle: (title: string) => void;
  description?: string;
  setDescription: (description: string) => void;
  isEditingTitle: boolean, 
  beginEditingTitle: () => void, 
  finishEditingTitle: (newTitle: string) => void
}

export const getChannelEditorPreviewContent = (props: ChannelEditorModeProps) => (
  <div style={{
    overflowY: 'auto',
    paddingTop: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: "1 0",
  }}>
    <div style={{
      width: '100%',
      maxWidth: 752,
    }}>
      <div className={classNames(styles.image, cssAspectRatio.aspectRatio)}>
        <GideImage src={props.image?.url || props.image?.location || ''} alt={'Channel Image'} />
      </div>
      <div style={{ marginBottom: 20 }}>
        {!props.isEditingTitle && <>        
          {!props.title && 
            <div className={`${styles.channelTitle} ${styles.missing}`} onClick={props.beginEditingTitle}>
              <span><icons.Nav_Edit_EditMain /></span>
              Give the channel a title!
            </div>}
          {props.title && (
            <div className={styles.channelTitle} onClick={props.beginEditingTitle}>
              <span><icons.Nav_Edit_EditMain /></span>
              {props.title}
            </div>
          )}
        </>
        }      
        {props.isEditingTitle &&
          <LabeledTextInput 
            label=""
            sublabel=""
            text={props.title || ''}
            theme="light"
            skipAcceptStep
            submitOnEnterKey
            onClear={() => {}}
            placeholder="Give the channel a title!"
            isAcceptable={(text: string) => true}
            onAccept={async (text: string) => true}
            onConfirm={props.finishEditingTitle}
          />
        }
      </div>
    </div>
  </div>    
);

