import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { history } from '../../store';
import agent from '../../agent';
import { DELETE_CHANNEL } from '../../constants/actionTypes';

const mapDispatchToProps = dispatch => ({
  onDelete: payload => dispatch({ type: DELETE_CHANNEL, payload }),
});

const ChannelActions = ({ onDelete, canModify, channel, slideNumber }) => {
  const onDeleteClick = () => {
    if (channel.title === 'FYEO') {
      return alert("Can't delete FYEO Channel.");
    }
    if (!window.confirm('Delete channel?')) return;
    onDelete(agent.Channels.del(channel));
  };

  const onSettingsClick = () => {
    history.push(`/channel/${channel.slug}/settings`);
  };

  if (canModify && channel.title !== 'FYEO') {
    return (
      <span>
        <Dropdown
          icon={{ name: 'ellipsis vertical', color: 'yellow', size: 'big' }}
        >
          <Dropdown.Menu>
            {channel.domain && channel.domain.length && (
              <Dropdown.Item
                text="Open Website"
                icon="globe"
                onClick={() => {
                  window.open(`https://${channel.domain}`);
                }}
              />
            )}
            <Dropdown.Item
              text={'Settings'}
              icon="setting"
              onClick={onSettingsClick}
            />
            <Dropdown.Item
              text={'Delete'}
              icon="trash"
              onClick={onDeleteClick}
            />
          </Dropdown.Menu>
        </Dropdown>
      </span>
    );
  }

  return <span />;
};

export default connect(() => ({}), mapDispatchToProps)(ChannelActions);
