import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { InlineTextEditor } from '../InlineTextEditor.js';
import { isNullOrUndefined } from 'util';
import agent from '../../agent';
import { formatDisplayTextWithSearchMarkup } from '../../utils/helperFunctions';
import SearchContext from '../Shared/SearchBar/SearchContext';
const createDOMPurify = require('dompurify');
class HeaderSlide extends Component {
  render() {
    const { slide, inlineSlideTextEditInfo, inlineEditedSlide, viewMode, disableInlineTextEditing } = this.props;
    const onCollapseSlide = () => {
      this.props.collapseSlides(slide);
    };
    const headerClassName = slide.data.level === 0 ? 'ti' : slide.data.type === 'END' ? 'headerEnd' : `headerLevel${slide.data.level}`;
    return inlineSlideTextEditInfo &&
      inlineSlideTextEditInfo.inlineSlideTextEditId === slide.id &&
      inlineSlideTextEditInfo.type === 'title' &&
      !disableInlineTextEditing ? (
      <InlineTextEditor
        inlineEditorClasses={`inlineHeaderTextEdit ${headerClassName}`}
        dataField={'title'}
        onChange={this.props.updateSlideWithInlineEdits}
        slide={slide}
        saveEdits={this.props.onSubmit}
        setInlineSlideTextEditInfo={this.props.setInlineSlideTextEditInfo}
      />
    ) : (
      <SearchContext.Consumer>
        {({searchText}) => (
          <div className="paddedCard" style={{ display: 'flex', flex: 1 }}>
            <div>
              {this.props.viewMode !== 'SWIPE' &&
                slide.data &&
                slide.data.level > 0 &&
                !slide.data.notCollapsible &&
                this.props.collapseSlides && (
                  <Icon
                    onClick={onCollapseSlide}
                    name={this.props.collapsed ? 'caret right' : 'caret down'}
                    color="grey"
                    size="large"
                    style={{ cursor: 'pointer' }}
                  />
                )}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'left',
              }}
            >
              <span
                className={headerClassName}
                style={{ textAlign: 'left' }}
                onClick={async () => {
                  if (viewMode === 'SLIDE' && !this.props.disableInlineTextEditing) {
                    // First check to see if there is a slide with unsaved edits
                    if (inlineEditedSlide) {
                      // Make sure if the edit is for a caption that the caption has no p tags
                      if (inlineEditedSlide.data.caption) {
                        inlineEditedSlide.data.caption = inlineEditedSlide.data.caption.replace('<p>', '').replace('</p>', '');
                      }
                      const updatedSlide = await agent.Slides.update(inlineEditedSlide.id, inlineEditedSlide);
                      this.props.onSubmit({ slide: updatedSlide.slide, isTextEdit: true });
                    }
                    this.props.setInlineSlideTextEditInfo({
                      inlineSlideTextEditInfo: {
                        inlineSlideTextEditId: slide.id,
                        type: 'title',
                      },
                    });
                  }
                }}
              >
                {slide.data &&
                  slide.data.type === 'END' &&
                  !isNullOrUndefined(slide.data.title) && (
                    <div
                      className="paddedCard"
                      style={{ display: 'flex', flex: 1 }}
                      dangerouslySetInnerHTML={{
                        __html: searchText
                          ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.title), searchText)
                          : createDOMPurify
                              .sanitize(slide.data.title)
                              .replace('<p>', '')
                              .replace('</p>', ''),
                      }}
                    />
                  )}
                {slide.data &&
                  slide.data.type === 'END' &&
                  isNullOrUndefined(slide.data.title) && (
                    <div className="paddedCard" style={{ display: 'flex', flex: 1 }}>
                      End Header Section
                    </div>
                  )}
                {slide.data &&
                  !slide.data.type && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: searchText
                          ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.title), searchText)
                          : createDOMPurify
                              .sanitize(slide.data.title)
                              .replace('<p>', '')
                              .replace('</p>', ''),
                      }}
                    />
                  )}
              </span>
            </div>
          </div>
        )}
      </SearchContext.Consumer>
    );
  }
}

export default HeaderSlide;
