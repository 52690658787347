import React from 'react';
import { User } from '../../../models/User';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { Article } from '../../../models/Article';
import { SlideSelectionInfo, MultiSlideSelectionMethod, SlideSelectionTransferType } from '../../../models/SlideSelectionInfo';
import { MultiSlideSelectionDetail, SlideInlineViewSlidesDictionary, ExpandCollapseSlideAdditionInfo } from '../../../reducers/article';
import classNames from 'classnames';
import { SlideList } from '../../Gide/SlideList/SlideList';
import { ChildArticleType } from '../../../models/ArticleLayoutEnum';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { InlineSlideTextEditInfo, SlideView, ViewMode } from '../SlideView/SlideView';
import { withTheme } from 'styled-components';
import { getCustomDomain, slideEditorModalLookup, titleToPath, urlForArticle } from '../../../utils/helperFunctions';
import GideTitleSlidePlaceholder from '../../Slides/GideTitleSlide/GideTitleSlidePlaceholder';
import agent from '../../../agent';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { Link } from 'react-router-dom';
import styles from './_slide-container.module.scss';
import { history } from '../../../store';
export interface SlideContainerProps {
  currentUser: User;
  classes?: string;
  slides: Slide[];
  view: string;
  viewMode: ViewMode;
  nextViewMode?: ViewMode;
  article?: Article;
  articleEditMode?: string;
  viewOnly?: boolean;
  authorViewing: boolean;
  slideSelectionModeDetail?: SlideSelectionInfo;
  collapsedSlides?: Slide[];
  displayCollapsedHeaders?: boolean;
  slideIdOfSlideOutOfOrder?: string;
  slideIdOfSlideInAdvancedEditMode?: string;
  multiSlideSelectionDetail?: MultiSlideSelectionDetail;
  wideScreenEditModeEnabled?: boolean;
  inlineSlideTextEditInfo?: InlineSlideTextEditInfo;
  inlineEditedSlide?: Slide;
  slideInlineViewSlidesDictionary: SlideInlineViewSlidesDictionary;
  expandCollapseSlideAdditionInfo?: ExpandCollapseSlideAdditionInfo;
  disableInlineTextEditing: boolean;
  loading: boolean;
  setNextViewMode?: (mode: ViewMode) => void;
  collapseSlides?: (slide: Slide) => void;
  showNotification?: (toasterMessageInfo: ToasterMessageInfo) => void;
  openModal?: (payload: any) => void;
  onSubmitSlide?: (payload: any) => void;
  onEditingText?: (slide: Slide) => void;
  onEnterAdvancedEditForSlide?: (slideId: string) => void;
  deleteSlide?: (slideId: string) => void;
  multiSlideSelectionChanged?: (slideId: string, checked: boolean) => void;
  updateSlideNumber?: (number: number) => void;
  enterSlideSelectionMode?: (payload: SlideSelectionInfo) => void;
  updateSlideSelectionMethod: (payload: { multiSlideSelectionMethod: MultiSlideSelectionMethod }) => void;
  updateSlideSelectionOperationMode: (payload: { transferType: SlideSelectionTransferType }) => void;
  copySlides?: (payload: any) => void;
  moveSlides?: (selectedSlideIds: string[], moveToPosition: number) => void;
  setInlineSlideTextEditInfo?: (inlineSlideTextEditInfo?: InlineSlideTextEditInfo) => void;
  updateSlideWithInlineEdits?: (payload: any) => void;
  onSetViewMode?: (mode: ViewMode) => void;
  setMultiSelectSlideView?: (payload: any) => void;
  openChildArticleModal?: (
    slide: Slide,
    childArticleTypeList: ChildArticleType[],
    selectedArticleType: ChildArticleType,
    viewOnly?: boolean,
  ) => void;
  exitSlideSelectionMode?: () => void;
  addSlideAddition?: (slide: Slide, additionType: string) => void;
  updateChildGidesOnSlideForGideType?: (payload: { slideId: string; articleType: string; slides: Slide[] }) => void;
  onAuthorizeSlide?: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  onUnauthenticatedAuthorizeSlide?: (id: string, authInfo: any, isBlockAuthorizationSlide: boolean) => void;
  theme?: any;
  onSubmitSlideZero?: (articleId: string, slideZero: Slide, articleSlug: string, tagList?: string[], description?: string, descriptionSlide?: string) => void;
  onShowSignup?: () => void;
  onShowSignin?: () => void;
  onRenavigateToSameArticle?: (ignore: boolean) => void;
}

export const SlideContainer = (props: SlideContainerProps) => {
  const createSlideZero = async (slideUpdateInfo: SlideUpdateInfo) => {
    const articleSlug = titleToPath(slideUpdateInfo.slide.data.title);
    if (props.article) {
      const slideZeroResponse = await agent.Slides.createSlideZero(props.article.id, {
        slide: slideUpdateInfo.slide,
        tagList: slideUpdateInfo.tagList,
        articleSlug: articleSlug,
        description: slideUpdateInfo.description,
        descriptionSlideId: slideUpdateInfo.descriptionSlideId
      });
      props.onRenavigateToSameArticle && props.onRenavigateToSameArticle(true);
      history.replace(urlForArticle({slug: articleSlug, author: {username: props.currentUser.username}}));
      props.onSubmitSlideZero && props.onSubmitSlideZero(props.article.id, slideZeroResponse.slide, slideZeroResponse.articleSlug, slideUpdateInfo.tagList, slideZeroResponse.description, slideZeroResponse.descriptionSlide);
    }
  };
  const viewingAnotherUserGide = !props.authorViewing && props.view !== 'website';
  const slideContainerClassNames = props.currentUser
    ? classNames(
        'slideContainer',
        props.theme && props.theme.mode ? props.theme.mode : '',
        'flexColumnFull',
        props.classes ? props.classes : '',
        viewingAnotherUserGide ? 'viewingAnotherUserGide' : '',
      )
    : classNames('slideContainer', props.theme && props.theme.mode ? props.theme.mode : '', 'col-xs-12', 'col-md-8', 'offset-md-2');

  let slideZero = props.article && props.article.slideZero;

  const signupSlide = props.article && props.currentUser ? <div></div> : <></>;

  if (props.currentUser) {
    return (
      <div className={slideContainerClassNames}>
        {!props.loading && props.article && props.currentUser.username === props.article.author.username && !slideZero && (
          <GideTitleSlidePlaceholder
            onClick={() => {
              !props.slideSelectionModeDetail &&
                props.openModal &&
                props.openModal({
                  modalType: `${slideEditorModalLookup['GIDETITLE']}`,
                  modalProps: {
                    currentUser: props.currentUser,
                    article: props.article,
                    onSubmitSlide: createSlideZero,
                    position: -1,
                  },
                });
            }}
          />
        )}
        {!props.loading && props.article && slideZero && (
          <div className="slideWrap">
            <SlideView
              currentUser={props.currentUser}
              view={props.view}
              viewMode={props.viewMode}
              slide={slideZero}
              // onClick={() => {
              //     slideZero &&
              //     !props.slideSelectionModeDetail &&
              //     props.currentUser.username === (props.article as Article).author.username &&
              //     props.openModal &&
              //     props.openModal({
              //       modalType: `${slideEditorModalLookup['GIDETITLE']}`,
              //       modalProps: {
              //         currentUser: props.currentUser,
              //         article: props.article,
              //         onSubmitSlide: updateSlideZero,
              //         position: -1,
              //         editSlide: slideZero,
              //       },
              //     });
              // }}
              viewOnly={true}
              slidePosition={-1}
            />
          </div>
        )}
        <SlideList
          slides={props.slides}
          article={props.article}
          classes={'slideWrap'}
          viewMode={props.viewMode}
          articleEditMode={props.articleEditMode}
          collapsedSlides={props.collapsedSlides}
          collapseSlides={props.collapseSlides}
          showNotification={props.showNotification}
          openModal={props.openModal}
          onSubmit={props.onSubmitSlide}
          deleteSlide={props.deleteSlide}
          slideSelectionModeDetail={props.slideSelectionModeDetail}
          displayCollapsedHeaders={props.displayCollapsedHeaders}
          slideIdOfSlideOutOfOrder={props.slideIdOfSlideOutOfOrder}
          nextViewMode={props.nextViewMode}
          currentUser={props.currentUser}
          multiSlideSelectionDetail={props.multiSlideSelectionDetail}
          slideIdOfSlideInAdvancedEditMode={props.slideIdOfSlideInAdvancedEditMode}
          wideScreenEditModeEnabled={props.wideScreenEditModeEnabled}
          onEnterAdvancedEditForSlide={props.onEnterAdvancedEditForSlide}
          view={props.view}
          slideInlineViewSlidesDictionary={props.slideInlineViewSlidesDictionary}
          expandCollapseSlideAdditionInfo={props.expandCollapseSlideAdditionInfo}
          multiSlideSelectionChanged={props.multiSlideSelectionChanged}
          updateSlideNumber={props.updateSlideNumber}
          enterSlideSelectionMode={props.enterSlideSelectionMode}
          updateSlideSelectionMethod={props.updateSlideSelectionMethod}
          updateSlideSelectionOperationMode={props.updateSlideSelectionOperationMode}
          copySlides={props.copySlides}
          moveSlides={props.moveSlides}
          inlineSlideTextEditInfo={props.inlineSlideTextEditInfo}
          inlineEditedSlide={props.inlineEditedSlide}
          setInlineSlideTextEditInfo={props.setInlineSlideTextEditInfo}
          updateSlideWithInlineEdits={props.updateSlideWithInlineEdits}
          onEditingText={props.onEditingText}
          setNextViewMode={props.setNextViewMode}
          onSetViewMode={props.onSetViewMode}
          setMultiSelectSlideView={props.setMultiSelectSlideView}
          openChildArticleModal={props.openChildArticleModal}
          exitSlideSelectionMode={props.exitSlideSelectionMode}
          addSlideAddition={props.addSlideAddition}
          updateChildGidesOnSlideForGideType={props.updateChildGidesOnSlideForGideType}
          onAuthorizeSlide={props.onAuthorizeSlide}
          onUnauthenticatedAuthorizeSlide={props.onUnauthenticatedAuthorizeSlide}
          disableInlineTextEditing={props.disableInlineTextEditing}
        />
      </div>
    );
  } else {
    return (
      <div className="col-xs-12 col-md-8 offset-md-2">
        {!props.loading && props.article && slideZero && (
          <div className="slideWrap">
            <SlideView
              currentUser={props.currentUser}
              view={props.view}
              viewMode={props.viewMode}
              slide={slideZero}
              viewOnly={true}
              slidePosition={-1}
            />
          </div>
        )}
        <SlideList
          slides={props.slides}
          article={props.article}
          currentUser={props.currentUser}
          view={props.view}
          viewMode={props.viewMode}
          collapsedSlides={props.collapsedSlides}
          collapseSlides={props.collapseSlides}
          disableInlineTextEditing={props.disableInlineTextEditing || getCustomDomain() !== undefined}
        />

        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <div style={{
                display: 'flex', maxWidth: '752px', width:'100%', justifyContent: 'center',
                alignItems: 'flex-end', flexDirection: 'column', marginBottom: '30px', boxShadow: '0px 0 2px rgba(0, 0, 0, 0.2)',
                backgroundColor: 'var(--WHITES-NORMAL-1000)'
            }}>
            <span className={styles.heading}>The only thing missing is U!</span>
            <span className={styles.subHeading}>Request an account.</span>
            {(process.env.REACT_APP__READ_ONLY === 'true' || process.env.REACT_APP__IOS === 'true') && (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '50px', marginBottom: '30px'}}>
                <RoundedCornerButton
                  label="Sign up"
                  style={{ backgroundColor: 'var(--COLOR-PRIMARY-700)', minWidth: '76px', width: '76px', height: '32px', marginRight: '12px' }}
                  onClick={props.onShowSignup}
                />
                {process.env.REACT_APP__IOS === 'true' && (
                  <RoundedCornerButton
                    label="Sign in"
                    labelColor="rgba(0,s 0, 0, 0.45)"
                    style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', minWidth: '76px', width: '76px', height: '32px', marginRight: '12px' }}
                    onClick={props.onShowSignin}
                  />
                )}
              </div>
            )}
            {process.env.REACT_APP__READ_ONLY !== 'true' && process.env.REACT_APP__IOS !== 'true' && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '30px'}}>
                {/* <Link to="/login" className="login-link"> */}
                  <RoundedCornerButton
                    label="Sign in"
                    labelColor="rgba(0, 0, 0, 0.45)"
                    style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', minWidth: '76px', width: '76px', height: '32px' }}
                    onClick={props.onShowSignin}
                  />
                {/* </Link> */}
                <Link to="/register" className="login-link">
                  <RoundedCornerButton
                    label="Sign up"
                    style={{
                      backgroundColor: 'var(--COLOR-PRIMARY-700)',
                      minWidth: '76px',
                      width: '76px',
                      marginLeft: '12px',
                      height: '32px',
                      marginRight: '12px',
                    }}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
        <div style={{height: '50px'}}></div>
      </div>
    );
  }
};
export default withTheme(SlideContainer);
