import React, { Component } from 'react';
import { User } from '../../../models/User';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import { SlideList } from '../../Gide/SlideList/SlideList';
import { Article } from '../../../models/Article';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { MultiSlideSelectionDetail, InlineViewSlides } from '../../../reducers/article';
import { articleDisplayTime } from '../../../utils/helperFunctions';
import { InlineSlideTextEditInfo, ViewMode } from '../SlideView/SlideView';
import HeaderNavigation from '../../Article/HeaderNavigation';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { ChildArticleType } from '../../../models/ArticleLayoutEnum';
import { InquiryResponseHeader, InquiryResponseMode } from '../../Shared/InquiryResponseHeader/InquiryResponseHeader';
import { ArticleViewDetail } from '../GideViewerManagerModal/GideViewerManagerModal';

export interface GideViewerProps {
  currentUser: User;
  view: string;
  viewMode: ViewMode;
  nextViewMode: ViewMode;
  articleViewDetail: ArticleViewDetail;
  showInquiryResponseHeader?: boolean;
  articleEditMode: string;
  viewOnly?: boolean;
  // scrollToSlideId?: string;
  slideSelectionModeDetail?: SlideSelectionInfo;
  displayCollapsedHeaders?: boolean;
  inlineSlideTextEditInfo?: InlineSlideTextEditInfo;
  showTableOfContentsView?: boolean;
  inlineEditedSlide?: Slide;
  inquiryResponseMode?: InquiryResponseMode;
  canApprove?: boolean;
  disableInlineTextEditing: boolean;
  onSubmitArticle: (article: Article, slides: Slide[], currentSlidePosition?: number) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  openModal: (payload: any) => void;
  closeModal: () => void;
  onArticleInlineSlidesChanged: (articleId: string, inlineViewSlides: InlineViewSlides) => void;
  addSlideArticle?: (articleType: ChildArticleType) => void;
  onEditArticle: (articleId: string) => void;
  onDeleteArticle: (articleId: string) => void;
  onUpdateArticleStatus?: (articleId: string, articleStatus: string) => void;
  onReply?: (parentArticleId: string, parentPendingApproval?: boolean) => void;
  onEditingText?: (slide: Slide) => void;
  collapseSlides: (articleId: string, slide: Slide) => void;
  enterSlideSelectionMode?: (slideSelectionModeDetail: SlideSelectionInfo) => void;
  setInlineSlideTextEditInfo?: (inlineSlideTextEditInfo?: InlineSlideTextEditInfo) => void;
  updateSlideWithInlineEdits?: (payload: any) => void;
  onCreateGide: () => void;
  submitSlide?: (addSlideDetail: SlideUpdateInfo) => void;
  updateSlideNumber?: (number: number) => void;
  deleteSlide?: (slideId: string) => void;
  copySlides?: (payload: any) => void;
  moveSlides?: (selectedSlideIds: string[], moveToPosition: number) => void;
  toggleTableOfContents: () => void;
}

export interface GideViewerState {
  slideIdOfSlideInAdvancedEditMode?: string;
  multiSlideSelectionDetail: MultiSlideSelectionDetail;
}

export default class GideViewer extends Component<GideViewerProps, GideViewerState> {
  constructor(props: GideViewerProps) {
    super(props);
    this.state = {
      slideIdOfSlideInAdvancedEditMode: undefined,
      multiSlideSelectionDetail: {
        showMultiSelectSlideView: false,
        selectedSlideIds: [],
        mode: undefined,
      },
    };
  }

  scrollToSlideById = (slideId: string) => {
    const queryString = `[data-slide-id="${slideId}"]`;
    const el = document.querySelector(queryString);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  };

  onEnterAdvancedEditForSlide = (slideId: string) => {
    this.setState({ slideIdOfSlideInAdvancedEditMode: slideId });
  };

  setMultiSelectSlideView = (multiSelectSlideView: { showMultiSelectSlideView: boolean; mode: string }) => {
    this.setState({
      multiSlideSelectionDetail: {
        showMultiSelectSlideView: multiSelectSlideView.showMultiSelectSlideView,
        selectedSlideIds: [],
        mode: multiSelectSlideView.mode,
      },
    });
  };

  onCancelInquiryResponsePost = (articleId: string) => {
    // InquiryResponse article has been canceled, so delete
    this.onDeleteArticle(articleId);
  };

  onCollapseSlides = (slide: Slide) => {
    this.props.collapseSlides(this.props.articleViewDetail.article.id, slide);
  };

  onInquiryResponsePost = () => {
    this.props.articleViewDetail
      ? this.props.onSubmitArticle(
          this.props.articleViewDetail.article,
          this.props.articleViewDetail.slides,
          this.props.articleViewDetail.currentSlidePosition,
        )
      : this.props.onCreateGide();
  };

  articleDisplayTime = (article: Article) => {
    return new Date(article.updatedAt).getDay() === new Date().getDay()
      ? new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(new Date(article.updatedAt))
      : new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }).format(new Date(article.updatedAt));
  };

  onEditArticle = () => {
    if (this.props.articleViewDetail) {
      this.props.onEditArticle(this.props.articleViewDetail.article.id);
    }
  };

  onReply = () => {
    if (this.props.onReply && this.props.articleViewDetail) {
      this.props.onReply(this.props.articleViewDetail.article.id, this.props.articleViewDetail.article.status === 'PENDING');
    }
  };

  onApproveArticle = (articleId: string) => {
    this.props.onUpdateArticleStatus && this.props.onUpdateArticleStatus(articleId, 'APPROVED');
  };

  onRejectArticle = (articleId: string) => {
    this.props.onUpdateArticleStatus && this.props.onUpdateArticleStatus(articleId, 'REJECTED');
  };

  onDeleteArticle = (articleId: string) => {
    if (!window.confirm('Are you sure?')) return;
    this.props.canApprove ? this.onRejectArticle(articleId) : this.props.onDeleteArticle(articleId);
  };

  createArticleView = (): JSX.Element => {
    return (
      <>
        {this.props.articleViewDetail &&
          this.props.showInquiryResponseHeader &&
          this.props.inquiryResponseMode && (
            <div className="gideViewerContainer">
              <InquiryResponseHeader
                articleId={this.props.articleViewDetail.article.id}
                author={this.props.articleViewDetail.article.author}
                headingText={articleDisplayTime(this.props.articleViewDetail.article)}
                inquiryResponseMode={this.props.inquiryResponseMode}
                onBack={this.props.closeModal}
                onCancel={this.onCancelInquiryResponsePost}
                onPost={
                  this.props.articleViewDetail.slides && this.props.articleViewDetail.slides.length > 0
                    ? this.onInquiryResponsePost
                    : undefined
                }
                onEdit={
                  (this.props.inquiryResponseMode || this.props.inquiryResponseMode !== InquiryResponseMode.View) &&
                  this.props.articleViewDetail &&
                  this.props.articleViewDetail.article.author.id === this.props.currentUser.id
                    ? this.onEditArticle
                    : undefined
                }
                onReply={
                  this.props.articleViewDetail &&
                  this.props.articleViewDetail.article.author.id !== this.props.currentUser.id &&
                  this.props.articleViewDetail.article.type !== 'RESPONSE'
                    ? this.onReply
                    : undefined
                }
                onDelete={
                  this.props.canApprove ||
                  (this.props.articleViewDetail && this.props.articleViewDetail.article.author.id === this.props.currentUser.id)
                    ? this.onDeleteArticle
                    : undefined
                }
                onApprove={
                  this.props.canApprove &&
                  this.props.onUpdateArticleStatus &&
                  this.props.articleViewDetail.article.status &&
                  this.props.articleViewDetail.article.status === 'PENDING'
                    ? this.onApproveArticle
                    : undefined
                }
              />
            </div>
          )}
        <SlideList
          slides={this.props.articleViewDetail ? this.props.articleViewDetail.slides : []}
          disableSlideExpiration={true}
          currentUser={this.props.currentUser}
          view={this.props.view}
          classes={'slideWrap'}
          viewMode={this.props.viewMode}
          nextViewMode={this.props.nextViewMode}
          article={this.props.articleViewDetail ? this.props.articleViewDetail.article : undefined}
          articleEditMode={this.props.articleEditMode}
          onEditingText={this.props.onEditingText}
          viewOnly={this.props.inquiryResponseMode === InquiryResponseMode.View || this.props.articleViewDetail.article.author.id !== this.props.currentUser.id ? true : this.props.viewOnly}
          collapseSlides={this.onCollapseSlides}
          showNotification={this.props.showNotification}
          openModal={this.props.openModal}
          onSubmit={this.props.submitSlide}
          slideSelectionModeDetail={this.props.slideSelectionModeDetail}
          collapsedSlides={this.props.articleViewDetail.collapsedSlides}
          hideThreeDotMenu={true}
          displayCollapsedHeaders={this.props.displayCollapsedHeaders}
          slideIdOfSlideInAdvancedEditMode={this.state.slideIdOfSlideInAdvancedEditMode}
          onEnterAdvancedEditForSlide={this.onEnterAdvancedEditForSlide}
          updateSlideNumber={this.props.updateSlideNumber}
          enterSlideSelectionMode={this.props.enterSlideSelectionMode}
          // updateSlideSelectionMethod={this.props.updateSlideSelectionMethod}
          // updateSlideSelectionOperationMode={this.props.updateSlideSelectionOperationMode}
          copySlides={this.props.copySlides}
          moveSlides={this.props.moveSlides}
          deleteSlide={this.props.deleteSlide}
          inlineSlideTextEditInfo={this.props.inlineSlideTextEditInfo}
          inlineEditedSlide={this.props.inlineEditedSlide}
          setInlineSlideTextEditInfo={this.props.setInlineSlideTextEditInfo}
          updateSlideWithInlineEdits={this.props.updateSlideWithInlineEdits}
          disableInlineTextEditing={
            this.props.disableInlineTextEditing || ((this.props.inquiryResponseMode || this.props.inquiryResponseMode !== InquiryResponseMode.View) &&
            this.props.articleViewDetail &&
            this.props.articleViewDetail.article.author.id === this.props.currentUser.id)
          }
        />
      </>
    );
  };

  render() {
    const headerSlides = this.props.articleViewDetail
      ? this.props.articleViewDetail.slides.filter(s => s.slideType === 'HEADER' && s.data.type !== 'END')
      : [];
    return (
      <>
        {this.createArticleView()}
        {this.props.showTableOfContentsView &&
          headerSlides.length > 0 && (
            <HeaderNavigation slides={headerSlides} closeModal={this.props.toggleTableOfContents} onNavigate={this.scrollToSlideById} />
          )}
      </>
    );
  }
}
