import React from 'react';
import {
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
  ProgressIndicatorType 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import * as ImageLogic from './SharedLogicForImageSlideEditor';
import * as VideoLogic from './SharedLogicForVideoSlideEditor';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isImageSlide, isVideoSlide } = context;
  const fileType = 
    isImageSlide ? 'image':
    isVideoSlide ? 'video':
      "UNKNOWN";
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.New,
    loadingInfo: {
      message: `Processing ${fileType}`,
      progressIndicatorType: ProgressIndicatorType.Indeterminate,
    },
    modalOverlayState: ModalOverlayState.Header,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon(`Processing your ${fileType}`, `/icons/slidetype/${fileType}/main.svg`, 'white'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.New, mediaChooserAction: undefined, hiddenMediaChooserAction: undefined });
    },
  };
}

export const getContent = (context: CommonContext) => {
  //return <></>;
  const content =
    context.isImageSlide ? ImageLogic.getHiddenMediaChooser(context) :
    context.isVideoSlide ? VideoLogic.getHiddenMediaChooser(context) :
      "ERROR";
  return content;
};

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const Uploading = {
  getSlideEditorModalProps,
  getContent: getContent,
  getDerivedState,
};
