import io from 'socket.io-client';
import { API_ROOT } from './constants/paths';
import { store } from './store';
import { APP_LOAD, SIDEBAR_LOAD } from './constants/actionTypes';
import { User } from './models/User';
import { Collection } from './models/Collection';
import { Channel } from './models/Channel';
import { Meta } from './models/Meta';
import agent from './agent';
import isNil from 'ramda/es/isNil';

const uriAndNamespace = `${API_ROOT as string}`;
// console.error(`uriAndNamespace: ${uriAndNamespace}`);
const options: SocketIOClient.ConnectOpts = {
  // query: {
  //   token: window.localStorage.getItem('jwt'),
  // },
};
const socket = io.connect(uriAndNamespace, options);
// socket.on('news', function (data: any) {
//   console.log(data);
//   socket.emit('my other event', { my: 'data' });
// });

const setToken = (s?: SocketIOClient.Socket) => {
  // socket.io.opts.query = {
  //   token: window.localStorage.getItem('jwt'),
  // };
  s?.emit("set_token", window.localStorage.getItem('jwt'));
}

const onConnect = () => {
  console.info("Connected socket...");
  setToken(socket);
};

const onDisconnect = () => {
  console.info("Disconnected socket...");
  setToken();
};

const onReconnect = () => {
  console.info("Reconnected socket...");
  setToken();
  // Fetch the data again just to make sure we are up-to-date.
  onArticleViewed();
};

const onReconnectAttempt = () => {
  console.info("Attempting to reconnect socket...");
  setToken();
};

const onError = (error: any) => {
  console.error(`Socket error:\n${error}`);
  setToken();
}

socket.on('connect', onConnect);
socket.on('disconnect', onDisconnect);
socket.on('reconnect', onReconnect);
socket.on('reconnect_attempt', onReconnectAttempt);
socket.on('error', onError);


// **** Custom Events ****

export const onArticleViewed = () => {
  const views = store.getState().common.meta?.views;
  if (!isNil(views) && views.length > 0) {
    const lastViewDate = views[0].updatedAt;
    const payload = agent.Auth.currentSinceLastViewDate(lastViewDate);
    store.dispatch({ type: SIDEBAR_LOAD, payload });
  }
};
export const onLoad = () => {
  const token = window.localStorage.getItem('jwt');
  if(token) {
    store.dispatch({ type: APP_LOAD, payload: agent.Auth.current(), token, skipTracking: true });
  }
}
socket.on('article_viewed', onArticleViewed);


// **** Public API ****
export interface API {
  reconnect: () => void;
};

export const api: API = {
  reconnect: () => {
    socket.disconnect();
    socket.connect();
  },
};
