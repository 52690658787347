import React from 'react';
import { RoundedCornerButton } from '../RoundedCornerButton/RoundedCornerButton';
import styles from './_viewbarSignupForm.module.scss';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { Link } from 'react-router-dom';

export interface ViewbarSignupFormProps {
  isLoggedIn: boolean;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  openModal: (payload: { modalType: string, modalProps?: any}) => void;
  closeModal: (closeDialogList?: string[]) => void;
  signIn: () => void;
  signUp: () => void;
}

export const ViewbarSignupForm: React.FC<ViewbarSignupFormProps> = (props) => {

  const loggedOutSignupText = (<>
    <div className={styles.signUpText}>
      This feature is coming soon.
    </div>
    <div className={styles.signUpText}>
      Sign up for an account and get notified when this feature is available.
    </div>
  </>);

  return (
    <div style={{
      padding: '15px',
      width: "100%",
    }}>
      {(process.env.REACT_APP__READ_ONLY === 'true' || process.env.REACT_APP__IOS === 'true') && 
        <>
          {loggedOutSignupText}
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end',  marginTop: "16px",}}>
            <RoundedCornerButton
              label="Sign up"
              style={{
                backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                width: '112px',
                height: '32px',
                marginRight: '10px'
              }}
              onClick={props.signUp}
            />
            {process.env.REACT_APP__IOS === 'true' && (
              <RoundedCornerButton
                label="Sign in"
                labelColor="rgba(0, 0, 0, 0.45)"
                style={{ 
                  backgroundColor: 'var(--COLOR-SECONDARY-200)', 
                  minWidth: '112px', width: '112px', height: '32px'
                }}
                onClick={props.signIn}
              />
            )}
          </div>          
        </>
      }
      {process.env.REACT_APP__READ_ONLY !== 'true' && process.env.REACT_APP__IOS !== 'true' &&
        <>
          {props.isLoggedIn &&
            <div className={styles.signUpText}>
              This feature is coming soon.
            </div>
          }
          {!props.isLoggedIn &&
            <>
              {loggedOutSignupText}
              <div style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                margin: "16px 0px 24px",
              }}>
                <RoundedCornerButton
                  label="Sign in"
                  labelColor="rgba(0, 0, 0, 0.45)"
                  style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', minWidth: '76px', width: '76px', height: '32px' }}
                  onClick={props.signIn}
                />
                <Link to="/register">
                  <RoundedCornerButton
                  label="Sign up"
                  style={{
                    backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                    width: '112px',
                    height: '32px',
                    marginLeft: "12px",
                  }}
                  />
                </Link>
              </div>
            </>
          }
        </>
      }
    </div>
  );
}

export default ViewbarSignupForm;
