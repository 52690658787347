export const APP_LOAD = 'APP_LOAD';
export const SIDEBAR_LOAD = 'SIDEBAR_LOAD';
export const REDIRECT = 'REDIRECT';
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const ARTICLE_TITLE_IMAGE_UPDATED = 'ARTICLE_TITLE_IMAGE_UPDATED';
export const ARTICLE_IMAGE_URL_UPDATED = 'ARTICLE_IMAGE_URL_UPDATED';
export const ARTICLE_CREATED_NAVIGATE_TO_SETTINGS = 'ARTICLE_CREATED_NAVIGATE_TO_SETTINGS';
export const ARTICLE_DELETE_SLIDES = 'ARTICLE_DELETE_SLIDES';
export const ARTICLE_COPY_SLIDES = 'ARTICLE_COPY_SLIDES';
export const ARTICLE_MOVE_SLIDES = 'ARTICLE_MOVE_SLIDES';
export const ARTICLE_MOVE_SLIDES_OUT_OF_GIDE = 'ARTICLE_MOVE_SLIDES_OUT_OF_GIDE';
export const SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES = 'SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES';
export const ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT = 'ARTICLE_COPY_SLIDES_FROM_CHILD_TO_PARENT';
export const ARTICLE_EXPORT_SLIDES = 'ARTICLE_EXPORT_SLIDES';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const EXPIRE_ARTICLE = 'EXPIRE_ARTICLE';
export const DELETE_ARTICLE_NO_REDIRECT = 'DELETE_ARTICLE_NO_REDIRECT';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';
export const DELETE_CHANNEL_NO_REDIRECT = 'DELETE_CHANNEL_NO_REDIRECT';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_PAGE_ARTICLE_LOADED = 'ARTICLE_PAGE_ARTICLE_LOADED';
export const ARTICLE_PAGE_SLIDES_LOADED = 'ARTICLE_PAGE_SLIDES_LOADED';
export const ARTICLE_PAGE_SELECTED_FROM_SIDEBAR = 'ARTICLE_PAGE_SELECTED_FROM_SIDEBAR';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const ARTICLE_SHOW_GIDE_OWNER_PANEL = 'ARTICLE_SHOW_GIDE_OWNER_PANEL';
export const LOAD_COMMENTS = 'LOAD_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ADD_SLIDE = 'ADD_SLIDE';
export const ADD_MULTIPLE_SLIDES = 'ADD_MULTIPLE_SLIDES';
export const REPLACE_SLIDE = 'REPLACE_SLIDE';
export const DELETE_SLIDE = 'DELETE_SLIDE';
export const AUTHORIZE_SLIDE = 'AUTHORIZE_SLIDE';
export const AUTHORIZE_BLOCK_SLIDE = 'AUTHORIZE_BLOCK_SLIDE';
export const AUTHORIZE_BLOCK_WEBSITE_SLIDE = 'AUTHORIZE_BLOCK_WEBSITE_SLIDE';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const ARTICLE_SUBSCRIBED = 'ARTICLE_SUBSCRIBED';
export const ARTICLE_UNSUBSCRIBED = 'ARTICLE_UNSUBSCRIBED';
export const ARTICLE_DISPLAY_OWNER_PANEL = 'ARTICLE_DISPLAY_OWNER_PANEL';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const ADD_USER = 'ADD_USER';
export const ADD_USERS = 'ADD_USERS';
export const ADD_VIEWERS = 'ADD_VIEWERS';
export const REMOVE_TAG = 'REMOVE_TAG';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_VIEWER = 'REMOVE_VIEWER';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const SLIDE_PAGE_LOADED = 'SLIDE_PAGE_LOADED';
export const SLIDE_PAGE_UNLOADED = 'SLIDE_PAGE_UNLOADED';
export const UPDATE_FOOTER_INPUT = 'UPDATE_FOOTER_INPUT';
export const SLIDE_SELECTED = 'SLIDE_SELECTED';
export const SLIDE_CAPTURED = 'SLIDE_CAPTURED';
export const UPDATE_POINTS = 'UPDATE_POINTS';
export const GIDE_MODAL_LOADED = 'GIDE_MODAL_LOADED';
export const GIDE_MODAL_SEARCHED = 'GIDE_MODAL_SEARCHED';
export const UPDATE_SLIDE_INLINE_SELECTION = 'UPDATE_SLIDE_INLINE_SELECTION';
export const MAKE_INLINE_SELECTION_ON_SLIDE = 'MAKE_INLINE_SELECTION_ON_SLIDE';
export const CANCEL_MODE = 'CANCEL_MODE';
export const SEARCH_EXECUTED = 'SEARCH_EXECUTED';
export const SEARCH_CANCELED = 'SEARCH_CANCELED';
export const SET_CREATE_MODE = 'SET_CREATE_MODE';
export const USERS_LOADED = 'USERS_LOADED';
export const ATTENDANCES_LOADED = 'ATTENDANCES_LOADED';
export const SWIPE_MODE_SELECTED = 'SWIPE_MODE_SELECTED';
export const BEGIN_ADD_SETTING_ON_SLIDE = 'BEGIN_ADD_SETTING_ON_SLIDE';
export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const SET_CHROME_VISIBILITY = 'SET_CHROME_VISIBILITY';
export const SET_OVERLAY_ITEM_VISIBILITY = 'SET_OVERLAY_ITEM_VISIBILITY';
export const TOGGLE_TOOLBAR = 'TOGGLE_TOOLBAR';
export const DELETE_VIEW = 'DELETE_VIEW';
export const ARTICLE_UPDATED = 'ARTICLE_UPDATED';
export const VIEW_SLIDE = 'VIEW_SLIDE';
export const TOGGLE_LEFT_SIDEBAR = 'TOGGLE_LEFT_SIDEBAR';
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const EDIT_TEXT = 'EDIT_TEXT';
export const FOCUS_INPUT = 'FOCUS_INPUT';
export const INPUT_FOCUSED = 'INPUT_FOCUSED';
export const SET_SLIDE_TYPE = 'SET_SLIDE_TYPE';
export const MANUAL_RETURN_MADE = 'MANUAL_RETURN_MADE';
export const SET_NEXT_VIEW_MODE = 'SET_NEXT_VIEW_MODE';
export const INPUT_BLURRED = 'INPUT_BLURRED';
export const UPDATE_SLIDE_NUMBER = 'UPDATE_SLIDE_NUMBER'; // This is for scrolling when viewing in slide/scroll
export const SET_SWIPE_SLIDE_POSITION = 'SET_SWIPE_SLIDE_POSITION'; // This is so I can navigate back to the correct slide when leaving swipe mode
export const SCROLL_TO_SLIDE = 'SCROLL_TO_SLIDE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ZOOM = 'SET_ZOOM';
export const CHANNEL_SUBMITTED = 'CHANNEL_SUBMITTED';
export const COLLAPSE_SLIDES = 'COLLAPSE_SLIDES';
export const COLLAPSE_ALL_SLIDES = 'COLLAPSE_ALL_SLIDES';
export const EXPAND_ALL_SLIDES = 'EXPAND_ALL_SLIDES';
export const COPY_ARTICLE = 'COPY_ARTICLE';
export const AUTHORIZE_WEBSITE_SLIDE = 'AUTHORIZE_WEBSITE_SLIDE';
export const WEBSITE_BODY_ARTICLE_LOADED = 'WEBSITE_BODY_ARTICLE_LOADED';
export const WEBSITE_HEADER_ARTICLE_LOADED = 'WEBSITE_HEADER_ARTICLE_LOADED';
export const WEBSITE_FOOTER_ARTICLE_LOADED = 'WEBSITE_FOOTER_ARTICLE_LOADED';
export const WEBSITE_SIDEBAR_ARTICLE_LOADED = 'WEBSITE_SIDEBAR_ARTICLE_LOADED';
export const COLLAPSE_SLIDE_LIST = 'COLLAPSE_SLIDE_LIST';
export const SET_ARTICLE_PERCENT_COMPLETE = 'SET_ARTICLE_PERCENT_COMPLETE';
export const TOGGLE_SWIPE_OVERLAYS = 'TOGGLE_SWIPE_OVERLAYS';
//export const UPDATE_SLIDE_RANGE_LIST = 'UPDATE_SLIDE_RANGE_LIST';
export const SET_INLINE_SLIDE_TEXT_EDIT_INFO = 'SET_INLINE_SLIDE_TEXT_EDIT_INFO';
export const UPDATE_SLIDE_WITH_INLINE_EDITS = 'UPDATE_SLIDE_WITH_INLINE_EDITS';
export const SET_OPEN_FOOTER_MENU = 'SET_OPEN_FOOTER_MENU';
export const TOGGLE_MULTI_SLIDE_SELECTION_MODE = 'TOGGLE_MULTI_SLIDE_SELECTION_MODE';
export const ADD_SLIDES_TO_SELECTION = 'ADD_SLIDES_TO_SELECTION';
export const REMOVE_SLIDES_FROM_SELECTION = 'REMOVE_SLIDES_FROM_SELECTION';
export const SET_SELECTED_SLIDES = 'SET_SELECTED_SLIDES';
export const SET_TOASTER_MESSAGE = 'SET_TOASTER_MESSAGE';
export const TOGGLE_COLUMNS = 'TOGGLE_COLUMNS';
export const TOGGLE_ARTICLE_LAYOUT_VIEW = 'TOGGLE_ARTICLE_LAYOUT_VIEW';
export const TOGGLE_GIDES_MENU = 'TOGGLE_GIDES_MENU'; // TODO: Rename this to open and fix implementation in reducer and for callers
export const CLOSE_AVATAR_MENU = 'CLOSE_AVATAR_MENU';
export const LOAD_USER_SHORTCUTS = 'LOAD_USER_SHORTCUTS';
export const LOAD_USER_FAVORITES = 'LOAD_USER_FAVORITES';
export const LOAD_GIDE_ELEMENT_SEARCH_RESULTS = 'LOAD_GIDE_ELEMENT_SEARCH_RESULTS';
export const CLEAR_GIDE_ELEMENT_SEARCH_RESULTS = 'CLEAR_GIDE_ELEMENT_SEARCH_RESULTS';
export const ADD_USER_SHORTCUTS = 'ADD_USER_SHORTCUTS';
export const UPDATE_USER_SHORTCUT = 'UPDATE_USER_SHORTCUT';
export const DELETE_USER_SHORTCUT = 'DELETE_USER_SHORTCUT';
export const ENTER_CHILD_ARTICLE_EDITOR = 'ENTER_CHILD_ARTICLE_EDITOR';
export const ENTER_CHILD_ARTICLE_EDITOR_FOR_GROUP_SLIDE = 'ENTER_CHILD_ARTICLE_EDITOR_FOR_GROUP_SLIDE';
export const EXIT_CHILD_ARTICLE_EDITOR = 'EXIT_CHILD_ARTICLE_EDITOR';
export const TOGGLE_CHILD_ARTICLE_VIEW = 'TOGGLE_CHILD_ARTICLE_VIEW';
export const UPDATE_SLIDE_NUMBER_FOR_SLIDE_ARTICLE_EDIT_MODE = 'UPDATE_SLIDE_NUMBER_FOR_SLIDE_ARTICLE_EDIT_MODE';
export const TOGGLE_ARTICLE_EDIT_MODE = 'TOGGLE_ARTICLE_EDIT_MODE';
export const COLLAPSE_ALL_CHILD_ARTICLES_BY_TYPE = 'COLLAPSE_ALL_CHILD_ARTICLES_BY_TYPE';
export const ENTER_ADVANCED_EDIT_MODE_FOR_SLIDE = 'ENTER_ADVANCED_EDIT_MODE_FOR_SLIDE';
export const ENTER_SLIDE_SELECTION_MODE = 'ENTER_SLIDE_SELECTION_MODE';
export const UPDATE_SLIDE_SELECTION_METHOD = 'UPDATE_SLIDE_SELECTION_METHOD';
export const UPDATE_SLIDE_SELECTION_OPERATION_MODE = 'UPDATE_SLIDE_SELECTION_OPERATION_MODE';
export const EXIT_SLIDE_SELECTION_MODE = 'EXIT_SLIDE_SELECTION_MODE';
export const ACCOUNT_RECHARGED = 'ACCOUNT_RECHARGED';
export const UPDATE_ACCOUNT_EDITOR = 'UPDATE_ACCOUNT_EDITOR';
export const ACCOUNT_PAGE_LOADED = 'ACCOUNT_PAGE_LOADED';
export const ACCOUNT_PAGE_UNLOADED = 'ACCOUNT_PAGE_UNLOADED';
export const TOGGLE_BLAME = 'TOGGLE_BLAME';
export const CHANNEL_PAGE_CHANNEL_LOADED = 'CHANNEL_PAGE_CHANNEL_LOADED';
export const CHANNEL_PAGE_CONTENT_LOADED = 'CHANNEL_PAGE_CONTENT_LOADED';
export const CHANNEL_PAGE_UNLOADED = 'CHANNEL_PAGE_UNLOADED';
export const ARTICLES_LOADED = 'ARTICLES_LOADED';
export const CHANNELS_LOADED = 'CHANNELS_LOADED';
export const UPDATE_TEMPLATE_SLIDES = 'UPDATE_TEMPLATE_SLIDES';
export const TOGGLE_WIDESCREEN_EDIT_MODE = 'TOGGLE_WIDESCREEN_EDIT_MODE';
export const RECOVER_ARTICLE = 'RECOVER_ARTICLE';
export const SET_CHILD_SUBARTICLE_AND_SLIDES = 'SET_CHILD_SUBARTICLE_AND_SLIDES';
export const CLEAR_CHILD_SUB_ARTICLE = 'CLEAR_CHILD_SUB_ARTICLE';
export const ADD_INQUIRY_SLIDE = 'ADD_INQUIRY_SLIDE';
export const CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE = 'CONVERT_MULTI_TEXT_SLIDES_TO_SINGLE_SLIDE';
export const SCROLL_TO_SLIDE_ID = 'SCROLL_TO_SLIDE_ID';
export const SET_SLIDE_APPROVAL = 'SET_SLIDE_APPROVAL';
export const TOGGLE_COLLAPSED_HEADER_DISPLAY = 'TOGGLE_COLLAPSED_HEADER_DISPLAY';
export const TOGGLE_END_SLIDE_DISPLAY = 'TOGGLE_END_SLIDE_DISPLAY';
export const JOIN_CHANNEL = 'JOIN_CHANNEL';
export const FOLLOW_CHANNEL = 'FOLLOW_CHANNEL';
export const UNFOLLOW_CHANNEL = 'UNFOLLOW_CHANNEL';
export const COLLECTION_MODAL_LOADED = 'COLLECTION_MODAL_LOADED';
export const COLLECTION_MODAL_UNLOADED = 'COLLECTION_MODAL_UNLOADED';
export const UPDATE_SLIDE_CHILD_GIDES = 'UPDATE_SLIDE_CHILD_GIDES';
export const UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE = 'UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE';
export const ARTICLE_FILTER_SLIDES = 'ARTICLE_FILTER_SLIDES';
export const ENABLE_SLIDE_COMMENTS = 'ENABLE_SLIDE_COMMENTS';
export const DISABLE_SLIDE_COMMENTS = 'DISABLE_SLIDE_COMMENTS';
export const ENABLE_SLIDE_QUESTIONS = 'ENABLE_SLIDE_QUESTIONS';
export const DISABLE_SLIDE_QUESTIONS = 'DISABLE_SLIDE_QUESTIONS';
export const COLLAPSE_ALL_CHILD_GIDES_BY_GIDE_TYPE = 'COLLAPSE_ALL_CHILD_GIDES_BY_GIDE_TYPE';
export const LOCK_GIDE_INLINE_TEXT_EDIT = 'LOCK_GIDE_INLINE_TEXT_EDIT';
export const UNLOCK_GIDE_INLINE_TEXT_EDIT = 'UNLOCK_GIDE_INLINE_TEXT_EDIT';
export const HOME_PAGE_GIDES_LOADED = 'HOME_PAGE_GIDES_LOADED';
export const REGISTER_PAGE_LOADED = 'REGISTER_PAGE_LOADED';
export const SLIDE_ZERO_SUBMITTED = 'SLIDE_ZERO_SUBMITTED';
export const TOGGLE_NAVIGIDE = 'TOGGLE_NAVIGIDE';
