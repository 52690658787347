import React from 'react';
import classNames from 'classnames';
import styles from './_SidebarLeft.module.scss';
import { User } from '../../models/User';
import icons from '../../assets/icons';
import GideListItemPreview, { ItemSelectionMode } from '../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import TabMenu from '../Shared/TabMenu/TabMenu';
import { Link } from 'react-router-dom';
import { urlForArticle } from '../../utils/helperFunctions';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Skeleton from '@material-ui/lab/Skeleton';
import { View } from '../../models/Meta';

export enum SidebarTab {
    Notifications = 0,
    History = 1,
}

export interface SidebarLeftProps {
    views: View[];
    leftSidebarOpen: boolean;
    hideHeaderOnLargerScreen: boolean;
    selectedTab: SidebarTab;
    avatarMenu: React.ReactNode;
    readOnly?: boolean;
    loading: boolean;
    toggleSidebar: () => void;
    onSelectGideFromSidebar: (viewData: View) => void;
    onSelectedTabChanged: (tab: SidebarTab) => void;
}

const onSelectGideFromSidebar = (v: View, onSelectGideFromSidebar: (viewData: View) => void) => {
    if (!v.article) {
        return alert('Sorry, this Gide is no longer available.');
    }
    onSelectGideFromSidebar(v);
};

export const SidebarLeft: React.FC<SidebarLeftProps> = (props) => {
    return (
        <div className={styles.sidebarLeft}>
            <div className={classNames(styles.sidebarLeftHeader, props.hideHeaderOnLargerScreen ? styles.hideHeaderOnLargerScreen : '', props.readOnly === true ? styles.readOnly : '')}>
                {props.leftSidebarOpen && (
                    <icons.Nav_Hamburger_Closed
                        style={{cursor: 'pointer'}}
                        className={styles.hamburger}
                        color="var(--COLOR-SECONDARY-600)"
                        onClick={props.toggleSidebar} // currently this just toggles but we should have an explicit open/close.
                    />
                )}
                {!props.leftSidebarOpen && (
                    <icons.Nav_Hamburger_Open
                        style={{cursor: 'pointer'}}
                        className={styles.hamburger}
                        color="var(--COLOR-SECONDARY-600)"
                        onClick={props.toggleSidebar}
                    />
                )}
                <Link to={'/'} >
                    <icons.Nav_Logo_LogoType doNotFillFromColor={true} className={styles.logo}
                        onClick={props.leftSidebarOpen ? props.toggleSidebar: () => {}}
                    />
                </Link>
            </div>
            <div className={classNames(styles.sidebarLeftAvatarMenu, props.hideHeaderOnLargerScreen ? styles.hideHeaderOnLargerScreen : '')}>
                {props.avatarMenu}
            </div>
            {props.readOnly !== true && (
              <>
                <TabMenu className={styles.sidebarLeftTabs}
                    selectedTab={props.selectedTab}
                    tabs={[
                        {
                            label: "Notifications",
                            icon: <icons.Nav_Notifications />,
                        },
                        {
                            label: "History",
                            icon: <icons.Nav_History />,
                        }
                    ]}
                    onTabClicked={(i: number) => {props.onSelectedTabChanged(i)}} />
                {props.selectedTab === 0 &&
                    <div className={styles.sidebarLeftNotifications}>
                        <div>COMING SOON</div>
                    </div>
                }
                {props.selectedTab === 1 &&
                  <div className={styles.sidebarLeftHistory}>
                    {props.loading && (
                      <div className={styles.loadingSkeleton}>
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                      </div>
                    )}
                    {!props.loading && (
                      <AutoSizer>
                          {({ height, width }) =>
                          <List
                              height={height || 0}
                              itemCount={(props.views && props.views.length) || 0}
                              itemSize={61}
                              width={width || 0}>
                              {({ index, style }) => {
                                  const v = props.views[index];
                                  return <Link to={urlForArticle(v.article)} key={v.article.slug} style={style}>
                                      <GideListItemPreview
                                        className={styles.item}
                                        authorImage={v.article.author.image}
                                        author={v.article.author.username}
                                        createdAt={new Date(v.createdAt)}
                                        image={v.article.image}
                                        onClick={() => {onSelectGideFromSidebar(v, props.onSelectGideFromSidebar)}}
                                        title={v.article.title}
                                        updatedAt={new Date(v.updatedAt)}
                                        slideCount={v.article.slideCount}
                                        selectionMode={ItemSelectionMode.SingleDisplayNone}
                                      />
                                  </Link>
                              }}
                          </List>
                          }
                      </AutoSizer>
                    )}
                  </div>
                }
              </>
            )}
        </div>
    );
}

export default SidebarLeft;
