import React from 'react';
import Avatar from '../../Avatar/Avatar';
import styles from './_user-tile.module.scss';
import flagDictionary from '../../../../assets/icons/flag-lookup';
import GideImage from '../../Image/GideImage';
export enum UserTileStyle {
  ProfileListMain = 1,
  BusinesListMain = 2,
  BusinessHorizontalMain = 3,
  ProfileSlideAttribution = 4
}
export interface  UserTileProps {
  tileStyle: UserTileStyle;
  userImage: string;
  username: string;
  userLanguage: string;
  category: string;
  connections: number;
  gides: number;
  channels: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function UserTile (props:  UserTileProps) {
  return (
    <div className={styles.userTileContainer}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <div className={styles.avatarContainer}>
        <Avatar
          avatarImage={props.userImage}
          imageWidthAndHeight={49}  
          displayOuterBorder={false}          
        />
        {/* <GideImage src="/icons/" */}
        {flagDictionary[props.userLanguage] && (
          <GideImage className={styles.userLanguageFlag} src={flagDictionary[props.userLanguage]}
            alt={`${props.userLanguage} flag`}
          />
        )}
      </div>
      <div className={styles.userDetails}>
        <div className={styles.userName}>
          {props.username}
        </div>
        <div className={styles.categoryAndCounts}>
          <span className={styles.category}>{props.category} •</span> 
          <div className={styles.counts}>
            <span>{props.connections} connections ∙ </span>
            {props.tileStyle === UserTileStyle.BusinesListMain && (<span>{props.channels} channels ∙ </span>)}
            <span>{props.gides} gides</span>
          </div>
        </div>
      </div>
    </div>
  );
}
