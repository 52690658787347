import React from 'react';
import {
  SlideEditorModalProps,
  BackIconMode,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { v4 } from 'uuid';
import { darkTheme } from '../../../themes/custom-theme';
import { MediaChooser, MultiMediaType } from '../../DataEntry/CreationProcess/MediaChooser/MediaChooser';
import { NotificationType } from '../../../constants/strings';
import {
  CommonContext,
  CommonDerivedState,
  getHeaderTitleAndIcon,
  CommonModes,
} from './Common';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import icons from '../../../assets/icons';
import { MediaFile } from '../../../models/SlideFile';

export const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { commonSlideEditorModalProps, isImageSlide, isVideoSlide } = context;
  const headerActions =
    isImageSlide ? getHeaderTitleAndIcon('Image', '/icons/slidetype/image/main.svg', 'white') :
    isVideoSlide ? getHeaderTitleAndIcon('Video', '/icons/slidetype/video/main.svg', 'white') :
      "ERROR";
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.New,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: false,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions,
    onNavigateBack: () => {
      commonSlideEditorModalProps.closeModal();
    },
  };
}

export const getContent = (context: CommonContext, isHiddenMediaChooser?: boolean): React.ReactNode => {
  const { state, setState, commonSlideEditorModalProps: { showNotification }, isImageSlide, isVideoSlide } = context;

  const additionalActions = [
    {
      label: "Slide",
      button:
        <CircleIconButton
          backgroundColor='var(--COLOR-BLUE-100)'
          onClick={() => {
            setState({ ...state, mode:  CommonModes.ChooseGideFile });
          }}
        >
          <icons.CreationProcess_Slide
            color='var(--WHITES-NORMAL-1000)'
          />
        </CircleIconButton>
    },
    {
      label: "Link",
      button:
        <CircleIconButton
          backgroundColor='var(--COLOR-BLUE-100)'
          onClick={() => {
            setState({ ...state, mode: CommonModes.Link });
          }}
        >
          <icons.SlideType_Link_Main
            color='var(--WHITES-NORMAL-1000)'
          />
        </CircleIconButton>
    },
  ];

  const mediaType =
    isImageSlide ? MultiMediaType.Image :
    isVideoSlide ? MultiMediaType.Video :
      MultiMediaType.Image;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <MediaChooser
        key={ isHiddenMediaChooser ? "hidden" : "visible" }
        additionalActions={additionalActions}
        action={isHiddenMediaChooser ? state.hiddenMediaChooserAction : state.mediaChooserAction}
        style={{
          maxWidth: '768px',
        }}
        maxUploadSize={isImageSlide ? 10 : 30}
        onSavingMedia={() => {
          setState({
            ...state,
            mode: CommonModes.Uploading,
            slide: state.slide,
            mediaChooserAction: undefined,
            hiddenMediaChooserAction: undefined,
          });
        }}
        mediaType={mediaType}
        onCameraStarted={(cameraType) => {
          setState({
            ...state,
            mode: cameraType === 'InApp' ? CommonModes.Camera : CommonModes.Uploading,
            slide: state.slide,
          });
        }}
        onMediaCreated={(mediaUrls, fileType) => {
          const newFiles: MediaFile[] = [];
          mediaUrls.forEach((mediaUrl: any) => {
            newFiles.push({
              id: v4(),
              name: 'name',
              url: URL.createObjectURL(mediaUrl),
              dataUrl: mediaUrl,
              processingId: v4(),
              type: fileType,
              caption: '',
            });
          });
          // TODO: Handle Native Resolution setting
          setState({
            ...state,
            mode: CommonModes.Edit,
            selectedItemIndex: state.slide.data.files.length,
            slide: {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: [...state.slide.data.files, ...newFiles],
              },
            },
            mediaChooserAction: undefined,
            hiddenMediaChooserAction: undefined,
          });
        }}
        onError={(error: string) => {
          showNotification({ message: error, type: NotificationType.ERROR });
          setState({
            ...state,
            mode: state.slide.data.files.length ? CommonModes.Edit : CommonModes.New,
            mediaChooserAction: undefined,
            hiddenMediaChooserAction: undefined,
          });
        }}
      />
    </div>
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const New = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
