import React, { CSSProperties } from 'react';
import styles from './_rounded-corner-button.module.scss';
import classNames from 'classnames';
import GideImage from '../Image/GideImage';
export interface RoundedCornerButtonProps {
  /**
  *  @deprecated Use the icon property instead and pass in the icon component from shared icons
  */
  image?: string;
  icon?: JSX.Element;
  imagePosition?: 'right' | 'left';
  label: string;
  labelColor?: string;
  alt?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: CSSProperties;
  className?: string;
  iconCssClass?: string;
  fontCssClass?: string;
  disabled?: boolean;
}

export function RoundedCornerButton(props: RoundedCornerButtonProps) {
  return (
    <div
      // className={`${styles.roundedCornerButton} ${props.className ? props.className : ''}${props.disabled ? ' disabled': ''}`}
      className={classNames(styles.roundedCornerButton, props.className, props.disabled ? styles.disabled : undefined)}
      onClick={props.onClick}
      style={props.style}
    >
      {props.image && props.imagePosition === 'left' && (
        <GideImage 
          // className={`roundedCornerButtonIcon${props.iconCssClass ? ' ' + props.iconCssClass : ''}`}
          className={classNames(styles.roundedCornerButtonIcon, props.iconCssClass)}
          src={props.image}
          alt={props.alt ? props.alt : props.image}
          onClick={props.onClick}
        />
      )}
      {props.icon && props.imagePosition === 'left' && (
        props.icon
      )}
      <span className={props.fontCssClass ? props.fontCssClass : styles.roundedCornerButtonLabel}
        style={props.labelColor ? {color: props.labelColor} : {}}
      >
        {props.label}
      </span>
      {props.icon && props.imagePosition === 'right' && (
        props.icon
      )}
      {props.image && props.imagePosition === 'right' && (
        <GideImage 
          // className={`roundedCornerButtonIcon${props.iconCssClass ? ' ' + props.iconCssClass : ''}`}
          className={classNames(styles.roundedCornerButtonIcon, props.iconCssClass)}
          src={props.image}
          alt={props.alt ? props.alt : props.image}          
        />
      )}
    </div>
  );
}
