import  React, { CSSProperties, useCallback } from 'react';
import styles from './_channel-view.module.scss';
import { Channel } from '../../models/Channel';
import { Article } from '../../models/Article';
import GidePreviewList from '../Home/GidePreviewList/GidePreviewList';
import { User } from '../../models/User';
import classNames from 'classnames';
import { RoundedCornerButton } from '../Shared/RoundedCornerButton/RoundedCornerButton';
import icons from '../../assets/icons';
import { Link } from 'react-router-dom';

export interface  ChannelViewProps {
  channel: Channel;
  gideList: Article[];
  currentUser?: User | null;
  style?: CSSProperties;
  onGideSelected: (gideId: string) => void;
  onClickChannelImage: () => void;
}

export default function ChannelView (props:  ChannelViewProps) {

  const isCurrentUser = props.currentUser && props.currentUser.username === props.channel.author.username;

  return (
    <div className={styles.channelViewContainer} style={props.style}>
      <div className={styles.channelViewHeader}>
        <img
          className={classNames(styles.channelImage, { pointer: isCurrentUser })}
          src={
            props.channel.image
              ? props.channel.image
              : 'https://images.unsplash.com/photo-1471553342576-d066fea8dd03?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
          }
          onClick={() => {
            if (isCurrentUser) {
              props.onClickChannelImage();
            }
          }}
        />
        <div className={styles.channelDetails}>
          <div className={styles.channelDescriptionContainer}>
            <div className={styles.title}>{props.channel.title}</div>
            <div style={{ display: 'flex', paddingTop: '9px' }}>
              <span className={styles.channelType}>{props.channel.type} CHANNEL </span>
              <span className={styles.channelUsage}> • {props.gideList.length} Gides</span>
              {/* <span>{props.channel.numberOfMembers} • Members</span> */}
            </div>
            <div className={styles.description}>{props.channel.description}</div>
          </div>
          {isCurrentUser && (
            <Link to={`/channel/${props.channel.slug}`}>
            <RoundedCornerButton
              className={styles.editChannelButton}
              label="Edit channel"
              style={{
                backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
                marginLeft: '12px',
                width: '132px',
                height: '32px',
              }}
              icon={<icons.Nav_Settings color="var(--WHITES-NORMAL-1000)" />}
              imagePosition="right"
            />
            </Link>
          )}
        </div>
      </div>

      <div className={styles.channelGidesContainer}>
        <GidePreviewList gideList={props.gideList} currentUser={props.currentUser} />
      </div>
    </div>
  );
}
