import React, { useCallback, useState } from 'react';
import styles from './_distribute-settings-editor-modal.module.scss';
import ResizeableModal from '../ResizeableModal/ResizeableModal';
import GideTilePreview from '../../Shared/Previews/GidePreview/GideTilePreview/GideTilePreview';
import { Article } from '../../../models/Article';
import { isNil, dropLast, append, contains } from 'ramda';
import { User } from '../../../models/User';
import icons from '../../../assets/icons';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import InputBarEditor from '../../DataEntry/InputBar/InputBarEditor/InputBarEditor';
import { ItemSelectionMode } from '../../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview'
import ChannelChooser from '../../Shared/ChannelChooser/ChannelChooser';
import { ChannelSearchElement } from '../../GideElementSearch';
import { Agent } from '../../../models/Agent';
import { Slide, SlideUpdateInfo } from '../../../models/Slide';
import ChannelTilePreview from '../../Shared/Previews/ChannelTilePreview/ChannelTilePreview';
import ChannelListItemPreview from '../../Shared/Previews/ChannelPreview/ChannelListItemPreview/ChannelListItemPreview';
import classNames from 'classnames';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { SearchCriteria } from '../../Header/GidesAppHeader/GidesAppHeader';
import { Channel, createChannelSearchElementFromChannel } from '../../../models/Channel';
import { slideEditorModalLookup, titleToPath } from '../../../utils/helperFunctions';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import { ToasterMessageInfo } from "../../../models/ToasterMessageInfo";
import { NotificationType } from '../../../constants/strings';
import PickerModal from '../../DataEntry/Pickers/Modals/PickerModal/PickerModal';
import SlideFileChooser from '../../Shared/SlideFileChooser/SlideFileChooser';
import { SlideFileReference } from '../../../models/SlideFileReference';
import { NotificationInline } from '../../Shared/Notification/NotificationInline';
enum DistributionMode {
  BeginPublish = 1,
  SelectChannel = 2,
  ReviewSelections = 3,
  SelectDescriptionFromSlide = 4,
  CopyGideToDrafts = 5,
  CopyGideToDraftsSuccess = 6,
  TransferGide = 7,
  DeleteGide = 8,
  Congratulations = 9
}
interface GideDistributionSettings {
  channelId?: string;
  descriptionSlideId?: string;
  description?: string;
}
export interface DistributionSettingsEditorModalProps {
  gide: Article;
  channel?: ChannelSearchElement;
  draftChannelId?: string;
  slides: Slide[];
  currentUser: User;
  agent: Agent;
  channels: Channel[];
  distributionSettings?: GideDistributionSettings;
  allowCopy: boolean;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
  closeModal: () => void;
  openModal: (payload: {modalType: string, modalProps: any}) => void;
  onSubmitSlideZero: (articleId: string, slideZero: Slide, articleSlug: string, tagList?: string[], descriptionS?: string, descriptionSlide?: string) => void;
  updateArticle: (article: Article) => void;
  articleSubmitted: (payload: {article: Article}) => void;
  deleteArticle: (id: string) => void;
  shareGide: (messageTarget: string)  => void;
}
interface DistributeActionsProps {
  onDelete: () => void;
  onTransfer: () => void;
  onSettings: () => void;
  onCopy: () => void;
  mode: DistributionMode;
}
const DistributeActions: React.FC<DistributeActionsProps> = (props) => {
  const showEditorState = props.mode === DistributionMode.DeleteGide
    || props.mode === DistributionMode.CopyGideToDrafts
    || props.mode === DistributionMode.CopyGideToDraftsSuccess
    || props.mode === DistributionMode.SelectDescriptionFromSlide
    || props.mode === DistributionMode.SelectChannel;

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {!showEditorState && (
        <>
          {props.mode !== DistributionMode.Congratulations && (
            <>
              <icons.ContentAlteration_Transfer
                onClick={props.onTransfer}
                color='var(--COLOR-SECONDARY-600)'
                className={styles.distributionActionIcon}
              />
              <icons.ContentAlteration_Copy
                onClick={props.onCopy}
                color='var(--COLOR-SECONDARY-600)'
                className={styles.distributionActionIcon}
              />
            </>
          )}
          <icons.ContentAlteration_Delete
            onClick={props.onDelete}
            color='var(--COLOR-SECONDARY-600)'
            className={styles.distributionActionIcon}
          />
          <div className={styles.verticalLine}/>
          <icons.Nav_Settings
            onClick={props.onSettings}
            color='var(--COLOR-SECONDARY-600)'
            className={styles.distributionCornerActionIcon}
          />
        </>
      )}
      {showEditorState && (
        <>
          {props.mode === DistributionMode.DeleteGide && (
            <EditorState title="Delete" icon={<icons.ContentAlteration_Delete color="var(--WHITES-NORMAL-1000)" />} backgroundColor="var(--COLOR-PRIMARY-700)" />
          )}
          {(props.mode === DistributionMode.CopyGideToDrafts || props.mode === DistributionMode.CopyGideToDraftsSuccess) && (
            <EditorState title="Copy gide to your drafts" icon={<icons.ContentAlteration_Copy color="var(--WHITES-NORMAL-1000)" />} backgroundColor="var(--COLOR-PRIMARY-700)" />
          )}
          {props.mode === DistributionMode.SelectChannel && (
            <EditorState title="Select a channel" icon={<icons.Nav_Channels_Add color="var(--WHITES-NORMAL-1000)" />} backgroundColor="var(--COLOR-PRIMARY-700)" />
          )}
          {props.mode === DistributionMode.SelectDescriptionFromSlide && (
            <EditorState title="Select a text slide" icon={<icons.CreationProcess_Slide color="var(--WHITES-NORMAL-1000)" />} backgroundColor="var(--COLOR-PRIMARY-700)" />
          )}
        </>
      )}
    </div>
  );
};
export const DistributionSettingsEditorModal: React.FC<DistributionSettingsEditorModalProps> = (props: DistributionSettingsEditorModalProps) => {
  const { gide, currentUser, closeModal} = {...props};
  const [mode, setMode] = useState<DistributionMode>(props.channel ? DistributionMode.Congratulations : DistributionMode.BeginPublish);
  const [navigationHistory, setNavigationHistory] = useState<DistributionMode[]>([]);
  const [isInputBarFocused, setIsInputBarFocused] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState<ChannelSearchElement | undefined>(props.channel);
  const [distributionSettings, setDistributionSettings] = useState<GideDistributionSettings>(props.distributionSettings ? props.distributionSettings : {});
  const [gideCopy, setGideCopy] = useState<Article | undefined>(undefined);

  const navigate = (newMode: DistributionMode, historyMode: 'append' | 'clearAll' | 'clearLast', clearCount?: number ) => {
    if(historyMode === 'append') {
      setNavigationHistory(append(mode, navigationHistory));
    } else if (historyMode === 'clearLast' && navigationHistory.length > (clearCount ? clearCount : 1)) {
      setNavigationHistory(dropLast(clearCount ? clearCount : 1, navigationHistory));
    } else if (historyMode === 'clearAll') {
      setNavigationHistory([]);
    }
    setMode(newMode);
  };
  const navigateBack = () => {
    if(navigationHistory.length > 0) {
      setMode(navigationHistory[navigationHistory.length - 1]);
      setNavigationHistory(dropLast(1, navigationHistory));
    }
  };

  const getGideDescription = (slideId?: string): string => {
    const slide = slideId && props.slides.find(s => s.id === slideId);
    return slide
      ? slide.slideType === 'HEADER'
        ? slide.data.title
        : slide.data.body
      : distributionSettings.description;
  }


  const headerContent = (mode: DistributionMode) => (
    <DistributeActions
      onCopy={() => {
        navigate(DistributionMode.CopyGideToDrafts, 'append');
      }}
      onTransfer={() => {
        props.showNotification({
          message: 'is coming very soon',
          title: 'Transfer gide',
          target: 'distributionSettingsEditor',
          type: NotificationType.INFO,
          timeoutMilliseconds: 3000,
        });
      }}
      onDelete={() => {
        navigate(DistributionMode.DeleteGide, 'append');
      }}
      onSettings={() => {}}
      mode={mode}
    />
  );

  const DistributionGideTile: React.FC<{gide: Article, draftChannelId?: string}> = (props: {gide: Article, draftChannelId?: string}) => (
    <GideTilePreview
      id={props.gide.id}
      slug={props.gide.slug}
      key={props.gide.slug}
      image={!isNil(props.gide.slideZero) ? (props.gide.slideZero.data.image as string) : ''}
      title={props.gide.title}
      description={props.gide.description}
      authorImage={props.gide.author.image}
      author={props.gide.author.username}
      language={!isNil(props.gide.language) ? (props.gide.language as string) : 'English'}
      views={props.gide.viewCount}
      slideCount={!isNil(props.gide.slideCount) ? (props.gide.slideCount as number) : 0}
      gideCost={props.gide.price}
      priceType={props.gide.priceType}
      isOwner={!isNil(currentUser) && currentUser.username === props.gide.author.username}
      subscriptionPlan={props.gide.subscribeType}
      createdAt={new Date(props.gide.createdAt)}
      updatedAt={new Date(props.gide.updatedAt)}
      disableLink={true}
      isReadOnly={true}
      draftGide={props.gide.channel === props.draftChannelId}
    />);

  const updateSlideZero = async (slideUpdateInfo: SlideUpdateInfo) => {
      const articleSlug = titleToPath(slideUpdateInfo.slide.data.title);
      // Update the description to match what came from the slide Zero editor if the description has changed.
      if((distributionSettings.descriptionSlideId && getGideDescription(distributionSettings.descriptionSlideId) !== slideUpdateInfo.description) || !distributionSettings.descriptionSlideId) {
        setDistributionSettings({
          ...distributionSettings,
          description: slideUpdateInfo.description,
          descriptionSlideId: undefined
        });
      }
      const slideZeroResponse = await props.agent.Slides.updateSlideZero(gide.id, {slide: slideUpdateInfo.slide,
        articleSlug,
        tagList: slideUpdateInfo.tagList,
        description: slideUpdateInfo.description,
        descriptionSlideId: slideUpdateInfo.descriptionSlideId,
      });
      props.onSubmitSlideZero(gide.id, slideZeroResponse.slide, slideZeroResponse.articleSlug, slideUpdateInfo.tagList, slideZeroResponse.description, slideZeroResponse.descriptionSlide);
  };
  const actionMode = mode === DistributionMode.Congratulations
  || mode === DistributionMode.DeleteGide
  || mode === DistributionMode.CopyGideToDrafts
  || mode === DistributionMode.CopyGideToDraftsSuccess;

  const onSetSlideZero = () => {
    if (props.gide) {
      props.openModal({
        modalType: `${slideEditorModalLookup['GIDETITLE']}`,
        modalProps: {
          currentUser: props.currentUser,
          article: {
            ...props.gide,
            description: getGideDescription(distributionSettings.descriptionSlideId),
            descriptionSlide: distributionSettings.descriptionSlideId
          },
          onSubmitSlide: updateSlideZero,
          position: -1,
          editSlide: gide.slideZero,

        },
      });
    }
  };

  return (
    <ResizeableModal
      closeModal={props.closeModal}
      canResize={false}
      mode="noMargin"
      style={{ maxWidth: '768px' }}
      navigationState={navigationHistory.length > 0 ? 'backNavigation' : 'closeModal'}
      rightToolbarContent={headerContent(mode)}
      navigateBack={() => {
        navigateBack();
      }}
    >
      <div style={{
        width: "100%",
        height: "0px",
        position: "absolute",
      }}>
        <NotificationInline target="distributionSettingsEditor" />
      </div>
      <div className={styles.distributeSettingsEditorContainer}>
        {mode === DistributionMode.DeleteGide && (
          <PickerModal
            className={styles.deleteGideModal}
            onSave={async () => {
              await props.agent.Articles.del(gide);
              props.deleteArticle(gide.id);
              props.closeModal();
            }}
            onCancel={() => {
              navigateBack();
            }}
            saveLabel="Delete"
            saveIcon={icons.ContentAlteration_Delete}
          >
            <div className={styles.deleteGideMessage}>
              <span>Are you sure you want to move this to your trash?</span>
            </div>
          </PickerModal>
        )}
        {mode === DistributionMode.SelectChannel && (
          <ChannelChooser
            channelSearch={(searchCriteria: SearchCriteria): Promise<ChannelSearchElement[]> => {
              return Promise.resolve(props.channels.filter(ch => contains(searchCriteria.searchText
                ? searchCriteria.searchText.toLocaleLowerCase() : '', ch.title.toLocaleLowerCase()))
                .map(c => createChannelSearchElementFromChannel(c)));
            }}
            initialChannels={props.channels.map(c => createChannelSearchElementFromChannel(c))}
            searchOnKeyStroke={true}
            selectionMode={ItemSelectionMode.SingleDisplayNone}
            onSelectChannel={(channelSummary: ChannelSearchElement) => {
              navigate(DistributionMode.ReviewSelections, 'clearAll');
              setSelectedChannel(channelSummary);
            }}
          />
        )}
          {mode === DistributionMode.SelectDescriptionFromSlide && (
            <SlideFileChooser
              title={'Select a description slide'}
              subtitle={'Pick a slide to be used as the description of your gide'}
              hideActionBar={true}
              hideCommandBar={true}
              showNotification={props.showNotification}
              currentUser={props.currentUser}
              slideTypes={['TEXT', 'HEADER']}
              onNavigateBack={() => {navigateBack();}}
              slides={props.slides ? props.slides.filter(s => contains(s.slideType, ['TEXT', 'HEADER'])) : undefined}
              singleSelectionOnly={true}
              chooseCompleteSlide={true}
              selectedSlideIds={distributionSettings.descriptionSlideId ? [distributionSettings.descriptionSlideId] : undefined}
              removeSlideFileReferences={() => {
                setDistributionSettings({
                  ...distributionSettings,
                  descriptionSlideId: undefined,
                  description: undefined,
                });
              }}
              mode="distribution"
              addSlideFileReferences={(slideFileReference: SlideFileReference[]) => {
                slideFileReference.map(sfr => {
                  setDistributionSettings({
                    ...distributionSettings,
                    descriptionSlideId: sfr.referenceSlide ? sfr.referenceSlide.id : undefined,
                    description: undefined,
                  });
                });
                navigateBack();
              }}
              agent={props.agent}
            />
          )}
        <div className={classNames(styles.scrollArea,
          mode === DistributionMode.ReviewSelections ? styles.finishPublish : undefined,
          mode === DistributionMode.SelectChannel ? styles.selectChannel : undefined
          )}>
          {(mode === DistributionMode.BeginPublish ||
            mode === DistributionMode.ReviewSelections ||
            mode === DistributionMode.DeleteGide ||
            mode === DistributionMode.CopyGideToDrafts ||
            mode === DistributionMode.CopyGideToDraftsSuccess ||
            mode === DistributionMode.Congratulations) && (
            <div className={styles.beginPublish}>
              <div
                className={classNames(
                  styles.distributeGideTileContainer,
                  actionMode ? styles.removeBackground : undefined,
                  mode === DistributionMode.Congratulations ? styles.congratulations : undefined
                )}
              >
                {mode === DistributionMode.Congratulations && (
                  <>
                    <span className="TEXTTIblackboldhigh-emphasiscenter">Congratulations!</span>
                    <span style={{ marginTop: '9px', marginBottom: '9px' }} className="TEXTSUBTITLEblackhigh-emphasiscenter">
                      Your gide has been published.
                    </span>
                  </>
                )}

                <DistributionGideTile gide={props.gide} draftChannelId={props.draftChannelId} />
                {mode === DistributionMode.CopyGideToDraftsSuccess && (
                  <>
                  <div className={styles.gideCopyToDrafts}>
                    <DistributionGideTile gide={props.gide} draftChannelId={props.draftChannelId}/>
                  </div>
                  <div className={styles.gideCopyToDraftsIcon}>

                    <CircleIconButton
                      width={48}
                      height={48}
                      iconWidth={32}
                      iconHeight={32}
                      backgroundColor={'var(--COLOR-PRIMARY-700-HALF)'}
                    >
                      <icons.ContentAlteration_Copy
                        color='var(--WHITES-NORMAL-1000)'
                      />
                    </CircleIconButton>
                  </div>
                  </>
                )}

                {!actionMode && (
                  <div className={styles.editTitleAndImage} onClick={() => {
                    onSetSlideZero();
                  }}>
                    <icons.Nav_Edit_EditMain color="var(--COLOR-SECONDARY-800)" />
                    <span style={{ marginLeft: '3px' }}>Edit title and image</span>
                  </div>
                )}

              </div>

              {(mode === DistributionMode.BeginPublish) && (
                <div className={styles.publishGideContainer}>
                  <CircleIconButton
                    style={{ width: '108px', height: '108px', cursor: 'pointer' }}
                    backgroundColor="var(--COLOR-BLUE-100)"
                    onClick={() => navigate(DistributionMode.SelectChannel, 'append')}
                    iconWidth={66}
                    iconHeight={70}
                  >
                    <icons.Nav_Channels_Add color="var(--WHITES-NORMAL-1000)" />
                  </CircleIconButton>
                  <span className="TEXTTIblackboldhigh-emphasiscenter">Publish your gide</span>
                  <span className="TEXTSUBTITLEblackmedium-emphasiscenter">
                    Your gide is currently in Drafts. To publish your gide choose a channel.
                  </span>
                </div>
              )}
              {(mode === DistributionMode.ReviewSelections || mode === DistributionMode.Congratulations) && selectedChannel && (
                <>
                  <span className="TEXTSUBTITLEblackmedium-emphasiscenter" style={{ marginTop: '44px' }}>
                    {`Your Gide ${mode === DistributionMode.ReviewSelections ? 'will be' : 'has been'} published to this channel.`}
                  </span>
                  {mode === DistributionMode.ReviewSelections && (
                    <div className={styles.selectedChannelContainer}>
                      <ChannelTilePreview
                        image={selectedChannel.imageUrl}
                        title={selectedChannel.displayName}
                        authorImage={selectedChannel.authorImageUrl}
                        author={selectedChannel.authorUsername}
                        slug={selectedChannel.slug}
                        type={selectedChannel.type}
                        numberOfGides={selectedChannel.numberOfGides}
                        selectionMode={ItemSelectionMode.SingleDisplayNone}
                        numberOfMembers={selectedChannel.numberOfMembers}
                        linkMode='noLink'
                      />

                      <div
                        className={styles.editChannel}
                        onClick={() => {
                          navigate(DistributionMode.SelectChannel, "append");
                        }}
                      >
                        <icons.Nav_Edit_EditMain color="var(--COLOR-SECONDARY-800)" />
                        <span style={{ marginLeft: '3px' }}>Change Channel</span>
                      </div>
                    </div>
                  )}
                  {mode === DistributionMode.Congratulations && (
                    <>
                      <div className={styles.channelContratulationsContainer}>
                        <ChannelListItemPreview
                          image={selectedChannel.imageUrl}
                          title={selectedChannel.displayName}
                          authorImage={selectedChannel.authorImageUrl}
                          author={selectedChannel.authorUsername}
                          type={selectedChannel.type}
                          selectionMode={ItemSelectionMode.SingleDisplayNone}
                        />
                        <div className={styles.shareGideContainer}>
                        <CircleIconButton
                          style={{ width: '108px', height: '108px', cursor: 'pointer' }}
                          backgroundColor="var(--COLOR-BLUE-100)"
                          onClick={() => {
                            props.shareGide('distributionSettingsEditor');
                          }}
                          iconWidth={66}
                          iconHeight={70}
                        >
                          <icons.SlideType_Link_Main color="var(--WHITES-NORMAL-1000)" />
                        </CircleIconButton>
                        <span className="TEXTTIblackboldhigh-emphasiscenter">Share your gide</span>
                        <span className="TEXTSUBTITLEblackmedium-emphasiscenter">Copy URL to clipboard and share with friends</span>
                      </div>
                      </div>
                      <div className={styles.gidePublishedActions}>
                        <RoundedCornerButton
                          label="Unpublish"
                          onClick={async () => {
                            const updatedGide = {
                              id: gide.id,
                              channel: props.draftChannelId
                            };
                            const article = await props.agent.Articles.update(updatedGide);
                            props.updateArticle(article);
                            navigate(DistributionMode.BeginPublish, 'clearAll');
                          }}
                          style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '102px'}}
                          labelColor="var(--COLOR-PRIMARY-700"
                        />
                        <RoundedCornerButton
                          label="Back to gide"
                          onClick={props.closeModal}
                          style={{backgroundColor: 'var(--COLOR-PRIMARY-700', marginLeft: '12px', width: '131px'}}
                          labelColor="white"
                          icon={<icons.Nav_Logo_LogoIconSm color="white" />}
                          imagePosition="right"
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {mode === DistributionMode.CopyGideToDrafts && (
                <div className={styles.copyGideActions}>
                  <RoundedCornerButton
                    label="cancel"
                    onClick={async () => {
                      const updatedGide = {
                        id: gide.id,
                        channel: props.draftChannelId
                      };
                      const article = await props.agent.Articles.update(updatedGide);
                      props.updateArticle(article);
                      navigate(DistributionMode.BeginPublish, 'clearAll');
                    }}
                    style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '102px'}}
                    labelColor="var(--COLOR-PRIMARY-700"
                  />
                  <RoundedCornerButton
                    label="Confirm copy"
                    onClick={async () => {
                      if(props.allowCopy) {
                        const copyPayload = await props.agent.Articles.copy(props.gide.id);
                        setGideCopy(copyPayload.article);
                        props.showNotification({
                          message: 'gide has been copied to your drafts',
                          title: 'Copied!',
                          target: 'distributionSettingsEditor',
                          type: NotificationType.SUCCESS,
                          timeoutMilliseconds: 3000,
                        });
                        navigate(DistributionMode.CopyGideToDraftsSuccess, 'clearLast', 1);
                      } else {
                        props.showNotification({
                          message: 'This gide has files being processed in the background. Until all files are processed, copying the gide is not allowed. Try again in a couple of minutes.',
                          title: 'Copy Restrcted',
                          target: 'distributionSettingsEditor',
                          type: NotificationType.WARNING,
                          timeoutMilliseconds: 3000,
                        });
                      }
                    }}
                    style={{backgroundColor: 'var(--COLOR-PRIMARY-700', marginLeft: '12px', width: '131px'}}
                    labelColor="white"
                  />
                </div>
              )}
              {mode === DistributionMode.CopyGideToDraftsSuccess && (
                  <div className={styles.copyGideActions}>
                  <RoundedCornerButton
                    label="Undo"
                    onClick={async () => {
                      if(gideCopy) {
                        await props.agent.Articles.del(gideCopy);
                        navigate(DistributionMode.BeginPublish, 'clearAll');
                      }
                    }}
                    style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', minWidth: '69px'}}
                    labelColor="var(--COLOR-PRIMARY-700"
                  />
                  <RoundedCornerButton
                    label="Open Copy"
                    onClick={async () => {
                      if(gideCopy) {
                        props.articleSubmitted({article: gideCopy});
                        props.closeModal();
                      }
                    }}
                    style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', marginLeft: '12px',  minWidth: '104px'}}
                    labelColor="var(--COLOR-PRIMARY-700"
                  />
                  <RoundedCornerButton
                    label="Back to original"
                    onClick={async () => {
                      props.closeModal();
                    }}
                    style={{backgroundColor: 'var(--COLOR-PRIMARY-700', marginLeft: '12px', width: '138px'}}
                    labelColor="white"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {mode === DistributionMode.ReviewSelections && !isInputBarFocused && (
            <div className={styles.finishLabelContainer}>
              <span className={styles.finishLabel}>Finish your gide!</span>
              <div className={styles.finishPointer} />
              <div className={styles.finishPointerArrow} />
            </div>
        )}
        {(mode === DistributionMode.BeginPublish ||
          mode === DistributionMode.ReviewSelections ||
          mode === DistributionMode.TransferGide) && (
          <div className={styles.distributionInputBarContainer}>
            <InputBarEditor
              style={{ margin: '7px 14px 5px 14px' }}
              onPaste={() => {}}
              inputBarValue={getGideDescription(distributionSettings.descriptionSlideId)}
              onChange={(value: string) => {
                setDistributionSettings({
                  ...distributionSettings,
                  descriptionSlideId: distributionSettings.descriptionSlideId ? distributionSettings.descriptionSlideId : undefined,
                  description: value,
                });
              }}
              onInputEditorFocusChanged={(isFocused: boolean) => {
                setIsInputBarFocused(isFocused);
              }}
              placeholderText={'Add gide description...'}
              onAcceptInput={(value: string) => {}}
              displayElipsisOnLostFocus={true}
            >
              <icons.CreationProcess_Slide
                color={distributionSettings.descriptionSlideId ? 'var(--COLOR-PRIMARY-700)' : 'var(--COLOR-SECONDARY-600)'}
                onClick={() => {
                  navigate(DistributionMode.SelectDescriptionFromSlide, 'append');
                }}
                style={{ marginRight: '10px', marginBottom: '5px', cursor: 'pointer' }}
              />
            </InputBarEditor>
            <icons.ContentAlteration_Hashtag className={styles.hashTag} color="var(--COLOR-SECONDARY-600)" width={30} height={30} />
            <CircleIconButton
              width={32}
              height={32}
              style={{ marginRight: '14px', cursor: 'pointer' }}
              iconWidth={28}
              iconHeight={28}
              backgroundColor={mode === DistributionMode.ReviewSelections ? 'var(--COLOR-PRIMARY-700)' : 'var(--COLOR-SECONDARY-200)'}
            >
              <icons.Nav_Publish
                onClick={async () => {
                  if(selectedChannel) {
                    const updatedGide = {
                      id: gide.id,
                      description: distributionSettings.descriptionSlideId ? undefined : distributionSettings.description,
                      descriptionSlide: distributionSettings.descriptionSlideId ? distributionSettings.descriptionSlideId : undefined,
                      channel: selectedChannel.id,
                      trashedDate: gide.trashedDate,
                    };
                    const article = await props.agent.Articles.update(updatedGide);
                    props.updateArticle(article);

                    setNavigationHistory([]);
                    setMode(DistributionMode.Congratulations);
                  }
                }}
                color="var(--WHITES-NORMAL-1000)"
                width={28}
                height={28}
              />
            </CircleIconButton>
          </div>
        )}
      </div>
    </ResizeableModal>
  );
}
