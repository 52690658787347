import React, { Component } from 'react';
import classNames from 'classnames';

import {
  Count,
  HtmlEditor,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.items = [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateLink',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo',
    ];
    this.toolbarSettings = {
      items: this.items,
    };
    this.template = ``;
    this.services = [Link, HtmlEditor, Count];
    this.fontFamily = {
      default: "Plex Sans",
      items: [
          { text: "HILVCC Boing", value: "HILVCCBoing", command: "Font", subCommand: "FontName" },
          { text: "Plex Sans", value: "IBM Plex Sans", command: "Font", subCommand: "FontName" },
          { text: "Open Sans", value: "Open Sans", command: "Font", subCommand: "FontName" },
          { text: "Roboto", value: "Roboto", command: "Font", subCommand: "FontName" },
          { text: "Lato", value: "Lato", command: "Font", subCommand: "FontName" },
          // { text: "Slabo", value: "Slabo", command: "Font", subCommand: "FontName" },
          { text: "Oswald", value: "Oswald", command: "Font", subCommand: "FontName" },
          { text: "Source Sans Pro", value: "Source Sans Pro", command: "Font", subCommand: "FontName" },
          { text: "Montserrat", value: "Montserrat", command: "Font", subCommand: "FontName" },
          { text: "PT Sans", value: "PT Sans", command: "Font", subCommand: "FontName" },
          { text: "Raleway", value: "Raleway", command: "Font", subCommand: "FontName" },
          { text: "Lora", value: "Lora", command: "Font", subCommand: "FontName" },
          // { text: "Noto Sans", value: "Noto Sans", command: "Font", subCommand: "FontName" },
          // { text: "Impact", value: "Impact,Charcoal,sans-serif", class: "e-impact", command: "Font", subCommand: "FontName" },
          { text: "Tahoma", value: "Tahoma,Geneva,sans-serif", class: "e-tahoma", command: "Font", subCommand: "FontName" },
        ]
    };
    if (props.showToolbar) {
      this.services.push(Toolbar);
      this.services.push(QuickToolbar);
    }

  }

  onEditorCreated = () => {
    if (
      !this.props.isReadOnly &&
      this.props.focusInputOnLoad &&
      this.richtexteditor
    ) {
      this.richtexteditor.focusIn();
    }
  };

  componentDidMount() {
    this.richtexteditor.readonly = this.props.isReadOnly;
  }

  render() {
    const classes = classNames(
      this.props.limitHeight
        ? 'limitRichTextEditorHeight'
        : 'richTextHeaderFullHeight',
      this.props.classes,
    );
    return (
      <RichTextEditorComponent
        ref={richtexteditor => {
          this.richtexteditor = richtexteditor;
        }}
        // style={{fontFamily: 'arial', fontSize: '1.2em', lineHeight: '1.4285em'}}
        created={this.onEditorCreated}
        className={classes}
        change={e => {
          if (this.props.onChange) this.props.onChange(e.value);
        }}
        fontFamily={this.fontFamily}
        fontSize={{default: '18pt'}}
        valueTemplate={this.props.value}
        // maxLength={20000}
        enableTabKey={true}
        showCharCount={!this.props.isReadOnly}
        toolbarSettings={this.toolbarSettings}
        blur={() => {if(this.props.onBlur) this.props.onBlur();}}
      >
        <Inject services={this.services} />
      </RichTextEditorComponent>
    );
  }
}

export default RichTextEditor;
