import React from 'react';
import MicrolinkCard from '@microlink/react';
import { hasValue } from '../../utils/helperFunctions';
import { LinkPreview } from '../Viewers/LinkViewers/LinkPreview/LinkPreview';
import LinkView from '../Viewers/LinkViewers/LinkView/LinkView';

const TextSlide = props => {
  const files = props.slide.data.files
    ? props.slide.data.files
    : props.slide.data.items;
  return (
    <div className="linkSlideContainer">
      {hasValue(props.slide.data.title) && (
        <h4 style={{ textAlign: 'center' }}>{props.slide.data.title}</h4>
      )}
      <div className="linkSlideItems">
        {files.map((link, i) => {
          return (
            <React.Fragment key={i}>
              {props.slide.data.items && (
                <>
                  {!link.gidesLinkPreview &&
                    link.showLinkPreview && (
                      <MicrolinkCard
                        key={i}
                        className="microlinkCard"
                        url={link.url}
                        target="_blank"
                      />
                    )}
                  {!link.showLinkPreview && (
                    <a href={link.url} target="blank" key={i}>
                      {link.text ? link.text : link.url}
                    </a>
                  )}
                  {link.gidesLinkPreview &&
                    link.showLinkPreview && (
                      <LinkPreview
                        key={i}
                        url={link.url}
                        image={link.image}
                        title={link.title}
                        description={link.description}
                      />
                    )}
                </>
              )}
              {props.slide.data.files && (
                <LinkView
                  key={i}
                  linkFile={link}
                  isReferenceType={link.type === 'SLIDE'}
                  style={i < files.length - 1 ? { marginBottom: '36px' } : undefined}
                  view={props.view}
                  viewMode={props.viewMode}
                  overrideWidth={props.slide.width}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default TextSlide;
