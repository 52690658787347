import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  DELETE_CHANNEL_NO_REDIRECT,
} from '../constants/actionTypes';
import { reject } from 'ramda';

const initialState = {
  collections: [],
  users: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE_LOADED:
      return {
        ...state,
        tags: action.payload[0].tags,
        channels: action.payload[2].channels,
        collections: action.payload[3].collections,
        users: action.payload[4].users,
      };
    case DELETE_CHANNEL_NO_REDIRECT:
      if (state.channels) {
        return {
          ...state,
          channels: reject(c => c._id === action.payload.id, state.channels),
        };
      }
      return state;
    case HOME_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
