import React from 'react';
import GideImage from '../Shared/Image/GideImage';

const ItemSlide = props => {
  const {
    type,
    name,
    url,
    linkText,
    quantity,
    description,
    sku,
    units,
    image,
  } = props.slide.data;
  return (
    <div className="itemSlide">
      <div className="itemImage">
        {image && <GideImage src={image} alt={`□`} />}
      </div>
      <div className="itemMeta">
        {name && <div className="itemName">{name}</div>}
        <div className="itemType">{type}</div>
        {sku && <p># {sku}</p>}
        {quantity && (
          <p>
            {quantity} {units} remaining.
          </p>
        )}
      </div>
      <div className="itemInfo">
        {description && <p>{description}</p>}
        {url && (
          <p>
            <a href={url} target="blank" className="itemLinkBtn">
              {linkText ? linkText : url}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default ItemSlide;
