import React from 'react';
import { ChannelEditorModeProps, getChannelEditorPreviewContent } from './ChannelEditorModesShared';
import SlideEditorCommandBar from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import { lightTheme } from '../../../themes/custom-theme';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import icons from '../../../assets/icons';
import PickerModal from '../../DataEntry/Pickers/Modals/PickerModal/PickerModal';
import styles from './_ChannelEditorModeStyles.module.scss';

export const ChannelEditorModeDelete: React.FC<ChannelEditorModeProps> = (props) => {

  const navigateBack = () => {
    props.setMode('Edit');
  };

  const content = getChannelEditorPreviewContent(props);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    }}>
      <SlideEditorCommandBar
        backIconMode={BackIconMode.Light}
        showBackNavigationButton={true}
        showDefaultActions={false}
        onExit={navigateBack}
        onNavigateBack={navigateBack}
        theme={lightTheme}
      >
        {/* {imageHeaderActions} */}
        <EditorState title='Delete channel image' 
          backgroundColor="var(--COLOR-PRIMARY-600)"
          icon={<icons.Nav_Channels color="var(--WHITES-NORMAL-1000)" />}
        />
      </SlideEditorCommandBar>
     
      <div style={{ position: 'relative', overflowY: 'auto' }}>
        <PickerModal
          className={styles.deleteModal}
          onSave={() => props.setMode('New')}
          onCancel={navigateBack}
          saveLabel="Delete"
          saveIcon={icons.ContentAlteration_Delete}
        >
          <div className={styles.deleteMessage}>{`Are you sure you want to delete this image?`}</div>
        </PickerModal>
        {content}
      </div>
    </div>
  );
}
