import {
  EDITOR_PAGE_LOADED,
  EDITOR_PAGE_UNLOADED,
  ARTICLE_SUBMITTED,
  ASYNC_START,
  ADD_TAG,
  REMOVE_TAG,
  ADD_USER,
  ADD_USERS,
  ADD_VIEWERS,
  REMOVE_USER,
  REMOVE_VIEWER,
  UPDATE_FIELD_EDITOR,
} from '../constants/actionTypes';
import { storyTellingImage } from '../utils/helperFunctions';
import { EditorAction } from '../models/EditorAction';

export interface EditorState {
  tagList: string[];
  tagInput: string;
  userList: string[];
  userInput: string;
  viewerList: string[];
  viewersSelected: string[];
}

const defaultEditorState: EditorState = {
  tagList: [],
  tagInput: '',
  userList: [],
  userInput: '',
  viewerList: [],
  viewersSelected: [],
};

export default (state: EditorState = defaultEditorState, action: EditorAction) => {
  switch (action.type) {
    case EDITOR_PAGE_LOADED:
      return {
        ...state,
        id: action.payload ? action.payload.article.id : '',
        slug: action.payload ? action.payload.article.slug : '',
        articleSlug: action.payload ? action.payload.article.slug : '',
        type: action.payload ? action.payload.article.type : 'NORMAL',
        title: action.payload
          ? action.payload.article.title
          : 'My Amazing Gide',
        image: action.payload
          ? action.payload.article.image
          : storyTellingImage,
        channel: action.payload ? action.payload.article.channel : null,
        description: action.payload ? action.payload.article.description : '',
        backgroundColor: action.payload
          ? action.payload.article.backgroundColor
          : '',
        body: action.payload ? action.payload.article.body : '',
        useAttending: action.payload
          ? action.payload.article.useAttending
          : true,
        showAttending: action.payload
          ? action.payload.article.showAttending
          : '',
        date: action.payload ? action.payload.article.date : null,
        allowLink: action.payload ? action.payload.article.allowLink : false,
        allowEmbed: action.payload ? action.payload.article.allowEmbed : false,
        headersCollapsed: action.payload
          ? action.payload.article.headersCollapsed
          : false,
        allowScrollView: action.payload
          ? action.payload.article.allowScrollView
          : true,
        allowSwipeView: action.payload
          ? action.payload.article.allowSwipeView
          : true,
        allowSlideView: action.payload
          ? action.payload.article.allowSlideView
          : true,
        defaultView: action.payload
          ? action.payload.article.defaultView
          : 'SLIDE',
        tagInput: '',
        tagList: action.payload ? action.payload.article.tagList : [],
        userInput: '',
        font: action.payload ? action.payload.article.font : '',
        userList: action.payload ? action.payload.article.userList : [],
        viewerList: action.payload ? action.payload.article.viewerList : [],
        allowComments: action.payload
          ? action.payload.article.allowComments
          : false,
        price: action.payload ? action.payload.article.price : '',
        priceType: action.payload ? action.payload.article.priceType : '',
        purchaseType: action.payload ? action.payload.article.purchaseType : '',
        subscribeType: action.payload
          ? action.payload.article.subscribeType
          : '',
        allowBlame: action.payload ? action.payload.article.allowBlame : '',
        allowSlideComments: action.payload
          ? action.payload.article.allowSlideComments
          : '',
        allowSlideQuestions: action.payload
          ? action.payload.article.allowSlideQuestions
          : '',
        pauseSlideComments: action.payload
          ? action.payload.article.pauseSlideComments
          : '',
        pauseSlideQuestions: action.payload
          ? action.payload.article.pauseSlideQuestions
          : '',
        requireApprovalForInquiries: action.payload
          ? action.payload.article.requireApprovalForInquiries
          : '',
        language: action.payload
          ? action.payload.article.language
          : '',
        expirationSettings: action.payload
          ? action.payload.article.expirationSettings
          : undefined,
      };
    case EDITOR_PAGE_UNLOADED:
      return {};
    case ARTICLE_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null,
      };
    case ASYNC_START:
      if (action.subtype === ARTICLE_SUBMITTED) {
        return { ...state, inProgress: true };
      }
      break;
    case ADD_TAG:
      return {
        ...state,
        tagList: state.tagList.concat([state.tagInput]),
        tagInput: '',
      };
    case REMOVE_TAG:
      return {
        ...state,
        tagList: state.tagList.filter(tag => tag !== action.payload.tag),
      };
    case ADD_USER:
      return {
        ...state,
        userList: state.userList.concat([state.userInput]),
        userInput: '',
      };
    case ADD_USERS:
      return {
        ...state,
        userList: state.userList.concat(action.users),
        usersSelected: [],
      };
    case ADD_VIEWERS:
      return {
        ...state,
        viewerList: state.viewerList.concat(action.users),
        viewersSelected: [],
      };
    case REMOVE_USER:
      return {
        ...state,
        userList: state.userList.filter(user => user !== action.user),
      };
    case REMOVE_VIEWER:
      return {
        ...state,
        viewerList: state.viewerList.filter(user => user !== action.user),
      };
    case UPDATE_FIELD_EDITOR:
        return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }

  return state;
};
