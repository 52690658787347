import React from 'react';

export interface SliderValue {
  value: number;
}

export interface SliderProps {
  sliderValue: number;
  sliderTickLabels: string[];
  onChange: (args: any) => void;
  onMouseDown?: (args: any) => void;
  onMouseUp?: (args: any) => void;
  onClick?: (args: any) => void;
}

export default function Slider(props: SliderProps) {
  return (
    <div className="sliderContainer">
      <div className="sliderTicks"/>
      <div className="slider">
        <input
          style={{
            background: `linear-gradient(to right, #f7b500 0%, #f7b500 ${props.sliderValue * 100}%, #f5f5f5 ${props.sliderValue *
              100}%, #f5f5f5 100%)`,
          }}
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={props.sliderValue}
          onChange={props.onChange}
          onMouseDown={props.onMouseDown}
          onMouseUp={props.onMouseUp}
          onClick={props.onClick}
        />
      </div>
      <div className="sliderTickLabel">{props.sliderTickLabels.map((sliderLabel, index) => <div key={index}>{sliderLabel}</div>)}</div>
    </div>
  );
}
