import React from 'react';
import { List, Image } from 'semantic-ui-react';

import { history } from '../store';

const User = ({ user }) => {
  const handleClick = () => {
    const url = `/@${user.username}`;
    history.push(url);
  };

  return (
    <List.Item className="link underlined" onClick={handleClick}>
      <Image avatar src={user.image} />
      <List.Content>
        <List.Header>{user.username}</List.Header>
      </List.Content>
    </List.Item>
  );
};

const UserList = ({ users }) => {
  if (!users) return null;
  return (
    <List selection>
      {users.map((user, i) => {
        return <User key={i} user={user} />;
      })}
    </List>
  )
}

export default UserList;
