import React, { Component } from 'react';
import { Slide } from '../../../models/Slide';
import {
  Dictionary,
  SlideshowSlideInfo,
  SlideshowSlideDetails,
} from '../../../models/SlideShowSlide';
import { SlideshowSlideSlide } from './SlideshowSlideSlide';
import { MediaPlayer } from '../../media/MediaPlayer';
import { isNullOrUndefined } from 'util';
import Dropzone from 'react-dropzone';
import { Button, Icon, Input } from 'semantic-ui-react';
import {
  CheckBoxComponent,
} from '@syncfusion/ej2-react-buttons';
import { TimeRangeSlider, Duration } from './TimeRangeSlider';

export interface SlideshowEditorProps {
  slideshowAudioUrl?: string;
  slideshowSlides?: Slide[];
  slideshowSlidesInfo: Dictionary<SlideshowSlideInfo>;
  slideshowSlideDetails: SlideshowSlideDetails;
  onUpdateSlideshowSlidesInfo: (
    slideId: string,
    slideshowSlideInfo: SlideshowSlideInfo,
  ) => void;
  onUpdateSlideshowSlideDetails: (
    slideshowSlideDetails: SlideshowSlideDetails,
  ) => void;
  onDropAudioTrack: (acceptedFiles: any) => void;
  onRemoveSlideshowAudioTrack: () => void;
}

interface SlideshowEditorState {
  selectedSlideId?: string;
}

export class SlideshowEditor extends Component<
  SlideshowEditorProps,
  SlideshowEditorState
> {
  constructor(props: SlideshowEditorProps) {
    super(props);

    this.state = {
      selectedSlideId: undefined,
    };
  }

  markAsSelected = (selectedSlideId: string) => {
    this.setState({ selectedSlideId: selectedSlideId });
  };

  onUpdateDurationFromAudioTrack = (duration: number) => {
    if (
      !this.props.slideshowSlideDetails.duration ||
      this.props.slideshowSlideDetails.duration <= 0
    ) {
      this.onUpdateDuration(duration);
    }
  };

  onUpdateDuration = (duration: number) => {
    const slideshowSlideDetails: SlideshowSlideDetails = {
      ...this.props.slideshowSlideDetails,
    };
    slideshowSlideDetails.duration = duration;

    // initialize the beginTime to 0 if null and endTime to duration if null
    if (!slideshowSlideDetails.endTime || !slideshowSlideDetails.beginTime) {
      slideshowSlideDetails.beginTime = this.props.slideshowSlideDetails
        .beginTime
        ? this.props.slideshowSlideDetails.beginTime
        : 0;
      slideshowSlideDetails.endTime = slideshowSlideDetails.endTime
        ? slideshowSlideDetails.endTime
        : duration;
    }

    this.props.onUpdateSlideshowSlideDetails(slideshowSlideDetails);
  };

  onSlideStartTimeUpdated = (startTimeInSeconds: number) => {
    if (this.state.selectedSlideId) {
      let slideshowSlideInfo = this.props.slideshowSlidesInfo[
        this.state.selectedSlideId
      ];
      if (slideshowSlideInfo) {
        slideshowSlideInfo.startTimeInSeconds = startTimeInSeconds;
        this.props.onUpdateSlideshowSlidesInfo(
          this.state.selectedSlideId,
          slideshowSlideInfo,
        );
      }
    }
  };

  onUpdateUseAudioTrackChange = (e: any) => {
    const slideshowSlideDetails: SlideshowSlideDetails = {
      ...this.props.slideshowSlideDetails,
    };
    slideshowSlideDetails.useAudioTrack = e.checked;
    this.props.onUpdateSlideshowSlideDetails(slideshowSlideDetails);
  };

  onAllowManualScrollingChange = (e: any) => {
    const slideshowSlideDetails: SlideshowSlideDetails = {
      ...this.props.slideshowSlideDetails,
    };
    slideshowSlideDetails.allowManualScrolling = e.checked;
    this.props.onUpdateSlideshowSlideDetails(slideshowSlideDetails);
  };

  onUpdateShowMediaControls = (e: any) => {
    const slideshowSlideDetails: SlideshowSlideDetails = {
      ...this.props.slideshowSlideDetails,
    };
    slideshowSlideDetails.showSlideshowControls = e.checked;
    this.props.onUpdateSlideshowSlideDetails(slideshowSlideDetails);
  };

  onUseTimeDurationChange = (e: any) => {
    const slideshowSlideDetails: SlideshowSlideDetails = {
      ...this.props.slideshowSlideDetails,
    };
    slideshowSlideDetails.useTimeDuration = e.checked;
    this.props.onUpdateSlideshowSlideDetails(slideshowSlideDetails);
  };

  onUpdateSlideshowSlideRange = (beginTime: number, endTime: number) => {
    const slideshowSlideDetails: SlideshowSlideDetails = {
      ...this.props.slideshowSlideDetails,
    };
    slideshowSlideDetails.beginTime = beginTime;
    slideshowSlideDetails.endTime = endTime;
    this.props.onUpdateSlideshowSlideDetails(slideshowSlideDetails);
  };

  componentDidUpdate(prevProps: any) {
    if (
      isNullOrUndefined(this.state.selectedSlideId) &&
      prevProps.slideshowSlides !== this.props.slideshowSlides
    ) {
      if (this.props.slideshowSlides && this.props.slideshowSlides.length > 0) {
        this.markAsSelected(this.props.slideshowSlides[0].id);
      }
    }
  }

  render() {
    const selectedSlideId: string = this.state.selectedSlideId
      ? this.state.selectedSlideId
      : '';
    const selectedSlideStartTime: number = this.state.selectedSlideId
      ? this.props.slideshowSlidesInfo[this.state.selectedSlideId]
          .startTimeInSeconds
      : 0;
    const slideshowDuration: number = this.props.slideshowSlideDetails.duration
      ? this.props.slideshowSlideDetails.duration
      : 30;
    const slideshowBeginTime: number = this.props.slideshowSlideDetails
      .beginTime
      ? this.props.slideshowSlideDetails.beginTime
      : 0;
    const slideshowEndTime: number = this.props.slideshowSlideDetails.endTime
      ? this.props.slideshowSlideDetails.endTime
      : slideshowDuration;
    return (
      <div>
        <div className="slideshowEditor">
          <h3>Slideshow Options:</h3>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              alignContent: 'center',
            }}
          >
            <fieldset
              style={{
                border: '1px solid rgba(255, 255, 255, 1)',
                borderRadius: '.4rem',
                fontFamily: 'Aileron-SemiBold',
                fontSize: '14px',
                padding: '2%',
              }}
            >
              <CheckBoxComponent
                label="Use Audio Track"
                change={this.onUpdateUseAudioTrackChange}
                checked={this.props.slideshowSlideDetails.useAudioTrack}
              />
              <CheckBoxComponent
                label="Show Slideshow Controls"
                change={this.onUpdateShowMediaControls}
                checked={this.props.slideshowSlideDetails.showSlideshowControls}
              />
              <CheckBoxComponent
                label="Allow Manual Slide Advance"
                change={this.onAllowManualScrollingChange}
                checked={this.props.slideshowSlideDetails.allowManualScrolling}
              />
              <CheckBoxComponent
                label="Use Timings"
                change={this.onUseTimeDurationChange}
                checked={this.props.slideshowSlideDetails.useTimeDuration}
              />
              <span className="slideshowLabel">
                <label>Total Duration in Seconds</label>
                <Input
                  className="slideshowInput"
                  min={0}
                  type="number"
                  placeholder="Seconds"
                  value={Math.round(slideshowDuration)}
                  onChange={(ev: any) => {
                    this.onUpdateDuration(ev.target.value);
                  }}
                />
              </span>
            </fieldset>
          </div>
          {this.props.slideshowSlideDetails.useAudioTrack && (
            <div style={{ width: '100%' }}>
              <h3 style={{ color: 'white', width: '100%', marginTop: '25px' }}>
                Slideshow Audio:
              </h3>
              {this.props.slideshowAudioUrl && (
                <div style={{ width: '100%' }}>
                  <div className="mediaPlayer">
                    <MediaPlayer
                      mediaUrl={this.props.slideshowAudioUrl}
                      rangeMin={
                        slideshowBeginTime > 0 ? slideshowBeginTime : 0.1
                      }
                      rangeMax={slideshowEndTime}
                      sliderValue={selectedSlideStartTime}
                      showControls={true}
                      onUpdateSeekTime={this.onSlideStartTimeUpdated}
                      onUpdateRange={this.onUpdateSlideshowSlideRange}
                      onUpdateDuration={this.onUpdateDurationFromAudioTrack}
                    />
                  </div>
                  <div>
                    <label className="slideshowLabel">
                      Delete Slideshow Audio{' '}
                      <Button
                        style={{ marginLeft: '5px', marginTop: '15px' }}
                        icon
                        onClick={() => this.props.onRemoveSlideshowAudioTrack()}
                      >
                        <Icon name="trash" />
                      </Button>
                    </label>
                  </div>
                </div>
              )}
              {!this.props.slideshowAudioUrl && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Dropzone
                    className="dropZoneStyles"
                    onDrop={accepted => this.props.onDropAudioTrack(accepted)}
                  >
                    <p>Drop files or select.</p>
                  </Dropzone>
                </div>
              )}
            </div>
          )}
          {this.props.slideshowSlideDetails.useTimeDuration && (
            <div style={{ width: '100%' }}>
              <h3 style={{ color: 'white', width: '100%', marginTop: '25px' }}>
                Slideshow Duration Details:
              </h3>
              {/* <label className="slideshowLabel"> Slideshow Duration:
            <span style={{height: '27px', marginLeft: '5px'}}>
            <Input
              min={0}
              // className="form-control"
              type="number"
              placeholder="Seconds"
              value={Math.round(slideshowDuration)}
              onChange={(ev: any) => { this.onUpdateDuration(ev.target.value); }}
            />
            </span>
          </label> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'nowrap',
                }}
              >
                <label className="slideshowLabel">
                  Slideshow Begin: <Duration seconds={slideshowBeginTime} />
                </label>
                <label className="slideshowLabel">
                  Slideshow End: <Duration seconds={slideshowEndTime} />
                </label>
                <label className="slideshowLabel">
                  Slideshow Length:{' '}
                  <Duration seconds={slideshowEndTime - slideshowBeginTime} />
                </label>
                <label className="slideshowLabel">
                  Slide Begin: <Duration seconds={selectedSlideStartTime} />
                </label>
              </div>
              <span style={{ color: 'white' }}>
                <strong>Slideshow Slide Start Time:</strong>
              </span>
              <TimeRangeSlider
                sliderMin={0}
                sliderMax={slideshowDuration}
                rangeMin={slideshowBeginTime}
                rangeMax={slideshowEndTime}
                sliderValue={selectedSlideStartTime}
                onUpdateRange={this.onUpdateSlideshowSlideRange}
                onTimeSliderChange={this.onSlideStartTimeUpdated}
                // onSliderCreated={this.onSliderCreated}
              />
            </div>
          )}
          <div style={{ width: '100%' }}>
            <h3 style={{ color: 'white', width: '100%', marginTop: '25px' }}>
              Slideshow Slides:
            </h3>
            <div className="slideList">
              {this.props.slideshowSlides &&
                this.props.slideshowSlides.map((slide: Slide, key: number) => {
                  let slideshowSlideInfo = this.props.slideshowSlidesInfo[
                    slide.id
                  ];
                  return (
                    <SlideshowSlideSlide
                      slide={slide}
                      slideshowSlideInfo={slideshowSlideInfo}
                      selectedSlideId={selectedSlideId}
                      key={key}
                      markAsSelected={this.markAsSelected}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
