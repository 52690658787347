import { ReactNode } from 'react';
import { ChildArticleType } from './ArticleLayoutEnum';
export enum SlideSelectionOperation {
  EditChildArticle = 0,
  Move = 1,
  Copy = 2,
  AddSlideAddition = 3,
  AddSlideBelow = 4,
  AddSlideAbove = 5,
  Delete = 6,
  Select = 7,
  Like = 8,
  AttachToSlide = 9,
  Transfer = 10,
}
export enum SlideSelectionTransferType {
  Copy = 1,
  Move = 2,
}
export enum MultiSlideSelectionMethod {
  Range = 1,
  Selection = 2
}
export interface SlideSelectionInfo {
  operation: SlideSelectionOperation;
  // Used when operation is Transfer - User can then choose Copy or Move.
  transferType?: SlideSelectionTransferType;
  originSlideId?: string; // Null when operation is EditChildArticle
  childArticleType?: ChildArticleType; // Null when operation is not EditChildArticle
  multiSlideSelectionMethod?: MultiSlideSelectionMethod;
  actions?: ReactNode;
  options?: ReactNode;
}
