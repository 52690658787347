import React, { Component } from 'react';

import agent from '../../agent';
import { SlideList } from '../Gide/SlideList/SlideList';
import ArticlePreview from '../ArticlePreview';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';
import GideImage from '../Shared/Image/GideImage';

class GideSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.load = this._load.bind(this);
  }

  async _load() {
    try {
      if (this.props.slide.data.embed) {
        let resp = await agent.Slides.forArticle(this.props.slide.data.gide);
        const gideDefaultCollapsed =
          this.props.slide.data.embed &&
          !this.props.slide.data.notCollapsible &&
          this.props.slide.data.defaultCollapsed;
        this.setState({ slides: resp.slides, collapsed: gideDefaultCollapsed });
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { slide, viewMode } = this.props;
    const collapsible =
      slide.data.embed && !this.props.slide.data.notCollapsible;
    const displayBorder =
      slide.data.embed &&
      (viewMode === 'SLIDE' || slide.data.displayBorder === true);
    const classes = classNames(
      'gideSlide',
      (viewMode === 'SWIPE' || viewMode === 'SLIDE' || viewMode === 'SCROLL') &&
      displayBorder
        ? 'gideSlideBorder'
        : '',
    );
    return (
      <div className="gideSlide">
        {/* Need to handle infinite recursion
          Examples:
          1. Gide 1 Embeds Gide 2, Gide 2 Embeds Gide 1
          2. Gide 1 Embeds Gide 2, Gide 2 Embeds Gide 3, Gide 3 Embeds Gide 2
          There are many variations but it needs to short circuit the load.
        */}
        {viewMode !== 'SWIPE' && slide.data.embed ? (
          this.state.slides ? (
            // Filtering the slides this way is a temporary hack to handle the recursion problem
            // so that it does not completely break the application. Need to discuss a good solution
            // for this with Grant and Dale. This seems to work on case 1 but I have not thoroughly tested it.
            // Pretty sure it will fail on case 2
            // UPDATE: Jeff says we are going to add the following 2 rules
            // 1. Can't embed a gide that is embedded in another gide.
            // 2. Can't embed a gide that has pages.
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Aileron-Regular',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: '1.43',
                    letterSpacing: '0.3px',
                    color: 'rgba(156, 160, 165, 0.9)',
                  }}
                >
                  <GideImage 
                    src={slide.data.gide.author.image}
                    alt={slide.data.gide.author.username}
                    style={{
                      width: '24px',
                      height: '24px',
                      border: 'solid 1px white',
                      borderRadius: '50%',
                    }}
                  />
                  <span>{slide.data.gide.author.username}</span>
                </div>
                {collapsible && (
                  <Icon
                    onClick={() => {
                      this.setState({ collapsed: !this.state.collapsed });
                    }}
                    circular
                    name={this.state.collapsed ? 'plus' : 'close'}
                    color="grey"
                    style={{ cursor: 'pointer', marginBottom: '2px' }}
                  />
                )}
              </div>
              {!this.state.collapsed && (
                <SlideList
                  // isEmbeddedGide={true}
                  classes={classes}
                  slides={this.state.slides.filter(s => s.id !== slide.id)}
                />
              )}
            </>
          ) : (
            'Loading..'
          )
        ) : (
          <ArticlePreview article={slide.data.gide} />
        )}
      </div>
    );
  }
}
export default GideSlide;
