import React from 'react';
import { connect } from 'react-redux';
import { Flag, Dropdown } from 'semantic-ui-react';

import Link from './Link';
import agent from '../agent';
import { history } from '../store';
import {
  urlForArticle,
  getFormattedDateDiff,
  getMaxLengthString,
  storyTellingImage,
} from '../utils/helperFunctions';
import { MODAL_OPEN } from '../constants/actionTypes';
import GideImage from './Shared/Image/GideImage';

const mapDispatchToProps = dispatch => ({
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
});

class ArticlePreview extends React.Component {
  render() {
    const {
      article,
      onDeleteArticle,
      onRecoverArticle,
      currentUser,
    } = this.props;
    if (!article) return null;
    const articleMenuTrigger = (
      <GideImage 
        style={{ width: '24px', height: '24px' }}
        src="/icons/nav/threedots/horizontal.svg"
        alt={`□`}
      />
    );
    const onDeleteArticleClick = async () => {
      if (!onDeleteArticle || !window.confirm('Are you sure?')) return;
      await agent.Articles.del(article, { full: article.trashedDate !== null });
      onDeleteArticle({ id: article.id });
    };
    const onRecoverArticleClick = async () => {
      if (!onRecoverArticle || !window.confirm('Are you sure?')) return;
      await agent.Articles.recover(article);
      onRecoverArticle({ id: article.id });
    };

    const onAddToCollectionClick = article => {
      this.props.openModal({
        modalType: 'SelectCollectionModal',
        modalProps: {
          saveCallback: ({ collection, collectionId }) => {
            agent.Collections.addItem(collection, article)
          },
          saveButtonTitle: 'OK',
        },
      });
    };
    const onAddToFolderClick = article => {
      this.props.openModal({
        modalType: 'SelectFolderModal',
        modalProps: {
          saveCallback: ({ folder, folderId }) => {
            agent.Folders.addArticle(folder, article)
          },
          saveButtonTitle: 'OK',
        },
      });
    };
    return (
      <div className="articlePreviewSquare">
        <div className="articleInfo">
          <Link to={urlForArticle(article)}>
            <div className="articleHeader">
              {article.author &&
                <GideImage 
                  src={article.author.image}
                  alt={`□`}
                  className="articleAuthorImage"
                />
              }
              {article.author &&
                <div className="articleAuthor">{article.author.username}</div>
              }
              <div className="articleDate">
                {article.createdAt !== null &&
                  getFormattedDateDiff(new Date(), new Date(article.createdAt))}
              </div>
            </div>
            <div className="articleImageWrap">
              <GideImage className="articleImage" src={article.image || storyTellingImage} alt={`□`} />
            </div>

            <div className="articleContent">
              <div className="locals">
                <Flag name="gb" />
              </div>
              <div className="articleTitle">
                {getMaxLengthString(38, article.title || "Untitled")}
              </div>
            </div>
          </Link>
          <div className="articleFooter">
            <div className="flexRowFlexNormal">
              <GideImage 
                className='color-secondary-500-svg'
                style={{ width: '24px', height: '24px' }}
                src="/icons/nav/eye/eye-open.svg"
                alt={`□`}
              />
              <span style={{ marginLeft: '3px' }}>2789k</span>
            </div>
            <div className="flexRowFlexNormal">
              <GideImage 
                className="color-secondary-500-svg"
                style={{ width: '24px', height: '24px' }}
                src="/icons/nav/thumbs-up.svg"
                alt={`□`}
              />
              <span>100%</span>
            </div>

            {currentUser && article.author &&
              article.author.username === currentUser.username && (
                <Dropdown trigger={articleMenuTrigger} icon={null}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text={'Settings'}
                      icon="setting"
                      onClick={() => {
                        history.push(`/editor/${article.id}`);
                      }}
                    />
                    {onDeleteArticle && (
                      <Dropdown.Item
                        text={article.trashedDate !== null ? 'Delete' : 'Trash'}
                        icon="trash"
                        onClick={() => onDeleteArticleClick(article)}
                      />
                    )}
                    {onRecoverArticle && article.trashedDate !== null && (
                      <Dropdown.Item
                        text={'Recover from trash'}
                        onClick={() => onRecoverArticleClick(article)}
                      />
                    )}
                    <Dropdown.Item
                      text={'Add to Collection'}
                      icon="add"
                      onClick={() => {
                        onAddToCollectionClick(article);
                      }}
                    />
                    <Dropdown.Item
                      text={'Add to Folder'}
                      icon="add"
                      onClick={() => {
                        onAddToFolderClick(article);
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              )}

            {/* Spacer */}
            {(!currentUser ||
              article.author.username !== currentUser.username) && (
                <div style={{ width: '24px' }} />
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ArticlePreview);
