import React, { CSSProperties } from 'react';
import GideTilePreview from '../GidePreview/GideTilePreview/GideTilePreview';
import { Link } from 'react-router-dom';
import { urlForArticle } from '../../../../utils/helperFunctions';

export interface SlidePreviewProps {
  id: string;
  slug: string;
  image: string;
  title: string;
  language: string;
  description?: string;
  authorImage: string;
  author: string;
  createdAt: Date;
  updatedAt: Date;
  views: number;
  slidePosition: number;
  style?: CSSProperties;
  isReadOnly: boolean;
}

export const SlidePreview: React.FC<SlidePreviewProps> = (props) => {
  const backgroundColor = "var(--COLOR-RED-100)";
  return (
    <Link to={`${urlForArticle({ slug: props.slug, author: { username: props.author } })}${props.slidePosition !== undefined ? (`?slide=${props.slidePosition + 1}`) : ''}`} target='_blank'>
      <div style={{
        width: 188,
        height: 225,
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        margin: "auto",
      }}>
        <GideTilePreview {...props} disableLink={true} />
      </div>
    </Link>
  );
}
