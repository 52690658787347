import React from 'react';
import { Dropdown, List, Image } from 'semantic-ui-react';
import moment from 'moment';

import { history } from '../../store';

const attendingOptions = [
  {
    text: 'No Answer',
    value: '',
  },
  {
    text: 'Maybe',
    value: 'MAYBE',
  },
  {
    text: 'Yes',
    value: 'YES',
  },
  {
    text: 'No',
    value: 'NO',
  },
];

const ArticleMeta = props => {
  const article = props.article;
  const onUserClick = user => {
    const url = `/@${user.username}`;
    history.push(url);
  };
  return (
    <div className="article-meta">
      {article.date && (
        <div>
          <div>{moment(article.date).format('LLLL')}</div>
          {article.useAttending && (
            <div>
              <span>Attending?</span>{' '}
              <Dropdown
                onChange={props.onChangedAttending}
                inline
                options={attendingOptions}
                defaultValue={attendingOptions[0].value}
              />
            </div>
          )}
          {article.showAttending && (
            <List>
              {(props.attendances || []).map((attendance, i) => {
                return (
                  <List.Item
                    key={i}
                    onClick={() => onUserClick(attendance.user)}
                    className="link"
                  >
                    <Image avatar src={attendance.user.image} />
                    <List.Content>
                      <List.Header as="a">
                        {attendance.user.username}
                      </List.Header>
                    </List.Content>
                    <div>- {`"${attendance.answer}"`}</div>
                  </List.Item>
                );
              })}
            </List>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticleMeta;
