import React, { useEffect } from 'react';
import { User } from '../../models/User';
import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducer';
import Home from './Home';
import { HOME_PAGE_GIDES_LOADED } from '../../constants/actionTypes';
import { GideGroup } from '../../models/GideEnum';
import { getRecommendedGides, getCustomDomain } from '../../utils/helperFunctions';
import HomeWebsite from './index';
import { Article } from '../../models/Article';
import { TitleSlideProcessorInfo } from '../../reducers/FileProcessing/fileProcessing';
import styles from './_home.module.scss';
interface HomeStoreProps {
  currentUser: User | null;
  currentUserName: string;
  gideGroups: GideGroup[];
  searchText: string;
}

export interface HomeConnectProps {
  token?: string | null;
  onShowSignup?: () => void;
  onNavigateToAboutGides: () => void;
  onNavigateToHowToUseGides: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
}

interface HomeConnectActions {
  onLoad: () => void;
  clearSearch: () => void; 
}

export default function HomeConnect(props: HomeConnectProps) {
  const dispatch = useDispatch();
  const actions: HomeConnectActions = {
    onLoad: async () => dispatch({ type: HOME_PAGE_GIDES_LOADED, payload: await getRecommendedGides() }),
    clearSearch: () => dispatch({ type: HOME_PAGE_GIDES_LOADED, payload: getRecommendedGides() }),
  };
  const homeStoreProps: HomeStoreProps = useSelector<AppState, HomeStoreProps>(
    (state: AppState): HomeStoreProps => {
      const titleProcessingList: TitleSlideProcessorInfo[] = state.fileProcessor.titleSlideProcessorList;
      return {
        currentUserName: state.common.currentUser ? state.common.currentUser.username : '',
        currentUser: state.common.currentUser,
        gideGroups: titleProcessingList.length > 0 && state.articleList.gideGroups
          ? state.articleList.gideGroups.map((gg: GideGroup) => {
          return {
            ...gg,
            gideList: gg.gideList.map((gide: Article) => {
              const gideTitleProcessingInfo = titleProcessingList.find(g => g.gideId === gide.id);
              if(gideTitleProcessingInfo) {
                return {
                  ...gide,
                  slideZero: {
                    ...gide.slideZero,
                    image: URL.createObjectURL(gideTitleProcessingInfo.dataUrl)
                  }
                }
              } else {
                return gide;
              }
            })
          }
        }) : state.articleList.gideGroups,
        searchText: state.articleList.searchText,
      };
    },
  );
  useEffect(() => {
    actions.onLoad();
  }, [homeStoreProps.currentUserName]);
  // const getRecommendedGides = async () => {
  //   const gideList = !isNil(props.token) ? await agent.Articles.feed() : await agent.Articles.all();
  //   return { groupTitle: 'Recommended', gideList };
  // };
  if(!getCustomDomain()) {
    return (
      <div className={styles.homeConnectContainer}>
        <Home
          currentUser={homeStoreProps.currentUser}          
          gideGroups={homeStoreProps.gideGroups}
          searchText={homeStoreProps.searchText}
          exitSearch={actions.clearSearch}
          onShowSignup={props.onShowSignup}
          onNavigateToAboutGides={props.onNavigateToAboutGides}
          onNavigateToHowToUseGides={props.onNavigateToHowToUseGides}
        />
      </div>
    );
  } else {
    // This component renders the website.
    return <HomeWebsite />
  }
}
