import React from 'react';
import styles from './_gide-title-slide.module.scss';

export interface GideTitleSlidePlaceholderProps {
  onClick: () => void;
}

export default function GideTitleSlidePlaceholder(props: GideTitleSlidePlaceholderProps) {
  return (
    <div
      className={`${styles.gideTitleSlideContainer} ${styles.placeHolderContainer}`}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        props.onClick && props.onClick();
      }}
      style={{cursor: 'pointer'}}
    >
      <div className={styles.gideTitle}>Add an image and title</div>
    </div>
  );
}
