import React, { ReactNode } from 'react';
import styles from './_search-bar-with-results.module.scss';
import { SearchCriteria } from '../../Header/GidesAppHeader/GidesAppHeader';
import SearchBar from '../../SearchBar';

export interface SearchBarWithResultsProps {
  children?: ReactNode;
  searchPlaceHolder?: string;
  searchOnKeyStroke?: boolean;
  onSearch: (searchCriteria: SearchCriteria) => void;
  onClearSearch: () => void;
}

export default function SearchBarWithResults(props:SearchBarWithResultsProps) {
  return (
    <div className={styles.searchBarWithResultsContainer}>
      <div className={styles.searchContainer}>
        <SearchBar
          style={{ minHeight: '42px', maxHeight: '42px', borderRadius: '21px', maxWidth: '360px', width: '100%', marginLeft: '5px' }}
          focusOnLoad={true}
          onSearch={props.onSearch}
          searchOnKeyStroke={props.searchOnKeyStroke}
          onToggleSearch={props.onClearSearch}
          placeholder={props.searchPlaceHolder ? props.searchPlaceHolder : 'search...'}
        />
      </div>
      <div className={styles.searchResuls}>{props.children}</div>
    </div>
  );
}
