import React, { Component } from 'react';
import { Icon, Modal, Button } from 'semantic-ui-react';
import { stripTags, getParentHeaderSlides } from '../../utils/helperFunctions';
export class HeaderNavigation extends Component {
  generateTableOfContents(slides) {
    let headers = [];
    let levelCounts = [0, 0, 0, 0, 0, 0];
    slides
      .filter(s => s.slideType === 'HEADER' && s.data.type !== 'END')
      .forEach(h => {
        if (h.data.level === 0) {
          let header = {
            id: h.id,
            slidePosition: h.position,
            level: h.data.level,
            title: h.data.title,
            idx: 0,
          };
          headers.push(header);
        } else {
          levelCounts = levelCounts.map(
            (lc, idx) => (idx + 1 > h.data.level ? 0 : lc),
          );
          levelCounts[!h.data.level ? 0 : h.data.level - 1]++;
          let header = {
            id: h.id,
            slidePosition: h.position,
            level: h.data.level,
            title: h.data.title,
            idx: levelCounts
              .filter(i => i > 0)
              .reduce(
                (idx, levelCount, currentIndex) =>
                  currentIndex && currentIndex + 1 <= h.data.level
                    ? idx + '.' + levelCount
                    : idx,
              ),
          };
          headers.push(header);
        }
      });
    return headers;
  }

  render() {
    const headers = this.generateTableOfContents(this.props.slides);

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="headerNavigationModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={true}
      >
        <Modal.Content>
          <div className="modalHeader">
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Table of Contents</span>
          </div>
          <Modal.Description>
            <div style={{ backgroundColor: 'white', margin: '20px' }}>
              {headers.map(headerSlide => {
                const tocClassName = 'tocLevel' + headerSlide.level;
                return (
                  <div
                    key={headerSlide.idx}
                    onClick={() =>
                      this.props.onNavigate({
                        slideId: headerSlide.id,
                        parentHeaderSlides: getParentHeaderSlides(this.props.slides.find(s => s.id === headerSlide.id), this.props.slides)
                      })
                    }
                    data-header-id={headerSlide.id}
                    className="flexRowFlexNormal"
                    style={{ marginBottom: '5px' }}
                  >
                    {headerSlide.idx !== 0 && (
                      <div className={tocClassName}>{headerSlide.idx}</div>
                    )}
                    <div className="tocTitle">
                      {stripTags(headerSlide.title)}
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default HeaderNavigation;
