import React, { Component, CSSProperties } from 'react';
import { Icon, Progress } from 'semantic-ui-react';
import { v4 } from 'uuid';
import GideImage from '../../Shared/Image/GideImage';

export enum AudioPlayerType {
  AudioSlide = 1,
  AudioCaption = 2,
  Other = 3,
}
interface AudioPlayerProps {
  autoPlay: boolean;
  audioEnded?: (this: GlobalEventHandlers, ev: Event) => void;
  playerType: AudioPlayerType;
  fileTypeIcon?: string;
  src: string;
  color?: string;
  backgroundColor?: string;
  style?: CSSProperties;
}
interface AudioPlayerState {
  playing: boolean;
  ready: boolean;
  elapsedTime: string;
  remainingTime: string;
  audioMuted: boolean;
  percentComplete: number;
  volumeLevel: number;
  duration: number;
}
export default class AudioPlayer extends Component<AudioPlayerProps, AudioPlayerState> {
  constructor(props: AudioPlayerProps) {
    super(props);

    this.state = {
      // duration: 100, TODO: Set duration when recording. It takes too long to compute when audio starts
      playing: this.props.autoPlay ? this.props.autoPlay : false,
      ready: false,
      elapsedTime: '00:00',
      remainingTime: '00:00',
      percentComplete: 0,
      audioMuted: false,
      volumeLevel: 0,
      duration: 0,
    }
  };

  audioPlayer?: HTMLAudioElement | undefined;
  downloadLink?: HTMLAnchorElement | undefined;
  handlePlay(e: any): void {
    if (this.audioPlayer) {
      if (this.audioPlayer.paused) {
        this.audioPlayer.play();
        this.setState({ playing: true });
      } else {
        this.audioPlayer.pause();
        this.setState({ playing: false });
      }
    }
  }

  updateAudioVolume(e: any) {
    if (this.audioPlayer) {
      const newVolume = e.target.value > 0 ? e.target.value / 100 : 0;
      this.audioPlayer.volume = newVolume;
      this.setState({ volumeLevel: e.target.value })
    }
  }

  muteAudio(e: any) {
    if (this.audioPlayer) {
      this.audioPlayer.muted = false;
      this.setState({ audioMuted: false });
    }
  }

  unmuteAudio(e: any) {
    if (this.audioPlayer) {
      this.audioPlayer.muted = true;
      this.setState({ audioMuted: true });
    }
  }

  handleStop() {
    if (this.audioPlayer) {
      this.audioPlayer.currentTime = 0;
      this.setState({ playing: false });
      this.audioPlayer.pause();
    }
  }

  componentDidMount() {
    if (this.audioPlayer) {
      this.audioPlayer.onloadedmetadata = () => {
        if (this.audioPlayer) {
          this.setState({
            duration: this.audioPlayer.duration,
            ready: true,
            volumeLevel: this.audioPlayer.volume * 100
          });
        }
      };


      this.audioPlayer.onpause = () => {
        this.setState({ playing: false });
      };

      this.audioPlayer.ontimeupdate = () => {
        // This is a hack to fix a bug in chrome where the duration does not properly get updated.
        if (this.audioPlayer && this.audioPlayer.duration === Infinity && this.audioPlayer.currentTime !== 1000000) {
          this.audioPlayer.currentTime = 1000000;
          this.audioPlayer.currentTime = 0;
          return;
        }

        if (this.audioPlayer) {
          const elapsedTimeAsDate = new Date(0, 0);
          const remainingTimeAsDate = new Date(0, 0);

          const remainingTime = this.audioPlayer.duration && this.audioPlayer.duration !== Infinity ? this.audioPlayer.duration - this.audioPlayer.currentTime : 0;

          elapsedTimeAsDate.setSeconds(this.audioPlayer.currentTime);
          const formattedElapsedTime = elapsedTimeAsDate.toTimeString().slice(3, 8);

          remainingTimeAsDate.setSeconds(remainingTime);
          const formattedRemainingTime = remainingTimeAsDate.toTimeString().slice(3, 8);

          const percentComplete = this.audioPlayer.duration && this.audioPlayer.duration !== Infinity && this.audioPlayer.duration > 0
            ? this.audioPlayer.currentTime / this.audioPlayer.duration : 0;

          this.setState({
            elapsedTime: formattedElapsedTime,
            percentComplete: percentComplete * 100,
            remainingTime: formattedRemainingTime,
            duration: this.audioPlayer.duration
          });
        }
      }
      if (this.props.audioEnded) {
        this.audioPlayer.onended = this.props.audioEnded;
      }
    }
  }
  render() {
    if (this.props.playerType === AudioPlayerType.AudioSlide) {
      return (
        <div className={`customAudioPlayer audioSlide${this.props.color ? ' ' + this.props.color : ''}`}
          style={this.props.style ? this.props.style : undefined}
          onClick={(e) => e.stopPropagation()}
        >
          <Progress size="medium"
            percent={this.state.percentComplete}
          />
          <div className="timeInfo">
            <div className="customAudioPlayerTimeFonts audioSlideFonts">
              {this.state.elapsedTime}
            </div>
            <div className="customAudioPlayerTimeFonts audioSlideFonts">
              {this.state.remainingTime}
            </div>
          </div>
          <div className="playerControls">
            <GideImage 
              className={`pointer color-blue-100-svg`}
              onClick={this.muteAudio.bind(this)}
              width="24px" height="24px"
              src={`${this.props.fileTypeIcon}`}
              alt="Volume"
            />
            <div className="centerControls">
              <GideImage 
                style={{ width: '24px', height: '24px' }}
                className={`pointer color-blue-100-svg`}
                onClick={() => {
                  if(this.audioPlayer) {
                    this.audioPlayer.currentTime = this.audioPlayer.currentTime > 10 ? this.audioPlayer.currentTime - 10 : 0;
                  }
                }}
                src='/icons/creationprocess/advance/rewind-10s.svg'
                alt={`play audio`}
              />
              {!this.state.playing && (
                <GideImage 
                  style={{ width: '24px', height: '24px' }}
                  className={`pointer color-blue-100-svg`}
                  onClick={this.handlePlay.bind(this)}
                  src='/icons/nav/media/player/play.svg'
                  alt={`play audio`}
                />
              )}
              {this.state.playing && (
                <GideImage style={{ width: '24px', height: '24px' }}
                  className={`pointer color-blue-100-svg`}
                  onClick={this.handlePlay.bind(this)}
                  src='/icons/nav/media/player/pause.svg'
                  alt="pause audio"
                />
              )}
              <GideImage 
                style={{ width: '24px', height: '24px' }}
                className={`pointer color-blue-100-svg`}
                onClick={() => {
                  if(this.audioPlayer && this.state.duration > 0) {
                    this.audioPlayer.currentTime = this.audioPlayer.currentTime + 10 <= this.state.duration ? this.audioPlayer.currentTime + 10 : this.state.duration;
                  }
                }}
                src='/icons/creationprocess/advance/advance-10s.svg'
                alt={`play audio`}
              />
            </div>

            <div className="actions">
              <GideImage 
                className={`pointer color-blue-100-svg`}
                onClick={() => {
                  this.downloadLink && this.downloadLink.click();
                }}
                width="24px" height="24px"
                src={`/icons/content-alteration/download.svg`}
                alt="Volume"
              />
              <a href={this.props.src} style={{display: 'hidden'}}
                target="_blank"
                download={"audioDownload.mp3"}
                ref={(downloadLink: HTMLAnchorElement) => { this.downloadLink = downloadLink }}
              ></a>
            </div>
          </div>

          <audio
            id={v4()}
            ref={(audioPlayer: HTMLAudioElement) => { this.audioPlayer = audioPlayer }}
            src={this.props.src}
            autoPlay={this.props.autoPlay}
          />
        </div>
      );
    }
    else if (this.props.playerType === AudioPlayerType.AudioCaption) {
      return (
        <div className={`customAudioPlayer${this.props.color ? ' ' + this.props.color : ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          {!this.state.playing && (
            <GideImage style={{ width: '24px', height: '24px' }}
              className='pointer color-blue-100-svg'
              onClick={this.handlePlay.bind(this)}
              src='/icons/nav/media/player/play.svg'
              alt={`play audio`}
            />
          )}
          {this.state.playing && (
            <GideImage style={{ width: '24px', height: '24px' }}
              className='pointer color-blue-100-svg'
              onClick={this.handlePlay.bind(this)}
              src='/icons/nav/media/player/pause.svg'
              alt="pause audio"
            />
          )}

          <div className="customAudioPlayerTimeFonts" style={{ marginLeft: '18px' }}>
            {this.state.elapsedTime}
          </div>
          <Progress size="tiny"
            percent={this.state.percentComplete}
            style={{ marginLeft: '3px' }}
          />
          <div className="customAudioPlayerTimeFonts" style={{ marginLeft: '2px' }}>
            {this.state.remainingTime}
          </div>
          {this.state.audioMuted === true && (
            <GideImage style={{ marginLeft: '12px' }}
              className='pointer color-blue-100-svg'
              onClick={this.muteAudio.bind(this)}
              width="24px" height="24px"
              src='/icons/nav/media/audio/volume/mute.svg'
              alt="Volume"
            />
          )}
          {this.state.audioMuted === false && (
            <GideImage style={{ marginLeft: '12px' }}
              className='pointer color-blue-100-svg'
              width="24px" height="24px"
              onClick={this.unmuteAudio.bind(this)}
              src='/icons/nav/media/audio/volume/loud.svg'
              alt="Volume"
            />
          )}
          <input
            className={`audioVolumeSlider${this.props.color ? ' ' + this.props.color : ''}`}
            type="range" min="0"
            onChange={this.updateAudioVolume.bind(this)}
            max="100" value={this.state.volumeLevel}
          ></input>
          <audio
            id={v4()}
            ref={(audioPlayer: HTMLAudioElement) => { this.audioPlayer = audioPlayer }}
            src={this.props.src}
            autoPlay={this.props.autoPlay}
          />
        </div>
      );
    } else {
      return (
        <span className={`customAudioPlayer${this.props.color ? ' ' + this.props.color : ''}`}
        >
          <span style={{ marginRight: '5px', fontSize: '14px' }}>
            Audio caption
          </span>
          <span className="audioCaptionIcon"
            onClick={this.handlePlay.bind(this)}>
            <Icon
              name={this.state.playing ? 'pause' : 'play'}
            />
            <audio
              id={v4()}
              ref={(audioPlayer: HTMLAudioElement) => { this.audioPlayer = audioPlayer }}
              src={this.props.src}
              autoPlay={this.props.autoPlay}
            />
          </span>
        </span>
      );
    }
  }
}

