import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Menu,
  Checkbox,
  Icon,
  Input,
  Dropdown,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import Article from './Article';
import { history } from '../store';
import { urlForArticle } from '../utils/helperFunctions';
import agent from '../agent';
import ArticleList from './ArticleList';
import ChannelList from './ChannelList';
import CollectionList from './CollectionList';
import UserList from './UserList';
import {
  FOLLOW_USER,
  UNFOLLOW_USER,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  SET_VIEW_MODE,
  LOGOUT,
  DELETE_CHANNEL_NO_REDIRECT,
  RECOVER_ARTICLE,
  DELETE_ARTICLE_NO_REDIRECT,
} from '../constants/actionTypes';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import GideImage from './Shared/Image/GideImage';
const DnDCalendar = withDragAndDrop(BigCalendar);

let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

const collectionContentTypeOptions = [
  { key: 'ANY', value: 'ANY', text: 'Any' },
  { key: 'ARTICLE', value: 'ARTICLE', text: 'Gide' },
  { key: 'SLIDE', value: 'SLIDE', text: 'Slide' },
];

const collectionTypeOptions = [
  { key: 'PUBLIC', value: 'PUBLIC', text: 'Public' },
  { key: 'PRIVATE', value: 'PRIVATE', text: 'Private' },
];

const collectionTypeOptionsAdmin = [
  { key: 'PUBLIC', value: 'PUBLIC', text: 'Public' },
  { key: 'PRIVATE', value: 'PRIVATE', text: 'Private' },
  { key: 'LANDING', value: 'LANDING', text: 'Landing' },
];

class Collections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newCollectionName: '',
    };

    this.handleAddCollection = this._handleAddCollection.bind(this);
    this.handleRemoveCollection = this._handleRemoveCollection.bind(this);

    // this.onEmptyTrash = async () => {
    //   const confirmed = window.confirm('Are you sure?');
    //   if (!confirmed) return;
    //
    //   try {
    //     await agent.Articles.emptyTrash();
    //     this.props.handleReload();
    //   } catch (e) {
    //     console.log('error', e);
    //     alert('There was a problem, please try again later.');
    //   }
    // };
  }

  async _handleRemoveCollection(collection) {
    let confirmed = window.confirm('Are you sure?');
    if (!confirmed) return;
    try {
      await agent.Collections.remove(collection);
      this.props.handleReload();
    } catch (e) {
      alert('Please try again.');
      console.log(e);
    }
  }

  async _handleAddCollection() {
    const collection = {
      // channel: this.state.channel._id,
      title: this.state.newCollectionName,
      type: this.state.newCollectionType,
      limit: this.state.newCollectionContentType,
    };
    try {
      await agent.Collections.create(collection);
      this.props.handleReload();
      this.setState({
        newCollectionName: '',
        newCollectionType: '',
        newCollectionContentType: '',
      });
    } catch (e) {
      console.log('error', e);
      alert('There was a problem, please try again later.');
    }
  }

  componentWillMount() {
    this.props.onSetViewMode('SLIDE');
  }

  render() {
    const { collections, favorites, currentUser } = this.props;

    if (!collections) {
      return <div>Loading..</div>;
    }
    return (
      <div style={{ padding: 10 }}>
        <h2>Collections</h2>
        <Input
          value={this.state.newCollectionName || ''}
          onChange={ev => this.setState({ newCollectionName: ev.target.value })}
          placeholder="Name"
        />
        <Dropdown
          placeholder="Limit to type"
          selection
          options={collectionContentTypeOptions}
          onChange={(ev, data) =>
            this.setState({ newCollectionContentType: data.value })
          }
          value={this.state.newCollectionContentType || ''}
        />
        <Dropdown
          placeholder="Type"
          selection
          options={
            currentUser.isAdmin
              ? collectionTypeOptionsAdmin
              : collectionTypeOptions
          }
          onChange={(ev, data) =>
            this.setState({ newCollectionType: data.value })
          }
          value={this.state.newCollectionType || ''}
        />
        <Button
          onClick={this.handleAddCollection}
          icon="add"
          disabled={!this.state.newCollectionName.length}
          content="Add Collection"
        />
        <CollectionList
          collections={collections}
          showRemove={true}
          onRemove={this.handleRemoveCollection}
          iterate={true}
          currentUser={currentUser}
          onDeleteArticle={this.props.onDeleteArticle}
        />
        <h2 style={{ clear: 'both' }}>Favorites</h2>
        <ArticleList
          articles={favorites}
          layout="SQUARE"
          currentUser={currentUser}
          onRecoverArticle={this.props.onRecoverArticle}
          onDeleteArticle={this.props.onDeleteArticle}
          center={true}
        />
      </div>
    );
  }
}

const Connections = ({ connections }) => {
  if (!connections) {
    return <div>Loading..</div>;
  }
  return (
    <div style={{ padding: 10 }}>
      <h2>Following</h2>
      {connections.following && !connections.following.length ? (
        <span>None.</span>
      ) : (
        <UserList users={connections.following} />
      )}
      <h2>Followers</h2>
      {!connections.followers ||
      (connections.followers && !connections.followers.length) ? (
        <span>None.</span>
      ) : (
        <UserList users={connections.followers} />
      )}
    </div>
  );
};

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.updateStateCheckbox = field => (ev, data) => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: data.checked });
      this.setState(newState);
    };

    this.state = {
      view: 'CALENDAR',
      date: 'MAY 2018',
      showOwn: true,
      showOthers: true,
    };

    this.onEventResize = (type, { event, start, end, allDay }) => {
      this.setState(state => {
        state.events[0].start = start;
        state.events[0].end = end;
        return { events: state.events };
      });
    };

    this.onEventDrop = ({ event, start, end, allDay }) => {
      console.log(start);
    };
    this.handleEventSelected = this._handleEventSelected.bind(this);
  }

  _handleEventSelected(event) {
    history.push(`/gide/${event.event.slug}`);
  }

  render() {
    const { currentUser } = this.props;
    const { view, date, showOwn, showOthers } = this.state;
    let events = this.props.events.map(e => {
      return {
        id: e._id,
        title: e.title,
        start: new Date(e.date),
        end: new Date(e.date),
        event: e,
      };
    });

    events = events.filter(e => {
      if (!showOwn && !showOthers) return false;
      if (showOwn && showOthers) return true;
      const isAuthor =
        e.event.author._id.toString() === currentUser.id.toString();
      if (!showOwn && isAuthor) return false;
      if (!showOthers && !isAuthor) return false;
      return true;
    });
    return (
      <div className="calendarTab">
        <div
          className="calendarHeader"
          style={{
            width: '100%',
            display: 'inline-block',
            padding: '15px 10px 7px 19px',
          }}
        >
          <div style={{ float: 'left' }}>
            {view === 'CALENDAR' && <span>{date}</span>}
          </div>
          <div style={{ float: 'right' }}>
            <Checkbox
              label="My Events"
              checked={this.state.showOwn}
              onChange={this.updateStateCheckbox('showOwn')}
            />
            <Checkbox
              label="Other's Events"
              checked={this.state.showOthers}
              onChange={this.updateStateCheckbox('showOthers')}
            />
            <span>
              <Icon
                name="unordered list"
                className="slideViewIcon pointer"
                onClick={() => this.setState({ view: 'GIDE' })}
                style={{ margin: '0 10px 0 0' }}
              />
            </span>
            <span>
              <GideImage
                className="svgIcon pointer"
                src="/icons/creationprocess/events.svg"
                onClick={() => this.setState({ view: 'CALENDAR' })}
                alt={`□`}
              />
            </span>
            <span>
              <Dropdown
                trigger={
                  <GideImage
                    className="svgIcon pointer"
                    src="/icons/nav/sort.svg"
                    alt={`□`}
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Checkbox label="Slides" checked={true} />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Checkbox label="Gides" checked={true} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        </div>
        <div style={{ height: 1000, clear: 'both', background: 'white' }}>
          {view === 'CALENDAR' && (
            <DnDCalendar
              events={events}
              views={allViews}
              step={60}
              showMultiDayTimes
              defaultDate={new Date()}
              onEventDrop={this.onEventDrop}
              onEventResize={this.onEventResize}
              onSelectEvent={this.handleEventSelected}
              onSelectSlot={slotInfo =>
                alert(
                  `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
                    `\nend: ${slotInfo.end.toLocaleString()}` +
                    `\naction: ${slotInfo.action}`,
                )
              }
            />
          )}
          {view === 'GIDE' && (
            <Article
              type="CALENDAR"
              username={this.props.username}
              events={this.props.events}
            />
          )}
        </div>
      </div>
    );
  }
}

const channelAccessOptions = [
  { key: 'PUBLIC', value: 'PUBLIC', text: 'Public' },
  { key: 'PUBLIC_FOLLOW', value: 'PUBLIC_FOLLOW', text: 'Require Follow' },
  { key: 'INVITE', value: 'INVITE', text: 'Invite only' },
  { key: 'REQUEST', value: 'REQUEST', text: 'User Can Request' },
  { key: 'WEBSITE', value: 'WEBSITE', text: 'Website' },
];

const Channels = ({
  channels,
  newChannelName,
  handleNewChannelNameChanged,
  handleNewChannelAccessChanged,
  handleAddChannel,
  newChannelAccess,
  onDeleteChannel,
  currentUser,
}) => {
  return (
    <div>
      <Input
        value={newChannelName}
        onChange={handleNewChannelNameChanged}
        placeholder="Name"
      />
      <Dropdown
        placeholder="Public/Private"
        selection
        options={channelAccessOptions}
        onChange={handleNewChannelAccessChanged}
        value={newChannelAccess || 'PUBLIC'}
      />
      <Button onClick={handleAddChannel} icon="add" content="Add" />
      <div>{channels && channels.length ? channels.length : ``} Channels</div>
      <ChannelList
        currentUser={currentUser}
        channels={channels}
        onDeleteChannel={onDeleteChannel}
      />
    </div>
  );
};

const EditProfileSettings = ({ isUser }) => {
  if (isUser) {
    return (
      <Link
        to="/settings"
        className="btn btn-sm btn-outline-secondary action-btn editProfileButton"
        title="Edit Profile"
        style={{ border: 'none' }}
      >
        <GideImage className="pointer whites-normal-800-svg"
          style={{width: '30px'}}
          src="/icons/nav/settings.svg"
          alt={`settings`}
        />
      </Link>
    );
  }
  return null;
};

const Logout = ({ isUser, onLogout }) => {
  if (isUser) {
    return (
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={onLogout}
        style={{ position: 'absolute', left: 0, top: 0 }}
      >
        Logout.
      </button>
    );
  }
  return null;
};

const FollowUserButton = props => {
  if (props.isUser || !props.currentUser) {
    return null;
  }

  let classes = 'btn btn-sm action-btn';
  if (props.user.following) {
    classes += ' btn-secondary';
  } else {
    classes += ' btn-outline-secondary';
  }

  const handleClick = ev => {
    ev.preventDefault();
    if (props.user.following) {
      props.unfollow(props.user.username);
    } else {
      props.follow(props.user.username);
    }
  };

  return (
    <button className={classes} onClick={handleClick}>
      <i className="ion-plus-round" />
      &nbsp;
      {props.user.following ? 'Unfollow' : 'Follow'} {props.user.username}
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  const tab = ownProps.match.params.tab;
  return {
    ...state.articleList,
    channels: state.profile.channels,
    currentUser: state.common.currentUser,
    profile: state.profile,
    tab,
  };
};

const mapDispatchToProps = dispatch => ({
  onFollow: username =>
    dispatch({
      type: FOLLOW_USER,
      payload: agent.Profile.follow(username),
    }),
  onLoad: payload => dispatch({ type: PROFILE_PAGE_LOADED, payload }),
  onUnfollow: username =>
    dispatch({
      type: UNFOLLOW_USER,
      payload: agent.Profile.unfollow(username),
    }),
  onUnload: () => dispatch({ type: PROFILE_PAGE_UNLOADED }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onDeleteChannel: payload =>
    dispatch({ type: DELETE_CHANNEL_NO_REDIRECT, payload }),
  onRecoverArticle: payload => dispatch({ type: RECOVER_ARTICLE, payload }),
  onDeleteArticle: payload => dispatch({ type: DELETE_ARTICLE_NO_REDIRECT, payload }),
});

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newChannelName: '',
      newChannelAccess: 'PUBLIC',
    };

    this.handleItemClick = this._handleItemClick.bind(this);
    this.handleAddChannel = this._handleAddChannel.bind(this);
    this.refresh = this._refresh.bind(this);
    this.handleNewChannelNameChanged = ev => {
      this.setState({ newChannelName: ev.target.value });
    };
    this.handleNewChannelAccessChanged = (ev, data) => {
      this.setState({ newChannelAccess: data.value });
    };
  }

  _refresh() {
    this.props.onLoad(
      Promise.all([
        agent.Profile.get(this.props.match.params.username),
        agent.Articles.byAuthor(this.props.match.params.username),
      ]),
    );
  }

  _handleItemClick(e, { name }) {
    history.push(`/@${this.props.match.params.username}/${name}`);
  }

  async _handleAddChannel() {
    if (this.state.newChannelName === 'FYEO') {
      return alert('Channel already exists.');
    }
    const { newChannelAccess } = this.state;
    let channel = {
      title: this.state.newChannelName,
    };
    channel.type = newChannelAccess;
    if (newChannelAccess === 'WEBSITE') {
      // Website needs to be public
      channel.type = 'PUBLIC';
      // And go ahead and switch on the website flag, which shows the website
      // options in Channel Settings, and prevents it from being publicly
      // listed alongside regular channels.
      channel.website = true;
    }
    try {
      await agent.Channels.create(channel);
      this.refresh();
      this.setState({ newChannelName: '' });
    } catch (e) {
      alert('There was a problem, please try again later.');
    }
  }

  componentWillMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.username !== this.props.match.params.username) {
      this.refresh();
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { tab, profile, currentUser } = this.props;
    if (!profile) {// || !profile.gides) {
      return null;
    }
    let articles = profile.gides;
    // let articlesTrashed = profile.gides.filter(a => a.trashedDate);
    const isUser =
      this.props.currentUser &&
      this.props.profile.username === this.props.currentUser.username;
    return (
      <div className="profile-page">
        <div className="user-info">
          {/* <div className="row"> */}
          <div className="col-xs-12">
            <div className="center-avatar">
              {profile.image && (
                <GideImage
                  src={profile.image}
                  className="user-img"
                  alt={profile.username}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '2.5em',
                color: 'white',
                marginBottom: '15px',
              }}
            >
              {profile.username} {isUser && currentUser.isAdmin ? '(admin)' : ''}
            </div>
            {/*<p>{profile.bio}</p>*/}
            {profile.connections &&
              profile.connections.followers &&
              profile.connections.following && (
                <div style={{ color: 'white' }}>
                  {profile.connections.followers.length}{' '}
                  <GideImage
                    className="pointer color-secondary-700-svg"
                    src="/icons/content-alteration/follower.svg"
                    alt={`□`}
                  />{' '}
                  {profile.connections.following.length +
                    profile.connections.followers.length}{' '}
                  <GideImage
                    className="pointer color-secondary-700-svg"
                    src="/icons/content-alteration/followers.svg"
                    alt={`□`}
                  />
                </div>
              )}
            <div>
              <div style={{ float: 'left' }}>
                {profile.myGide && (
                  <Link
                    to={urlForArticle(profile.myGide)}
                    title="View User's MyGide"
                    style={{ textDecoration: 'none' }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        left: '14px',
                        color: 'white',
                        fontWeight: '700',
                        textDecoration: 'none',
                      }}
                    >
                      MyGide
                    </span>
                  </Link>
                )}
              </div>
              <div style={{ float: 'right' }}>
                {profile.verified && <div>Verified</div>}
                <div style={{ color: 'white' }}>Rating: 0</div>
              </div>
            </div>
            <EditProfileSettings isUser={isUser} />
            <Logout isUser={isUser} onLogout={this.props.onLogout} />
            <FollowUserButton
              isUser={isUser}
              user={profile}
              follow={this.props.onFollow}
              unfollow={this.props.onUnfollow}
              currentUser={this.props.currentUser}
            />
          </div>
          {/* </div> */}
        </div>
        {profile.gides && (
        <div className="profilePageContentContainer">
          <div className="col-xs-12 profilePageContent">
            <div className="articles-toggle">
              <Menu pointing secondary className="flexRowFlexWrap">
                <Menu.Item name="" active={!tab} onClick={this.handleItemClick}>
                  Activity
                </Menu.Item>
                <Menu.Item
                  name="collections"
                  active={tab === 'collections'}
                  onClick={this.handleItemClick}
                >
                  COLLECTIONS
                </Menu.Item>
                <Menu.Item
                  name="connections"
                  active={tab === 'connections'}
                  onClick={this.handleItemClick}
                >
                  CONNECTIONS
                </Menu.Item>
                {/* <Menu.Item
                  name="calendar"
                  active={tab === 'calendar'}
                  onClick={this.handleItemClick}
                >
                  CALENDAR
                </Menu.Item> */}
                <Menu.Item
                  name="channels"
                  active={tab === 'channels'}
                  onClick={this.handleItemClick}
                >
                  CHANNELS
                </Menu.Item>
              </Menu>
            </div>
            {profile.username && (
              <div className="content">
                {!tab && (
                  <ArticleList
                    articles={articles}
                    layout="SQUARE"
                    currentUser={this.props.currentUser}
                    onRecoverArticle={this.props.onRecoverArticle}
                    onDeleteArticle={payload => {this.props.onDeleteArticle(payload); this.refresh();}}
                  />
                )}
                {tab === 'collections' && (
                  <Collections
                    collections={profile.collections}
                    favorites={profile.favorites}
                    handleReload={this.refresh}
                    onSetViewMode={this.props.onSetViewMode}
                    currentUser={this.props.currentUser}
                    onRecoverArticle={this.props.onRecoverArticle}
                    onDeleteArticle={payload => {this.props.onDeleteArticle(payload); this.refresh();}}
                  />
                )}
                {tab === 'connections' && (
                  <Connections
                    connections={profile.connections}
                    onSetViewMode={this.props.onSetViewMode}
                  />
                )}
                {tab === 'calendar' && (
                  <Calendar
                    username={profile.username}
                    events={profile.events}
                    currentUser={this.props.currentUser}
                  />
                )}
                {tab === 'channels' && (
                  <Channels
                    channels={this.props.channels}
                    newChannelName={this.state.newChannelName}
                    newChannelAccess={this.state.newChannelAccess}
                    handleNewChannelNameChanged={
                      this.handleNewChannelNameChanged
                    }
                    handleNewChannelAccessChanged={
                      this.handleNewChannelAccessChanged
                    }
                    handleAddChannel={this.handleAddChannel}
                    onDeleteChannel={this.props.onDeleteChannel}
                    currentUser={this.props.currentUser}
                  />
                )}
                {tab === 'hi' && (
                  <Article type="HI" username={profile.username} />
                )}
              </div>
            )}
          </div>
        </div>
        )}
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
export { Profile, mapStateToProps };
