import React, { useState, ReactElement, ReactNode } from 'react';
import styles from './_styles.module.scss';
import icons from '../../../../assets/icons';
import classNames from 'classnames';
import { RoundedCornerButton } from '../../../Shared/RoundedCornerButton/RoundedCornerButton';
import {useTimeout} from '../../../../effects/useTimeoutEffect';
import { motion, AnimatePresence } from 'framer-motion';


export interface ComponentCarouselProps {
  // images: DivMultiImageInfo[];
  carouselComponents: ReactNode[];
  height: number;
  mobileHeight?: number;
  width?: number; // undefined = 100%
  maxWidth?: number;
  autoAdvance: boolean;
  autoAdvanceSeconds: number;
  navigationStyle: 'bullets' | 'bullets and arrows' | 'arrows';
  onClick?: () => void;
}

export default function ComponentCarousel(props: ComponentCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useTimeout(() => {
    moveNext();
  }, 5000);

  const movePrevious = () => {
    if (currentIndex === 0) {
      setCurrentIndex(props.carouselComponents.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
    // TODO: Reset timeout
  };
  const moveNext = () => {
    if (currentIndex === props.carouselComponents.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    // TODO: Reset timeout
  };
  const containerStyles = {
    position: 'absolute',
    '--height' : `${props.height}px`,
    '--mobileHeight' : `${props.mobileHeight ? props.mobileHeight : props.height}px`,
    width: '100%'
  } as React.CSSProperties;

  return (
    <AnimatePresence
      initial={false}
    >
      <motion.div
        key={currentIndex}
        transition={{
          // x: { duration: 2 },
          opacity: { duration: 2 },
        }}
        // initial={{ opacity: 1, x: '100%' }}
        // animate={{ opacity: 1, x: 0 }}
        // exit={{ opacity: 0, x: '-100%' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={containerStyles}
        className={styles.componentCarouselContainer}
        onClick={() => {
          props.onClick && props.onClick();
        }}
      >

        {/* {desktopImageDivs[currentIndex]}
        {tabletImageDivs[currentIndex]}
        {mobileImageDivs[currentIndex]}
        {props.images[currentIndex].positionedComponent && (
          <>
            {props.images[currentIndex].positionedComponent?.component}
          </>
        )} */}
        {props.carouselComponents[currentIndex]}
        {(props.navigationStyle === 'arrows' || props.navigationStyle === 'bullets and arrows') &&(
          <>
            <div
              className={styles.leftNavigation}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                movePrevious();
              }}
            >
              <icons.Nav_Arrow_ArrowLeft width={32} height={32} color="var(--WHITES-NORMAL-1000)" style={{ pointerEvents: 'none' }} />
            </div>
            <div
              className={styles.rightNavigation}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                movePrevious();
              }}
            >
              <icons.Nav_Arrow_ArrowRight width={32} height={32} color="var(--WHITES-NORMAL-1000)" style={{ pointerEvents: 'none' }} />
            </div>
          </>
        )}
        {props.navigationStyle === 'bullets' && (
          <div className={styles.bulletNavigation}>
            {props.carouselComponents.map((image: any, index: number) => {
              return (
                <div
                  className={classNames(styles.bullet, index === currentIndex ? styles.selected : undefined)}
                  key={index}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentIndex(index);
                  }}
                ></div>
              );
            })}
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export const CarouselLabeledButtonComponent = (props: {
  title: string,
  buttonLabel?: string,
  class?: string,
  icon?: JSX.Element,
  mobileIcon?: JSX.Element,
  hideButton?: boolean;
  onClick?: () => void;
}) => {
  return (
  <div className={styles.labeledPositionComponent}>
      <div className={styles.title}>
        <span className={styles.labeledComponentLabel}>{props.title}</span>
        {props.icon}
        {props.mobileIcon}
      </div>
      {props.hideButton !== true && (
        <RoundedCornerButton
          className={classNames(props.class ? props.class : styles.button, !props.icon ? styles.addTopMargin : undefined)}
          label={props.buttonLabel ? props.buttonLabel : 'Missing'}
          onClick={props.onClick}
        />
      )}
    </div>
  );
};
