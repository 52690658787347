import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Image,
  Radio,
  Form,
  Button,
  Icon,
  Input,
  Checkbox,
  Dropdown,
} from 'semantic-ui-react';

import agent from '../../agent';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  UPDATE_POINTS,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';
import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import slideTools from '../../slideTools';
import isNil from 'ramda/es/isNil';

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  points: state.common.points,
  // NOTE: The inline editing feature of attachments acts on a different
  // article than state.article.article. And, because column slides cannot
  // be added as an attachment, this can always get the slides state.article.
  slides: state.article.slides,
});

const contentVerticalAlignmentOptions = [
  { key: 'CENTER', value: 'CENTER', text: 'Center' },
  { key: 'TOP', value: 'TOP', text: 'Top' },
  { key: 'BOTTOM', value: 'BOTTOM', text: 'Bottom' },
  { key: 'SPACEBETWEEN', value: 'SPACEBETWEEN', text: 'Space Between' },
  { key: 'SPACEAROUND', value: 'SPACEAROUND', text: 'Space Around' },
];
const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updatePoints: payload => dispatch({ type: UPDATE_POINTS, payload }),
});

export class ColumnModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'COLUMN',
        data: {
          type: '',
          columns: null,
          layout: '',
          columnDists: [],
          columnVerticalAligments: [],
        },
      },
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
      if (!this.state.slide.data.columnDists) {
        this.state.slide.data.columnDists = [];
        for (let i = 0; i < props.editSlide.data.columns; i++) {
          this.state.slide.data.columnDists.push({ dist: '0' });
        }
      }
      if(!this.state.slide.data.columnVerticalAligments) {
        this.state.slide.data.columnVerticalAligments = [];
        for (let i = 0; i < props.editSlide.data.columns; i++) {
          this.state.slide.data.columnVerticalAligments.push({verticalAlignment: 'TOP'})
        }
      }
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };
    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.setColumn = idx => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data.columnDists[idx].dist = ev.target.value;
      this.setState({ slide });
    };
    this.setColumnVerticalAlignment = (value, columnIndex) => {
      const slide = {
        ...this.state.slide,
        data: {
          ...this.state.slide.data,
          columnVerticalAligments: this.state.slide.data.columnVerticalAligments.map((cva, index) => {
            if(index === columnIndex) {
              return {
                verticalAlignment: value
              };
            } else {
              return cva;
            }
          })
        }
      }
      this.setState({slide});
    }

    this.createSlide = async () => {
      let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: false,
        allowQuestions: false,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.props.editSlide.position
          : slidePosition,
      };
      let payload;
      if (this.props.editSlide) {
        payload = await agent.Slides.update(this.props.editSlide.id, slide);
      } else {
        payload = await agent.Slides.create(this.props.article, slide);
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit({ ...payload, mode: this.props.mode });
      this.props.closeModal();
    };

    this.handleColumnsChange = (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.columns = data.value;
      slide.data.columnDists = [];
      slide.data.columnVerticalAligments = [];
      let value = 100 / slide.data.columns;
      for (let i = 0; i < slide.data.columns; i++) {
        slide.data.columnDists.push({ dist: value.toString() });
        slide.data.columnVerticalAligments.push({verticalAlignment: 'TOP'});
      }
      this.setState({ slide });
    };

    this.handleLayoutChange = (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data.layout = data.value;
      if (data.value === 'RESPONSIVE' && slide.data.type === 'WRAP') {
        slide.data.showBorders = false;
      }
      this.setState({ slide });
    };

    this.setType = type => {
      if (type === 'NEWS') {
        return alert('Coming soon.');
      }
      const slide = Object.assign({}, this.state.slide);
      slide.data.type = type;
      if (type === 'NEWS') {
        slide.data.layout = 'RESPONSIVE';
      }
      this.setState({ slide });
    };

    this.getPrecedingColumnSlide = () => {
      const { slides, position } = this.props;
      // need to subtract one from the position
      let pos = position ? position - 1 : slides.length - 1;
      if (!slides) return null;
      for (let i = pos; i >= 0; i--) {
        let s = slides[i];
        if (s.slideType === 'COLUMN') {
          if (s.data.type && s.data.type === 'END') {
            // Stop looking.
            return null;
          } else {
            if (s.data.columns) {
              // Only the start column slide has columns field set.
              return s;
            }
          }
        }
      }
    };

    this.getPrecedingColumnSlides = () => {
      const { slides, position } = this.props;
      // need to subtract one from the position
      let pos = position ? position - 1 : slides.length - 1;
      let count = 0;
      for (let i = pos; i >= 0; i--) {
        let s = slides[i];
        if (s.slideType === 'COLUMN') {
          if (s.data.type === 'END') {
            break;
          } else {
            count += 1;
          }
        }
      }
      return count;
    };

    const precedingColumnSlide = this.getPrecedingColumnSlide();
    if (precedingColumnSlide && !props.editSlide) {
      if (precedingColumnSlide.data.type === 'WRAP') {
        // No question, add another without choice of options (which will be
        // interpreted as closing) - there is only begin and end for Wrap type.
        this.setType('END');
        window.setTimeout(() => {
          this.createSlide();
        }, 1000);
      } else {
        // Question: close it or add another, depending on number of columns
        // and how many should be there.
        const count = this.getPrecedingColumnSlides();
        const columnsLimit = parseInt(precedingColumnSlide.data.columns, 10);
        if (count === columnsLimit) {
          this.setType('END');
        }
        window.setTimeout(() => {
          this.createSlide();
        }, 1000);
      }
    }
  }

  render() {
    const precedingColumnSlide = this.getPrecedingColumnSlide();
    const { slide } = this.state;
    let items = [];
    for (let i = 0; i < slide.data.columns; i++) {
      // Possibly could use a slider for each column, or a component with multiple handles on a single slider.
      // let item = (
      //   <input type="range" min="1" max="100" value="50" className="slider" />
      // );
      // For now, use an input box for each column.

      let item = (
        <div class="columnDistributionSettings" key={i}>
          <Input
            label={{ basic: true, content: '%' }}
            labelPosition="right"
            className="columnDist"
            value={slide.data.columnDists[i].dist}
            onChange={this.setColumn(i)}
          />
          <Dropdown
            placeholder="Content Vertical Alignment"
            options={contentVerticalAlignmentOptions}
            value={slide.data.columnVerticalAligments[i].verticalAlignment}
            onChange={(evt, data) => {
              this.setColumnVerticalAlignment(data.value, i);
            }}
          />
        </div>
      );
      items.push(item);
    }

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="modal choose"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(24, 120, 126)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Column Type</span>
            <Image
              src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <div className="inner">
                {!precedingColumnSlide &&
                  slide.data.type === '' && (
                    <div className="chooseTypes column">
                      <div
                        className="chooseType saveAnswer"
                        onClick={() => this.setType('FIXED')}
                      >
                        <GideImage src="/images/chooseSaveAnswer.png" alt={`□`} />
                        <div className="words">Divided</div>
                      </div>
                      <div
                        className="chooseType"
                        onClick={() => this.setType('WRAP')}
                      >
                        <GideImage src="/images/chooseJump.png" alt={`□`} />
                        <div className="words">Wrap</div>
                      </div>
                      <div
                        className="chooseType"
                        onClick={() => this.setType('NEWS')}
                      >
                        <GideImage src="/images/chooseJump.png" alt={`□`} />
                        <div className="words">Newspaper</div>
                      </div>
                    </div>
                  )}
                {slide.data.type && (
                  <div>
                    <div>
                      <h2>Select your number of columns</h2>
                      <Form.Field>
                        <Radio
                          label="2 columns"
                          name="numberGroup"
                          value="2"
                          checked={slide.data.columns === '2'}
                          onChange={this.handleColumnsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="3 columns"
                          name="numberGroup"
                          value="3"
                          checked={slide.data.columns === '3'}
                          onChange={this.handleColumnsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="4 columns"
                          name="numberGroup"
                          value="4"
                          checked={slide.data.columns === '4'}
                          onChange={this.handleColumnsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="5 columns"
                          name="numberGroup"
                          value="5"
                          checked={slide.data.columns === '5'}
                          onChange={this.handleColumnsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="6 columns"
                          name="numberGroup"
                          value="6"
                          checked={slide.data.columns === '6'}
                          onChange={this.handleColumnsChange}
                        />
                      </Form.Field>
                    </div>
                    {slide.data.columns &&
                      slide.data.type === 'FIXED' && (
                        <div>
                          <h4>Distribution (must equal 100%)</h4>
                          {items}
                        </div>
                      )}
                    <div>
                      <h3>Select Layout</h3>
                      <Form.Field>
                        <Radio
                          label="Non Responsive"
                          name="layoutGroup"
                          value="NONRESPONSIVE"
                          checked={slide.data.layout === 'NONRESPONSIVE'}
                          onChange={this.handleLayoutChange}
                          disabled={slide.data.type === 'NEWS'}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Responsive"
                          name="layoutGroup"
                          value="RESPONSIVE"
                          checked={slide.data.layout === 'RESPONSIVE'}
                          onChange={this.handleLayoutChange}
                          disabled={slide.data.type === 'NEWS'}
                        />
                      </Form.Field>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(evt, data) => {
                          const slide = Object.assign({}, this.state.slide);
                          slide.data.showBorders = data.checked;
                          this.setState({ slide });
                        }}
                        checked={slide.data.showBorders}
                        label="Show line separators"
                        disabled={
                          slide.data.type === 'WRAP' &&
                          slide.data.layout === 'RESPONSIVE'
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={false}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                showVerticalAlignmentSettings={true}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {slide.data.type !== '' && (
          <Modal.Actions style={{ background: 'rgb(24, 120, 126)' }}>
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              showSettings={this.state.showSettings}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnModal);
