import React, {Component, Dispatch } from 'react';
import ResizeableModal from '../ResizeableModal/ResizeableModal';
import { connect } from 'react-redux';
import { GideAction } from '../../../models/GideAction';
import { MODAL_CLOSE, SET_TOASTER_MESSAGE } from '../../../constants/actionTypes';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import SlideFilter, { SlideFilterState } from '../../Gide/SlideFilter/SlideFilter';

export interface GideSlideFilterModalProps {
  closeModal: () => void;
  onFilter: (slideFilter: SlideFilterState) => void;
  slideFilter?: SlideFilterState;
  allowFilterOnComments: boolean;
  allowFilterOnQuestions: boolean;
  allowFilterOnPrivateNotes: boolean;
  allowFilterOnAttachments: boolean;
  slideTypes: string[];
}

export interface GideSlideFilterModalState {
}

const mapDispatchToProps = (dispatch: Dispatch<GideAction>) => ({
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  showNotification: (toasterMessageInfo: ToasterMessageInfo) =>
    dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }),
});

export class GideSlideFilterModal extends Component<GideSlideFilterModalProps, GideSlideFilterModalState> {
  constructor(props: GideSlideFilterModalProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const slideTypes: string[] = [];
    new Set(this.props.slideTypes).forEach(s => {
      slideTypes.push(s);
    });
    return (
      <ResizeableModal
        closeModal={this.props.closeModal}   
        canResize={true}
        mode='displayGideHeader'             
      >
        <SlideFilter           
          slideTypes={slideTypes}
          onFilter={(slideFilter: SlideFilterState) => {
            this.props.onFilter(slideFilter);
            this.props.closeModal();
          }}
          slideFilter={this.props.slideFilter}
          allowFilterOnComments={this.props.allowFilterOnComments}
          allowFilterOnQuestions={this.props.allowFilterOnQuestions}
          allowFilterOnPrivateNotes={this.props.allowFilterOnPrivateNotes}
          allowFilterOnAttachments={this.props.allowFilterOnAttachments}
          onCancel={this.props.closeModal}
          style={{
            margin: '10px'
          }}
        />
      </ResizeableModal>
    );
  }
}
export default connect(null, mapDispatchToProps)(
  GideSlideFilterModal,
);
