import { useMemo } from "react";
import { getUrlForTarget } from "./helperFunctions";

export interface ResizeImageOptions {
  width?: string;
  height?: string;
}

const getSizeValue = (prefix: string, value?: string) => {
  value = value && value.trim();
  if (value) {
    if (value.endsWith('px')) {
      value = prefix + value?.replace('px', '');
    } else if (value.endsWith('%')) {
      value = prefix + (Number.parseFloat(value?.replace('%', '') || '0') / 100).toString();
    } else {
      value = prefix + value;
    }
  }
  return value;
}

const cloudinaryCloudName = process.env.REACT_APP__CLOUDINARY_CLOUD_NAME || 'gawa730';
const useCloudinary = false;

export const isSrcResizable = (src: string) => {
  return src && !src.startsWith('blob:') && !src.endsWith(".svg") && !src.startsWith("/");
}

export const useResizedImageUrl = (src: string, options: ResizeImageOptions) => {
  const resizedImageUrl = useMemo(() => {
    if (isSrcResizable(src)) {
      if (useCloudinary) {
        let width = getSizeValue("w_", options.width);
        let height = getSizeValue("h_", options.height);
        const optionsStr = [
          width,
          height,
          "c_fill",
          "f_auto",
        ].filter(x => !!x)
        .join(',');      
        src = `https://res.cloudinary.com/${cloudinaryCloudName}/image/fetch/${optionsStr}/${src}`;
      } else {
        let width = getSizeValue("w=", options.width);
        let height = getSizeValue("h=", options.height);
        const optionsStr = [
          width,
          height,
        ].filter(x => !!x)
        .join('&');
        const url = new URL(src);
        const path = url.pathname;
        if (url.hostname === 'gides-user-uploads.s3.us-east-2.amazonaws.com') {
          src = `https://d2ettyk3s9h19.cloudfront.net${path}?${optionsStr}`;
        }
      }
    }

    return getUrlForTarget(src);
  }, [src, options.width, options.height]);

  return resizedImageUrl;
}
