import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Image, Button, Icon } from 'semantic-ui-react';
import agent from '../../agent';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
} from '../../constants/actionTypes';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import slideTools from '../../slideTools';

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
});

export class EndCollapseModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'COLLAPSE',
        data: {
          level: 1,
          type: 'END',
        },
      },
      showSettings: false,
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.updateState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };
    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = data.checked;
      this.setState({ slide });
    };

    this.updateDataStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: false,
        allowQuestions: false,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : this.props.position,
      };
      let payload;
      if (this.props.editSlide) {
        payload = await agent.Slides.update(this.props.editSlide.id, slide);
      } else {
        payload = await agent.Slides.create(this.props.article, slide);
      }
      this.props.onSubmit({ ...payload, mode: this.props.mode });
      this.props.closeModal();
    };
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="modal choose"
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(24, 120, 126)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(24, 120, 126)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>End Collapse Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <div />
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={false}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndCollapseModal);
