import React, { CSSProperties } from 'react';
import { getFormattedDateDiff, urlForArticle } from '../../../../utils/helperFunctions';
import { ArticlePreviewLayout } from '../../../../models/CommonEnums';
import GideTilePreview from '../GidePreview/GideTilePreview/GideTilePreview';
import { isNil } from 'ramda';
import { PriceType, SubscriptionPlan } from '../../../../models/GideEnum';
import { GideBlogImageTopPreview } from '../GidePreview/GideBlogImageTopPreview/GideBlogImageTopPreview';
import { GideBlogImageLeftPreview } from '../GidePreview/GideBlogImageLeftPreview/GideBlogImageLeftPreview';
import { Link } from 'react-router-dom';
export interface ArticlePreviewProps {
  id: string;
  slug: string;
  image: string;
  title: string;
  description?: string;
  authorImage: string;
  author: string;
  updatedAt: Date;
  createdAt: Date;
  views?: number;
  language?: string;
  layout: ArticlePreviewLayout;
  style?: CSSProperties;
  slideCount: number;
  isOfficalGide?: boolean;
  price?: number;
  priceType?: PriceType;
  subscriptionPlan?: SubscriptionPlan;
  onClick?: () => void;
}

export default function ArticlePreview(props: ArticlePreviewProps) {
  return (
    <>
      {props.layout === ArticlePreviewLayout.BlogImageTop && (
        <Link to={urlForArticle({ slug: props.slug, author: { username: props.author } })} target="_blank">     
          <GideBlogImageTopPreview
            url={urlForArticle({slug: props.slug, author: {username: props.author}})}
            image={props.image}
            author={props.author}
            authorImage={props.authorImage}
            views={props.views}
            title={props.title}
            description={props.description}
            style={props.style}
            createdAt={getFormattedDateDiff(new Date(), props.createdAt)}
          />
        </Link>
      )}
      {props.layout === ArticlePreviewLayout.BlogImageLeft && (
        <Link to={urlForArticle({ slug: props.slug, author: { username: props.author } })} target="_blank">
          <GideBlogImageLeftPreview
            url={urlForArticle({slug: props.slug, author: {username: props.author}})}
            image={props.image}
            author={props.author}
            authorImage={props.authorImage}
            views={props.views}
            userLanguage={!isNil(props.language) ? (props.language as string) : 'en'}
            title={props.title}
            description={props.description}
            style={props.style}
            createdAt={getFormattedDateDiff(new Date(), props.createdAt)}
          />
        </Link>
      )}
      {props.layout === ArticlePreviewLayout.Tile && (
        <Link to={urlForArticle({ slug: props.slug, author: { username: props.author } })} target="_blank">
          <GideTilePreview
            id={props.id}
            slug={props.slug}
            key={props.slug}
            image={props.image}
            title={props.title}
            description={props.description}
            authorImage={props.authorImage}
            author={props.author}
            language={!isNil(props.language) ? (props.language as string) : 'en'}
            views={!isNil(props.views) ? props.views : 0}
            slideCount={!isNil(props.slideCount) ? (props.slideCount as number) : 0}
            gideCost={props.price}
            priceType={props.priceType}
            subscriptionPlan={props.subscriptionPlan}
            createdAt={new Date(props.createdAt)}
            updatedAt={new Date(props.updatedAt)}
            disableLink={true}
            isReadOnly={true}
          />
        </Link>
      )}
    </>
  );
}
