import React, { useState } from 'react';
import icons from '../../../assets/icons';
import { CircleIconButton } from '../CircleIconButton/CircleIconButton';
import Slider from '../Slider/Slider';
import { ArticleLayoutView, ArticleViewMode } from '../../../models/ArticleLayoutEnum';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType } from '../../../constants/strings';
import GideImage from '../Image/GideImage';
import ExpandingIconButton, { HorizontalAlignment } from '../ExpandingIconButton/ExpandingIconButton';
import { RoundedCornerButton } from '../RoundedCornerButton/RoundedCornerButton';
import ViewbarSignupForm from '../ViewbarSignupForm/ViewbarSignupForm';

export interface ViewSettingsProps {
  articleLayoutView?: ArticleLayoutView;
  articleViewMode?: ArticleViewMode;
  isLoggedIn: boolean;
  onSwitchArticleViewMode: (viewMode: ArticleViewMode) => void;
  onSwitchArticleLayoutView: (layoutView: ArticleLayoutView) => void;
  onChangedViewMode?: () => void;
  onCollapseAllSlides: (collapseType: string) => void;
  onExpandAllSlides: (expandType: string) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  openModal: (payload: { modalType: string, modalProps?: any}) => void;
  closeModal: (closeDialogList?: string[]) => void;
  signIn: () => void;
  signUp: () => void;
}

type TabType = 'Headers' | 'Notes' | 'Attachments';

export default function ViewSettings(props: ViewSettingsProps) {
  const [selectedFontSize] = useState(0.5);
  const [selectedTabType, setSelectedTabType] = useState<TabType>('Attachments');

  const buildActionTab = (tabType: TabType, index: number): JSX.Element => {
    return (
      <div
        key={index}
        className={selectedTabType === tabType ? 'tabText selectedTab' : 'tabText'}
        onClick={e => setSelectedTabType(tabType)}
        style={{
          width: "auto",
          flex: "1 1",
        }}
      >
        {tabType}
      </div>
    );
  };
  const viewSettingActions: TabType[] = ['Attachments', 'Notes']; // ['Headers', 'Notes', 'Attachments'];

  return (
    <div className="viewSettingsContainer"
      onScroll={(e) => e.stopPropagation()}
    >
      {/* <div
        className="viewSettingCheckbox"
        onClick={() => {
          props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
        }}
      >
        <div className="viewSettingCheckboxDescription">
          <GideImage src="/icons/content-alteration/follower.svg" className="viewSettingImage" alt="show creator follower" />
          Show slide creator
        </div>
        <GideImage src="/icons/content-alteration/checkbox/checkbox-deactive.svg" alt="show slide creator checkbox" />
      </div>
      <div className="actionHeaderSeparator" />
      <div className="viewSettingsHeader">Filter slides</div>
      <div
        className="viewSettingFilter"
        onClick={() => {
          props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
        }}
      >
        Choose filter options
        <GideImage src="/icons/nav/filter.svg" alt="filter options" />
      </div>
      <div
        className="viewSettingFilter"
        onClick={() => {
          props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
        }}
      >
        Not safe for work or minors
        <GideImage src="/icons/nav/filter.svg" alt="filter not safe" />
      </div>
      <div className="actionHeaderSeparator" />
      <div className="viewSettingsHeader">Theme</div>
      <div className="viewSettingTheme">
        <div className="viewSettingCheckbox theme">
          <div
            className="viewSettingCheckboxDescription"
            onClick={() => {
              props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
            }}
          >
            <GideImage src="/icons/content-alteration/follower.svg" className="viewSettingImage" alt="dark mode follower" />
            Dark mode
          </div>
          <GideImage src="/icons/content-alteration/checkbox/checkbox-deactive.svg" alt="deactive checkbox" />
        </div>
        <div
          className="viewSettingCheckbox theme"
          onClick={() => {
            props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
          }}
        >
          <div className="viewSettingCheckboxDescription">
            <GideImage src="/icons/content-alteration/follower.svg" className="viewSettingImage" alt="follower" />
            Minimalist
          </div>
          <GideImage src="/icons/content-alteration/checkbox/checkbox-deactive.svg" alt="deactive checkbox" />
        </div>
      </div> */}
      {/* <div className="actionHeaderSeparator" /> */}
      {/* <div className="viewSettingDetails"> */}
        {/* {props.onSwitchArticleViewMode && (
          <div className="viewModeSettings">
            <div className="viewModeTitle">Select a view mode</div>
            <div
              className="viewModeAction"
              onClick={(e: any) => {
                props.onSwitchArticleViewMode(ArticleViewMode.Scroll);
              }}
            >
              <div>
                <CircleIconButton
                  className="actionIcon"
                  alt="scroll mode"
                  onClick={() => {}}
                  iconCssClass="whites-normal-1000-svg"
                  backgroundColor={
                    props.articleViewMode === ArticleViewMode.Scroll ? 'var(--COLOR-PRIMARY-600)' : 'var(--whites-normal-1000-svg)'
                  }
                >
                  <icons.Nav_Display_Scroll color={props.articleViewMode === ArticleViewMode.Scroll ? 'var(--WHITES-NORMAL-1000)' : ''} />
                </CircleIconButton>
              </div>
              <div className="viewModeActionText">Scroll mode</div>
            </div>
            <div
              className="viewModeAction"
              onClick={(e: any) => {
                props.onSwitchArticleViewMode(ArticleViewMode.Slideshow);
              }}
            >
              <CircleIconButton
                className="actionIcon"
                alt="slideshow mode"
                onClick={() => {}}
                backgroundColor={
                  props.articleViewMode === ArticleViewMode.Slideshow ? 'var(--COLOR-PRIMARY-600)' : 'var(--whites-normal-1000-svg)'
                }
              >
                <icons.Nav_Display_Swipe color={props.articleViewMode === ArticleViewMode.Slideshow ? 'var(--WHITES-NORMAL-1000)' : ''} />
              </CircleIconButton>
              <div className="viewModeActionText">Swipe Mode</div>
            </div>
            <div
              className="viewModeAction"
              onClick={() => {
                props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
              }}
            >
              <CircleIconButton
                className="actionIcon"
                alt="thumbnail mode"
                onClick={() => {}}
                backgroundColor={
                  props.articleViewMode === ArticleViewMode.Thumbnail ? 'var(--COLOR-PRIMARY-600)' : 'var(--whites-normal-1000-svg)'
                }
              >
                <icons.Nav_Display_Thumbnail
                  color={props.articleViewMode === ArticleViewMode.Thumbnail ? 'var(--WHITES-NORMAL-1000)' : ''}
                />
              </CircleIconButton>
              <div className="viewModeActionText">Thumbnail</div>
            </div>
          </div>
        )} */}
        {/* <div className="viewWidthSettings">
          <div className="viewModeTitle">Select scroll width</div>
          <div className="viewModeAction" onClick={(e) => {props.onSwitchArticleLayoutView(ArticleLayoutView.Responsive);}}>
            <CircleIconButton
              className="actionIcon"
              alt="normal view"
              onClick={() => {}}
              backgroundColor={
                props.articleLayoutView === ArticleLayoutView.Responsive ? 'var(--COLOR-PRIMARY-600)' : 'var(--whites-normal-1000-svg)'
              }
            >
              <icons.Nav_Display_Normal
                color={props.articleLayoutView === ArticleLayoutView.Responsive ? 'var(--WHITES-NORMAL-1000)' : ''}
              />
            </CircleIconButton>
            <div className="viewModeActionText">Normal View</div>
          </div>
          <div className="viewModeAction" onClick={(e) => {props.onSwitchArticleLayoutView(ArticleLayoutView.WideScreen);}}>
            <div>
              <CircleIconButton
                className="actionIcon"
                alt="widescreen view"
                onClick={() => {}}
                iconCssClass="whites-normal-1000-svg"
                backgroundColor={
                  props.articleLayoutView === ArticleLayoutView.WideScreen ? 'var(--COLOR-PRIMARY-600)' : 'var(--whites-normal-1000-svg)'
                }
              >
                <icons.Nav_Display_Scroll
                  color={props.articleLayoutView === ArticleLayoutView.WideScreen ? 'var(--WHITES-NORMAL-1000)' : ''}
                />
              </CircleIconButton>
            </div>
            <div className="viewModeActionText">Widescreen view</div>
          </div>
        </div>
      </div> */}
      {/* <div className="actionHeaderSeparator" /> */}
      {/* <div className="viewModeFontSize">
        <div className="viewSettingsHeader">Font Size</div>
        <div
          className="fontSlider"
          onClick={() => {
            props.showNotification({ message: 'This feature is coming soon.', type: NotificationType.INFO });
          }}
        >
          <icons.SlideType_Text_ProductIcon height="42px" width="42px" />
          <Slider sliderValue={selectedFontSize} sliderTickLabels={['', '100%', '']} onChange={() => {}} />
          <icons.SlideType_Text_Large height="38px" width="38px" padding-left="5px" padding-right="5px" />
        </div>
      </div>
      <div className="actionHeaderSeparator" /> */}
      <div className="actionTab">{viewSettingActions.map((tabType: TabType, index: number) => buildActionTab(tabType, index))}</div>
      {selectedTabType === 'Notes' &&
        <ViewbarSignupForm
          isLoggedIn={props.isLoggedIn}
          showNotification={props.showNotification}
          openModal={props.openModal}
          closeModal={props.closeModal}
          signIn={props.signIn}
          signUp={props.signUp}
        />
      }
      {selectedTabType === 'Attachments' &&
        <div className="actionExpander">
         <RoundedCornerButton
          label="Collapse all"
          style={{
            backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
            width: '112px',
            height: '32px',
            // paddingRight: "18px",
            marginLeft: "12px",
          }}
          onClick={e => {
            props.onCollapseAllSlides(selectedTabType);
          }}
         />
         <RoundedCornerButton
          label="Expand all"
          style={{
            backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
            width: '112px',
            height: '32px',
            // paddingRight: "18px",
            marginLeft: "12px",
          }}
          onClick={e => {
            props.onExpandAllSlides(selectedTabType);
          }}
         />

        {/* <div
          className="expandAllAction"
          onClick={e => {
            props.onCollapseAllSlides(selectedTabType);
          }}
        >
          <CircleIconButton
            className="actionIcon"
            alt="scroll mode"
            onClick={() => {}}
            iconCssClass="whites-normal-1000-svg"
            backgroundColor="#fcfcfc"
          >
            <icons.Nav_Arrow_ArrowUp />
          </CircleIconButton>
          Collapse all
        </div>
        <div
          className="expandAllAction"
          onClick={e => {
            props.onExpandAllSlides(selectedTabType);
          }}
        >
          <CircleIconButton
            className="actionIcon"
            alt="scroll mode"
            onClick={() => {}}
            iconCssClass="whites-normal-1000-svg"
            backgroundColor="#fcfcfc"
          >
            <icons.Nav_Arrow_ArrowDown />
          </CircleIconButton>
          Expand all
        </div> */}
      </div>
      }
    </div>
  );
}
