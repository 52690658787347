import React, { CSSProperties } from 'react';
import { ItemSelectionMode } from '../GidePreview/GideListItemPreview/GideListItemPreview';
import styles from './_channel-tile-preview.module.scss';
import GideImage from '../../Image/GideImage';
import icons from '../../../../assets/icons';
import PreviewAvatar from '../PreviewAvatar/PreviewAvatar';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface ChannelTilePreviewProps {
  image: string;
  title: string;
  authorImage: string;
  author: string;
  slug: string;
  type: string;
  isSubChannel?: boolean;
  numberOfGides: number;
  numberOfMembers: number;
  className?: string;
  selected?: boolean;
  selectionMode: ItemSelectionMode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, slug: string) => void;
  style?: CSSProperties;
  linkMode: 'noLink' | 'inApp' | 'newTab';
}

export default function ChannelTilePreview(props: ChannelTilePreviewProps) {
  const channelSummary = `${props.numberOfGides} Gides${props.numberOfMembers > 0 ? ` • ${props.numberOfMembers} Members` : ''}`;
  const content = (
    <div className={classNames(styles.channelPublishPreviewContainer, props.className)}
      style={props.style}
      onClick={(event) => {
        if(props.onClick) {
          props.onClick(event, props.slug);
          event.preventDefault();
        }
      }}
    >
      <div className={styles.channelImage}>
        <GideImage src={props.image} alt={props.title} />
      </div>
      <div className={styles.channelPreviewDetails}>
        <div className={styles.channelTitle}>{props.title === 'FYEO' ? 'For Your Eyes Only' : props.title}</div>
        <div className={styles.channelSummary}>{channelSummary}</div>
        <div className={styles.channelInfo}>{`${props.type} CHANNEL`}</div>
        <div className={styles.channelFooter}>
          <div className={styles.channelAuthor}>
            <PreviewAvatar authorImage={props.authorImage} author={props.author} disabled={true} />
          </div>
          <GideImage src="/icons/nav/threedots/horizontal.svg" alt={`□`} />
        </div>
      </div>
      <icons.Nav_Channels className={styles.logo} />
      <div className={styles.logoBackground} />
    </div>
  );

  if (!props.linkMode || props.linkMode === 'noLink') {
    return content;
  } else {
    return (
      <Link to={`/channel/${props.slug}`} target={props.linkMode === 'newTab' ? "_blank": ""}>
        {content}
      </Link>
    );
  }
}
