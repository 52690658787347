import React from 'react';
import VideoViewer from '../VideoViewer/VideoViewer';
import { VideoFile } from '../../../../models/SlideFile';

export interface VideoListProps {
  videoList: VideoFile[];
}

export default function VideoList(props: VideoListProps) {
  return (
    <div className="videoListViewer">
      {props.videoList.map((video: VideoFile, i: number) => {
        return (
          <VideoViewer
            className={i < props.videoList.length - 1 ? 'hasEnsuingItem' : undefined}
            key={i}
            videoFile={video}
          />
        );
      })}
    </div>
  )
}