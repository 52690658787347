import React, { Component } from 'react';
import { connect } from 'react-redux';
import superagent from 'superagent';
import DropZone from 'react-dropzone';
import {
  Input,
  Checkbox,
  Loader,
  List,
  Image,
  Dropdown,
  Button,
  Icon,
  Divider,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CompactPicker } from 'react-color';
import { fonts } from '../../constants/fonts';
import '../../lib/Datetime/_Datetime.scss';
import agent from '../../agent';
import { API_ROOT } from '../../constants/paths';
import ListErrors from '../ListErrors';
import { ensureImageFileOrientation } from '../../utils/helperFunctions';
import ArticlePreviewSquare from '../ArticlePreviewSquare';
import {
  ADD_TAG,
  ADD_USER,
  ADD_USERS,
  ADD_VIEWERS,
  EDITOR_PAGE_LOADED,
  REMOVE_TAG,
  REMOVE_USER,
  REMOVE_VIEWER,
  CHANNEL_SUBMITTED,
  EDITOR_PAGE_UNLOADED,
  UPDATE_FIELD_EDITOR,
  USERS_LOADED,
  SET_VIEW_MODE,
  ARTICLES_LOADED,
  MODAL_OPEN,
} from '../../constants/actionTypes';
import GideImage from '../Shared/Image/GideImage';

let dropZoneStyles = {
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};

const mapStateToProps = state => ({
  ...state.editor,
  users: state.common.users,
  channels: state.common.meta.channels,
  articles: state.common.articles,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
  onAddTag: () => dispatch({ type: ADD_TAG }),
  onAddUser: () => dispatch({ type: ADD_USER }),
  onAddUsers: users => dispatch({ type: ADD_USERS, users }),
  onAddViewers: users => dispatch({ type: ADD_VIEWERS, users }),
  onLoad: payload => dispatch({ type: EDITOR_PAGE_LOADED, payload }),
  onRemoveTag: tag => dispatch({ type: REMOVE_TAG, payload: {tag} }),
  onRemoveUser: user => dispatch({ type: REMOVE_USER, user }),
  onRemoveViewer: user => dispatch({ type: REMOVE_VIEWER, user }),
  onSubmit: payload => {
    dispatch({ type: CHANNEL_SUBMITTED, payload });
  },
  onUnload: payload => dispatch({ type: EDITOR_PAGE_UNLOADED }),
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_EDITOR, payload: {key, value} }),
  onUsersLoaded: payload => dispatch({ type: USERS_LOADED, payload }),
  onArticlesLoaded: payload => dispatch({ type: ARTICLES_LOADED, payload }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
});

let userOptions = [];
let articleOptions = [];
let resp1 = {};

const menuPresets = [
  {
    name: 'White on Black',
    menuFontColor: '#fff',
    menuBackgroundColor: '#333',
  },
  {
    name: 'Black on White',
    menuFontColor: '#333',
    menuBackgroundColor: '#fff',
  },
];

const priceTypeOptions = [
  { key: 'FIXED', value: 'FIXED', text: 'Fixed' },
  { key: 'DONATE', value: 'DONATE', text: 'Donate' },
];

const purchaseTypeOptions = [
  { key: 'OWN', value: 'OWN', text: 'Own (one time payment)' },
  { key: 'RENT', value: 'RENT', text: 'Rent' },
  { key: 'SUBSCRIBE', value: 'SUBSCRIBE', text: 'Subscribe' },
];

const subscribeTypeOptions = [
  { key: 'DAILY', value: 'DAILY', text: 'Daily' },
  { key: 'WEEKLY', value: 'WEEKLY', text: 'Weekly' },
  { key: 'MONTHLY', value: 'MONTHLY', text: 'Monthly' },
  { key: 'YEARLY', value: 'YEARLY', text: 'Yearly' },
];

const typeOptions = [
  { key: 'PUBLIC', value: 'PUBLIC', text: 'Public' },
  { key: 'FOLLOW', value: 'PUBLIC_FOLLOW', text: 'Public Follow' },
  { key: 'INVITE', value: 'INVITE', text: 'Invite/Accept' },
  { key: 'REQUEST', value: 'REQUEST', text: 'Request/Approve' },
];

const tabOptions = [
  { key: 'NONE', value: '', text: 'None' },
  { key: 'MENU1', value: 'MENU1', text: 'Menu 1' },
  { key: 'MENU2', value: 'MENU2', text: 'Menu 2' },
];

const logoPositionOptions = [
  { key: 'LEFT', value: 'LEFT', text: 'Left' },
  { key: 'CENTER', value: 'CENTER', text: 'Center' },
  { key: 'RIGHT', value: 'RIGHT', text: 'Right' },
];

const tabAlignmentOptions = [
  { key: 'LEFT', value: 'LEFT', text: 'Left' },
  { key: 'RIGHT', value: 'RIGHT', text: 'Right' },
];

class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingHeaderImage: false,
      loadingFaviconImage: false,
      channel: {
        title: '',
        public: false,
        website: false,
        domain: '',
        menuBackgroundColor: '',
        menuFontColor: '',
        backgroundColor: '',
        tabs: [],
      },
      publishersSelected: [],
      editorsSelected: [],
      adminsSelected: [],
    };

    this.updateChannelCheckbox = field => (ev, data) => {
      let channel = this.state.channel;
      channel[field] = data.checked;
      this.setState({ channel });
    };

    this.updateLogoInline = field => (ev, data) => {
      let channel = this.state.channel;
      channel[field] = data.checked;
      if (channel.logoInline === true && channel.logoPosition === 'CENTER') {
        // Centered inline not supported yet
        channel.logoPosition = 'LEFT';
      }
      this.setState({ channel });
    };

    this.updateChannel = field => ev => {
      let channel = this.state.channel;
      channel[field] = ev.target.value;
      this.setState({ channel });
    };

    this.updateChannelColorPicker = field => color => {
      let channel = this.state.channel;
      channel[field] = color.hex;
      this.setState({ channel });
    };

    this.onTabChange = (idx, exportInfo) => {
      this.selectArticleForTab(idx, exportInfo.articleId);
    }

    this.updateTabCheckbox = (tabId, field) => (ev, data) => {
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      tabs[tabId][field] = data.checked;
      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.onTabSidebarChange = (idx, exportInfo) => {
      this.selectArticleForTabSidebar(idx, exportInfo.articleId);
    };

    this.onSubTabChange = (iTab, iSubtab) => (ev, data) => {
      this.selectArticleForSubTab(iTab, iSubtab, data.value);
    };

    this.onAddSubTab = idx => (ev, data) => {
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      tabs[idx].subtabs.push({ article: {} });
      this.setState({ channel });
    };

    this.selectArticleForTab = (tabId, articleId) => {
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      let selected = this.props.articles.find(obj => {
        return obj.id === articleId;
      });
      tabs[tabId].article = selected;
      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.selectArticleForTabSidebar = (tabId, articleId) => {
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      let selected = this.props.articles.find(obj => {
        return obj.id === articleId;
      });
      tabs[tabId].sidebarArticle = selected;
      channel.tabs = tabs;
      // tabs[tabId].sidebarArticle = null;
      this.setState({ channel });
    };

    this.selectArticleForSubTab = (tabId, subtabId, articleId) => {
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      let selected = this.props.articles.find(obj => {
        return obj.id === articleId;
      });
      tabs[tabId].subtabs[subtabId].article = selected;
      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.onRemoveTab = idx => {
      if (!window.confirm('Are you sure?')) return;
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      tabs.splice(idx, 1);
      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.onRemoveSubTab = (iTab, iSubtab) => {
      if (!window.confirm('Are you sure?')) return;
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      tabs[iTab].subtabs.splice(iSubtab, 1);
      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.onMoveTabUp = idx => {
      const idxTarget = idx - 1;

      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();

      if (idxTarget > tabs.length - 1) return;

      const tabToMove = tabs[idx];
      const tabToReplace = tabs[idxTarget];

      tabs[idx] = tabToReplace;
      tabs[idxTarget] = tabToMove;

      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.onMoveTabDown = idx => {
      const idxTarget = idx + 1;
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();

      if (idxTarget > tabs.length - 1) return;

      const tabToMove = tabs[idx];
      const tabToReplace = tabs[idxTarget];

      tabs[idx] = tabToReplace;
      tabs[idxTarget] = tabToMove;

      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.onAddTab = () => {
      let channel = this.state.channel;
      if (channel.tabs.length >= 7) {
        return alert('Max 7 tabs allowed.');
      }
      const tab = { text: '', article: '', subtabs: [] };
      channel.tabs.push(tab);
      this.setState({ channel });
    };

    this.onChangeTabText = idx => (ev, data) => {
      let channel = Object.assign({}, this.state.channel);
      let tabs = channel.tabs.slice();
      tabs[idx].text = data.value;
      channel.tabs = tabs;
      this.setState({ channel });
    };

    this.selectedMenuPreset = preset => {
      let channel = this.state.channel;
      channel.menuFontColor = preset.menuFontColor;
      channel.menuBackgroundColor = preset.menuBackgroundColor;
      this.setState({ channel });
    };

    this.handleAddPublishers = () => {
      let users = [];
      this.state.publishersSelected.forEach(id => {
        let u = this.props.users.find(user => user._id === id);
        users.push(u);
      });
      let channel = this.state.channel;
      channel.publishers = users;
      this.setState({ channel, publishersSelected: [] });
    };

    this.handleSelectedPublisher = (ev, data) => {
      this.setState({ publishersSelected: data.value });
    };

    this.handleRemovePublisher = user => {
      let channel = this.state.channel;
      let users = channel.publishers.filter(u => u !== user);
      channel.publishers = users;
      this.setState({ channel });
    };

    this.handleAddEditors = () => {
      let users = [];
      this.state.editorsSelected.forEach(id => {
        let u = this.props.users.find(user => user._id === id);
        users.push(u);
      });
      let channel = this.state.channel;
      channel.editors = users;

      this.setState({ channel, editorsSelected: [] });
    };

    this.handleSelectedEditor = (ev, data) => {
      this.setState({ editorsSelected: data.value });
    };

    this.handleRemoveEditor = user => {
      let channel = this.state.channel;
      let users = channel.editors.filter(u => u !== user);
      channel.editors = users;
      this.setState({ channel });
    };

    this.handleAddAdmins = () => {
      let users = [];
      this.state.adminsSelected.forEach(id => {
        let u = this.props.users.find(user => user._id === id);
        users.push(u);
      });
      let channel = this.state.channel;
      channel.admins = users;
      this.setState({ channel, adminsSelected: [] });
    };

    this.handleSelectedAdmin = (ev, data) => {
      this.setState({ adminsSelected: data.value });
    };

    this.handleRemoveAdmin = user => {
      let channel = this.state.channel;
      let users = channel.admins.filter(u => u !== user);
      channel.admins = users;
      this.setState({ channel });
    };

    this.save = () => {
      let channel = {
        slug: this.state.channel.slug,
        title: this.state.channel.title,
        public: this.state.channel.public,
        website: this.state.channel.website,
        domain: this.state.channel.domain,
        image: this.state.channel.image,
        menuBackgroundColor: this.state.channel.menuBackgroundColor,
        menuFontColor: this.state.channel.menuFontColor,
        backgroundColor: this.state.channel.backgroundColor,
        tabs: this.state.channel.tabs,
        font: this.state.channel.font,
        showHomeTab: this.state.channel.showHomeTab,
        headerGide: this.state.channel.headerGide
          ? this.state.channel.headerGide.id
          : null,
        footerGide: this.state.channel.footerGide
          ? this.state.channel.footerGide.id
          : null,
        sidebarGide: this.state.channel.sidebarGide
          ? this.state.channel.sidebarGide.id
          : null,
        sidebarResponsive: this.state.channel.sidebarResponsive,
        sidebarHome: this.state.channel.sidebarHome,
        tabPreset: this.state.channel.tabPreset,
        tabAlignment: this.state.channel.tabAlignment,
        logoPosition: this.state.channel.logoPosition,
        logoInline: this.state.channel.logoInline,
        favicon: this.state.channel.favicon,
        type: this.state.channel.type,
        publishers: this.state.channel.publishers.map(u => u._id),
        editors: this.state.channel.editors.map(u => u._id),
        admins: this.state.channel.admins.map(u => u._id),
        teaseArticles: this.state.channel.teaseArticles,
        price: this.state.channel.price,
        priceType: this.state.channel.priceType,
        purchaseType: this.state.channel.purchaseType,
        subscribeType: this.state.channel.subscribeType,
      };
      const promise = channel.slug
        ? agent.Channels.update(channel)
        : agent.Channels.create(channel);
      this.props.onSubmit(promise);
    };

    this.load = this._load.bind(this);
    this.createNewForTab = this._createNewForTab.bind(this);
  }

  async _createNewForTab(tabId) {
    const name = window.prompt('Choose a name for the tab:');
    if (!name) return;
    const article = {
      title: name,
      channel: this.state.channel._id,
    };
    let resp = await agent.Articles.create(article);
    if (resp.article) {
      articleOptions.unshift({
        key: resp.article.id,
        text: resp.article.title,
        value: resp.article.id,
      });

      this.props.onArticlesLoaded(
        agent.Articles.byAuthor(this.props.currentUser.username),
      );

      this.selectArticleForTab(tabId, resp.article.id);
    } else {
      alert('Sorry, please try again.');
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length) {
      this.setState({ loadingHeaderImage: true });
      ensureImageFileOrientation(acceptedFiles[0]).then(imageFile => {
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', imageFile)
          .end((err, res) => {
            if (err) {
              this.setState({ loadingHeaderImage: false });
            }
            if (res.body && res.body.url) {
              let channel = this.state.channel;
              channel.image = res.body.url;
              this.setState({ channel, loadingHeaderImage: false });
            }
          });
        return;
      });
    }
  }

  onDropFavicon(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length) {
      this.setState({ loadingFaviconImage: true });
      ensureImageFileOrientation(acceptedFiles[0]).then(imageFile => {
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', imageFile)
          .end((err, res) => {
            if (err) {
              this.setState({ loadingFaviconImage: false });
            }
            if (res.body && res.body.url) {
              let channel = this.state.channel;
              channel.favicon = res.body.url;
              this.setState({ channel, loadingFaviconImage: false });
            }
          });
        return;
      });
    }
  }

  async _load() {
    if (!this.props.match.params.slug) {
      return;
    }
    try {
      resp1 = await agent.Channels.get(this.props.match.params.slug);
      this.setState({ channel: resp1.channel, loading: false });
      this.props.onLoad();
    } catch (err) {
      alert('Sorry, please try again.');
    }
  }

  componentWillMount() {
    this.props.onUsersLoaded(agent.Users.getAll());
    this.props.onArticlesLoaded(
      agent.Articles.byAuthor(this.props.currentUser.username, null, 200),
    );
    this.load();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { channel, loading, loadingHeaderImage } = this.state;
    const { users, articles } = this.props;
    if (loading) {
      return <Loader active inline="centered" />;
    }
    if (users) {
      userOptions = users
        .filter(user => {
          return user.username !== this.props.currentUser.username;
        })
        .map(user => {
          return {
            text: user.username,
            value: user._id,
            image: {
              avatar: true,
              src: user.image,
            },
          };
        });
    }
    if (articles) {
      articleOptions = articles.map(article => {
        return {
          text: article.title,
          value: article.id,
        };
      });

      // TODO Fix. Something broke this.
      // articleOptions.push({
      //   text: '+ New',
      //   value: 'NEW',
      // });

      articleOptions.unshift({
        key: 'NONE',
        text: 'None',
        value: '',
      });
    }

    return (
      <div className="editor-page">
        <div className="container page">
          <div className="row">
            <div className="col-xs-12">
              <ListErrors errors={this.props.errors} />
              <div>
                <fieldset>
                  <fieldset>
                    <button
                      className="btn btn-lg pull-xs-right btn-primary"
                      type="button"
                      disabled={this.props.inProgress}
                      onClick={this.save}
                    >
                      {channel.slug ? 'Save' : 'Create'}
                    </button>
                    <Link to={`/channel/${channel.slug}`}>Cancel</Link>
                  </fieldset>
                  <h2>Channel Settings</h2>
                  <Divider />
                  <fieldset className="form-group">
                    <label>Title</label>
                    <Input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="required"
                      value={channel.title}
                      onChange={this.updateChannel('title')}
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Image</label>
                    {loadingHeaderImage && <Loader active inline="centered" />}
                    {channel.image && (
                      <Image
                        src={channel.image}
                        className="coverImage"
                        alt={`□`}
                      />
                    )}
                    <aside>
                      <div className="dropzone">
                        <DropZone
                          accept="image/jpeg, image/png"
                          onDrop={this.onDrop.bind(this)}
                          style={dropZoneStyles}
                          multiple={false}
                        >
                          <p>Drop file here, or click to select file.</p>
                        </DropZone>
                      </div>
                    </aside>
                  </fieldset>
                  <Divider />
                  <h2>Cost</h2>
                  <Divider />
                    <fieldset className="form-group">
                      <label>{channel.priceType === 'FIXED' ? `Price` : `Suggested Donation`} (in US dollars)</label>
                      <Input
                        className="form-control form-control-lg"
                        type="number"
                        min="0"
                        placeholder="optional"
                        value={channel.price || ''}
                        onChange={(e) => {
                          let channel = this.state.channel;
                          channel.price = e.target.value;
                          this.setState({ channel });
                        }}
                      />
                    </fieldset>
                    {channel.price >= 0.01 && (
                      <fieldset>
                        <fieldset>
                          <label>Price Type</label>
                          <div>
                            <Dropdown
                              selection
                              text={channel.priceType}
                              options={priceTypeOptions}
                              value={channel.priceType}
                              onChange={(evt, data) => {
                                let channel = this.state.channel;
                                channel.priceType = data.value;
                                this.setState({ channel });
                              }}
                            />
                          </div>
                        </fieldset>
                        <fieldset>
                          <label>Purchase Type</label>
                          <div>
                            <Dropdown
                              selection
                              text={channel.purchaseType}
                              options={purchaseTypeOptions}
                              value={channel.purchaseType}
                              onChange={(evt, data) => {
                                let channel = this.state.channel;
                                channel.purchaseType = data.value;
                                this.setState({ channel });
                              }}
                            />
                          </div>
                        </fieldset>
                        {channel.purchaseType === 'SUBSCRIBE' && (
                          <fieldset>
                            <label>Subscription Length</label>
                            <div>
                              <Dropdown
                                selection
                                text={channel.subscribeType}
                                options={subscribeTypeOptions}
                                value={channel.subscribeType}
                                onChange={(evt, data) => {
                                  let channel = this.state.channel;
                                  channel.subscribeType = data.value;
                                  this.setState({ channel });
                                }}
                              />
                            </div>
                          </fieldset>
                        )}
                      </fieldset>
                    )}
                  <Divider />
                  <h2>Access</h2>
                  <Divider />
                  <fieldset>
                    <h3>Type</h3>
                    <div>
                      <Dropdown
                        selection
                        text={channel.type}
                        options={typeOptions}
                        value={channel.type}
                        onChange={(evt, data) => {
                          let channel = this.state.channel;
                          channel.type = data.value;
                          this.setState({ channel });
                        }}
                      />
                    </div>
                  </fieldset>
                  {channel.type !== 'PUBLIC' && (
                    <fieldset className="form-group">
                      <Checkbox
                        onChange={this.updateChannelCheckbox('teaseArticles')}
                        toggle
                        checked={channel.teaseArticles}
                        label="Tease Gides (show previews to users without access)"
                      />
                    </fieldset>
                  )}
                  {channel.type !== 'HIDDEN' &&
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateChannelCheckbox('public')}
                      toggle
                      checked={channel.public}
                      label="Publicly Listed (special channels FYEO and Drafts are always hidden)"
                    />
                  </fieldset>
                  }
                  <fieldset>
                    <h3>Publishers</h3>
                    <p>Publishers can add Gides to this Channel</p>
                    <Dropdown
                      value={this.state.publishersSelected}
                      placeholder="Select User(s)"
                      search
                      fluid
                      selection
                      multiple
                      closeOnChange
                      options={userOptions}
                      onChange={this.handleSelectedPublisher}
                    />
                    <Button
                      onClick={this.handleAddPublishers}
                      content="Add"
                      icon="add"
                      disabled={
                        !this.state.publishersSelected ||
                        this.state.publishersSelected.length <= 0
                      }
                    />
                    <List relaxed selection divided>
                      {(channel.publishers || []).map((user, i) => {
                        return (
                          <List.Item key={i}>
                            <GideImage 
                              className={`svgIcon small pointer color-secondary-500-svg`}
                              src="/icons/content-alteration/delete.svg"
                              alt={`□`}
                              onClick={() => {
                                this.handleRemovePublisher(user);
                              }}
                            />
                            <Image avatar src={user.image} />
                            <List.Content>
                              <List.Header as="a">{user.username}</List.Header>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </fieldset>
                  <fieldset>
                    <h3>Editors</h3>
                    <p>Editors can edit existing Gides in this channel</p>
                    <Dropdown
                      value={this.state.editorsSelected || []}
                      placeholder="Select User(s)"
                      search
                      fluid
                      selection
                      multiple
                      closeOnChange
                      options={userOptions}
                      onChange={this.handleSelectedEditor}
                    />
                    <Button
                      onClick={this.handleAddEditors}
                      content="Add"
                      icon="add"
                      disabled={
                        !this.state.editorsSelected ||
                        this.state.editorsSelected.length <= 0
                      }
                    />
                    <List relaxed selection divided>
                      {(channel.editors || []).map((user, i) => {
                        return (
                          <List.Item key={i}>
                            <GideImage 
                              className={`svgIcon small pointer color-secondary-500-svg`}
                              src="/icons/content-alteration/delete.svg"
                              alt={`□`}
                              onClick={() => {
                                this.handleRemoveEditor(user);
                              }}
                            />
                            <Image avatar src={user.image} />
                            <List.Content>
                              <List.Header as="a">{user.username}</List.Header>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </fieldset>
                  <fieldset>
                    <h3>Admins</h3>
                    <p>
                      Admins can add, edit, or delete existing Gides in this
                      channel.{' '}
                    </p>
                    <Dropdown
                      value={this.state.adminsSelected || []}
                      placeholder="Select User(s)"
                      search
                      fluid
                      selection
                      multiple
                      closeOnChange
                      options={userOptions}
                      onChange={this.handleSelectedAdmin}
                    />
                    <Button
                      onClick={this.handleAddAdmins}
                      content="Add"
                      icon="add"
                      disabled={
                        !this.state.adminsSelected ||
                        this.state.adminsSelected.length <= 0
                      }
                    />
                    <List relaxed selection divided>
                      {(channel.admins || []).map((user, i) => {
                        return (
                          <List.Item key={i}>
                            <GideImage 
                              className={`svgIcon small pointer color-secondary-500-svg`}
                              src="/icons/content-alteration/delete.svg"
                              alt={`□`}
                              onClick={() => {
                                this.handleRemoveAdmin(user);
                              }}
                            />
                            <Image avatar src={user.image} />
                            <List.Content>
                              <List.Header as="a">{user.username}</List.Header>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </fieldset>
                  {channel.public && (
                    <fieldset className="form-group">
                      <Checkbox
                        onChange={this.updateChannelCheckbox('website')}
                        toggle
                        checked={channel.website}
                        label="Website"
                      />
                    </fieldset>
                  )}
                  {channel.website && (
                    <div>
                      <fieldset className="form-group">
                        <label>Domain</label>
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="example.com"
                          value={channel.domain}
                          onChange={this.updateChannel('domain')}
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <label>Favicon</label>
                        {loadingHeaderImage && (
                          <Loader active inline="centered" />
                        )}
                        {channel.favicon && (
                          <Image
                            src={channel.favicon}
                            alt={`□`}
                            style={{ height: 50 }}
                          />
                        )}
                        <aside>
                          <div className="dropzone">
                            <DropZone
                              accept="image/jpeg, image/png"
                              onDrop={this.onDropFavicon.bind(this)}
                              style={dropZoneStyles}
                              multiple={false}
                            >
                              <p>Drop file here, or click to select file.</p>
                            </DropZone>
                          </div>
                        </aside>
                        <div>
                          <Button
                            basic
                            size="small"
                            content="No Favicon"
                            onClick={() => {
                              let channel = this.state.channel;
                              channel.favicon = '';
                              this.setState({ channel });
                            }}
                          />
                        </div>
                      </fieldset>
                      <Divider />
                      <h2>Components</h2>
                      <Divider />
                      <fieldset>
                        <label>Header: </label>
                          <fieldset>
                            <ArticlePreviewSquare article={channel.headerGide} />
                            <fieldset>
                              <Button size="small" onClick={() => {
                                  this.props.openModal({
                                    modalType: 'SelectGideModal',
                                    modalProps: {
                                      articles,
                                      saveCallback: exportInfo => {
                                        let selected = articles.find(obj => {
                                          return obj.id === exportInfo.articleId;
                                        });
                                        let channel = this.state.channel;
                                        channel.headerGide = selected;
                                        this.setState({ channel });
                                      },
                                      saveButtonTitle: 'Select',
                                    },
                                  });
                                }}>{channel.headerGide ? `Change` : `Select Gide`}
                              </Button>
                            </fieldset>
                          </fieldset>
                      </fieldset>
                      <fieldset>
                        <label>Tabs</label>
                        <div>
                          <Button
                            onClick={this.onAddTab}
                            icon="add"
                            content="Add"
                          />
                        </div>
                        <List ordered>
                          {channel.tabs.map((tab, i) => {
                            return (
                              <List.Item key={i}>
                                <List.Content>
                                  <fieldset>
                                    <ArticlePreviewSquare article={tab.article} />
                                    <p>
                                      <Button size="small" onClick={() => {
                                          this.props.openModal({
                                            modalType: 'SelectGideModal',
                                            modalProps: {
                                              articles,
                                              saveCallback: (exportInfo) => {this.onTabChange(i, exportInfo)},
                                              saveButtonTitle: 'Select for Tab',
                                            },
                                          });
                                        }}>{tab.article ? `Change` : `Select Gide`}
                                      </Button>
                                    </p>
                                  </fieldset>
                                  <fieldset>
                                    <label>Tab Text</label>
                                    <Input
                                      placeholder="tab text"
                                      value={tab.text}
                                      onChange={this.onChangeTabText(i)}
                                    />
                                  </fieldset>
                                  <fieldset>
                                    <label>Sidebar: </label>
                                      <fieldset>
                                        <ArticlePreviewSquare article={tab.sidebarArticle} />
                                        <p>
                                          <Button size="small" onClick={() => {
                                              this.props.openModal({
                                                modalType: 'SelectGideModal',
                                                modalProps: {
                                                  articles,
                                                  saveCallback: (exportInfo) => {this.onTabSidebarChange(i, exportInfo)},
                                                  saveButtonTitle: 'Select for Subtab',
                                                },
                                              });
                                            }}>{tab.article ? `Change` : `Select Gide`}
                                          </Button>
                                        </p>
                                      </fieldset>
                                    <div>
                                      <Checkbox
                                        onChange={this.updateTabCheckbox(
                                          i,
                                          'sidebar',
                                        )}
                                        toggle
                                        checked={tab.sidebar}
                                        label="Sidebar On"
                                      />
                                    </div>
                                  </fieldset>
                                  <Button
                                    onClick={this.onAddSubTab(i)}
                                    icon="add"
                                    content="Add Subtab"
                                  />
                                  <Button
                                    icon
                                    onClick={() => this.onMoveTabUp(i)}
                                    disabled={i === 0}
                                  >
                                    <Icon name="arrow up" />
                                  </Button>
                                  <Button
                                    icon
                                    onClick={() => this.onMoveTabDown(i)}
                                    disabled={i === channel.tabs.length - 1}
                                  >
                                    <Icon name="arrow down" />
                                  </Button>
                                  <Button
                                    icon
                                    onClick={() => this.onRemoveTab(i)}
                                  >
                                    <Icon name="trash" color="red" />
                                  </Button>
                                  <List>
                                    {tab.subtabs.map((subtab, iSubtab) => {
                                      return (
                                        <List.Item key={iSubtab}>
                                          <List.Content>
                                            <fieldset>
                                              <ArticlePreviewSquare article={subtab.article} />
                                              <fieldset>
                                                <Button size="small" onClick={() => {
                                                    this.props.openModal({
                                                      modalType: 'SelectGideModal',
                                                      modalProps: {
                                                        articles,
                                                        saveCallback: exportInfo => {
                                                          this.selectArticleForSubTab(i, iSubtab, exportInfo.articleId);
                                                        },
                                                        saveButtonTitle: 'Select',
                                                      },
                                                    });
                                                  }}>{channel.sidebarGide ? `Change` : `Select Gide`}
                                                </Button>
                                              </fieldset>
                                            </fieldset>
                                            <Button
                                              icon
                                              onClick={() =>
                                                this.onRemoveSubTab(i, iSubtab)
                                              }
                                            >
                                              <Icon name="trash" color="red" />
                                            </Button>
                                          </List.Content>
                                        </List.Item>
                                      );
                                    })}
                                  </List>
                                </List.Content>
                              </List.Item>
                            );
                          })}
                        </List>
                      </fieldset>
                      <fieldset className="form-group">
                        <Checkbox
                          onChange={this.updateChannelCheckbox('showHomeTab')}
                          toggle
                          checked={channel.showHomeTab}
                          label="Show Home Tab"
                        />
                      </fieldset>
                      <fieldset>
                        <p>Align Tabs:</p>
                        <div>
                          <Dropdown
                            text={channel.tabAlignment}
                            options={tabAlignmentOptions}
                            value={channel.tabAlignment}
                            onChange={(evt, data) => {
                              let channel = this.state.channel;
                              channel.tabAlignment = data.value;
                              this.setState({ channel });
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset>
                        <label>Footer: </label>
                        <fieldset>
                          <ArticlePreviewSquare article={channel.footerGide} />
                          <fieldset>
                            <Button size="small" onClick={() => {
                                this.props.openModal({
                                  modalType: 'SelectGideModal',
                                  modalProps: {
                                    articles,
                                    saveCallback: exportInfo => {
                                      let selected = articles.find(obj => {
                                        return obj.id === exportInfo.articleId;
                                      });
                                      let channel = this.state.channel;
                                      channel.footerGide = selected;
                                      this.setState({ channel });
                                    },
                                    saveButtonTitle: 'Select',
                                  },
                                });
                              }}>{channel.footerGide ? `Change` : `Select Gide`}
                            </Button>
                          </fieldset>
                        </fieldset>
                      </fieldset>
                      <fieldset>
                        <label>Sidebar (global): </label>
                          <fieldset>
                            <ArticlePreviewSquare article={channel.sidebarGide} />
                            <fieldset>
                              <Button size="small" onClick={() => {
                                  this.props.openModal({
                                    modalType: 'SelectGideModal',
                                    modalProps: {
                                      articles,
                                      saveCallback: exportInfo => {
                                        let selected = articles.find(obj => {
                                          return obj.id === exportInfo.articleId;
                                        });
                                        let channel = this.state.channel;
                                        channel.sidebarGide = selected;
                                        this.setState({ channel });
                                      },
                                      saveButtonTitle: 'Select',
                                    },
                                  });
                                }}>{channel.sidebarGide ? `Change` : `Select Gide`}
                              </Button>
                            </fieldset>
                          </fieldset>
                        <div>
                          <Checkbox
                            onChange={this.updateChannelCheckbox(
                              'sidebarResponsive',
                            )}
                            toggle
                            checked={channel.sidebarResponsive}
                            label="Sidebars in Responsive Views"
                          />
                        </div>
                        <div>
                          <Checkbox
                            onChange={this.updateChannelCheckbox('sidebarHome')}
                            toggle
                            checked={channel.sidebarHome}
                            label="Sidebar in Home Page"
                          />
                        </div>
                      </fieldset>
                      <Divider />
                      <h2>Style</h2>
                      <Divider />
                      <fieldset>
                        <label>Main Background Color</label>
                        <div>
                          <CompactPicker
                            color={channel.backgroundColor}
                            onChange={this.updateChannelColorPicker(
                              'backgroundColor',
                            )}
                          />
                        </div>
                      </fieldset>
                      <h3>Font Family</h3>
                      <div>
                        <p
                          style={{ fontFamily: channel.font, fontSize: '2em' }}
                        >
                          This is example text.
                        </p>
                        <Dropdown
                          placeholder="Font"
                          options={fonts}
                          value={channel.font}
                          onChange={(evt, data) => {
                            let channel = this.state.channel;
                            channel.font = data.value;
                            this.setState({ channel });
                          }}
                        />
                      </div>
                      <h1>Menu</h1>
                      <p>Color Presets:</p>
                      <List selection>
                        {menuPresets.map((preset, i) => (
                          <List.Item
                            key={i}
                            onClick={() => this.selectedMenuPreset(preset)}
                          >
                            <List.Content>{preset.name}</List.Content>
                          </List.Item>
                        ))}
                      </List>
                      <fieldset>
                        <label>Background Color</label>
                        <div>
                          <CompactPicker
                            color={channel.menuBackgroundColor}
                            onChange={this.updateChannelColorPicker(
                              'menuBackgroundColor',
                            )}
                          />
                        </div>
                        <div>
                          <Button
                            basic
                            size="small"
                            content="None"
                            onClick={() => {
                              let channel = this.state.channel;
                              channel.menuBackgroundColor = '';
                              this.setState({ channel });
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset>
                        <label>Font Color</label>
                        <div>
                          <CompactPicker
                            color={channel.menuFontColor}
                            onChange={this.updateChannelColorPicker(
                              'menuFontColor',
                            )}
                          />
                        </div>
                      </fieldset>
                      <fieldset>
                        <p>Behavior Presets:</p>
                        <div>
                          <Dropdown
                            text={channel.tabPreset}
                            options={tabOptions}
                            value={channel.tabPreset}
                            onChange={(evt, data) => {
                              let channel = this.state.channel;
                              channel.tabPreset = data.value;
                              this.setState({ channel });
                            }}
                          />
                        </div>
                        <h3>Tabs preview</h3>
                        <div className={channel.tabPreset}>
                          <div
                            role="list"
                            className="ui horizontal list tabMenu"
                            style={{
                              color: channel.menuFontColor,
                              background: channel.menuBackgroundColor,
                            }}
                          >
                            <div role="listitem" className="item">
                              <div className="content">
                                <a href="#/">Home</a>
                              </div>
                            </div>
                            <div role="listitem" className="item">
                              <div className="content">
                                <a href="#/">Contact</a>
                              </div>
                            </div>
                            <div role="listitem" className="item">
                              <div className="content">
                                <a href="#/">Blog</a>
                              </div>
                            </div>
                            <div role="listitem" className="item">
                              <div className="content">
                                <a href="#/">About</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset>
                        <p>Logo Position:</p>
                        <div>
                          <Dropdown
                            text={channel.logoPosition}
                            options={logoPositionOptions}
                            value={channel.logoPosition}
                            onChange={(evt, data) => {
                              let channel = this.state.channel;
                              channel.logoPosition = data.value;
                              if (channel.logoPosition === 'CENTER') {
                                // Centered inline not supported yet
                                channel.logoInline = false;
                              }
                              this.setState({ channel });
                            }}
                          />
                        </div>
                        <div>
                          <Checkbox
                            onChange={this.updateLogoInline('logoInline')}
                            toggle
                            checked={channel.logoInline}
                            label="Inline"
                          />
                        </div>
                      </fieldset>
                    </div>
                  )}
                  <fieldset>
                    <button
                      className="btn btn-lg pull-xs-right btn-primary"
                      type="button"
                      disabled={this.props.inProgress}
                      onClick={this.save}
                    >
                      {channel.slug ? 'Save' : 'Create'}
                    </button>
                    <Link to={`/channel/${channel.slug}`}>Cancel</Link>
                  </fieldset>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
