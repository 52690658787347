import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { SlideUpdateInfo } from '../../../models/Slide';
import { ADD_SLIDE, MODAL_CLOSE, SET_TOASTER_MESSAGE } from "../../../constants/actionTypes";
import { Dispatch } from "react";
import { GideAction } from "../../../models/GideAction";

export const slideEditorModalMapDispatchToProps = (dispatch: Dispatch<GideAction>, ownProps: any) => ({
  onSubmitSlide: ownProps.onSubmitSlide ? ownProps.onSubmitSlide : (payload: SlideUpdateInfo) =>
    dispatch({ type: ADD_SLIDE, payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) =>
    dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }),
});
