import React, { Component } from 'react';
import { Input, Button } from 'semantic-ui-react';

export class LeadSlide extends Component {
  constructor() {
    super();

    this.state = {
      files: [],
    };
    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };
  }

  render() {
    return (
      <div>
        {this.props.slide.data.type === 'email' && (
          <div>
            <p>
              Please enter your email{this.props.slide.data.required
                ? ' to continue.'
                : '.'}
            </p>
            <Input
              fluid
              placeholder="name@example.com"
              value={this.state.body}
              onChange={this.updateState('body')}
            />
          </div>
        )}
        {this.props.slide.data.type === 'phone' && (
          <div>
            <p>
              Please enter your phone #{this.props.slide.data.required
                ? ' to continue.'
                : '.'}
            </p>
            <Input
              fluid
              placeholder="+1 555-555-5555"
              value={this.state.body}
              onChange={this.updateState('body')}
            />
          </div>
        )}
        {this.props.slide.data.type === 'gidePost' && (
          <div>
            Allow your interaction on this Gide to be used in a Gide Post?
            <section>
              <Button>Allow Gide Post</Button>
            </section>
          </div>
        )}
        {this.props.slide.data.type === 'vcf' && (
          <div>
            Allow the creator of this Gide access to your Gide profile?
            <section>
              <Button>Allow Access</Button>
            </section>
          </div>
        )}
        {this.props.slide.data.type === 'socialMedia' && (
          <section>
            Please enter your Twitter username.
            <section>
              <Input placeholder="Username" />
            </section>
          </section>
        )}
        {this.props.slide.data.type === 'follow' && (
          <section>
            Follow the creator of this Gide?
            <section>
              <Button>Follow</Button>
            </section>
          </section>
        )}
      </div>
    );
  }
}

export default LeadSlide;
