import React from 'react';
import styles from './_gide-owner-view-panel.module.scss';
import Avatar from '../../../Shared/Avatar/Avatar';
import { RoundedCornerButton } from '../../../Shared/RoundedCornerButton/RoundedCornerButton';
import icons from '../../../../assets/icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { urlForUser } from '../../../../utils/helperFunctions';

interface SignUpButtonsProps {
  signUp: () => void;
  signIn: () => void;
}
const SignUpButtons: React.FC<SignUpButtonsProps> = (props) => {
  if (process.env.REACT_APP__READ_ONLY === 'true' || process.env.REACT_APP__IOS === 'true') {
    return (<>
      <RoundedCornerButton
        label="Sign up"
        style={{
          backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
          width: '76px',
          height: '32px',
          marginRight: "18px",
          marginLeft: "12px",
        }}
        onClick={props.signUp}
      />
      {process.env.REACT_APP__IOS === 'true' && (
        <RoundedCornerButton
          label="Sign in"
          style={{
            backgroundColor: 'var(--COLOR-SECONDARY-200)',
            width: '76px',
            height: '32px',
            paddingLeft: "5px",
            color: "rgba(0, 0, 0, 0.45)",
          }}
          fontCssClass='TEXTSUBTITLEblackmedium-emphasiscenter'
          onClick={props.signIn}
        />        
      )}
    </>);
  } else {
    return (<>
      {/* <Link to="/login"> */}
        <RoundedCornerButton
          label="Sign in"
          style={{
            backgroundColor: 'var(--COLOR-SECONDARY-200)',
            width: '76px',
            height: '32px',
            paddingLeft: "5px",
            color: "rgba(0, 0, 0, 0.45)",
          }}
          fontCssClass='TEXTSUBTITLEblackmedium-emphasiscenter'
          onClick={props.signIn}
        />
      {/* </Link> */}
      <Link to="/register">
        <RoundedCornerButton
        label="Sign up"
        style={{
          backgroundImage: 'linear-gradient(291deg, var(--COLOR-PRIMARY-700) 102%, #fab317 29%)',
          width: '76px',
          height: '32px',
          marginRight: "18px",
          marginLeft: "12px",
        }}
        />
      </Link>
    </>);
  }
}

export interface GideOwnerViewPanelProps {
  gideAuthorImage: string;
  gideAuthorName: string;
  isVerified: boolean;
  className?: string;
  leftSidebarOpen: boolean;
  isFollowing: boolean;
  followUser?: (username: string) => void;
  unFollowUser: (username: string) => void;
  signUp: () => void;
  signIn: () => void;
  isLoggedIn: boolean;
}

export default function GideOwnerViewPanel(props: GideOwnerViewPanelProps) {
  return (
    <div
      className={classNames(
        styles.gideOwnerViewPanelContainer,
        props.className,
        props.leftSidebarOpen ? styles.leftSidebarOpen : undefined,
      )}
    >
      <Link to={`?profile=${props.gideAuthorName}&page=profile`} style={{ display: 'flex', flex: '1' }}>
        <Avatar
          avatarImage={props.gideAuthorImage}
          imgStyle={{ width: '32px', height: '32px' }}
          style={{ marginLeft: '6px' }}
          outerBorderColor=""
          displayOuterBorder={true}
        />
        <div className={styles.authorGideDetails}>
          <div className={styles.gideAuthor}>
            <span style={{ marginRight: '3px', color: '#4183c4' }}>{props.gideAuthorName}</span>
            {props.isVerified && <icons.Avatar_Verifyed doNotFillFromColor={true} width={15} height={15} />}
          </div>
          <div className={styles.gideDetails}>
            {'Aug 12 • ★★★★'}
            {/* TODO: Get actual verified date */}
          </div>
        </div>
      </Link>
      {props.isLoggedIn && (
        <RoundedCornerButton
          label={props.isFollowing ? 'Unfollow' : 'Follow'}
          style={{
            backgroundImage: 'linear-gradient(305deg, var(--COLOR-PRIMARY-700) 106%, #fab317 30%',
            marginRight: '16px',
            width: '57px',
          }}
          onClick={() => (props.isFollowing
            ? props.unFollowUser(props.gideAuthorName)
            : props.followUser && props.followUser(props.gideAuthorName))
          }
        />
      )}
      {!props.isLoggedIn && <SignUpButtons signUp={props.signUp} signIn={props.signIn} />}
      {/* {!props.followUser && props.signUp && (
        <RoundedCornerButton
          label='Sign up'
          style={{
            backgroundImage: 'linear-gradient(305deg, var(--COLOR-PRIMARY-700) 106%, #fab317 30%',
            marginRight: '16px',
            width: '57px',
          }}
          onClick={props.signUp}
        />
      )} */}
    </div>
  );
}
