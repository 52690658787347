/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import React from 'react';
import { compose, withProps, lifecycle } from  'recompose';
import {
  withScriptjs,
} from 'react-google-maps';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import { GOOGLE_MAPS_URL } from '../../constants/paths';

const LocationField = compose(
  withProps({
    googleMapURL: GOOGLE_MAPS_URL,
    loadingElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      this.setState({
        onChange: address => {
          this.props.onAddressChanged(address);
          geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
              this.props.onLatLngChanged(address, latLng)
            })
            .catch(error => console.error('Error', error))
        },
      })
    },
  }),
  withScriptjs
)(props =>
  <PlacesAutocomplete inputProps={{
    value: props.address,
    onChange: props.onChange,
    placeholder: 'Location',
    onKeyDown: props.onKeyDown,
  }} />
);
export default LocationField;
