import React from 'react';
import { getSlideFileUrl } from '../../../../utils/helperFunctions';
import { ImageFile } from '../../../../models/SlideFile';
import GideImage from '../../../Shared/Image/GideImage';
export interface ImageSwipeProps {
  imageList: ImageFile[];
  width?: number;
  view: string;
  onClick?: () => void;
}

export default function ImageSwipe(props: ImageSwipeProps) {
  return (
    <div className="gideImageSwipeContainer"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <div className="gideImageSwipe">
        <div className="gideImageSwipeImage1">
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 0
              ? getSlideFileUrl(props.imageList[0])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'}
            alt="top left display"
          />
        </div>
        <div className="gideImageSwipeImage2">
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 1
              ? getSlideFileUrl(props.imageList[1])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
            }
            alt="top right display"
          />
        </div>
        <div className="gideImageSwipeImage3">
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 2
              ? getSlideFileUrl(props.imageList[2])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
            }
            alt="bottom left display"
          />
        </div>
        <div className="gideImageSwipeImage4">
          <GideImage 
            className={props.imageList.length > 0
              ? ''
              : 'missingResourceIcon'
            }
            src={props.imageList.length > 3
              ? getSlideFileUrl(props.imageList[3])
              : '/icons/content-alteration/camera/MISSING_RESOURCE_REPLACE.svg'
            }
            alt="bottom right display"
          />
        </div>
        {props.imageList.length > 4 && (
          <div className="lastImageOverlay">
            {`+${props.imageList.length - 4} Images`}
          </div>
        )}
      </div>
    </div>
  );
}
