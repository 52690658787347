import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, List, Button, Image, Grid, Icon } from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import GideImage from '../Shared/Image/GideImage';
import { contentMetaData } from '../../constants/contentMetaData';
import agent from '../../agent';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  GIDE_MODAL_LOADED,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import slideTools from '../../slideTools';
import isNil from 'ramda/es/isNil';

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.common.currentUser,
  ...slideTools.mapStateToProps(state, ownProps),
  ...state.articleList,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: GIDE_MODAL_LOADED, payload }),
  onSubmit: payload => dispatch({ type: ADD_SLIDE, payload }),
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class BlogModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: 'GRID',
      channels: [],
      slide: {
        slideType: 'BLOG',
        data: {
          audioCaption: null,
          caption: '',
          channel: null,
        },
      },
    };

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.changeLayout = layout => {
      this.setState({ layout });
    };

    this.selectChannel = channel => {
      let slide = Object.assign({}, this.state.slide);
      slide.data.channel = channel;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: this.props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };

      if (!slide.data.channel) {
        return alert('Please select a Channel.');
      }

      let payload;
      if (this.props.editSlide) {
        payload = await agent.Slides.update(this.props.editSlide.id, slide);
      } else {
        payload = await agent.Slides.create(this.props.article, slide);
        if (this.props.childArticleEditInfo) {
          slideTools.getSlideAttachmentInfo(
            this.props.childArticleEditInfo.ownerSlide.id,
            this.props.updateSlideAttachmentInfo,
          );
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit({ ...payload, mode: this.props.mode });
      this.props.closeModal();
    };

    this.load = this._load.bind(this);
  }

  async _load() {
    try {
      const resp = await agent.Channels.byAuthor(
        this.props.currentUser.username,
      );
      this.setState({ channels: resp.channels });
    } catch (e) {
      alert('Sorry, please try again later.');
    }
  }

  componentWillMount() {
    this.load();
  }

  render() {
    const { layout, slide, channels } = this.state;

    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="gideModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(237, 168, 52)' }}
          >
            <Button id="modalClose" icon onClick={this.props.closeModal}>
              <Icon name="close" />
            </Button>
            <span>Channel as Blog</span>
            <Button.Group className="layoutBtns">
              <Button
                icon="block layout"
                onClick={() => this.changeLayout('GRID')}
              />
              <Button
                icon="list layout"
                onClick={() => this.changeLayout('LIST')}
              />
            </Button.Group>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-gide.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <section>
                {(!channels || !channels.length) && (
                  <div>No Channels available.</div>
                )}
                {layout === 'LIST' && (
                  <List selection divided relaxed>
                    {channels.map((c, i) => (
                      <List.Item
                        key={i}
                        onClick={() => this.selectChannel(c)}
                        className={
                          slide.data.channel
                            ? slide.data.channel.slug === c.slug
                              ? 'selected'
                              : ''
                            : ''
                        }
                      >
                        <GideImage src={c.image} className="icon sm" alt={`□`} />
                        <List.Content>{c.title}</List.Content>
                      </List.Item>
                    ))}
                  </List>
                )}
                {layout === 'GRID' && (
                  <Grid columns={3} padded>
                    {channels.map((c, i) => (
                      <Grid.Column
                        key={i}
                        onClick={() => this.selectChannel(c)}
                        className={
                          slide.data.channel
                            ? slide.data.channel.slug === c.slug
                              ? 'selected'
                              : ''
                            : ''
                        }
                      >
                        <GideImage src={c.image} alt={`□`} />
                      </Grid.Column>
                    ))}
                  </Grid>
                )}
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions
          style={{ background: contentMetaData['GIDE'].primaryColor }}
        >
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide.bind(this)}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogModal);
