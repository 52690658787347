import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, Image, Dropdown, Checkbox } from 'semantic-ui-react';
import DropZone from 'react-dropzone';
import superagent from 'superagent';

import {
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
  LOGOUT,
} from '../constants/actionTypes';
import agent from '../agent';
import { API_ROOT } from '../constants/paths';
import ListErrors from './ListErrors';
import { ensureImageFileOrientation } from '../utils/helperFunctions';

const dropZoneStyles = {
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};

const langOptions = [
  { key: 'en', value: 'en', flag: 'us', text: 'English' },
  { key: 'es', value: 'es', flag: 'es', text: 'Spanish' },
  { key: 'fr', value: 'fr', flag: 'fr', text: 'French' },
  { key: 'it', value: 'it', flag: 'it', text: 'Italian' },
];

class SettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: '',
      username: '',
      bio: '',
      email: '',
      password: '',
      language: '',
      public: '',
      business: '',
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: data.checked });
      this.setState(newState);
    };

    this.submitForm = ev => {
      ev.preventDefault();

      const user = Object.assign({}, this.state);
      // console.log(user)
      if (!user.password) {
        delete user.password;
      }

      this.props.onSubmitForm(user);
    };
  }

  componentWillMount() {
    if (this.props.currentUser) {
      Object.assign(this.state, {
        image: this.props.currentUser.image || '',
        username: this.props.currentUser.username,
        bio: this.props.currentUser.bio,
        email: this.props.currentUser.email,
        language: this.props.currentUser.language,
        public: this.props.currentUser.public,
        business: this.props.currentUser.business,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      this.setState(
        Object.assign({}, this.state, {
          image: nextProps.currentUser.image || '',
          username: nextProps.currentUser.username,
          bio: nextProps.currentUser.bio,
          email: nextProps.currentUser.email,
          language: nextProps.currentUser.language,
          public: this.props.currentUser.public,
          business: this.props.currentUser.business,
        }),
      );
    }
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length) {
      ensureImageFileOrientation(acceptedFiles[0])
      .then(imageFile => {
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', imageFile)
          .end((err, res) => {
            if (err) console.log(err);
            if (res.body && res.body.url) {
              const url = res.body.url;
              this.setState({ image: url });
            }
          });
          return;
      });
    }
  }

  render() {
    return (
      <form onSubmit={this.submitForm}>
        <fieldset>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={this.props.onClickLogout}
          >
            Logout.
          </button>
          <fieldset className="form-group editPic">
            {this.state.image && <Image src={this.state.image} alt={`□`} />}
            <aside>
              <div className="dropzone">
                <DropZone
                  accept="image/jpeg, image/png"
                  onDrop={this.onDrop.bind(this)}
                  style={dropZoneStyles}
                  multiple={false}
                >
                  <p>Drop file or select.</p>
                </DropZone>
              </div>
            </aside>
          </fieldset>
          <label>Username</label>
          <fieldset className="form-group">
            <Input
              className="form-control form-control-lg"
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={this.updateState('username')}
            />
          </fieldset>

          <label>About Me..</label>
          <fieldset className="form-group">
            <textarea
              className="form-control form-control-lg"
              rows="3"
              placeholder=""
              value={this.state.bio}
              onChange={this.updateState('bio')}
            />
          </fieldset>

          <fieldset className="form-group">
            <Checkbox
              onChange={this.updateStateCheckbox('public')}
              toggle
              checked={this.state.public}
              label="Allow yourself to be shown in public listings"
            />
          </fieldset>

          <fieldset className="form-group">
            <Checkbox
              onChange={this.updateStateCheckbox('business')}
              toggle
              checked={this.state.business}
              label="Business Account"
            />
          </fieldset>

          <label>Language</label>
          <fieldset>
            <Dropdown
              placeholder='Select Language'
              fluid
              search
              selection
              options={langOptions}
              value={this.state.language}
              onChange={(ev, data) => {
                this.setState({ language: data.value });
              }}
            />
          </fieldset>

          <label>Email</label>
          <fieldset className="form-group">
            <Input
              className="form-control form-control-lg"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.updateState('email')}
            />
          </fieldset>

          <label>Password</label>
          <fieldset className="form-group">
            <Input
              className="form-control form-control-lg"
              type="password"
              placeholder="New Password"
              value={this.state.password}
              onChange={this.updateState('password')}
            />
          </fieldset>

          <button
            className="btn btn-lg btn-primary pull-xs-right"
            type="submit"
            disabled={this.state.inProgress}
          >
            Save
          </button>

          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={this.props.onClickLogout}
          >
            Logout.
          </button>
        </fieldset>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  ...state.settings,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onSubmitForm: user =>
    dispatch({ type: SETTINGS_SAVED, payload: agent.Auth.save(user) }),
  onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED }),
});

class Settings extends React.Component {
  constructor() {
    super();

    this.handleOpenModalClick = e => {
      this.props.openModal({
        modalType: 'ChoicesModal',
      });
    };
  }
  render() {
    return (
      <div className="settings-page">
        <div className="row" style={{display: 'flex', flex: 1}}>
          <div className="col-xs-10 offset-xs-1">
            <h1 className="text-xs-center">Edit Profile</h1>

            <ListErrors errors={this.props.errors} />

            <SettingsForm
              currentUser={this.props.currentUser}
              onSubmitForm={this.props.onSubmitForm}
              onClickLogout={this.props.onClickLogout}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
