import React from 'react';
import styles from './_preview-avatar.module.scss';
import { Link } from 'react-router-dom';
import GideImage from '../../Image/GideImage';

export interface PreviewAvatarProps {
  authorImage: string;
  author: string;
  disabled?: boolean;
}

export default function PreviewAvatar(props: PreviewAvatarProps) {
  return (
    <div className={`${styles.previewAvatarContainer} ${props.disabled ? styles.disabled : ''}`}>
      <Link to={`?profile=${props.author}&page=profile`}>
        <div className={styles.authorDetails}>
          <GideImage className={styles.authorImage} src={props.authorImage} alt={props.author} />
          <div className={styles.authorUsername}>{props.author}</div>
        </div>
      </Link>
    </div>
  );
}
