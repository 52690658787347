import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Label,
  Button,
  Loader,
  Segment,
  Image,
  List,
} from 'semantic-ui-react';

import ArticleList from './ArticleList';
import agent from '../agent';
import {
  SET_LOADING,
  ACCOUNT_RECHARGED,
  ACCOUNT_PAGE_LOADED,
  ACCOUNT_PAGE_UNLOADED,
  LOGOUT,
  UPDATE_ACCOUNT_EDITOR,
  SET_TOASTER_MESSAGE,
} from '../constants/actionTypes';
import { NotificationType } from '../constants/strings';

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  loading: state.common.loading,
  rechargeAmount: state.common.rechargeAmount,
  account: state.common.account,
});

const mapDispatchToProps = dispatch => ({
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_ACCOUNT_EDITOR, key, value }),
  setLoading: () => dispatch({ type: SET_LOADING }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onRecharge: info =>
    dispatch({
      type: ACCOUNT_RECHARGED,
      payload: agent.Account.recharge(info),
    }),
  onLoad: payload => dispatch({ type: ACCOUNT_PAGE_LOADED, payload }),
  onUnload: () => dispatch({ type: ACCOUNT_PAGE_UNLOADED }),
  showNotification: payload => dispatch({ type: SET_TOASTER_MESSAGE, payload }),
});

class Account extends Component {
  constructor(props) {
    super(props);

    this.updateFieldEvent = key => ev =>
      this.props.onUpdateField(key, ev.target.value);

    this.onRecharge = () => {
      this.props.setLoading();
      this.props.onRecharge({
        amount: this.props.rechargeAmount,
      });
    };
    this.requestPayout = () => {
      this.props.showNotification({
        toasterMessageInfo: {
          message: `Coming soon.`,
          type: NotificationType.INFO,
        },
      });
    };
    this.refund = p => {
      const confirmed = window.confirm('Are you sure?');
      if (!confirmed) return;

      agent.Articles.refund(p)
        .then(resp => {
          this.props.showNotification({
            toasterMessageInfo: {
              message: `Refunded.`,
              type: NotificationType.INFO,
            },
          });
          this.load();
        })
        .catch(err => {
          this.props.showNotification({
            toasterMessageInfo: {
              message: `Please try again.`,
              type: NotificationType.INFO,
            },
          });
        });
    };
  }

  load() {
    this.props.onLoad(agent.Account.get());
  }

  componentWillMount() {
    this.load();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { currentUser, loading, rechargeAmount, account } = this.props;
    return (
      <div className="accountPage">
        <h1 className="text-xs-center">Account</h1>

        <div style={{ display: 'flex', flex: 1, height: '30px' }}>
          {loading && <Loader active inline="centered" />}
        </div>
        <h2>Balance</h2>
        <p>Your balance is ${currentUser.balance}</p>
        <Input labelPosition="right" placeholder="Amount">
          <Label basic>$</Label>
          <Input
            type="number"
            min="0"
            value={rechargeAmount}
            onChange={this.updateFieldEvent('rechargeAmount')}
          />
          <Label>.00</Label>
        </Input>
        <Button
          primary
          disabled={rechargeAmount <= 0 || loading}
          onClick={this.onRecharge}
          loading={loading}
          style={{ marginLeft: 10 }}
        >
          Add Funds
        </Button>
        <Segment>
          Credit card not required at this time as funds are not backed by
          actual currency.
        </Segment>
        <section>
          <h2>Purchases</h2>
          <ArticleList articles={account.purchases} layout="SQUARE" />
        </section>
        <h2>Transactions</h2>
        <List divided relaxed>
          {account.transactions.map((p, i) => {
            return (
              <List.Item key={i}>
                <Image src={p.article.image} size="tiny" />
                <List.Content>
                  <List.Header>{p.article.title}</List.Header>
                  <List.Description>
                    <p>{p.seller === currentUser.id ? `Sold` : `Purchased`}</p>
                    <p>${p.price} on {new Date(p.createdAt).toDateString()}</p>
                    {p.seller === currentUser.id && (
                      <Button
                        size="tiny"
                        onClick={() => {
                          this.refund(p);
                        }}
                        style={{ marginLeft: '10px' }}
                        disabled={p.refunded}
                      >
                        {!p.refunded ? `Refund` : `Refunded`}
                      </Button>
                    )}
                    {p.buyer === currentUser.id && (
                      <Button secondary size="mini">Contact Seller</Button>
                    )}
                  </List.Description>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
        <p>Total sold balance: ${account.balanceSold}</p>
        <Button secondary onClick={this.requestPayout} size="small">
          Request Payout
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
