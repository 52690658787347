import React, { Component } from 'react';
import { connect } from 'react-redux';
import superagent from 'superagent';
import DropZone from 'react-dropzone';
import { Input, Checkbox, Loader, List, Image, Dropdown, Button, Segment } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import PlacesWithStandaloneSearchBox from './maps/PlacesWithStandaloneSearchBox';

import { history } from '../store';
import { fonts } from '../constants/fonts';
import '../lib/Datetime/_Datetime.scss';
import agent from '../agent';
import { API_ROOT } from '../constants/paths';
import ListErrors from './ListErrors';
import {
  ADD_TAG,
  ADD_USER,
  ADD_USERS,
  ADD_VIEWERS,
  EDITOR_PAGE_LOADED,
  REMOVE_TAG,
  REMOVE_USER,
  REMOVE_VIEWER,
  ARTICLE_SUBMITTED,
  EDITOR_PAGE_UNLOADED,
  UPDATE_FIELD_EDITOR,
  USERS_LOADED,
  SET_VIEW_MODE,
  UPDATE_TEMPLATE_SLIDES,
  ENABLE_SLIDE_COMMENTS,
  DISABLE_SLIDE_COMMENTS,
  ENABLE_SLIDE_QUESTIONS,
  DISABLE_SLIDE_QUESTIONS,
} from '../constants/actionTypes';
import { ensureImageFileOrientation, titleToPath, unitTimeOptions } from '../utils/helperFunctions';
import { EXCLUDED_SLUG_CHARS } from '../constants/strings';
import { ExpirationAction, DateTimeExpiration, ExpireBy, Expiration, DurationExpiration, DurationUnit } from '../models/ExpirationSettings';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import GideImage from '../../src/components/Shared/Image/GideImage';

let dropZoneStyles = {
  width: '100%',
  height: '70px',
  backgroundColor: '#efefef',
  cursor: 'pointer',
  padding: '15px',
};

const mapStateToProps = state => ({
  ...state.editor,
  users: state.common.users,
  currentUser: state.common.currentUser,
  article: state.article.article,
});

const mapDispatchToProps = dispatch => ({
  onAddTag: () => dispatch({ type: ADD_TAG }),
  onAddUser: () => dispatch({ type: ADD_USER }),
  onAddUsers: users => dispatch({ type: ADD_USERS, users }),
  onAddViewers: users => dispatch({ type: ADD_VIEWERS, users }),
  onLoad: payload => dispatch({ type: EDITOR_PAGE_LOADED, payload }),
  onRemoveTag: tag => dispatch({ type: REMOVE_TAG, payload: {tag} }),
  onRemoveUser: user => dispatch({ type: REMOVE_USER, user }),
  onRemoveViewer: user => dispatch({ type: REMOVE_VIEWER, user }),
  onSubmit: payload => {
    dispatch({ type: ARTICLE_SUBMITTED, payload });
  },
  onUnload: payload => dispatch({ type: EDITOR_PAGE_UNLOADED }),
  onUpdateField: (key, value) => dispatch({ type: UPDATE_FIELD_EDITOR, payload: {key, value}}),
  onUsersLoaded: payload => dispatch({ type: USERS_LOADED, payload }),
  onSetViewMode: mode => dispatch({ type: SET_VIEW_MODE, mode }),
  updateTemplateSlides: payload => dispatch({ type: UPDATE_TEMPLATE_SLIDES, payload }),
  enableSlideComments: updateAllSlides => dispatch({ type: ENABLE_SLIDE_COMMENTS, payload: { updateAllSlides } }),
  disableSlideComments: updateAllSlides => dispatch({ type: DISABLE_SLIDE_COMMENTS, payload: { updateAllSlides } }),
  enableSlideQuestions: updateAllSlides => dispatch({ type: ENABLE_SLIDE_QUESTIONS, payload: { updateAllSlides } }),
  disableSlideQuestions: updateAllSlides => dispatch({ type: DISABLE_SLIDE_QUESTIONS, payload: { updateAllSlides } }),
});

const priceTypeOptions = [{ key: 'FIXED', value: 'FIXED', text: 'Fixed' }, { key: 'DONATE', value: 'DONATE', text: 'Donate' }];

const purchaseTypeOptions = [
  { key: 'OWN', value: 'OWN', text: 'Own' },
  { key: 'RENT', value: 'RENT', text: 'Rent' },
  { key: 'SUBSCRIBE', value: 'SUBSCRIBE', text: 'Subscribe' },
];

const subscribeTypeOptions = [
  { key: 'DAILY', value: 'DAILY', text: 'Daily' },
  { key: 'WEEKLY', value: 'WEEKLY', text: 'Weekly' },
  { key: 'MONTHLY', value: 'MONTHLY', text: 'Monthly' },
  { key: 'YEARLY', value: 'YEARLY', text: 'Yearly' },
];

const typeOptions = [
  { key: 'NORMAL', value: 'NORMAL', text: 'Gide' },
  { key: 'POST', value: 'POST', text: 'GidePost' },
  { key: 'CHAT', value: 'CHAT', text: 'GideChat' },
  { key: 'TEMPLATE', value: 'TEMPLATE', text: 'GideTemplate' },
];

const viewOptions = [
  { key: 'SWIPE', value: 'SWIPE', text: 'Swipe Mode' },
  { key: 'SCROLL', value: 'SCROLL', text: 'Scroll Mode' },
  { key: 'SLIDE', value: 'SLIDE', text: 'Slide Mode' },
];

let userOptions = [];
let repeatOptions = [
  {
    text: 'Never',
    value: 'NEVER',
  },
  {
    text: 'Daily',
    value: 'DAILY',
  },
  {
    text: 'Weekly',
    value: 'WEEKLY',
  },
  {
    text: 'Monthly',
    value: 'MONTHLY',
  },
  {
    text: 'Yearly',
    value: 'YEARLY',
  },
];

const langOptions = [
  { key: 'en', value: 'en', flag: 'us', text: 'English' },
  { key: 'es', value: 'es', flag: 'es', text: 'Spanish' },
  { key: 'fr', value: 'fr', flag: 'fr', text: 'French' },
  { key: 'it', value: 'it', flag: 'it', text: 'Italian' },
];

class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
    const updateFieldEvent = key => ev => this.props.onUpdateField(key, ev.target.value);
    this.changeTitle = updateFieldEvent('title');
    this.changeDescription = updateFieldEvent('description');
    this.changeBackgroundColor = updateFieldEvent('backgroundColor');
    this.changeTagInput = updateFieldEvent('tagInput');
    this.changeUserInput = updateFieldEvent('userInput');
    this.changeSlideInput = updateFieldEvent('slideInput');
    this.changePrice = updateFieldEvent('price');
    this.handleUserSearchChanged = this._handleUserSearchChanged.bind(this);
    this.handleUserSearchKey = this._handleUserSearchKey.bind(this);

    this.handleSelectedUser = this._handleSelectedUser.bind(this);
    this.handleAddUsers = this._handleAddUsers.bind(this);

    this.handleSelectedViewer = this._handleSelectedViewer.bind(this);
    this.handleAddViewers = this._handleAddViewers.bind(this);
    this.handleChangedChannel = this._handleChangedChannel.bind(this);
    this.handlePlacesChanged = this._handlePlacesChanged.bind(this);
    this.handleChangedFont = this._handleChangedFont.bind(this);

    this.pathFromTitle = () => {
      const val = titleToPath(this.props.title);
      this.props.onUpdateField('slug', val);
    };

    this.changeSlug = ev => {
      let val = ev.target.value;
      let passed = true;
      EXCLUDED_SLUG_CHARS.forEach(c => {
        if (val.indexOf(c) >= 0) {
          passed = false;
          alert('That is not allowed in the url path.');
        }
      });
      if (!passed) return;
      val = val.toLowerCase();
      this.props.onUpdateField('slug', val);
    };

    this.changeDefaultView = (ev, data) => {
      this.props.onUpdateField('defaultView', data.value);
    };

    this.changeType = (ev, data) => {
      this.props.onUpdateField('type', data.value);
    };

    this.changeDate = moment => {
      let date = '';
      if (moment) {
        date = moment.toDate();
      }
      this.props.onUpdateField('date', date);
    };

    this.changeAllowComments = (ev, data) => {
      this.props.onUpdateField('allowComments', data.checked);
    };

    this.changeAllowSlideComments = async (ev, data) => {
      if (this.props.articleSlug && data.checked && !this.props.allowSlideComments) {
        if (window.confirm('Do you want to enable comments for all existing slides?')) {
          await agent.Articles.updateCommentSlides(this.props.article, true);
          this.props.enableSlideComments(true);
        } else {
          this.props.enableSlideComments(false);
        }
      }
      if (this.props.articleSlug && !data.checked && this.props.allowSlideComments) {
        if (window.confirm('Do you want to disable comments for all existing slides?')) {
          await agent.Articles.updateCommentSlides(this.props.article, false);
          this.props.disableSlideComments(true);
        } else {
          this.props.disableSlideComments(false);
        }
      }
      this.props.onUpdateField('allowSlideComments', data.checked);
    };

    this.changeAllowSlideQuestions = async (ev, data) => {
      if (this.props.articleSlug && !data.checked && this.props.allowSlideQuestions) {
        await agent.Articles.updateQuestionSlides(this.props.article, false);
        this.props.disableSlideQuestions(true);
      }
      if (this.props.articleSlug && data.checked && !this.props.allowSlideQuestions) {
        if (window.confirm('Do you want to enable questions for all existing slides?')) {
          await agent.Articles.updateQuestionSlides(this.props.article, true);
          this.props.enableSlideQuestions(true);
        } else {
          this.props.enableSlideQuestions(false);
        }
      }
      this.props.onUpdateField('allowSlideQuestions', data.checked);
    };

    this.updateStateCheckbox = field => (ev, data) => {
      this.props.onUpdateField(field, data.checked);
    };

    this.watchForEnter = ev => {
      if (ev.keyCode === 13) {
        ev.preventDefault();
        this.props.onAddTag();
      }
    };

    this.removeTagHandler = tag => () => {
      this.props.onRemoveTag(tag);
    };

    this.removeUserHandler = user => () => {
      this.props.onRemoveUser(user);
    };

    this.removeViewerHandler = user => () => {
      this.props.onRemoveViewer(user);
    };

    this.onCancel = () => {
      if (this.props.onCancel) {
        this.props.onCancel();
      } else {
        history.push('/');
      }
    };

    this.submitForm = async ev => {
      ev.preventDefault();
      const article = {
        id: this.props.id,
        slug: this.props.slug,
        type: this.props.type,
        image: this.props.image,
        title: this.props.title,
        description: this.props.description,
        tagList: this.props.tagList,
        userList: this.props.userList.map(u => u._id),
        viewerList: this.props.viewerList.map(u => u._id),
        slideList: this.props.slideList,
        allowComments: this.props.allowComments,
        date: this.props.date,
        allowScrollView: this.props.allowScrollView,
        allowSwipeView: this.props.allowSwipeView,
        allowSlideView: this.props.allowSlideView,
        defaultView: this.props.defaultView,
        useAttending: this.props.useAttending,
        showAttending: this.props.showAttending,
        allowLink: this.props.allowLink,
        allowEmbed: this.props.allowEmbed,
        requireAttribution: this.props.requireAttribution,
        backgroundColor: this.props.backgroundColor,
        font: this.props.font,
        headersCollapsed: this.props.headersCollapsed,
        price: this.props.price,
        priceType: this.props.priceType,
        purchaseType: this.props.purchaseType,
        subscribeType: this.props.subscribeType,
        allowBlame: this.props.allowBlame,
        allowSlideComments: this.props.allowSlideComments,
        allowSlideQuestions: this.props.allowSlideQuestions,
        pauseSlideComments: this.props.pauseSlideComments,
        pauseSlideQuestions: this.props.pauseSlideQuestions,
        requireApprovalForInquiries: this.props.requireApprovalForInquiries,
        language: this.props.language,
        expirationSettings: this.props.expirationSettings,
      };
      if (article.title.indexOf('#') >= 0) {
        return alert('Sorry, title cannot contain these characters: #');
      }
      if (
        this.props.articleSlug &&
        article.type === 'TEMPLATE' &&
        window.confirm('Do you want to update all of the Gides slides to Template slides?')
      ) {
        await agent.Articles.updateTemplateSlides(article, true);
        this.props.updateTemplateSlides({ article, isTemplate: true });
      }
      // if (
      //   this.props.articleSlug &&
      //   !article.allowSlideComments &&
      //   this.props.allowSlideComments &&
      //   window.confirm(
      //     'Do you want to disable comments for all existing slides?',
      //   )
      // ) {
      //   await agent.Articles.updateCommentSlides(article, false);
      // }
      // if (
      //   this.props.articleSlug &&
      //   article.allowSlideComments &&
      //   !this.props.allowSlideComments &&
      //   window.confirm(
      //     'Do you want to enable comments for all existing slides?',
      //   )
      // ) {
      //   await agent.Articles.updateCommentSlides(article, true);
      // }
      // if (
      //   this.props.articleSlug &&
      //   !article.allowSlideQuestions &&
      //   this.props.allowSlideQuestions
      // ) {
      //   await agent.Articles.updateQuestionSlides(article, false);
      // }
      // if (
      //   this.props.articleSlug &&
      //   article.allowSlideQuestions &&
      //   !this.props.allowSlideQuestions &&
      //   window.confirm(
      //     'Do you want to enable questions for all existing slides?',
      //   )
      // ) {
      //   await agent.Articles.updateQuestionSlides(article, true);
      // }
      const promise = this.props.articleSlug ? agent.Articles.update(article) : agent.Articles.create(article);
      this.props.onSubmit(promise);
    };

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1300);
  }

  _handlePlacesChanged(places) {
    if (!places.length) return;
    // const place = places[0];
    // this.setState({
    //   lat: place.geometry.location.lat(),
    //   lng: place.geometry.location.lng(),
    // });
    // this._map.panTo({
    //   lat:place.geometry.location.lat(),
    //   lng:place.geometry.location.lng()
    // });
    // this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
    //   {lat:place.geometry.location.lat(),
    //     lng:place.geometry.location.lng()
    //   })
    // // this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setOptions({
    // //   zoom: 1
    // // })
  }

  _handleChangedFont(ev, data) {
    this.props.onUpdateField('font', data.value);
  }

  _handleChangedChannel(ev, data) {
    this.props.onUpdateField('channel', data.value);
  }

  _handleSelectedUser(ev, data) {
    this.props.onUpdateField('usersSelected', data.value);
  }

  _handleSelectedViewer(ev, data) {
    this.props.onUpdateField('viewersSelected', data.value);
  }

  _handleUserSearchChanged(ev) {
    ev.preventDefault();
    this.props.onUpdateField('userInput', ev.target.value);
  }

  _handleUserSearchKey(ev) {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      this.props.onAddUser();
    }
  }

  _handleAddUsers(ev) {
    ev.preventDefault();
    let users = [];
    this.props.usersSelected.forEach(id => {
      let u = this.props.users.find(user => user._id === id);
      users.push(u);
    });
    this.props.onAddUsers(users);
  }

  _handleAddViewers(ev) {
    ev.preventDefault();
    let users = [];
    this.props.viewersSelected.forEach(id => {
      let u = this.props.users.find(user => user._id === id);
      users.push(u);
    });
    this.props.onAddViewers(users);
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length) {
      ensureImageFileOrientation(acceptedFiles[0]).then(imageFile => {
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', imageFile)
          .end((err, res) => {
            if (err) console.log(err);
            if (res.body && res.body.url) {
              const url = res.body.url;
              this.props.onUpdateField('image', url);
            }
          });
      });
      return;
    }
  }
  componentWillReceiveProps(nextProps) {
    // if (this.props.match.params.slug !== nextProps.match.params.slug) {
    //   if (nextProps.match.params.slug) {
    //     this.props.onUnload();
    //     return this.props.onLoad(agent.Articles.get(this.props.match.params.slug));
    //   }
    //   this.props.onLoad(null);
    // }
  }

  componentWillMount() {
    this.props.onUsersLoaded(agent.Users.getAll());
    if (this.props.match && this.props.match.params.id) {
      return this.props.onLoad(agent.Articles.get(this.props.match.params.id));
    } else if (this.props.article) {
      return this.props.onLoad(agent.Articles.get(this.props.article.id));
    }
    this.props.onLoad(null);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const { users, articleSlug, currentUser } = this.props;
    if (users) {
      userOptions = users
        .filter(user => {
          return user.username !== this.props.currentUser.username;
        })
        .map(user => {
          return {
            text: user.username,
            value: user._id,
            image: {
              avatar: true,
              src: user.image,
            },
          };
        });
    }
    const { loading } = this.state;
    if (loading) {
      return <Loader active inline="centered" />;
    }
    return (
      <div className="editor-page">
        <div className="container page">
          <div className="row">
            <div className="col-xs-12">
              <ListErrors errors={this.props.errors} />
              <form>
                <fieldset>
                  <button
                    className="btn btn-lg pull-xs-right btn-primary"
                    type="button"
                    disabled={this.props.inProgress}
                    onClick={this.submitForm}
                  >
                    {articleSlug ? 'Save' : 'Create'}
                  </button>
                  <span onClick={this.onCancel} className="link underlined">
                    Cancel
                  </span>
                  <fieldset className="form-group">
                    <label>Title</label>
                    <Input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="required"
                      value={this.props.title || ''}
                      onChange={this.changeTitle}
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Url path (only lowercase letters and dashes - allowed. No special characters or spaces.)</label>

                    <Input
                      label={`gides.com/${currentUser.username}/`}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="required"
                      value={this.props.slug || ''}
                      onChange={this.changeSlug}
                    />
                    <p>
                      <span className="link underlined" onClick={this.pathFromTitle}>
                        Auto set from title
                      </span>
                    </p>
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Image</label>
                    {this.props.image && <Image src={this.props.image} className="coverImage" alt={`□`} />}
                    <aside>
                      <div className="dropzone">
                        <DropZone accept="image/jpeg, image/png" onDrop={this.onDrop.bind(this)} style={dropZoneStyles} multiple={false}>
                          <p>Drop file or select.</p>
                        </DropZone>
                      </div>
                    </aside>
                  </fieldset>
                  <label>Language</label>
                  <fieldset>
                    <Dropdown
                      placeholder="Select Language"
                      fluid
                      search
                      selection
                      options={langOptions}
                      value={this.props.language}
                      onChange={(ev, data) => {
                        this.props.onUpdateField('language', data.value);
                      }}
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label>{this.props.priceType === 'FIXED' ? `Price` : `Suggested Donation`} (in US dollars)</label>
                    <Input
                      className="form-control form-control-lg"
                      type="number"
                      min="0"
                      placeholder="optional"
                      value={this.props.price || ''}
                      onChange={this.changePrice}
                    />
                  </fieldset>
                  {this.props.price >= 0.01 && (
                    <fieldset>
                      <fieldset>
                        <label>Price Type</label>
                        <div>
                          <Dropdown
                            selection
                            text={this.props.priceType}
                            options={priceTypeOptions}
                            value={this.props.priceType}
                            onChange={(evt, data) => {
                              this.props.onUpdateField('priceType', data.value);
                            }}
                          />
                        </div>
                      </fieldset>
                      <fieldset>
                        <label>Purchase Type</label>
                        <div>
                          <Dropdown
                            selection
                            text={this.props.purchaseType}
                            options={purchaseTypeOptions}
                            value={this.props.purchaseType}
                            onChange={(evt, data) => {
                              this.props.onUpdateField('purchaseType', data.value);
                            }}
                          />
                        </div>
                      </fieldset>
                      {this.props.purchaseType === 'SUBSCRIBE' && (
                        <fieldset>
                          <label>Subscription Length</label>
                          <div>
                            <Dropdown
                              selection
                              text={this.props.subscribeType}
                              options={subscribeTypeOptions}
                              value={this.props.subscribeType}
                              onChange={(evt, data) => {
                                this.props.onUpdateField('subscribeType', data.value);
                              }}
                            />
                          </div>
                        </fieldset>
                      )}
                    </fieldset>
                  )}

                  <Segment>Gides with price aren't able to be bought when viewing a Website channel.</Segment>
                  <fieldset className="form-group">
                    <label>Type: </label>
                    <div>
                      <Dropdown options={typeOptions} value={this.props.type} onChange={this.changeType} />
                    </div>
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateStateCheckbox('allowBlame')}
                      toggle
                      checked={this.props.allowBlame}
                      label="Allow blame mode gives viewers the ability in the ViewBar to see slide author."
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateStateCheckbox('allowScrollView')}
                      toggle
                      checked={this.props.allowScrollView}
                      label="Allow Scroll View"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateStateCheckbox('allowSwipeView')}
                      toggle
                      checked={this.props.allowSwipeView}
                      label="Allow Swipe View"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateStateCheckbox('allowSlideView')}
                      toggle
                      checked={this.props.allowSlideView}
                      label="Allow Slide View"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox onChange={this.updateStateCheckbox('allowLink')} toggle checked={this.props.allowLink} label="Allow Link" />
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateStateCheckbox('allowEmbed')}
                      toggle
                      checked={this.props.allowEmbed}
                      label="Allow Embed"
                    />
                  </fieldset>
                  {this.props.allowEmbed && (
                    <fieldset className="form-group">
                      <Checkbox
                        onChange={this.updateStateCheckbox('requireAttribution')}
                        toggle
                        checked={this.props.requireAttribution}
                        label="Require Attribution"
                      />
                    </fieldset>
                  )}
                  <fieldset className="form-group">
                    <Checkbox
                      onChange={this.updateStateCheckbox('headersCollapsed')}
                      toggle
                      checked={this.props.headersCollapsed}
                      label="Collapse Headers by Default"
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <Checkbox
                      // onChange={this.updateStateCheckbox('allowSlideComments')}
                      onChange={this.changeAllowSlideComments}
                      toggle
                      checked={this.props.allowSlideComments}
                      label="Allow Slide Comments"
                    />
                  </fieldset>
                  {this.props.allowSlideComments && (
                    <fieldset className="form-group">
                      <Checkbox
                        onChange={this.updateStateCheckbox('pauseSlideComments')}
                        toggle
                        checked={this.props.pauseSlideComments}
                        label="Pause Slide Comments"
                      />
                    </fieldset>
                  )}
                  <fieldset className="form-group">
                    <Checkbox
                      // onChange={this.updateStateCheckbox('allowSlideQuestions')}
                      onChange={this.changeAllowSlideQuestions}
                      toggle
                      checked={this.props.allowSlideQuestions}
                      label="Allow Slide Questions"
                    />
                  </fieldset>
                  {this.props.allowSlideQuestions && (
                    <fieldset className="form-group">
                      <Checkbox
                        onChange={this.updateStateCheckbox('pauseSlideQuestions')}
                        toggle
                        checked={this.props.pauseSlideQuestions}
                        label="Pause Slide Questions"
                      />
                    </fieldset>
                  )}
                  {(this.props.allowSlideComments || this.props.allowSlideQuestions) && (
                    <fieldset className="form-group">
                      <Checkbox
                        onChange={this.updateStateCheckbox('requireApprovalForInquiries')}
                        toggle
                        checked={this.props.requireApprovalForInquiries}
                        label="Require Approval for Inquiries (Comments/Questions)"
                      />
                    </fieldset>
                  )}
                  <fieldset>
                    <Checkbox
                      onChange={(event, data) => {
                        const expirationSettings = data.checked
                          ? {
                              action: ExpirationAction.Hide,
                              expireBy: ExpireBy.Global,
                              source: new DateTimeExpiration(new Date()),
                            }
                          : {
                              action: ExpirationAction.Undefined,
                            };
                        this.props.onUpdateField('expirationSettings', expirationSettings);
                      }}
                      toggle
                      checked={
                        this.props.expirationSettings !== undefined && this.props.expirationSettings.action !== ExpirationAction.Undefined
                      }
                      label="Expire Gide"
                    />
                    {this.props.expirationSettings &&
                      this.props.expirationSettings.action !== ExpirationAction.Undefined && (
                        <div className="expireGideSettings">
                          <div className="expireGideOptions">
                            <RadioButtonComponent
                              checked={
                                this.props.expirationSettings.source &&
                                this.props.expirationSettings.source.expiration === Expiration.DateTime
                              }
                              label="Specific Time"
                              name="expireGideType"
                              value={Expiration.DateTime.toString()}
                              change={e => {
                                // When user switches to this selection use these default settings for Specific Time
                                if (e.value === Expiration.DateTime.toString()) {
                                  const expirationSettings = {
                                    action: ExpirationAction.Hide,
                                    expireBy: ExpireBy.Global,
                                    source: new DateTimeExpiration(new Date()),
                                  };

                                  this.props.onUpdateField('expirationSettings', expirationSettings);
                                }
                              }}
                            />
                            <div className="expireGideByDuration">
                              <RadioButtonComponent
                                style={{ marginLeft: '20px' }}
                                checked={
                                  this.props.expirationSettings.source &&
                                  this.props.expirationSettings.source.expiration === Expiration.Duration
                                }
                                label="Duration"
                                name="expireGideType"
                                value={Expiration.Duration.toString()}
                                change={e => {
                                  // When user switches to this selection use these default settings for Duration
                                  if (e.value === Expiration.Duration.toString()) {
                                    const expirationSettings = {
                                      action: ExpirationAction.Hide,
                                      expireBy: ExpireBy.Global,
                                      source: new DurationExpiration(30, DurationUnit.Second),
                                    };

                                    this.props.onUpdateField('expirationSettings', expirationSettings);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="expireGideOptionSettings">
                            {this.props.expirationSettings.source &&
                              this.props.expirationSettings.source.expiration === Expiration.Duration && (
                                <div className="expireGideDurationSettings">
                                  <Input
                                    placeholder="Expiration Duration"
                                    value={this.props.expirationSettings.source.duration}
                                    onChange={e => {
                                      const expirationSettings = {
                                        ...this.props.expirationSettings,
                                        source: {
                                          ...this.props.expirationSettings.source,
                                          duration: e.target.value,
                                        },
                                      };

                                      this.props.onUpdateField('expirationSettings', expirationSettings);
                                    }}
                                    type="number"
                                    min="1"
                                  />
                                  <Dropdown
                                    style={{ marginLeft: '5px' }}
                                    options={unitTimeOptions}
                                    value={this.props.expirationSettings.source.durationUnits}
                                    onChange={(e, data) => {
                                      const expirationSettings = {
                                        ...this.props.expirationSettings,
                                        source: {
                                          ...this.props.expirationSettings.source,
                                          durationUnits: data.value,
                                        },
                                      };

                                      this.props.onUpdateField('expirationSettings', expirationSettings);
                                    }}
                                  />
                                </div>
                              )}
                            {this.props.expirationSettings.source &&
                              this.props.expirationSettings.source.expiration === Expiration.DateTime && (
                                <div className="expireGideTimeSettings">
                                  <div className="expireGideTime">
                                    <div className="expireGideTimeSettingsDate">
                                      <DatePickerComponent
                                        value={this.props.expirationSettings.source.dateTime}
                                        change={e => {
                                          const expirationSettings = {
                                            ...this.props.expirationSettings,
                                            source: {
                                              ...this.props.expirationSettings.source,
                                              dateTime: e.value,
                                            },
                                          };
                                          this.props.onUpdateField('expirationSettings', expirationSettings);
                                        }}
                                      />
                                    </div>
                                    <div className="expireGideTimeSettingsTime">
                                      <TimePickerComponent
                                        value={this.props.expirationSettings.source.dateTime}
                                        change={e => {
                                          const expirationSettings = {
                                            ...this.props.expirationSettings,
                                            source: {
                                              ...this.props.expirationSettings.source,
                                              dateTime: e.value,
                                            },
                                          };
                                          this.props.onUpdateField('expirationSettings', expirationSettings);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            <div className="expirationActionOptions">
                              <RadioButtonComponent
                                checked={this.props.expirationSettings && this.props.expirationSettings.action === ExpirationAction.Hide}
                                label="Hide"
                                name="expireGideAction"
                                value={ExpirationAction.Hide.toString()}
                                change={e => {
                                  // When user switches to this selection use these default settings for Specific Time
                                  if (e.value === ExpirationAction.Hide.toString()) {
                                    const expirationSettings = {
                                      ...this.props.expirationSettings,
                                      action: ExpirationAction.Hide,
                                    };
                                    this.props.onUpdateField('expirationSettings', expirationSettings);
                                  }
                                }}
                              />

                              <RadioButtonComponent
                                checked={this.props.expirationSettings && this.props.expirationSettings.action === ExpirationAction.Bye}
                                label="Bye"
                                name="expireGideAction"
                                value={ExpirationAction.Bye.toString()}
                                change={e => {
                                  // When user switches to this selection use these default settings for Specific Time
                                  if (e.value === ExpirationAction.Bye.toString()) {
                                    const expirationSettings = {
                                      ...this.props.expirationSettings,
                                      action: ExpirationAction.Bye,
                                    };
                                    this.props.onUpdateField('expirationSettings', expirationSettings);
                                  }
                                }}
                              />
                              {/* Only DateTime can currently be "Deleted" (Should put in Trash)  */}
                              {this.props.expirationSettings.source &&
                                this.props.expirationSettings.source.expiration === Expiration.DateTime && (
                                  <RadioButtonComponent
                                    checked={
                                      this.props.expirationSettings && this.props.expirationSettings.action === ExpirationAction.Delete
                                    }
                                    label="Delete"
                                    name="expireGideAction"
                                    value={ExpirationAction.Delete.toString()}
                                    change={e => {
                                      // When user switches to this selection use these default settings for Specific Time
                                      if (e.value === ExpirationAction.Delete.toString()) {
                                        const expirationSettings = {
                                          ...this.props.expirationSettings,
                                          action: ExpirationAction.Delete,
                                        };
                                        this.props.onUpdateField('expirationSettings', expirationSettings);
                                      }
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Open in: </label>
                    <div>
                      <Dropdown options={viewOptions} value={this.props.defaultView} onChange={this.changeDefaultView} />
                    </div>
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Description</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="optional"
                      value={this.props.description || ''}
                      onChange={this.changeDescription}
                    />
                  </fieldset>
                  <fieldset>
                    <h3>Font Family</h3>
                    <div>
                      <p style={{ fontFamily: this.props.font, fontSize: '2em' }}>This is example text.</p>
                      <Dropdown placeholder="Font" options={fonts} value={this.props.font} onChange={this.handleChangedFont} />
                    </div>
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Background Color Hex</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="optional"
                      value={this.props.backgroundColor || ''}
                      onChange={this.changeBackgroundColor}
                    />
                  </fieldset>
                  <fieldset className="form-group">
                    <label>Tags</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Enter tags, separated by spaces and press enter to add (optional)"
                      value={this.props.tagInput || ''}
                      onChange={this.changeTagInput}
                      onKeyDown={this.watchForEnter}
                    />
                    <div className="tag-list">
                      {(this.props.tagList || []).map((tag, i) => {
                        return (
                          <span className="tag-default tag-pill" key={i}>
                            <i className="ion-close-round" onClick={this.removeTagHandler(tag)} />
                            {tag}
                          </span>
                        );
                      })}
                    </div>
                  </fieldset>
                  {/* <fieldset className="form-group">
                    <Checkbox
                      onChange={this.changeAllowComments}
                      toggle
                      checked={this.props.allowComments}
                      label="Allow Gide Comments"
                    />
                  </fieldset> */}
                  <fieldset>
                    <label>Date</label>
                    <br />
                    <label className="light">Set date to make your Gide an EventGide</label>
                    <Datetime
                      className="form-control form-control-lg"
                      inputProps={{ placeholder: 'optional' }}
                      onChange={this.changeDate}
                      value={this.props.date ? new Date(this.props.date) : null}
                    />
                  </fieldset>
                  {this.props.date && (
                    <div>
                      <fieldset>
                        <span>Repeat</span> <Dropdown inline options={repeatOptions} defaultValue={repeatOptions[0].value} />
                      </fieldset>
                      <fieldset className="form-group">
                        <Checkbox
                          toggle
                          onChange={this.updateStateCheckbox('useAttending')}
                          checked={this.props.useAttending}
                          label="Offer Attending Choice"
                        />
                      </fieldset>
                      <fieldset className="form-group">
                        <Checkbox
                          toggle
                          onChange={this.updateStateCheckbox('showAttending')}
                          checked={this.props.showAttending}
                          label="Show Attending to Viewers"
                        />
                      </fieldset>
                      <fieldset>
                        <label>Location</label>
                        <div className="searchBox">
                          <PlacesWithStandaloneSearchBox placesChanged={this.handlePlacesChanged} />
                        </div>
                      </fieldset>
                    </div>
                  )}
                  <fieldset>
                    <h3>Editors</h3>
                    <Dropdown
                      value={this.props.usersSelected || []}
                      placeholder="Select User(s)"
                      search
                      fluid
                      selection
                      multiple
                      closeOnChange
                      options={userOptions}
                      onChange={this.handleSelectedUser}
                    />
                    <Button
                      onClick={this.handleAddUsers}
                      content="Add"
                      icon="add"
                      disabled={!this.props.usersSelected || this.props.usersSelected.length <= 0}
                    />
                    <List relaxed selection divided>
                      {(this.props.userList || []).map((user, i) => {
                        return (
                          <List.Item key={i}>
                            <GideImage 
                              className={`svgIcon small pointer color-secondary-500-svg`}
                              src="/icons/content-alteration/delete.svg"
                              alt={`□`}
                              onClick={this.removeUserHandler(user)}
                            />
                            <Image avatar src={user.image} />
                            <List.Content>
                              <List.Header as="a">{user.username}</List.Header>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </fieldset>
                  <fieldset>
                    <h3>Admins</h3>
                    <Dropdown
                      value={this.props.viewersSelected || []}
                      placeholder="Select User(s)"
                      closeOnChange
                      search
                      fluid
                      selection
                      multiple
                      options={userOptions}
                      onChange={this.handleSelectedViewer}
                    />
                    <Button
                      onClick={this.handleAddViewers}
                      content="Add"
                      icon="add"
                      disabled={!this.props.viewersSelected || this.props.viewersSelected.length <= 0}
                    />
                    <List>
                      {(this.props.viewerList || []).map((user, i) => {
                        return (
                          <List.Item key={i}>
                            <i className="ion-close-round" onClick={this.removeViewerHandler(user)} />
                            <Image avatar src={user.image} />
                            <List.Content>
                              <List.Header as="a">{user.username}</List.Header>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </fieldset>
                  <button
                    className="btn btn-lg pull-xs-right btn-primary"
                    type="button"
                    disabled={this.props.inProgress}
                    onClick={this.submitForm}
                  >
                    {articleSlug ? 'Save' : 'Create'}
                  </button>
                  <span onClick={this.onCancel} className="link underlined">
                    Cancel
                  </span>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
