import React from 'react';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
const createDOMPurify = require('dompurify');

const CollapseSlide = props => {
  const onCollapseSlide = () => {
    props.collapseSlides(props.slide);
  };
  const collapseBeginClasses = classNames(
    'paddedCard',
    'collapseBegin',
    props.collapsed ? 'collapsed' : null,
  );
  return (
    <div>
      {props.slide.data &&
        props.slide.data.level > 0 &&
        props.slide.data.type === 'BEGIN' && (
          <div
            className={collapseBeginClasses}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            {props.slide.data && props.slide.data.body ? (
              <span
                dangerouslySetInnerHTML={{ __html: createDOMPurify.sanitize(props.slide.data.body) }}
              />
            ) : null}
            {props.collapseSlides && (
              <Icon
                onClick={onCollapseSlide}
                circular
                name={props.collapsed ? 'plus' : 'close'}
                color="grey"
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        )}
      {props.slide.data &&
        props.slide.data.level > 0 &&
        props.slide.data.type === 'END' && (
          <div
            className="paddedCard collapseEnd"
            style={{ display: 'flex', flex: 1 }}
          >
            End Collapse Section
          </div>
        )}
    </div>
  );
};

export default CollapseSlide;
