import React from 'react';
import VideoList from '../Viewers/VideoViewers/VideoList/VideoList';
import { VideoViewerMode } from '../../models/CommonEnums';
import VideoSwipe from '../Viewers/VideoViewers/VideoSwipe/VideoSwipe';


const VideoSlide = ({ slide, fileNum, viewMode }) => {
  if (viewMode === 'SWIPE') {
    const video = slide.data.files[fileNum || 0];
    return (
      <div>
        {video &&
          (video.type === 'UPLOAD' || video.type === 'RECORDED') && (
            <video controls className="video">
              <source src={video.url} />
            </video>
          )}
        {video &&
          video.type === 'URL' && (
            <iframe
              title={video.url}
              src={video.url}
              frameBorder="0"
              allowFullScreen
              className="video"
            />
          )}
      </div>
    );
  } else {
    return (
      <div>
        {slide.data.files && slide.data.slideTypeSpecificSettings &&
          slide.data.slideTypeSpecificSettings.displayType === VideoViewerMode.Stacked && (
          <VideoList 
            videoList={slide.data.files}
          />
          // <List>
          //   {/* {slide.data.files.map(({ type, url, name }, i) => ( */}
          //   {slide.data.files.map((videoFile, i) => (
          //     <List.Item key={i}>
          //       {/* {(type === 'UPLOAD' || type === 'RECORDED') && (
          //         <video controls className="video">
          //           <source src={url} />
          //         </video>
          //       )}
          //       {type === 'URL' && (
          //         <div>
          //           <iframe
          //             title={url}
          //             src={url}
          //             frameBorder="0"
          //             allowFullScreen
          //             className="video"
          //           />
          //         </div>
          //       )} */}
          //       <VideoView
          //         videoFile={videoFile}
          //       />
          //     </List.Item>
          //   ))}
          // </List>
        )}
        {slide.data.files && slide.data.slideTypeSpecificSettings &&
          slide.data.slideTypeSpecificSettings.displayType === VideoViewerMode.Gallery && (
          <VideoSwipe
            videoList={slide.data.files}
          />
        )}
      </div>
    );
  }
};

export default VideoSlide;
