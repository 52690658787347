import React from 'react';
import {
  SlideEditorModalProps,
  BackIconMode,
  EditorState,
  ModalOverlayState,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { lightTheme } from '../../../themes/custom-theme';
import styles from './_Common.module.scss';
import SlideDimensionsPicker from '../../DataEntry/Pickers/Modals/SlideDimensionsPicker/SlideDimensionsPicker';
import {
  CommonContext,
  CommonDerivedState,
  getHeaderTitleAndIcon,
  getSlideView,
  CommonModes,
} from './Common';
import { getCreateActions, getPreviewItems } from './SharedLogic';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isImageSlide, isVideoSlide } = context;

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: false,
    hideCommandBar: false,
    editorState: EditorState.Preview,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: false,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: getCreateActions(context),
    headerActions: getHeaderTitleAndIcon('Slide dimensions', '/icons/nav/display/normal.svg', 'black'),
    onNavigateBack: () => {
      const mode = state.selectedItemIndex > -1 ? CommonModes.Edit : CommonModes.Preview;
      setState({ ...state, mode });
    },
    previewItems: getPreviewItems(-1, context),
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { state, setState } = context;
  const slideView = getSlideView(state.slide);
  const backMode = state.selectedItemIndex > -1 ? CommonModes.Edit : CommonModes.Preview;

  const onSave = (slideWidthType: string, slideAlignment: string, slideWidthPercentage: number) => {
    setState({ ...state, mode: backMode });
  };

  const onCancel = (slideWidthType: string, slideAlignment: string, slideWidthPercentage: number) => {
    setState({
      ...state,
      mode: backMode,
      slide: {
        ...state.slide,
        width: slideWidthType,
        contentHorizontalAlignment: slideAlignment,
        widthPercentage: slideWidthPercentage,
      },
    });
  };

  const onChange = (slideWidthType: string, slideAlignment: string, slideWidthPercentage: number) => {
    setState({
      ...state,
      mode: state.mode,
      slide: {
        ...state.slide,
        width: slideWidthType,
        contentHorizontalAlignment: slideAlignment,
        widthPercentage: slideWidthPercentage,
      },
    });
  };

  const onUnhandled = (slideWidthType: string, slideAlignment: string, slideWidthPercentage: number) => {
    setState((state) => ({
      ...state,
      slide: {
        ...state.slide,
        width: slideWidthType,
        contentHorizontalAlignment: slideAlignment,
        widthPercentage: slideWidthPercentage,
      },
    }));
  };

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <SlideDimensionsPicker
          className={styles.dimensionsModal}
          onSave={onSave}
          onCancel={onCancel}
          onChange={onChange}
          onUnhandled={onUnhandled}
          slideWidthType={state.slide.width || 'NORMAL'}
          slideAlignment={state.slide.contentHorizontalAlignment || 'CENTER'}
          slideWidthPercentage={state.slide.widthPercentage || 100}
        />
      </div>
      {slideView}
    </div>
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: lightTheme,
  };
};

export const Dimensions = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
