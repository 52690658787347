import React from 'react';
import GideImage from '../Shared/Image/GideImage';

const Banner = ({ appName, token }) => {
  if (token) {
    return null;
  }
  return (
    <div className="banner">
      <div className="container">
        <GideImage src="/icons/nav/logo/logo-type.svg" className="logo centered" alt={`□`}/>
        <p>A place to share your knowledge.</p>
      </div>
    </div>
  );
};

export default Banner;
