import React, { useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { Slide } from '../../../models/Slide';
import { ImageFile } from '../../../models/SlideFile';
import { v4 } from 'uuid';
import { LinkViewerMode } from '../../../models/CommonEnums';
import { LinkMode } from '../Modes';
import { useSlideEditorContext } from '../useSlideEditorContext';
import { CommonContext } from '../Modes/Common';
import { LinkSlideEditorProps, Context, DerivedState } from '../Modes/SharedLogicForLinkSlideEditor';
import SlideEditorModal from '../../modals/SlideEditorModal/SlideEditorModal';


export const getSlide = (props: LinkSlideEditorProps): Slide => {
  const slide = props.editSlide ? {
    ...props.editSlide,
    data: {
      ...props.editSlide.data,
      // This will handle the old method of keeping track of the image display type.
      slideTypeSpecificSettings: {
        ...props.editSlide.data.slideTypeSpecificSettings,
        displayType: props.editSlide.data.slideTypeSpecificSettings &&
          props.editSlide.data.slideTypeSpecificSettings.displayType ?
          props.editSlide.data.slideTypeSpecificSettings.displayType :
          props.editSlide.data.displayInCarousel ?
          LinkViewerMode.Carousel : LinkViewerMode.Stacked,
      },
      files: props.editSlide.data.files.map((f: ImageFile) => {
        return {
          ...f,
          id: f.id ? f.id : v4(),
        }
      })
    },
  } : {
    id: '',
    author: {
      id: '',
      image: props.currentUser.image,
      username: props.currentUser.username,
      connections: [],
      favorites: [],
      views: [],
      following: false,
      verified: false,
    },
    slideType: 'LINKS',
    data: {
      audioCaption: null,
      files: [],
      caption: '',
      slideTypeSpecificSettings: {
        displayType: LinkViewerMode.Stacked,
      },
    },
    position: props.position,
    useNativeResolution: false,
    width: "NORMAL",
    widthPercentage: undefined,
  };
  return slide;
};

const getDerivedState = (context: Context): DerivedState => {
  if (LinkMode[context.state.mode]) {
    var derivedState = LinkMode[context.state.mode].getDerivedState(context as CommonContext);
    return derivedState as DerivedState;
  } else {
    throw new Error(`Unknown mode: ${context.state.mode}`);
  }
};

export const LinkSlideEditor: React.FC<LinkSlideEditorProps> = (props) => {
  const getSlideCallback = useCallback(() => getSlide(props), [props]);
  const context = useSlideEditorContext(props, getSlideCallback);
  const derivedState = getDerivedState(context as Context);

  return (
    <ThemeProvider theme={derivedState.theme}>
      <SlideEditorModal {...derivedState.slideEditorModalProps}>
        {derivedState.content}
      </SlideEditorModal>
    </ThemeProvider>
  );
}

export default LinkSlideEditor;
