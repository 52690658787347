import React, { Component } from 'react';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { API_ROOT } from '../../../constants/paths';
import superagent from 'superagent';
import { AudioFile } from '../../modals/SlideEditorModal/SlideEditorModal';
import DropZone from 'react-dropzone';
import { v4 } from 'uuid';
import classNames from 'classnames';
import { TertiaryButton } from '../../Shared/Buttons/TertiaryButton/TertiaryButton';
import icons from '../../../assets/icons';
import { calculateDurationInSecondsFromCurrentTime, formatMinuteAndSeconds } from '../../../utils/helperFunctions';

enum AudioCaptionRecordingState {
  Waiting = 1,
  Recording = 2,
  Playing = 3,
  Stopped = 4
}
export interface AudioRecorderProps {
  closeAudioRecording: () => void;
  saveAudioRecording: (audioFile: AudioFile) => void;
  onDeleteAudioRecording: (audioFile: AudioFile) => void;
  audioFile?: AudioFile;
  displayAutoPlayControls: boolean;
  iconCssClass?: string;
  color?: string;
  theme?: 'light' | 'dark';
}

export interface AudioRecorderState {
  audioCaptionRecordingState: AudioCaptionRecordingState;
  recorder?: { start: () => void, stop: () => Promise<any> };
  mediaRecorder?: MediaRecorder | null;
  audio?: any;
  autoPlay: boolean;
  autoAdvance: boolean;
  audioFile?: AudioFile;
  audioRecordingStartTime?: Date;
  audioRecordingDuration?: number;
  remainigPlayDuration: number;
}

export default class AudioRecorder extends Component<AudioRecorderProps, AudioRecorderState> {
  constructor(props: AudioRecorderProps) {
    super(props);

    this.state = {
      audioCaptionRecordingState: props.audioFile ? AudioCaptionRecordingState.Stopped : AudioCaptionRecordingState.Waiting,
      audioFile: props.audioFile ? props.audioFile : undefined,
      autoPlay: props.audioFile && props.audioFile.autoPlay === undefined ? props.audioFile.autoPlay : false,
      autoAdvance: props.audioFile && props.audioFile.autoAdvance === undefined ? props.audioFile.autoAdvance : false,
      remainigPlayDuration: props.audioFile && props.audioFile.duration ? props.audioFile.duration : 0
    }
  }
  private async setAudioRecordingState() {
    if (this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Waiting) {
      this.setState({ audioCaptionRecordingState: AudioCaptionRecordingState.Recording, audioRecordingDuration: 0, audioRecordingStartTime: new Date() });
      this.startRecordingAudio();
    } else if (this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Recording) {
      await this.stopRecordingAudio();
      this.setState({
        audioCaptionRecordingState: AudioCaptionRecordingState.Stopped,
        remainigPlayDuration: this.state.audioRecordingDuration ? this.state.audioRecordingDuration : 0,
        audioRecordingDuration: undefined, audioRecordingStartTime: undefined 
      });      
    } else if (this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Playing) {
      this.stopPlayingAudio();
      this.setState({ audioCaptionRecordingState: AudioCaptionRecordingState.Stopped, audioRecordingDuration: undefined, audioRecordingStartTime: undefined });
    } else if (this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Stopped) {
      this.playAudio();
      this.setState({ audioCaptionRecordingState: AudioCaptionRecordingState.Playing, audioRecordingDuration: undefined, audioRecordingStartTime: undefined });
    }
  }
  playAudio() {
    if (this.audioPlayer && this.state.audioFile) {
      if(!this.audioPlayer.src || this.state.audioCaptionRecordingState !== AudioCaptionRecordingState.Stopped) {
        this.audioPlayer.src = this.state.audioFile.url;
        this.audioPlayer.onended = () => {
          this.setState({ audioCaptionRecordingState: AudioCaptionRecordingState.Stopped, remainigPlayDuration: this.audioPlayer ? this.audioPlayer.duration : 0 });
        }
      }
      this.audioPlayer.play();
    }
  }
  stopPlayingAudio() {
    if (this.audioPlayer) {
      this.audioPlayer.pause();
    }
  }

  async stopRecordingAudio() {
    const duration = this.state.audioRecordingDuration;
    if (!this.state.mediaRecorder || !this.state.recorder) return;

    if (this.state.mediaRecorder.state === 'inactive') {
      alert('Not recording.');
      return;
    }
    if (this.state.mediaRecorder.state === 'recording') {
      const audio: any = await this.state.recorder.stop();
      this.setState({ audio });
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', audio.audioBlob, 'foo.m4a')
        .end((err, res) => {
          if (err) {
            return alert('Please try again.');
          }
          const audioFile = {
            name: 'recorded audio',
            url: res.body.url,
            duration: duration,
            autoPlay: false,
            autoAdvance: false,
          };
          // this.addFile(f);
          this.setState({ audioFile: audioFile });
        });
    }
    let tracks = this.state.mediaRecorder.stream.getTracks();
    tracks.forEach((t: any) => {
      t.stop();
    });
    this.setState({ mediaRecorder: null });
  }

  async startRecordingAudio() {
    const recorder: { start: () => void, stop: () => Promise<any> } = await this.recordAudio();
    recorder.start();
    this.setState({ recorder });
  }

  recordAudio(): Promise<{ start: () => void, stop: () => Promise<any> }> {
    return new Promise(resolve => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream: MediaStream) => {
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'video/webm',
        });
        const audioChunks: BlobPart[] = [];

        mediaRecorder.addEventListener('dataavailable', (event: any) => {
          audioChunks.push(event.data);
        });

        this.setState({ mediaRecorder });

        const start = () => {
          mediaRecorder.start();
        };

        const stop = () => {
          return new Promise(resolve => {
            mediaRecorder.addEventListener('stop', () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              const play = () => {
                audio.addEventListener('ended', () => {
                  this.setState({ audioCaptionRecordingState: AudioCaptionRecordingState.Stopped })
                });
                audio.play();
              };

              resolve({ audioBlob, audioUrl, play });
            });

            mediaRecorder.stop();
          });
        };

        resolve({ start, stop });
      });
    });
  }

  onDrop(acceptedFiles: any[], rejectedFiles: any[]) {
    acceptedFiles.forEach(file => {
      superagent
        .post(`${API_ROOT}/util/upload`)
        .attach('theseNamesMustMatch', file)
        .end((err, res) => {
          if (err) console.log(err);
          const audioFile = {
            name: file.name,
            url: res.body.url,
            autoPlay: false,
            autoAdvance: false,
          };
          this.setState({ audioFile: audioFile });
        });
    });
  }
  audioPlayer?: HTMLAudioElement | undefined;
  componentDidUpdate(prevProps: AudioRecorderProps, prevState: AudioRecorderState) {
    if (this.props.audioFile !== prevProps.audioFile) {
      if (this.props.audioFile) {
        this.setState({
          audioFile: {...this.props.audioFile},
          autoPlay: this.props.audioFile.autoPlay,
          autoAdvance: this.props.audioFile.autoAdvance,
          audioCaptionRecordingState: AudioCaptionRecordingState.Stopped,
        });
      } else {
        this.setState({
          audioFile: undefined,
          autoPlay: false,
          autoAdvance: false,
          audioCaptionRecordingState: AudioCaptionRecordingState.Waiting,
        });
      }
    }
    if(this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Recording 
      && this.state.audioRecordingDuration !== undefined
      && this.state.audioRecordingDuration !== prevState.audioRecordingDuration) {
        if(this.state.audioRecordingStartTime) {
          window.setTimeout(() => {
            this.calculateAudioRecordingDuration();
          }, 1000)
        }
      }
  }
  calculateAudioRecordingDuration = () => {
    const duration = this.state.audioRecordingDuration !== undefined 
    && this.state.audioRecordingStartTime
      ? calculateDurationInSecondsFromCurrentTime(this.state.audioRecordingStartTime) 
      : undefined;

    this.setState({
      audioRecordingDuration: duration
    });
  }
  componentDidMount() {
    if (this.audioPlayer) {      
      this.audioPlayer.onloadedmetadata = () => {
        if (this.audioPlayer) {                    
          this.setState({            
            remainigPlayDuration: this.audioPlayer.duration !== Infinity && !isNaN(this.audioPlayer.duration)
            ? this.audioPlayer.duration
            : this.state.audioFile && this.state.audioFile.duration ? this.state.audioFile.duration : 1
            ,
          });
        }
      };
      this.audioPlayer.ontimeupdate = () => {
        // This is a hack to fix a bug in chrome where the duration does not properly get updated.
        if (this.audioPlayer && this.audioPlayer.duration === Infinity && this.audioPlayer.currentTime !== 1000000) {
          this.audioPlayer.currentTime = 1000000;
          this.audioPlayer.currentTime = 0;
          return;
        }

        if (this.audioPlayer) {
          const remainingTime = this.audioPlayer.duration && this.audioPlayer.duration !== Infinity ? this.audioPlayer.duration - this.audioPlayer.currentTime : 0;
          this.setState({
            remainigPlayDuration: remainingTime,
          });
        }
      }
    }
  }
  public render() {
    return (
      <div className="slideEditorAudioCaptionContainer">
        {/* Spacer - Do not remove */}
        <span></span>
        <div className="slideEditorAudioCaption">
          <div className={classNames('slideEditorAudioCaptionControls', this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Recording ? 'recording' : undefined)}>
            {this.state.audioCaptionRecordingState !== AudioCaptionRecordingState.Recording && (
              <div className="exitAudio"
                onClick={this.props.closeAudioRecording}
              >
                <icons.Nav_Exit_Alternative
                  color='var(--COLOR-RED-100)'
                />
              </div>
            )}

            <div className="audioRecorderSlider">
              <CircleIconButton
                className={`audioButton ${this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Waiting
                  || this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Stopped ? '' : ' notWaiting'}`}
                width={38}
                height={38}
                iconCssClass={'whites-normal-1000-svg'}
                alt="record audio"
                onClick={() => {
                  this.setAudioRecordingState();
                }}
                backgroundColor='var(--COLOR-BLUE-100)'
              >
                <>
                  {this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Waiting && (
                    <icons.SlideType_Audio_Mic 
                      color="var(--WHITES-NORMAL-1000)"
                    />
                  )}
                  {this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Recording && (
                    <icons.Nav_Media_Player_Stop 
                      color="var(--WHITES-NORMAL-1000)"
                    />
                  )}       
                  {this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Playing && (
                    <icons.Nav_Media_Player_Pause 
                      color="var(--WHITES-NORMAL-1000)"
                    />
                  )}
                  {this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Stopped && (
                    <icons.Nav_Media_Player_Play 
                      color="var(--WHITES-NORMAL-1000)"
                    />
                  )}           
                </>
              </CircleIconButton>
              {/* TODO: set this based on the state of the recording */}
              <span className={`recordingControl audioRecorderSliderLabel${this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Waiting
                || this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Stopped
                ? '' : ' notWaiting'}`}
                onClick={() => {
                  this.setAudioRecordingState();
                }}
              >
                {this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Waiting                  
                  ? this.state.audioFile 
                    ? formatMinuteAndSeconds(this.state.remainigPlayDuration) 
                    : 'REC'
                  : this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Recording
                    ? formatMinuteAndSeconds(this.state.audioRecordingDuration as number)
                    : this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Playing
                      ? !isNaN(this.state.remainigPlayDuration)
                        ? formatMinuteAndSeconds(this.state.remainigPlayDuration)
                        : ''
                      : this.state.audioCaptionRecordingState === AudioCaptionRecordingState.Stopped // Paused
                        ? formatMinuteAndSeconds(this.state.remainigPlayDuration)
                        : ''
                }
              </span>
            </div>
            {this.state.audioFile && (
              <div className="audioFileActions">
                <TertiaryButton
                  icon={icons.ContentAlteration_Check_Main}
                  isSelected={true}
                  label="Finish"
                  style={{width: '71px', backgroundColor: this.props.theme !== 'light' ? 'var(--WHITES-NORMAL-100)' : '', cursor: 'pointer'}}
                  onClick={() => {
                    this.props.saveAudioRecording({ 
                      ...this.state.audioFile as AudioFile, 
                      autoPlay: this.state.autoPlay,
                      autoAdvance: this.state.autoAdvance 
                    });
                  }}
                />
                <TertiaryButton
                  icon={icons.ContentAlteration_Delete}
                  isSelected={true}
                  style={{width: '71px', backgroundColor: this.props.theme !== 'light' ? 'var(--WHITES-NORMAL-100)' : '', cursor: 'pointer'}}
                  label="Delete"
                  onClick={() => this.props.onDeleteAudioRecording(this.state.audioFile as AudioFile)}
                />
              </div>
            )}
            {!this.state.audioFile && this.state.audioCaptionRecordingState !== AudioCaptionRecordingState.Recording && (
              <div className="audioFileDropzone">
                <DropZone
                  onDrop={this.onDrop.bind(this)}
                  
                >
                  <TertiaryButton
                    icon={icons.ContentAlteration_Upload}
                    isSelected={true}
                    style={{width: '71px', backgroundColor: this.props.theme !== 'light' ? 'var(--WHITES-NORMAL-100)' : '', cursor: 'pointer'}}
                    label="Upload"
                    onClick={() => {}}
                  />
                </DropZone>
              </div>
            )}

          </div>
          {this.props.displayAutoPlayControls && (
            <div className="slideEditorAudioCaptionOptions">
              <CheckBoxComponent
                checked={this.state.autoPlay}
                label="Auto Play"
                change={(e: any) => {
                  this.setState({ autoPlay: e.checked });
                }}
              />
              <CheckBoxComponent
                checked={this.state.autoAdvance}
                label="Auto Advance"
                change={(e: any) => {
                  this.setState({ autoAdvance: e.checked });
                }}
              />
            </div>
          )}
        </div>
        {/* Spacer - Do not remove */}
        <audio
          className="hiddenAudioPlayer"
          preload="metadata"
          id={v4()}
          ref={(audioPlayer: HTMLAudioElement) => { this.audioPlayer = audioPlayer }}
        />
        <span></span>
      </div>
    );
  }
}
