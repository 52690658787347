import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState,    
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import ImageEditor from '../../Viewers/ImageViewers/ImageEditor/ImageEditor';
import { ensureImageFileOrientation } from '../../../utils/helperFunctions';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes, 
} from './Common';
import { v4 } from 'uuid';
import { ImageModes } from './SharedLogicForImageSlideEditor';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;
  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon(
      'Crop your image to the tile size',
      '/icons/creationprocess/cropandrotate/cropandrotate.svg',
      'white',
    ),
    onNavigateBack: () => {
      setState({...state, mode: CommonModes.Edit, slide: context.state.slide });
    }
  };
}

export const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { state, setState } = context;
  return (
    <div style={{display: 'flex', flex: 1, maxWidth: '100%',  height: '100%', flexDirection: 'row'}}>
      <ImageEditor
        image={{url: state.slide.data.files[state.selectedItemIndex].url, type: 'IMAGE'}}
        saveImage={(image: any) => {
          setState({
            ...state,
            mode: ImageModes.CroppingImage
          });
          // catch the returned data and add to list. will need to save it
          ensureImageFileOrientation(image).then(imageFile => {
            const croppedSlide = {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: state.slide.data.files.map((i: any, index: number) => {
                  if (state.selectedItemIndex === index) {
                    return {
                      ...i,
                      url: URL.createObjectURL(imageFile),
                      dataUrl: imageFile,
                      processingId: v4(),
                      type: 'UPLOAD',
                    };
                  } else {
                    return i;
                  }
                })
              }
            };
              setState({
                ...state,
                slide: croppedSlide,
                mode: CommonModes.Edit,
              });
          });
        }}
        responseType='base64'         
        cropperOptions={{
          guides : true,
          autoCropArea: 1,
          rotatable: true,
        }}
      />
    </div>
    );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const Crop = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
